import { Col, Row } from "antd";
import Role from "common/constants/Role";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";
import RoleCheck from "components/RoleCheck";
import { useTranslation } from "react-i18next";
import { IconAward } from "@tabler/icons-react";

const ShopEventHeader = ({
	title,
	pathname,
}: {
	title?: string;
	pathname: string;
}) => {
	const { t } = useTranslation();
	return (
		<PageHeader
			heading={title ?? t("shopsetting:shopevent.heading_list")}
			siteMenuOpenKey="/shopevent"
			siteMenuSelectedParentKey="shop"
			siteMenuSelectedKey={`/shopevent/` + pathname}></PageHeader>
	);
};

export default ShopEventHeader;
