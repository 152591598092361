import { Badge, Table, Tag, Typography } from "antd";
import Role from "common/constants/Role";
import InventoryReceiptRepository from "common/repositories/InventoryReceiptRepository";
import {
	InventoryReceiptCollectionJson,
	InventoryReceiptDirection,
	InventoryReceiptFilter,
	InventoryReceiptSourceType,
	InventoryReceiptStatus,
	InventoryReceiptTargetType,
} from "common/types/InventoryResceipt";
import { TableColumnsType } from "common/types/Table";
import { WarehouseJson } from "common/types/Warehouse";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import TextDateTime from "components/TextDateTime";
import TextProductReceiptDirection from "components/TextProductReceiptDirection";
import useDatabaseTable from "hooks/useDatabaseTable";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import InventoryReceiptModel from "common/models/InventoryReceiptModel";
import InventoryReceiptListHeader from "./InventoryReceiptListHeader";
import InventoryReceiptListFilter from "./InventoryReceiptListFilter";
import InventoryReceiptCollection from "common/collections/InventoryReceiptCollection";
import Helper from "common/utils/helper";
import WarehouseCollection from "common/collections/WarehouseCollection";
import WarehouseRepository from "common/repositories/WarehouseRepository";
import WarehouseModel from "common/models/WarehouseModel";
import { Link } from "react-router-dom";
import TextWarehouse from "components/TextWarehouse";
import SupplierRepository from "common/repositories/SupplierRepository";
import SupplierModel from "common/models/SupplierModel";
import StoryModel from "common/models/StoryModel";
import SupplierCollection from "common/collections/SupplierCollection";
import StoryCollection from "common/collections/StoryCollection";
import StoryRepository from "common/repositories/StoryRepository";
import PublicationsRepository from "common/repositories/PublicationsRepository";

interface ExpandedDataType {
	key: React.Key;
	date: string;
	name: string;
	upgradeNum: string;
}
type Props = {
	direction?: InventoryReceiptDirection;
};
const InventoryReceiptList = ({ direction }: Props) => {
	const { t } = useTranslation();

	const [warehouseItemsWithLimit] = useDatabaseTable<WarehouseJson>(
		"warehouse",
		Role.INVENTORY_VIEW
	);

	//////////////////////////////////////////
	//Filtering
	const defaultFilters: InventoryReceiptFilter = useMemo(
		() => ({
			...InventoryReceiptRepository.getDefaultFilters(),
			direction: direction ?? -1,
		}),
		[direction]
	);
	const [filters, setFilters] =
		useStateFilter<InventoryReceiptFilter>(defaultFilters);
	useFilterLocation(defaultFilters, filters);

	//////////////////////////////////////////
	//Fetch data from this collections
	const [total, setTotal] = useState<number>(0);
	const [dataSource, setDataSource] = useState<InventoryReceiptModel[]>([]);
	const [warehouseItems, setWarehouseItems] = useState<WarehouseModel[]>([]);
	const [supplierItems, setSupplierItems] = useState<SupplierModel[]>([]);
	const [documentItems, setDocumentItems] = useState<StoryModel[]>([]);

	const fetchData =
		useCallback(async (): Promise<InventoryReceiptCollection> => {
			let collection = await new InventoryReceiptRepository().getItems({
				filters,
			});

			setTotal(collection.total);
			setDataSource(collection.items);

			return collection;
		}, [filters]);

	const fetchDataWarehouse =
		useCallback(async (): Promise<WarehouseCollection> => {
			let collection = await new WarehouseRepository().getItems({
				filters: {
					...WarehouseRepository.getDefaultFilters(),
				},
			});
			setWarehouseItems(collection.items);

			return collection;
		}, []);

	useEffect(() => {
		fetchDataWarehouse();
	}, [fetchDataWarehouse]);

	const fetchDataSupplier =
		useCallback(async (): Promise<SupplierCollection> => {
			let collection = await new SupplierRepository().getItems({
				filters: {
					...SupplierRepository.getDefaultFilters(),
				},
			});
			setSupplierItems(collection.items);

			return collection;
		}, []);

	useEffect(() => {
		fetchDataSupplier();
	}, [fetchDataSupplier]);

	const fetchDataDocument =
		useCallback(async (): Promise<StoryCollection> => {
			let collection = await new PublicationsRepository().getItems({
				filters: {
					...PublicationsRepository.getDefaultFilter(),
				},
			});
			setDocumentItems(collection.items);

			return collection;
		}, []);

	useEffect(() => {
		fetchDataDocument();
	}, [fetchDataDocument]);

	const renderColumnSource = (source: any) => {
		switch(source.type) {
			case InventoryReceiptSourceType.ORDER:
				return (
					<Link target="_blank" to={`/order/edit/id/${source.id}`}>
						Đơn hàng <div>#{source.id}</div>
					</Link>
				);
			case InventoryReceiptSourceType.WAREHOUSE:
				return (
					<Link target="_blank" to={`/warehouse/edit/id/${source.id}`}>
						Kho hàng <TextWarehouse id={source.id} />
					</Link>
				);
			case InventoryReceiptSourceType.SUPPLIER: {
				const supplier = supplierItems.find((item) => item.id === Number(source.id));

				return (
					<Link target="_blank" to={`/supplier/edit/id/${source.id}`}>
						Nhà cung cấp <div>{supplier?.name ?? `#${source.id}`}</div>
					</Link>
				);
			}
			case InventoryReceiptSourceType.DOC: {
				const document = documentItems.find((item) => item.id === Number(source.id));

				return (
					<Link target="_blank" to={`/documents/edit/id/${source.id}`}>
						Chứng từ <div>{document?.meta_title ?? `#${source.id}`}</div>
					</Link>
				);
			}
			case InventoryReceiptSourceType.OTHER:
				return (
					<Typography.Text>Khác #{source.id}</Typography.Text>
				);
		}
	}

	const renderColumnTarget = (target: any) => {
		switch(target.type) {
			case InventoryReceiptTargetType.DELIVERY:
				return (
					<Link target="_blank" to={`/delivery/edit/id/${target.id}`}>
						Vận đơn <div>#{target.id}</div>
					</Link>
				);
			case InventoryReceiptTargetType.WAREHOUSE:
				return (
					<Link target="_blank" to={`/warehouse/edit/id/${target.id}`}>
						Kho hàng <TextWarehouse id={target.id} />
					</Link>
				);
			case InventoryReceiptTargetType.SUPPLIER: {
				const supplier = supplierItems.find((item) => item.id === Number(target.id));

				return (
					<Link target="_blank" to={`/supplier/edit/id/${target.id}`}>
						Nhà cung cấp <div>{supplier?.name ?? `#${target.id}`}</div>
					</Link>
				);
			}
			case InventoryReceiptTargetType.OTHER:
				return (
					<Typography.Text>Khác #{target.id}</Typography.Text>
				);
		}
	}

	//Table columns
	const columns: TableColumnsType<InventoryReceiptModel> = [
		{
			title: t("Mã"),
			key: "id",
			align: "center",
			width: 200,
			render: (_, record) => {
				return (
					<>
						<TableLinkText link={"/inventoryreceipt/edit/id/" + record.id}>
							{record.code}
						</TableLinkText>
					</>
				);
			},
		},
		{
			title: t("Loại phiếu"),
			key: "direction",
			render: (direction: number) => {
				return <TextProductReceiptDirection direction={direction} />;
			},
			width: 80,
		},
		{
			title: t("Từ kho"),
			key: "warehouse_id",
			width: 150,
			render: (warehouse_id: number) => {
				const found = warehouseItems.find((i) => i.id === warehouse_id);
				if (typeof found !== "undefined") {
					return <span>{found.name}</span>;
				} else {
					return "ID #" + warehouse_id;
				}
			},
		},
		{
			title: t("Nguồn"),
			key: "source",
			width: 150,
			render: renderColumnSource,
		},
		{
			title: t("Đích"),
			key: "target",
			width: 150,
			render: renderColumnTarget,
		},
		{
			title: t("productreceipt:type"),
			key: "type",
			width: 100,

			render: (type: number, record) => {
				const typeList = InventoryReceiptModel.getTypeList(record.direction);

				return <>{typeList.find((i) => i.value === type)?.label}</>;
			},
		},
		// {
		//   title: t("productreceipt:order"),
		//   key: "sale_order_id",
		//   width: 130,
		//   render: (_, record) => {
		//     return (
		//       <>
		//         {record.sale_order_id > 0 ? (
		//           <TableLinkText
		//             link={"/order/edit/id/" + record.sale_order_id}
		//             title={t("productreceipt:sale_order")}
		//             target="_blank"
		//           >
		//             {t("productreceipt:sale_order_short")} #{record.sale_order_id}
		//           </TableLinkText>
		//         ) : null}
		//       </>
		//     );
		//   },
		// },
		{
			title: t("common:status"),
			key: "status",
			sortby: "status",
			render: (status: number) => {
				let statusInfo = InventoryReceiptModel.getStatus(status);
				return <Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>;
			},
			width: 120,
		},
		{
			title: t("common:date_created"),
			key: "date_created",
			width: 120,
			render: (date_created) => Helper.formatTimestampToText2(date_created),
		},
		{
			title: " ",
			key: "actions",
			align: "right",
			fixed: "right",
			toggletype: "trigger",
			width: 95,
			render: (_: any, record: InventoryReceiptModel) => (
				<>
					{/* <ProductReceiptItemEdit model={record} /> */}
					<TableInfo record={record}></TableInfo>
				</>
			),
		},
	];

	useEffect(() => {
		direction &&
			setFilters({ ...filters, direction: direction, type: undefined });
	}, [direction]);

	return (
		<>
			<InventoryReceiptListHeader />

			<InventoryReceiptListFilter
				warehouseItemsWithLimit={warehouseItems}
				filters={filters}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
				total={total}
				direction={direction}
			/>

			<PageDataTable<
				InventoryReceiptFilter,
				InventoryReceiptModel,
				InventoryReceiptCollection
			>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					tableColumnToggleKey: "inventoryreceipt",
				}}></PageDataTable>

			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>
		</>
	);
};

export default InventoryReceiptList;
