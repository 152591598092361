import { useTranslation } from "react-i18next";
import useStateFilter from "hooks/useStateFilter";
import useFilterLocation from "hooks/useFilterLocation";
import React, { useCallback, useMemo, useState } from "react";
import { Switch, TableColumnsType, Tag, message, notification } from "antd";

import RoleCheck from "components/RoleCheck";
import DocumentListHeader from "./DocumentListHeader";
import TableInfo from "components/table/TableInfo";
import PageDataTable from "components/page/PageDataTable";

import Role from "common/constants/Role";
import StoryModel from "common/models/StoryModel";
import { FilterStory, PublicationsStatus, StoryJson } from "common/types/Story";
import StoryCollection from "common/collections/StoryCollection";
import PageDataPagination from "components/page/PageDataPagination";
import DocumentListFilter from "./DocumentListFilter";
import PublicationsRepository from "common/repositories/PublicationsRepository";
import TableEdit from "components/table/TableEdit";
import Helper from "common/utils/helper";
import { IdFetcherResult } from "common/types/IdFetcher";
import useIdFetcherState from "hooks/useIdFetcherState";
import IdFetcherRender from "components/idfetcher/IdFetcherRender";

const DocumentList = () => {
	const { t } = useTranslation();
	const [messageApi, contextHolder] = message.useMessage();

	const { results: employees, getListIdFetcher } = useIdFetcherState();
	// default filter
	const defaultFilters = useMemo(() => {
		return { ...PublicationsRepository.getDefaultFilter() };
	}, []);

	//state
	const [total, setTotal] = useState<number>(0);
	const [dataSource, setDataSource] = useState<StoryModel[]>([]);
	const [filters, setFilters] = useStateFilter(defaultFilters);

	useFilterLocation(defaultFilters, filters);

	async function onChangeStatus(story: StoryModel, status: boolean) {
		messageApi.open({
			type: "loading",
			content: "Đang xử lí",
			duration: 0,
		});
		const res = await new PublicationsRepository().updateStatus({
			id: story.id,
			status: status ? PublicationsStatus.ACTIVE : PublicationsStatus.UNACTIVE,
		});
		messageApi.destroy();
		if (!res.hasError()) {
			fetchData();
		} else {
			notification.error({ message: res.error.errors[0] });
		}
	}
	//Table columns
	const columns: TableColumnsType<StoryModel> = [
		{
			title: t("common:columns.id"),
			key: "id",
			width: 100,
			render: (id) => {
				return <Tag color="blue">{id}</Tag>;
			},
		},
		{
			title: t("common:columns.title"),
			key: "meta_title",
			width: 200,
			render: (title: string) => <p className="line-clamp-2 m-0">{title}</p>,
		},
		{
			title: t("common:columns.creator"),
			key: "creator_id",
			width: 100,
			render: (creator_id) => (
				<IdFetcherRender
					value={creator_id}
					mapping={employees}
					key_value="text"
				/>
			),
		},
		{
			title: t("common:columns.status"),
			key: "status",
			width: 150,
			render: (status: number, record) => (
				<Switch
					checked={status == PublicationsStatus.ACTIVE}
					onChange={(e) => {
						onChangeStatus(record, e.valueOf());
					}}
				/>
			),
		},
		{
			title: t("common:date_created"),
			key: "date_created",
			width: 120,
			render: (date_created) => Helper.formatTimestampToText2(date_created),
		},
		{
			title: t(""),
			key: "actions",
			width: 100,
			align: "right",
			fixed: "right",
			render: (_: any, record: StoryJson) => (
				<RoleCheck roles={[Role.SETTING_HRM]} hideOnFail>
					<TableEdit link={`/documents/edit/id/${record.id}`} />
					<TableInfo record={record} />
				</RoleCheck>
			),
		},
	];

	//function
	const fetchData = useCallback(async (): Promise<StoryCollection> => {
		const response = await new PublicationsRepository().getItems({
			filters: filters,
		});

		if (!response.hasError()) {
			setTotal(response.total);
			setDataSource(response.items);
			getListIdFetcher({
				ids: response.items.map((i) => i.creator_id),
				object_type: "employee",
			});
		} else {
			message.error(t("common:error.error_fetching_data"));
		}

		return response;
	}, [filters]);

	return (
		<div>
			<DocumentListHeader />
			<DocumentListFilter
				total={total}
				filters={filters}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
			/>
			<PageDataTable<FilterStory, StoryModel, StoryCollection>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					tableColumnToggleKey: "story",
				}}
			/>
			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>

			{contextHolder}
		</div>
	);
};

export default DocumentList;
