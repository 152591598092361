import { IconInfoCircle, IconRefresh } from "@tabler/icons-react";
import { Button, Col, Empty, Popover, Row, Table, Tag, Typography } from "antd";
import EventHistoryCollection from "common/collections/EventHistoryCollection";
import EventHistoryConstant from "common/constants/EventHistory";
import EventHistoryModel from "common/models/EventHistoryModel";
import EventHistoryRepository from "common/repositories/EventHistoryRepository";
import LogAuditRepository from "common/repositories/LogAuditRepository";
import { EventHistoryJson } from "common/types/EventHistory";
import { LogAuditJson } from "common/types/LogAudit";
import { OrderJson } from "common/types/Order";
import { TableColumnsType } from "common/types/Table";
import ErrorAlert from "components/ErrorAlert";
import TableInfo from "components/table/TableInfo";
import TableInfoRow from "components/table/TableInfoRow";
import TextDateTime from "components/TextDateTime";
import TextUser from "components/TextUser";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
	order: OrderJson;
};
const OrderFormSectionHistory = ({ order }: Props) => {
	const { t } = useTranslation();
	const [filter, setFilter] = useState(
		EventHistoryRepository.getDefaultFilters()
	);
	const [dataSourceAudit, setDataSourceAudit] = useState<LogAuditJson[]>([]);
	const [dataSource, setDataSource] = useState<EventHistoryJson[]>([]);
	const [total, setTotal] = useState(0);
	const [errors, setErrors] = useState<string[]>([]);
	const [loading, setLoading] = useState<boolean>(true);

	const fetchDataHistory = useCallback(async () => {
		setLoading(true);
		const allPromise = [
			EventHistoryConstant.SOURE_TYPE.SOURCE_CASHFLOW_RECEIPT,
			EventHistoryConstant.SOURE_TYPE.SOURCE_INVENTORY_RECEIPT,
			EventHistoryConstant.SOURE_TYPE.SOURCE_OTHER,
			EventHistoryConstant.SOURE_TYPE.SOURCE_SALE_ORDER,
		].map((type) =>
			new EventHistoryRepository().getItems({
				filters: {
					...filter,
					source_id: order.id,
					source_type: type,
				},
			})
		);

		await Promise.allSettled(allPromise).then((results) => {
			const result: EventHistoryJson[] = [];
			let totalData = 0;

			results.forEach((res) => {
				if (res.status === "fulfilled") {
					const value = res.value as EventHistoryCollection;

					if (!value.hasError()) {
						result.push(...value.items);
						totalData += value.total;
					}
				} else if (res.status === "rejected") {
					console.error(`Promise failed: ${res.reason}`);
					// Handle or log the rejection
				}
			});

			setDataSource(result);
			setTotal(totalData);

			setLoading(false);
		});
	}, [order]);

	const fetchDataHistoryAudit = useCallback(async () => {
		setLoading(true);
		setErrors([]);
		const res = await new LogAuditRepository().getItems({
			filters: {
				...LogAuditRepository.getDefaultFilters(),
				object_id: order.id,
				controller: "order",
			},
		});
		if (!res.hasError()) {
			setDataSourceAudit(res.toJson().items);
		} else {
			setErrors(res.error.errors);
		}
		setLoading(false);
	}, [order]);

	const columns: TableColumnsType<EventHistoryJson> = [
		{
			title: "Mã",
			key: "id",
			dataIndex: "id",
			render: (id, record, index) => {
				return <>{id}</>;
			},
		},
		{
			title: "Thao tác trên",
			key: "source_type",
			dataIndex: "source_type",
			render: (source_type, record, index) => {
				const label = EventHistoryModel.getSoureTypeLabel(source_type);
				return <Tag color={label?.color}>{label?.label}</Tag>;
			},
		},

		{
			title: "Hành động",
			key: "detail",
			dataIndex: "detail",
			render: (detail, record) => {
				return (
					<Typography.Text
						ellipsis={{ tooltip: <p>{detail.action_detail}</p> }}>
						{t(`order:event_history.action.${detail.action_detail}`)}
					</Typography.Text>
				);
			},
		},

		{
			title: "Thời gian",
			key: "date_created",
			dataIndex: "date_created",
			render: (date_created, record) => {
				if (date_created <= 0) {
					return <>__</>;
				}
				return (
					<>
						<TextDateTime ts={date_created}></TextDateTime>
					</>
				);
			},
		},

		{
			title: "",
			key: "date_created",
			dataIndex: "date_created",
			render: (date_created, record) => {
				const popoverContent = (
					<>
						<TableInfoRow label={t("common:sid_long")} content={record.id} />
						<TableInfoRow
							label={t("common:creator_id")}
							content={<TextUser id={record.detail.employee_id || 0} />}
						/>
					</>
				);
				return (
					<>
						<Popover
							trigger={"click"}
							title={""}
							content={popoverContent}
							placement="topRight">
							<Button size="small" type="text">
								<IconInfoCircle
									size="18"
									className={" -mt-0.5  text-gray-400 hover:text-gray-500"}
								/>
							</Button>
						</Popover>
					</>
				);
			},
		},
	];

	const autditColumns: TableColumnsType<LogAuditJson> = [
		{
			title: "Mã",
			key: "id",
			dataIndex: "id",
			render: (id, record, index) => {
				return <>{id}</>;
			},
		},
		{
			title: "Hành động",
			key: "action",
			dataIndex: "action",
			render: (action, record, index) => {
				return (
					<Typography.Text className="text-blue-500">
						{t(`order:event_history.action.${action}`)}
					</Typography.Text>
				);
			},
		},

		{
			title: "Người tạo",
			key: "creator_id",
			dataIndex: "creator_id",
			render: (creator_id, record, index) => {
				const newDataCustomer: object = record.new_data || {};
				if (creator_id <= 0 && newDataCustomer) return <Tag>Khách hàng</Tag>;
				return <TextUser id={creator_id}></TextUser>;
			},
		},

		{
			title: "Thời gian",
			key: "date_created",
			dataIndex: "date_created",
			render: (date_created, record) => {
				if (date_created <= 0) {
					return <>__</>;
				}
				return (
					<>
						<TextDateTime
							format="HH:ss DD/MM/YYYY"
							ts={date_created}></TextDateTime>
					</>
				);
			},
		},

		{
			title: "",
			key: "date_created",
			align: "end",
			dataIndex: "date_created",
			render: (date_created, record) => {
				const popoverContent = (
					<div className="relative max-w-[90vw]  overflow-auto md:max-w-[70vw] max-h-[70vh]">
						{Object.entries(record).map(([key, value]: [string, any]) => {
							return (
								<TableInfoRow
									className="flex"
									label={
										<Typography.Link>{t(`order:key.${key}`)}</Typography.Link>
									}
									content={<pre>{JSON.stringify(value, null, 2)}</pre>}
								/>
							);
						})}
					</div>
				);
				return (
					<>
						<TableInfo record={record}></TableInfo>
						{/* <Popover
							trigger={"hover"}
							title={"Thông tin chi tiết"}
							content={popoverContent}
							placement="topRight">
							<Button size="small" type="text">
								<IconInfoCircle
									size="18"
									className={" -mt-0.5  text-gray-400 hover:text-gray-500"}
								/>
							</Button>
						</Popover> */}
					</>
				);
			},
		},

		// {
		// 	title: "",
		// 	key: "more_data",
		// 	dataIndex: "more_data",
		// 	render: (more_data, record) => {
		// 		const popoverContent = (
		// 			<Typography className="max-h-[80vh] overflow-auto">
		// 				<pre>{JSON.stringify(record, null, 2)}</pre>
		// 			</Typography>
		// 		);
		// 		return (
		// 			<>
		// 				<Popover
		// 					trigger={"hover"}
		// 					title={"Thông tin chi tiết"}
		// 					autoAdjustOverflow
		// 					content={popoverContent}
		// 					placement="topRight">
		// 					<Button size="small" type="text">
		// 						<IconInfoCircle
		// 							size="18"
		// 							className={" -mt-0.5  text-gray-400 hover:text-gray-500"}
		// 						/>
		// 					</Button>
		// 				</Popover>
		// 			</>
		// 		);
		// 	},
		// },
	];

	// useEffect(() => {
	// 	fetchDataHistory();
	// }, [fetchDataHistory]);

	useEffect(() => {
		fetchDataHistoryAudit();
	}, [fetchDataHistoryAudit]);
	return (
		<div className="mt-8">
			<Row className="p-2 bg-gray-100">
				<Col
					span={3}
					className="px-2 text-xl font-extrabold text-right text-gray-600 uppercase bg-gray-100">
					{t("order:form.section_history")}
					<br />
				</Col>
				<Col span={21}>
					{errors.length > 0 ? (
						<div className="p-2">
							<ErrorAlert
								items={errors}
								translate_prefix="order:form.error"
								onRetry={fetchDataHistoryAudit}
							/>
						</div>
					) : null}
					<>
						{!dataSourceAudit.length && errors.length <= 0 && !loading ? (
							<div className="p-4 m-2 bg-white rounded-md">
								<Empty
									imageStyle={{ height: 40 }}
									description={
										<div className="">{t("order:form.history_empty")}</div>
									}></Empty>
							</div>
						) : (
							<Table
								expandable={{
									showExpandColumn: true,
								}}
								className="w-full border"
								rowKey={"id"}
								pagination={{
									pageSize: 100,
									hideOnSinglePage: true,
								}}
								columns={autditColumns}
								dataSource={dataSourceAudit}
								loading={loading}
							/>
						)}
					</>
					<div className="my-2">
						<Button
							onClick={() => fetchDataHistoryAudit()}
							icon={<IconRefresh size={16} className="mr-1" />}
							className="opacity-40 hover:opacity-80"
							size="small"
							type="text">
							{t("order:form.refresh_receipt")}
						</Button>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default OrderFormSectionHistory;
