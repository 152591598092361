import { PlusSquareOutlined } from "@ant-design/icons";
import { Button, Modal, Select, SelectProps } from "antd";
import BrandCollection from "common/collections/BrandCollection";
import BrandModel from "common/models/BrandModel";
import BrandRepository from "common/repositories/BrandRepository";
import BrandForm from "features/brand/form/BrandForm";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";

export default function BrandSearchCreate({
  selectProps,
  isEdit,
}: {
  selectProps?: SelectProps;
  isEdit?: boolean;
}) {
  const [brandList, setBrandList] = useState<BrandModel[]>([]);
  const [open, setOpen] = useState(false);

  const [filterBrand, setFilterBrand] = useState(
    BrandRepository.getDefaultFilters()
  );

  const fetchDataBrandList = useCallback(async (): Promise<BrandCollection> => {
    let collection = await new BrandRepository().getItems({
      filters: { ...filterBrand, limit: 50 },
    });
    if (collection.items.length > 0) {
      setBrandList(collection.items);
    }
    return collection;
  }, [filterBrand]);
  const debouceSearchBrand = useCallback(
    debounce((value: string) => {
      setFilterBrand((pre) => {
        return { ...pre, keyword: value };
      });
    }, 500),
    []
  );
  useEffect(() => {
    fetchDataBrandList();
  }, [fetchDataBrandList]);
  return (
    <div className=" flex  items-center">
      <Select
        {...selectProps}
        showSearch
        placeholder="Tìm kiếm thương hiệu"
        onSearch={(value) => {
          debouceSearchBrand(value);
        }}
        allowClear
        filterOption={false}
        options={brandList.map((store) => ({
          label: store.title,
          value: store.id,
        }))}
      />

      <Button onClick={() => setOpen(true)}>
        <PlusSquareOutlined />
      </Button>

      <Modal
        width={1000}
        centered
        open={open}
        onCancel={() => setOpen(false)}
        title="thêm mới thương hiệu"
        footer={null}
      >
        <BrandForm
          model={new BrandModel(BrandModel.getDefaultData())}
          isHiddenHeader
          onSucsess={() => {
            fetchDataBrandList();
            setOpen(false);
          }}
          redirectOnSuccess=""
        />
      </Modal>
    </div>
  );
}
