import {
  Button,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Row,
  Spin,
  message,
} from "antd";
import { IconPencil, IconPhoto } from "@tabler/icons-react";

import { useCallback, useEffect, useMemo, useState } from "react";
import useDbForSelect from "hooks/useDbForSelect";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import update from "immutability-helper";
import moment, { Moment } from "moment";
import dayjs from "dayjs";

import FormSection from "components/form/FormSection";
import FormSelect from "components/form/FormSelect";
import LayoutForm from "components/form/LayoutForm";
import FileUploaderButton from "components/file/uploader/FileUploaderButton";
import DepartmentFormSelect from "features/department/DepartmentFormSelect";

import Helper from "common/utils/helper";

import type {
  BankInfoEmployee,
  EmployeeProfileJsonEdit,
} from "common/types/Employee";

import Employee from "common/constants/Employee";
import EmployeeModel from "common/models/EmployeeModel";
import EmployeeRepository from "common/repositories/EmployeeRepository";
import AccountModel from "common/models/AccountModel";
import FileModel from "common/models/FileModel";

import AccountRepository from "common/repositories/AccountRepository";
import PositionRepository from "common/repositories/PositionRepository";
import TypeOfWorkRepository from "common/repositories/TypeOfWorkRepository";

import OfficeFormSelect from "features/employee/form/components/OfficeFormSelect";
import ProfileEditorHeader from "./ProfileEditorHeader";
import ProfilePasswordModal from "./ProfilePasswordModal";
import useSettingValue from "hooks/useSettingValue";
import SettingApi from "common/constants/Setting";

const { Panel } = Collapse;

const ProfileEditor = ({ model }: { model: EmployeeModel }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isEditing = model.id > 0;
  const key_avatar = "key_avatar";
  const [account, setAccount] = useLoginAccountStore((state) => [
    state.account,
    state.setAccount,
  ]);
  const modelAccount = new AccountModel(
    useLoginAccountStore((state) => state.account)
  );

  const {
    setting: settingEditProfileDays,
    isLoading: isLoadingSettingEditProfileDays,
  } = useSettingValue(SettingApi.KEY.setting_edit_profile_days, SettingApi.VALUE.setting_edit_profile_days);

  //state
  const [errors, setErrors] = useState<string[]>([]);
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [officeItems] = useDbForSelect("companyoffice");
  const [bankList, setBankList] = useState<any>([]);
  const [typeWorks, setTypeWorks] = useState<any>([]);
  const [positions, setPositions] = useState<any>([]);
  const [contractCompleted, setContractCompleted] = useState<boolean>(false);

  const isDisabledAfterDaysSetting = useMemo(
    () =>
      {
        const fromDate = model.date_created * 1000;
        const currentDate = new Date().getTime();

        const diffInMs = currentDate - fromDate;
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

        if (diffInDays < settingEditProfileDays.value) {
          return false;
        }

        return true;
      },
    [model.date_created, settingEditProfileDays.value]
  );

  const convertArrayToContract = (contract: any) => {
    if (contract !== null) {
      if (model.contract.length === 7) {
        setContractCompleted(true);
      }
      for (let i = 0; i < 8; i++) {
        if (model.contract.includes(i)) {
          switch (i) {
            case 1:
              form.setFieldsValue({ syll: true });
              break;
            case 2:
              form.setFieldsValue({ cmtnd: true });
              break;
            case 3:
              form.setFieldsValue({ picture: true });
              break;
            case 4:
              form.setFieldsValue({ btnn: true });
              break;
            case 5:
              form.setFieldsValue({ gksk: true });
              break;
            case 6:
              form.setFieldsValue({ dxv: true });
              break;
            case 7:
              form.setFieldsValue({ hddk: true });
              break;
          }
        }
      }
    }
  };

  const initialValues = {
    email: model.email,
    full_name: model.full_name,
    gender: model.gender,
    phone: model.phone,
    internal_id: model.internal_id,
    office_id: model.office_id > 0 ? model.office_id : null,
    type: model.type || Employee.TYPE_FULLTIME,
    department_id: model.department_id > 0 ? model.department_id : null,
    position_id: model.position_id || Employee.POSITION_STAFF,
    job_title: model.job_title,
    checkin_type: model.checkin_type || Employee.CHECKIN_TYPE_OFFICE,
    status: model.status || Employee.STATUS_ENABLE,

    address: model.address || "",
    birthday:
      model.birthday > 0 ? dayjs(new Date(model.birthday * 1000)) : null,
    marital_status: model.marital_status > 0 ? model.marital_status : 0,
    bank_number: model.bank_number || "",
    bank_id: model.bank_id > 0 ? model.bank_id : 6,
    relatives_name: model.relatives_name || "",
    relatives_phone: model.relatives_phone || "",
    relationship: model.relationship || "",
    tax: model.tax || "",
    insurance: model.insurance || "",
    date_start:
      model.date_start > 0 ? dayjs(new Date(model.date_start * 1000)) : null,
    personal_email: model.personal_email || "",
    personal_phone: model.personal_phone || "",
    role_id: model.role_id > 0 ? model.role_id : null,
    permanent_address: model.permanent_address || "",
    cccd: model.cccd || "",
    cccd_issue_day:
      model.cccd_issue_day > 0
        ? dayjs(new Date(model.cccd_issue_day * 1000))
        : 0,
    cccd_publish: model.cccd_publish || "",
    salary_offer:
      `${model.salary_offer}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
    basic_salary:
      `${model.basic_salary}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
    contract_code: model.contract_code || "",
    contract: model.contract,
    files: FileModel.convertToUploadFiles([model.avatar]),
  };

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: EmployeeProfileJsonEdit = {
        // ...EmployeeModel.getDefaultData(),
        id: model.id,
        full_name: formData.full_name,
        gender: formData.gender,
        address: formData.address,
        marital_status: formData.marital_status,
        bank_id: formData.bank_id,
        bank_number: formData.bank_number,
        relatives_name: formData.relatives_name,
        relatives_phone: formData.relatives_phone,
        relationship: formData.relationship,
        birthday:
          Helper.datePickerValueToTimestamp(formData.birthday) > 0
            ? Helper.datePickerValueToTimestamp(formData.birthday)
            : -1,
        tax: formData.tax,
        insurance: formData.insurance || "",
        personal_email: formData.personal_email,
        personal_phone: formData.personal_phone,
        permanent_address: formData.permanent_address,
        cccd: formData.cccd,
        cccd_issue_day:
          Helper.datePickerValueToTimestamp(formData.cccd_issue_day) > 0
            ? Helper.datePickerValueToTimestamp(formData.cccd_issue_day)
            : -1,
        cccd_publish: formData.cccd_publish,
      };

      return submitData;
    },
    [model.id]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    doPrepareData(formData);

    let myObj: EmployeeModel =
      await new EmployeeRepository().updateInfoEmployee(
        doPrepareData(formData)
      );
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setIsSuccess(true);
    }
  };
  const onUpdateAvatar = async (id: number) => {
    console.log("🥺 ~ file: ProfileEditor.tsx:211 ~ onUpdateAvatar ~ id:", id);
    // const data = {
    //   id: modelAccount.id,
    //   full_name: model.full_name,
    //   avatar_file_id: FileModel.getFirstFileId(form.getFieldValue("files")),
    // };

    let updatedAccount: EmployeeModel =
      await new EmployeeRepository().updateAvatar(id);
    if (updatedAccount.hasError()) {
      setErrors(updatedAccount.error.errors);
    } else {
      // update info to current login session
      message.success({
        content: t("employee:avatar_success"),
        className: "message_success",
        key: key_avatar + 1,
        duration: 1,
      });
      // setAccount(
      //   update(account, {
      //     $merge: {
      //       full_name: data.full_name,
      //       avatar: updatedAccount.avatar,
      //       avatar_file_id: updatedAccount.avatar_file_id,
      //     },
      //   })
      // );
    }
  };

  //for edit, show status
  const sidebarItems = (
    <div>
      <FormSelect
        disabled
        label={t("employee:checkin_type")}
        name="checkin_type"
        required
        options={EmployeeModel.getCheckinTypeList()}
      />
      <FormSelect
        disabled
        label={t("common:status")}
        name="status"
        required
        options={EmployeeModel.getStatusList()}
      />
      <Form.Item label={t("newscategory:image")}>
        <FileUploaderButton
          form={form}
          formName="files"
          label={t("file:select_file_image")}
          icon={<IconPhoto size="24" color="grey" />}
          origin="avatar"
          uploadRenderType="thumbnail"
          isSingleFile={true}
          initFiles={initialValues.files}
          // onDeleteCompleted={(f) => {
          //   onUpdateAvatar(0);
          // }}
          onUploadCompleted={(f) => {
            onUpdateAvatar(f.id);
          }}
          disabled={isDisabledAfterDaysSetting}
        />
      </Form.Item>
    </div>
  );

  const getBankList = async () => {
    const items: BankInfoEmployee[] = await new EmployeeRepository().getBank();
    const data = items.map((item: BankInfoEmployee) => {
      return {
        value: item.id,
        label: item.brand_Name,
      };
    });

    setBankList(data);
    return data;
  };

  const getTypeWorks = async () => {
    const collection: any = await new TypeOfWorkRepository().getItems();
    const data = collection.items.map((item: any) => {
      return {
        value: item.type_work_id,
        label: EmployeeModel.getTypeWorkList(item.type_work_code),
      };
    });

    setTypeWorks(data);
    return data;
  };

  const getPositions = async () => {
    const collection: any = await new PositionRepository().getItems();

    const data = collection.items.map((item: any) => {
      return {
        value: item.position_id,
        label: EmployeeModel.getPositionList(item.position_code),
      };
    });

    setPositions(data);
    return data;
  };

  useEffect(() => {
    getBankList();
    getTypeWorks();
    getPositions();
    convertArrayToContract(model.contract);
  }, []);

  return (
    <Spin spinning={isLoadingSettingEditProfileDays}>
      <ProfileEditorHeader />
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={
          isEditing
            ? t("employee:form.success.update")
            : t("employee:form.success.add")
        }
        // redirectOnSuccess="/"
        error_translate_prefix="employee:form.error"
        onSubmit={onSubmit}
        submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
        sidebarItems={sidebarItems}
        submitDisabled={isDisabledAfterDaysSetting}
      >
        <FormSection
          title={t("employee:form.section_account")}
          subtitle={t("employee:form.section_account_subtitle")}
          className="bg-green-50 rounded-sm p-6 pb-4 -m-6 mb-6 border-b border-gray-100"
          gutter={0}
        >
          <Row gutter={16} className="pl-1.5">
            <Col md={12} xs={24}>
              <Form.Item
                label={t("employee:internal_id")}
                name="internal_id"
                // tooltip={t("employee:internal_id_help")}
                rules={[
                  {
                    required: true,
                    message: t("employee:form.error.error_internalid_required"),
                  },
                ]}
              >
                <Input size="large" disabled />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item label={t("common:password")} required>
                <Button
                  size="large"
                  type="primary"
                  ghost
                  icon={<IconPencil size="14" className="mr-2" />}
                  onClick={() => setShowPasswordFields(!showPasswordFields)}
                >
                  {t("user:profile.change_password")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </FormSection>

        <FormSection
          title={t("employee:form.section_contact")}
          subtitle={t("employee:form.section_contact_subtitle")}
          divider
        >
          <Row gutter={16}>
            <Col md={8} xs={24}>
              <Form.Item
                label={t("common:full_name")}
                name="full_name"
                rules={[
                  {
                    required: true,
                    message: t("common:error.required_full_name"),
                  },
                ]}
              >
                <Input disabled={isDisabledAfterDaysSetting} />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label={t("employee:birthday")}
                name="birthday"
                // rules={[
                //   {
                //     required: true,
                //     message: t("employee:form.error.error_birthday_required"),
                //   },
                // ]}
              >
                <DatePicker
                  // defaultValue={moment(new Date())}
                  className="w-full"
                  placeholder={"dd/mm/yyyy"}
                  format={"DD/MM/YYYY"}
                  disabled={isDisabledAfterDaysSetting}
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <FormSelect
                showSearch
                // required
                label={t("common:gender")}
                name="gender"
                options={EmployeeModel.getGenderList()}
                // rules={[
                //   {
                //     required: true,
                //     message: t("common:error.required_gender"),
                //   },
                // ]}
                disabled={isDisabledAfterDaysSetting}
              />
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                // label={t("common:phone")}
                label={t("employee:phone")}
                name="personal_phone"
                rules={[
                  {
                    validator(_, value: string) {
                      if (value.length > 0) {
                        if (Helper.validatePhoneNumber(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error(t("common:error.error_phone_format"))
                          );
                        }
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input disabled={isDisabledAfterDaysSetting} />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                label={t("employee:personal_email")}
                // required
                name="personal_email"
                rules={[
                  {
                    validator(_, value: string) {
                      if (value.length > 0) {
                        if (Helper.validateEmail(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error(t("common:error.error_email_format"))
                          );
                        }
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input disabled={isDisabledAfterDaysSetting} />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label={t("employee:address")}
                name="address"
                // rules={[
                //   {
                //     required: true,
                //     message: t("employee:form.error.error_phone_required"),
                //   },
                // ]}
              >
                <Input disabled={isDisabledAfterDaysSetting} />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                label={t("employee:permanent_address")}
                name="permanent_address"
                // rules={[
                //   {
                //     required: true,
                //     message: t("employee:form.error.error_phone_required"),
                //   },
                // ]}
              >
                <Input disabled={isDisabledAfterDaysSetting} />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <FormSelect
                showSearch
                // required
                label={t("employee:marital_status")}
                name="marital_status"
                options={EmployeeModel.getMaritalStatusList()}
                // rules={[
                //   {
                //     required: true,
                //     message: t("employee:form.error.error_marital_status"),
                //   },
                // ]}
                disabled={isDisabledAfterDaysSetting}
              />
            </Col>

            {/* <Col md={8} xs={24}>
              <FormSelect
                showSearch
                // required
                label={t("Loại thanh toán lương")}
                name="paytype"
                options={[
                  { label: "Qua thẻ", value: 0 },
                  { label: "Tiền mặt", value: 1 },
                ]}
              />
            </Col> */}

            <Col md={8} xs={24}>
              <FormSelect
                showSearch
                // required
                label={t("employee:bank_id")}
                name="bank_id"
                options={bankList}
                // rules={[
                //   {
                //     required: true,
                //     message: t("employee:form.error.error_bank_id_required"),
                //   },
                // ]}
                disabled={isDisabledAfterDaysSetting}
              />
            </Col>

            {/* <Col md={8} xs={24}>
              <Form.Item
                label={t("employee:date_start")}
                name="date_start"
                rules={[
                  {
                    required: true,
                    message: t("employee:form.error.error_date_start_required"),
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder={"dd/mm/yyyy"}
                  format={"DD/MM/YYYY"}
                />
              </Form.Item>
            </Col> */}

            <Col md={8} xs={24}>
              <Form.Item
                label={t("employee:bank_number")}
                name="bank_number"
                // rules={[
                //   {
                //     required: true,
                //     message: t(
                //       "employee:form.error.error_bank_number_required"
                //     ),
                //   },
                // ]}
              >
                <Input disabled={isDisabledAfterDaysSetting} />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item label={t("employee:insurance")} name="insurance">
                <Input disabled={isDisabledAfterDaysSetting} />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item label={t("employee:tax")} name="tax">
                <Input disabled={isDisabledAfterDaysSetting} />
              </Form.Item>
            </Col>

            {/* <Col md={8} xs={24}>
              <Form.Item label={t("Số người phụ thuộc")} name="person">
                <Input />
              </Form.Item>
            </Col> */}

            {/* <Col md={8} xs={24}>
              <Form.Item label={t("Trình độ học vấn")} name="learn">
                <Input />
              </Form.Item>
            </Col> */}

            {/* <Col md={8} xs={24}>
              <Form.Item
                label={t("Trường")}
                name="school"
                // rules={[
                //   {
                //     required: true,
                //     message: t("employee:form.error.error_date_start_required"),
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </Col> */}

            {/* <Col md={8} xs={24}>
              <Form.Item label={t("Chuyên ngành")} name="learning">
                <Input />
              </Form.Item>
            </Col> */}

            <Col md={8} xs={24}>
              <Form.Item label={t("employee:cccd")} name="cccd">
                <Input disabled={isDisabledAfterDaysSetting} />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                label={t("employee:cccd_issue_day")}
                name="cccd_issue_day"
                // rules={[
                //   {
                //     required: true,
                //     message: t("employee:form.error.error_date_start_required"),
                //   },
                // ]}
              >
                <DatePicker
                  className="w-full"
                  placeholder={"dd/mm/yyyy"}
                  format={"DD/MM/YYYY"}
                  disabled={isDisabledAfterDaysSetting}
                />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item label={t("employee:cccd_publish")} name="cccd_publish">
                <Input disabled={isDisabledAfterDaysSetting} />
              </Form.Item>
            </Col>
          </Row>

          {/* <EmployeeFormExperience isEditing={true} /> */}
        </FormSection>

        <FormSection
          title={t("employee:form.section_more")}
          subtitle={t("employee:form.section_more_subtitle")}
          divider
        >
          <Row gutter={16}>
            <Col md={8} xs={24}>
              {/* <FormSelect
                required
                showSearch
                label={t("employee:office")}
                name="office_id"
                placeholder="Chọn văn phòng"
                options={officeItems}
                rules={[
                  {
                    required: true,
                    message: t("employee:form.error.required_office"),
                  },
                ]}
              /> */}
              <OfficeFormSelect
                label={t("employee:office")}
                name="office_id"
                placeholder="Chọn văn phòng"
                disabled={true}
              />
            </Col>

            <Col md={8} xs={24}>
              <FormSelect
                showSearch
                label={t("employee:type")}
                name="type"
                disabled={true}
                // options={await new TypeOfWorkRepository.get}
                options={typeWorks}
                // options={EmployeeModel.getTypeList()}
              />
            </Col>
            <Col md={8} xs={24}>
              <DepartmentFormSelect
                label={t("employee:department")}
                name="department_id"
                disabled={true}
              />
            </Col>

            <Col md={8} xs={24}>
              <FormSelect
                showSearch
                label={t("employee:position")}
                name="position_id"
                options={positions}
                disabled={true}
              />
              {/* <PositionFormSelect
                label={t("employee:position")}
                name="position_id"
                placeholder="Chọn văn phòng"
              /> */}
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                label={t("employee:job_title")}
                name="job_title"
                rules={[
                  {
                    required: true,
                    message: t("employee:form.error.required_job_title"),
                  },
                ]}
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label={t("employee:email")}
                name="email"
                // rules={[
                //   {
                //     required: true,
                //     message: t("common:error.required_email"),
                //   },
                // ]}
                // tooltip={t("employee:form.email_extra")}
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                label={t("employee:work_phone")}
                name="phone"
                // rules={[
                //   {
                //     required: true,
                //     message: t("employee:form.error.required_job_title"),
                //   },
                // ]}
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                label={t("employee:date_start")}
                name="date_start"
                // rules={[
                //   {
                //     required: true,
                //     message: t("employee:form.error.error_date_start_required"),
                //   },
                // ]}
              >
                <DatePicker
                  disabled
                  className="w-full"
                  placeholder={"dd/mm/yyyy"}
                  format={"DD/MM/YYYY"}
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label={t("employee:contract_code")}
                name="contract_code"
              >
                <Input disabled />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item label={t("employee:salary_offer")} name="salary_offer">
                <Input.Password
                  placeholder="input password"
                  addonAfter="VND"
                  readOnly
                  disabled={isDisabledAfterDaysSetting}
                />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item label={t("employee:basic_salary")} name="basic_salary">
                <Input.Password
                  placeholder="input password"
                  addonAfter="VND"
                  readOnly
                  disabled={isDisabledAfterDaysSetting}
                />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>

        <FormSection
          title={t("employee:form.section_contract")}
          subtitle={t("employee:form.section_contract_subtitle")}
          divider
        >
          {/* <Row gutter={16}>
            <Col md={8} xs={24}>
              <FormSelect
                showSearch
                // required
                label={t("Tình trạng hồ sơ")}
                name="hsstatus"
                options={[
                  { label: "Hoàn tất", value: 0 },
                  { label: "Thiếu", value: 1 },
                ]}
              />
            </Col>
          </Row> */}
          <Collapse>
            <Panel
              header={
                <p>
                  Tình trạng hồ sơ:{" "}
                  {/* <span className="text-green-500 font-semibold">Hoàn tất</span> */}
                  {contractCompleted ? (
                    <span className="text-green-500 font-semibold">
                      Hoàn tất
                    </span>
                  ) : (
                    <span className="text-red-500 font-semibold">Thiếu</span>
                  )}
                </p>
              }
              key="1"
            >
              <Row gutter={16}>
                <Col md={8} xs={24}>
                  <Form.Item name="syll" valuePropName="checked">
                    <Checkbox disabled>{t("employee:syll")}</Checkbox>
                  </Form.Item>
                </Col>

                <Col md={8} xs={24}>
                  <Form.Item name="cmtnd" valuePropName="checked">
                    <Checkbox disabled>{t("employee:cmtnd")}</Checkbox>
                  </Form.Item>
                </Col>

                {/* <Col md={8} xs={24}>
                  <Form.Item name="gks" valuePropName="checked">
                    <Checkbox>{t("employee:gks")}</Checkbox>
                  </Form.Item>
                </Col>

                <Col md={8} xs={24}>
                  <Form.Item name="shk" valuePropName="checked">
                    <Checkbox>{t("employee:shk")}</Checkbox>
                  </Form.Item>
                </Col> */}

                <Col md={8} xs={24}>
                  <Form.Item name="picture" valuePropName="checked">
                    <Checkbox disabled>{t("employee:picture")}</Checkbox>
                  </Form.Item>
                </Col>

                <Col md={8} xs={24}>
                  <Form.Item name="btnn" valuePropName="checked">
                    <Checkbox disabled>{t("employee:btnn")}</Checkbox>
                  </Form.Item>
                </Col>

                <Col md={8} xs={24}>
                  <Form.Item name="gksk" valuePropName="checked">
                    <Checkbox disabled>{t("employee:gksk")}</Checkbox>
                  </Form.Item>
                </Col>

                <Col md={8} xs={24}>
                  <Form.Item name="dxv" valuePropName="checked">
                    <Checkbox disabled>{t("employee:dxv")}</Checkbox>
                  </Form.Item>
                </Col>

                <Col md={8} xs={24}>
                  <Form.Item name="hddk" valuePropName="checked">
                    <Checkbox disabled>{t("employee:hddk")}</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </FormSection>

        <FormSection
          title={t("employee:form.section_relative")}
          subtitle={t("employee:form.section_relative_subtitle")}
          // divider
        >
          <Row gutter={16}>
            <Col md={8} xs={24}>
              <Form.Item
                label={t("employee:relatives_name")}
                name="relatives_name"
                // rules={[
                //   {
                //     required: true,
                //     message: t("employee:form.error.error_phone_required"),
                //   },
                // ]}
              >
                <Input disabled={isDisabledAfterDaysSetting} />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                label={t("employee:relatives_phone")}
                name="relatives_phone"
                // rules={[
                //   {
                //     required: true,
                //     message: t("employee:form.error.error_phone_required"),
                //   },
                // ]}
              >
                <Input disabled={isDisabledAfterDaysSetting} />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label={t("employee:relationship")}
                name="relationship"
                // rules={[
                //   {
                //     required: true,
                //     message: t("employee:form.error.error_phone_required"),
                //   },
                // ]}
              >
                <Input disabled={isDisabledAfterDaysSetting} />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>
      </LayoutForm>

      <ProfilePasswordModal
        model={new AccountModel(account)}
        visible={showPasswordFields}
        setVisible={(isVisible) => {
          setShowPasswordFields(isVisible);
        }}
      />
    </Spin>
  );
};

export default ProfileEditor;
