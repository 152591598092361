import AppProvider from "AppProvider";
import AppRoute from "AppRoute";
import AppSocket from "AppSocket";
import { useEffect } from "react";
import { HashRouter as Router } from "react-router-dom";

const App = () => {
	useEffect(() => {
		if (process.env.NODE_ENV === "production") {
			console.warn = function () {};
			console.error = function () {};
			console.log = function () {};
		}
	}, []);
	return (
		<Router>
			<AppSocket>
				<AppProvider>
					<AppRoute />
				</AppProvider>
			</AppSocket>
		</Router>
	);
};

export default App;
