import {
	Button,
	Card,
	Checkbox,
	Col,
	ColorPicker,
	DatePicker,
	Form,
	Input,
	Popconfirm,
	PopconfirmProps,
	Radio,
	Row,
	Select,
} from "antd";
import { ZoneDataProductBD } from "common/types/Event";
import dayjs from "dayjs";
import Helper from "common/utils/helper";
import { CloseOutlined, EditFilled, EyeFilled } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { Modal } from "antd";
import { useDebounce } from "rooks";
import { Spin, Table, notification } from "antd";
import { TableColumnsType } from "common/types/Table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ProductCollectionRepository from "common/repositories/ProductCollectionRepository";
import ProductCollectionModel from "common/models/ProductCollectionModel";
import moment from "moment";
import { Link } from "react-router-dom";
import { FormListFieldData } from "antd/lib/form";
import ProductCollectionProductModal from "features/productcollection/product/ProductCollectionProductModal";
import UploadEvent from "../UploadEvent";
import { FormInstance } from "antd";
export default function SectionProductly({
	data,
	onSubmit,
}: {
	data: ZoneDataProductBD[];
	onSubmit: (data: ZoneDataProductBD[]) => void;
}) {
	const [form] = useForm();

	const defaultValue = {
		show: 1,
		load_number: 0,
		available_type: [[]],
		params: {
			collection_id: "",
			sort_by: "display_order",
			sort_type: "DESC",
			show_web: 1,
			show_promotion_price: 1,
			page: 1,
			limit: 8,
			in_stock: 1,
		},
		style: {
			bg: "#B0242C",
			button: "#A72631",
		},
		image_desktop: "",
		image_mobile: "",
		link: "",
	};

	const init = data.map((item) => ({
		...item,
		available_type: item.available_type.map((date) => {
			return [
				dayjs(date.from_time * 1000 || Date.now()),
				dayjs(date.to_time * 1000 || Date.now()),
			];
		}),
	}));

	const onFinish = (values: any) => {
		const value = values.items;
		const dataNew = value.map((item: any) => {
			const cols = item.params?.collection_id;
			return {
				...item,
				load_number: Number(item.load_number) || 0,
				params: {
					...item.params,
					sort_by: "display_order",
					sort_type: "DESC",
					show_promotion_price: 1,
					show_web: 1,
					page: 1,
					collection_id: Array.isArray(cols) ? cols.join(",") : cols,
					limit: Number(item?.params?.limit) || 0,
				},

				available_type: item.available_type.map((date: any) => {
					return {
						from_time: Helper.convertedDateToTimeStamp(date[0]),
						to_time: Helper.convertedDateToTimeStamp(date[1]),
					};
				}),
				image_desktop: item.image_desktop,
				image_mobile: item.image_mobile,
				link: item.link,
			};
		});
		onSubmit(dataNew);
	};
	return (
		<div>
			<Form
				labelCol={{ span: 6 }}
				wrapperCol={{ span: 18 }}
				form={form}
				name="dynamic_form_products"
				autoComplete="off"
				className=" relative pb-10"
				initialValues={{ items: init }}
				layout="vertical"
				onFinish={onFinish}>
				<Form.List name="items">
					{(fields, { add, remove }) => (
						<div
							style={{ display: "flex", rowGap: 16, flexDirection: "column" }}>
							<div className="   sticky w-full  -top-4 border rounded-md right-0 shadow   bg-white  z-10 left-0 py-2 px-10">
								<div className=" flex  justify-between items-center">
									<h4 className="  mb-0 text-right">
										{fields.length} chương trình
									</h4>
									<Button
										className="  w-[100px]"
										type="primary"
										htmlType="submit">
										Lưu
									</Button>
								</div>
							</div>

							{fields.map((field) => {
								return (
									<ProgramCard
										form={form}
										onUpdateSuccec={(ids, index) => {
											form.setFieldValue(
												["items", index, "params", "collection_id"],
												ids
											);
										}}
										remove={remove}
										field={field}
										key={field.key}
										data={data[field.key]}
									/>
								);
							})}

							<Button type="dashed" onClick={() => add(defaultValue)} block>
								+ Thêm chương trình
							</Button>
						</div>
					)}
				</Form.List>

				{/* <Form.Item noStyle shouldUpdate>
					{() => (
						<Typography>
							<pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>
						</Typography>
					)}
				</Form.Item> */}
			</Form>
		</div>
	);
}

function ProgramCard({
	field,
	remove,
	onUpdateSuccec,
	form,
	data,
}: {
	field: FormListFieldData;
	remove: (key: number) => void;
	onUpdateSuccec: (ids: string, index: number) => void;
	form: FormInstance;
	data: ZoneDataProductBD;
}) {
	const { RangePicker } = DatePicker;

	const [open, setOpen] = useState(false);
	const [isWrite, setIsWrite] = useState(false);

	const [openEditCol, setOpenEditCol] = useState(false);
	const [dataUpdate, setDataUpdate] = useState({
		ids: "",
		index: 0,
	});

	const [imgMobile, setImgMobile] = useState(data.image_mobile);
	const [imgDesktop, setImgDesktop] = useState(data.image_desktop);

	const rangeConfig = {
		rules: [
			{
				type: "array" as const,
				required: true,
				message: "Vui lòng chọn thời gian!",
			},
		],
	};

	const available_type = Form.useWatch(
		["items", field.name, "available_type"],
		form
	);

	const available_type1 = Form.useWatch(
		["items", field.name, "available_type"],
		form
	);
	const times = available_type?.[0];
	const dateFormat = "HH:mm:ss DD-MM-YYYY";
	const from_time = times ? times?.[0]?.format(dateFormat) : 0;

	const to_time = times ? times?.[1]?.format(dateFormat) : 0;

	const idColInit = Form.useWatch(
		["items", field.name, "params", "collection_id"],
		form
	);

	return (
		<>
			{/* <Button
				onClick={() => {
					form.setFieldValue("image_desktop", "123");
				}}>
				set
			</Button> */}
			<Card
				hoverable
				size="default"
				title={
					<span className=" text-blue-500">
						<b>{field.name + 1}</b> - Nhóm chạy từ {from_time ? from_time : "_"}{" "}
						đến {to_time ? to_time : "_"}
					</span>
				}
				key={field.key}
				extra={
					<Popconfirm
						title="Xóa sản phẩm"
						description="Xóa nhóm sản phẩm này ?"
						onConfirm={() => {
							remove(field.name);
						}}
						okText="Đồng ý"
						cancelText="Hủy">
						<CloseOutlined />
					</Popconfirm>
				}>
				<Row gutter={[10, 10]}>
					<Col span={12}>
						<div className="">
							<Checkbox
								checked={isWrite}
								onChange={(val) => {
									setIsWrite(val.target.checked);
								}}>
								Set tay
							</Checkbox>
							<Form.Item
								labelCol={{ span: 24 }}
								className="mb-4"
								rules={[
									{
										required: true,
										message: "Yêu cầu nhập",
									},
								]}
								label="Chọn nhóm hàng"
								name={[field.name, "params", "collection_id"]}>
								{isWrite ? (
									<Input></Input>
								) : (
									<Select
										disabled
										// showSearch
										placeholder="Chọn nhóm hàng"
										// onChange={handleChange}
										style={{ width: "100%" }}
									/>
								)}
							</Form.Item>
							{!isWrite && (
								<div className=" flex gap-2">
									<Button
										className=" w-fit"
										onClick={() => {
											// setIdCol()
											setOpenEditCol(true);
										}}
										icon={<EyeFilled />}></Button>
									<Button
										className=" w-fit"
										onClick={() => {
											setDataUpdate({
												ids: idColInit || "",
												index: field.name,
											});
											setOpen(true);
										}}
										icon={<EditFilled />}></Button>
								</div>
							)}
						</div>
					</Col>
					<Col span={12}>
						<Form.Item
							labelCol={{ span: 24 }}
							className="mb-4"
							rules={[
								{
									required: true,
									message: "Yêu cầu nhập",
								},
							]}
							label="Số lượng sản phẩm hiển thị"
							name={[field.name, "params", "limit"]}>
							<Input type="number" size="middle"></Input>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item
							labelCol={{ span: 24 }}
							className="mb-4"
							rules={[
								{
									required: true,
									message: "Yêu cầu nhập",
								},
							]}
							label="Ẩn & hiện chương trình"
							name={[field.name, "show"]}>
							<Select
								options={[
									{ label: "Hiện", value: 1 },
									{ label: "Ẩn", value: 0 },
								]}></Select>
						</Form.Item>
					</Col>
					<Col span={6} className=" hidden">
						<Form.Item
							labelCol={{ span: 24 }}
							className="mb-4"
							label="Số lượng sản phẩm tải thêm"
							name={[field.name, "load_number"]}>
							<Input type="number" size="middle"></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							labelCol={{ span: 24 }}
							label="Mốc thời gian chạy"
							{...rangeConfig}
							name={[field.name, "available_type", 0]}>
							<RangePicker showTime format={dateFormat} />
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item
							labelCol={{ span: 24 }}
							className="mb-4"
							rules={[
								{
									required: true,
									message: "Yêu cầu nhập",
								},
							]}
							label="Hiển thị sản phẩm hết hàng"
							name={[field.name, "params", "in_stock"]}>
							<Select
								options={[
									{ label: "Hiện", value: 0 },
									{ label: "Ẩn", value: 1 },
								]}></Select>
						</Form.Item>
					</Col>

					<Col span={6}>
						<Form.Item
							name={[field.name, "style", "bg"]}
							className="mb-4"
							label="Màu nền"
							labelCol={{ span: 24 }}
							getValueFromEvent={(color) => {
								return "#" + color.toHex();
							}}>
							<ColorPicker />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							labelCol={{ span: 24 }}
							label="Màu nền nút bấm"
							name={[field.name, "style", "button"]}
							className="mb-4"
							getValueFromEvent={(color) => {
								return "#" + color.toHex();
							}}>
							<ColorPicker />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							labelCol={{ span: 24 }}
							label="Chọn các theo các mã sku (chỉ IT nhập)"
							name={[field.name, "params", "sku"]}
							className="mb-4">
							<Input></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							labelCol={{ span: 24 }}
							label="Ẩn/Hiện Countdown time"
							name={[field.name, "show_time"]}
							className="mb-4">
							<Select
								options={[
									{
										label: "Hiện",
										value: 1,
									},
									{
										label: "Ẩn",
										value: 0,
									},
								]}></Select>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Row>
							<Col span={12}>
								<UploadEvent
									defaultUrl={imgDesktop}
									height={150}
									onSuccess={(url) => {
										form.setFieldValue(
											["items", field.name, "image_desktop"],
											url
										);

										setImgDesktop(url);
									}}></UploadEvent>
							</Col>

							<Col span={12}>
								<UploadEvent
									defaultUrl={imgMobile}
									height={150}
									onSuccess={(url) => {
										form.setFieldValue(
											["items", field.name, "image_mobile"],
											url
										);

										setImgMobile(url);
									}}></UploadEvent>
							</Col>
							<Col span={24}>
								<Form.Item
									name={[field.name, "link"]}
									className="mb-4"
									label="Link"
									labelCol={{ span: 24 }}>
									<Input></Input>
								</Form.Item>
							</Col>
						</Row>
					</Col>
				</Row>
			</Card>

			<Modal
				open={open}
				onCancel={() => setOpen(false)}
				closeIcon={null}
				footer={null}>
				<CollectionSelect
					onUpdateSuccec={(ids, isNoCLose) => {
						onUpdateSuccec(ids, dataUpdate.index);
						if (!isNoCLose) {
							setOpen(false);
						}
					}}
					collection_list={
						dataUpdate.ids.length ? dataUpdate.ids.split(",").map(Number) : []
					}
				/>
			</Modal>

			{Number(idColInit) ? (
				<ProductCollectionProductModal
					open={openEditCol}
					id={Number(idColInit)}
					setOpen={() => setOpenEditCol(false)}
					onSuccsec={() => {}}
				/>
			) : null}
		</>
	);
}

function CollectionSelect({
	collection_list,
	onUpdateSuccec,
}: {
	collection_list: number[];
	onUpdateSuccec: (col_ids: string, isNoCLose?: boolean) => void;
}) {
	const [collectionIds, setCollectionIds] = useState(collection_list);

	const [loading, setLoading] = useState(true);
	const { t } = useTranslation();
	const [spinning, setSpinning] = React.useState(false);
	const [inputKeyword, setInputKeyword] = useState("");
	//fetch PromotionProduct
	const setValueDebounced = useDebounce(setInputKeyword, 500);
	const [dataSource, setDataSource] = useState<ProductCollectionModel[]>([]);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(0);

	const filter = useMemo(() => {
		return {
			...ProductCollectionRepository.getDefaultFilters(),
			page: page,
			limit: 10,
			keyword: inputKeyword,
		};
	}, [inputKeyword, page]);

	const fetchDataCollectionProduct = useCallback(async () => {
		const collection = await new ProductCollectionRepository().getItems({
			filters: {
				...filter,
			},
		});
		if (!collection.hasError()) {
			setDataSource(collection.items);
			setTotal(collection.total);
			setLoading(false);
		} else {
			notification.error({
				message: "Có lỗi vui lòng thử lại",
			});
		}
	}, [filter]);

	async function onSubmit() {
		onUpdateSuccec?.(collectionIds.join(","));
	}

	useEffect(() => {
		fetchDataCollectionProduct();
	}, [fetchDataCollectionProduct]);

	const columns: TableColumnsType<ProductCollectionModel> = [
		{
			title: "Chọn",
			key: "",
			dataIndex: "",
			width: 100,
			render: (_, record) => {
				return (
					<Radio
						id={record.id.toString()}
						checked={collectionIds.some((col) => col === record.id)}
						onChange={(e) => {
							setCollectionIds((pre) => [record.id]);
							// if (collectionIds.length) {
							// 	return
							// }

							// const value = e.target.checked;
							// if (value) {
							// } else {
							// 	setCollectionIds((pre) => pre.filter((id) => id !== record.id));
							// }
						}}
					/>
				);
			},
		},
		{
			title: t("id"),
			key: "id",
			render: (_: any, record: ProductCollectionModel) => {
				return (
					<Link
						target="_blank"
						to={`/productcollection?keyword=${record.title}`}>
						{record.id}
					</Link>
				);
			},
		},
		{
			title: t("Tên nhóm hàng"),
			key: "name",
			render: (_: any, record: ProductCollectionModel) => {
				return (
					<Link
						target="_blank"
						to={`/productcollection?keyword=${record.title}`}>
						{record.title}
					</Link>
				);
			},
		},
		{
			title: t("Ngày tạo"),
			key: "date_created",
			width: 150,
			render: (_: any, record: ProductCollectionModel) => {
				return (
					<p>{moment.unix(record.date_created).format("DD/MM/YYYY HH:mm")}</p>
				);
			},
		},
	];
	const confirm: PopconfirmProps["onConfirm"] = (e) => {
		onUpdateSuccec?.("", true);
	};
	return (
		<>
			<Spin spinning={spinning} delay={500}>
				<p>
					Đã chọn {collectionIds.length} / {total} nhóm hàng
				</p>
				{/* <div className=" my-4 flex justify-end">
					<Popconfirm
						title="Bỏ chọn tất cả"
						description="Xác nhận bỏ chọn tất cả"
						onConfirm={confirm}
						okText="Yes"
						cancelText="No"
					>
						<Button danger >Bỏ chọn tất cả</Button>
					</Popconfirm>
				</div> */}
				<div className=" mb-4">
					<Input
						placeholder="Tìm kiếm nhóm hàng"
						size="middle"
						onChange={(e) => {
							setValueDebounced(e.target.value);
							setPage(1);
						}}
					/>
				</div>

				<Table
					size="small"
					className="table-dashboard"
					dataSource={dataSource}
					columns={columns}
					rowKey={"id"}
					bordered
					loading={loading}
					pagination={{
						hideOnSinglePage: true,
						current: page,
						total: total,
						onChange: (page) => {
							setPage(page);
						},
					}}
					footer={() => (
						<div className=" flex justify-end">
							<Button onClick={onSubmit} type="primary">
								Lưu
							</Button>
						</div>
					)}
				/>
			</Spin>
		</>
	);
}
