import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Image, Tag, Typography } from "antd";

import PageDataTable from "components/page/PageDataTable";
import useStateFilter from "hooks/useStateFilter";
import useFilterLocation from "hooks/useFilterLocation";

import PageDataPagination from "components/page/PageDataPagination";
import TextDateTime from "components/TextDateTime";
import IdFetcherRender from "components/idfetcher/IdFetcherRender";

import { CheckinJson, FilterCheckin } from "common/types/Checkin";
import { IdFetcherResult } from "common/types/IdFetcher";

import CheckinRepository from "common/repositories/CheckinRepository";
import CheckinModel from "common/models/CheckinModel";
import CheckinCollection from "common/collections/CheckinCollection";

import CheckinListFilter from "./CheckinListFilter";
import CheckinListHeader from "./CheckinListHeader";
import useIdFetcher from "hooks/useIdFetcher";
import { IconMapPin } from "@tabler/icons-react";
import FileModel from "common/models/FileModel";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import CheckinMineListFilter from "./CheckinMineListFilter";
import { TableColumnsType } from "common/types/Table";
import CheckinMineListHeader from "./CheckinMineListHeader";
import useDatabaseTable from "hooks/useDatabaseTable";
import { EmployeeJson } from "common/types/Employee";
import { DepartmentJson } from "common/types/Department";

const CheckinMineList = () => {
	const { t } = useTranslation();

	const { account } = useLoginAccountStore();

	const [employeeItems] = useDatabaseTable<EmployeeJson>("employee");

	////////////////////////
	// modal editing
	const [addVisible, setAddVisible] = useState(false);
	const [editingId, setEditingId] = useState(0);

	////////////////////////
	// default filter
	const defaultFilters = useMemo(() => {
		return CheckinRepository.getDefaultFilters();
	}, []);
	const now = new Date();

	const day = now.getDate();
	const month: number = now.getMonth();
	const year = now.getFullYear();
	const dateNow = Number(new Date(year, month, day)) / 1000;

	///////////////////////
	//state
	const [total, setTotal] = useState<number>(0);
	const [dataSource, setDataSource] = useState<CheckinModel[]>([]);

	const [filters, setFilters] = useStateFilter({
		...defaultFilters,
		date_created: dateNow,
	});
	useFilterLocation(defaultFilters, filters);

	//Table columns
	const columns: TableColumnsType<CheckinModel> = [
		{
			title: t("common:columns.id"),
			key: "id",
			toggletype: "ignore",
			width: 100,
		},
		{
			title: t("checkin:full_name"),
			key: "full_name",
			fixed: "left",
			align: "center",
			width: 120,
			render: (_, record) => {
				return (
					<Typography.Text>
						{employeeItems.find((i) => i.id === record.employee_id)?.full_name}
					</Typography.Text>
				);
			},
		},
		{
			title: t("common:columns.internal_id"),
			key: "id",
			toggletype: "extend",
			width: 100,
			render: (_, record) => {
				return (
					<Tag>
						{
							employeeItems.find((i) => i.id === record.employee_id)
								?.internal_id
						}
					</Tag>
				);
			},
		},

		{
			title: t("checkin:job_title"),
			key: "job_title",
			toggletype: "extend",

			width: 100,
			render: (_, record) => {
				return (
					<Tag>
						{employeeItems.find((i) => i.id === record.employee_id)?.job_title}
					</Tag>
				);
			},
		},

		{
			title: t("checkin:type"),
			key: "type",
			width: 120,
			render: (type: number) => {
				return CheckinModel.getCheckinType(type)?.label;
			},
		},
		{
			title: t("checkin:time_checkin"),
			key: "datecreated",
			width: 150,

			render: (datecreated) => {
				return (
					<TextDateTime
						format="HH:mm, DD/MM/YYYY"
						ts={datecreated}></TextDateTime>
				);
			},
		},
		{
			key: "latitude",
			title: t("checkin:latitude"),
			width: 100,
			toggletype: "extend",

			render: (_: any, record: CheckinModel) => {
				return (
					<>
						<Typography.Link
							href={`https://maps.google.com/?q=${record.latitude},${record.longitude}`}
							target="_blank">
							<IconMapPin />
						</Typography.Link>
					</>
				);
			},
		},
		{
			key: "image",
			title: t("checkin:image"),
			align: "center",
			width: 120,
			render: (_, record) => {
				if (record.image.id <= 0) {
					return <Tag>Trống</Tag>;
				}
				return (
					<Image
						width={120}
						height={120}
						src={FileModel.getThumbnailFromUrl(
							record.image.file_path,
							// 1080,
							1080,
							1080,
							"resize"
						)}></Image>
				);
			},
		},

		// {
		//   title: "",
		//   key: "actions",
		//   width: 100,
		//   align: "right",
		//   fixed: "right",
		//   render: (_: any, record: CheckinJson) => (
		//     <RoleCheck roles={[Role.SETTING_HRM]} hideOnFail>
		//       <TableEdit link="" onClick={() => onEdit(record.id)} />
		//       <TableDelete
		//         error_translate_prefix="department:form.error"
		//         onDeleteCallback={(id) => {
		//           fetchData();
		//         }}
		//         repository={new CheckinRepository()}
		//         id={record.id}
		//       />
		//       <TableInfo record={record} />
		//     </RoleCheck>
		//   ),
		// },
	];

	///////////////////////
	//function
	const fetchData = useCallback(async (): Promise<CheckinCollection> => {
		const response = await new CheckinRepository().getMineItems({
			filters: {
				...filters,
				employee_id: account.company.employee.id,
			},
		});
		setTotal(response.total);
		setDataSource(response.items);

		return response;
	}, [filters]);

	return (
		<div>
			<CheckinMineListHeader></CheckinMineListHeader>
			<CheckinMineListFilter
				total={total}
				filters={filters}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
			/>

			<PageDataTable<FilterCheckin, CheckinModel, CheckinCollection>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					tableColumnToggleKey: "checkinmine",
				}}></PageDataTable>
			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>
		</div>
	);
};

export default CheckinMineList;
