import { FormLabelAlign } from "antd/es/form/interface";
import { SelectValue } from "antd/lib/select";
import WarehouseRepository from "common/repositories/WarehouseRepository";
import { SelectOption } from "common/types/SelectOption";
import FormSelect from "components/form/FormSelect";
import { useCallback, useEffect, useState } from "react";

const WarehouseFormFilter = ({
  label,
  name,
  disabled,
  value,
  onChange,
  labelAlign,
  required,
  allowClear,
  defaultOptions,
  placeholder,
  popupMatchSelectWidth,
  className,
  multiple,
  defaultValue,
  warehouseIds,
}: {
  label?: string;
  name: string;
  disabled?: boolean;
  value?: SelectValue;
  onChange?: (v: SelectValue) => void;
  labelAlign?: FormLabelAlign;
  required?: boolean;
  allowClear?: boolean;
  defaultOptions?: SelectOption[];
  placeholder?: string;
  popupMatchSelectWidth?: boolean;
  className?: string;
  multiple?: boolean;
  defaultValue?: SelectValue;
  warehouseIds?: number[];
}) => {
	const [errors, setErrors] = useState<string[]>([]);
	const [loading, setLoading] = useState(true);
	const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const collection = await new WarehouseRepository().getItems({
      filters: {
        ...WarehouseRepository.getDefaultFilters(),
        ids: warehouseIds && warehouseIds.length > 0 ? warehouseIds.join(",") : "",
      },
    });
    if (collection.hasError()) {
      setErrors(collection.error.errors);
    } else {
      setOptions(
        collection.items.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      );
    }
    setLoading(false);
  }, [warehouseIds]);

	useEffect(() => {
		if (defaultOptions && defaultOptions.length > 0) {
			setOptions(defaultOptions);
		}
	}, [defaultOptions]);

  useEffect(() => {
    if (typeof defaultOptions === "undefined") {
			fetchData();
		} else {
			setLoading(false);
		}
  }, [fetchData, defaultOptions, warehouseIds]);

	return (
		<FormSelect
			className={className}
			defaultValue={defaultValue}
			disabled={disabled}
			required={required}
			loading={loading}
			showSearch={options.length > 7}
			label={label}
			name={name}
			options={options}
			errors={errors}
			errorRetry={fetchData}
			value={value}
			onChange={onChange}
			labelAlign={labelAlign}
			allowClear={allowClear}
			placeholder={placeholder}
			multiple={multiple}
			popupMatchSelectWidth={popupMatchSelectWidth}
		/>
	);
};

export default WarehouseFormFilter;
