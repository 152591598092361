import { Col, Row, Spin } from "antd";
import StoreWarehouseRepository from "common/repositories/StoreWarehouseRepository";
import WarehouseFormFilter from "features/warehouse/WarehouseFormFilter";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import StoreFormSelect from "./StoreFormSelect";
import StoreModel from "common/models/StoreModel";
import WarehouseModel from "common/models/WarehouseModel";

const StoreWarehouseFormSelect = forwardRef(({
  filters,
  storeName,
  warehouseName,
  disabled,
  allowClear,
  popupMatchSelectWidth,
  className,
}: {
  filters: any;
  storeName: string;
  warehouseName: string;
  disabled?: boolean;
  allowClear?: boolean;
  popupMatchSelectWidth?: boolean;
  className?: string;
}, ref) => {
  const [storeId, setStoreId] = useState(filters[storeName]);
  const [isLoading, setIsLoading] = useState(false);
  const [warehouseIds, setWarehouseIds] = useState<number[]>([]);

  const [storeSource, setStoreSource] = useState<StoreModel[]>([]);
  const [warehouseSource, setWarehouseSource] = useState<WarehouseModel[]>([]);

  useImperativeHandle(ref, () => ({
    setStoreSource,
    setWarehouseSource,
  }), []);

  const fetchStoreWarehouseData = useCallback(async () => {
		setIsLoading(true);
		const collection = await new StoreWarehouseRepository().getItems({
			filters: {
        ...StoreWarehouseRepository.getDefaultFilters(),
        limit: 50,
        store_id: storeId && storeId > 0 ? storeId : -1,
      },
		});
    setWarehouseIds(collection.items.map((item) => item.warehouse_id));
		setIsLoading(false);
	}, [storeId]);

  useEffect(() => {
    if (storeId > -1) {
      fetchStoreWarehouseData();
    } else {
      setWarehouseIds([]);
    }
  }, [storeId, fetchStoreWarehouseData]);

  useEffect(() => {
    if (filters[storeName] === -1) {
      setStoreId(filters[storeName]);
    }
  }, [filters, storeName]);

  const storeDefaultOptions = useMemo(() => storeSource.length ? storeSource.map((item) => ({
    value: item.id,
    label: item.name,
  })) : undefined, [storeSource]);

  const warehouseDefaultOptions = useMemo(() => warehouseSource.length ? warehouseSource.map((item) => ({
    value: item.id,
    label: item.name,
  })) : undefined, [warehouseSource]);

  return (
    <Spin spinning={isLoading}>
      <Row className={`bg-white border border-[#d9d9d9] rounded-sm me-4 [&_.ant-form-item]:me-0 [&_.ant-select-selector]:!border-0 [&_.ant-select-selector]:!rounded-none ${className}`}>
        <Col span={12}>
          <StoreFormSelect
            name={storeName}
            placeholder="Cửa hàng"
            allowClear={allowClear}
            popupMatchSelectWidth={popupMatchSelectWidth}
            disabled={disabled}
            onChange={setStoreId}
            defaultOptions={storeDefaultOptions}
          />
        </Col>
        <Col span={12}>
          <WarehouseFormFilter
            name={warehouseName}
            placeholder="Nhà kho"
            allowClear={allowClear}
            popupMatchSelectWidth={popupMatchSelectWidth}
            disabled={disabled}
            warehouseIds={warehouseIds}
            defaultOptions={warehouseDefaultOptions}
          />
        </Col>
      </Row>
    </Spin>
  );
});

export default StoreWarehouseFormSelect;
