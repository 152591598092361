import { IconX } from "@tabler/icons-react";
import { Checkbox, Divider, List, Skeleton, Tag } from "antd";
import TagConstant from "common/constants/Tag";
import ProductRepository from "common/repositories/ProductRepository";
import TagRepository from "common/repositories/TagRepository";
import { ProductCategoryJson } from "common/types/ProductCategory";
import { SelectOption } from "common/types/SelectOption";
import PageDataPagination from "components/page/PageDataPagination";
import useDatabaseTable from "hooks/useDatabaseTable";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import AddListToItemFilter from "./AddListToItemFilter";
import ProductCollectionRepository from "common/repositories/ProductCollectionRepository";
import { AddListToItemComponentType } from "./AddListToItemComponent";

type Props = {
	values?: number[];
	ignoreValues?: number[];
	multiple?: boolean;
	name?: string;
	placeholder?: string;
	isMulti?: boolean;
	className?: string;
	icon?: React.ReactNode;
	width?: number;
	selectedValue: SelectOption[];
	handleSelectedValue: (v: SelectOption[]) => void;
	handleRemoveItem: any;
	renderItems: any;
	reload: boolean;
	type: AddListToItemComponentType;
	store_id?: number;
	changeProcessing: (f: boolean) => void;
};

const SelectListComponent = ({
	values = [],
	icon,
	selectedValue,
	handleSelectedValue,
	ignoreValues,
	multiple,
	name,
	placeholder,
	isMulti,
	className,
	width,
	handleRemoveItem,
	renderItems,
	reload,
	type,
	store_id,
	changeProcessing,
}: Props) => {
	const [categoryItems] =
		useDatabaseTable<ProductCategoryJson>("productcategory");
	const defaultFilters: any = useMemo(() => {
		switch (type) {
			case "product":
				return {
					...ProductRepository.getDefaultFilters(),
					limit: 10,
					store_id: store_id,
				};

			case "productrelated":
				return {
					...ProductRepository.getDefaultFilters(),
					limit: 10,
					store_id: store_id,
				};

			case "productintag":
				return {
					...ProductRepository.getDefaultFilters(),
					limit: 10,
					store_id: store_id,
				};

			case "collectionincollection":
				return {
					...ProductCollectionRepository.getDefaultFilters(),
					limit: 10,
				};

			default:
				const getResourceType = () => {
					switch (type) {
						case "tagbrand":
							return TagConstant.RESOURCE_TYPE.BRAND;
						case "tagproductcollection":
							return TagConstant.RESOURCE_TYPE.PRODUCT_COLLECTION;

						case "tagstory":
							return TagConstant.RESOURCE_TYPE.STORY;

						case "tagproduct":
							return TagConstant.RESOURCE_TYPE.PRODUCT;

						case "tagstorycollection":
							return TagConstant.RESOURCE_TYPE.STORY_COLLECTION;

						case "warehouse":
							return TagConstant.RESOURCE_TYPE.WAREHOUSE;

						default:
							break;
					}
				};
				return {
					...TagRepository.getDefaultFilters(),
					limit: 10,
					resource_type: getResourceType(),
				};

				break;
		}
	}, [type]);

	//state
	// const [selectedValue, setSelectedValue] = useState<Option[]>([]);
	const [keyword, setKeyword] = React.useState<string>("");
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState("");
	const [filters, setFilters] = useState(defaultFilters);
	const [total, setTotal] = useState<number>(0);
	const [dataSource, setDataSouce] = useState<any[]>([]);

	//sort in page
	function customSort(arr1: number[], arr2: any[]): any[] {
		let sortedArr: any[] = [];

		arr1.forEach((id) => {
			for (let i = 0; i < arr2.length; i++) {
				if (arr2[i].id === id) {
					sortedArr.push(arr2.splice(i, 1)[0]);
					break;
				}
			}
		});

		sortedArr = sortedArr.concat(arr2);

		return sortedArr;
	}

	const fetchDataOptions = useCallback(async (): Promise<void> => {
		try {
			setLoading(true);

			if (
				type === "product" ||
				type === "productintag" ||
				type === "productrelated"
			) {
				let collection = await new ProductRepository().getItems(
					{
						filters: keyword
							? { ...filters, keyword: keyword }
							: { ...filters },
					},
					categoryItems
				);
				if (collection.hasError()) {
					setError(collection.error.errors[0]);
				} else {
					setDataSouce(customSort(values, collection.items));
					setTotal(collection.total);
				}
			} else if (type === "collectionincollection") {
				let collection = await new ProductCollectionRepository().getItems({
					filters: keyword ? { ...filters, keyword: keyword } : { ...filters },
				});
				if (collection.hasError()) {
					setError(collection.error.errors[0]);
				} else {
					setDataSouce(customSort(values, collection.items));
					setTotal(collection.total);
				}
			} else {
				let collection = await new TagRepository().getItems({
					filters: keyword ? { ...filters, keyword: keyword } : { ...filters },
				});
				if (collection.hasError()) {
					setError(collection.error.errors[0]);
				} else {
					setDataSouce(customSort(values, collection.items));
					setTotal(collection.total);
				}
			}
		} catch (error) {
			throw error;
		} finally {
			setLoading(false);
		}
	}, [filters, keyword, values]);

	const handleCheckBoxId = ({ item }: { item: SelectOption }) => {
		const findIndex = selectedValue.findIndex(
			(tmp) => tmp.value === item.value
		);
		if (findIndex >= 0) {
			const cloneArray = [...selectedValue];
			cloneArray.splice(findIndex, 1);
			handleSelectedValue(cloneArray);
		} else {
			handleSelectedValue([
				...selectedValue,
				{ label: item.label, value: item.value },
			]);
		}
	};

	const tagRender = ({ item }: { item: SelectOption }) => {
		return (
			<Tag
				closable
				onClose={() => {
					handleCheckBoxId({ item: item });
				}}>
				{item.label}
			</Tag>
		);
	};

	useEffect(() => {
		fetchDataOptions();
	}, [keyword, filters, reload]);

	return (
		<>
			<div className="mb-2">
				Đã chọn
				<span className="font-bold"> {selectedValue.length} </span>
				{type === "product" ||
				type === "productintag" ||
				type === "productrelated"
					? "sản phẩm"
					: type === "collectionincollection"
					? "danh mục"
					: "tag"}{" "}
			</div>
			{selectedValue.map((item, index) => {
				return <span key={item.value}>{tagRender({ item: item })}</span>;
			})}
			<Divider />
			<AddListToItemFilter
				defaultFilters={defaultFilters}
				filters={filters}
				setFilters={setFilters}
				total={total}
				type={type}
			/>
			<Checkbox
				checked={
					selectedValue.length ===
						dataSource.filter(
							(item) => values.findIndex((tmp) => tmp == item.id) < 0
						).length &&
					dataSource.filter(
						(item) => values.findIndex((tmp) => tmp == item.id) < 0
					).length > 0
				}
				disabled={
					dataSource.filter(
						(item) => values.findIndex((tmp) => tmp == item.id) < 0
					).length === 0
				}
				onChange={(e) => {
					if (!e.target.checked) {
						//untick
						handleSelectedValue([]);
					} else {
						//tick
						if (type === "collectionincollection") {
							handleSelectedValue(
								dataSource
									.filter(
										(item) => values.findIndex((tmp) => tmp == item.id) < 0
									)
									.map((pro) => {
										return {
											label: pro.title,
											value: pro.id,
										};
									})
							);
						} else {
							handleSelectedValue(
								dataSource
									.filter(
										(item) => values.findIndex((tmp) => tmp == item.id) < 0
									)
									.map((pro) => {
										return {
											label: pro.name,
											value: pro.id,
										};
									})
							);
						}
					}
				}}>
				{" "}
				Chọn tất cả
			</Checkbox>

			{!loading ? (
				<List
					dataSource={dataSource}
					renderItem={(item) => {
						return (
							<List.Item className="w-full block">
								<div className="flex justify-between items-center w-full">
									<div className="flex gap-2 items-center flex-1">
										{values &&
											values.findIndex((tmp) => tmp === item.id) < 0 && (
												<Checkbox
													checked={
														selectedValue.findIndex(
															(tmp) => tmp.value === item.id
														) >= 0
													}
													onChange={(e) => {
														if (!e.target.checked) {
															const findIndex = selectedValue.findIndex(
																(tmp) => tmp.value === item.id
															);
															if (findIndex >= 0) {
																const cloneArray = [...selectedValue];
																cloneArray.splice(findIndex, 1);
																handleSelectedValue(cloneArray);
															}
														} else {
															if (type === "collectionincollection") {
																handleSelectedValue([
																	...selectedValue,
																	{ label: item.title, value: item.id },
																]);
															} else {
																handleSelectedValue([
																	...selectedValue,
																	{ label: item.name, value: item.id },
																]);
															}
														}
													}}
												/>
											)}
										{renderItems(item)}
									</div>
									{values && values.findIndex((tmp) => tmp === item.id) >= 0 ? (
										<IconX
											onClick={async () => {
												changeProcessing(true);
												await handleRemoveItem({
													label: item.name,
													value: item.id,
												});
												changeProcessing(false);
											}}
											className="min-w-6 min-h-6 hover:text-red-500 cursor-pointer"
										/>
									) : (
										<></>
									)}
								</div>
							</List.Item>
						);
					}}
				/>
			) : (
				<Skeleton active />
			)}
			<PageDataPagination
				dataSource={dataSource}
				filters={filters}
				setFilters={setFilters}
				total={total}
			/>
		</>
	);
};

export default SelectListComponent;
