import { Form, Input } from "antd";
import { Filter } from "common/types/Filter";
import DatePickerRangeFilter from "components/datetime/DatePickerRangeFilter";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import { useTranslation } from "react-i18next";

const DeviceMineListFilter = <F extends Filter>({
  total,
  filters,
  setFilters,
  defaultFilters,
}: {
  total: number;
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <PageDataFilterForm
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      >
        <Form.Item name="keyword">
          <Input
            allowClear
            placeholder={t("Nhập từ khóa")}
          />
        </Form.Item>
        <DatePickerRangeFilter
          filters={filters}
          keyStart="date_from"
          keyEnd="date_to"
          setFilters={setFilters}
          placeholderRange={[
            t("reportdelivery:placeholder_datefrom"),
            t("reportdelivery:placeholder_dateto"),
          ]}
        />
      </PageDataFilterForm>
    </>
  );
};

export default DeviceMineListFilter;
