import ContractAndDeviceModel from "common/models/ContractAndDeviceModel";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DeviceAddEditModal from "../components/ContractAddEditModal";

interface IContractManageListHeaderProps {
  onSaveSuccess: (item: ContractAndDeviceModel) => void;
}

const ContractManageListHeader = ({ onSaveSuccess }: IContractManageListHeaderProps) => {
  const { t } = useTranslation();

  const [isOpenForm, setIsOpenForm] = useState(false);

  return (
    <PageHeader
      heading={t("contractanddevice:contract_manage.heading_list")}
      siteMenuSelectedKey="/contract/manage"
    >
      <PageHeaderButton
        type="primary"
        link=""
        onClick={() => setIsOpenForm(true)}
      >
        {t("contractanddevice:contract_manage.heading_add_btn")}
      </PageHeaderButton>
      <DeviceAddEditModal
        open={isOpenForm}
        setOpen={(isOpen) => setIsOpenForm(isOpen)}
        onSaveSuccess={onSaveSuccess}
      />
    </PageHeader>
  );
};

export default ContractManageListHeader;
