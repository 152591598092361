import SystemEventModel from "common/models/SystemEventModel";
import SystemEventList from "features/systemevent/list/SystemEventList";
import TemplateList from "features/template/list/TemplateList";
import usePathParams from "hooks/usePathParams";
import React, { useState } from "react";

const SystemEventPage = () => {
	const [pathParams] = usePathParams();
	const [model, setModel] = useState(
		new SystemEventModel(SystemEventModel.getDefaultData())
	);

	let com = <></>;

	switch (pathParams.action) {
		default:
			com = <SystemEventList></SystemEventList>;
			break;
	}
	return com;
};

export default SystemEventPage;
