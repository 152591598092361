import { Tabs, TabsProps } from "antd";
import ProductPublicModel from "common/models/ProductPublicModel";
import StoreModel from "common/models/StoreModel";
import TableInventoryProductPublic from "./TableInventoryProductPublic";
import ProductTag from "../tag/ProductTag";
import ProductRelated from "../productrelated/ProductRelated";
import { t } from "i18next";
import ProductActions from "../form/ProductActions";
import ProductParentChild from "./ProductParentChild";
import { useEffect } from "react";
import TableSampleProduct from "./TableSampleProduct";

const ProductItemPublicExpand = ({
	product,
	storeList,
	onSaveSuccess,
	onDeleteSuccess,
	renderAction,
}: // storeIdActive
{
	product: ProductPublicModel;
	storeList?: StoreModel[];
	// storeIdActive : number
	onSaveSuccess: () => void;
	onDeleteSuccess?: (productId: number) => void;
	renderAction?: (id: number) => React.ReactNode;
}) => {
	const items: TabsProps["items"] = [
		{
			key: "1",
			label: "Thông tin",
			children: (
				<>
					{/* <ProductParentChild store_id={storeIdActive} product={product}  /> */}
					<ProductActions
						onSaveSuccess={onSaveSuccess}
						product={product}
						onDeleteSuccess={onDeleteSuccess}
					/>
				</>
			),
		},

		{
			key: "2",
			label: "Tồn kho",
			children: (
				// <RoleCheck roles={[Role.INVENTORY_VIEW]}>
				<div className=" bg-white">
					<TableInventoryProductPublic
						storeList={storeList}
						product={product}
					/>
				</div>
				// </RoleCheck>
			),
		},

		{
			key: "3",
			label: "Phân loại sản phẩm (tag)",
			children: <ProductTag id={product.id} onSaveSuccess={onSaveSuccess} />,
		},
		{
			key: "4",
			label: "Cấu trúc hiển thị sản phẩm",
			children: (
				<ProductRelated
					data={product}
					id={product.id}
					onSaveSuccess={() => {}}
					renderAction={renderAction}
				/>
			),
		},

		{
			key: "5",
			label: "Mẫu thử",
			children: (
				// <RoleCheck roles={[Role.INVENTORY_VIEW]}>
				<div className=" bg-white">
					<TableSampleProduct product={product} />
				</div>
				// </RoleCheck>
			),
		},
	];

	useEffect(() => {
		const i = document.getElementById("123");
		const arr =
			"312,000;2,250,000;1,314,000;304,000;312,000;2,565,000;312,000;448,000;256,000;2,151,000;1,791,000;2,000,000;1,572,500;544,000;1,385,500;1,326,000;1,755,000;1,623,500;1,623,500;2,115,000;2,520,000;2,682,000;2,142,000;3,240,000;384,000;384,000;3,168,000;2,754,000;1,647,000;384,000;2,772,000;2,340,000;2,151,000;1,782,000;3,051,000;2,048,000;1,680,000;1,496,000;1,984,000;1,760,000;1,504,000;1,800,000;1,600,000;1,400,000;2,193,000;2,252,500;1,955,000;1,800,000;1,955,000;1,700,000;774,000;774,000;2,167,500;1,105,000;1,665,000;1,062,000;304,000;2,250,000;3,015,000;2,082,500;2,448,000;1,768,000;1,665,000;2,934,000;256,000;2,340,000;3,240,000;2,754,000;2,160,000;2,592,500;2,592,500;2,592,500;2,592,500;2,592,500;1,997,500;2,805,000;2,448,000;1,241,000;1,929,500;1,955,000;272,000;1,657,500;1,935,000;1,317,500;748,000;3,141,000;624,000;3,150,000;2,385,000;3,240,000;2,952,000;3,285,000;2,070,000;1,341,000;1,251,000;1,251,000;1,742,500;312,000;1,946,500;1,258,000;1,258,000;2,070,000;2,167,500;1,657,500;2,070,000;2,745,000;2,151,000;2,880,000;304,000;288,000;2,727,000;624,000;720,000;2,502,000;1,764,000;2,048,000;1,984,000;1,760,000;1,504,000;2,376,000;2,376,000;2,376,000;2,376,000;2,040,000;1,560,000";
		arr.split(";").map((i) => Number(i));
	}, []);

	return (
		<div className="pb-8" style={{ marginLeft: 42 }}>
			<Tabs defaultActiveKey="1" items={items} />
		</div>
	);
};

export default ProductItemPublicExpand;
