import BaseRepository from "./BaseRepository";
import type { GetListProps } from "common/types/Filter";

//////////////////////////////////
//Phần thay thế
import { SystemEventAddEditJson, SystemEventFilter } from "common/types/SystemEvent";
import SystemEventApi from "common/api/SystemEventApi";
import SystemEventCollection from "common/collections/SystemEventCollection";
import SystemEventModel from "common/models/SystemEventModel";

class TemplateRepository extends BaseRepository<SystemEventApi> {
	_api: SystemEventApi | null;
	constructor() {
		super();
		this._api = new SystemEventApi(true);
	}

	static getDefaultFilter(): SystemEventFilter {
		return {
			page: 1,
			limit: 20,
			sortby: "date_created",
			sorttype: "DESC",
			keyword: "",
			status: -1,
			resource_id: -1,
			event: -1,
			action: -1,
			resource_type: -1,
			from_time: -1,
			to_time: -1,
		};
	}

	async getItems(
		props: GetListProps<SystemEventFilter>
	): Promise<SystemEventCollection> {
		return this._api
			? await this._api.getList(props)
			: new SystemEventCollection();
	}

	async getItem(id: number): Promise<SystemEventModel> {
		return this._api
			? await this._api.getDetail(id)
			: new SystemEventModel(SystemEventModel.getDefaultData());
	}

	async saveRemote(data: SystemEventAddEditJson): Promise<SystemEventModel> {
	  if (this._api) {
	    return await this._api.edit(data)
	  } else {
	    return new SystemEventModel(SystemEventModel.getDefaultData());
	  }
	}

	async deleteItem(id: number): Promise<string[]> {
		return this._api ? await this._api.delete(id) : [];
	}
}

export default TemplateRepository;
