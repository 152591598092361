import Error from "common/api/Error";
import CommonConstant from "common/constants/CommonConstant";
import { SelectOption } from "common/types/SelectOption";
import i18n from "i18n";

class BaseModel {
	error: Error;
	constructor() {
		this.error = new Error({
			statusCode: 0,
			errors: [],
			error_detail: [],
		});
	}

	withError(error: Error) {
		this.error = error;
	}

	hasError(): boolean {
		return this.error.errors.length > 0;
	}

	static convertLocaleToLanguage(locale: string): string {
		let language = "";

		switch (locale) {
			case "vi-VN":
				language = "vn";
				break;
			case "en-US":
				language = "en";
				break;
			default:
				language = locale;
		}

		return language;
	}

	static getCheckboxOptionList(): SelectOption[] {
		return [
			{
				value: CommonConstant.CHECKED,
				label: i18n.t("common:yes"),
			},
			{
				value: CommonConstant.UNCHECKED,
				label: i18n.t("common:no"),
			},
		];
	}

	static getCheckboxValue(value: number): SelectOption | undefined {
		return this.getCheckboxOptionList().find((item) => item.value === value);
	}
}

export default BaseModel;
