import { SelectProps } from "antd";
import { Select } from "antd";
import { FormLabelAlign } from "antd/es/form/interface";
import { BaseOptionType } from "antd/es/select";
import { SelectValue } from "antd/lib/select";
import WarehouseRepository from "common/repositories/WarehouseRepository";
import { SelectOption } from "common/types/SelectOption";
import FormSelect from "components/form/FormSelect";
import React, { useCallback, useEffect, useState } from "react";

type Props = {};
const WarehouseSelects = ({
	value,
	defaultValue,
	onChange,
	selectProps,
}: {
	value?: SelectValue;
	onChange?: (v: SelectValue) => void;

	multiple?: boolean;
	defaultValue?: SelectValue;
	selectProps?: SelectProps;
}) => {
	const [errors, setErrors] = useState<string[]>([]);
	const [loading, setLoading] = useState(true);
	const [options, setOptions] = useState<SelectOption[]>([]);

	const fetchData = useCallback(async () => {
		const collection = await new WarehouseRepository().getItems({
			filters: WarehouseRepository.getDefaultFilters(),
		});
		if (collection.hasError()) {
			setErrors(collection.error.errors);
		} else {
			setOptions(
				collection.items.map((item) => ({
					value: item.id,
					label: item.name,
				}))
			);
		}
		setLoading(false);
	}, []);

	useEffect(() => {
		if (loading) {
			fetchData();
		} else {
			setLoading(false);
		}
	}, [loading, fetchData]);

	return (
		<Select
			defaultValue={defaultValue}
			value={value}
			onChange={onChange}
			options={options}
			{...selectProps}
			filterOption={(input: any, option: any) =>
				option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
				option.label
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "")
					.replace(/[đĐ]/g, "d")
					.toLowerCase()
					.indexOf(
						input
							.normalize("NFD")
							.replace(/[\u0300-\u036f]/g, "")
							.replace(/[đĐ]/g, "d")
							.toLowerCase()
					) >= 0
			}></Select>
	);
};

export default WarehouseSelects;
