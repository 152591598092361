import React from "react";
import { Button, Card, message, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import ProductRepository from "common/repositories/ProductRepository";
import ProductModel from "common/models/ProductModel";
import { ProductStatus } from "common/types/Product";
import { t } from "i18next";
import {
	CheckCircleFilled,
	CopyOutlined,
	EditFilled,
	LockOutlined,
	PlusOutlined,
} from "@ant-design/icons";
import ProductPublicModel from "common/models/ProductPublicModel";
import TableDelete from "components/table/TableDelete";
import { cn } from "common/utils/utils";
export default function ProductActions({
	product,
	onSaveSuccess,
	onDeleteSuccess,
	className,
	isNotShowUpdate,
}: {
	product: ProductPublicModel | ProductModel;
	onSaveSuccess: (product: ProductModel) => void;
	onDeleteSuccess?: (productId: number) => void;
	className?: string;
	isNotShowUpdate?: boolean;
}) {
	async function handleAllowSale(status: ProductStatus) {
		let item: ProductModel = await new ProductRepository().editRemote(
			product.id,
			{
				status: status,
			}
		);
		if (!item.hasError()) {
			message.success({
				content: t("common:form.success.save"),
				className: "message_success",
				key: "message",
				duration: 2,
			});
			onSaveSuccess?.(item);
		} else {
			message.error({
				content: <></>,
				className: "message_error",
				key: "message",
				duration: 4,
			});
		}
	}
	return (
		<div className={cn(" flex gap-2  items-center", className)}>
			{!isNotShowUpdate && (
				<Link to={`/product/edit/id/${product.id}`}>
					<Button
						className=" bg-green-500"
						type="primary"
						icon={<EditFilled />}>
						Cập nhập
					</Button>
				</Link>
			)}

			<Link to={`/product/add/copy/${product.id}`}>
				<Button
					className=" bg-green-500"
					type="primary"
					icon={<CopyOutlined />}>
					Sao chép
				</Button>
			</Link>
			{/* {!product.parent_id ? ( */}
			<Link
				to={`/product/add/parent/${
					product.parent_id ? product.parent_id : product.id
				}`}
				target="_blank">
				<Button type="primary" icon={<PlusOutlined />}>
					Thêm hàng hóa cùng loại
				</Button>
			</Link>
			{/* ) : null} */}
			{product.status === ProductStatus.ACTIVE ? (
				<Popconfirm
					title="Ngừng hiệu lực sản phẩm"
					description="bạn chắc chắn muốn ngừng hiệu lực sản phẩm này?"
					onConfirm={() => handleAllowSale(ProductStatus.UNACTIVE)}
					okText="Xác nhận"
					cancelText="Hủy">
					<Button
						className=" bg-red-500"
						type="primary"
						icon={<LockOutlined />}>
						Ngừng hiệu lực
					</Button>
				</Popconfirm>
			) : (
				<Popconfirm
					title="Mở hiệu lực sản phẩm"
					description="bạn chắc chắn muốn mở hiệu lực sản phẩm này?"
					onConfirm={() => handleAllowSale(ProductStatus.ACTIVE)}
					okText="Xác nhận"
					cancelText="Hủy">
					<Button
						className=" bg-green-500"
						type="primary"
						icon={<CheckCircleFilled />}>
						Mở hiệu lực
					</Button>
				</Popconfirm>
			)}

			<TableDelete
				error_translate_prefix="product:form.error"
				label="Xóa sản phẩm"
				buttonType="primary"
				className=" bg-red-500"
				buttonSize="middle"
				onDeleteCallback={(id) => {
					onDeleteSuccess?.(id);
					// setDataSource(dataSource.filter((item) => item.id !== id));
				}}
				repository={new ProductRepository()}
				id={product.id}
			/>
		</div>
	);
}
