import ShipperKPIModel from "common/models/ShipperKPIModel";
import {
  ShipperKPICollectionJson,
  ShipperKPIJson,
} from "common/types/ShipperKPI";
import BaseCollection from "./BaseCollection";

class ShipperKPICollection extends BaseCollection<
  ShipperKPIJson,
  ShipperKPIModel,
  ShipperKPICollectionJson
> {
  itemsFromJson(jsonItems: ShipperKPIJson[]): ShipperKPIModel[] {
    return jsonItems.map((item) => new ShipperKPIModel(item));
  }
}
export default ShipperKPICollection;
