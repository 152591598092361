import { Tag } from "antd";
import ProductTransmitCollection from "common/collections/ProductTransmitCollection";
import Role from "common/constants/Role";
import ProductReceiptModel from "common/models/ProductReceiptModel";
import ProductTransmitModel from "common/models/ProductTransmitModel";
import WarehouseModel from "common/models/WarehouseModel";
import ProductTransmitRepository from "common/repositories/ProductTransmitRepository";
import WarehouseRepository from "common/repositories/WarehouseRepository";
import { FilterProductTransmit } from "common/types/ProductTransmit";
import { TableColumnsType } from "common/types/Table";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import TextDateTime from "components/TextDateTime";
import ProductTransmitListHeader from "features/producttransmit/list/ProductTransmitListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import { IconArrowBigRight } from "@tabler/icons-react";

import ProductTransmitListFilter from "./ProductTransmitListFilter";
import Helper from "common/utils/helper";

const ProductTransmitList = () => {
  const { t } = useTranslation();
  const hasRoleAndLimit = useLoginAccountStore(
    (state) => state.hasRoleAndLimit
  );
  //////////////////////////////////////////

  //fecth data warehouse
  const [warehouseItems, setWarehouseItems] = useState<WarehouseModel[]>([]);
  const fetchWarehouse = useCallback(async () => {
    const collection = await new WarehouseRepository().getItems({
      filters: WarehouseRepository.getDefaultFilters(),
    });
    if (!collection.hasError()) {
      setWarehouseItems(collection.items);
    }
  }, []);

  useEffect(() => {
    fetchWarehouse();
  }, [fetchWarehouse]);

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterProductTransmit = useMemo(
    () => ProductTransmitRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] =
    useStateFilter<FilterProductTransmit>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<ProductTransmitModel[]>([]);

  const fetchData =
    useCallback(async (): Promise<ProductTransmitCollection> => {
      let collection = await new ProductTransmitRepository().getItems({
        filters,
      });
      setDataSource(collection.items);
      setTotal(collection.total);
      let receiptIds: number[] = [];
      collection.items.map((item) =>
        item.receipt_id_from > 0 ? receiptIds.push(item.receipt_id_from) : null
      );
      collection.items.map((item) =>
        item.receipt_id_to > 0 ? receiptIds.push(item.receipt_id_to) : null
      );

      return collection;
    }, [filters]);

  //Table columns
  const columns: TableColumnsType<ProductTransmitModel> = [
    {
      toggletype: "ignore",
      title: t("producttransmit:id"),
      key: "id",
      width: 110,
      align: "center",
      render: (_, record) => {
        return (
          <>
            <TableLinkText link={"/producttransmit/edit/id/" + record.id}>
              {record.id}
            </TableLinkText>
          </>
        );
      },
    },
    {
      title: t("producttransmit:warehouse_id_from"),
      key: "warehouse_id_from",
      render: (warehouse_id_from: number) => {
        const found = warehouseItems.find((i) => i.id === warehouse_id_from);

        if (typeof found !== "undefined") {
          return <span>{found.name}</span>;
        } else {
          return "ID #" + warehouse_id_from;
        }
      },
    },
    {
      title: t("producttransmit:receipt_id_from"),
      key: "receipt_id_from",
      render: (_: any, record: ProductTransmitModel) => {
        if (record.receipt_from.id > 0) {
          let statusInfo = ProductReceiptModel.getStatus(
            record.receipt_from.status
          );

          return (
            <>
              {hasRoleAndLimit(
                Role.INVENTORY_VIEW,
                record.warehouse_id_from
              ) ? (
                <Link
                  to={"/productreceipt/edit/id/" + record.receipt_from.id}
                  title={t("producttransmit:receipt_edit_tooltip")}
                  target="_blank"
                >
                  {" "}
                  {record.receipt_from.id}
                </Link>
              ) : (
                record.receipt_from.id
              )}{" "}
              <Tag
                color={statusInfo?.color}
                style={{ borderWidth: 0, background: "transparent" }}
              >
                ({statusInfo?.label})
              </Tag>
            </>
          );
        } else {
          return <>-</>;
        }
      },
    },
    {
      title: " ",
      width: 50,
      key: "id",
      align: "center",
      togglelabel: "-->",
      render: () => {
        return (
          <>
            <IconArrowBigRight size={18} color="gray" />
          </>
        );
      },
    },
    {
      title: t("producttransmit:warehouse_id_to"),
      key: "warehouse_id_to",
      render: (warehouse_id_to: number) => {
        const found = warehouseItems.find((i) => i.id === warehouse_id_to);
        if (typeof found !== "undefined") {
          return <span>{found.name}</span>;
        } else {
          return "ID #" + warehouse_id_to;
        }
      },
    },
    {
      title: t("producttransmit:receipt_id_to"),
      key: "receipt_id_to",
      render: (_: any, record: ProductTransmitModel) => {
        if (record.receipt_to.id > 0) {
          let statusInfo = ProductReceiptModel.getStatus(
            record.receipt_to.status
          );
          return (
            <>
              {hasRoleAndLimit(Role.INVENTORY_VIEW, record.warehouse_id_to) ? (
                <Link
                  to={"/productreceipt/edit/id/" + record.receipt_to.id}
                  title={t("producttransmit:receipt_edit_tooltip")}
                  target="_blank"
                >
                  {" "}
                  {record.receipt_to.id}
                </Link>
              ) : (
                record.receipt_to.id
              )}{" "}
              <Tag
                color={statusInfo?.color}
                style={{ borderWidth: 0, background: "transparent" }}
              >
                ({statusInfo?.label})
              </Tag>
            </>
          );
        } else {
          return <>-</>;
        }
      },
    },
    {
      title: t("producttransmit:status"),
      key: "status",
      sortby: "status",
      render: (status: number) => {
        let statusInfo = ProductTransmitModel.getStatus(status);
        return <Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>;
      },
      width: 110,
    },
		{
			title: t("common:date_created"),
			key: "date_created",
			width: 120,
			render: (date_created) => Helper.formatTimestampToText2(date_created),
		},
    {
      title: " ",
      key: "actions",
      toggletype: "trigger",
      align: "right",
      fixed: "right",
      width: 95,
      render: (_: any, record: ProductTransmitModel) => (
        <RoleCheck roles={[Role.INVENTORY_TRANSFER_REQUEST]} hideOnFail>
          <TableEdit link={"/producttransmit/edit/id/" + record.id} />
          <TableInfo record={record}></TableInfo>
        </RoleCheck>
      ),
    },
  ];

  return (
    <>
      <ProductTransmitListHeader />
      <ProductTransmitListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />
      <PageDataTable<
        FilterProductTransmit,
        ProductTransmitModel,
        ProductTransmitCollection
      >
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "producttransmit",
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </>
  );
};

export default ProductTransmitList;
