import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { TemplateJson } from "common/types/Template";
import BaseModel from "./BaseModel";
const defaultData: TemplateJson = {
	id: 0,
};
class TemplateModel extends BaseModel implements BaseModelJson<TemplateJson> {
	id: number = defaultData.id;

	constructor(json: TemplateJson) {
		super();
		Object.assign(this, { ...defaultData, ...json });
	}

	toJson(): TemplateJson {
		return JSON.parse(JSON.stringify({ ...this }));
	}

	static getDefaultData(): TemplateJson {
		return { ...defaultData };
	}
}

export default TemplateModel;
