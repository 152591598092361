import { EditOutlined } from "@ant-design/icons";
import { IconPhoto, IconTrash } from "@tabler/icons-react";
import {
  App,
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Row,
  Slider,
  Switch,
} from "antd";
import Meta from "antd/es/card/Meta";
import TextArea from "antd/es/input/TextArea";
import ContractAndDevice from "common/constants/ContractAndDevice";
import ContractAndDeviceModel from "common/models/ContractAndDeviceModel";
import FileModel from "common/models/FileModel";
import ContractAndDeviceRepository from "common/repositories/ContractAndDeviceRepository";
import FileRepository from "common/repositories/FileRepository";
import { ContractAndDeviceAddEditJson } from "common/types/Device";
import Helper from "common/utils/helper";
import FileUploaderButton from "components/file/uploader/FileUploaderButton";
import FormSelect from "components/form/FormSelect";
import Error from "components/LayoutError";
import PageSortList from "components/list/PageSortList";
import FormChangedContext from "contexts/FormChangedContext";
import dayjs from "dayjs";
import UserFormSelect from "features/user/UserFormSelect";
import useGetfilesByIds from "hooks/useGetfilesByIds";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

const DeviceAddEditForm = ({
  model,
  setOpen,
  setProcessing,
  onSaveSuccess,
}: {
  model: ContractAndDeviceModel;
  setOpen: (v: boolean) => any;
  setProcessing: (p: boolean) => any;
  onSaveSuccess: (o: ContractAndDeviceModel) => void;
}) => {
  const [account] = useLoginAccountStore((state) => [state.account]);

  const { t } = useTranslation();
  const { message } = App.useApp();

  const [form] = Form.useForm();
  const formChangedProvider = useContext(FormChangedContext);

  const { files: filess } = useGetfilesByIds(
    model.file_id.split(",").map(Number),
    {
      object_type: 10,
    }
  );
  const [images, setImages] = useState<FileModel[]>([]);
  const [fileEdit, setFileEdit] = useState({
    open: false,
    file: new FileModel(FileModel.getDefaultData()),
  });

  useEffect(() => {
    if (filess) {
      const files = model.file_id
        .split(",")
        .map((file: any) => {
          return filess[file];
        })
        .filter(Boolean) as FileModel[];
      setImages(files);
    }
  }, [filess, model.file_id]);

  const initialValues = useMemo(() => {
    return {
      name: model.name,
      code: model.code.length > 0 ? model.code : "",
      holder_id: account.company.employee.id,
      employee_id: model.employee_id > 0 ? model.employee_id : null,
      description: model.description.length > 0 ? model.code : "",
      note: model.note.length > 0 ? model.name : "",
      quality: model.quality > 0 ? model.quality : 0,
      warranty:
        model.warranty === ContractAndDevice.WARRANTY_ENABLE ? true : false,
      status: model.status > 0 ? model.status : ContractAndDevice.STATUS_DRAFT,
      source_status:
        model.source_status > 0
          ? model.source_status
          : ContractAndDevice.DEVICE.SOURCE_STATUS_NEW,
      from_time:
        model.from_time > 0 ? dayjs(new Date(model.from_time * 1000)) : 0,
      to_time: model.to_time > 0 ? dayjs(new Date(model.to_time * 1000)) : 0,
    };
  }, [model, account.company.employee.id]);

  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: ContractAndDeviceAddEditJson = {
        ...ContractAndDeviceModel.getDefaultData(),
        id: model.id,
        code: formData.code.length > 0 ? formData.code.trim() : "",
        name: formData.name.length > 0 ? formData.name.trim() : "",
        holder_id: account.company.employee.id,
        employee_id: formData.employee_id > 0 ? formData.employee_id : 0,
        description:
          formData.description.length > 0 ? formData.description.trim() : "",
        note: formData.note.length > 0 ? formData.note.trim() : "",
        quality: formData.quality > 0 ? formData.quality : 0,
        warranty: formData.warranty
          ? ContractAndDevice.WARRANTY_ENABLE
          : ContractAndDevice.WARRANTY_DISABLE,
        type: ContractAndDevice.GROUP_DEVICE,
        status: formData.status,
        source_status: formData.source_status,
        from_time:
          Helper.datePickerValueToTimestamp(formData.from_time) > 0
            ? Helper.datePickerValueToTimestamp(formData.from_time)
            : 0,
        to_time:
          Helper.datePickerValueToTimestamp(formData.to_time) > 0
            ? Helper.datePickerValueToTimestamp(formData.to_time)
            : 0,
        files:
          images.length > 0 ? images.map((item) => item.id).toString() : "",
      };

      return submitData;
    },
    [model.id, account.company.employee.id, images]
  );

  const handleUpload = (file: FileModel) => {
    setImages((preState) => [...preState, file]);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination?.index;
    const newArr = [...images];
    Helper.moveItem(newArr, sourceIndex, destinationIndex);
    setImages(newArr);
  };

  const handleDeleteFile = async (file: FileModel) => {
    await new FileRepository().deleteItem(file.id);
    setImages(images.filter((image) => image.id !== file.id));
  };

  const handleEditImage = async () => {
    const file = fileEdit.file;
    await new FileRepository().saveDirectoryRemote({ ...file });
    const findIdex = images.findIndex((f) => f.id === file.id);
    const newArr = [...images];
    newArr[findIdex] = file;
    setImages(newArr);
  };

  const onSubmit = async (formData: any) => {
    setProcessing(true);
    message.loading({
      content: t("common:form.processing"),
      key: "message",
      duration: 0,
    });

    let item: ContractAndDeviceModel =
      await new ContractAndDeviceRepository().saveRemote(
        doPrepareData(formData)
      );

    setProcessing(false);
    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="contractanddevice:form.error"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4,
      });
    } else {
      message.success({
        content: t("common:form.success.save"),
        className: "message_success",
        key: "message",
        duration: 2,
      });
      onSaveSuccess(item);
      setOpen(false);
    }
  };

  console.log(model.warranty === ContractAndDevice.WARRANTY_ENABLE);

  return (
    <Form
      layout="vertical"
      initialValues={initialValues}
      form={form}
      onFinish={onSubmit}
      id={formChangedProvider.id}
      onFieldsChange={() => formChangedProvider.setChanged(true)}
    >
      <Row gutter={16}>
        <Col md={12} xs={24}>
          <Form.Item
            label={t("contractanddevice:form.code")}
            name="code"
            rules={[
              {
                required: true,
                message: t("contractanddevice:form.error.code_required"),
              },
              {
                validator(rule, value: string, callback) {
                  if (/^[a-zA-Z0-9-_]+$/.test(value)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      "Chỉ chấp nhận chữ cái không dấu thường và không khoảng trắng."
                    );
                  }
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item
            label={t("contractanddevice:form.name")}
            name="name"
            rules={[
              {
                required: true,
                message: t("contractanddevice:form.error.name_required"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={12} xs={24}>
          <Form.Item label={t("contractanddevice:form.quality")} name="quality">
            <Slider />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item
            label={t("contractanddevice:form.warranty")}
            name="warranty"
          >
            <Switch
              onChange={(isCheck) => {
                form.setFieldValue(
                  "warranty",
                  isCheck
                    ? ContractAndDevice.WARRANTY_ENABLE
                    : ContractAndDevice.WARRANTY_DISABLE
                );
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <UserFormSelect
        label={t("contractanddevice:form.employee_id")}
        name="employee_id"
        allowClear
        popupMatchSelectWidth={false}
      />
      <Row gutter={16}>
        <Col md={12} xs={24}>
          <FormSelect
            label={t("contractanddevice:form.status")}
            name="status"
            placeholder={"Trạng thái"}
            options={ContractAndDeviceModel.getStatusList()}
            popupMatchSelectWidth={false}
          />
        </Col>
        <Col md={12} xs={24}>
          <FormSelect
            label={t("contractanddevice:form.source_status")}
            name="source_status"
            placeholder={"Trạng thái gốc"}
            options={ContractAndDeviceModel.getDeviceSourceStatusList()}
            popupMatchSelectWidth={false}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={12} xs={24}>
          <Form.Item
            label={t("contractanddevice:form.from_time")}
            name="from_time"
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder={"HH:mm dd/mm/yyyy"}
              format="HH:mm DD/MM/YYYY"
              showTime
            />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item label={t("contractanddevice:form.to_time")} name="to_time">
            <DatePicker
              style={{ width: "100%" }}
              placeholder={"HH:mm dd/mm/yyyy"}
              format="HH:mm DD/MM/YYYY"
              showTime
            />
          </Form.Item>
        </Col>
      </Row>
      <Collapse className="mt-4 mb-2 bg-white">
        <Collapse.Panel header={t("collection:image_group")} key={"1"}>
          <Form.Item name="images" label={t("product:image")}>
            <FileUploaderButton
              objectType={10}
              label={t("storycollection:image")}
              icon={<IconPhoto size="24" color="grey" />}
              origin="avatar"
              uploadRenderType="list"
              isSingleFile={false}
              maxCount={6}
              initFiles={[]}
              onUploadCompleted={(file) => {
                handleUpload(file);
              }}
            />
          </Form.Item>
          <PageSortList
            dataSource={images}
            className=" flex flex-row gap-4"
            handleDragEnd={handleDragEnd}
            listKey="id"
            renderListItem={(file, index) => {
              return (
                <Card
                  actions={[
                    <EditOutlined
                      key="edit"
                      onClick={() => {
                        setFileEdit({ file: file, open: true });
                      }}
                    />,
                    <Popconfirm
                      title="Xóa hình ảnh"
                      description="bạn muốn xóa hình ảnh này?"
                      onConfirm={() => handleDeleteFile(file)}
                      okText="Xác nhận"
                      cancelText="Hủy"
                    >
                      <IconTrash key="delete" />
                    </Popconfirm>,
                  ]}
                  hoverable
                  style={{ width: 180, height: "100%" }}
                  bodyStyle={{
                    padding: 6,
                    display: "flex",
                    justifyContent: "center",
                  }}
                  cover={
                    <Image className=" flex-1" alt="example" src={file.url} />
                  }
                >
                  <Meta title={index + 1} />
                </Card>
              );
            }}
          />
          <Modal
            title={"Mô tả hình ảnh"}
            open={fileEdit.open}
            onCancel={() => {
              setFileEdit((pre) => ({
                file: new FileModel(FileModel.getDefaultData()),
                open: false,
              }));
            }}
            onOk={handleEditImage}
          >
            <p>Nhập mô tả cho hình ảnh</p>
            <TextArea
              value={fileEdit.file.description}
              onChange={(e) => {
                const value = e.target.value;
                setFileEdit((pre) => ({
                  ...pre,
                  file: new FileModel({
                    ...pre.file,
                    description: value,
                  }),
                }));
              }}
            />
          </Modal>
        </Collapse.Panel>
      </Collapse>
      <Row gutter={16}>
        <Col md={12} xs={24}>
          <Form.Item
            label={t("contractanddevice:form.description")}
            name="description"
          >
            <TextArea rows={4} />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item label={t("contractanddevice:form.note")} name="note">
            <TextArea rows={4} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default DeviceAddEditForm;
