import PageHeader from "components/page/PageHeader";
import React from "react";
import { useTranslation } from "react-i18next";

const NewsletterListHeader = ({ children }: { children?: React.ReactNode }) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("newsletter:heading_list")}
      siteMenuSelectedKey="/newsletter">
      {children}
    </PageHeader>
  );
};

export default NewsletterListHeader;
