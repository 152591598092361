import { Empty, FormItemProps, Typography } from "antd";
import CustomerCollection from "common/collections/CustomerCollection";
import CustomerRepository from "common/repositories/CustomerRepository";
import StoreRepository from "common/repositories/StoreRepository";
import { CustomerJson, FilterCustomer } from "common/types/Customer";
import MultiSearchLayout from "components/search/MultiSearchLayout";
import SearchComponent from "components/search/SearchLayout";
import { useTranslation } from "react-i18next";
type Props = FormItemProps & {
	disabled?: boolean;
	isMulti?: boolean;
};

const CustomerSearch = (props: Props) => {
	const { t } = useTranslation();
	if (props?.isMulti) {
		return (
			<>
				<MultiSearchLayout<CustomerJson, FilterCustomer, CustomerCollection>
					fetchRepository={(filers) => {
						return new CustomerRepository().getItems({
							filters: filers,
						});
					}}
					defaultFilters={CustomerRepository.getDefaultFilters()}
					formItemProps={{
						...props,
					}}
					disabled={props.disabled}
					keyValue="id"
					showFilters={["keyword"]}
					defaultUseFilters={["keyword"]}
					renderLabel={(item) => {
						return <>{item.full_name}</>;
					}}
				/>
			</>
		);
	}
	return (
		<>
			{}

			<SearchComponent<CustomerJson, FilterCustomer, CustomerCollection>
				fetchRepository={(filers) => {
					return new CustomerRepository().getItems({
						filters: filers,
					});
				}}
				defaultFilters={CustomerRepository.getDefaultFilters()}
				formItemProps={{
					...props,
				}}
				disabled={props.disabled}
				keyValue="id"
				showFilters={["keyword"]}
				defaultUseFilters={["keyword"]}
				renderLabel={(item) => {
					return <>{item.full_name}</>;
				}}
			/>
		</>
	);
};

export default CustomerSearch;
