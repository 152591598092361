import {
	Button,
	Checkbox,
	Col,
	Flex,
	FloatButton,
	Image,
	InputNumber,
	message,
	Result,
	Row,
	Skeleton,
	Statistic,
	Tag,
	Tooltip,
	Typography,
} from "antd";
import Role from "common/constants/Role";
import ProductRepository from "common/repositories/ProductRepository";
import { FilterProduct, ProductStatus, Quantitie } from "common/types/Product";
import { ProductCategoryJson } from "common/types/ProductCategory";
import { TableColumnsType } from "common/types/Table";
import NoPhoto from "components/NoPhoto";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import ProductListHeader from "features/product/list/ProductListHeader";
import useDatabaseTable from "hooks/useDatabaseTable";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconEye, IconMinus, IconPerfume, IconTag } from "@tabler/icons-react";

import StoreCollection from "common/collections/StoreCollection";
import StoreModel from "common/models/StoreModel";
import StoreRepository from "common/repositories/StoreRepository";
import { FileJson } from "common/types/File";
import { FilterStore } from "common/types/Store";
import ProductListFilter from "./ProductListFilter";
import { convertNumberToPriceVND } from "utils";
import ProductPublicCollection from "common/collections/ProductPublicCollection";
import ProductPublicModel from "common/models/ProductPublicModel";
import { cn } from "common/utils/utils";
import { Link } from "react-router-dom";
import ProductItemPublicExpand from "../item/ProductItemPublicExpand";
import ProductParentChild from "../item/ProductParentChild";
import TagTypeRepository from "common/repositories/TagTypeRepository";
import { TagResourceType } from "common/types/Tag";
import TagTypeModel from "common/models/TagTypeModel";
import TagModel from "common/models/TagModel";
import TagRepository from "common/repositories/TagRepository";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import useLocalStorage from "hooks/useLocalStorage";
import {
	CommentOutlined,
	CustomerServiceOutlined,
	PieChartOutlined,
	SyncOutlined,
	ToolOutlined,
} from "@ant-design/icons";
import { debounce } from "lodash";
import TagConstant from "common/constants/Tag";
import ProductWarehouseRepository from "common/repositories/ProductWarehouseRepository";
import ProductWarehouseModel from "common/models/ProductWarehouseModel";
import Helper from "common/utils/helper";

type LoadingSync = {
	[id: number]: boolean;
};

const ProductList = () => {
	const { t } = useTranslation();
	const enableResyncProductCompanyResource = false;
	const [messageApi, contextMessage] = message.useMessage();

	//////////////////////////////////////////
	// modal sync product
	const [formProductSyncVisible, setFormProductSyncVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState<string[]>([]);

	//////////////////////////////////////////
	//Filtering
	const [categoryItems] =
		useDatabaseTable<ProductCategoryJson>("productcategory");

	const [storeList, setStoreList] = useState<StoreModel[]>([]);

	const defaultFilters: FilterProduct = useMemo(() => {
		return {
			...ProductRepository.getDefaultFilters(),
			parent_id: 0,
			show: "all",
		};
	}, [storeList]);
	const [filters, setFilters] = useStateFilter<FilterProduct>(defaultFilters);
	useFilterLocation(defaultFilters, filters);
	// const [types, setTypes] = useState<TagTypeModel[]>([]);

	//fetchDataStore
	const fetchDataStoreList = useCallback(async (): Promise<StoreCollection> => {
		setLoading(true);
		let collection = await new StoreRepository().getItems({
			filters: { ...StoreRepository.getDefaultFilters(), limit: 50 },
		});
		if (!collection.hasError()) {
			if (collection.items.length > 0) {
				setStoreList(collection.items);
			}
		} else {
			setErrors(collection.error.errors);
		}

		setLoading(false);

		return collection;
	}, []);

	//////////////////////////////////////////
	//Fetch data from this collections
	const [total, setTotal] = useState(0);
	const [dataSource, setDataSource] = useState<ProductPublicModel[]>([]);

	const fetchData = useCallback(async (): Promise<ProductPublicCollection> => {
		let collection = new ProductPublicCollection();
		collection = await new ProductRepository().getItems(
			{
				filters: { ...filters, parent_id: filters.keyword ? undefined : 0 },
			},
			categoryItems
		);
		setDataSource(collection.items);
		setTotal(collection.total);
		return collection;
	}, [categoryItems, filters]);

	const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
	const [tags, setTags] = useState<TagModel[]>([]);
	//modal tag
	const [addTagVisible, setAddTagVisible] = useState(false);
	const [editingTagId, setEditingTagId] = useState(0);
	const [loadingSync, setLoadingSync] = useState<LoadingSync>({});

	const [loadingCheckSample, setLoadingCheckSample] = useState<boolean>(false);

	const [idProductSyncs, setIdProductSyncs] = useState<number[]>([]);

	useEffect(() => {
		fetchDataStoreList();
	}, [fetchDataStoreList]);

	const onSaveSuccess = () => {
		//detech this is NEW or UPDATE
		// const foundIndex = dataSource.findIndex((r) => r.id === item.id);
		// if (foundIndex >= 0) {
		//   //update current role item info
		//   setDataSource(
		//     update(dataSource, {
		//       [foundIndex]: {
		//         $set: item,
		//       },
		//     })
		//   );
		// } else {
		//   //append new item to list
		//   setDataSource(
		//     update(dataSource, {
		//       $unshift: [item],
		//     })
		//   );
		// }

		fetchData();
	};
	const expandable = useMemo(
		() => ({
			expandedRowRender: (record: ProductPublicModel) => {
				// !record.parent_id && record.count_childs > 1
				if (false) {
					return (
						<ProductParentChild
							store_id={filters.store_id}
							product={record}
							storeList={storeList}
							renderAction={() => {
								return (
									<RoleCheck roles={[Role.PRODUCT_MANAGE]} hideOnFail>
										<div onClick={(e) => e.stopPropagation()}>
											<TableEdit
												label=""
												link={``}
												onClick={() =>
													!loadingSync?.[record.id] &&
													handleSyncKiotviet(record.id)
												}
												tooltip="Đồng bộ kiotviet"
												icon={
													<SyncOutlined
														spin={
															loadingSync?.[record.id] || false
														}></SyncOutlined>
												}
											/>
										</div>
									</RoleCheck>
								);
							}}
						/>
					);
				}
				// return <ProductItemExpand recordId={record.id} />;
				return (
					<ProductItemPublicExpand
						product={record}
						storeList={storeList}
						onSaveSuccess={onSaveSuccess}
						onDeleteSuccess={onDeleteCallback}
						renderAction={(id) => {
							return (
								<RoleCheck roles={[Role.PRODUCT_MANAGE]} hideOnFail>
									<div onClick={(e) => e.stopPropagation()}>
										<TableEdit
											label=""
											link={``}
											onClick={() =>
												!loadingSync?.[id] && handleSyncKiotviet(id)
											}
											tooltip="Đồng bộ kiotviet"
											icon={
												<SyncOutlined
													spin={loadingSync?.[id] || false}></SyncOutlined>
											}
										/>
									</div>
								</RoleCheck>
							);
						}}
					/>
				);
			},
			expandedRowClassName: () => "ant-table-expanded-row-dimmer",
			expandRowByClick: true,
			showExpandColumn: false,
			expandedRowKeys: expandedRowKeys,
			onExpand: (expanded: boolean, record: ProductPublicModel) => {
				if (expanded) {
					setExpandedRowKeys([record.id]);
				} else {
					setExpandedRowKeys([]);
				}
			},
		}),
		[expandedRowKeys, onDeleteCallback, storeList]
	);

	//product
	const [addProductRelatedVisible, setAddProductRelatedVisible] =
		useState(false);
	const [editingProductRelatedId, setEditingProductRelatedId] =
		useState<number>(0);
	const [chooseProductRelated, setChooseProductRelated] =
		useState<ProductPublicModel>(
			new ProductPublicModel(ProductPublicModel.getDefaultData())
		);

	const onEditProductRelated = (record: ProductPublicModel) => {
		setEditingProductRelatedId(record.id);
		setChooseProductRelated(record);
		setAddProductRelatedVisible(true);
	};

	function onDeleteCallback(id: number) {
		setDataSource(dataSource.filter((item) => item.id !== id));
	}

	const handleSyncKiotviet = async (id: number) => {
		messageApi.info({
			content: "Tính năng tạm thời bảo trì",
			duration: 2,
		});
		return;
		setLoadingSync((prev) => ({ ...prev, [id]: true }));
		const res = await new ProductRepository().syncProductKv(id);
		if (res.length > 0) {
			messageApi.open({
				type: "error",
				content: "Đồng bộ thất bại",
				duration: 2,
			});
		} else {
			messageApi.open({
				type: "success",
				content: "Đồng bộ thành công",
				duration: 2,
			});
		}

		setLoadingSync((prev) => ({ ...prev, [id]: false }));
		return res;
	};

	const handleSyncAll = debounce(async () => {
		messageApi.info({
			content: "Tính năng tạm thời bảo trì",
			duration: 2,
		});
		return;
		const promises = idProductSyncs.map((i) => handleSyncKiotviet(i));
		await Promise.allSettled(promises);
	});

	const handleCheckedSample = useCallback(
		async (productIds: number[]) => {
			setLoadingCheckSample(true);
			const addTag = async (id: number) => {
				const response = await new TagRepository().addTagDetail({
					details: [
						{
							tag_id: 114,
							resource_id: id,
							resource_type: TagConstant.RESOURCE_TYPE.WAREHOUSE,
						},
					],
				});
			};

			const listPromise = productIds.map((id) => addTag(id));

			await Promise.all(listPromise);
			messageApi.open({
				type: "success",
				content: "Đã hoàn thành đánh dấu tester",
				duration: 2,
			});
			setLoadingCheckSample(false);
		},
		[idProductSyncs.length]
	);

	useEffect(() => {
		(async () => {
			const res = await new TagRepository().getItems({
				filters: {
					...TagRepository.getDefaultFilters(),
					type: 6,
					is_root: -1,
				},
			});
			if (!res.hasError()) {
				setTags(res.items);
			}
		})();
	}, []);

	/** Get inventories for product from Inventories API */
	const [productInvestories, setProductInvestories] = useState<
		ProductWarehouseModel[]
	>([]);

	const fetchProductInventories = useCallback(async () => {
		const response = await new ProductWarehouseRepository().getItems({
			filters: {
				...ProductWarehouseRepository.getDefaultFilters(),
				warehouse_id: filters.warehouse_id,
				item_idlist:
					dataSource.length > 0
						? dataSource.map((source) => source.id).join(",")
						: "",
			},
		});
		setProductInvestories(response.items);
	}, [filters.warehouse_id, dataSource]);

	useEffect(() => {
		if (filters.warehouse_id > -1) {
			fetchProductInventories();
		}
	}, [filters.warehouse_id]);
	/** End get inventories for product from Inventories API */

	//Table columns
	const columns: TableColumnsType<ProductPublicModel> = [
		{
			title: (
				<Checkbox
					onChange={(e) => {
						const value = e.target.checked;
						value
							? setIdProductSyncs(dataSource.map((i) => i.id))
							: setIdProductSyncs([]);
					}}></Checkbox>
			),
			key: "images",
			align: "center",
			width: 50,
			render: (images: FileJson[], record) => {
				return (
					<div>
						<Checkbox
							onChange={(e) => {
								const value = e.target.checked;
								value
									? setIdProductSyncs((prev) => [...prev, record.id])
									: setIdProductSyncs((prev) =>
											prev.filter((i) => i !== record.id)
									  );
								// setIdProductSyncs(prev);
							}}
							checked={idProductSyncs.some((i) => i === record.id)}></Checkbox>
					</div>
				);
			},
		},
		{
			title: t("product:image_short"),
			key: "images",
			align: "center",
			width: 50,
			render: (images: FileJson[], record) => {
				return images.length > 0 ? (
					<div onClick={(e) => e.stopPropagation()}>
						<Image
							width="40"
							src={images[0].url}
							preview={{
								src: images[0].url,
								mask: <IconEye size="16" />,
							}}
						/>
					</div>
				) : (
					<NoPhoto width={40} height={40} />
				);
			},
		},

		{
			title: t("product:name"),
			key: "name",
			sortby: "name",
			width: 200,
			render: (name: string, record) => {
				return (
					<>
						{/* <>
              {record.sell_on_zero === 1 ? (
                <div className="float-right pt-2">
                  <Tooltip
                    title={t("product:sell_on_zero_tag")}
                    mouseEnterDelay={0.4}
                  >
                    <IconLockOpen className="text-green-500" size={18} />
                  </Tooltip>
                </div>
              ) : (
                <></>
              )}
            </> */}

						<div className=" text-md font-bold line-clamp-2">
							{record.count_childs > 1 ? `(${record.count_childs} size) ` : ""}
							{record.count_childs > 1
								? record.name
								: record.full_name
								? record.full_name
								: record.name}
						</div>
						{/* <div className="text-xs text-gray-400 mt-1">{record.sku}</div> */}
					</>
				);
			},
		},
		{
			title: t("product:sku"),
			key: "sku",
			width: 120,
			align: "center",
		},
		{
			title: t("tồn kho tại cửa hàng"),
			key: "quantity",
			sortby: "quantity",
			width: 200,
			render: (_, record) => {
				const dataStoreFilter = productInvestories.find(
					(inventory) => inventory.item_id === record.id
				);

				if (filters.warehouse_id > -1 && dataStoreFilter) {
					return (
						<>
							<Row gutter={16}>
								<Col span={12}>
									<Statistic
										title={
											<Tooltip
												title={
													<Typography.Text className="text-white">
														Số lượng khách đang đặt hàng trên website
													</Typography.Text>
												}>
												Khách đặt
											</Tooltip>
										}
										value={dataStoreFilter.reserved}
										valueStyle={{ color: "#3f8600" }}
									/>
								</Col>
								<Col span={12}>
									<Statistic
										title={
											<Tooltip
												title={
													<Typography.Text className="text-white">
														Số lượng tồn kho thực tế trong kho
													</Typography.Text>
												}>
												Tồn kho
											</Tooltip>
										}
										value={dataStoreFilter.quantity}
										valueStyle={{ color: "#cf1322" }}
										prefix={
											dataStoreFilter.quantity < 0 ? (
												<IconMinus></IconMinus>
											) : null
										}
									/>
								</Col>
							</Row>
						</>
					);
				}
				return (
					<>
						<Tag> Không có thông tin</Tag>
					</>
				);
			},
		},
		{
			title: t("product:total_quantity"),
			key: "quantity",
			width: 150,
			render: (quantity: number, record) => {
				const caseQuatity = {
					isZero: quantity === 0,
					isNegative: quantity < 0,
				};
				return (
					<>
						<Typography.Text
							className={cn("", {
								"text-yellow-500": caseQuatity.isZero,
								"text-red-500": caseQuatity.isNegative,
							})}>
							{" "}
							{quantity}
						</Typography.Text>
					</>
				);
			},
		},

		{
			title: t("product:price_tax"),
			key: "vat",
			width: 150,
			render: (vat: number, record) => {
				return (
					<>
						<InputNumber<number>
							disabled
							defaultValue={vat}
							min={0}
							max={100}
							formatter={(price_tax) => `${price_tax}%`}
							parser={(price_tax) =>
								price_tax?.replace("%", "") as unknown as number
							}
							// onChange={onChange}
						/>
					</>
				);
			},
		},
		{
			title: t("product:price"),
			key: "price",
			sortby: "price",
			width: 160,
			render: (price: number) => {
				return <p>{convertNumberToPriceVND(price)}</p>;
			},
		},

		{
			title: t("common:view"),
			key: "show_web",
			render: (show_web: number, record) => {
				return (
					<Flex gap={2}>
						{show_web <= 0 && record.show_pos <= 0 ? (
							<>
								<Tag>Ẩn</Tag>
							</>
						) : (
							<>
								{show_web > 0 && <Tag color="blue-inverse">Website</Tag>}

								{record.show_pos > 0 && <Tag color="orange-inverse">Pos</Tag>}
							</>
						)}
					</Flex>
				);
			},
			width: 150,
		},
		{
			title: t("order:date_created"),
			key: "created_at",
			width: 140,
			render: (ts: number) => {
				return (
					<>
						<span>{Helper.formatTimestampToText2(ts)}</span>
						{/* <TextDateTime ts={ts} format="HH:mm, DD/MM/YYYY"></TextDateTime> */}
					</>
				);
			},
		},
		{
			title: t("common:status"),
			key: "status",
			sortby: "status",
			render: (status: number) => {
				let statusInfo = ProductPublicModel.getStatus(status);
				return (
					<span
						className={
							status === ProductStatus.UNACTIVE
								? "text-red-500"
								: " text-green-500"
						}>
						{statusInfo?.label}
					</span>
				);
			},
			width: 110,
		},
		{
			title: " ",
			toggletype: "trigger",
			key: "actions",
			align: "right",
			fixed: "right",
			width: 200,
			render: (_: any, record: ProductPublicModel) => {
				// const unitInfo = ProductPublicModel.getUnit(record.unit);

				return (
					<RoleCheck roles={[Role.PRODUCT_MANAGE]} hideOnFail>
						<div onClick={(e) => e.stopPropagation()}>
							<TableEdit
								label=""
								link={``}
								onClick={() =>
									!loadingSync?.[record.id] && handleSyncKiotviet(record.id)
								}
								tooltip="Đồng bộ kiotviet"
								icon={
									<SyncOutlined
										spin={loadingSync?.[record.id] || false}></SyncOutlined>
								}
							/>
							<TableEdit link={`/product/edit/id/${record.id}`} />

							{/* <Button
								size={"small"}
								type={"text"}
								onClick={() => onEditTag(record.id)}>
								<Tooltip title="Quản lý phân loại sản phẩm (tag)">
									<IconTag
										className="-mt-0.5 text-gray-400 hover:text-red-500"
										size="18"
									/>
								</Tooltip>
							</Button>
							<Button
								size={"small"}
								type={"text"}
								onClick={() => onEditProductRelated(record)}>
								<Tooltip title="Sản phẩm liên quan">
									<IconPerfume
										className="-mt-0.5 text-gray-400 hover:text-red-500"
										size="18"
									/>
								</Tooltip>
							</Button>
							<TableDelete
								error_translate_prefix="product:form.error"
								onDeleteCallback={(id) => {
									setDataSource(dataSource.filter((item) => item.id !== id));
								}}
								repository={new ProductRepository()}
								id={record.id}
							/> */}
						</div>
					</RoleCheck>
				);
			},
		},
	];

	const onEditTag = (id: number) => {
		setEditingTagId(id);
		setAddTagVisible(true);
	};
	if (loading) {
		return <Skeleton></Skeleton>;
	}

	if (storeList.length <= 0)
		return <Result status="warning" title={errors.join(".")} />;

	return (
		<>
			{contextMessage}
			<ProductListHeader>
				{enableResyncProductCompanyResource ? (
					<Button
						type="default"
						className="mr-3"
						onClick={() => {
							setFormProductSyncVisible(true);
						}}>
						{t("product:sync_product")}
					</Button>
				) : null}
				<RoleCheck roles={[Role.PRODUCT_MANAGE]}>
					<Link to={"/product/add"}>
						<Button type="primary">{t("common:table.add_button")}</Button>
					</Link>
				</RoleCheck>
			</ProductListHeader>
			<ProductListFilter<FilterProduct>
				// list_type={types.map((item) => {
				//   return { label: item.name, value: item.code };
				// })}
				listTags={tags.map((item) => {
					return { label: item.name, value: item.code };
				})}
				filters={filters}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
				total={total}
			/>
			<PageDataTable<FilterProduct, ProductPublicModel, ProductPublicCollection>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					expandable,
					tableColumnToggleKey: "product",
					rowClassName: "cursor-pointer",
				}}></PageDataTable>

			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>
			{idProductSyncs.length > 0 && (
				<FloatButton.Group
					trigger="click"
					type="primary"
					style={{ insetInlineEnd: 24 }}
					icon={<ToolOutlined />}>
					<FloatButton
						tooltip="Đồng bộ Kioviet"
						icon={<SyncOutlined />}
						onClick={() => handleSyncAll()}
					/>

					{/* <FloatButton
						tooltip="Đánh dấu tester"
						icon={<PieChartOutlined spin={loadingCheckSample} />}
						onClick={() => handleCheckedSample(idProductSyncs)}
					/> */}
				</FloatButton.Group>
			)}

			{/* {enableResyncProductCompanyResource ? (
        <ProductSyncFormModal
          key={"product-sync-modal"}
          open={formProductSyncVisible}
          setOpen={(isOpen) => {
            setFormProductSyncVisible(isOpen);
          }}
        />
      ) : null} */}
			{/* <ProductRelatedModal
        id={editingProductRelatedId}
        data={chooseProductRelated}
        key={editingProductRelatedId}
        open={addProductRelatedVisible}
        setOpen={(isOpen) => {
          //clear editing id when close
          if (!isOpen) {
            setEditingProductRelatedId(0);
          }
          setAddProductRelatedVisible(isOpen);
        }}
        onSaveSuccess={onSaveSuccess}
      /> */}

			{/* <ProductTagModal
        id={editingTagId}
        key={editingTagId}
        open={addTagVisible}
        setOpen={(isOpen) => {
          if (!isOpen) {
            setEditingTagId(0);
          }
          setAddTagVisible(isOpen);
        }}
        onSaveSuccess={onSaveSuccess}
      /> */}
		</>
	);
};

export default ProductList;
