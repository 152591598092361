import { App, Button, Col, Divider, Row, Spin, Tag, Tooltip } from "antd";
import TaxInvoiceModel from "common/models/TaxInvoiceModel";
import TaxInvoiceGatewayRepository from "common/repositories/TaxInvoiceGatewayRepository";
import LayoutError from "components/LayoutError";
import { useCallback, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";

import { IconRefresh } from "@tabler/icons-react";
import TaxInvoice from "common/constants/TaxInvoice";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

const TaxInvoiceItemIssueSync = ({
  model,
  updateCallback,
}: {
  model: TaxInvoiceModel;
  updateCallback: (v: TaxInvoiceModel) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const initCreateRemoteLockRef = useRef(false);
  const [showErrorRequestPayload, setShowErrorRequestPayload] = useState(false);
  const [processing, setProcessing] = useState(false);

  /**
   * Create order on remote carrier
   */
  const doCreateRemoteOrder = useCallback(
    async (id: number) => {
      initCreateRemoteLockRef.current = true;

      setProcessing(true);

      let item: TaxInvoiceModel =
        await new TaxInvoiceGatewayRepository().createRemoteOrder(id);

      setProcessing(false);
      if (item.hasError()) {
        message.error({
          content: (
            <LayoutError
              onClickClose={() => {
                message.destroy("message");
              }}
              heading={t("common:form.error.heading")}
              translate_prefix="taxinvoice:form.error"
              items={item.error.errors}
            />
          ),
          className: "message_error",
          key: "message",
          duration: 7,
        });
      } else {
        message.success({
          content: t("taxinvoice:form.success.update"),
          className: "message_success",
          key: "message",
          duration: 2,
        });

        updateCallback(item);
      }
    },
    [t, updateCallback, setProcessing, message]
  );

  /**
   * Sync shippingbill STATUS to API-type shippingcarrier
   */
  const doSyncStatus = useCallback(
    async (id: number) => {
      setProcessing(true);

      let item: TaxInvoiceModel =
        await new TaxInvoiceGatewayRepository().syncStatus(id);

      setProcessing(false);
      if (item.hasError()) {
        message.error({
          content: (
            <LayoutError
              onClickClose={() => {
                message.destroy("message");
              }}
              heading={t("common:form.error.heading")}
              translate_prefix="taxinvoice:form.error"
              items={item.error.errors}
            />
          ),
          className: "message_error",
          key: "message",
          duration: 4,
        });
      } else {
        message.success({
          content: t("taxinvoice:form.success.update"),
          className: "message_success",
          key: "message",
          duration: 2,
        });

        updateCallback(item);
      }
    },
    [t, updateCallback, setProcessing, message]
  );

  //   /**
  //    * Remove current shippingbill
  //    */
  //   const doRemoveTaxInvoice = useCallback(
  //     async (id: number) => {
  //       setProcessing(true);
  //       const deletedErrors = await new TaxInvoiceRepository().deleteItem(id);
  //       setProcessing(false);
  //       if (deletedErrors.length === 0) {
  //         message.success({
  //           content: t("common:table.delete_item_success"),
  //           className: "message_success",
  //           key: messageKeyDeletePrefix + id,
  //           duration: 1,
  //         });

  //         deleteCallback(id);
  //       } else {
  //         message.error({
  //           content: (
  //             <LayoutError
  //               onClickClose={() => {
  //                 message.destroy(messageKeyDeletePrefix + id);
  //               }}
  //               heading={t("common:error.table_delete_heading")}
  //               translate_prefix={"taxinvoice:form.error"}
  //               items={deletedErrors}
  //             />
  //           ),
  //           className: "message_error",
  //           key: messageKeyDeletePrefix + id,
  //           duration: 10,
  //         });
  //       }
  //     },
  //     [t, deleteCallback, setProcessing, message]
  //   );

  //   useEffect(() => {
  //     if (
  //       shipping_bill.gateway_status === TaxInvoice.GATEWAY_STATUS_PENDING &&
  //       initCreateRemoteLockRef.current !== true
  //     ) {
  //       doCreateRemoteOrder(shipping_bill.id);
  //     }
  //   }, [shipping_bill, doCreateRemoteOrder]);

  //   const removeComponent = (
  //     <Popconfirm
  //       title={t("common:table.confirm_delete_title")}
  //       placement="topRight"
  //       onConfirm={() => {
  //         doRemoveTaxInvoice(model.id);
  //       }}
  //       okText={t("common:table.confirm_ok")}
  //       cancelText={t("common:table.confirm_cancel")}
  //     >
  //       <Button
  //         loading={processing}
  //         disabled={processing || !allowEditInput}
  //         type="text"
  //         block
  //         icon={<IconTrash size={18} color={"gray"} />}
  //       ></Button>
  //     </Popconfirm>
  //   );

  //if not INIT, we show init form
  if (processing) {
    return (
      <div className="p-4 italic">
        <Spin /> &nbsp;{t("taxinvoice:init_remote_processing")}
      </div>
    );
  }

  let issueStatusInfo = TaxInvoiceModel.getIssueStatus(model.issue_status);

  return (
    <div>
      {model.issue_provider === 0 ? (
        <Row>
          <Col>
            <span className="text-gray-400">
              {t("taxinvoice:issue_provider_not_set")}
            </span>
            <br />
            <Button
              className="mt-1"
              loading={processing}
              type="primary"
              size="small"
              ghost
              disabled={processing}
              onClick={() => doCreateRemoteOrder(model.id)}
              icon={<IconRefresh size={18} className="mr-1 -mt-0.5" />}
            >
              {t("taxinvoice:init_remote_button")}
            </Button>
          </Col>
          {/* <Col flex={"50px"}>{removeComponent}</Col> */}
        </Row>
      ) : (
        <div className="">
          <div>
            <span className="text-gray-400">
              {t("taxinvoice:issue_transaction_id")}: &nbsp;
            </span>

            <Link
              target="_blank"
              to={model.getRemoteUrl()}
              className="text-gray-800 font-bold text-xs hover:underline"
              title={t("taxinvoice:issue_transaction_id_link_tooltip")}
            >
              {model.issue_transaction_id}
            </Link>
          </div>
          <div>
            <span className="text-gray-400">
              {t("taxinvoice:issue_ymd")}: &nbsp;
            </span>
            {dayjs(model.issue_ymd.toString(), "YYYYMMDD").format("YYYY-MM-DD")}
          </div>
          <div>
            <span className="text-gray-400">
              {t("taxinvoice:issue_status")}: &nbsp;
            </span>
            <Tag
              closable
              color={issueStatusInfo?.color}
              closeIcon={
                <Tooltip
                  title={t("taxinvoice:sync_button")}
                  mouseEnterDelay={0.4}
                >
                  <IconRefresh
                    size={16}
                    className="text-blue-500 hover:text-black -mr-0.5"
                  />
                </Tooltip>
              }
              onClose={() => {
                doSyncStatus(model.id);
                return false;
              }}
            >
              {issueStatusInfo?.label}
            </Tag>
          </div>
        </div>
      )}

      <Row gutter={8}>
        <Col span={24}>
          {!processing &&
          model.issue_status === TaxInvoice.ISSUE_STATUS_FAILED ? (
            <div className="mt-2 text-xs">
              {t("taxinvoice:last_sync_error")} <br />
              <span className="text-red-500 ">
                {/* <span className="font-bold">
                  {shipping_bill.gateway_log.error}
                </span> */}
                {typeof model.issue_request === "string" &&
                model.issue_request.length > 0 ? (
                  <span
                    className="text-blue-400 cursor-pointer"
                    onClick={() =>
                      setShowErrorRequestPayload(!showErrorRequestPayload)
                    }
                  >
                    [Show Request Data]
                  </span>
                ) : null}
              </span>
              {showErrorRequestPayload &&
              typeof model.issue_request === "string" &&
              model.issue_request.length > 0 ? (
                <div className="w-full p-4 bg-red-50">
                  <div>Request Data:</div>
                  <Scrollbars
                    style={{
                      height: 200,
                      overflow: "hidden",
                    }}
                  >
                    <pre>
                      {JSON.stringify(JSON.parse(model.issue_request), null, 2)}
                    </pre>
                  </Scrollbars>
                  <Divider />
                  <div>Response Data:</div>
                  <Scrollbars
                    style={{
                      height: 200,
                      overflow: "hidden",
                    }}
                  >
                    <pre>
                      {JSON.stringify(
                        JSON.parse(model.issue_response),
                        null,
                        2
                      )}
                    </pre>
                  </Scrollbars>
                </div>
              ) : null}
            </div>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default TaxInvoiceItemIssueSync;
