import { CheckOutlined } from "@ant-design/icons";
import { Empty, FormItemProps, Typography } from "antd";
import { AutoComplete, Form, Input } from "antd";
import { DefaultOptionType } from "antd/es/select";
import StoreCollection from "common/collections/StoreCollection";
import PublicationsRepository from "common/repositories/PublicationsRepository";
import StoreRepository from "common/repositories/StoreRepository";
import { StoreJson, FilterStore } from "common/types/Store";
import { FilterStory, StoryJson } from "common/types/Story";
import MultiSearchLayout from "components/search/MultiSearchLayout";
import SearchComponent from "components/search/SearchLayout";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
type Props = FormItemProps & {
	disabled?: boolean;
	isMulti?: boolean;
};

const StoreSearch = (props: Props) => {
	const { t } = useTranslation();
	if (props?.isMulti) {
		return (
			<>
				<MultiSearchLayout<StoreJson, FilterStore, StoreCollection>
					fetchRepository={(filers) => {
						return new StoreRepository().getItems({
							filters: filers,
						});
					}}
					defaultFilters={StoreRepository.getDefaultFilters()}
					formItemProps={{
						...props,
					}}
					disabled={props.disabled}
					keyValue="id"
					showFilters={["keyword"]}
					defaultUseFilters={["keyword"]}
					renderLabel={(item) => {
						return <>{item.name}</>;
					}}
				/>
			</>
		);
	}
	return (
		<>
			{}

			<SearchComponent<StoreJson, FilterStore, StoreCollection>
				fetchRepository={(filers) => {
					return new StoreRepository().getItems({
						filters: filers,
					});
				}}
				defaultFilters={StoreRepository.getDefaultFilters()}
				formItemProps={{
					...props,
				}}
				disabled={props.disabled}
				keyValue="id"
				showFilters={["keyword"]}
				defaultUseFilters={["keyword"]}
				renderLabel={(item) => {
					return <>{item.name}</>;
				}}
			/>
		</>
	);
};

export default StoreSearch;
