import { useTranslation } from "react-i18next";
import useStateFilter from "hooks/useStateFilter";
import useFilterLocation from "hooks/useFilterLocation";
import React, { useCallback, useMemo, useState } from "react";
import {
	Button,
	TableColumnsType,
	Tag,
	Tooltip,
	Typography,
	message,
} from "antd";

import RoleCheck from "components/RoleCheck";
import StoryListFilter from "./StoryListFilter";
import StoryListHeader from "./StoryListHeader";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableDelete from "components/table/TableDelete";
import PageDataTable from "components/page/PageDataTable";

import Role from "common/constants/Role";
import StoryModel from "common/models/StoryModel";
import { FilterStory, StoryJson } from "common/types/Story";
import StoryCollection from "common/collections/StoryCollection";
import StoryRepository from "common/repositories/StoryRepository";
import PageDataPagination from "components/page/PageDataPagination";
import { IconTag } from "@tabler/icons-react";
import StoryTagModal from "../tag/StoryTagModal";
import { Link } from "react-router-dom";
import Helper from "common/utils/helper";

const StoryList = () => {
	const { t } = useTranslation();

	// default filter
	const defaultFilters = useMemo(() => {
		return StoryRepository.getDefaultFilter();
	}, []);

	//state
	const [total, setTotal] = useState<number>(0);
	const [dataSource, setDataSource] = useState<StoryModel[]>([]);
	const [filters, setFilters] = useStateFilter(defaultFilters);
	useFilterLocation(defaultFilters, filters);

	//modal tag
	const [addTagVisible, setAddTagVisible] = useState(false);
	const [editingTagId, setEditingTagId] = useState(0);
	const onEditTag = (id: number) => {
		setEditingTagId(id);
		setAddTagVisible(true);
	};

	//Table columns
	const columns: TableColumnsType<StoryModel> = [
		{
			title: t("common:columns.id"),
			key: "id",
			width: 100,
			render: (id) => {
				return <Tag color="blue">{id}</Tag>;
			},
		},
		{
			title: t("common:columns.title"),
			key: "title",
			width: 200,
			render: (title: string) => <p className="line-clamp-2 m-0">{title}</p>,
		},
		{
			title: t("common:date_created"),
			key: "date_created",
			width: 120,
			render: (date_created) => Helper.formatTimestampToText2(date_created),
		},
		{
			title: t(""),
			key: "actions",
			width: 100,
			align: "right",
			fixed: "right",
			render: (_: any, record: StoryJson) => (
				<RoleCheck roles={[Role.SETTING_HRM]} hideOnFail>
					{/* <a
						href={`http://localhost:3000/#/story/edit/id/${record.id}`}
						target="_blank">
						Edit Local
					</a> */}
					<TableEdit link={`/story/edit/id/${record.id}`} />
					<Button
						size={"small"}
						type={"text"}
						onClick={() => onEditTag(record.id)}>
						<Tooltip title="Quản lý tag">
							<IconTag
								className="-mt-0.5 text-gray-400 hover:text-red-500"
								size="18"
							/>
						</Tooltip>
					</Button>
					<TableDelete
						error_translate_prefix="department:form.error"
						onDeleteCallback={(id) => {
							fetchData();
						}}
						repository={new StoryRepository()}
						id={record.id}
					/>
					<TableInfo record={record} />
				</RoleCheck>
			),
		},
	];

	//function
	const fetchData = useCallback(async (): Promise<StoryCollection> => {
		const response = await new StoryRepository().getItems({
			filters: filters,
		});

		if (response) {
			setTotal(response.total);
			setDataSource(response.items);
		} else {
			message.error(t("common:error.error_fetching_data"));
		}

		return response;
	}, [filters]);

	return (
		<div>
			<StoryListHeader />
			<StoryListFilter
				total={total}
				filters={filters}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
			/>
			<PageDataTable<FilterStory, StoryModel, StoryCollection>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					tableColumnToggleKey: "story",
				}}
			/>
			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>

			<StoryTagModal
				id={editingTagId}
				key={editingTagId}
				open={addTagVisible}
				setOpen={(isOpen) => {
					//clear editing id when close
					if (!isOpen) {
						setEditingTagId(0);
					}
					setAddTagVisible(isOpen);
				}}
			/>
		</div>
	);
};

export default StoryList;
