import ContactModel from "common/models/ContactModel";
import {
  ContactCollectionJson,
  ContactJson,
} from "common/types/Contact";
import BaseCollection from "./BaseCollection";

class ContactCollection extends BaseCollection<
  ContactJson,
  ContactModel,
  ContactCollectionJson
> {
  itemsFromJson(jsonItems: ContactJson[]): ContactModel[] {
    return jsonItems.map((item) => new ContactModel(item));
  }
}
export default ContactCollection;
