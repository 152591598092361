import ShipperKPIApi from "common/api/ShipperKPIApi";
import ShipperKPICollection from "common/collections/ShipperKPICollection";
import { GetListProps } from "common/types/Filter";
import { FilterShipperKPI } from "common/types/ShipperKPI";
import BaseRepository from "./BaseRepository";

class ShipperKPIRepository extends BaseRepository<ShipperKPIApi> {
  _api: ShipperKPIApi | null;

  constructor() {
    super();
    this._api = new ShipperKPIApi(true);
  }

  static getDefaultFilters(): FilterShipperKPI {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      employee_id: -1,
      shift: "",
      coordinator: -1,
    };
  }

  async getItems(props: GetListProps<FilterShipperKPI>) {
    return this._api
      ? await this._api.getList(props)
      : new ShipperKPICollection();
  }
}

export default ShipperKPIRepository;
