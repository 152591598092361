import { App, Col, Form, Input, Row } from "antd";
import Error from "components/LayoutError";
import FormChangedContext from "contexts/FormChangedContext";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useFormDefaultStore from "zustands/useFormDefaultStore";

import TagModel from "common/models/TagModel";
import TagRepository from "common/repositories/TagRepository";
import { TagAddEditJson, TagProductStructAddEditJson } from "common/types/Tag";
import FormSelect from "components/form/FormSelect";
import TagTypeFormSelect from "features/tag/form/TagTypeFormSelect";
import TagConstant from "common/constants/Tag";
const ProductRelatedAddTagForm = ({
	model,
	productId,
	setOpen,
	setProcessing,
	onSaveSuccess,
}: {
	model: TagModel;
	productId: number;
	setOpen: (v: boolean) => any;
	setProcessing: (p: boolean) => any;
	onSaveSuccess: (o: TagModel) => void;
}) => {
	console.log(model);
	const { t } = useTranslation();
	const { message } = App.useApp();
	const formChangedProvider = useContext(FormChangedContext);

	const [getValue, setValue] = useFormDefaultStore((state) => [
		state.getValue,
		state.setValue,
	]);

	const [type, setType] = useState<number>(model.type_id);

	const [form] = Form.useForm();
	const initialValues = useMemo(() => {
		return {
			tag_name: "",
			status: model.status > 0 ? model.status : 1,
			tag_value: "",
		};
	}, [model, getValue]);

	//prepare data for submit
	const doPrepareData = useCallback(
		(formData: any) => {
			const submitData: TagProductStructAddEditJson = {
				details: [
					{
						resource_id: [productId],
						resource_type: TagConstant.RESOURCE_TYPE.PRODUCT,
						status: formData.status,
						tag_name: formData.tag_name,
						tag_value: formData.tag_name + "-" + productId,
						type_id: TagConstant.TYPE.PRODUCT_STRUCT,
					},
				],
			};

			return submitData;
		},
		[model.id, model.code, setValue, type]
	);

	//submit data to server
	const onSubmit = async (formData: any) => {
		setProcessing(true);
		message.loading({
			content: t("common:form.processing"),
			key: "message",
			duration: 0,
		});

		let item: TagModel = await new TagRepository().updateTagProductStruct(
			doPrepareData(formData)
		);

		setProcessing(false);
		if (item.hasError()) {
			message.error({
				content: (
					<Error
						onClickClose={() => {
							message.destroy("message");
						}}
						heading={t("common:form.error.heading")}
						translate_prefix="tag:form.error"
						items={item.error.errors}
					/>
				),
				className: "message_error",
				key: "message",
				duration: 4,
			});
		} else {
			message.success({
				content: t("common:form.success.save"),
				className: "message_success",
				key: "message",
				duration: 2,
			});
			onSaveSuccess(item);
			setOpen(false);
		}
	};

	return (
		<Form
			layout="vertical"
			initialValues={initialValues}
			form={form}
			onFinish={onSubmit}
			id={formChangedProvider.id}
			onFieldsChange={() => formChangedProvider.setChanged(true)}>
			<Row gutter={16}>
				<Col md={24} xs={24}>
					<Form.Item
						label={t("tag:name")}
						name="tag_name"
						rules={[
							{
								required: true,
								message: t("tag:form.error.error_name_required"),
							},
						]}
						className="mb-0">
						<Input />
					</Form.Item>
				</Col>
			</Row>

			{/* <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item
            label={t("tag:value")}
            name="tag_value"
            className="mb-0"
            rules={[
              {
                required: true,
                message: t("tag:form.error.error_value_required"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row> */}

			<Row gutter={16}>
				<Col md={24} xs={24}>
					<FormSelect
						label={t("tag:status")}
						name="status"
						placeholder={"Trạng thái"}
						options={TagModel.getStatusSelectList()}
						popupMatchSelectWidth={false}
					/>
				</Col>
			</Row>
		</Form>
	);
};

export default ProductRelatedAddTagForm;
