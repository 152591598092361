import { MoreOutlined } from "@ant-design/icons";
import {
	Button,
	Dropdown,
	Flex,
	MenuProps,
	message,
	Tag,
	Typography,
} from "antd";
import ContactCollection from "common/collections/ContactCollection";
import Contact from "common/constants/Contact";
import SettingApi from "common/constants/Setting";
import ContactModel from "common/models/ContactModel";
import SettingModel from "common/models/SettingModel";
import ContactRepository from "common/repositories/ContactRepository";
import { FilterContact } from "common/types/Contact";
import { TableColumnsType } from "common/types/Table";
import TextDateTime from "components/TextDateTime";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import useFilterLocation from "hooks/useFilterLocation";
import useSettingValue from "hooks/useSettingValue";
import useStateFilter from "hooks/useStateFilter";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ContactListExpand from "./ContactListExpand";
import ContactListFilter from "./ContactListFilter";
import ContactListHeader from "./ContactListHeader";

const ContactList = () => {
	const { t } = useTranslation();
	const {
		setting: settingContactCommentOn,
		isLoading: isLoadingSettingContact,
	} = useSettingValue(SettingApi.KEY.setting_contact_contents);

	//////////////////////////////////////////
	//Filtering
	const defaultFilters: FilterContact = useMemo(
		() => ContactRepository.getDefaultFilters(),
		[]
	);

	//state
	const [isLoadingUpdate, setLoadingUpdate] = useState(false);
	const [total, setTotal] = useState<number>(0);
	const [dataSource, setDataSource] = useState<ContactModel[]>([]);
	const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
	const [filters, setFilters] = useStateFilter<FilterContact>(defaultFilters);
	useFilterLocation(defaultFilters, filters);

	//////////////////////////////////////////

	//////////////////////////////////////////
	//Fetch data from this collections
	const fetchData = useCallback(async (): Promise<ContactCollection> => {
		let collection = await new ContactRepository().getItems({
			filters,
		});

		if (collection) {
			setTotal(collection.total);
			setDataSource(collection.items);
		} else {
			message.error(t("common:error.error_fetching_data"));
		}

		return collection;
	}, [filters, t]);

	const onUpdateStatus = async (record: ContactModel, status: number) => {
		setLoadingUpdate(true);
		try {
			const response = await new ContactRepository().saveRemote({
				id: record.id,
				status,
			});
			if (!response.hasError()) {
				await fetchData();
			}
		} catch (error: any) {
			message.error(error);
		} finally {
			setLoadingUpdate(false);
		}
	};

	//////////////////////////////////////////
	//Table columns
	const columns: TableColumnsType<ContactModel> = [
		{
			title: "ID",
			key: "id",
			width: 80,
		},
		{
			title: t("contact:table_header.comments_on"),
			key: "comments_on",
			width: 300,
			render(comments_on) {
				const commentsOnArr = comments_on.split(",");
				let com = <></>;
				if (commentsOnArr.length <= 0) {
					com = <Tag>Không có thông tin</Tag>;
				}
				com = (
					<Flex wrap gap={4}>
						{commentsOnArr.map((commentOn: string) => {
							console.log("🚀 ~ {commentsOnArr.map ~ commentOn:", commentOn);
							const commentLabel = settingContactCommentOn.value
								? settingContactCommentOn.value.find(
										(setting: SettingModel) => {
											const label = setting?.value;
											return setting.value === commentOn || label === commentOn;
										}
								  )?.label
								: "";

							if (!commentLabel) {
								return <Tag>Không có thông tin</Tag>;
							}

							return <Tag color="blue-inverse">{commentLabel}</Tag>;
						})}
					</Flex>
				);
				return com;
			},
		},
		{
			title: t("contact:table_header.email"),
			key: "email",
			width: 200,
			render: (email) => {
				if (email.length === 0) return "--";
				return email;
			},
		},
		{
			title: t("contact:table_header.full_name"),
			key: "full_name",
			width: 200,
			render: (full_name) => {
				if (full_name.length === 0) return "--";
				return full_name;
			},
		},
		{
			title: t("contact:table_header.phone"),
			key: "phone",
			width: 100,
			render: (phone) => {
				if (!Number(phone)) return "--";
				return (
					<Typography.Text className="text-blue-500" copyable>
						{phone}
					</Typography.Text>
				);
			},
		},
		{
			title: t("contact:table_header.status"),
			key: "status",
			width: 100,
			render: (status: number) => {
				let statusInfo = ContactModel.getStatus(status);
				return (
					<Tag bordered color={statusInfo?.color}>
						{statusInfo?.label}
					</Tag>
				);
			},
		},
		{
			title: t("contact:table_header.date_created"),
			key: "date_created",
			width: 120,
			render(_, record) {
				return (
					<TextDateTime ts={record.date_created} format="HH:mm, DD/MM/YYYY" />
				);
			},
		},
		{
			title: " ",
			toggletype: "trigger",
			key: "actions",
			align: "right",
			fixed: "right",
			width: 100,
			render: (_: number, record) => {
				const items: MenuProps["items"] = [
					{
						key: "1",
						label: (
							<Button
								type="text"
								title="Đã xử lý"
								onClick={() =>
									onUpdateStatus(
										record,
										record.status === Contact.STATUS_NEW
											? Contact.STATUS_CONTACTED
											: Contact.STATUS_NEW
									)
								}>
								{record.status === Contact.STATUS_NEW
									? "Đã xử lý"
									: "Chưa xử lý"}
							</Button>
						),
					},
				];
				return (
					<div onClick={(e) => e.stopPropagation()}>
						<Dropdown
							disabled={record.status === Contact.STATUS_CONTACTED}
							trigger={["click"]}
							menu={{ items }}>
							<MoreOutlined
								style={{ fontSize: "16pt" }}
								className="cursor-pointer"
							/>
						</Dropdown>
					</div>
				);
			},
		},
	];

	return (
		<>
			<ContactListHeader />
			<ContactListFilter
				total={total}
				filters={filters}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
			/>
			<PageDataTable<FilterContact, ContactModel, ContactCollection>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					isLoading: isLoadingSettingContact || isLoadingUpdate,
					tableColumnToggleKey: "contact",
					rowClassName: "cursor-pointer",
					expandable: {
						expandedRowRender: (record: ContactModel) => (
							<ContactListExpand record={record} />
						),
						expandedRowClassName: () =>
							"ant-table-expanded-row-dimmer [&_.ant-table]:!ms-0 [&_.ant-table]:!me-0 [&_.ant-table]:!mt-0 [&_.ant-table]:!mb-0",
						expandRowByClick: true,
						showExpandColumn: false,
						expandedRowKeys: expandedRowKeys,
						onExpand: (expanded: boolean, record: ContactModel) => {
							setExpandedRowKeys(
								expanded
									? [...expandedRowKeys, record.id]
									: expandedRowKeys.filter((key) => key !== record.id)
							);
						},
					},
				}}
			/>
			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>
		</>
	);
};

export default ContactList;
