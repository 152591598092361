import { Form, Input, Radio } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Filter } from "common/types/Filter";
import { InventoryStockNumberType } from "common/types/InventoryStock";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import ProductCategoryFormSelect from "features/productcategory/ProductCategoryFormSelect";
import { useTranslation } from "react-i18next";

const InventoryBySkuFilter = <F extends Filter>({
  filters,
  setFilters,
  defaultFilters,
  total,
  stockType,
  setStockType,
}: {
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
  total: number;
  stockType: InventoryStockNumberType;
  setStockType: (v: InventoryStockNumberType) => void;
}) => {
  const { t } = useTranslation();

  return (
    <PageDataFilterForm
      filters={filters}
      setFilters={setFilters}
      total={total}
      defaultFilters={defaultFilters}
    >
      <Form.Item name="skus">
        <TextArea
          rows={2}
          allowClear
          placeholder={t("inventory:filter_sku_keyword_placeholder")}
        />
      </Form.Item>


<div className="mt-1 mr-4">
          {t("inventory:stock_number_type")}
          <Radio.Group
            size="small"
            buttonStyle="solid"
            value={stockType}
            onChange={(e) => setStockType(e.target.value)}
          >
            <Radio.Button value="onhand">{t("inventory:onhand")}</Radio.Button>
            <Radio.Button value="allocated">
              {t("inventory:allocated")}
            </Radio.Button>
            <Radio.Button value="available">
              {t("inventory:available")}
            </Radio.Button>
            <Radio.Button value="incoming">
              {t("inventory:incoming")}
            </Radio.Button>
          </Radio.Group>
        </div>
    </PageDataFilterForm>
  );
};

export default InventoryBySkuFilter;
