import { IconEdit, IconPlus, IconTrash, IconX } from "@tabler/icons-react";
import {
	Button,
	Checkbox,
	Empty,
	InputNumber,
	List,
	Modal,
	Select,
	Skeleton,
	Tag,
	message,
} from "antd";
import StoreRepository from "common/repositories/StoreRepository";
import { ProductCategoryJson } from "common/types/ProductCategory";
import { FilterStore } from "common/types/Store";
import PageDataPagination from "components/page/PageDataPagination";
import useDatabaseTable from "hooks/useDatabaseTable";
import React, {
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import {
	DragDropContext,
	Draggable,
	DropResult,
	Droppable,
} from "react-beautiful-dnd";
import SettingModel from "common/models/SettingModel";
import ProductCollectionRepository from "common/repositories/ProductCollectionRepository";
import {
	DataChangeDislayOrder,
	FilterDetailProductCollection,
} from "common/types/ProductCollection";
import AddListToItemFilter from "features/productcollection/form/children/AddListToItemFilter";
import { DetailProductCollectionItem } from "common/types/ProductCollection";
import DetailProductCollectionModel from "common/models/DetailProductCollectionModel";
import FormEditDisplayOrder from "features/productcollection/form/children/FormEditDisplayOrder";
import ProductModel from "common/models/ProductModel";
import ProductRepository from "common/repositories/ProductRepository";
import { ProductJson2 } from "common/types/Product";
import CollectionRepository from "common/repositories/CollectionRepository";

type Props = {
	process?: "display" | "product";
	collection_id: number;
	renderItems: (item: ProductJson2) => React.ReactNode;
	children?: React.ReactNode;
};
const ListProductCollectionDetail = ({
	process,
	collection_id,
	children,
	renderItems,
}: Props) => {
	const [messageApi, context] = message.useMessage();

	const defaultFilters: FilterDetailProductCollection = useMemo(() => {
		return {
			...ProductCollectionRepository.getDefaultDetailFilters(),
			collection_id: collection_id,
			sortby: "display_order",
			sorttype: "DESC",
		};
	}, [collection_id]);

	const defaultProductFilters = useMemo(() => {
		return { ...ProductRepository.getDefaultFilters() };
	}, []);

	//state
	const [open, setOpen] = useState<boolean>(false);
	const [dataSource, setDataSouce] = useState<DetailProductCollectionModel[]>(
		[]
	);
	const [dataProduct, setDataProduct] = useState<ProductJson2[]>([]);

	const [filters, setFilters] =
		useState<FilterDetailProductCollection>(defaultFilters);
	const [total, setTotal] = useState<number>(0);

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");

	const [openDisplay, setOpenDisplay] = useState<boolean>(false);
	const [dataEdit, setDataEdit] = useState<DetailProductCollectionItem | null>(
		null
	);

	//fetch store list
	const defaultFiltersStore: FilterStore = useMemo(
		() => StoreRepository.getDefaultFilters(),
		[]
	);
	const [filtersStore, setFiltersStore] =
		useState<FilterStore>(defaultFiltersStore);
	const [storeValue, setStoreValue] = useState<SettingModel>(
		new SettingModel(SettingModel.getDefaultData())
	);

	//fetchDataStore
	// const fetchDataStoreId = useCallback(async (): Promise<SettingModel> => {
	// 	let collection = await new SettingRepository().getItem(
	// 		SettingApi.KEY.k_setting_id_store_web_shop,
	// 		""
	// 	);
	// 	if (!collection.hasError()) {
	// 		const convertId = JSON.parse(collection.value);
	// 		setStoreValue(
	// 			new StoreModel({ ...StoreModel.getDefaultData(), id: convertId })
	// 		);
	// 	}
	// 	return collection;
	// }, [filtersStore]);

	////////////////////////////////////
	const handleEdit = (item: DetailProductCollectionItem) => {
		setDataEdit(item);
		setOpenDisplay(true);
	};

	const handleChangeSort = async (value: string) => {
		setLoading(true);
		const sortBy = value.split("-")?.[0] || "";
		const sortType = value.split("-")?.[1] || "";
		sortBy.length > 0 &&
			sortBy.length > 0 &&
			setFilters((prev) => ({ ...prev, sortby: sortBy, sorttype: sortType }));
		const res = await new ProductCollectionRepository().saveRemote({
			id: collection_id,
			sort: {
				sort_by: sortBy,
				sort_type: sortType,
			},
		});
		if (!res.hasError()) {
			messageApi.success("Thay đổi filter thành công");
		} else {
			messageApi.error("Thay đổi filter thất bại");
		}
		setLoading(false);
	};

	const getDataDetail = async (
		f?: FilterDetailProductCollection,
		productIds?: number[]
	) => {
		const data =
			await new ProductCollectionRepository().getListDetailProductCollection({
				...filters,
				product_ids: f?.product_ids,
			});

		if (!data.hasError()) {
			const ids = data.items.map((i) => i.product_id);

			const dataProductIds =
				productIds ?? (await getDataProduct(ids)).map((p) => p.id);
			setTotal(data.total);
			console.log("🚀 ~ dataProductIds:", dataProductIds);

			setDataSouce(
				data.items.filter((i) => dataProductIds.includes(i.product_id))
			);
		}
		setLoading(false);
	};

	const getDataProduct = async (ids: number[]) => {
		const collection = await new ProductRepository().getItems({
			filters: {
				...defaultProductFilters,
				list_product_id: ids.join(","),
				parent_id: 0,
			},
		});

		if (!collection.hasError()) {
			setDataProduct(collection.toJson().items);
			return collection.toJson().items;
		} else {
			return [];
		}
	};

	///////////////////////////////

	// const renderCheckboxAll = () => {
	// 	if (dataSource.length > 0) {
	// 		return (
	// 			<>
	// 				<Checkbox
	// 					checked={
	// 						selectedValue.length === dataSource.length &&
	// 						dataSource.length > 0
	// 					}
	// 					disabled={dataSource.length === 0}
	// 					onChange={(e) => {
	// 						if (!e.target.checked) {
	// 							//untick
	// 							setSelectedValue([]);
	// 						} else {
	// 							//tick
	// 							setSelectedValue(
	// 								dataSource.map((product: any) => {
	// 									return {
	// 										label:
	// 											product.full_name && product.full_name.length > 0
	// 												? product.full_name
	// 												: product.name,
	// 										value: product.id,
	// 									};
	// 								})
	// 							);
	// 						}
	// 					}}>
	// 					{" "}
	// 					Chọn tất cả
	// 				</Checkbox>
	// 			</>
	// 		);
	// 	} else {
	// 		return <></>;
	// 	}
	// };

	const renderListItem = (
		item: ProductJson2,
		itemDetail: DetailProductCollectionModel
	): React.ReactNode => {
		if (true) {
			return (
				<>
					<List.Item className="w-full block">
						<div className="flex justify-between items-center w-full">
							<div className="shrink-0 basis-[100px]">
								<InputNumber
									disabled
									value={itemDetail.displayorder}></InputNumber>
							</div>
							<div className="flex gap-2 items-center">
								{/* <Checkbox
									checked={
										selectedValue.findIndex(
											(select) => select.value == item.id
										) >= 0
									}
									onChange={(e) => {
										if (!e.target.checked) {
											//untick
											if (!["productintag", "productrelated"].includes(type)) {
												let cloneSelectOptions = [...selectedValue];
												const findIndex = cloneSelectOptions.findIndex(
													(tmp) => tmp.value == item.id
												);
												if (findIndex >= 0) {
													cloneSelectOptions.splice(findIndex, 1);
													setSelectedValue(cloneSelectOptions);
												}
											}
										} else {
											//tick
											if (!["productintag", "productrelated"].includes(type)) {
												let cloneSelectOptions = [...selectedValue];
												cloneSelectOptions.push({
													label:
														item.full_name && item.full_name.length > 0
															? item.full_name
															: item.name,
													value: item.id,
												});
												console.log(cloneSelectOptions);
												setSelectedValue(cloneSelectOptions);
											}
										}
									}}
								/> */}
								{renderItems(item)}
							</div>
							<div className="flex-1 basis-[100px] shrink-0 text-right flex w-full gap-2 justify-end">
								{process === "display" && (
									<IconEdit
										onClick={() => {
											handleEdit(itemDetail.toJson());
										}}
										className="cursor-pointer hover:text-red-500"
									/>
								)}

								{/* {process === "product" && (
									<IconX
										onClick={() => {
											handleRemoveItem({
												label: item.name,
												value: item.id,
											});
										}}
										className="min-w-6 min-h-6 w-6 h-6 max-h-6 max-w-6 hover:text-red-500 cursor-pointer"
									/>
								)} */}
							</div>
						</div>
					</List.Item>
				</>
			);
		} else {
			return <></>;
		}
	};

	const handleDragData = async (formData: DataChangeDislayOrder) => {
		try {
			const res =
				await new ProductCollectionRepository().editDisplayOrderProductCollection(
					formData
				);

			if (res.length > 0) {
				messageApi.error("Thay đổi vị trí thất bại !");
				return true;
			} else {
				messageApi.success("Thay đổi vị trí thành công !");
				getDataDetail();
				return false;
			}
		} catch (error) {
			messageApi.error("Thay đổi vị trí thất bại !");
			return false;
		}
	};

	const handleDragEnd = async (result: DropResult) => {
		const captureDataSource = dataSource;
		if (!result.destination) return;

		const chooseItem = dataSource.find(
			(item) => item.id.toString() === result.draggableId
		);
		const dataSourceJson = dataSource.map((i) => i.toJson());
		const destinationItem: DetailProductCollectionItem = {
			...dataSourceJson[result.destination.index],
		};

		if (chooseItem && destinationItem) {
			// const response = await new TagRepository().updateTagDetailDisplay({
			//   display_order: destinationItem.display_order,
			//   tagdetail_id: Number(chooseItem.id),
			// });
			const switchDisplayOrder =
				destinationItem.displayorder > 0 &&
				destinationItem.displayorder !== chooseItem.displayorder
					? destinationItem.displayorder
					: destinationItem.displayorder + 1;
			const reorderedItems = Array.from(dataSource);
			const [removed] = reorderedItems.splice(result.source.index, 1);
			reorderedItems.splice(result.destination.index, 0, removed);
			setDataSouce(reorderedItems);
			await handleDragData({
				display_order: switchDisplayOrder,
				id: chooseItem.id,
			}).then((res) => {
				if (!res) {
					setDataSouce(captureDataSource);
				}
			});
		}
	};

	const handleSearchProduct = async (keyword: string) => {
		try {
			setLoading(true);

			const collection = await new ProductRepository().getItems({
				filters: {
					...defaultProductFilters,
					keyword: keyword,
					limit: 50,
					collection_id: collection_id,
					parent_id: 0,
				},
			});

			if (!collection.hasError()) {
				setDataProduct(collection.toJson().items);

				const productIds = collection.toJson().items.map((i) => i.id);
				if (productIds.length <= 0) {
					setDataSouce([]);
				} else {
					await getDataDetail(
						{
							...filters,
							product_ids: productIds.join(","),
						},
						productIds
					);
				}
			} else {
			}
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const options = [
		{ label: "Độ ưu tiên giảm dần", value: "display_order-DESC" },
		{ label: "Độ ưu tiên tăng dần", value: "display_order-ASC" },
		{ label: "Tên sản phẩm A-Z", value: "name-DESC" },
		{ label: "Tên sản phẩm Z-A", value: "name-ASC" },
		{ label: "Giá giảm dần", value: "price-DESC" },
		{ label: "Giá tăng dần", value: "price-ASC" },
		{ label: "Mới nhất", value: "id-DESC" },
		{ label: "Cũ nhất", value: "id-ASC" },
		{ label: "Tồn kho giảm dần", value: "sold_quantity-DESC" },
		{ label: "Tồn kho tăng dần", value: "sold_quantity-ASC" },
	];

	useEffect(() => {
		getDataDetail();
	}, []);

	//clear checkbox

	return (
		<div>
			{context}
			<div className="flex justify-between">
				<AddListToItemFilter
					defaultFilters={defaultFilters}
					filters={filters}
					setFilters={setFilters}
					total={total}
					type={"product"}
					onSearchKeyword={handleSearchProduct}
				/>

				<Select
					className="h-fit"
					defaultValue="display_order-DESC"
					style={{ width: 220 }}
					onChange={(value) => {
						handleChangeSort(value);
					}}
					options={options}
				/>
			</div>
			{/* <div className="flex justify-between items-center">
				{renderCheckboxAll()}
			</div> */}
			{/**List component */}
			{!loading ? (
				<>
					{dataSource.length ? (
						<DragDropContext onDragEnd={handleDragEnd}>
							<Droppable droppableId="droppable">
								{(provided) => (
									<div
										{...provided.droppableProps}
										ref={provided.innerRef}
										className="flex flex-col gap-2">
										{dataSource.map((item, index) => {
											const product = dataProduct.find(
												(i) => i.id === item.product_id
											);

											return (
												<Draggable
													key={item.id}
													draggableId={item.id.toString()}
													index={index}>
													{(provided) => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															className="shadow-sm"
															style={{
																userSelect: "none",
																backgroundColor: "white",
																...provided.draggableProps.style,
															}}>
															{product ? (
																renderListItem(product, item)
															) : (
																<Tag color="error">
																	Thôn tin sản phảm lỗi (Sản phẩm không tồn tại
																	hoặc b)
																</Tag>
															)}
														</div>
													)}
												</Draggable>
											);
										})}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
					) : (
						<Empty />
					)}
					{dataSource.length > 0 && (
						<PageDataPagination
							dataSource={dataSource}
							filters={filters}
							setFilters={setFilters}
							total={total}
						/>
					)}
				</>
			) : (
				<Skeleton active />
			)}

			<Modal
				destroyOnClose
				title="Thay đổi ưu tiên hiển thị"
				okButtonProps={{ className: "hidden" }}
				open={openDisplay}
				onCancel={() => {
					setOpenDisplay(false);
				}}>
				{dataEdit && (
					<FormEditDisplayOrder
						initialValue={{
							display_order: dataEdit.displayorder,
							id: dataEdit.id,
						}}
						onSuccess={() => {
							setDataEdit(null);
							setOpenDisplay(false);
							getDataDetail();
						}}></FormEditDisplayOrder>
				)}
			</Modal>
		</div>
	);
};

export default ListProductCollectionDetail;
