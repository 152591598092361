import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { ProductStatus } from "common/types/Product";
import { ProductWarehouseJson } from "common/types/ProductWarehouse";
import { SelectOption } from "common/types/SelectOption";
import { t } from "i18next";
import BaseModel from "./BaseModel";
import ProductModel from "./ProductModel";
import WarehouseModel from "./WarehouseModel";
import StoreModel from "./StoreModel";

const defaultData: ProductWarehouseJson = {
  id: 0,
  company_id: 0,
  status: 0,
  warehouse_id: 0,
  item_id: 0,
  item_type: 0,
  cost_price: 0,
  quantity: 0,
  reserved: 0,
  display_order: 0,
  note: "",
  date_created: 0,
  date_modified: 0,
  product: ProductModel.getDefaultData(),
  warehouse: WarehouseModel.getDefaultData(),
  store: StoreModel.getDefaultData(),
};

class ProductWarehouseModel
  extends BaseModel
  implements BaseModelJson<ProductWarehouseJson>
{
  id = defaultData.id;
  company_id = defaultData.company_id;
  status = defaultData.status;
  warehouse_id = defaultData.warehouse_id;
  item_id = defaultData.item_id;
  item_type = defaultData.item_type;
  cost_price = defaultData.cost_price;
  quantity = defaultData.quantity;
  reserved = defaultData.reserved;
  display_order = defaultData.display_order;
  note = defaultData.note;
  date_created = defaultData.date_created;
  date_modified = defaultData.date_modified;
  product = defaultData.product;
  warehouse = defaultData.warehouse;
  store = defaultData.store;

  constructor(json: ProductWarehouseJson) {
    super();
    Object.assign(this, { ...defaultData, ...json });
  }

  toJson(): ProductWarehouseJson {
    return JSON.parse(JSON.stringify({ ...this }));
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: ProductStatus.ACTIVE,
        label: t("common:STATUS_ENABLE"),
        color: "green",
      },
      {
        value: ProductStatus.UNACTIVE,
        label: t("common:STATUS_DISABLED"),
        color: "red",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }
}

export default ProductWarehouseModel;
