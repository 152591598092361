import Customer from "common/constants/Customer";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import i18n from "i18n";

import BaseModel from "./BaseModel";

import type { Birthday, CustomerJson } from "common/types/Customer";
import FileModel from "./FileModel";
import { FileJson } from "common/types/File";

const defaultData: CustomerJson = {
	address: "",
	age_range: 0,
	avartar: FileModel.getDefaultData(),
	birthday: 0,
	code: "",
	company_id: 0,
	count_order: 0,
	country: 0,
	creator_id: 0,
	customer_attributes: [], // Dùng 'any[]' nếu chưa biết kiểu chính xác
	customer_type_id: 0,
	customers_id: "",
	date_created: 0,
	date_deleted: 0,
	date_first_ordered: 0,
	date_last_ordered: 0,
	date_modified: 0,
	datemodified_contact: 0,
	datemodified_edit_user: 0,
	datemodified_email: 0,
	datemodified_phone: 0,
	datemodified_username: 0,
	district: 0,
	debt: 0,
	email: "",
	email_verified: 0,
	first_name: "",
	full_name: "",
	gender: 0,
	id: 0,
	ignore_auto_upgrade: 0,
	invoicing_detail: "",
	isdeleted: 0,
	last_name: "",
	money: 0,
	note: "",
	order_count: 0,
	order_lastdate: 0,
	order_lastid: 0,
	order_lastnumber: 0,
	order_lastspent: 0,
	order_spent: 0,
	phone: "",
	phone_verified: 0,
	point: 0,
	province: 0,
	rank: 0,
	rank_type: 0,
	region_id: 0,
	road: "",
	status: 0,
	sub_region_id: 0,
	sub_sub_region_id: 0,
	tag: "",
	tax_buyer_account_number: "",
	tax_buyer_address: "",
	tax_buyer_name: "",
	tax_buyer_phone: "",
	tax_buyer_tax_number: "",
	total_collection: 0,
	user_id: 0,
	user_name: "",
	ward: 0,
};

class CustomerModel extends BaseModel implements BaseModelJson<CustomerJson> {
	address: string = defaultData.address;
	age_range: number = defaultData.age_range;
	avartar: FileJson = defaultData.avartar;
	birthday: number = defaultData.birthday;
	code: string = defaultData.code;
	company_id: number = defaultData.company_id;
	count_order: number = defaultData.count_order;
	country: number = defaultData.country;
	creator_id: number = defaultData.creator_id;
	customer_attributes: any[] = defaultData.customer_attributes;
	customer_type_id: number = defaultData.customer_type_id;
	customers_id: string = defaultData.customers_id;
	date_created: number = defaultData.date_created;
	date_deleted: number = defaultData.date_deleted;
	date_first_ordered: number = defaultData.date_first_ordered;
	date_last_ordered: number = defaultData.date_last_ordered;
	date_modified: number = defaultData.date_modified;
	datemodified_contact: number = defaultData.datemodified_contact;
	datemodified_edit_user: number = defaultData.datemodified_edit_user;
	datemodified_email: number = defaultData.datemodified_email;
	datemodified_phone: number = defaultData.datemodified_phone;
	datemodified_username: number = defaultData.datemodified_username;
	district: number = defaultData.district;
	debt: number = defaultData.debt;
	email: string = defaultData.email;
	email_verified: number = defaultData.email_verified;
	first_name: string = defaultData.first_name;
	full_name: string = defaultData.full_name;
	gender: number = defaultData.gender;
	id: number = defaultData.id;
	ignore_auto_upgrade: number = defaultData.ignore_auto_upgrade;
	invoicing_detail: string = defaultData.invoicing_detail;
	isdeleted: number = defaultData.isdeleted;
	last_name: string = defaultData.last_name;
	money: number = defaultData.money;
	note: string = defaultData.note;
	order_count: number = defaultData.order_count;
	order_lastdate: number = defaultData.order_lastdate;
	order_lastid: number = defaultData.order_lastid;
	order_lastnumber: number = defaultData.order_lastnumber;
	order_lastspent: number = defaultData.order_lastspent;
	order_spent: number = defaultData.order_spent;
	phone: string = defaultData.phone;
	phone_verified: number = defaultData.phone_verified;
	point: number = defaultData.point;
	province: number = defaultData.province;
	rank: number = defaultData.rank;
	rank_type: number = defaultData.rank_type;
	region_id: number = defaultData.region_id;
	road: string = defaultData.road;
	status: number = defaultData.status;
	sub_region_id: number = defaultData.sub_region_id;
	sub_sub_region_id: number = defaultData.sub_sub_region_id;
	tag: string = defaultData.tag;
	tax_buyer_account_number: string = defaultData.tax_buyer_account_number;
	tax_buyer_address: string = defaultData.tax_buyer_address;
	tax_buyer_name: string = defaultData.tax_buyer_name;
	tax_buyer_phone: string = defaultData.tax_buyer_phone;
	tax_buyer_tax_number: string = defaultData.tax_buyer_tax_number;
	total_collection: number = defaultData.total_collection;
	user_id: number = defaultData.user_id;
	user_name: string = defaultData.user_name;
	ward: number = defaultData.ward;
	constructor(json: CustomerJson) {
		super();

		Object.assign(this, { ...defaultData, ...json });
	}

	static getDefaultData(): CustomerJson {
		return defaultData;
	}

	toJson(): CustomerJson {
		return JSON.parse(JSON.stringify({ ...this }));
	}

	static getGenderList(): SelectOption[] {
		return [
			{
				value: Customer.GENDER_FEMALE,
				label: i18n.t("common:gender_female"),
			},
			{
				value: Customer.GENDER_MALE,
				label: i18n.t("common:gender_male"),
			},
			{
			  value: Customer.GENDER_OTHER,
			  label: i18n.t("common:gender_other"),
			},
			{
				value: Customer.GENDER_UNKNOWN,
				label: i18n.t("Không xác định"),
			},
		];
	}


	static getStatusList(): SelectOption[] {
		return [
			{
				value: Customer.STATUS_ALREADY_CREATED,
				label: i18n.t("customer:STATUS_ALREADY_CREATED"),
			},
			{
				value: Customer.STATUS_DELETED,
				label: i18n.t("customer:STATUS_DELETED"),
			},
			{
				value: Customer.STATUS_DISABLED,
				label: i18n.t("customer:STATUS_DISABLED"),
			},
			{
				value: Customer.STATUS_ENABLE,
				label: i18n.t("customer:STATUS_ENABLE"),
			},
			{
				value: Customer.STATUS_UNACTIVATED,
				label: i18n.t("customer:STATUS_UNACTIVATED"),
			},
		];
	}

	static getStatus(value: number): SelectOption | undefined {
		return this.getStatusList().find((item) => item.value === value);
	}
	static getGender(value: number): SelectOption | undefined {
		return this.getGenderList().find((item) => item.value === value);
	}

	static getCustomerVisitorList(): SelectOption[] {
		return [
			{
				value: Customer.CUSTOMER_VISITOR,
				label: i18n.t("Khách vãng lai"),
				color: "yellow",
			},
			{
				value: Customer.CUSTOMER_NOT_VISITOR,
				label: i18n.t("Khách bình thường"),
				color: "green",
			},
		];
	}

	static getCustomerVisitor(value: number): SelectOption | undefined {
		return this.getCustomerVisitorList().find((item) => item.value === value);
	}

	static getAgeRangeList(): SelectOption[] {
		return [
			{
				value: Customer.AGE_RANGE_CHILD,
				label: i18n.t("Dưới 18 tuổi"),
			},
			{
				value: Customer.AGE_RANGE_YOUNG,
				label: i18n.t("18 - 24"),
			},
			{
				value: Customer.AGE_RANGE_MIDDLE_1,
				label: i18n.t("25 - 34"),
			},
			{
				value: Customer.AGE_RANGE_MIDDLE_2,
				label: i18n.t("35 - 44"),
			},
			{
				value: Customer.AGE_RANGE_MIDDLE_3,
				label: i18n.t("45 - 54"),
			},
			{
				value: Customer.AGE_RANGE_ELDER,
				label: i18n.t("55 - 64"),
			},
			{
				value: Customer.AGE_RANGE_OVER_ELDER,
				label: i18n.t("Trên 65 tuổi"),
			},
			{
				value: Customer.AGE_RANGE_UNKNOWN,
				label: i18n.t("Không xác định"),
			},
		];
	}

	static getAgeRange(value: number): SelectOption | undefined {
		return this.getAgeRangeList().find((item) => item.value === value);
	}
}

export default CustomerModel;
