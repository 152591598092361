import DeviceManageList from "features/devicemanage/list/DeviceManageList";
import usePathParams from "hooks/usePathParams";

const DeviceManagePage = (): JSX.Element => {
  const [pathParams] = usePathParams();

  let com = <DeviceManageList />;
  switch (pathParams.action) {
    default:
      com = <DeviceManageList />;
  }

  return com;
};

export default DeviceManagePage;
