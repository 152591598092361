import { Form, Input } from "antd";
import ShipperKPIModel from "common/models/ShipperKPIModel";
import { Filter } from "common/types/Filter";
import DatePickerRangeFilter from "components/datetime/DatePickerRangeFilter";
import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import UserFormSelect from "features/user/UserFormSelect";
import { useTranslation } from "react-i18next";

const ShipperKPIListFilter = <F extends Filter>({
  total,
  filters,
  setFilters,
  defaultFilters,
}: {
  total: number;
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <PageDataFilterForm
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      >
        <Form.Item name="keyword">
          <Input allowClear placeholder={t("Nhập từ khóa")} />
        </Form.Item>
        <UserFormSelect
          name="employee_id"
          allowClear
          placeholder={t("shipperkpi:table_header.employee_id")}
          popupMatchSelectWidth={false}
        />
        <FormSelect
          name="shift"
          options={ShipperKPIModel.getShiftList()}
          allowClear
          placeholder={t("shipperkpi:table_header.shift")}
        />
        <FormSelect
          name="status"
          options={ShipperKPIModel.getStatusList()}
          allowClear
          placeholder={t("shipperkpi:table_header.status")}
        />
        <UserFormSelect
          name="coordinator"
          allowClear
          placeholder={t("shipperkpi:table_header.coordinator")}
          popupMatchSelectWidth={false}
        />
        <DatePickerRangeFilter
          filters={filters}
          keyStart="date_from"
          keyEnd="date_to"
          setFilters={setFilters}
          placeholderRange={[
            t("reportdelivery:placeholder_datefrom"),
            t("reportdelivery:placeholder_dateto"),
          ]}
        />
      </PageDataFilterForm>
    </>
  );
};

export default ShipperKPIListFilter;
