import BaseApi from "./BaseApi";
import AxiosClient from "./AxiosClient";
import { GetListProps } from "common/types/Filter";
import Helper from "common/utils/helper";

//////////////////////////////////
//Phần thay thế
import { SystemEventAddEditJson, SystemEventFilter } from "common/types/SystemEvent";
import SystemEventCollection from "common/collections/SystemEventCollection";
import SystemEventModel from "common/models/SystemEventModel";

const SERVICE_URL = "/systemevents";
class SystemEventApi extends BaseApi {
	//
	async getList(
		props: GetListProps<SystemEventFilter>
	): Promise<SystemEventCollection> {
		let collection = new SystemEventCollection();
		const convertProps = Helper.convertParams(props.filters);
		const queryData = {
			params: {
				...convertProps,
				status: Number(props.filters.status) > -1 ? props.filters.status : null
			},
		};

		try {
			const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}
		return collection;
	}
	//
	async getDetail(id: number): Promise<SystemEventModel> {
		let item = new SystemEventModel(SystemEventModel.getDefaultData());

		try {
			const response = await AxiosClient().get<any>(SERVICE_URL + id);
			if (response.hasOwnProperty("data")) {
				item = new SystemEventModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}
		return item;
	}
	async edit(data: SystemEventAddEditJson): Promise<SystemEventModel> {
		let item = new SystemEventModel(SystemEventModel.getDefaultData());
		try {
		  let postData = {
			...data,
		  };
		  const response = await AxiosClient().put<any>(
			SERVICE_URL + "/update/" + data.id,
			postData
		  );
		  if (response.hasOwnProperty("data")) {
			item = new SystemEventModel(response.data);
		  }
		} catch (error) {
		  item.withError(BaseApi.handleError(error));
		}
	
		return item;
	}
	//
	async delete(id: number): Promise<string[]> {
		let resultErrors = [];

		try {
			const response = await AxiosClient().put(SERVICE_URL + "/delete/" + id);
			if (response.status !== 200) {
				resultErrors.push("error_delete_response_not_200");
			}
		} catch (error) {
			resultErrors = BaseApi.handleError(error).errors;
		}

		return resultErrors;
	}
}

export default SystemEventApi;
