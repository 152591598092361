import FeedbackApi from "common/api/FeedbackApi";
import FeedbackCollection from "common/collections/FeedbackCollection";
import { FeedbackAddEditJson, FilterFeedback } from "common/types/Feedback";
import { GetListProps } from "common/types/Filter";
import BaseRepository from "./BaseRepository";
import FeedbackModel from "common/models/FeedbackModel";

class FeedbackRepository extends BaseRepository<FeedbackApi> {
  _api: FeedbackApi | null;

  constructor() {
    super();
    this._api = new FeedbackApi(true);
  }

  static getDefaultFilters(): FilterFeedback {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      comments_on: null,
      receive_from: null,
    };
  }

  async getItems(props: GetListProps<FilterFeedback>) {
    return this._api
      ? await this._api.getList(props)
      : new FeedbackCollection();
  }

  async saveRemote(data: FeedbackAddEditJson): Promise<FeedbackModel> {
    if (this._api) {
      return await this._api.edit(data);
    } else {
      return new FeedbackModel(FeedbackModel.getDefaultData());
    }
  }
}

export default FeedbackRepository;
