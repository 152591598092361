import BaseCollection from 'common/collections/BaseCollection';
import StoreWarehouseModel from 'common/models/StoreWarehouseModel';
import { StoreWarehouseCollectionJson, StoreWarehouseJson } from 'common/types/StoreWarehouse';

class StoreWarehouseCollection extends BaseCollection<
  StoreWarehouseJson,
  StoreWarehouseModel,
  StoreWarehouseCollectionJson
> {
  itemsFromJson(jsonItems: StoreWarehouseJson[]): StoreWarehouseModel[] {
    return jsonItems.map((item) => new StoreWarehouseModel(item));
  }
}

export default StoreWarehouseCollection;
