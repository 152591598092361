import OrderApi from "common/api/OrderApi";
import OrderCollection from "common/collections/OrderCollection";
import OrderModel from "common/models/OrderModel";
import { GetListProps } from "common/types/Filter";
import { PosOrder } from "common/types/PosOrder";

import BaseRepository from "./BaseRepository";

import type { FilterOrder, OrderJsonEdit } from "common/types/Order";
import Delivery from "common/constants/Delivery";
import { YesNo } from "common/types/Enum";
class OrderRepository extends BaseRepository<OrderApi> {
	_api: OrderApi | null;

	constructor() {
		super();
		this._api = new OrderApi(true);
	}

	static getDefaultFilters(): FilterOrder {
		return {
			page: 1,
			limit: 20,
			sortby: "date_created",
			sorttype: "DESC",
			status: YesNo.Default,
			keyword: "",
			id: YesNo.Default,
			origin: YesNo.Default,
			store_id: YesNo.Default,
			tag: "",
			sale_channel: YesNo.Default,
			code: "",
			from_date: YesNo.Default,
			to_date: YesNo.Default,
			type: YesNo.Default,
			payment_status: YesNo.Default,
			delivery_status: YesNo.Default,
			service_type: YesNo.Default,
			customer_credit_balance: YesNo.Default,
			payment_confirmed_by_staff: YesNo.Default,
			using_cod: YesNo.Default,
			is_promotion: YesNo.Default,

			is_promotion_body: YesNo.Default,
			is_promotion_item: YesNo.Default,
		};
	}

	async getItems(props: GetListProps<FilterOrder>) {
		return this._api ? await this._api.getList(props) : new OrderCollection();
	}

	async getItem(id: number): Promise<OrderModel> {
		return this._api
			? await this._api.getDetail(id)
			: new OrderModel(OrderModel.getDefaultData());
	}

	async createRemote(data: PosOrder): Promise<OrderModel> {
		return this._api
			? await this._api.add(data)
			: new OrderModel(OrderModel.getDefaultData());
	}

	async saveRemote(data: Partial<OrderJsonEdit>): Promise<OrderModel> {
		return this._api
			? await this._api.edit(data)
			: new OrderModel(OrderModel.getDefaultData());
	}

	async confirm(
		data: Pick<OrderJsonEdit, "id" | "status">
	): Promise<OrderModel> {
		return this._api
			? await this._api.comfirm(data)
			: new OrderModel(OrderModel.getDefaultData());
	}
	async cancel(
		data: Pick<OrderJsonEdit, "cancel_reason" | "id" | "status" | "note_cancel">
	): Promise<OrderModel> {
		return this._api
			? await this._api.cancel(data)
			: new OrderModel(OrderModel.getDefaultData());
	}

	async fetchOrder(id: number | string): Promise<string[]> {
		return this._api ? await this._api.fetchOrder(id) : [];
	}
}

export default OrderRepository;
