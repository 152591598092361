import clsx from "clsx";
import { ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

type AnyObject = { [key: string]: any };

export const cn = (...inputs: ClassValue[]) => {
	return twMerge(clsx(inputs));
};

export function mergeObjects(
	obj1: AnyObject,
	obj2: AnyObject,
	isDefault?: boolean
): AnyObject {
	const result: AnyObject = {};

	// Get all unique keys from both objects
	const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);
	if (isDefault) {
		allKeys.forEach((key) => {
			// Always prioritize obj2's properties if they are present
			if (key in obj2) {
				result[key] = obj2[key];
			} else if (key in obj1) {
				result[key] = obj1[key];
			}
		});

		return result;
	}

	allKeys.forEach((key) => {
		if (obj1[key] !== undefined && obj1[key] !== 0 && obj1[key] !== "") {
			result[key] = obj1[key];
		}
		if (obj2[key] !== undefined && obj2[key] !== 0 && obj2[key] !== "") {
			result[key] = obj2[key];
		}
	});

	return result;
}

export async function delay(time: number = 500) {
	return await new Promise((resolve) => {
		setTimeout(() => {
			resolve(1);
		}, time);
	});
}

export function checkKeyObject() {}

export function uniqueArray(array: any[]) {
	return array.filter((o, index, arr) =>
		arr.findIndex(item => JSON.stringify(item) === JSON.stringify(o)) === index
	);
}
