import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { StoreWarehouseJson } from "common/types/StoreWarehouse";
import BaseModel from "./BaseModel";

class StoreWarehouseModel extends BaseModel implements BaseModelJson<StoreWarehouseJson> {
  c_id: number;
  date_created: number;
  date_modified: number;
  id: number;
  status: number;
  store_id: number;
  u_id: number;
  warehouse_id: number;

  constructor(json: StoreWarehouseJson) {
    super();

    this.id = json.id || 0;
    this.status = json.status || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.c_id = json.c_id || 0;
    this.store_id = json.store_id || 0;
    this.u_id = json.u_id || 0;
    this.warehouse_id = json.warehouse_id || 0;
  }

  static getDefaultData(): StoreWarehouseJson {
    return {
      id: 0,
      status: 0,
      date_created: 0,
      date_modified: 0,
      c_id: 0,
      store_id: 0,
      u_id: 0,
      warehouse_id: 0,
    };
  }

  toJson(): StoreWarehouseJson {
    return {
      id: this.id,
      status: this.status,
      date_created: this.date_created,
      date_modified: this.date_modified,
      c_id: this.c_id,
      store_id: this.store_id,
      u_id: this.u_id,
      warehouse_id: this.warehouse_id,
    };
  }
}

export default StoreWarehouseModel;
