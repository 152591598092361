import ContractManageList from "features/contractmanage/list/ContractManageList";
import usePathParams from "hooks/usePathParams";

const ContractManagePage = (): JSX.Element => {
  const [pathParams] = usePathParams();

  let com = <ContractManageList />;
  switch (pathParams.action) {
    default:
      com = <ContractManageList />;
  }

  return com;
};

export default ContractManagePage;
