import { Form, Input } from "antd";
import { FilterProductWarehouse } from "common/types/ProductWarehouse";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import StoreWarehouseFormSelect from "features/store/StoreWarehouseFormSelect";
import { forwardRef, memo, Ref, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface IProductWarehouseListFilterProps {
  filters: FilterProductWarehouse;
  setFilters: (f: SetStateAction<FilterProductWarehouse>) => any;
  defaultFilters: FilterProductWarehouse;
  total: number;
}

const ProductWarehouseListFilter = forwardRef(
  (
    {
      filters,
      setFilters,
      defaultFilters,
      total,
    }: IProductWarehouseListFilterProps,
    ref: Ref<any>
  ) => {
    const { t } = useTranslation();

    return (
      <PageDataFilterForm
        filters={filters}
        setFilters={setFilters}
        total={total}
        defaultFilters={defaultFilters}
      >
        <Form.Item name="keyword">
          <Input
            allowClear
            placeholder={t("product:filter_keyword_placeholder")}
          />
        </Form.Item>
        <StoreWarehouseFormSelect
          ref={ref}
          filters={filters}
          storeName="store_id"
          warehouseName="warehouse_id"
          allowClear={false}
          popupMatchSelectWidth={false}
        />
      </PageDataFilterForm>
    );
  }
);

export default memo(ProductWarehouseListFilter);
