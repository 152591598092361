import {
	Alert,
	App,
	Button,
	Card,
	Checkbox,
	Col,
	DatePicker,
	Divider,
	Flex,
	Form,
	Image,
	Input,
	InputNumber,
	Modal,
	Popconfirm,
	Popover,
	Row,
} from "antd";
import ProductModel from "common/models/ProductModel";
import ProductRepository from "common/repositories/ProductRepository";
import Error from "components/LayoutError";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconInfoCircle, IconPhoto, IconTrash } from "@tabler/icons-react";
import {
	ProductAllowSale,
	ProductShowWeb,
	ProductStatus,
	type ProductJsonAddEdit2,
} from "common/types/Product";
import FileModel from "common/models/FileModel";
import MoneyInput from "components/MoneyInput";
import dayjs from "dayjs";
import Helper from "common/utils/helper";
import FileUploaderButton from "components/file/uploader/FileUploaderButton";
import TextArea from "antd/lib/input/TextArea";
import { useWatch } from "antd/es/form/Form";
import LayoutForm from "components/form/LayoutForm";
import ProductFormHeader from "./ProductFormHeader";
import ReactQuill from "react-quill";
import useGetfilesByIds from "hooks/useGetfilesByIds";
import ProductCollectionSearchCreate from "components/ProductCollectionSearchCreate";
import BrandSearchCreate from "components/BrandSearchCreate";
import Meta from "antd/es/card/Meta";
import { EditOutlined } from "@ant-design/icons";
import FileRepository from "common/repositories/FileRepository";
import PageSortList from "components/list/PageSortList";
import { DropResult } from "react-beautiful-dnd";
import ProductActions from "./ProductActions";
import SuppilerSearchCreate from "components/SuppilerSearchCreate";
import { useLocation, useNavigate } from "react-router-dom";
import useProduct from "hooks/useProduct";
import ProductCollectionRepository from "common/repositories/ProductCollectionRepository";
import ProductCollectionModel from "common/models/ProductCollectionModel";
const ProductForm = ({
	model,
	setOpen,
	setProcessing,
	onSaveSuccess,
	isCopy,
	isParent,
}: {
	model: ProductModel;
	setOpen?: (v: boolean) => void;
	setProcessing?: (p: boolean) => void;
	onSaveSuccess?: (o: ProductModel) => void;
	isCopy?: boolean;
	isParent?: boolean;
}) => {
	const { t } = useTranslation();
	const { message } = App.useApp();
	const [form] = Form.useForm();
	const location = useLocation();
	const navigate = useNavigate();

	////////////////////////////////////////////////
	const { loading, onCloneProduct } = useProduct({
		defaultData: model.toJson(),
	});

	////////////////////////////////////////////////

	const [errors, setErrors] = useState<string[]>([]);
	const [isSuccess, setIsSuccess] = useState(false);
	const [files, setFiles] = useState<FileModel[]>([]);
	const [maxImage, setMaxImage] = useState<number>(0);
	const [isFormTouched, setIsFormTouched] = useState(false);
	const [fileEdit, setFileEdit] = useState({
		open: false,
		file: new FileModel(FileModel.getDefaultData()),
	});
	const isEditing = useMemo(() => model.id > 0, [model.id]);

	const name = useWatch("name", form) || "";
	const option_name = useWatch("option_name", form) || "";
	const collections = useWatch("collections", form) || [];
	const brand_id = useWatch("brand_id", form) || [];
	const supplier_id = useWatch("supplier_id", form) || "";

	const originalCollections = useMemo(
		() => model.collections.map((coll) => coll.id),
		[model.collections]
	);
	const addedCollections = useMemo(() => {
		const originalSet = new Set(originalCollections);
		return collections.filter((item: number) => !originalSet.has(item));
	}, [originalCollections, collections]);
	const deletedCollections = useMemo(() => {
		const modifiedSet = new Set(collections);
		return originalCollections.filter((item) => !modifiedSet.has(item));
	}, [originalCollections, collections]);

	//state
	// const [productDraft , setProductDraft] = useLocalStorage("product-draft" , ProductModel.getDefaultData())

	// const initialValues = useMemo(() => {
	//   return {
	//     name: model.name || (!isEditing && productDraft?.name),
	//     sku: model.sku || (!isEditing && productDraft?.sku),
	//     price: model.price ||  (!isEditing && productDraft?.price),
	//     compare_at_price: model.compare_at_price || (!isEditing && productDraft?.compare_at_price),
	//     brand_id: model.brand || (!isEditing && productDraft?.brand) || null ,
	//     full_name: model.full_name || (!isEditing && productDraft?.full_name),
	//     barcode: model.barcode|| (!isEditing && productDraft?.barcode),
	//     allow_sale: model.id === 0 ? true : !!model.allow_sale,
	//     show_web: model.id === 0 ? true : !!model.show_web,
	//     show_pos: model.id === 0 ? true : !!model.show_pos,
	//     allow_promotion: model.id === 0 ? true : !!model.allow_promotion,
	//     limit_sale: model.limit_sale || 10,
	//     min_buy: model.min_buy || 1,
	//     handle: model.handle,
	//     supplier_id: model.supplier_id  || (!isEditing && productDraft?.supplier_id) || null,
	//     status: model.status || (!isEditing && productDraft?.status) ,
	//     id: model.id,
	//     origin: model.origin || (!isEditing && productDraft?.origin),
	//     parent_id: model.parent_id || (!isEditing && productDraft?.parent_id),
	//     production_year: model.production_year || (!isEditing && productDraft?.production_year),
	//     display_from:
	//       model.display_from > 0 ? dayjs(new Date(model.display_from * 1000)) : null,
	//     display_to:
	//       model.display_to > 0 ? dayjs(new Date(model.display_to * 1000)) : null,
	//     collections: model.collections.map((col) => col.id),
	//     summary:(!isEditing && productDraft?.summary) || null,
	//     description: (!isEditing && productDraft?.description) || null,
	//   };
	// }, [model]);

	const initialValues = useMemo(() => {
		return {
			name: model.name,
			sku: model.sku,
			price: model.price,
			compare_at_price: model.compare_at_price,
			brand_id: model.brand?.id || null,
			full_name: model.full_name,
			option_name: model.option_name,
			barcode: model.barcode,
			allow_sale: model.id === 0 ? true : !!model.allow_sale,
			show_web: model.id === 0 ? true : !!model.show_web,
			show_pos: model.id === 0 ? true : !!model.show_pos,
			allow_promotion: model.id === 0 ? true : !!model.allow_promotion,
			limit_sale: model.limit_sale || 10,
			min_buy: model.min_buy || 1,
			handle: model.handle,
			supplier_id: model.supplier.id || null,
			supplier: model.supplier || null,
			status: model.status,
			id: model.id,
			origin: model.origin,
			parent_id: model.parent_id,
			production_year: model.production_year,
			display_from:
				model.display_from > 0
					? dayjs(new Date(model.display_from * 1000))
					: null,
			display_to:
				model.display_to > 0 ? dayjs(new Date(model.display_to * 1000)) : null,
			collections: model.collections.map((col) => col.id),
			summary: model.summary,
			description: model.description,
			meta_description: model.meta_description,
			meta_keyword: model.meta_keyword,
			meta_title: model.meta_title,
			vat: model.vat,
		};
	}, [model]);

	//prepare data for submit
	const doPrepareData = useCallback(
		(formData: any) => {
			const submitData: ProductJsonAddEdit2 = {
				id: formData.id,
				supplier_id: formData.supplier_id || undefined,
				limit_sale: formData.limit_sale,
				min_buy: formData.min_buy,
				name: formData.name,
				option_name: formData.option_name,
				sku: formData.sku || undefined,
				price: formData.price,
				vat: formData.vat,
				barcode: formData.barcode || undefined,
				compare_at_price: formData.compare_at_price,
				full_name: formData.full_name,
				handle: formData.handle || undefined,
				origin: formData.origin,
				collections: formData.collections,
				status: isEditing ? model.status : ProductStatus.ACTIVE,
				production_year: formData.production_year,
				show_web: formData.show_web
					? ProductShowWeb.ACTIVE
					: ProductShowWeb.UNACTIVE,
				show_pos: formData.show_pos
					? ProductShowWeb.ACTIVE
					: ProductShowWeb.UNACTIVE,
				meta_description: formData.meta_description,
				meta_keyword: formData.meta_keyword,
				meta_title: formData.meta_title,
				summary: formData.summary,
				description: formData.description,
				images: files.length > 0 ? files.map((item) => item.id).toString() : "",
				allow_promotion: formData.allow_promotion
					? ProductShowWeb.ACTIVE
					: ProductShowWeb.UNACTIVE,
				allow_sale: formData.allow_sale
					? ProductAllowSale.ALLOW_SALE
					: ProductAllowSale.NOT_ALLOW_SALE,
				brand_id: formData.brand_id
					? formData.brand_id
					: model.brand?.id || undefined,
				parent_id: model.parent_id >= 0 ? model.parent_id : 0,
				display_from:
					Helper.datePickerValueToTimestamp(formData.display_from) > 0
						? Helper.datePickerValueToTimestamp(formData.display_from)
						: undefined,
				display_to:
					Helper.datePickerValueToTimestamp(formData.display_to) > 0
						? Helper.datePickerValueToTimestamp(formData.display_to)
						: undefined,
			};

			return submitData;
		},
		[files, isEditing, model.brand, model.id, model.parent_id, model.status]
	);

	const handleFieldChange = () => {
		setIsFormTouched(true);
	};

	//submit data to server
	const onSubmit = useCallback(
		async (formData: any) => {
			// return;
			setIsSuccess(false);
			if (isParent || isCopy) {
				formData.id = 0;
			} else {
				formData.id = model.id;
			}
			let item: ProductModel = await new ProductRepository().saveRemote(
				doPrepareData(formData)
			);

			if (item.hasError()) {
				message.error({
					content: (
						<Error
							onClickClose={() => {
								message.destroy("message");
							}}
							heading={t("common:form.error.heading")}
							translate_prefix="product:form.error"
							items={item.error.errors}
						/>
					),
					className: "message_error",
					key: "message",
					duration: 4,
				});
				setErrors(item.error.errors);
			} else {
				const addCollectionPromises: Promise<ProductCollectionModel>[] = [];
				const deleteCollectionPromises: Promise<string[]>[] = [];

				if (addedCollections.length) {
					addedCollections.forEach((collection: number) => {
						addCollectionPromises.push(
							new ProductCollectionRepository().saveRemoteDetail({
								collection_id: collection,
								item_ids: [item.id],
							})
						);
					});
				}

				if (deletedCollections.length) {
					deletedCollections.forEach((collection: number) => {
						deleteCollectionPromises.push(
							new ProductCollectionRepository().deleteItemDetail({
								collection_id: collection,
								product_ids: [item.id],
							})
						);
					});
				}

				await Promise.allSettled(addCollectionPromises);
				await Promise.allSettled(deleteCollectionPromises);

				message.success({
					content: t("common:form.success.save"),
					className: "message_success",
					key: "message",
					duration: 2,
				});
				setErrors([]);
				navigate(-1);
			}
		},
		[
			form,
			t,
			onSaveSuccess,
			setOpen,
			doPrepareData,
			model.id,
			message,
			addedCollections,
			deletedCollections,
		]
	);

	function handleUpload(f: FileModel) {
		setFiles((pre) => [...pre, f]);
	}

	async function handleDeleteFile(f: FileModel) {
		const res = await new FileRepository().deleteItem(f.id);
		setFiles(files.filter((file) => file.id != f.id));
	}

	function handleDragEnd(result: DropResult) {
		if (!result.destination) return;
		const sourceIndex = result.source.index;
		const destinationIndex = result.destination?.index;
		const newArr = [...files];
		Helper.moveItem(newArr, sourceIndex, destinationIndex);
		setFiles(newArr);
	}

	async function handleEditImage() {
		const file = fileEdit.file;
		const res = await new FileRepository().saveDirectoryRemote({ ...file });
		const findIdex = files.findIndex((f) => f.id == file.id);
		const newArr = [...files];
		newArr[findIdex] = file;
		setFiles(newArr);
	}

	function onDeleteSuccess() {}

	////////////////////////////////////////////////
	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			if (isFormTouched) {
				const message = "Bạn có chắc chắn muốn rời khỏi trang này?";
				event.preventDefault();
				event.returnValue = message;
				return message;
			}
		};

		// Thêm event listener
		window.addEventListener("beforeunload", handleBeforeUnload);

		// Xóa event listener khi component unmount
		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [location, isFormTouched]); // Chạy lại khi location thay đổi
	const { files: filess } = useGetfilesByIds(
		model.images.map((img) => img.id || (img as unknown as number)),
		{
			origin: "company",
		}
	);
	useEffect(() => {
		if (filess) {
			const files = model.images
				.map((file: any) => {
					return filess[file.id];
				})
				.filter(Boolean) as FileModel[];
			setFiles(files);
		}
	}, [filess, model.images]);
	useEffect(() => {
		if (name && option_name && model.full_name) {
			const full_name = name + " - " + option_name;
			form.setFieldValue("full_name", full_name);
		}
	}, [form, name, option_name]);

	// useEffect(() => {
	//   setProductDraft(product)
	// }, [product]);

	////////////////////////////////////////////////
	const toolbarOptions = [
		[{ font: [] }],
		// [{ header: [1, 2, 3, 4, 5, 6, false] }],
		[{ size: ["small", false, "large", "huge"] }],
		["bold", "italic", "underline", "strike"],
		[{ align: [] }],
		[{ color: [] }, { background: [] }],
		[{ script: "super" }, { script: "sub" }],
		[{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
		[
			{ list: "ordered" },
			{ list: "bullet" },
			{ indent: "-1" },
			{ indent: "+1" },
		],
		["direction"],
		["link", "image", "video", "formula"],
		["clean"],
	];
	const sidebarItems = (
		<>
			{isEditing && (
				<Card title="Thao tác" className=" mb-4">
					{!model.status ? (
						<div className=" mb-4">
							<Alert
								message="Sản phẩm đang bị ngừng hiệu lực"
								type="warning"
								showIcon
							/>
						</div>
					) : null}
					<ProductActions
						isNotShowUpdate
						onDeleteSuccess={onDeleteSuccess}
						className="  flex-col items-end"
						product={model}
						onSaveSuccess={(pro) => {
							onSaveSuccess?.(pro);
						}}
					/>
				</Card>
			)}
			<Card title="Hiển thị" className=" mb-4">
				<Col md={24} xs={24}>
					<Row>
						<Col md={24} xs={24}>
							<div className=" flex items-center gap-1">
								<Form.Item
									name="show_web"
									valuePropName="checked"
									className=" m-0">
									<Checkbox>{t("product:show_web")} </Checkbox>
								</Form.Item>
								<Popover
									trigger={"hover"}
									title={"Cho phép hiển thị, tìm kiếm trên website"}
									placement="topRight">
									<Button size="small" type="text">
										<IconInfoCircle
											size="18"
											className={" -mt-0.5 text-blue-400"}
										/>
									</Button>
								</Popover>
							</div>
							<div className=" flex items-center gap-2">
								<Form.Item
									name="allow_sale"
									valuePropName="checked"
									className=" m-0">
									<Checkbox>{t("product:web_allow_sale")}</Checkbox>
								</Form.Item>
								<Popover
									trigger={"hover"}
									title={
										"Cho phép hiển thị, tìm kiếm trên website nhưng sẽ có giao diện ngừng kinh doanh và không thể đặt hàng"
									}
									placement="topRight">
									<Button size="small" type="text">
										<IconInfoCircle
											size="18"
											className={" -mt-0.5 text-blue-400"}
										/>
									</Button>
								</Popover>
							</div>
						</Col>
						<Col md={24} xs={24}>
							<Form.Item
								label={t("storycollection:publish_from")}
								name="display_from"
								style={{ width: "100%" }}
								rules={[
									{
										message: t("storycollection:form.error.error_publish_from"),
										validator: (_: any, value: any) => {
											if (
												Helper.datePickerValueToTimestamp(
													form.getFieldValue("date_published")
												) >= Helper.datePickerValueToTimestamp(value) ||
												!Helper.datePickerValueToTimestamp(value)
											) {
												return Promise.resolve();
											} else {
												return Promise.reject("Some message here");
											}
										},
									},
								]}>
								<DatePicker
									style={{ width: "100%" }}
									placeholder={"HH:mm dd/mm/yyyy"}
									format="HH:mm DD/MM/YYYY"
									showTime
								/>
							</Form.Item>

							<Form.Item
								label={t("storycollection:publish_to")}
								name="display_to"
								style={{ width: "100%" }}
								rules={[
									{
										message: t("leave:form.error.error_range_date"),
										validator: (_: any, value: any) => {
											if (
												Helper.datePickerValueToTimestamp(
													form.getFieldValue("publish_from")
												) < Helper.datePickerValueToTimestamp(value) ||
												!Helper.datePickerValueToTimestamp(value)
											) {
												return Promise.resolve();
											} else {
												return Promise.reject("Some message here");
											}
										},
									},
								]}>
								<DatePicker
									style={{ width: "100%" }}
									placeholder={"HH:mm dd/mm/yyyy"}
									format="HH:mm DD/MM/YYYY"
									showTime
									disabledDate={(current) => {
										return current.isBefore(form.getFieldValue("publish_from"));
									}}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Col>

				<Divider />
				<Col md={24} xs={24}>
					<Form.Item name="show_pos" valuePropName="checked">
						<Checkbox>{t("product:show_pos")}</Checkbox>
					</Form.Item>
				</Col>
			</Card>

			<Card className="  mb-4 " title="Nhóm sản phẩm">
				<Row gutter={32}>
					<Col md={24} xs={24}>
						<Form.Item label={t("product:collections")} name="collections">
							<ProductCollectionSearchCreate
								isEdit={isEditing || isCopy || isParent}
								selectProps={{
									value: collections,
									onChange: (e) => {
										form.setFieldValue("collections", e);
									},
								}}
							/>
						</Form.Item>
					</Col>
					<Col md={24} xs={24}>
						<Form.Item label={t("product:brand_id")} name="brand_id">
							<BrandSearchCreate
								selectProps={{
									value: brand_id,
									onChange: (e) => {
										form.setFieldValue("brand_id", e);
									},
								}}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Card>

			<Card title="Khuyến mãi" className=" mb-4">
				<Row>
					<Col md={24} xs={24}>
						<Form.Item name="allow_promotion" valuePropName="checked">
							<Checkbox>{t("product:allow_promotion")}</Checkbox>
						</Form.Item>
					</Col>
				</Row>
			</Card>
		</>
	);

	////////////////////////////////////////////////

	return (
		<>
			<ProductFormHeader
				isCopy={isCopy}
				isParent={isParent}
				isEditing={isEditing}
			/>
			<LayoutForm
				form={form}
				initialValues={initialValues}
				errors={errors}
				disable_status_loading
				isSuccess={isSuccess}
				successTitle={
					isEditing
						? t("product:form.success.update")
						: t("product:form.success.add")
				}
				error_translate_prefix="product:form.error"
				onSubmit={onSubmit}
				submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
				sidebarItems={sidebarItems}
				id="product-form"
				redirectOnSuccess={"/product"}
				onValuesChange={handleFieldChange}>
				<Row gutter={32}>
					<Col lg={24} xs={24} className="pt-2">
						<Form.Item
							label={t("product:name")}
							name="name"
							required
							rules={[
								{
									required: true,
									message: t("product:form.error.error_name_required"),
								},
							]}>
							<Input autoFocus />
						</Form.Item>

						<Row gutter={16}>
							<Col md={12} xs={24}>
								<Form.Item label={t("product:option_name")} name="option_name">
									<Input disabled />
								</Form.Item>
							</Col>
							<Col md={12} xs={24}>
								<Form.Item label={t("product:full_name")} name="full_name">
									<Input />
								</Form.Item>
							</Col>
							<Col md={12} xs={24}>
								<Form.Item
									label={t("product:production_year")}
									name="production_year">
									<Input />
								</Form.Item>
							</Col>
							<Col md={12} xs={24}>
								<Form.Item label={t("product:origin")} name="origin">
									<Input />
								</Form.Item>
							</Col>
							<Col md={24} xs={24}>
								<Form.Item name="summary" label="Giới thiệu ngắn">
									<TextArea />
								</Form.Item>
							</Col>

							<Col md={24} xs={24}>
								<Form.Item name="description" label={t("product:description")}>
									<ReactQuill
										theme="snow"
										style={{
											height: "400px",
											width: "100%",
											marginBottom: "70px",
										}}
										modules={{
											toolbar: toolbarOptions,
										}}
									/>
								</Form.Item>
							</Col>
						</Row>

						<Card title="Phần hình ảnh" className=" mb-4">
							<Alert
								message="Lưu ý: ảnh đầu tiên cũng sẽ là ảnh đại diện của sản phẩm"
								type="info"
								showIcon
							/>
							<Col lg={24} xs={24} className="pt-2 bg-gray-50">
								<Form.Item name="images" label={t("product:image")}>
									<FileUploaderButton
										// form={form}
										// formName="images"
										objectType={10}
										label={t("story:image")}
										icon={<IconPhoto size="24" color="grey" />}
										origin="company"
										uploadRenderType="list"
										isSingleFile={false}
										maxCount={6}
										initFiles={[]}
										onUploadCompleted={(f) => {
											handleUpload(f);
										}}
									/>
								</Form.Item>
								<PageSortList
									dataSource={files}
									className=" flex flex-row gap-4"
									handleDragEnd={handleDragEnd}
									listKey="id"
									renderListItem={(file, index) => {
										return (
											<Card
												actions={[
													<EditOutlined
														key="edit"
														onClick={() => {
															setFileEdit({ file: file, open: true });
														}}
													/>,
													<Popconfirm
														title="Xóa hình ảnh"
														description="bạn muốn xóa hình ảnh này?"
														onConfirm={() => handleDeleteFile(file)}
														okText="Xác nhận"
														cancelText="Hủy">
														<IconTrash key="delete" />
													</Popconfirm>,
												]}
												hoverable
												style={{ width: 180, height: "100%" }}
												bodyStyle={{
													padding: 6,
													display: "flex",
													justifyContent: "center",
												}}
												cover={
													<Image
														className=" flex-1"
														alt="example"
														src={file.url}
													/>
												}>
												<Meta title={index + 1} />
											</Card>
										);
									}}
								/>

								<Modal
									title={"Mô tả hình ảnh"}
									open={fileEdit.open}
									onCancel={() => {
										setFileEdit((pre) => ({
											file: new FileModel(FileModel.getDefaultData()),
											open: false,
										}));
									}}
									onOk={() => {
										handleEditImage();
									}}>
									<p>Nhập mô tả cho hình ảnh</p>
									<TextArea
										value={fileEdit.file.description}
										onChange={(e) => {
											const value = e.target.value;
											setFileEdit((pre) => ({
												...pre,
												file: new FileModel({
													...pre.file,
													description: value,
												}),
											}));
										}}
									/>
								</Modal>
							</Col>
						</Card>
						<Card title="Giá sản phẩm" className="  mb-4">
							<Row gutter={32}>
								<Col md={12} xs={24}>
									<Form.Item label={t("product:price")} name="price">
										<MoneyInput className="w-full" addonAfter="VND" />
									</Form.Item>
								</Col>
								<Col md={12} xs={24}>
									<Form.Item
										label={t("product:compare_at_price")}
										name="compare_at_price"
										rules={[
											{
												message: "Giá so sánh phải lớn hơn giá sản phẩm",
												validator: (_: any, value: string) => {
													if (
														Number(value) === 0 ||
														Number(value) > Number(form.getFieldValue("price"))
													) {
														return Promise.resolve();
													} else {
														return Promise.reject("Some message here");
													}
												},
											},
										]}>
										<MoneyInput className="w-full" addonAfter="VND" />
									</Form.Item>
								</Col>

								<Col md={12} xs={24}>
									<Form.Item label={t("product:price_tax")} name="vat">
										<InputNumber<number>
											defaultValue={model.vat}
											min={0}
											max={100}
											formatter={(val) => `${val}%`}
											parser={(val) =>
												val?.replace("%", "") as unknown as number
											}
											// onChange={onChange}
										/>
									</Form.Item>
								</Col>
							</Row>
						</Card>
						<Card title="Quản lí sản phẩm" className="  mb-4">
							<Row gutter={32}>
								<Col lg={12} xs={24}>
									<Form.Item
										label={`${t("product:code")}`}
										name="sku"
										// required
										// rules={[{ required: true }]}
									>
										<Input />
									</Form.Item>
								</Col>
								<Col md={12} xs={24}>
									<Form.Item label={t("product:barcode")} name="barcode">
										<Input />
									</Form.Item>
								</Col>
								<Col lg={12} xs={24}>
									<Form.Item
										label={`${t("product:limit_sale")}`}
										name="limit_sale">
										<Input type="number" min={0} />
									</Form.Item>
								</Col>
								<Col lg={12} xs={24}>
									<Form.Item label={`${t("product:min_buy")}`} name="min_buy">
										<Input type="number" min={1} />
									</Form.Item>
								</Col>
							</Row>
						</Card>
						<Card title="Nhà cung cấp" className="  mb-4">
							<Row gutter={32}>
								<Col lg={12} xs={24}>
									<Form.Item
										label={t("product:supplier_id")}
										name="supplier_id">
										<SuppilerSearchCreate
											selectProps={{
												value: supplier_id,
												onChange: (e) => {
													form.setFieldValue("supplier_id", e);
												},
											}}
										/>
									</Form.Item>
								</Col>
							</Row>
						</Card>

						<Card title="Tối ưu SEO">
							<Row gutter={32}>
								<Col md={12} xs={24}>
									<Form.Item label={t("product:handle")} name="handle">
										<Input placeholder={t("product:placeholder_URL")} />
									</Form.Item>
								</Col>

								<Col md={12} xs={24}>
									<Form.Item name="meta_title" label="SEO tiêu đề">
										<Input />
									</Form.Item>
								</Col>
								<Col md={12} xs={24}>
									<Form.Item name="meta_description" label="SEO Mô tả">
										<Input />
									</Form.Item>
								</Col>
								<Col md={12} xs={24}>
									<Form.Item name="meta_keyword" label="SEO từ khóa">
										<Input />
									</Form.Item>
								</Col>
							</Row>
						</Card>
						{/* 
            <Card title="Thuộc tính">
                    <ProductPickTags />

            </Card> */}
					</Col>
				</Row>
			</LayoutForm>
		</>
	);
};

export default ProductForm;
