import InventoryReceiptCollection from "common/collections/InventoryReceiptCollection";
import { GetListProps } from "common/types/Filter";
import { InventoryReceiptHistoryFilter } from "common/types/InventoryReceiptHistory";
import Helper from "common/utils/helper";
import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

const SERVICE_URL = "/inventoryreceipts/history";

class InventoryReceiptHistoryApi extends BaseApi {
	async getList(
		props: GetListProps<InventoryReceiptHistoryFilter>
	): Promise<InventoryReceiptCollection> {
		let collection = new InventoryReceiptCollection();

		try {
			let queryData = {
				params: Helper.convertParams(props.filters, true),
			};

			const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
			if (response.hasOwnProperty("data")) {
				collection.fromJson({
					items: response.data.inventory_receipt,
					total: response.data.inventory_receipt.length,
					currentpage: 1,
					limit: 10,
				});
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}

		return collection;
	}
}

export default InventoryReceiptHistoryApi;
