import { IconPhoto, IconTrash } from "@tabler/icons-react";
import { Button, Col, Collapse, Flex, Form, Image, Input, Popconfirm, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import BrandModel from "common/models/BrandModel";
import BrandRepository from "common/repositories/BrandRepository";
import { BrandAddEditJson } from "common/types/Brand";
import FormSection from "components/form/FormSection";
import FormSelectNormal from "components/form/FormSelectNormal";
import LayoutForm from "components/form/LayoutForm";
import FormItemHandleInput from "features/productcollection/form/children/FormItemHandleInput";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import BrandFormHeader from "./BrandFormHeader";
import FileUploaderButton from "components/file/uploader/FileUploaderButton";
import FileModel from "common/models/FileModel";

type Props = {
  model: BrandModel;
  isHiddenHeader?: boolean;
  onSucsess?: (data: BrandModel) => void;
  redirectOnSuccess?: string;
};
const BrandForm = ({
  model,
  isHiddenHeader,
  onSucsess,
  redirectOnSuccess = "/brand",
}: Props) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const isEditing = model.id > 0;
  //state
  const [isSuccess, setIsSuccess] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [url, setUrl] = useState<string>(model.handle);

  const [logoBrand, setLogoBrand] = useState((model.logo_brand as any)?.[0]?.url);
  const [logoBrandId, setLogoBrandId] = useState<number | null>(null);
  const [images, setImages] = useState((model.images as any)?.[0]?.url);
  const [imagesId, setImagesId] = useState<number | null>(null);

  //default data
  const initialValues = useMemo(() => {
    return {
      title: model.title,
      description: model.description,
      keyword: model.key_word,
      present: model.present,
      detail: model.detail,
      quote: model.quote,
      handle: model.handle,
      status: model.id === 0 ? 1 : model.status,
      logo_brand: logoBrand,
      images: images,
    };
  }, [model, logoBrand, images]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      console.log(logoBrandId, imagesId);

      const submitData: BrandAddEditJson = {
        id: model.id,
        title: formData.title,
        description: formData.description,
        keyword: formData.keyword,
        present: model.present,
        quote: formData.quote,
        status: formData.status,
        detail: formData.detail,
        handle: formData.handle,
        logo_brand: logoBrandId ?? 0,
        images: imagesId ?? 0,
      };

      return submitData;
    },
    [model.id, logoBrandId, imagesId]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    const response: BrandModel = await new BrandRepository().saveRemote(
      doPrepareData(formData)
    );
    if (!response.hasError()) {
      setIsSuccess(true);
      onSucsess?.(response);
    } else {
      setErrors(response.error.errors);
    }
  };

  //sidebar form
  const sidebarItems = <></>;

  const toolbarOptions = [
    [{ font: [] }],
    // [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike"],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    [{ script: "super" }, { script: "sub" }],
    [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["direction"],
    ["link", "image", "video", "formula"],
    ["clean"],
  ];

  return (
    <div>
      {!isHiddenHeader ? <BrandFormHeader isEditing={isEditing} /> : null}
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={
          isEditing
            ? t("common:form.success.general")
            : t("common:form.success.save")
        }
        redirectOnSuccess={redirectOnSuccess}
        error_translate_prefix="brand:form.error"
        onSubmit={onSubmit}
        submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
        sidebarItems={sidebarItems}
      >
        <FormSection
          title={t("brand:form.section_main")}
          subtitle={t("brand:form.section_subtitle_main")}
          divider
        >
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item
                name="title"
                label={t("brand:title")}
                required
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col md={24} xs={24}>
              <Form.Item name="description" label={t("brand:description")}>
                <TextArea rows={4} />
              </Form.Item>
            </Col>

            <Col md={24} xs={24}>
              <Form.Item name="handle" label={t("brand:handle")}>
                <Input
                  placeholder={t("product:placeholder_URL")}
                  onChange={(e) => {
                    setUrl(e.target.value);
                  }}
                />
              </Form.Item>
              <FormItemHandleInput
                prefix={
                  process.env.SHOP_DOMAIN_URL + "/blogs/thuong-hieu-nuoc-hoa" ||
                  ""
                }
                value={url}
              />
            </Col>

            <Col md={24} xs={24}>
              <Form.Item
                name="detail"
                label={t("brand:detail")}
              >
                <ReactQuill
                  theme="snow"
                  style={{
                    height: "300px",
                    width: "100%",
                    marginBottom: "70px",
                  }}
                  modules={{
                    toolbar: toolbarOptions,
                  }}
                />
              </Form.Item>
            </Col>

            <Col md={24} xs={24} span={24}>
              <Collapse className="mt-4 mb-2 bg-white">
                <Collapse.Panel header={t("collection:image_group")} key={"1"}>
                  <Row className="gap-2">
                    <Col md={24} xs={24} span={24}>
                      <Form.Item className="m-0" label={<>Ảnh logo</>}>
                        {logoBrand ? (
                          <Flex>
                            <Image
                              className="cursor-pointer"
                              src={logoBrand}
                              preview={true}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            />
                            <Popconfirm
                              title="Bạn có muốn xóa ảnh"
                              onConfirm={() => {
                                setLogoBrand(null);
                                setLogoBrandId(1);
                              }}
                            >
                              <Button
                                icon={
                                  <IconTrash className="w-4 h-4 text-gray-500 hover:text-red-500" />
                                }
                              />
                            </Popconfirm>
                          </Flex>
                        ) : (
                          <FileUploaderButton
                            label={t("Ảnh logo")}
                            icon={<IconPhoto size="24" color="grey" />}
                            origin="avatar"
                            uploadRenderType="thumbnail"
                            isSingleFile={true}
                            maxCount={1}
                            onUploadCompleted={(file: FileModel) => setLogoBrandId(file.id)}
                            onDeleteCompleted={() => setLogoBrandId(1)}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col md={24} xs={24} span={24}>
                      <Form.Item className="m-0" label="Ảnh chi tiết">
                        {images ? (
                          <Flex>
                            <Image
                              className="cursor-pointer"
                              src={images}
                              preview={true}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            />
                            <Popconfirm
                              title="Bạn có muốn xóa ảnh"
                              onConfirm={() => {
                                setImages(null);
                                setImagesId(1);
                              }}
                            >
                              <Button
                                icon={
                                  <IconTrash className="w-4 h-4 text-gray-500 hover:text-red-500" />
                                }
                              />
                            </Popconfirm>
                          </Flex>
                        ) : (
                          <FileUploaderButton
                            label={t("Ảnh chi tiết")}
                            icon={<IconPhoto size="24" color="grey" />}
                            origin="avatar"
                            uploadRenderType="thumbnail"
                            isSingleFile={true}
                            maxCount={1}
                            onUploadCompleted={(file: FileModel) => setImagesId(file.id)}
                            onDeleteCompleted={() => setImagesId(1)}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Collapse.Panel>
              </Collapse>
            </Col>
          </Row>
        </FormSection>
        <FormSection
          title={t("brand:form.section_sub")}
          subtitle={t("brand:form.section_subtitle_sub")}
        >
          <Row gutter={[8, 8]}>
            <Col md={8} xs={24}>
              <FormSelectNormal
                name="status"
                label={t("brand:status")}
                options={BrandModel.getStatusSelectList()}
              />
            </Col>

            <Col md={8} xs={24}>
              <Form.Item name="keyword" label={t("brand:keyword")}>
                <Input placeholder="" />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item name="quote" label={t("brand:quote")}>
                <Input placeholder={t("story:quote_placeholder")} />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>
      </LayoutForm>
    </div>
  );
};

export default BrandForm;
