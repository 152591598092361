import usePathParams from "hooks/usePathParams";
import { useEffect, useState } from "react";

import ShopEventList from "features/event/list/ShopEventList";
import ShopEventListBD11 from "features/event/list/ShopEventListBD11";
import ShopEventLastYear from "features/event/list/ShopEventLastYear";

const ShopEventPage = () => {
	const [pathParams] = usePathParams();

	const [errors, setErrors] = useState<string[]>([]);

	//clear edit model when change action
	useEffect(() => {
		setErrors([]);
	}, [pathParams.action]);
	console.log(pathParams);
	let com = <></>;
	switch (pathParams.action) {
		case "edit":
			break;
		case "birthday-11-year":
			com = <ShopEventListBD11 />;
			break;

		case "sale-20-5":
			com = <ShopEventList />;

			break;

		case "salecuoinam":
			com = <ShopEventLastYear />;
			break;
		default:
			break;
	}
	return com;
};

export default ShopEventPage;
