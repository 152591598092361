import { GetListProps } from "common/types/Filter";

import BaseRepository from "./BaseRepository";
import InventoryReceiptApi from "common/api/InventoryReceiptApi";
import {
	InventoryReceiptFilter,
	InventoryReceiptJsonAddEdit,
} from "common/types/InventoryResceipt";
import InventoryReceiptCollection from "common/collections/InventoryReceiptCollection";
import InventoryReceiptModel from "common/models/InventoryReceiptModel";
class InventoryReceiptRepository extends BaseRepository<InventoryReceiptApi> {
	_api: InventoryReceiptApi | null;

	constructor() {
		super();
		this._api = new InventoryReceiptApi(true);
	}

	static getDefaultFilters(): InventoryReceiptFilter {
		return {
			keyword: "",
			limit: 20,
			page: 1,
			sortby: "",
			sorttype: "",
			source_id: -1,
			source_type: -1,
			direction: -1,
			status: -1,
			is_complete: -1,
			ids: "",
		};
	}

	async getItems(props: GetListProps<InventoryReceiptFilter>) {
		return this._api
			? await this._api.getList(props)
			: new InventoryReceiptCollection();
	}

	async getItem(id: number): Promise<InventoryReceiptModel> {
		return this._api
			? await this._api.getDetail(id)
			: new InventoryReceiptModel(InventoryReceiptModel.getDefaultData());
	}

	async deleteItem(id: number): Promise<string[]> {
		return this._api ? await this._api.delete(id) : [];
	}

	async saveRemote(
		data: InventoryReceiptJsonAddEdit
	): Promise<InventoryReceiptModel> {
		if (this._api) {
			if (data.id > 0) {
				const { details, ...res } = data;
				return await this._api.edit(res);
			} else {
				return await this._api.add(data);
			}
		} else {
			return new InventoryReceiptModel(InventoryReceiptModel.getDefaultData());
		}
	}

	async editDetail(
		data: Pick<InventoryReceiptJsonAddEdit, "action" | "details" | "id">
	) {
		if (this._api) {
			return await this._api.editDetails(data);
		} else {
			return new InventoryReceiptModel(InventoryReceiptModel.getDefaultData());
		}
	}

	async cancel(id: number) {
		if (this._api) {
			return await this._api.cancel(id);
		} else {
			return new InventoryReceiptModel(InventoryReceiptModel.getDefaultData());
		}
	}
}

export default InventoryReceiptRepository;
