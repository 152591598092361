import { Button, Typography } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CompanyResourceError = ({
	error,
	errorAuthorized,
	setError,
	doLogout,
}: {
	error: string[];
	errorAuthorized: boolean;
	setError: (v: string[]) => void;
	doLogout: () => void;
}) => {
	const { t } = useTranslation();

	return (
		<div className="text-center">
			<Typography.Title level={4}>
				{t("common:error.error_loading_data")}
			</Typography.Title>
			{errorAuthorized ? (
				<div>
					{t("common:error.error_jwt_invalid_text_1")}
					<br />
					{t("common:error.error_jwt_invalid_text_2")}
				</div>
			) : (
				<ul>
					{error.map((item, index) => {
						return <li key={index}>{t(`common:error.${item}`)}</li>;
					})}
				</ul>
			)}

			<br />
			<div>
				{errorAuthorized ? (
					<Button>
						<Link
							to=""
							onClick={() => doLogout()}
							className="nav-sub-link pd-3">
							{t("user:logout.title")}
						</Link>
					</Button>
				) : (
					<Button type="primary" onClick={() => setError([])}>
						{/* {t("common:retry")} */}
						{t("user:logout.title")}
					</Button>
				)}
			</div>
		</div>
	);
};

export default CompanyResourceError;
