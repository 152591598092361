import TagConstant from "common/constants/Tag";
import ProductCollectionRepository from "common/repositories/ProductCollectionRepository";
import TagRepository from "common/repositories/TagRepository";
import { ProductJsonPublic } from "common/types/Product";
import React, { useCallback, useState } from "react";
type useProductProps = {
	defaultData: ProductJsonPublic;
};
const useProduct = ({ defaultData }: useProductProps) => {
	const [loading, setLoading] = useState(false);

	const handleCloneTags = useCallback(
		async (product: ProductJsonPublic) => {
			const listTagToClones = defaultData.tags;

			try {
				const response = await new TagRepository().addTagDetail({
					details: listTagToClones.map((tag) => {
						return {
							tag_id: tag.code,
							resource_id: product.id,
							resource_type: TagConstant.RESOURCE_TYPE.PRODUCT,
							display_order: tag.display_order,
						};
					}),
				});
				if (response.hasError()) {
					throw new Error("error_clone_tags_failed");
				}

				return response;
			} catch (error) {
				throw error;
			}
		},
		[defaultData.id]
	);

	const handleCloneCollections = useCallback(
		async (product: ProductJsonPublic) => {
			try {
				const listCollection = defaultData.collections;
				const funcAddCollectionToProduct = (id: number) => {
					return new ProductCollectionRepository().saveRemoteDetail({
						collection_id: id,
						item_ids: [product.id],
					});
				};
				const allPromise = listCollection.map((i) =>
					funcAddCollectionToProduct(i.id)
				);

				await Promise.all(allPromise);
			} catch (error) {
				throw error;
			}
		},
		[defaultData.id]
	);

	const handleCheckedSample = useCallback(async (productIds: number[]) => {
		const addTag = async (id: number) => {
			const response = await new TagRepository().addTagDetail({
				details: [
					{
						tag_id: 114,
						resource_id: id,
						resource_type: TagConstant.RESOURCE_TYPE.PRODUCT,
					},
				],
			});
		};

		const listPromise = productIds.map((id) => addTag(id));

		await Promise.all(listPromise);
	}, []);

	const onCloneProduct = async (product: ProductJsonPublic) => {
		setLoading(true);
		try {
			await Promise.all([
				handleCloneCollections(product),
				handleCloneTags(product),
			]);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	return {
		loading,
		onCloneProduct,
		handleCheckedSample,
	};
};

export default useProduct;
