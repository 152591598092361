import FeedbackModel from "common/models/FeedbackModel";
import { FeedbackCollectionJson, FeedbackJson } from "common/types/Feedback";
import BaseCollection from "./BaseCollection";

class FeedbackCollection extends BaseCollection<
  FeedbackJson,
  FeedbackModel,
  FeedbackCollectionJson
> {
  itemsFromJson(jsonItems: FeedbackJson[]): FeedbackModel[] {
    return jsonItems.map((item) => new FeedbackModel(item));
  }
}
export default FeedbackCollection;
