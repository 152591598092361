import DeliveryInternalForm from "features/delivery/manager/form/DeliveryInternalForm";
import DeliveryModel from "common/models/DeliveryModel";
import DeliveryRepository from "common/repositories/DeliveryRepository";
import { ActionCode, DeliveryButtonSubmit, DeliveryInternalAddEditJson, MatrixItem } from "common/types/Delivery";
import { useEffect, useMemo, useState } from "react";
import Delivery from "common/constants/Delivery";
import {
	Button,
	Dropdown,
	MenuProps,
	message,
	Modal,
	notification,
	Select,
	Spin,
	Tag,
} from "antd";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import OrderModel from "common/models/OrderModel";
import { OrderDeliveryStatus } from "common/types/Order";
import { SelectOption } from "common/types/SelectOption";
import { useTranslation } from "react-i18next";
import ButtonAction from "features/delivery/action/ButtonAction";
import PostAction from "features/delivery/action/PostAction";
import useOrderStore from "zustands/useOrderStore";
import ErrorAlert from "components/ErrorAlert";
import useCheckPermisstion from "hooks/useCheckPermisstion";
import Role from "common/constants/Role";

export default function DeliveryOrderForm({
	id,
	hidePostAction,
	onConfirmSuccess,
}: {
	id: number;
	hidePostAction?: Boolean;
	onConfirmSuccess?: (dely: DeliveryModel) => void;
}) {
	const { t } = useTranslation();
	const setRefreshTrigger = useOrderStore((state) => state.setRefreshTrigger);
	const { checkRole } = useCheckPermisstion({
		roles: [Role.DELIVERY_MANAGER, Role.DELIVERY_ACCOUNTANTS, Role.DELIVERY_SHIPPER],
	});

	//////////////////////////////////////
	const [model, setModel] = useState<DeliveryModel>(
		new DeliveryModel(DeliveryModel.getDefaultData())
	);
	const [modal, contextHolder] = Modal.useModal();
	const [messageApi, contextHolderMess] = message.useMessage();
	//////////////////////////////////////
	const [loading, setLoading] = useState(true);
	const [errors, setErrors] = useState<string[]>([]);

	const [actionCode, setActionCode] = useState<ActionCode>("ACTION_NEW");
	const [requiredUpload, setRequiredUpload] = useState<boolean>(true);
	const [requiredGPS, setRequiredGPS] = useState<boolean>(true);
	const [requiredNote, setRequiredNote] = useState<boolean>(true);
	const [showModal, setShowModal] = useState<boolean>(false);

	const find = useMemo(
		(): ((action: ActionCode) => MatrixItem | null) => (action: ActionCode) => {
			let foundItem: MatrixItem | null = null;
			for (let i = 0; i < Delivery.FULL_MATRIX_ACTION.length; i++) {
				if (Delivery.FULL_MATRIX_ACTION[i][0] === action) {
					foundItem = Delivery.FULL_MATRIX_ACTION[i];
				}
			}
			return foundItem;
		},
		[]
	);

	//////////////////////////////////////
	async function featchData(id: number) {
		if (id) {
			setErrors([]);
			setLoading(true);
			const res = await new DeliveryRepository().getItem(id);
			if (!res.hasError()) {
				setModel(res);
			} else {
				setErrors(res.error.errors);
			}

			setLoading(false);
		}
	}

	const onSubmit = async (data: DeliveryInternalAddEditJson) => {
		messageApi.open({
			type: "loading",
			content: "Action in progress..",
			duration: 0,
		});
		let response: DeliveryModel = await new DeliveryRepository().saveRemote(
			data
		);

		if (response.hasError()) {
			notification.error({ message: response.error.errors[0] });
		} else {
			onConfirmSuccess?.(response);
		}

		messageApi.destroy();
	};
	// async function handleAction(option: SelectOption) {
	// 	messageApi.open({
	// 		type: "loading",
	// 		content: "Action in progress..",
	// 		duration: 0,
	// 	});
	// 	// if (data.partner_delivery !== Delivery.PARTNER_NSHIP) {
	// 	//   new DeliveryRepository().addAction({
	// 	//     action_code: Delivery.ACTION_WAITING,
	// 	//     deliveries_id: response.id.toString(),
	// 	//   });
	// 	// } else {
	// 	//   new DeliveryRepository().addAction({
	// 	//     action_code: Delivery.ACTION_NEW,
	// 	//     deliveries_id: response.id.toString(),
	// 	//   });
	// 	// }

	// 	if (!model) return;
	// 	let action_code: string = "ACTION_NEW";

	// 	switch (option.value) {
	// 		// case OrderDeliveryStatus.STATUS_DRAFT:
	// 		//   action_code = "ACTION_DRAFT";
	// 		//   break;
	// 		// Manager
	// 		case OrderDeliveryStatus.STATUS_NEW:
	// 			action_code = Delivery.ACTION_NEW;
	// 			break;
	// 		case OrderDeliveryStatus.STATUS_WAITING:
	// 			action_code = Delivery.ACTION_WAITING;
	// 			break;
	// 		case OrderDeliveryAccountantStatus.STATUS_APPROVE_REFUND:
	// 			action_code = Delivery.ACTION_APPROVE_REFUND;
	// 			break;
	// 		case OrderDeliveryStatus.STATUS_CANCEL:
	// 			action_code = Delivery.ACTION_CANCEL;
	// 			break;
			
	// 		// Accountant
	// 		case OrderDeliveryAccountantStatus.STATUS_APPROVE_RECEIVE_COD:
	// 			action_code = Delivery.ACTION_APPROVE_RECEIVE_COD;
	// 			break;
	// 		case OrderDeliveryAccountantStatus.STATUS_APPROVE_TRANSFERS:
	// 			action_code = Delivery.ACTION_APPROVE_TRANSFERS;
	// 			break;
			
	// 		// Shipper
	// 		case OrderDeliveryStatus.STATUS_CREATE:
	// 			action_code = Delivery.ACTION_CREATE;
	// 			break;
	// 		case OrderDeliveryStatus.STATUS_DELIVER:
	// 			action_code = Delivery.ACTION_START;
	// 			break;
	// 		case OrderDeliveryStatus.STATUS_SUCCESS:
	// 			action_code = Delivery.ACTION_DELIVERED;
	// 			break;
	// 		case OrderDeliveryStatus.STATUS_COMPLETE:
	// 			action_code = Delivery.ACTION_COMPLETE;
	// 			break;
	// 		case OrderDeliveryStatus.STATUS_REFUND:
	// 			action_code = Delivery.ACTION_REFUND;
	// 			break;
	// 		default:
	// 			break;
	// 	}

	// 	const res = await new DeliveryRepository().addAction({
	// 		action_code: action_code,
	// 		deliveries_id: model.id.toString(),
	// 	});

	// 	if (!res.hasError()) {
	// 		notification.success({ message: "Thành công" });
	// 		featchData(id);
	// 		onConfirmSuccess?.(res);
	// 	} else {
	// 		notification.error({ message: res.error.errors[0] });
	// 	}

	// 	messageApi.destroy();
	// }

	const ItemButtonSubmit = ({
		action,
		title,
		htmlType = "button",
	  }: DeliveryButtonSubmit) => {
		const foundAction = find(action as ActionCode);

		return (
			<Button
				block
				type="primary"
				htmlType={htmlType}
				onClick={() => {
					if (!foundAction) return;

					setActionCode(foundAction[0]);
					setRequiredUpload(foundAction[1]);
					setRequiredGPS(foundAction[2]);
					setRequiredNote(foundAction[3]);
					setShowModal(true);
				}}
			>
				{title.toLocaleUpperCase()}
			</Button>
		);
	};

	const itemsAction: MenuProps["items"] = [
		...checkRole([Role.DELIVERY_MANAGER]) ? [
			{
				key: '1',
				label: 'Quản lý',
				children: OrderModel.getManagerActionStatusList().map((option, index) => {
					return {
						label: (
							<>
								{contextHolder}
								<ItemButtonSubmit
									skey={String(index)}
									action={String(option.value)}
									title={option.label}
								/>
							</>
						),
						key: index,
					};
				}),
			}
		] : [],
		...checkRole([Role.DELIVERY_ACCOUNTANTS]) ? [
			{
				key: '2',
				label: 'Kế toán',
				children: OrderModel.getAccountantActionStatusList().map((option, index) => {
					return {
						label: (
							<>
								{contextHolder}
								<ItemButtonSubmit
									skey={String(index)}
									action={String(option.value)}
									title={option.label}
								/>
							</>
						),
						key: index,
					};
				}),
			},
		] : [],
		...checkRole([Role.DELIVERY_SHIPPER]) ? [
			{
				key: '3',
				label: 'Giao hàng',
				children: OrderModel.getShipperActionStatusList().map((option, index) => {
					return {
						label: (
							<>
								{contextHolder}
								<ItemButtonSubmit
									skey={String(index)}
									action={String(option.value)}
									title={option.label}
								/>
							</>
						),
						key: index,
					};
				}),
			},
		] : [],
	];

	useEffect(() => {
		featchData(id);
	}, [id]);

	return (
		<>
			<Spin spinning={loading}>
				<ErrorAlert items={errors} onRetry={() => featchData(id)}>
					<div className=" flex flex-col gap-4 overflow-auto">
						{/* {model.partner_delivery &&
		model.partner_delivery !== Delivery.PARTNER_NSHIP ? ( */}
						<div className=" flex items-center gap-3">
							<div className=" flex items-center gap-1">
								<span>Trạng thái:</span>
								<Tag
									color={
										DeliveryModel.gtLabelManageAction(model.action_code)?.color
									}>
									{t(`delivery:action_code.${model.action_code}`)}
								</Tag>
							</div>
							{/* {!hidePostAction && (
							<ButtonAction
								single_action={{

								}}
								action_code={model.action_code}
								matrix_action={Delivery.FULL_MATRIX_ACTION}
								prefix_translate_button="delivery:action_code"
								renderModal={({
									nextAction,
									closeModal,
									gpsRequire,
									noteRequire,
									uploadRequire,
								}) => (
									<PostAction
										action={nextAction}
										data={{ deliveries_id: model.id }}
										gpsRequire={gpsRequire}
										uploadRequire={uploadRequire}
										noteRequire={noteRequire}
										handlePostActionSuccess={() => {
											closeModal();
											notification.success({ message: "Thành công" });
											featchData(id);
											setRefreshTrigger("delivery");
											onConfirmSuccess?.(model);
										}}></PostAction>
								)}></ButtonAction>
						)} */}

							<Dropdown
								menu={{ items: itemsAction }}
								trigger={["click"]}
								arrow
								placement="bottom">
								<Button icon={<DownOutlined />}>Thao tác</Button>
							</Dropdown>
						</div>
						{model.id > 0 && (
							<DeliveryInternalForm
								model={model}
								redirectOnSuccess={""}
								onSubmitForm={(data) => {
									onSubmit(data);
								}}
								isShowHeader={false}
								submitText={"Lưu"}
							/>
						)}

						{contextHolderMess}
					</div>
				</ErrorAlert>
			</Spin>
			<Modal
				title={t("delivery:button_action.modal_title")}
				destroyOnClose
				centered
				open={showModal}
				onCancel={() => {
					setShowModal(false);
				}}
				bodyStyle={{ padding: "12px 24px" }}
				okButtonProps={{ style: { display: "none" } }}
			>
				<PostAction
					key={1}
					action={actionCode}
					gpsRequire={requiredGPS}
					noteRequire={requiredNote}
					uploadRequire={requiredUpload}
					data={{
						deliveries_id: model.id,
					}}
					handlePostActionSuccess={() => {
						setShowModal(false);
						message.success({
							content: "Thao tác thành công !",
						});
						featchData(id);
					}}
				/>
			</Modal>
		</>
	);
}
