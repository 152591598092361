import { Menu, Tooltip } from "antd";
import DataGridMainNavigation from "features/datagrid/DataGridMainNavigation";
import useBreakpoint from "hooks/useBreakpoint";
import { useCallback } from "react";
import useMenuStore from "zustands/useMenuStore";

import {
	IconBike,
	IconChartBar,
	IconPackage,
	IconSettings2,
	IconShoppingBag,
	IconTable,
	IconUsers,
	IconTemplate,
	IconTools,
	IconBooks,
	IconClipboardList,
	IconShoppingCart,
	IconSettingsAutomation,
	IconDeviceDesktop,
} from "@tabler/icons-react";
import { SubMenuType } from "antd/es/menu/interface";
import { ApartmentOutlined, RadarChartOutlined } from "@ant-design/icons";

const MainNavigationItem = ({
	menuItem,
	getSubMenu,
	popupContainer,
}: {
	menuItem: SubMenuType;
	getSubMenu: (k: string) => any[];
	popupContainer: any;
}) => {
	const [isSmall] = useBreakpoint();
	const [minimizeMenu] = useMenuStore((state) => [state.minimize]);
	const selectedKeys = useMenuStore((state) => state.selectedKeys);
	const [activeGroup, setActiveGroup] = useMenuStore((state) => [
		state.activeGroup,
		state.setActiveGroup,
	]);

	const getMenuIcon = useCallback((key: string) => {
		let icon = null;
		const iconProps = { className: "text-white", size: 28, strokeWidth: 1 };

		const iconProps1 = {
			className: "text-white text-7",
			size: 28,
			strokeWidth: 1,
		};

		switch (key) {
			case "sale":
				icon = <IconShoppingBag {...iconProps} />;
				break;
			case "inventory":
				icon = <IconPackage {...iconProps} />;
				break;
			case "hrm":
				icon = <IconUsers {...iconProps} />;
				break;
			case "delivery":
				icon = <IconBike {...iconProps} />;
				break;
			case "datagrid":
				icon = <IconTable {...iconProps} />;
				break;
			case "report":
				icon = <IconChartBar {...iconProps} />;
				break;
			case "setting":
				icon = <IconSettings2 {...iconProps} />;
				break;
			case "template":
				icon = <IconTemplate {...iconProps} />;
				break;
			case "tool":
				icon = <IconTools {...iconProps} />;
				break;
			case "content":
				icon = <IconBooks {...iconProps} />;
				break;
			case "task":
				icon = <IconClipboardList {...iconProps} />;
				break;
			case "shop":
				icon = <IconShoppingCart {...iconProps} />;
				break;
			case "system":
				icon = <IconSettingsAutomation {...iconProps} />;
				break;
			case "device":
				icon = <IconDeviceDesktop {...iconProps} />;
				break;
		}

		return icon;
	}, []);

	const menuItemCom = (
		<>
			<div
				className={
					"w-full text-center py-2 mb-2 cursor-pointer rounded border border-transparent" +
					(menuItem.key === activeGroup
						? " bg-blue-700 hover:border-blue-500"
						: " hover:border-blue-400")
				}
				onClick={() => setActiveGroup(menuItem.key)}>
				{getMenuIcon(menuItem.key)}

				<div className="text-center text-xs uppercase font-semibold text-white mt-1 opacity-80">
					<small>{menuItem.label}</small>
				</div>
			</div>
		</>
	);

	return minimizeMenu && !isSmall ? (
		<Tooltip
			showArrow={false}
			title={
				<>
					{menuItem.key === "datagrid" ? (
						<DataGridMainNavigation source="tooltip" />
					) : (
						<Menu
							items={getSubMenu(menuItem.key)}
							selectedKeys={selectedKeys}
							onSelect={() => setActiveGroup(menuItem.key)}
							mode="inline"
							style={{ background: "transparent" }}
							theme="dark"
							className="tooltip_navigation"
						/>
					)}
				</>
			}
			overlayInnerStyle={{ width: 240 }}
			placement="rightTop"
			getPopupContainer={() => popupContainer}
		>
			{menuItemCom}
		</Tooltip>
	) : (
		menuItemCom
	);
};

export default MainNavigationItem;
