const ContractAndDevice = {
  STATUS_DRAFT: 1, // Nháp
  STATUS_HOLDING: 2, // Đang giữ
  STATUS_ACTIVE: 3, // Đang sử dụng
  STATUS_CANCELLED: 4, // Đã hủy
  STATUS_LOST: 5, // Đã mất

  DEVICE: {
    SOURCE_STATUS_NEW: 1, // Thiết bị mới
    SOURCE_STATUS_USED: 2, // Thiết bị đang sử dụng
    SOURCE_STATUS_REPAIR: 3, // Thiết bị đang sửa chữa
    SOURCE_STATUS_OLD: 4, // Thiết bị cũ
    SOURCE_STATUS_BROKEN: 5, // Thiết bị hỏng
  },

  CONTRACT: {
    SOURCE_STATUS_DRAFT: 10, // Nháp
    SOURCE_STATUS_PENDING: 11, // Chờ duyệt
    SOURCE_STATUS_SIGNED: 12, // Đã ký
    SOURCE_STATUS_COMPLETED: 13, // Hoàn thành
    SOURCE_STATUS_CANCELLED: 14, // Đã hủy
  },

  GROUP_DEVICE: 1,
  GROUP_CONTRACT: 2,

  WARRANTY_ENABLE: 1,
  WARRANTY_DISABLE: 0,
};

export default ContractAndDevice;
