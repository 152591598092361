import {
	Alert,
	Button,
	Col,
	Dropdown,
	Form,
	MenuProps,
	Modal,
	notification,
	Row,
	Select,
	Tabs,
	TabsProps,
	Tag,
	Typography,
} from "antd";
import OrderModel from "common/models/OrderModel";
import OrderRepository from "common/repositories/OrderRepository";
import { useEffect, useState } from "react";
import OrderFormSectionInfo from "../form/section/OrderFormSectionInfo";
import OrderFormSectionDetail from "../form/section/orderdetail/OrderDetailListForm";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import Order from "common/constants/Order";
import OrderPrintBill from "../form/OrderPrintBill";
import useActionsOrder from "hooks/useActionsOrder";
import TextArea from "antd/es/input/TextArea";
import { useTranslation } from "react-i18next";
import RoleCheck from "components/RoleCheck";
import Role from "common/constants/Role";
import OrderFormSectionCashflowReceipt from "../form/section/shipping/OrderFormSectionCashflowReceipt";
import { Link } from "react-router-dom";
import { IconTruck } from "@tabler/icons-react";
import Delivery from "common/constants/Delivery";
import DeliveryModel from "common/models/DeliveryModel";
import DeliveryOrderForm from "components/order/DeliveryOrderForm";
import OrderFormSectionProductReceipt from "../form/section/shipping/OrderFormSectionProductReceipt";
import OrderFormSectionHistory from "../form/section/OrderFormSectionHistory";
import OrderFormSectionNoteTaxInvoice from "../form/section/OrderFormSectionNoteTaxInvoice";
export default function OrderQuickView({
	orderId,
	onUpdate,
}: {
	orderId: number | string;
	onUpdate?: () => void;
}) {
	const [order, setOrder] = useState(OrderModel.getDefaultData());
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingCancel, setIsLoadingCancel] = useState(false);
	const [isLoadingDeli, setIsLoadingDeli] = useState(false);
	const [form] = Form.useForm();
	const [modal, contextHolder] = Modal.useModal();
	const { t } = useTranslation();

	const { allowStatusCancel, allowStatusComplete } = useActionsOrder({ order });

	const [openCancel, setOpenCancel] = useState(false);
	const [openDeli, setOpenDeli] = useState(false);
	const [reason, setReason] = useState<OrderCancelReasonPayload>({
		cancel_reason: 1,
		note_cancel: "",
	});

	const statusInfo = OrderModel.getStatus(order.status);

	async function handleCancelOrder() {
		if (
			reason.cancel_reason === Order.CANCEL_REASON_OTHER &&
			!reason.note_cancel
		) {
			notification.error({ message: "Vui lòng nhập lí do" });
			return;
		}

		setIsLoadingCancel(true);

		const res = await new OrderRepository().cancel({
			id: order.id,
			status: Order.STATUS_CANCEL,
			cancel_reason: reason.cancel_reason,
			note_cancel: reason.note_cancel,
		});

		if (!res.hasError()) {
			notification.success({ message: "Hủy thành công" });
			fetchData(orderId);
			setOpenCancel(false);
			onUpdate?.();
		} else {
			res.error.errors.forEach((err) => {
				notification.error({ message: t(`order:${err}`), duration: 5000 });
			});
		}

		setIsLoadingCancel(false);
	}

	async function handleCompleteOrder() {
		const res = await new OrderRepository().confirm({
			id: order.id,
			status: Order.STATUS_COMPLETE,
		});
		if (!res.hasError()) {
			fetchData(orderId);
			notification.success({ message: "Xác nhận thành công" });
			onUpdate?.();
		} else {
			res.error.errors.forEach((err) => {
				notification.error({ message: t(`order:${err}`), duration: 5000 });
			});
		}
	}

	const confirmComplete = () => {
		modal.confirm({
			title: "Bạn muốn xác nhận đơn đặt hàng này?",
			okText: "Xác nhận",
			cancelText: "Đóng",
			onOk: handleCompleteOrder,
		});
	};

	function onConfirmDelySuccess(deli: DeliveryModel) {
		notification.success({ message: "Xác nhận giao hàng thành công" });
		setOpenDeli(false);
	}

	const itemsAction: MenuProps["items"] = [
		{
			label: (
				<>
					{contextHolder}

					<Button
						disabled={!allowStatusComplete}
						onClick={confirmComplete}
						type="primary"
						style={{ width: "100%" }}>
						Xác nhận
					</Button>
				</>
			),
			key: "0",
			disabled: !allowStatusComplete,
		},
		{
			label: (
				<>
					{contextHolder}

					<Button
						disabled={!allowStatusCancel}
						onClick={() => setOpenCancel(true)}
						type="primary"
						danger
						style={{ width: "100%" }}>
						Hủy
					</Button>
				</>
			),
			key: "1",
			disabled: !allowStatusCancel,
		},
	];

	const items: TabsProps["items"] = [
		{
			key: "1",
			label: "Sản phẩm & tổng tiền",
			children: (
				<>
					<OrderFormSectionDetail
						model={order}
						form={form}
						allowEditInput={false}
					/>
				</>
			),
		},
		{
			key: "2",
			label: "Thông tin & trạng thái",
			children: (
				<Row gutter={24} style={{ marginTop: 16 }}>
					<Col span={12}>
						<Typography.Paragraph className=" font-bold">
							Thông tin giao hàng
						</Typography.Paragraph>
						<Typography className=" font-bold">
							{order.shipping_fullname}
						</Typography>
						<Typography>{order.shipping_phone}</Typography>
						<Typography>{order.shipping_address}</Typography>
					</Col>
					<Col span={12}>
						<OrderFormSectionInfo orderDefault={order} />
					</Col>
				</Row>
			),
		},
		{
			key: "3",
			label: "Thu - chi",
			children: (
				<RoleCheck
					roles={[Role.CASHFLOW_VIEW]}
					componentOnFail={
						<Alert
							className="mt-4"
							type="warning"
							showIcon
							message={
								t("order:form.cashflow_receipt_not_view_permission")
							}
						/>
					}
				>
					<OrderFormSectionCashflowReceipt order={order} />
				</RoleCheck>
			),
		},
		{
			key: "4",
			label: "Nhập - xuất kho",
			children: (
				<RoleCheck
					roles={[Role.INVENTORY_VIEW]}
					componentOnFail={
						<Alert
							className="mt-4"
							type="warning"
							showIcon
							message={
								t("order:form.product_receipt_not_view_permission")
							}
						/>
					}
				>
					<OrderFormSectionProductReceipt order={order} />
				</RoleCheck>
			),
		},
		{
			key: "5",
			label: "Hóa đơn VAT",
			children: (
				<RoleCheck
					roles={[Role.INVENTORY_VIEW]}
					componentOnFail={
						<Alert
							className="mt-4"
							type="warning"
							showIcon
							message={
								t("order:form.tax_invoice_not_view_permission")
							}
						/>
					}
				>
					<OrderFormSectionNoteTaxInvoice order={order} />
				</RoleCheck>
			),
		},
		{
			key: "6",
			label: "Lịch sử thao tác",
			children: (
				<RoleCheck
					roles={[Role.INVENTORY_VIEW]}
					componentOnFail={
						<Alert
							className="mt-4"
							type="warning"
							showIcon
							message={
								t("order:form.history_not_view_permission")
							}
						/>
					}
				>
					<OrderFormSectionHistory order={order} />
				</RoleCheck>
			),
		},
	];

	async function fetchData(orderId: number | string) {
		if (Number(orderId)) {
			setIsLoading(true);

			let fetchedModel = await new OrderRepository().getItem(Number(orderId));
			if (!fetchedModel.hasError()) {
				setOrder(fetchedModel.toJson());
			}

			setIsLoading(false);
		}
	}

	useEffect(() => {
		fetchData(orderId);
	}, [orderId]);

	if (isLoading) {
		return <LoadingOutlined />;
	}

	return (
		<div>
			<div className=" flex justify-between items-center">
				<div>
					<Typography.Paragraph className="flex gap-2 items-center font-bold m-0">
						Trạng thái:
						<Tag bordered color={statusInfo.color}>
							{statusInfo.label}
						</Tag>
					</Typography.Paragraph>
					{order.invoice_id ? (
						<Alert
							className="mb-4"
							message={
								<>
									Đơn hàng đặt hàng đã có phát sinh{" "}
									<Link
										className=" uppercase font-semibold"
										to={`/order/edit/id/${order.invoice_id}`}>
										hóa đơn
									</Link>
								</>
							}
							type="info"
							showIcon
						/>
					) : null}
				</div>
				<div className=" flex gap-4">
					<Dropdown
						menu={{ items: itemsAction }}
						trigger={["click"]}
						arrow
						placement="bottom">
						<Button icon={<DownOutlined />}>Thao tác</Button>
					</Dropdown>
					{order.status !== Order.STATUS_CANCEL &&
					order.delivery_lits_id.length > 0 ? (
						<RoleCheck roles={[Role.ORDER_MANAGER_DELIVERY]}>
							<Button
								type="primary"
								icon={<IconTruck />}
								onClick={() => setOpenDeli(true)}>
								Giao hàng
							</Button>
						</RoleCheck>
					) : null}
					{order.code ? <OrderPrintBill order={order} /> : null}
				</div>
			</div>
			<Tabs defaultActiveKey="1" items={items} />

			<Modal
				open={openCancel}
				onCancel={() => {
					setOpenCancel(false);
				}}
				title={"Bạn muốn hủy đơn đặt hàng này?"}
				okText="Xác nhận"
				cancelText="Đóng"
				confirmLoading={isLoadingCancel}
				onOk={handleCancelOrder}>
				<CancelOrderReason defaultValue={reason} onChange={setReason} />
			</Modal>

			<Modal
				open={openDeli}
				onCancel={() => {
					setOpenDeli(false);
				}}
				title={"Giao hàng"}
				okText="Xác nhận"
				cancelText="Đóng"
				confirmLoading={isLoadingDeli}
				destroyOnClose
				width={800}
				footer={null}
				centered>
				<DeliveryOrderForm
					onConfirmSuccess={onConfirmDelySuccess}
					id={
						order.delivery_lits_id.length
							? order.delivery_lits_id.split(",").map(Number)[0]
							: 0
					}
				/>
			</Modal>
		</div>
	);
}

interface OrderCancelReasonPayload {
	cancel_reason: number;
	note_cancel?: string;
}
function CancelOrderReason({
	defaultValue,
	onChange,
}: {
	defaultValue: OrderCancelReasonPayload;
	onChange: (data: OrderCancelReasonPayload) => void;
}) {
	const [viewNoteCanle, setViewNoteCancle] = useState(false);
	const { t } = useTranslation();
	return (
		<div className=" flex flex-col gap-4">
			<Select
				style={{ width: "100%" }}
				showSearch
				onChange={(e) => {
					onChange({ ...defaultValue, cancel_reason: Number(e) });
					if (e == Order.CANCEL_REASON_OTHER) {
						setViewNoteCancle(true);
					} else {
						setViewNoteCancle(false);
					}
				}}
				value={defaultValue.cancel_reason}
				options={OrderModel.getCancelReasonList()}></Select>

			{viewNoteCanle && (
				<TextArea
					placeholder="Lý do hủy đơn..."
					required
					value={defaultValue.note_cancel}
					onChange={(e) => {
						onChange({ ...defaultValue, note_cancel: e.target.value });
					}}>
					{" "}
				</TextArea>
			)}

			<div className="mb-2">
				<Alert
					showIcon
					type="warning"
					message={t("order:cancel_dependency_before_note_heading")}
					description={t("order:cancel_dependency_before_note")}></Alert>
			</div>
		</div>
	);
}
