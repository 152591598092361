import { Flex, Spin, Tag, Tooltip, Typography } from "antd";
import { all } from "axios";
import OrderModel from "common/models/OrderModel";
import PromotionModel from "common/models/PromotionModel";
import OrderRepository from "common/repositories/OrderRepository";
import { CartOrderJson } from "common/types/Cart";
import { CartPromotion, IsUse } from "common/types/Cart";
import { OrderJson } from "common/types/Order";
import { PromotionJson2 } from "common/types/Promotion";
import { useCallback, useEffect, useMemo, useState } from "react";

type Props = {
	order: OrderJson;
};

type StatusData = {
	loading: boolean;
	data: OrderJson | null;
	allPromotion: CartPromotion[];
};
const STATUS: StatusData = {
	loading: true,
	data: null,
	allPromotion: [],
};
export default function OrderFullPromotionView({ order }: Props) {
	const [status, setStatus] = useState<StatusData>(STATUS);
	const fetchData = useCallback(async () => {
		try {
			setStatus((prev) => ({ ...prev, loading: true }));

			// const orderModel = await new OrderRepository().getItem(+order.id);
			const orderModel = await new OrderModel(order);

			if (!orderModel.hasError()) {
				const fullPromotionOfOrderIsUSed = orderModel.promotions.filter(
					(promotion) => promotion.is_use === IsUse.USE
				);
				const fullPromotionOfItemIsUSed = orderModel.details.data
					.flatMap((i) => i.promotions)
					.filter(
						(promotion) =>
							promotion.is_use === IsUse.USE && promotion.code.length > 0
					);

				// setStatus((prev => { ...prev, data: orderModel.toJson()  }));
				setStatus((prev) => ({
					...prev,
					data: orderModel.toJson(),
					allPromotion: [
						...fullPromotionOfOrderIsUSed,
						...fullPromotionOfItemIsUSed,
					],
				}));
			}
		} catch (error) {
		} finally {
			setStatus((prev) => ({ ...prev, loading: false }));
		}
	}, [order.id, order.promotions, order.details.data]);

	const isEmptyPromotion = useMemo(() => {
		if (status.loading) return true;
		return status.allPromotion.length === 0;
	}, [status.allPromotion.length, status.loading]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return (
		<Spin spinning={status.loading}>
			<Flex wrap={true} gap={2}>
				{isEmptyPromotion && (
					<Tooltip
						title={
							<>
								<Typography.Text className="text-gray-400">
									Không có khuyến mãi áp dụng, có thể có giảm giá tùy chọn
								</Typography.Text>
							</>
						}>
						<Tag>Trống</Tag>
					</Tooltip>
				)}

				{status.allPromotion.map((promotion: CartPromotion) => (
					<Tooltip
						key={promotion.promotion_id}
						title={
							<Flex vertical gap={2}>
								<Typography.Text copyable className="text-white">
									{/* {PromotionModel.getLabelGroupFromDetailPublic(
                  promotion.promotion_detail.group
                )} - {} */}
									{promotion.promotion_detail.campaign_info.code}
								</Typography.Text>
								<Typography.Text className="text-gray-400">
									{promotion.promotion_detail.campaign_info.name ?? ""}
								</Typography.Text>
								<Typography.Text className="text-gray-400">
									{PromotionModel.getLabelDiscoutTypeFromDetailPublic(
										promotion.promotion_detail.discount_type
									)}
								</Typography.Text>
								<Typography.Text className="text-gray-400">
									{promotion.promotion_detail.campaign_info.description ?? ""}
								</Typography.Text>
							</Flex>
						}>
						<Tag color="blue">{promotion.code}</Tag>
					</Tooltip>
				))}
			</Flex>
		</Spin>
	);
}
