import Product from "common/constants/Product";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption, SelectOptionNormal } from "common/types/SelectOption";
import i18n from "i18n";

import BaseModel from "./BaseModel";

import {
	Images,
	ProductStatus,
	type BrandJson,
	type ProductJsonPublic,
	type Quantitie,
} from "common/types/Product";
const defaultDataProduct: ProductJsonPublic = {
	sku: "",
	status: 1,
	id: 0,
	name: "",
	handle: "",
	quantity: 0,
	option_name: "",
	brand: {
		handle: "",
		id: 0,
		title: "",
	},
	price_min: 0,
	price_max: 0,
	vat: 0,
	compare_at_price_min: 0,
	compare_at_price_max: 0,
	images: [],
	tags: [],
	created_at: 0,
	updated_at: 0,
	full_name: "",
	count_childs: 0,
	parent_id: -1,
	barcode: "",
	price: 0,
	compare_at_price: 0,
	kiotviet_id: 0,
	allow_sale: 0,
	origin: "",
	production_year: 0,
	display_from: 0,
	display_to: 0,
	limit_sale: 0,
	collections: [],
	compare_discount: 0,
	compare_discount_percent: 0,
	description: "",
	detail: "",
	list_childs: [],
	kiotviet_image: "",
	meta_description: "",
	meta_keyword: "",
	meta_title: "",
	parent_handle: "",
	summary: "",
	type_id: 1,
	variant_id: 1,
	quantities: [],
	store_id: 0,
	allow_promotion: 0,
	min_buy: 1,
	show_pos: 0,
	show_web: 0,
	supplier_id: 0,
	supplier: 0,
	warehouse_quantities: [],
};

class ProductModel
	extends BaseModel
	implements BaseModelJson<ProductJsonPublic>
{
	status: number = defaultDataProduct.status;
	id: number = defaultDataProduct.id;
	name: string = defaultDataProduct.name;
	handle: string = defaultDataProduct.handle;
	quantity: number = defaultDataProduct.quantity;
	brand: BrandJson | null = defaultDataProduct.brand;
	price_min: number = defaultDataProduct.price_min;
	price_max: number = defaultDataProduct.price_max;
	compare_at_price_min: number = defaultDataProduct.compare_at_price_min;
	compare_at_price_max: number = defaultDataProduct.compare_at_price_max;

	created_at: number = defaultDataProduct.created_at;
	updated_at: number = defaultDataProduct.updated_at;

	full_name: string = defaultDataProduct.full_name;
	count_childs: number = defaultDataProduct.count_childs;
	parent_id: number = defaultDataProduct.parent_id;
	barcode: string = defaultDataProduct.barcode;
	price: number = defaultDataProduct.price;
	compare_at_price: number = defaultDataProduct.compare_at_price;
	sku: string = defaultDataProduct.sku;

	kiotviet_id: number = defaultDataProduct.kiotviet_id;

	allow_sale: number = defaultDataProduct.allow_sale;
	origin: string = defaultDataProduct.origin;
	production_year: number = defaultDataProduct.production_year;
	display_from: number = defaultDataProduct.display_from;
	display_to: number = defaultDataProduct.display_to;
	limit_sale: number = defaultDataProduct.limit_sale;
	option_name: string = defaultDataProduct.option_name;
	store_id: number = defaultDataProduct.store_id;
	quantities: Quantitie[] = defaultDataProduct.quantities;
	images: Images[] = defaultDataProduct.images;
	allow_promotion = defaultDataProduct.allow_promotion;
	min_buy = defaultDataProduct.min_buy;
	show_pos = defaultDataProduct.show_pos;
	show_web = defaultDataProduct.show_web;
	supplier_id = defaultDataProduct.supplier_id;
	supplier = defaultDataProduct.supplier;
	collections = defaultDataProduct.collections;
	summary = defaultDataProduct.summary;
	description = defaultDataProduct.description;
	list_childs = defaultDataProduct.list_childs;

	meta_description = defaultDataProduct.meta_description;
	meta_keyword = defaultDataProduct.meta_keyword;
	meta_title = defaultDataProduct.meta_title;

	warehouse_quantities = defaultDataProduct.warehouse_quantities;
	vat = defaultDataProduct.vat;

	constructor(json: ProductJsonPublic) {
		super();
		Object.assign(this, { ...defaultDataProduct, ...json });
	}

	static getDefaultData(): ProductJsonPublic {
		return { ...defaultDataProduct };
	}

	toJson(): ProductJsonPublic {
		return JSON.parse(JSON.stringify({ ...this }));
	}

	static getStatusList(): SelectOption[] {
		return [
			{
				value: ProductStatus.ACTIVE,
				label: i18n.t("common:STATUS_ENABLE"),
				color: "green",
			},
			{
				value: ProductStatus.UNACTIVE,
				label: i18n.t("common:STATUS_DISABLED"),
				color: "red",
			},
		];
	}

	static getShowWebList(): SelectOption[] {
		return [
			{
				value: 1,
				label: "Có",
				color: "green",
			},
			{
				value: 0,
				label: "Không",
				color: "red",
			},
		];
	}

	static getStatus(value: number): SelectOption | undefined {
		return this.getStatusList().find((item) => item.value === value);
	}

	static getUnitList(): SelectOption[] {
		return [
			{
				value: Product.UNIT_DEFAULT,
				label: i18n.t("product:unit.UNIT_DEFAULT"),
			},
			{
				value: Product.UNIT_BAG,
				label: i18n.t("product:unit.UNIT_BAG"),
			},
			{
				value: Product.UNIT_CARTON,
				label: i18n.t("product:unit.UNIT_CARTON"),
			},
			{
				value: Product.UNIT_TICKET,
				label: i18n.t("product:unit.UNIT_TICKET"),
			},
			{
				value: Product.UNIT_BOTTLE,
				label: i18n.t("product:unit.UNIT_BOTTLE"),
			},
			{
				value: Product.UNIT_PIECE,
				label: i18n.t("product:unit.UNIT_PIECE"),
			},
			{
				value: Product.UNIT_GLASS,
				label: i18n.t("product:unit.UNIT_GLASS"),
			},
			{
				value: Product.UNIT_ANIMAL,
				label: i18n.t("product:unit.UNIT_ANIMAL"),
			},
			{
				value: Product.UNIT_SET,
				label: i18n.t("product:unit.UNIT_SET"),
			},
			{
				value: Product.UNIT_PAIR,
				label: i18n.t("product:unit.UNIT_PAIR"),
			},
			{
				value: Product.UNIT_ROLL,
				label: i18n.t("product:unit.UNIT_ROLL"),
			},
			{
				value: Product.UNIT_BOOK,
				label: i18n.t("product:unit.UNIT_BOOK"),
			},
		];
	}

	static getUnit(value: number): SelectOption | undefined {
		return this.getUnitList().find((item) => item.value === value);
	}

	static getShowOptions(): SelectOptionNormal[] {
		return [
			{
				value: Product.SHOW_ALL,
				label: i18n.t("product:show_all"),
				color: "",
			},
			{
				value: Product.SHOW_WEB,
				label: i18n.t("product:show_web"),
				color: "blue-inverse",
			},
			{
				value: Product.SHOW_POS,
				label: i18n.t("product:show_pos"),
				color: "orange-inverse",
			},
		];
	}
}

export default ProductModel;
