import { Flex, Modal, Spin, Tag, Typography } from "antd";
import Role from "common/constants/Role";
import CustomerModel from "common/models/CustomerModel";
import CustomerRepository from "common/repositories/CustomerRepository";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import TextDateTime from "./TextDateTime";

type Props = {
	id: number;
};
const TextCustomer = ({ id }: Props) => {
	const accountRoleSubjects = useLoginAccountStore((state) =>
		state.account.role.map((r) => r.subject_id)
	);

	////////////////////////////////////
	const [customer, setCustomer] = useState(CustomerModel.getDefaultData());
	const [loading, setLoading] = useState(true);
	const [errors, setErrors] = useState<string[]>([]);
	const [open, setOpen] = useState(false);
	const [isVisitors, setIsVisitors] = useState(false);

	////////////////////////////////////
	const getDetailCustomer = useCallback(async () => {
		setLoading(true);
		const res = await new CustomerRepository().getItem(id);

		if (!res.hasError()) {
			setCustomer(res.toJson());
			setErrors([]);
		} else {
			if (res.error.statusCode === 404) {
				setIsVisitors(true);
			} else {
				setErrors(res.error.errors);
			}
		}
		setLoading(false);
	}, [id]);

	////////////////////////////////////

	const checkRoleCustomerManage = useMemo(() => {
		return (
			[Role.CUSTOMER_MANAGE].filter((r) => accountRoleSubjects.includes(r))
				.length > 0
		);
	}, [accountRoleSubjects]);

	const isVistorNoName = useMemo(() => customer.full_name.length === 0, [customer.full_name]);

	////////////////////////////////////
	useEffect(() => {
		getDetailCustomer();
	}, [getDetailCustomer]);
	if (errors.length > 0) {
		return <Tag color="error">Có lỗi xảy ra !</Tag>;
	}

	if (isVisitors) {
		return <Typography.Link> Khách vãng lai</Typography.Link>;
	}
	return (
		<Spin size="small" spinning={loading}>
			<Typography.Link
				onClick={() => {
					checkRoleCustomerManage && setOpen(true);
				}}>
				{customer.full_name.length > 0 ? customer.full_name : "Khách vãng lai"}
				{customer.id > 0 && (
					<>
						{" - "}
						<Typography.Text className="inline" copyable>
							{customer.id}
						</Typography.Text>{" "}
					</>
				)}
			</Typography.Link>

			<Modal
				title="Thông tin khách hàng"
				cancelButtonProps={{ className: "hidden" }}
				okButtonProps={{ className: "hidden" }}
				open={open}
				onCancel={() => {
					setOpen(false);
				}}>
					{isVistorNoName ? (
						<Fragment>
							<Typography.Text className="text-yellow-500">Khách vãng lai</Typography.Text>
							<Flex gap={4}>
								<Typography.Text>Giới tính:</Typography.Text>
								{CustomerModel.getGender(customer.gender)?.label ? (
									<Typography.Link copyable>
										{CustomerModel.getGender(customer.gender)?.label}
									</Typography.Link>
								) : (
									<Tag>Trống</Tag>
								)}
							</Flex>
							<Flex gap={4}>
								<Typography.Text>Độ tuổi:</Typography.Text>
								{CustomerModel.getAgeRange(customer.age_range)?.label ? (
									<Typography.Link copyable>
										{CustomerModel.getAgeRange(customer.age_range)?.label}
									</Typography.Link>
								) : (
									<Tag>Trống</Tag>
								)}
							</Flex>
						</Fragment>
					) : (
						<Fragment>
							<Flex gap={4}>
								<Typography.Text>Họ tên:</Typography.Text>
								<Typography.Link copyable>{customer.full_name}</Typography.Link>
							</Flex>
							<Flex gap={4}>
								<Typography.Text>Số điện thoại:</Typography.Text>
								<Typography.Link copyable>{customer.phone}</Typography.Link>
							</Flex>
							<Flex gap={4}>
								<Typography.Text>Giới tính:</Typography.Text>
								{CustomerModel.getGender(customer.gender)?.label ? (
									<Typography.Link copyable>
										{CustomerModel.getGender(customer.gender)?.label}
									</Typography.Link>
								) : (
									<Tag>Trống</Tag>
								)}
							</Flex>
							<Flex gap={4}>
								<Typography.Text>Độ tuổi:</Typography.Text>
								{CustomerModel.getAgeRange(customer.age_range)?.label ? (
									<Typography.Link copyable>
										{CustomerModel.getAgeRange(customer.age_range)?.label}
									</Typography.Link>
								) : (
									<Tag>Trống</Tag>
								)}
							</Flex>
							<Flex gap={4}>
								<Typography.Text>Ngày sinh:</Typography.Text>
								{customer.birthday > 0 ? (
									<Typography.Link copyable>
										<TextDateTime ts={customer.birthday}></TextDateTime>
									</Typography.Link>
								) : (
									<Tag>Trống</Tag>
								)}
							</Flex>

							<Flex gap={4}>
								<Typography.Text>Email:</Typography.Text>
								<Typography.Link copyable>{customer.email}</Typography.Link>
							</Flex>
						</Fragment>
					)}
			</Modal>
		</Spin>
	);
};

export default TextCustomer;
