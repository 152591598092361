import { Card, DatePicker, message, Typography } from "antd";
import { Button } from "antd";
import { Col, Form, Input, Row } from "antd";
import {
	DataZoneBD,
	ZoneDataBanner,
	ZoneDataSubBanner,
} from "common/types/Event";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import UploadEvent from "./UploadEvent";
import { useForm } from "antd/es/form/Form";
import { CloseOutlined } from "@ant-design/icons";
import Helper from "common/utils/helper";
import useMessage from "antd/es/message/useMessage";
import dayjs from "dayjs";

const SectionSubbannerDB = ({
	data,
	onSubmit,
}: {
	data: ZoneDataSubBanner[];
	onSubmit: (data: ZoneDataSubBanner[]) => void;
}) => {
	const { t } = useTranslation();
	const [message] = useMessage();
	const { RangePicker } = DatePicker;

	const [form] = useForm();

	const init = data.map((item) => ({
		...item,
		available_type: [
			dayjs(item.from_time * 1000 || Date.now()),
			dayjs(item.to_time * 1000 || Date.now()),
		],
	}));
	const rangeConfig = {
		rules: [
			{
				type: "array" as const,
				required: true,
				message: "Vui lòng chọn thời gian!",
			},
		],
	};

	const dateFormat = "HH:mm:ss DD-MM-YYYY";

	const onFinish = (values: any) => {
		const dataForm = values.items as any[];
		try {
			const result: ZoneDataSubBanner[] = dataForm.map((item) => {
				const fromTimestamp = Helper.convertedDateToTimeStamp(
					item.available_type[0]
				) as number;
				const toTimestamp = Helper.convertedDateToTimeStamp(
					item.available_type[1]
				) as number;

				return {
					from_time: fromTimestamp,
					to_time: toTimestamp,
					img: item.img,
					url: item.url,
				};
			});
			onSubmit(result);
		} catch (error) {
			console.log("🚀 ~ onFinish ~ error:", error);
			message.error("Có lỗi xảy ra");
		}
	};

	const setDynamicFieldValue = () => {
		const users = form.getFieldValue("items") || [];
		if (users.length > 0) {
			users[0].name = "Updated Name"; // Cập nhật trường name của người dùng đầu tiên
			form.setFieldsValue({ users });
		}
	};
	return (
		<Form
			labelCol={{ span: 6 }}
			wrapperCol={{ span: 18 }}
			form={form}
			name="dynamic_form_sub_banner"
			autoComplete="off"
			initialValues={{ items: init }}
			layout="vertical"
			scrollToFirstError
			onFinish={onFinish}>
			<Form.List name="items">
				{(fields, { add, remove }) => (
					<div style={{ display: "flex", rowGap: 16, flexDirection: "column" }}>
						{fields.map((field, index) => (
							<Card
								size="default"
								title={`Hình ${field.name + 1}`}
								key={field.key}
								extra={
									<CloseOutlined
										onClick={() => {
											remove(field.name);
										}}
									/>
								}>
								<Row>
									<Col span={12}>
										<UploadEvent
											className="flex gap-2"
											height={120}
											defaultUrl={data?.[index]?.img || ""}
											onSuccess={(url) => {
												message.success("Thay đổi thành công");
												form.setFieldValue(["items", field.name, "img"], url);
											}}></UploadEvent>
									</Col>
									{/* <Col span={12}>
										<UploadEvent
											className="flex gap-2"
											height={120}
											defaultUrl={data?.[field.key]?.image_mobile || ""}
											onSuccess={(url) => {
												message.success("Thay đổi thành công");
												form.setFieldValue(
													["items", field.name, "image_mobile"],
													url
												);
											}}></UploadEvent>
									</Col> */}
									<Col span={24}>
										<Form.Item
											labelCol={{ span: 24 }}
											label="Mốc thời gian chạy"
											{...rangeConfig}
											name={[field.name, "available_type"]}>
											<RangePicker showTime format={dateFormat} />
										</Form.Item>
									</Col>
									<Col span={24}>
										<Form.Item
											labelCol={{ span: 24 }}
											className="mb-4"
											label="Đường dẫn"
											name={[field.name, "url"]}>
											<Input size="middle"></Input>
										</Form.Item>
									</Col>
								</Row>
							</Card>
						))}

						<Button type="dashed" onClick={() => add()} block>
							+ Thêm nhóm
						</Button>

						<Button
							className="  absolute bottom-10 right-4 w-[100px]"
							type="primary"
							htmlType="submit">
							Lưu
						</Button>
					</div>
				)}
			</Form.List>
		</Form>
	);
};

export default SectionSubbannerDB;
