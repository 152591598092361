import { Form, Input } from "antd";
import Role from "common/constants/Role";
import OrderModel from "common/models/OrderModel";
import { Filter } from "common/types/Filter";
import { StoreJson } from "common/types/Store";
import DateFormFilter from "components/datetime/DateFormFilter";
import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import dayjs, { Dayjs } from "dayjs";
import StoreFormSelect from "features/store/StoreFormSelect";
import useDatabaseTable from "hooks/useDatabaseTable";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import type { RangePickerProps } from "antd/es/date-picker";
import DatePickerRangeFilter from "components/datetime/DatePickerRangeFilter";
import DeliveryModel from "common/models/DeliveryModel";
import AxiosClient from "common/api/AxiosClient";
import BaseCollection from "common/collections/BaseCollection";

type RangeValue = [Dayjs | null, Dayjs | null] | null;

interface Ecomplatform {
	id: number;
	company_id: number;
	title: string;
	code: string;
	status: number;
	date_created: number;
	date_modified: number;
}

const OrderListFilter = <F extends Filter>({
	filters,
	setFilters,
	defaultFilters,
	total,
}: {
	filters: F;
	setFilters: (f: F) => any;
	defaultFilters: F;
	total: number;
}) => {
	const { t } = useTranslation();

	const [channels, setChannels] = useState<Ecomplatform[]>([]);

	const [storeItemsWithLimit] = useDatabaseTable<StoreJson>(
		"store",
		Role.SELF_ORDER_VIEW
	);
	async function getSaleChanel() {
		try {
			const { data: channels } = await AxiosClient().get("/ecomplatforms");
			const items = channels?.items as Ecomplatform[];
			if (items) {
				setChannels(items);
			}
		} catch (error) {}
	}

	useEffect(() => {
		getSaleChanel();
	}, []);

	return (
		<div id="order_filter">
			<PageDataFilterForm
				triggerOnSubmit={["code"]}
				filters={filters}
				setFilters={setFilters}
				total={total}
				defaultFilters={defaultFilters}
				convertValueByKeyFilter={[
					"status",
					"sale_channel",
					"delivery_status",
					"type",
					// "code",
				]}>
				<Form.Item name="code">
					<Input allowClear placeholder={t("order:id")} htmlSize={15} />
				</Form.Item>
				<StoreFormSelect
					placeholder={t("order:store")}
					name="store_id"
					allowClear
					defaultOptions={storeItemsWithLimit.map((i) => ({
						label: i.name,
						value: i.id,
					}))}
					popupMatchSelectWidth={false}
				/>


				<Form.Item name="keyword">
					<Input
						allowClear
						placeholder={t("order:filter_keyword_placeholder")}
					/>
				</Form.Item>

				<FormSelect
					name="sale_channel"
					placeholder={t("order:ecom_platform_type")}
					options={channels.map((item) => ({
						label: item.title,
						value: item.id,
					}))}
					allowClear
					popupMatchSelectWidth={false}
				/>

				{/* FILTER: DATESTART AND DATEEND */}

				<FormSelect
					name="status"
					placeholder={t("order:status")}
					options={OrderModel.getStatusList()}
					allowClear
					popupMatchSelectWidth={false}
				/>
				<FormSelect
					name="delivery_status"
					placeholder={t("order:shipping_status")}
					options={DeliveryModel.getManagerActionStatusList()}
					allowClear
					popupMatchSelectWidth={false}
				/>
				<FormSelect
					name="type"
					placeholder={t("Loại đơn hàng")}
					options={[...OrderModel.getTypeInvoiceList()]}
					allowClear
					popupMatchSelectWidth={false}
				/>
				<Form.Item
					className="lg:w-[400px] gap-1 "
					label={t("common:date_created")}>
					{/* <DateFormFilter
						rangeValue={getDateCreatedFilter}
						allowClear={true}
						showNow={false}
						mode="range"
						format="DD/MM/YYYY"
						onRangeChange={onChangeDateCreatedFilter}
					/> */}

					<DatePickerRangeFilter
						filters={filters}
						keyStart="from_date"
						keyEnd="to_date"
						setFilters={setFilters}
						placeholderRange={[
							t("reportdelivery:placeholder_datefrom"),
							t("reportdelivery:placeholder_dateto"),
						]}
					/>
				</Form.Item>
			</PageDataFilterForm>
		</div>
	);
};

export default OrderListFilter;
