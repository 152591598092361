import { Filter } from "common/types/Filter";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import type { Location } from "react-router-dom";
//extract filter data from URLSearchParams
const parseParams = <F extends object>(
	defaultFilters: F,
	params: { [k: string]: string }
): F => {
	let initFilters: F = {
		...defaultFilters,
		...Object.fromEntries(
			Object.keys(defaultFilters)
				.map((k) => {
					//ensure filter name appear on params list, set it
					const foundParam = typeof params[k] !== "undefined";
					if (foundParam) {
						return [k, params[k]];
					} else {
						return ["__", ""];
					}
				})
				.filter((e) => e[0] !== "__")
		),
	};

	return initFilters;
};

const useStateFilter = <F extends Filter>(
	defaultFilters: F
): [F, Dispatch<SetStateAction<F>>] => {
	const location: Location = useLocation();
	const [filters, setFilters] = useState<F>(
		parseParams(
			defaultFilters,
			Object.fromEntries(new URLSearchParams(location.search).entries())
		)
	);

	//This flag will prevent trigger location changed on pageload, prevent trigget setFilters on ready
	const [allowTriggerChange, setAllowTriggerChange] = useState(false);

	//Ensure this is change "location" by User/Client and not form programatic way (via navigate(...))
	// (This only trigger from URL change by manual or FIRST LOAD --> very BAD)
	useEffect(() => {
		if (
			typeof location.state === "object" &&
			location.state &&
			typeof location.state.ignoreUpdateFilters === "boolean" &&
			location.state.ignoreUpdateFilters
		) {
			//alow trigger now, because one signal from filters changed had been received
			setAllowTriggerChange(true);
		} else if (allowTriggerChange) {
			const changedFilters = parseParams(
				defaultFilters,
				Object.fromEntries(new URLSearchParams(location.search).entries())
			);
			setFilters(changedFilters);
		}
	}, [defaultFilters, allowTriggerChange, location]);

	return [filters, setFilters];
};

export default useStateFilter;
