import { List, Typography } from "antd";
import LogAuditModel from "common/models/LogAuditModel";
import { IdFetcherResult } from "common/types/IdFetcher";
import IdFetcherRender from "components/idfetcher/IdFetcherRender";
import TextDateTimeRelative from "components/TextDateTimeRelative";
import UserIdAvatar from "components/UserIdAvatar";
import PhoneBookModal from "features/phonebook/form/PhoneBookModal";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const LogAuditItem = ({
	model,
	employees,
}: {
	model: LogAuditModel;
	employees: IdFetcherResult[];
}) => {
	const { t } = useTranslation();

	const { text, link } = model.getObjectTextAndLink();
	const [open, setOpen] = useState(false);

	return (
		<Fragment>
			<List.Item.Meta
				avatar={<UserIdAvatar id={model.creator_id} />}
				title={
					<span>
						<strong>
							<Typography.Link
								className="cursor-pointer"
								onClick={() => {
									setOpen(true);
								}}>
								<IdFetcherRender
									value={model.creator_id}
									mapping={employees}
									key_value="text"
								/>
								{" - "}
								<Typography.Text className="inline" copyable>
									<IdFetcherRender
										value={model.creator_id}
										mapping={employees}
										key_value="internal_id"
									/>
								</Typography.Text>
							</Typography.Link>
						</strong>
						<br />
						<span style={{ fontWeight: "normal" }}>
							{t("logaudit:text." + model.controller + "." + model.action)}{" "}
							{text.length > 0 ? (
								<>
									{link.length > 0 ? (
										<Link to={link} className="text-blue-500">
											{text}
										</Link>
									) : (
										text
									)}
								</>
							) : null}
						</span>
					</span>
				}
				description={<TextDateTimeRelative ts={model.date_created} />}
			/>
			<PhoneBookModal
				id={model.creator_id}
				visible={open}
				setVisible={(isVisible) => {
					setOpen(isVisible);
				}}
			/>
		</Fragment>
	);
};

export default LogAuditItem;
