import SettingApi from "common/constants/Setting";
import SettingModel from "common/models/SettingModel";
import SettingRepository from "common/repositories/SettingRepository";
import { useCallback, useEffect, useState } from "react";

const useSettingValue = (key: string, value?: string) => {
    const [setting, setSetting] = useState<SettingModel>(new SettingModel({...SettingModel.getDefaultData()}));
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const getSetting = useCallback(async () => {
        let setting = await new SettingRepository().getItem(key);

        if (setting.hasError() && !value) {
            throw setting.error;
        }

        if (setting.hasError() && value) {
            await new SettingRepository().saveRemote({
                id: 0,
                key: key,
                value: value,
                group: SettingApi.GROUP.ALL,
                lang: "*",
            });

            setting = await getSetting();
        }

        return setting;
    }, [key, value]);

    const fetchSetting = useCallback(async () => {
        try {
            const setting = await getSetting();
            setSetting(setting);
        } catch (error: any) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }, [getSetting]);

    useEffect(() => {
        fetchSetting();
    }, [fetchSetting]);

    return { setting, isLoading, error };
}

export default useSettingValue;
