import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
	Button,
	Dropdown,
	Empty,
	MenuProps,
	message,
	TableColumnsType,
	Tag,
	Typography,
} from "antd";
import { useTranslation } from "react-i18next";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import useStateFilter from "hooks/useStateFilter";
import Role from "common/constants/Role";

import TableInfo from "components/table/TableInfo";
import useFilterLocation from "hooks/useFilterLocation";

//////////////////////////////////
//Phần thay thế
import { FilterTemplate } from "common/types/Template";
import { TemplateJson } from "common/types/Template";

import SystemEventListFilter from "./SystemEventListFilter";
import SystemEventModel from "common/models/SystemEventModel";
import SystemEventCollection from "common/collections/SystemEventCollection";
import SystemEventRepository from "common/repositories/SystemEventRepository";
import SystemEventListHeader from "./SystemEventListHeader";
import PageDataPagination from "components/page/PageDataPagination";
import TextDateTime from "components/TextDateTime";

import ReactJsonView from "react-json-view";
import {
	SystemEventFilter,
	SystemEventJson,
	SystemEventSourceType,
} from "common/types/SystemEvent";
import SystemEvent from "common/constants/SystemEvent";
import { MoreOutlined } from "@ant-design/icons";

const SystemEventList = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	////////////////////////
	// modal editing
	const [isLoadingUpdate, setLoadingUpdate] = useState(false);
	const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

	////////////////////////
	// default filter
	const defaultFilters = useMemo(() => {
		return SystemEventRepository.getDefaultFilter();
	}, []);

	///////////////////////
	//state
	const [total, setTotal] = useState<number>(0);
	const [dataSource, setDataSource] = useState<SystemEventModel[]>([]);
	const [filters, setFilters] = useStateFilter(defaultFilters);
	useFilterLocation(defaultFilters, filters);

	const onUpdateStatus = async (record: SystemEventJson, status: number) => {
		setLoadingUpdate(true);
		try {
			const response = await new SystemEventRepository().saveRemote({
				id: record.id,
				status,
			});
			if (!response.hasError()) {
				await fetchData();
			}
		} catch (error: any) {
			message.error(error);
		} finally {
			setLoadingUpdate(false);
		}
	};

	//Table columns
	const columns: TableColumnsType<SystemEventModel> = [
		{
			title: t("common:columns.id"),
			key: "id",
			width: 100,
		},
		{
			title: t("system:systemevent.resource_id"),
			key: "resource_id",

			render(resource_id, record, index) {
				return (
					<Tag>
						<Typography.Text copyable ellipsis title={resource_id}>
							{resource_id}
						</Typography.Text>
					</Tag>
				);
			},
			width: 200,
		},
		{
			title: t("system:systemevent.action"),
			key: "action",
			width: 140,
			render: (action: string) => {
				const actionVal = SystemEventModel.getActionLabel(action);

				return <Tag color={actionVal?.color}>{actionVal?.label ?? action}</Tag>;
			},
		},
		{
			title: t("system:systemevent.date_created"),
			key: "date_created",
			width: 100,
			render: (date_created: number) => {
				return (
					<TextDateTime
						format="HH:mm DD/MM/YYYY"
						ts={date_created}></TextDateTime>
				);
			},
		},
		{
			title: t("system:systemevent.event"),
			key: "event",
			width: 100,
			render: (event: string) => {
				const label = SystemEventModel.getEventLabel(event);
				return <Tag color={label?.color}>{label?.label}</Tag>;
			},
		},
		{
			title: t("system:systemevent.resource_type"),
			key: "resource_type",
			width: 100,
			render: (resource_type: SystemEventSourceType) => {
				const label = SystemEventModel.getResouceType(resource_type);

				return (
					<Tag color={label?.color ?? "default"}>
						{label?.label ?? resource_type}
					</Tag>
				);
			},
		},
		{
			title: t("system:systemevent.status"),
			key: "status",
			width: 100,
			render: (status: number) => {
				let statusInfo = SystemEventModel.getStatus(status);
				return (
					<Tag bordered color={statusInfo?.color}>
						{statusInfo?.label}
					</Tag>
				);
			},
		},
		{
			title: t(""),
			key: "actions",
			width: 100,
			align: "right",
			fixed: "right",
			render: (_: any, record: SystemEventJson) => {
				const items: MenuProps["items"] = [
					{
						key: "1",
						label: (
							<Button
								type="text"
								title="Đã xử lý"
								onClick={() =>
									onUpdateStatus(
										record,
										record.status === SystemEvent.STATUS_NEW
											? SystemEvent.STATUS_PROCESSED
											: SystemEvent.STATUS_NEW
									)
								}>
								{record.status === SystemEvent.STATUS_NEW
									? "Đã xử lý"
									: "Chưa xử lý"}
							</Button>
						),
					},
				];

				return (
					<div onClick={(e) => e.stopPropagation()}>
						<RoleCheck roles={[Role.SETTING_HRM]} hideOnFail>
							<Dropdown trigger={["click"]} menu={{ items }}>
								<MoreOutlined
									style={{ fontSize: "16pt" }}
									className="cursor-pointer"
								/>
							</Dropdown>
							<TableInfo record={record} />
						</RoleCheck>
					</div>
				);
			},
		},
	];

	const mappingEventDetails = (details: any) => {
		let detailsParsed = details;

		if (typeof details === "string") {
			detailsParsed = JSON.parse(details);
		}

		return detailsParsed;
	};

	const expandable = useMemo(
		() => ({
			expandedRowRender: (record: SystemEventModel) => {
				const render = () => {
					try {
						return (
							<ReactJsonView
								src={mappingEventDetails(record.details)}
								collapsed={3}
								sortKeys
								style={{ maxHeight: "300px", overflowY: "auto" }}
							/>
						);
					} catch (error) {
						return <Empty>Lỗi parse json</Empty>;
					}
				};
				return <>{render()}</>;
			},
			expandedRowClassName: () => "ant-table-expanded-row-dimmer",
			expandRowByClick: true,
			showExpandColumn: false,
			expandedRowKeys: expandedRowKeys,
			onExpand: (expanded: boolean, record: SystemEventModel) => {
				if (expanded) {
					setExpandedRowKeys([record.id]);
				} else {
					setExpandedRowKeys([]);
				}
			},
		}),
		[expandedRowKeys]
	);

	///////////////////////
	//function
	const fetchData = useCallback(async (): Promise<SystemEventCollection> => {
		const response = await new SystemEventRepository().getItems({
			filters: filters,
		});
		setTotal(response.total);
		setDataSource(response.items);
		return response;
	}, [filters]);

	return (
		<>
			<SystemEventListHeader />
			<SystemEventListFilter
				total={total}
				filters={filters}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
			/>
			<PageDataTable<SystemEventFilter, SystemEventModel, SystemEventCollection>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					isLoading: isLoadingUpdate,
					expandable,
					tableColumnToggleKey: "template",
				}}
			/>
			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>
		</>
	);
};

export default SystemEventList;
