import { BaseCollectionJson } from "./BaseCollectionJson";
import { FileJson } from "./File";
import { Filter } from "./Filter";
import { ProductCollectionJson as ProductCollectionCateJson } from "./ProductCollection";
import { TYPE_WAREHOUSE } from "./Warehouse";
type ProductJson = {
	status: number;
	id: number;
	company_id: number;
	creator_id: number;
	name: string;
	category_id: number;
	code: string;
	supplier_id: number[];
	unit: number;
	sell_on_zero: number;
	date_created: number;
	date_modified: number;
	image_id: number;
	image: FileJson;
};

type ProductJson2 = {
	allow_sale: ProductAllowSale;
	status: ProductStatus;
	id: number;
	name: string;
	full_name: string;
	sku: string;
	barcode: string;
	handle: string;
	quantity: number;
	brand: ProductBrandJson | null;
	price: number;
	compare_at_price: number;
	price_min: number;
	price_max: number;
	vat: number;
	parent_handle: string;
	compare_at_price_min: number;
	compare_at_price_max: number;
	type_id: number;
	images: Images[];
	tags: ProductTagJson[];
	created_at: number;
	updated_at: number;
	parent_id: number;
	count_childs: number;
	summary: string;
	description: string;
	detail: string;
	store_id: number;
	meta_keyword: string;
	meta_title: string;
	meta_description: string;
	allow_promotion: number;
	min_buy: number;
	supplier_id: number;
	supplier: any;
	//key client custom for productJson to process promotion
	// promotions: PromotionJson[];
	option_name: string;

	kiotviet_image: string;
	kiotviet_id: number;
	production_year: number;
	origin: string;

	list_childs: number[];

	//price discount / price compare
	compare_discount: number;
	compare_discount_percent: number;

	limit_sale: number;
	display_from: number;
	display_to: number;
	collections: ProductColletionJson[];
	variant_id: number;
	quantities: Quantitie[];
	show_web: number;
	show_pos: number;
	warehouse_quantities: WarehouseQuantity[];
};
export enum ProductAllowSale {
	NOT_ALLOW_SALE,
	ALLOW_SALE,
}
export type ProductBrandJson = {
	id: number;
	title: string;
	handle: string;
};
export type Images = {
	id: number;
	url: string;
};

export type ProductTagJson = {
	name: string;
	type: string;
	code: string;
	description: string;
	value: string;
	id: number;
	display_order: number;
};
type ProductJsonPublic = {
	allow_sale: ProductAllowSale;
	status: number;
	id: number;
	name: string;
	full_name: string;
	sku: string;
	barcode: string;
	handle: string;
	quantity: number;
	brand: ProductBrandJson | null;
	price: number;
	compare_at_price: number;
	price_min: number;
	price_max: number;
	vat: number;
	parent_handle: string;
	compare_at_price_min: number;
	compare_at_price_max: number;
	type_id: number;
	images: Images[];
	tags: ProductTagJson[];
	created_at: number;
	updated_at: number;
	parent_id: number;
	count_childs: number;
	summary: string;
	description: string;
	detail: string;
	store_id: number;
	meta_keyword: string;
	meta_title: string;
	meta_description: string;
	allow_promotion: number;
	min_buy: number;
	supplier_id: number;
	supplier: any;
	//key client custom for productJson to process promotion
	// promotions: PromotionJson[];
	option_name: string;

	kiotviet_image: string;
	kiotviet_id: number;
	production_year: number;
	origin: string;

	list_childs: number[];

	//price discount / price compare
	compare_discount: number;
	compare_discount_percent: number;

	limit_sale: number;
	display_from: number;
	display_to: number;
	collections: ProductColletionJson[];
	variant_id: number;
	quantities: Quantitie[];
	show_web: number;
	show_pos: number;
	warehouse_quantities: WarehouseQuantity[];
};

export type WarehouseQuantity = {
	quantity: number;
	name: string;
	type: TYPE_WAREHOUSE;
	warehouse_id: number;
};

interface Quantitie {
	store_id: number;
	quantity: number;
	actual_quantity: number;
	reserved: number;
	in_coming: number;
}
export type ProductColletionJson = {
	id: number;
	title: string;
	handle: string;
};
type ProductRateJson = {
	rate: number;
	count_rate: number;
	count_rate_1: number;
	count_rate_2: number;
	count_rate_3: number;
	count_rate_4: number;
	count_rate_5: number;
};

type TagJson = {
	code: string;
	display_order: number;
	name: string;
	type: string;
	value: string;
};

type BrandJson = {
	id: number;
	title: string;
	handle: string;
};

type OptionJson = {
	key: string;
	value: string;
};

type ProductJsonAddEdit = ProductJson & {};

export enum ProductStatus {
	UNACTIVE,
	ACTIVE,
}
export enum ProductShowWeb {
	UNACTIVE,
	ACTIVE,
}

type ProductJsonAddEdit2 = Partial<Omit<ProductJson2, "images">> & {
	id: number;
	images?: string;
	brand_id?: number;
};

export type ProductJsonEdit2 = Omit<ProductJsonAddEdit2, "id">;

type ProductCollectionJson = BaseCollectionJson<ProductJson>;
type ProductCollectionJson2 = BaseCollectionJson<ProductJson2>;
type ProductPublicCollectionJson = BaseCollectionJson<ProductJsonPublic>;

type FilterProduct = Filter & {
	id: number;
	category_id: number;
	supplier_id: number;
	list_product_id: string;
	store_id: number;
	warehouse_id: number;
	collection_id: number;
	tags?: string;
	parent_id?: number;
	// tags_code?: string;
	skus?: string;
	show?: string;
	min_quantity?: number;
	max_quantity?: number;
	ids?: string;
};

type sortBy = "" | "";

type ProductRelatedFilter = {
	id: number;
	store_id: number;
	tags: string;
};

type ProductStructJson = {
	code: string;
	name: string;
	type: string;
};

type Sample = {
	store_id: number | string;
	onHand: boolean;
	name: string;
	code: string;
};

export type {
	ProductJson,
	ProductJson2,
	ProductJsonPublic,
	ProductJsonAddEdit,
	ProductJsonAddEdit2,
	ProductCollectionJson,
	ProductCollectionJson2,
	FilterProduct,
	TagJson,
	BrandJson,
	OptionJson,
	ProductRateJson,
	ProductRelatedFilter,
	ProductStructJson,
	ProductPublicCollectionJson,
	Quantitie,
	Sample,
};
