import { IconHistory } from "@tabler/icons-react";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

const ActionWrapperHeader = (): JSX.Element => {
  const { t } = useTranslation();
  const { account } = useLoginAccountStore();

  return (
    <PageHeader
      heading={t("action:header_add")}
      siteMenuSelectedKey="/actions/add"
      siteMenuHidden
    >
      <div className="grid grid-cols-1">
        <PageHeaderButton
          type="primary"
          ghost
          link={`/actions?employee_id=${account.company.employee.id}`}
          icon={<IconHistory size={18} className="mr-1 -mt-0.5" />}
        >
          {t("action:header_nav")}
        </PageHeaderButton>
      </div>
    </PageHeader>
  );
};

export default ActionWrapperHeader;
