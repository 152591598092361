import Order from "common/constants/Order";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const OrderListHeader = ({ type }: { type: number }) => {
	const { t } = useTranslation();

	const [params] = useSearchParams();
	const hasFilterInvoice = type === Order.TYPE_INVOICE;

	return (
		<PageHeader
			heading={
				!!hasFilterInvoice
					? t("order:invoice_heading_list")
					: t("order:heading_list")
			}
			nav={[]}
			siteMenuOpenKey="sale"
			siteMenuSelectedKey={hasFilterInvoice ? "/invoice" : "/order"}>
			<PageHeaderButton type="primary" link="/pos" target="_blank">
				{t("order:pos_button")}
			</PageHeaderButton>
		</PageHeader>
	);
};

export default OrderListHeader;
