import {
	Button,
	Col,
	Form,
	FormInstance,
	Input,
	Modal,
	notification,
	Row,
	Tooltip,
} from "antd";
import Order from "common/constants/Order";
import RegionPicker from "components/RegionPicker";
import SimpleCollapse from "components/SimpleCollapse";
import TextRegion from "components/TextRegion";
import { useTranslation } from "react-i18next";
import useEditingSaleOrderStore from "zustands/useEditingSaleOrder";

import {
	IconMapPin,
	IconMapPinOff,
	IconTruck,
	IconUser,
} from "@tabler/icons-react";
import LocationPicker from "components/LocationPicker";
import RoleCheck from "components/RoleCheck";
import Role from "common/constants/Role";
import Delivery from "common/constants/Delivery";
import { useState } from "react";
import DeliveryModel from "common/models/DeliveryModel";
import DeliveryOrderForm from "components/order/DeliveryOrderForm";

const OrderFormSectionShipping = ({
	form,
	allowEditInput,
	refetchOrder,
}: {
	form: FormInstance;
	allowEditInput: boolean;
	refetchOrder?: () => void;
}) => {
	const { t } = useTranslation();
	const order = useEditingSaleOrderStore((state) => state.order);
	const [openDeli, setOpenDeli] = useState(false);

	const { shipping_regions } = form.getFieldsValue();

	function onConfirmDelySuccess(deli?: DeliveryModel) {
		notification.success({ message: "Xác nhận giao hàng thành công" });
		setOpenDeli(false);
		refetchOrder?.();
	}

	const PanelHeaderSummary = (
		<div className="mt-4">
			<Row>
				<Col span={8}>
					{form.getFieldValue("shipping_full_name").length > 0 ? (
						<div>
							<Tooltip
								title={t("order:shipping_full_name")}
								placement="left"
								mouseEnterDelay={0.4}>
								<IconUser size={16} className="mr-1 -mt-0.5 text-blue-800" />
							</Tooltip>
							{form.getFieldValue("shipping_full_name")} (
							{form.getFieldValue("shipping_phone")})
						</div>
					) : null}
				</Col>

				<Col span={16}>
					{form.getFieldValue("shipping_address").length > 0 ? (
						<>
							<Tooltip
								title={t("order:shipping_address")}
								mouseEnterDelay={0.4}
								placement="left">
								<IconMapPin size={16} className="mr-1 -mt-0.5 text-blue-800" />
							</Tooltip>
							{form.getFieldValue("shipping_address")}
							{/* 
							<TextRegion
								id={form.getFieldValue("shipping_regions")?.sub_sub_region_id}
								tagColor=""
							/>
							<TextRegion
								id={form.getFieldValue("shipping_regions")?.sub_region_id}
								tagColor="gray"
							/>
							<TextRegion
								id={form.getFieldValue("shipping_regions")?.region_id}
								tagColor="black"
							/> */}
						</>
					) : (
						<>
							<IconMapPinOff size={16} className="mr-1 -mt-0.5 text-blue-800" />
							<span className="text-gray-500">
								{t("order:shipping_address_empty")}
							</span>
						</>
					)}
				</Col>
			</Row>
		</div>
	);

	return (
		<>
			<SimpleCollapse
				header={
					<div className=" flex items-center justify-between">
						<div>{t("order:form.section_shipping")}</div>
						{order.status !== Order.STATUS_CANCEL ? (
							<RoleCheck roles={[Role.ORDER_MANAGER_DELIVERY]}>
								<Button
									type="primary"
									icon={<IconTruck />}
									onClick={() => setOpenDeli(true)}>
									Giao hàng
								</Button>
							</RoleCheck>
						) : null}
					</div>
				}
				headerSummary={PanelHeaderSummary}
				defaultOpen={false}
				className={
					"mb-4 " +
					(order.ecom_platform_type === Order.ECOM_PLATFORM_TYPE_POS &&
					order.shipping_carrier === 0
						? " hidden"
						: "")
				}
				childrenItem={
					<Row gutter={32} className="mb-2">
						<Col md={8} xs={24}>
							<Form.Item
								label={t("order:shipping_full_name")}
								name="shipping_full_name">
								<Input disabled={!allowEditInput} />
							</Form.Item>

							<Form.Item
								label={t("common:phone")}
								name="shipping_phone"
								className="mb-0">
								<Input disabled={!allowEditInput} />
							</Form.Item>
						</Col>
						<Col md={16} xs={24} className="border-l-2 border-gray-200">
							<Form.Item
								label={t("order:shipping_address")}
								name="shipping_address">
								<Input disabled={!allowEditInput} />
							</Form.Item>
							<Form.Item name="shipping_regions" noStyle>
								<LocationPicker disabled={!allowEditInput} className="mb-0" />
							</Form.Item>
						</Col>
					</Row>
				}
			/>
			<Modal
				open={openDeli}
				onCancel={() => {
					setOpenDeli(false);
				}}
				title={"Giao hàng"}
				okText="Xác nhận"
				cancelText="Đóng"
				destroyOnClose
				centered
				width={1280}
				height={"80vh"}
				footer={null}
				className="overflow-auto">
				<DeliveryOrderForm
					hidePostAction={order.type === Order.TYPE_INVOICE_ORDER}
					onConfirmSuccess={onConfirmDelySuccess}
					id={
						order.delivery_lits_id.length
							? order.delivery_lits_id.split(",").map(Number)[0]
							: 0
					}
				/>
			</Modal>
		</>
	);
};

export default OrderFormSectionShipping;
