interface ITextString {
  text: string;
  defaultText?: string;
}

const TextString = ({ text, defaultText }: ITextString): string => {
  if (!text) {
    return defaultText ?? "--";
  }

  if (!text.length) {
    return defaultText ?? "--";
  }

  return text;
};

export default TextString;
