import { Alert } from "antd";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import ActionWrapperHeader from "./ActionWrapperHeader";
import ActionAddForm from "./form/ActionAddForm";

const ActionWrapper = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <ActionWrapperHeader />
      {false ? (
        <Alert
          message={t("worktracking:form.error.warning_checkin_type_ignore")}
          type="warning"
        />
      ) : (
        <div className="p-8">
          <ActionAddForm />
        </div>
      )}
    </Fragment>
  );
};

export default ActionWrapper;
