import {
	Alert,
	App,
	Button,
	Col,
	Empty,
	FormInstance,
	Input,
	Popover,
	Row,
	Table,
	Tag,
	Typography,
} from "antd";
import Role from "common/constants/Role";
import { TableColumnsType } from "common/types/Table";
import ErrorAlert from "components/ErrorAlert";
import RoleCheck from "components/RoleCheck";
import TableLinkText from "components/table/TableLinkText";
import TextMoney from "components/TextMoney";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconRefresh } from "@tabler/icons-react";
import Order from "common/constants/Order";
import TaxInvoiceModel from "common/models/TaxInvoiceModel";
import OrderHelperRepository from "common/repositories/OrderHelperRepository";
import TaxInvoiceRepository from "common/repositories/TaxInvoiceRepository";
import TaxInvoiceItemEdit from "features/taxinvoice/item/TaxInvoiceItemEdit";
import useEditingSaleOrderStore from "zustands/useEditingSaleOrder";
import { NoteInvoiceJson } from "common/types/Order";
import { OrderJson } from "common/types/Order";

const OrderFormSectionNoteTaxInvoice = ({
	order,
	form,
  }: {
	order: OrderJson;
	form?: FormInstance;
  }) => {
	const { t } = useTranslation();
	const { message } = App.useApp();

	// const order = useEditingSaleOrderStore((state) => state.order);

	const [dataSource, setDataSource] = useState<OrderJson["note_invoice"][]>([
		order.note_invoice,
	]);

	const [addNote, setAddNote] = useState("");

	const [loading, setLoading] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [errors, setErrors] = useState<string[]>([]);

	const isDataNoteInvoice: boolean = useMemo(() => {
		return !!order?.note_invoice?.tax_code;
	}, [order, dataSource]);

	//////////////////////////////////////////
	//Table columns
	// const columns: TableColumnsType<OrderJson["note_invoice"]> = [
	// 	// {
	// 	// 	title: t("common:sid"),
	// 	// 	dataIndex: "id",
	// 	// 	render: (id, record) => {
	// 	// 		return (
	// 	// 			<TaxInvoiceItemEdit model={record} notPermissionFallback={record.id}>
	// 	// 				<TableLinkText link={"/taxinvoice/edit/id/" + record.id}>
	// 	// 					{record.id}
	// 	// 				</TableLinkText>
	// 	// 			</TaxInvoiceItemEdit>
	// 	// 		);
	// 	// 	},
	// 	// },

	// 	{
	// 		title: t("taxinvoice:group_buyer"),
	// 		dataIndex: "buyer_tax_number",
	// 		render: (buyer_tax_number, record) => {
	// 			return (
	// 				<div>
	// 					{record.buyer_name}
	// 					<br />
	// 					<span className="text-xs text-gray-500">
	// 						{t("taxinvoice:tax_number_short")}: {buyer_tax_number}
	// 					</span>
	// 				</div>
	// 			);
	// 		},
	// 	},
	// 	{
	// 		title: t("taxinvoice:price_sell"),
	// 		dataIndex: "price_sell",
	// 		align: "right",
	// 		render: (price_sell: number) => {
	// 			return <TextMoney money={price_sell} />;
	// 		},
	// 	},
	// 	{
	// 		title: t("taxinvoice:price_tax"),
	// 		dataIndex: "price_tax",
	// 		align: "right",
	// 		render: (price_tax: number) => {
	// 			return <TextMoney money={price_tax} />;
	// 		},
	// 	},
	// 	{
	// 		title: t("common:status"),
	// 		dataIndex: "status",
	// 		render: (status: number) => {
	// 			let statusInfo = TaxInvoiceModel.getStatus(status);
	// 			return <Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>;
	// 		},
	// 		width: 100,
	// 	},
	// ];

	const columnsTax: TableColumnsType<OrderJson["note_invoice"]> =
		useMemo(() => {
			let result: TableColumnsType<OrderJson["note_invoice"]> = [];
			if (isDataNoteInvoice) {
				result = [
					{
						title: t("Mã thuế"),
						dataIndex: "tax_code",
						render: (_, record, index) => {
							return (
								<div>
									<Typography.Text copyable>{record.tax_code}</Typography.Text>
								</div>
							);
						},
					},
					{
						title: t("Tên khách hàng"),
						dataIndex: "bill_recipient_name",
						render: (_, record, index) => {
							return (
								<div>
									<Typography.Text>
										{record.bill_recipient_name}
									</Typography.Text>
								</div>
							);
						},
					},

					{
						title: t("Email nhận hóa đơn"),
						dataIndex: "invoice_receipt_email",
						render: (_, record, index) => {
							return (
								<div>
									<Typography.Text>
										{record.invoice_receipt_email}
									</Typography.Text>
								</div>
							);
						},
					},
					{
						title: t("Tên công ty"),
						dataIndex: "",
						render: (_, record, index) => {
							return (
								<div>
									<Typography.Text>{record.company_name}</Typography.Text>
								</div>
							);
						},
					},
					{
						title: t("Địa chỉ công ty"),
						dataIndex: "company_address",

						render: (_, record, index) => {
							return (
								<div>
									<Typography.Text>{record.company_address}</Typography.Text>
								</div>
							);
						},
					},

					{
						title: t("Tiền thuế"),
						dataIndex: "price_tax",

						render: (_, record, index) => {
							return (
								<div>
									<TextMoney
										money={Math.floor(
											(order.price_tax / 100) * order.price_sell
										)}></TextMoney>
								</div>
							);
						},
					},
				];
			}

			return result;
		}, [isDataNoteInvoice, dataSource]);

	const moreButtons = (
		<div className="my-2">
			<Button
				icon={<IconRefresh size={16} className="mr-1" />}
				className="opacity-40 hover:opacity-80"
				size="small"
				type="text">
				{t("order:form.refresh_receipt")}
			</Button>
		</div>
	);

	return (
		<div className="mt-8">
			<Row className="p-2 bg-gray-100">
				<Col
					span={3}
					className="px-2 text-xl font-extrabold text-right text-gray-600 uppercase bg-gray-100">
					{t("order:form.section_taxprefix")}
					<br />
					{t("order:form.section_tax")}
				</Col>
				<Col span={21}>
					{errors.length > 0 ? (
						<div className="p-2">
							<ErrorAlert items={errors} translate_prefix="order:form.error" />
						</div>
					) : null}

					{/* 
					{!isDataNoteInvoice ? (
						<div className="p-4 m-2 bg-white rounded-md">
							<Empty
								imageStyle={{ height: 40 }}
								description={
									<div className="">{t("order:form.tax_invoice_empty")}</div>
								}>
						
							</Empty>
						</div>
					) : (
						<Table
							expandable={{
								showExpandColumn: true,
							}}
							className="w-full border"
							rowKey={"id"}
							pagination={{
								pageSize: 100,
								hideOnSinglePage: true,
							}}
							columns={columnsTax}
							dataSource={dataSource}
							loading={loading}
						/>
					)} */}
					{!isDataNoteInvoice ? (
						<div className="italic">{t("order:note_tax_empty")}</div>
					) : null}
					<div>
						<Table
							expandable={{
								showExpandColumn: true,
							}}
							className="w-full border"
							rowKey={"id"}
							pagination={{
								pageSize: 100,
								hideOnSinglePage: true,
							}}
							columns={columnsTax}
							dataSource={!isDataNoteInvoice ? [] : dataSource}
							loading={loading}
						/>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default OrderFormSectionNoteTaxInvoice;
