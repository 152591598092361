import Role from "common/constants/Role";
import { AccountJson } from "common/types/Account";
import { SiteMenu, SiteMenuSubItem } from "common/types/SiteMenu";
import i18n from "i18n";
import { v4 as uuid } from "uuid";

import {
	CommentOutlined,
	FileSyncOutlined,
	FundOutlined,
	RadarChartOutlined,
} from "@ant-design/icons";
import {
	IconAddressBook,
	IconArrowBigRightLine,
	IconBallpen,
	IconBell,
	IconBike,
	IconBook,
	IconBox,
	IconBuildingSkyscraper,
	IconBuildingStore,
	IconCalculator,
	IconCalendar,
	IconCategory,
	IconCheckbox,
	IconClipboardCheck,
	IconClock24,
	IconClockHour9,
	IconDeviceDesktop,
	IconDiscount2,
	IconFlag3,
	IconFolder,
	IconFrame,
	IconHash,
	IconHome2,
	IconId,
	IconLayoutKanban,
	IconListDetails,
	IconLock,
	IconMail,
	IconMailbox,
	IconMailFast,
	IconMessage,
	IconNews,
	IconPackage,
	IconPackageExport,
	IconPlus,
	IconQrcode,
	IconReceipt,
	IconReceipt2,
	IconReceiptRefund,
	IconReceiptTax,
	IconRoute,
	IconSettings,
	IconSettings2,
	IconShoppingBag,
	IconShoppingCart,
	IconShoppingCartBolt,
	IconSitemap,
	IconSubtask,
	IconTable,
	IconTag,
	IconTransferIn,
	IconTruck,
	IconTruckDelivery,
	IconUser,
	IconUserCircle,
	IconUsersGroup,
	IconWifi,
	IconWorldWww,
} from "@tabler/icons-react";
import InventoryReceipt from "common/constants/InventoryReceipt";
import { SubMenuType } from "antd/es/menu/interface";

class SiteMenuRepository {
	static getDefaultMenu() {
		const iconProps = { size: 18 };

		let menu: SiteMenu = [
			{
				key: "sale",
				label: i18n.t("sitemenu:group_sale"),
				type: "group",
				children: [
					{
						label: i18n.t("sitemenu:order_index"),
						icon: <IconShoppingCart {...iconProps} />,
						key: "/order",
						roles: [
							Role.SELF_ORDER_VIEW,
							Role.CROSS_ORDER_VIEW,
							Role.ORDER_VIEW,
						],
					},
					// {
					// 	label: i18n.t("sitemenu:invoice_index"),
					// 	icon: <FileSyncOutlined {...iconProps} />,
					// 	key: "/order?type=1",
					// 	roles: [
					// 		Role.SELF_ORDER_VIEW,
					// 		Role.CROSS_ORDER_VIEW,
					// 		Role.ORDER_VIEW,
					// 	],
					// },

					{
						label: i18n.t("sitemenu:invoice_index"),
						icon: <FileSyncOutlined {...iconProps} />,
						key: "/invoice",
						roles: [
							Role.SELF_ORDER_VIEW,
							Role.CROSS_ORDER_VIEW,
							Role.ORDER_VIEW,
						],
					},
					// {
					// 	label: i18n.t("sitemenu:orderseller"),
					// 	icon: <IconShoppingCart {...iconProps} />,
					// 	key: "/orderseller",
					// 	roles: [Role.SELF_ORDER_VIEW , Role.CROSS_ORDER_VIEW],
					// },
					{
						label: i18n.t("sitemenu:customer_index"),
						icon: <IconUserCircle {...iconProps} />,
						key: "/customer",
						roles: [Role.CUSTOMER_VIEW],
					},
					{
						label: i18n.t("sitemenu:cashflow_index"),
						icon: <IconReceipt2 {...iconProps} />,
						key: "/cashflowreceipt",
						roles: [Role.CASHFLOW_VIEW],
					},
					{
						label: i18n.t("sitemenu:tax_invoice_index"),
						icon: <IconReceiptTax {...iconProps} />,
						key: "/taxinvoice",
						roles: [Role.CASHFLOW_VIEW],
					},
					{
						label: i18n.t("sitemenu:promotion"),
						icon: <IconDiscount2 {...iconProps} />,
						key: "/promotion",
						roles: [Role.PROMOTION_VIEW],
					},
					{
						label: i18n.t("sitemenu:contactmail"),
						icon: <IconMail {...iconProps} />,
						key: "/contactmail",
						roles: [Role.ORDER_VIEW],
					},
					{
						key: "divider_" + uuid(),
						label: "",
						type: "divider",
						roles: [Role.SETTING_SALE, Role.PROMOTION_VIEW],
					},
					{
						label: i18n.t("setting:menu.store"),
						icon: <IconBuildingStore {...iconProps} />,
						key: "/store",
						roles: [Role.SETTING_SALE],
					},
					{
						label: i18n.t("setting:menu.shipping_carrier"),
						icon: <IconTruck {...iconProps} />,
						key: "/shippingcarrier",
						roles: [Role.SETTING_SALE],
					},
					{
						label: i18n.t("setting:menu.customer_type"),
						icon: <IconUsersGroup {...iconProps} />,
						key: "/customertype",
						roles: [Role.SETTING_SALE],
					},
					{
						key: "divider_" + uuid(),
						label: "",
						type: "divider",
						roles: [Role.PRODUCT_MANAGE, Role.SETTING_SALE],
					},
					{
						label: i18n.t("sitemenu:setting_product"),
						icon: <IconBox {...iconProps} />,
						key: "product",
						roles: [Role.PRODUCT_MANAGE],
						children: [
							{
								label: i18n.t("setting:menu.product"),
								key: "/product",
							},
							{
								label: i18n.t("setting:menu.productwarehouse"),
								key: "/productwarehouse",
							},
							// {
							//   label: i18n.t("setting:menu.product_category"),
							//   key: "/productcategory",
							// },
							{
								label: i18n.t("setting:menu.product_category"),
								key: "/productcollection",
							},
							{
								label: i18n.t("setting:menu.brand"),
								key: "/brand",
							},
							{
								label: i18n.t("setting:menu.product_supplier"),
								key: "/supplier",
							},
							// {
							// 	label: i18n.t("setting:menu.product_variant_color"),
							// 	key: "/productvariantcolor",
							// },
							// {
							// 	label: i18n.t("setting:menu.product_variant_size"),
							// 	key: "/productvariantsize",
							// },
						],
					},
					{
						key: "divider_" + uuid(),
						label: "",
						type: "divider",
						roles: [Role.PRODUCT_MANAGE, Role.SETTING_SALE],
					},
					{
						label: i18n.t("setting:menu.feedback"),
						icon: <IconMessage {...iconProps} />,
						key: "/feedback",
						roles: [Role.SETTING_SALE],
					},
					{
						label: i18n.t("setting:menu.contact"),
						icon: <IconMail {...iconProps} />,
						key: "/contact",
						roles: [Role.SETTING_SALE],
					},
					{
						label: i18n.t("setting:menu.newsletter"),
						icon: <IconMailFast {...iconProps} />,
						key: "/newsletter",
						roles: [Role.SETTING_SALE],
					},
					{
						key: "divider_" + uuid(),
						label: "",
						type: "divider",
						roles: [Role.PRODUCT_MANAGE, Role.SETTING_SALE],
					},
					{
						label: i18n.t("sitemenu:order_accountant"),
						icon: <FileSyncOutlined {...iconProps} />,
						key: "/invoice_accountant",
						roles: [
							Role.SELF_ORDER_VIEW,
							Role.CROSS_ORDER_VIEW,
							Role.ORDER_VIEW,
						],
					},
				],
			},
			{
				key: "inventory",
				label: i18n.t("sitemenu:group_inventory"),
				children: [
					// {
					// 	label: i18n.t("sitemenu:inventory"),
					// 	icon: <IconHash {...iconProps} />,
					// 	key: "/inventory",
					// 	roles: [Role.INVENTORY_NUMBER, Role.PRODUCT_MANAGE],
					// },

					{
						label: i18n.t("sitemenu:inventory_product"),
						icon: <IconHash {...iconProps} />,
						key: "/inventory/product",
						roles: [Role.INVENTORY_NUMBER],
					},
					// {
					// 	label: i18n.t("sitemenu:po"),
					// 	icon: <IconTransferIn {...iconProps} />,
					// 	key: "/purchaseorder",
					// 	roles: [Role.PURCHASE_ORDER_VIEW],
					// },
					// {
					// 	label: i18n.t("sitemenu:receipt_index"),
					// 	icon: <IconReceipt {...iconProps} />,
					// 	key: "/productreceipt",
					// 	roles: [Role.INVENTORY_VIEW],
					// },

					{
						label: i18n.t("sitemenu:inven_receipt_index"),
						icon: <IconReceipt {...iconProps} />,
						key: "",
						roles: [Role.INVENTORY_VIEW],
						children: [
							{
								label: i18n.t("Phiếu Nhập kho"),
								icon: <IconReceipt {...iconProps} />,
								key: "/inventoryreceipt/in",
								roles: [Role.INVENTORY_VIEW],
							},
							{
								label: i18n.t("Phiếu xuất kho"),
								icon: <IconReceipt {...iconProps} />,
								key: "/inventoryreceipt/out",
								roles: [Role.INVENTORY_VIEW],
							},
							{
								label: i18n.t("Tạo Nhập hàng"),
								icon: <IconReceipt {...iconProps} />,
								key: `/inventoryreceipt/add/direction/${InventoryReceipt.DIRECTION_INPUT}/type/${InventoryReceipt.TYPE_IN_INPUTRECEIVE}`,

								roles: [Role.INVENTORY_ADD],
							},
							{
								label: i18n.t("Tạo Nhập - chuyển"),
								icon: <IconReceipt {...iconProps} />,
								key: `/inventoryreceipt/add/direction/${InventoryReceipt.DIRECTION_INPUT}/type/${InventoryReceipt.TYPE_TRANSFERSTORE}`,

								roles: [Role.INVENTORY_ADD],
							},
							{
								label: i18n.t("Tạo Nhập - cân bằng kho"),
								icon: <IconReceipt {...iconProps} />,
								key: `/inventoryreceipt/add/direction/${InventoryReceipt.DIRECTION_INPUT}/type/${InventoryReceipt.TYPE_IN_BALANCESTORE}`,
								roles: [Role.INVENTORY_ADD],
							},
							{
								key: "divider_" + uuid(),
								label: "",
								type: "divider",
								roles: [
									Role.SETTING_SALE,
									Role.INVENTORY_TRANSFER_REQUEST,
									Role.INVENTORY_TRANSFER_APPROVE,
								],
							},

							{
								label: i18n.t("Tạo Xuất - hủy"),
								icon: <IconReceipt {...iconProps} />,
								key: `/inventoryreceipt/add/direction/${InventoryReceipt.DIRECTION_OUTPUT}/type/${InventoryReceipt.TYPE_OUT_EXPORT_CANCEL}`,
								roles: [Role.INVENTORY_ADD],
							},
							{
								label: i18n.t("Tạo Xuất - chuyển"),
								icon: <IconReceipt {...iconProps} />,
								key: `/inventoryreceipt/add/direction/${InventoryReceipt.DIRECTION_OUTPUT}/type/${InventoryReceipt.TYPE_TRANSFERSTORE}`,
								roles: [Role.INVENTORY_ADD],
							},
							// {
							// 	label: i18n.t("Tạo Xuất - cân bằng kho"),
							// 	icon: <IconReceipt {...iconProps} />,
							// 	key: `/inventoryreceipt/add/direction/${InventoryReceipt.DIRECTION_OUTPUT}/type/${InventoryReceipt.TYPE_IN_BALANCESTORE}`,
							// 	roles: [Role.INVENTORY_ADD],
							// },
						],
					},

					{
						label: i18n.t("sitemenu:check_inventory"),
						icon: <IconCheckbox {...iconProps} />,
						key: "/productinventorychecking",
						roles: [Role.INVENTORY_STOCK_CHECK],
					},
					{
						label: i18n.t("sitemenu:check_inventory_user"),
						icon: <IconCheckbox {...iconProps} />,
						key: "/productinventorycheckinguser",
						roles: [Role.INVENTORY_STOCK_CHECK],
					},
					{
						label: i18n.t("sitemenu:product_transmit"),
						icon: <IconArrowBigRightLine {...iconProps} />,
						key: "/producttransmit",
						roles: [
							Role.INVENTORY_TRANSFER_REQUEST,
							Role.INVENTORY_TRANSFER_APPROVE,
						],
					},

					{
						key: "divider_" + uuid(),
						label: "",
						type: "divider",
						roles: [
							Role.SETTING_SALE,
							Role.INVENTORY_TRANSFER_REQUEST,
							Role.INVENTORY_TRANSFER_APPROVE,
						],
					},

					{
						label: i18n.t("setting:menu.warehouse_index"),
						icon: <IconHome2 {...iconProps} />,
						key: "/warehouse",
						roles: [Role.SETTING_SALE],
					},
				],
			},

			{
				key: "delivery",
				label: i18n.t("sitemenu:group_delivery"),
				children: [
					// {
					// 	label: i18n.t("sitemenu:delivery_route"),
					// 	icon: <IconRoute {...iconProps} />,
					// 	key: "/shippingroute",
					// 	roles: [Role.SHIPPING_ROUTE_VIEW, Role.SHIPPING_ROUTE_MANAGE],
					// },
					// {
					// 	label: i18n.t("sitemenu:delivery_order"),
					// 	icon: <IconPackageExport {...iconProps} />,
					// 	key: "/shippingorder",
					// 	roles: [Role.SHIPPING_ORDER_VIEW, Role.SHIPPING_ORDER_MANAGE],
					// },

					{
						label: i18n.t("sitemenu:delivery_shipper"),
						icon: <IconBike {...iconProps} />,
						key: "/shipper",
						roles: [Role.SHIPPER_VIEW, Role.SHIPPER_MANAGE],
					},
					{
						label: i18n.t("sitemenu:shipping_type"),
						icon: <IconFolder {...iconProps} />,
						key: "/shippingtype",
						roles: [Role.SETTING_DMS],
					},
					{
						label: i18n.t("setting:menu.shipping_hub"),
						icon: <IconFlag3 {...iconProps} />,
						key: "/shippinghub",
						roles: [Role.SETTING_DMS],
					},
					{
						label: i18n.t("sitemenu:shipper_kpi"),
						icon: <IconBike {...iconProps} />,
						key: "/shipperkpi",
						roles: [Role.SHIPPER_VIEW, Role.SHIPPER_MANAGE],
					},
					{
						key: "divider_" + uuid(),
						label: "",
						type: "divider",
						roles: [Role.DELIVERY_MANAGER, Role.SETTING_DMS],
					},
					{
						label: i18n.t("sitemenu:delivery_manager"),
						key: "/delivery/manager",
						icon: <IconSettings {...iconProps} />,
						roles: [Role.DELIVERY_MANAGER],
					},
					{
						label: i18n.t("sitemenu:delivery_accountant"),
						key: "/delivery/accountant",
						icon: <IconCalculator {...iconProps} />,
						roles: [Role.DELIVERY_ACCOUNTANTS],
					},

					{
						label: i18n.t("sitemenu:delivery_waiting"),
						key: "/delivery/waiting",
						icon: <IconTruckDelivery {...iconProps} />,
						roles: [Role.DELIVERY_SHIPPER, Role.DELIVERY_MANAGER],
					},
					{
						key: "divider_" + uuid(),
						label: "",
						type: "divider",
						roles: [
							Role.DELIVERY_SHIPPER,
							Role.DELIVERY_SHIPPER,
							Role.DELIVERY_MANAGER,
						],
					},

					{
						label: i18n.t("sitemenu:delivery_mine"),
						key: "/delivery/mine",
						icon: <IconBike {...iconProps} />,
						roles: [Role.DELIVERY_SHIPPER],
					},
					{
						label: i18n.t("sitemenu:delivery_statistical"),
						key: "/delivery/statistical",
						icon: <IconBike {...iconProps} />,
						roles: [Role.DELIVERY_SHIPPER],
					},
				],
			},
			{
				key: "hrm",
				label: i18n.t("sitemenu:group_hrm"),
				children: [
					{
						label: i18n.t("sitemenu:checkin_add"),
						icon: <IconClockHour9 {...iconProps} />,
						key: "/checkin/add",
					},
					// {
					//   label: i18n.t("sitemenu:checkin_mine_list"),
					//   icon: <IconClockHour9 {...iconProps} />,
					//   key: "/checkin",
					//   roles: [Role.EMPLOYEE_MANAGE],
					// },
					{
						key: "divider_" + uuid(),
						label: "",
						type: "divider",
						roles: [
							Role.EMPLOYEE_MANAGE,
							Role.EMPLOYEE_MANAGE,
							Role.CHECKIN_EDIT,
							Role.CHECKIN_VERIFY,
						],
					},
					{
						label: i18n.t("sitemenu:checkin_list"),
						icon: <IconClockHour9 {...iconProps} />,
						key: "/checkin/public",
						roles: [
							Role.EMPLOYEE_MANAGE,
							Role.CHECKIN_EDIT,
							Role.CHECKIN_VERIFY,
						],
					},
					{
						label: i18n.t("sitemenu:checkin_review"),
						icon: <IconClipboardCheck {...iconProps} />,
						key: "/checkin/approval",
						roles: [Role.CHECKIN_EDIT, Role.CHECKIN_VERIFY],
					},
					{
						key: "divider_" + uuid(),
						label: "",
						type: "divider",
						roles: [
							Role.EMPLOYEE_MANAGE,
							Role.EMPLOYEE_MANAGE,
							Role.CHECKIN_EDIT,
							Role.CHECKIN_VERIFY,
						],
					},
					{
						label: i18n.t("sitemenu:action"),
						icon: <IconClockHour9 {...iconProps} />,
						key: "/actions/add",
						roles: [Role.CHECKIN_EDIT, Role.CHECKIN_VERIFY],
					},
					{
						label: i18n.t("sitemenu:action_manage"),
						icon: <IconClipboardCheck {...iconProps} />,
						key: "/actions",
						roles: [Role.CHECKIN_EDIT, Role.CHECKIN_VERIFY],
					},
					{
						key: "divider_" + uuid(),
						label: "",
						type: "divider",
						roles: [Role.CHECKIN_EDIT, Role.CHECKIN_VERIFY],
					},
					{
						label: i18n.t("sitemenu:leave_approval"),
						icon: <IconBallpen {...iconProps} />,
						key: "/leaveapprove/approval",
						roles: [Role.LEAVE_MANAGER],
					},
					{
						label: i18n.t("sitemenu:leave_assign"),
						icon: <IconBallpen {...iconProps} />,
						key: "/leaveapprove/assign",
						roles: [Role.COMPANY_SETTING],
					},
					{
						key: "divider_" + uuid(),
						label: "",
						type: "divider",
						roles: [Role.COMPANY_SETTING],
					},
					{
						label: i18n.t("sitemenu:leave"),
						key: "/leave",
						icon: <IconCalendar {...iconProps} />,
					},
					{
						key: "divider_" + uuid(),
						label: "",
						type: "divider",
						roles: [Role.SETTING_HRM],
					},

					{
						label: i18n.t("sitemenu:employee"),
						icon: <IconId {...iconProps} />,
						key: "/employee",
						roles: [Role.SETTING_HRM],
					},
					{
						label: i18n.t("sitemenu:phone_book"),
						key: "/phonebook",
						icon: <IconAddressBook {...iconProps} />,
						roles: [Role.SETTING_HRM],
					},
					{
						key: "divider_" + uuid(),
						label: "",
						type: "divider",
						roles: [Role.SETTING_HRM],
					},
					{
						label: i18n.t("setting:menu.office"),
						icon: <IconBuildingSkyscraper {...iconProps} />,
						key: "/office",
						roles: [Role.SETTING_HRM],
					},
					{
						label: i18n.t("setting:menu.department"),
						key: "/department",
						icon: <IconSitemap {...iconProps} />,
						roles: [Role.SETTING_HRM],
					},
				],
			},
			{
				key: "task",
				label: i18n.t("sitemenu:task"),
				children: [
					{
						label: i18n.t("sitemenu:task_all_list"),
						key: "/task",
						icon: <IconListDetails {...iconProps} />,
					},

					{
						label: i18n.t("sitemenu:task_department_list"),
						key: "/task/department",
						icon: <IconUsersGroup {...iconProps} />,
					},
					// {
					//   label: i18n.t("sitemenu:departmentkanban"),
					//   key: "/task/departmentkanban",
					//   icon: <IconLayoutKanban {...iconProps} />,
					// },
					{
						key: "divider_" + uuid(),
						label: "",
						type: "divider",
					},
					{
						label: i18n.t("sitemenu:my_task_list"),
						key: "/task/mine",
						icon: <IconUser {...iconProps} />,
					},
					{
						label: i18n.t("sitemenu:kanban"),
						key: "/task/kanban",
						icon: <IconLayoutKanban {...iconProps} />,
					},
					{
						key: "divider_" + uuid(),
						label: "",
						type: "divider",
						roles: [Role.SETTING_ADVANCED],
					},

					{
						label: i18n.t("sitemenu:project"),
						icon: <IconSubtask {...iconProps} />,
						key: "/project",
						roles: [Role.SETTING_ADVANCED],
					},
					{
						label: i18n.t("sitemenu:filemanager"),
						icon: <IconFolder {...iconProps} />,
						key: "/filemanager",
						roles: [Role.SETTING_ADVANCED],
					},
				],
			},

			{
				key: "tool",
				label: i18n.t("sitemenu:tool"),
				children: [
					{
						label: i18n.t("sitemenu:qrcode"),
						key: "/tool/qrcode",
						icon: <IconQrcode {...iconProps} />,
					},
					{
						label: i18n.t("sitemenu:wifiqrcode"),
						key: "/tool/wifiqrcode",
						icon: <IconWifi {...iconProps} />,
					},
					{
						label: i18n.t("sitemenu:avatarframe"),
						key: "/tool/avatarframe",
						icon: <IconFrame {...iconProps} />,
					},
					{
						label: i18n.t("sitemenu:itrequest"),
						key: "/itrequest",
						icon: <IconMailbox {...iconProps} />,
					},
					{
						key: "divider_" + uuid(),
						label: "",
						type: "divider",
					},
					// {
					// 	key: "datagrid",
					// 	label: i18n.t("sitemenu:group_datagrid"),
					// 	icon: <IconTable {...iconProps} />,
					// 	children: [
					// 		{
					// 			label: i18n.t("sitemenu:datagrid.createworkspace"),
					// 			key: "/datagridworkspace",
					// 			icon: <IconPlus {...iconProps} />,
					// 			roles: [Role.SETTING_ADVANCED],
					// 		},
					// 	],
					// },
				],
			},
			{
				key: "content",
				label: i18n.t("sitemenu:content"),
				children: [
					{
						label: i18n.t("sitemenu:notification"),
						key: "/notification",
						icon: <IconBell {...iconProps} />,
						roles: [Role.PUBLICATION_MANAGER],
					},
					// {
					//   label: i18n.t("sitemenu:collection"),
					//   key: "/collection",
					//   icon: <IconCategory {...iconProps} />,
					//   roles: [Role.COLLECTION_MANAGER],
					// },
					{
						label: i18n.t("sitemenu:storycollection"),
						key: "/storycollection",
						icon: <IconCategory {...iconProps} />,
						roles: [Role.COLLECTION_MANAGER],
					},
					{
						label: i18n.t("sitemenu:stories"),
						key: "/story",
						icon: <IconClock24 {...iconProps} />,
						roles: [Role.STORY_MANAGER],
					},
					{
						label: i18n.t("sitemenu:comment"),
						key: "/comment",
						icon: <CommentOutlined {...iconProps} />,
						roles: [Role.COMMENT_MANAGER],
					},
					{
						key: "divider_" + uuid(),
						label: "",
						type: "divider",
					},
					{
						label: i18n.t("setting:menu.tagtype"),
						icon: <IconTag {...iconProps} />,
						key: "/tagtype",
						roles: [
							Role.SETTING_SALE,
							Role.SETTING_ADVANCED,
							Role.PRODUCT_MANAGE,
						],
					},
					{
						label: i18n.t("setting:menu.tag"),
						icon: <IconTag {...iconProps} />,
						key: "tag",
						roles: [
							Role.SETTING_SALE,
							Role.SETTING_ADVANCED,
							Role.PRODUCT_MANAGE,
						],
						children: [
							{
								label: i18n.t("sitemenu:tag_product"),
								key: "/tagproduct",
							},
							{
								label: i18n.t("setting:menu.product_category"),
								key: "/tagproductcollection",
							},
							{
								label: i18n.t("sitemenu:storycollection"),
								key: "/tagstorycollection",
							},
							{
								label: i18n.t("sitemenu:stories"),
								key: "/tagstory",
							},
							{
								label: i18n.t("setting:menu.tagbrand"),
								key: "/tagbrand",
							},
							{
								label: i18n.t("setting:menu.tagwarehouse"),
								key: "/tag/warehouse",
							},
						],
					},
					{
						key: "divider_" + uuid(),
						label: "",
						type: "divider",
					},
					{
						label: i18n.t("sitemenu:cms_news"),
						key: "/cmsnews",
						icon: <IconNews {...iconProps} />,
						roles: [Role.CMS_NEWS_ADD, Role.CMS_NEWS_VIEW, Role.CMS_NEWS_EDIT],
					},
					{
						label: i18n.t("sitemenu:document"),
						key: "/documents",
						icon: <IconNews {...iconProps} />,
						roles: [Role.STORY_MANAGER],
					},
					// {
					//   label: i18n.t("sitemenu:cms_post"),
					//   icon: <IconBlockquote {...iconProps} />,
					//   key: "/cmspost",
					//   roles: [Role.CMS_POST_ADD, Role.CMS_POST_EDIT, Role.CMS_POST_VIEW],
					// },
					{
						key: "divider_" + uuid(),
						label: "",
						type: "divider",
						roles: [
							Role.CMS_POST_ADD,
							Role.CMS_POST_EDIT,
							Role.CMS_POST_VIEW,
							400,
						],
					},
					// {
					// 	label: i18n.t("sitemenu:academy"),
					// 	icon: <IconBook {...iconProps} />,
					// 	key: "/academy",
					// 	roles: [400],
					// },
				],
			},

			{
				key: "report",
				label: i18n.t("sitemenu:group_report"),
				children: [
					{
						label: i18n.t("sitemenu:report_sale"),
						icon: <IconShoppingBag {...iconProps} />,
						key: "sale",
						roles: [Role.REPORT_SALE],
						children: [
							{
								key: "/report/sale/general",
								label: i18n.t("sitemenu:report_sale_general"),
							},
							{
								key: "/report/sale/store",
								label: i18n.t("sitemenu:report_sale_store"),
							},
							{
								key: "/report/sale/region",
								label: i18n.t("sitemenu:report_sale_region"),
							},
							{
								key: "/report/sale/status",
								label: i18n.t("sitemenu:report_sale_status"),
							},
							{
								key: "/report/sale/employee",
								label: i18n.t("sitemenu:report_sale_employee"),
							},
							{
								key: "/report/sale/promotion",
								label: i18n.t("sitemenu:report_sale_promotion"),
								children: [
									{
										key: "/report/sale/promotion/general",
										label: i18n.t("sitemenu:report_sale_promotion_general"),
									},
									{
										key: "/report/sale/promotion/campain",
										label: i18n.t("sitemenu:report_sale_promotion_campaign"),
									},
									{
										key: "/report/sale/promotion/store",
										label: i18n.t("sitemenu:report_sale_promotion_store"),
									},
									{
										key: "/report/sale/promotion/employee",
										label: i18n.t("sitemenu:report_sale_promotion_employee"),
									},
								],
							},
						],
					},
					{
						label: i18n.t("sitemenu:report_order"),
						icon: <IconShoppingCart {...iconProps} />,
						key: "order",
						roles: [Role.ORDER_VIEW],
						children: [
							{
								key: "/report/order/general",
								label: i18n.t("sitemenu:report_order_general"),
							},
							// {
							//   key: "/report/order/productcategory",
							//   label: i18n.t("sitemenu:report_order_product_category")
							// },
							// {
							//   key: "/report/order/product",
							//   label: i18n.t("sitemenu:report_order_product")
							// },
							{
								key: "/report/order/productoption",
								label: i18n.t("sitemenu:report_order_product_option"),
							},
							{
								key: "/report/order/productattribute",
								label: i18n.t("sitemenu:report_order_product_attribute"),
								children: [
									{
										key: "/report/order/productattribute/general",
										label: i18n.t(
											"sitemenu:report_order_product_attribute_general"
										),
									},
									{
										key: "/report/order/productattribute/title",
										label: i18n.t(
											"sitemenu:report_order_product_attribute_title"
										),
									},
									{
										key: "/report/order/productattribute/color",
										label: i18n.t(
											"sitemenu:report_order_product_attribute_color"
										),
									},
									{
										key: "/report/order/productattribute/size",
										label: i18n.t(
											"sitemenu:report_order_product_attribute_size"
										),
									},
									// {
									//   key: "/report/order/productattribute/material",
									//   label: i18n.t(
									//     "sitemenu:report_order_product_attribute_material"
									//   )
									// },
									// {
									//   key: "/report/order/productattribute/style",
									//   label: i18n.t(
									//     "sitemenu:report_order_product_attribute_style"
									//   )
									// }
								],
							},
							{
								key: "/report/order/promotion",
								label: i18n.t("sitemenu:report_order_promotion"),
								children: [
									{
										key: "/report/order/promotion/general",
										label: i18n.t("sitemenu:report_order_promotion_general"),
									},
									// {
									//   key: "/report/order/promotion/productcategory",
									//   label: i18n.t(
									//     "sitemenu:report_order_promotion_product_category"
									//   )
									// },
									// {
									//   key: "/report/order/promotion/product",
									//   label: i18n.t("sitemenu:report_order_promotion_product")
									// },
									{
										key: "/report/order/promotion/productoption",
										label: i18n.t(
											"sitemenu:report_order_promotion_product_option"
										),
									},
								],
							},
						],
					},
					{
						label: i18n.t("sitemenu:report_customer"),
						icon: <IconUserCircle {...iconProps} />,
						key: "customer",
						roles: [Role.CUSTOMER_MANAGE],
						children: [
							{
								key: "/report/customer/general",
								label: i18n.t("sitemenu:report_customer_general"),
							},
							{
								key: "/report/customer/region",
								label: i18n.t("sitemenu:report_customer_region"),
							},
							// {
							//   key: "/report/customer/origin",
							//   label: i18n.t("sitemenu:report_customer_origin")
							// },
							{
								key: "/report/customer/type",
								label: i18n.t("sitemenu:report_customer_type"),
							},
							// {
							//   key: "/report/customer/feed",
							//   label: i18n.t("sitemenu:report_customer_feed")
							// },
							{
								key: "/report/customer/order",
								label: i18n.t("sitemenu:report_customer_order"),
							},
							{
								key: "/report/customer/point",
								label: i18n.t("sitemenu:report_customer_point"),
							},
						],
					},
					{
						label: i18n.t("sitemenu:report_cashflowreceipt_income"),
						icon: <IconReceiptTax {...iconProps} />,
						key: "cashflowreceipt_income",
						roles: [Role.CASHFLOW_VIEW],
						children: [
							{
								key: "/report/cashflowreceiptincome/general",
								label: i18n.t("sitemenu:report_cashflowreceipt_income_general"),
							},
							{
								key: "/report/cashflowreceiptincome/store",
								label: i18n.t("sitemenu:report_cashflowreceipt_income_store"),
							},
							// {
							//   key: "/report/cashflowreceiptincome/category",
							//   label: i18n.t("sitemenu:report_cashflowreceipt_income_category")
							// },
							// {
							//   key: "/report/cashflowreceiptincome/account",
							//   label: i18n.t("sitemenu:report_cashflowreceipt_income_account")
							// },
							{
								key: "/report/cashflowreceiptincome/method",
								label: i18n.t("sitemenu:report_cashflowreceipt_income_method"),
							},
							{
								key: "/report/cashflowreceiptincome/partner",
								label: i18n.t("sitemenu:report_cashflowreceipt_income_partner"),
								children: [
									{
										key: "/report/cashflowreceiptincome/partner/general",
										label: i18n.t(
											"sitemenu:report_cashflowreceipt_income_partner_general"
										),
									},
									{
										key: "/report/cashflowreceiptincome/partner/customer",
										label: i18n.t(
											"sitemenu:report_cashflowreceipt_income_partner_customer"
										),
									},
									{
										key: "/report/cashflowreceiptincome/partner/supplier",
										label: i18n.t(
											"sitemenu:report_cashflowreceipt_income_partner_supplier"
										),
									},
									{
										key: "/report/cashflowreceiptincome/partner/employee",
										label: i18n.t(
											"sitemenu:report_cashflowreceipt_income_partner_employee"
										),
									},
								],
							},
						],
					},
					{
						label: i18n.t("sitemenu:report_cashflowreceipt_expense"),
						icon: <IconReceiptRefund {...iconProps} />,
						key: "cashflowreceipt_expense",
						roles: [Role.CASHFLOW_VIEW],
						children: [
							{
								key: "/report/cashflowreceiptexpense/general",
								label: i18n.t(
									"sitemenu:report_cashflowreceipt_expense_general"
								),
							},
							{
								key: "/report/cashflowreceiptexpense/store",
								label: i18n.t("sitemenu:report_cashflowreceipt_expense_store"),
							},
							// {
							//   key: "/report/cashflowreceiptexpense/category",
							//   label: i18n.t(
							//     "sitemenu:report_cashflowreceipt_expense_category"
							//   )
							// },
							// {
							//   key: "/report/cashflowreceiptexpense/account",
							//   label: i18n.t("sitemenu:report_cashflowreceipt_expense_account")
							// },
							{
								key: "/report/cashflowreceiptexpense/method",
								label: i18n.t("sitemenu:report_cashflowreceipt_expense_method"),
							},
							{
								key: "/report/cashflowreceiptexpense/partner",
								label: i18n.t(
									"sitemenu:report_cashflowreceipt_expense_partner"
								),
								children: [
									{
										key: "/report/cashflowreceiptexpense/partner/general",
										label: i18n.t(
											"sitemenu:report_cashflowreceipt_expense_partner_general"
										),
									},
									{
										key: "/report/cashflowreceiptexpense/partner/customer",
										label: i18n.t(
											"sitemenu:report_cashflowreceipt_expense_partner_customer"
										),
									},
									{
										key: "/report/cashflowreceiptexpense/partner/supplier",
										label: i18n.t(
											"sitemenu:report_cashflowreceipt_expense_partner_supplier"
										),
									},
									{
										key: "/report/cashflowreceiptexpense/partner/employee",
										label: i18n.t(
											"sitemenu:report_cashflowreceipt_expense_partner_employee"
										),
									},
								],
							},
						],
					},
					{
						label: i18n.t("sitemenu:report_inventory"),
						icon: <IconPackage {...iconProps} />,
						key: "inventory",
						roles: [Role.INVENTORY_VIEW],

						children: [
							{
								key: "/report/inventory/general",
								label: i18n.t("sitemenu:report_inventory_general"),
							},
							{
								key: "/report/inventory/input",
								label: i18n.t("sitemenu:report_inventory_input"),
								children: [
									{
										key: "/report/inventory/input/general",
										label: i18n.t("sitemenu:report_inventory_input_general"),
									},
									{
										key: "/report/inventory/input/warehouse",
										label: i18n.t("sitemenu:report_inventory_input_warehouse"),
									},
									{
										key: "/report/inventory/input/type",
										label: i18n.t("sitemenu:report_inventory_input_type"),
									},
									// {
									//   key: "/report/inventory/input/productcategory",
									//   label: i18n.t(
									//     "sitemenu:report_inventory_input_product_category"
									//   )
									// },
									// {
									//   key: "/report/inventory/input/product",
									//   label: i18n.t("sitemenu:report_inventory_input_product")
									// },
									// {
									//   key: "/report/inventory/input/supplier",
									//   label: i18n.t("sitemenu:report_inventory_input_supplier")
									// },
									// {
									//   key: "/report/inventory/input/vender",
									//   label: i18n.t("sitemenu:report_inventory_input_vender")
									// }
								],
							},
							{
								key: "/report/inventory/output",
								label: i18n.t("sitemenu:report_inventory_output"),
								children: [
									{
										key: "/report/inventory/output/general",
										label: i18n.t("sitemenu:report_inventory_output_general"),
									},
									{
										key: "/report/inventory/output/warehouse",
										label: i18n.t("sitemenu:report_inventory_output_warehouse"),
									},
									{
										key: "/report/inventory/output/type",
										label: i18n.t("sitemenu:report_inventory_output_type"),
									},
									// {
									//   key: "/report/inventory/output/productcategory",
									//   label: i18n.t(
									//     "sitemenu:report_inventory_output_product_category"
									//   )
									// },
									// {
									//   key: "/report/inventory/output/product",
									//   label: i18n.t("sitemenu:report_inventory_output_product")
									// },
									// {
									//   key: "/report/inventory/output/supplier",
									//   label: i18n.t("sitemenu:report_inventory_output_supplier")
									// },
									// {
									//   key: "/report/inventory/output/vender",
									//   label: i18n.t("sitemenu:report_inventory_output_vender")
									// }
								],
							},
						],
					},
					{
						label: i18n.t("sitemenu:report_leave"),
						icon: <IconCalendar {...iconProps} />,
						roles: [Role.REPORT_HRM, Role.EMPLOYEE_MANAGE],
						key: "/report/leave",
					},
					{
						label: i18n.t("sitemenu:report_delivery"),
						icon: <IconBike {...iconProps} />,
						roles: [Role.DELIVERY_ACCOUNTANTS, Role.REPORT_DELIVERY],
						key: "/report/delivery",
					},
					{
						label: i18n.t("sitemenu:report_checkin"),
						icon: <IconClipboardCheck {...iconProps} />,
						roles: [Role.REPORT_HRM],
						key: "/report/checkin",
					},
				],
			},
			{
				key: "system",
				label: i18n.t("system:heading_nav"),
				icon: <RadarChartOutlined {...iconProps} />,
				children: [
					{
						label: i18n.t("system:heading_nav2"),
						key: "/systemevent",
						icon: <IconPlus {...iconProps} />,
					},
				],
			},
			{
				key: "device",
				label: i18n.t("sitemenu:device"),
				children: [
					{
						label: i18n.t("sitemenu:device_manage"),
						key: "/device/manage",
						icon: <IconDeviceDesktop {...iconProps} />,
					},
					{
						label: i18n.t("sitemenu:device_mine"),
						key: "/device/mine",
						icon: <IconDeviceDesktop {...iconProps} />,
					},
					{
						label: i18n.t("sitemenu:contract_manage"),
						key: "/contract/manage",
						icon: <IconNews {...iconProps} />,
					},
				],
			},
			{
				key: "setting",
				label: i18n.t("sitemenu:group_setting"),
				children: [
					{
						label: i18n.t("sitemenu:setting_general"),
						icon: <FundOutlined {...iconProps} />,

						key: "/setting/general",
						roles: [Role.SETTING_SALE],
					},
					{
						label: i18n.t("sitemenu:setting_tax"),
						icon: <IconSettings2 {...iconProps} />,
						key: "/setting/tax",
						roles: [Role.SETTING_SALE],
					},

					{
						label: i18n.t("setting:menu.leavetype"),
						icon: <IconSettings2 {...iconProps} />,
						key: "/leavetype",
						roles: [Role.SETTING_HRM],
					},

					{
						label: i18n.t("setting:menu.contracttype"),
						key: "/contracttype",
						icon: <IconSettings2 {...iconProps} />,
						roles: [Role.SETTING_HRM],
					},
					{
						key: "divider_" + uuid(),
						label: "",
						type: "divider",
						roles: [Role.SETTING_HRM],
					},
					{
						label: i18n.t("setting:menu.permission"),
						key: "/role",
						icon: <IconLock {...iconProps} />,
						roles: [Role.SETTING_HRM],
					},

					{
						key: "divider_" + uuid(),
						label: "",
						type: "divider",
						roles: [Role.SETTING_SALE, Role.SETTING_HRM],
					},
					{
						label: i18n.t("sitemenu:echannel"),
						icon: <IconWorldWww {...iconProps} />,
						key: "/ecom",
						roles: [Role.SETTING_SALE],
					},
					// {
					//   label: i18n.t("setting:menu.webhook"),
					//   icon: <IconShare2 {...iconProps} />,
					//   key: "/webhook",
					//   roles: [Role.SETTING_ADVANCED],
					// },
					// {
					//   label: i18n.t("setting:menu.apikey"),
					//   icon: <IconApi {...iconProps} />,
					//   key: "/apikey",
					//   roles: [Role.SETTING_ADVANCED],
					// },

					{
						key: "divider_" + uuid(),
						label: "",
						type: "divider",
						roles: [Role.SETTING_SALE, Role.SETTING_HRM],
					},

					{
						key: "shop",
						label: i18n.t("sitemenu:shop"),
						icon: <IconShoppingCartBolt {...iconProps} />,
						// roles: [999],
						// roles: [Role.SETTING_SALE, Role.SETTING_ADVANCED, Role.PRODUCT_MANAGE],
						children: [
							{
								label: i18n.t("sitemenu:shopmegamenu"),
								key: "/shopmegamenu",
								icon: <IconCategory {...iconProps} />,
								roles: [
									Role.SETTING_SALE,
									Role.SETTING_ADVANCED,
									Role.PRODUCT_MANAGE,
								],
							},

							{
								label: i18n.t("sitemenu:shop_home"),
								key: "shophome",
								icon: <IconCategory {...iconProps} />,
								roles: [
									Role.SETTING_SALE,
									Role.SETTING_ADVANCED,
									Role.PRODUCT_MANAGE,
								],
								children: [
									{
										label: i18n.t("sitemenu:shopbannerhome"),
										key: "/shopbannerhome",
										// icon: <IconCategory {...iconProps} />,
									},
									{
										label: i18n.t("sitemenu:shop_brand_home"),
										key: "/shopbrandhome",
									},
									{
										label: i18n.t("sitemenu:shopcollection"),
										key: "/shopcollection",
										// icon: <IconCategory {...iconProps} />,
									},
								],
							},

							{
								label: i18n.t("sitemenu:shopfooter"),
								key: "/shopfooter",
								icon: <IconCategory {...iconProps} />,
								roles: [
									Role.SETTING_SALE,
									Role.SETTING_ADVANCED,
									Role.PRODUCT_MANAGE,
								],
							},
							{
								label: i18n.t("sitemenu:shoppopup"),
								key: "/shoppopup",
								icon: <IconCategory {...iconProps} />,
								roles: [
									Role.SETTING_SALE,
									Role.SETTING_ADVANCED,
									Role.PRODUCT_MANAGE,
								],
							},
							{
								label: i18n.t("sitemenu:shop_event"),
								key: "/shopevent",
								icon: <IconCategory {...iconProps} />,
								roles: [
									Role.SETTING_SALE,
									Role.SETTING_ADVANCED,
									Role.PRODUCT_MANAGE,
								],
								children: [
									{
										label: i18n.t("sitemenu:shop_event_bd"),
										key: "/shopevent/birthday-11-year",
									},
									{
										label: i18n.t("sitemenu:sale_20_5"),
										key: "/shopevent/sale-20-5",
									},
									{
										label: i18n.t("sitemenu:salecuoinam"),
										key: "/shopevent/salecuoinam",
									},
								],
							},
							{
								label: i18n.t("sitemenu:shop_ads"),
								key: "shopads",
								icon: <IconCategory {...iconProps} />,
								roles: [
									Role.SETTING_SALE,
									Role.SETTING_ADVANCED,
									Role.PRODUCT_MANAGE,
								],
								children: [
									{
										label: i18n.t("sitemenu:shop_ads_collection"),
										key: "/shopadscollection",
									},
								],
							},
							{
								label: i18n.t("sitemenu:shop_dealthom"),
								key: "dealthom",
								icon: <IconCategory {...iconProps} />,
								roles: [
									Role.SETTING_SALE,
									Role.SETTING_ADVANCED,
									Role.PRODUCT_MANAGE,
								],
								children: [
									{
										label: i18n.t("sitemenu:dealthomsetting"),
										key: "/dealthomsetting",
									},
								],
							},
							{
								label: i18n.t("sitemenu:shopanother"),
								key: "shopanother",
								icon: <IconCategory {...iconProps} />,
								roles: [
									Role.SETTING_SALE,
									Role.SETTING_ADVANCED,
									Role.PRODUCT_MANAGE,
								],
								children: [
									{
										label: i18n.t("sitemenu:storeimage"),
										key: "/shopanother/storeImage",
									},
									{
										label: i18n.t("sitemenu:showonwebsite"),
										key: "/shopanother/showonwebsite",
									},
								],
							},
							{
								label: i18n.t("sitemenu:shopsetting"),
								key: "manualsetting",
								icon: <IconSettings {...iconProps} />,
								roles: [
									Role.SETTING_SALE,
									Role.SETTING_ADVANCED,
									Role.PRODUCT_MANAGE,
								],
								children: [
									{
										label: i18n.t("sitemenu:shop_setting"),
										key: "/shopsetting",
									},
									{
										label: i18n.t("sitemenu:page_setting"),
										key: "/pagesetting",
									},
									{
										label: i18n.t("sitemenu:erp_setting"),
										key: "/erpsetting",
									},
									{
										label: i18n.t("sitemenu:language_setting"),
										key: "/languagesetting",
									},
									{
										label: i18n.t("sitemenu:common_setting"),
										key: "/commonsetting",
									},
									{
										label: i18n.t("sitemenu:shopsettingall"),
										key: "/shopsettingall",
									},
								],
							},
							// {
							//   label: i18n.t("sitemenu:settingpromotions"),
							//   key: "/settingpromotions",
							//   icon: <IconSettings {...iconProps} />,
							//   // roles: [Role.COLLECTION_MANAGER, Role.PRODUCT_MANAGE],
							// },
						],
					},
				],
			},
			// {
			//   key: "template",
			//   label: i18n.t("template:heading_nav"),

			//   children: [
			//     {
			//       label: i18n.t("template:heading_nav2"),
			//       key: "/template",
			//       icon: <IconPlus {...iconProps} />,
			//       roles: process.env.NODE_ENV === "development" ? [] : [99999999],
			//     },
			//   ],
			// },
		];

		return menu;
	}

	static getSiteMenuWithRoleCheck(account: AccountJson) {
		const sourceMenu = this.getDefaultMenu();
		const menuItems: SubMenuType[] = [];

		//inline method to check current login account has role
		const hasRole = (subject_id: number) =>
			typeof account.role.find((r) => r.subject_id === subject_id) !==
			"undefined";

		const hasAllRole = (subject_id: number[]) =>
			subject_id.every(
				(i) =>
					typeof account.role.find((r) => r.subject_id === i) !== "undefined"
			);

		sourceMenu.forEach((item) => {
			let foundItemRole = false;
			let foundChildren: SiteMenuSubItem[] = [];

			//check role of root item
			if (
				typeof item.roles !== "undefined" &&
				Array.isArray(item.roles) &&
				item.roles.length > 0
			) {
				foundItemRole = item.roles.findIndex((r) => hasRole(r)) >= 0;
				// foundItemRole = true;
			} else {
				//check role from children
				if (typeof item.children !== "undefined") {
					// const listChildren = item.children!;

					item.children.forEach((subitem, index) => {
						let foundSubItemRole = false;

						//check role of subitem
						if (
							typeof subitem.roles !== "undefined" &&
							Array.isArray(subitem.roles) &&
							subitem.roles.length > 0
						) {
							if (
								subitem.hasOwnProperty("type") &&
								subitem.type === "divider"
							) {
								foundSubItemRole = hasAllRole(subitem.roles as number[]);
							} else {
								foundSubItemRole =
									subitem.roles.findIndex((r: any) => hasRole(r)) >= 0;
							}
						} else if (typeof subitem.roles === "undefined") {
							foundSubItemRole = true;
						}
						// if (
						//   subitem.hasOwnProperty("type") &&
						//   subitem.type === "divider" &&
						//   !(
						//     listChildren.indexOf(subitem) !== listChildren.length - 1 ||
						//     listChildren
						//       .slice(index, listChildren.length)
						//       .every((i) => i.type === "divider")
						//   )
						// ) {
						//   foundSubItemRole = true;
						// }
						// foundSubItemRole = true;
						if (foundSubItemRole) {
							foundChildren.push(subitem);
						}
					});

					//if children valid, we show item
				} else {
					//default if not children and not role, this will be always show
					foundItemRole = false;
				}
			}
			if (foundChildren.filter((i) => i.type !== "divider").length > 0) {
				foundItemRole = true;
			}

			// if (foundChildren.length > 0) {
			//   foundItemRole = true;
			// }

			if (foundItemRole) {
				menuItems.push({ ...item, children: foundChildren });
			}
		});

		return menuItems;
	}
}

export default SiteMenuRepository;
