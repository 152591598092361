import { MoreOutlined } from "@ant-design/icons";
import { IconInfoCircle } from "@tabler/icons-react";
import {
	Button,
	Checkbox,
	Col,
	Dropdown,
	MenuProps,
	Modal,
	Row,
	Tag,
	Tooltip,
	Typography,
	message,
} from "antd";

import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import usePostActionStore from "zustands/usePostActionStore";

import DeliveryCollection from "common/collections/DeliveryCollection";
import DeliveryModel from "common/models/DeliveryModel";
import EmployeeModel from "common/models/EmployeeModel";
import DeliveryRepository from "common/repositories/DeliveryRepository";
import IdFetcherRepository from "common/repositories/IdFetcherRepository";
import {
	ActionCode,
	FilterDelivery,
	DeliveryButtonSubmit,
} from "common/types/Delivery";
import { TableColumnsType } from "common/types/Table";

import TextCashflowReceiptMethod from "components/TextCashflowReceiptMethod";
import TextDateTime from "components/TextDateTime";
import TextMoney from "components/TextMoney";
import PageDataTable from "components/page/PageDataTable";

import DeliverySumByManager from "features/delivery/sumby/DeliverySumbyManager";
import PhoneBookModal from "features/phonebook/form/PhoneBookModal";

import DeliveryAccountantListFilter from "./DeliveryAccountantListFilter";
import DeliveryAccountantListHeader from "./DeliveryAccountantListHeader";
import ButtonActionAccountant from "../component/ButtonActionAccountant";
import ActionDelivery from "common/constants/ActionDelivery";
import ConfirmManyAction from "features/delivery/accountant/component/ConfirmManyActionForm";
import ButtonAction from "features/delivery/action/ButtonAction";
import Delivery from "common/constants/Delivery";
import PostAction from "features/delivery/action/PostAction";
import PageDataPagination from "components/page/PageDataPagination";
import Helper from "common/utils/helper";

const DeliveryAccountantsList = () => {
	const { t } = useTranslation();
	const messageKey = "completed_d";
	const navigate = useNavigate();

	const { reload, setReload } = usePostActionStore();

	//state
	//filtering
	const defaultFilters: any = useMemo(() => {
		return {
			page: 1,
			limit: 20,
			sortby: "id",
			sorttype: "DESC",
			status: -1,
			keyword: "",
			action_code: null,
			internal_id: "",
			soandcode: "",
			date_created: -1,
			date_completed: -1,
			actor_id: -1,
			find_internal: "",
		};
	}, []);
	const [filters, setFilters] = useStateFilter<FilterDelivery>(defaultFilters);

	const [dataSource, setDataSource] = useState<DeliveryModel[]>([]);
	const [total, setTotal] = useState<number>(0);

	const [creatorsD, setCreatorsD] = useState<any[]>([]);
	const [disabled, setDisabled] = useState<boolean>(true);
	const [showContactFields, setShowContactFields] = useState(false);
	const [modalId, setModalId] = useState<number>(0);
	const [showModalConfirm, setShowModalConfirm] =
		React.useState<boolean>(false);
	const [showModalTip, setShowModalTip] = React.useState<boolean>(false);
	const [showModalRefund, setShowModalRefund] = React.useState<boolean>(false);
	const [shipperInfo, setShipperInfo] = React.useState<EmployeeModel>(
		new EmployeeModel(EmployeeModel.getDefaultData())
	);
	const [selectDeliveryArr, setSelectDeliveryArr] = React.useState<
		DeliveryModel[]
	>([]);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [actionCode, setActionCode] = useState<ActionCode>("ACTION_ADDRESS");
	const [employeesD, setEmployeesD] = useState<any[]>([]);
	const [items, setItems] = useState<MenuProps["items"]>([]);

	//newcheckboxlist
	const handleCheckBoxSelect = (selectItem: DeliveryModel) => {
		let newArr = [...selectDeliveryArr];
		const isExist = selectDeliveryArr.findIndex(
			(item) => item.id === selectItem.id
		);
		if (isExist >= 0) {
			newArr.splice(isExist, 1);
		} else {
			newArr = [...newArr, selectItem];
		}
		setSelectDeliveryArr(newArr);
	};

	//////////////////////////////////////////
	//Filtering
	useFilterLocation(defaultFilters, filters);

	//actor

	const fetchingEmployeeD = useCallback(async (id_list: number[]) => {
		const collection = await new IdFetcherRepository().doFetching({
			object_type: "employee",
			id_list,
		});

		setEmployeesD(
			collection.items.find((i) => i.object_type === "employee")?.result || []
		);
	}, []);

	//creator_id
	const fetchingCreatorD = useCallback(async (id_list: number[]) => {
		const collection = await new IdFetcherRepository().doFetching({
			object_type: "employee",
			id_list,
		});

		setCreatorsD(
			collection.items.find((i) => i.object_type === "employee")?.result || []
		);
	}, []);

	//////////////////////////////////////////
	//Fetch data from this collections

	const fetchData = useCallback(async (): Promise<DeliveryCollection> => {
		const collection =
			await new DeliveryRepository().getItemsDeilveryAccountants({
				filters,
			});
		if (collection) {
			if (collection?.items.length > 0) {
				fetchingEmployeeD(collection.items.map((item) => item.actor_id));
				fetchingCreatorD(collection.items.map((item) => item.creator_id));
			}
			setTotal(collection.total);
			setDataSource(collection.items);
		} else {
			message.error(t("common:error.error_fetching_data"));
		}
		getMenuAction();
		setSelectDeliveryArr([]);

		return collection;
	}, [filters, reload]);

	const handleClickCard = (id: number) => {
		setModalId(id);
	};

	//////////////

	const checkSameActionCode = (
		selectDeliveryArr: DeliveryModel[],
		action_code: string
	) => {
		for (let i = 0; i < selectDeliveryArr.length; i++) {
			if (selectDeliveryArr[i].action_code !== action_code) {
				return false;
			}
		}
		return true;
	};
	//Table columns
	const columns: TableColumnsType<DeliveryModel> = [
		{
			title: (
				<>
					<Checkbox
						disabled={
							!Helper.checkHasActionCode(dataSource, "ACTION_DELIVERED")
						}
						onChange={(e) => {
							if (e.target.checked) {
								dataSource.map((item) => {
									if (
										!selectDeliveryArr.includes(item) &&
										["ACTION_DELIVERED"].includes(item.action_code)
										// item.action_code === ActionDelivery.ACTION_DELIVERED
									) {
										setSelectDeliveryArr((prev) => [...prev, item]);
									}
								});
							} else {
								setSelectDeliveryArr([]);
							}
						}}></Checkbox>
				</>
			),
			key: "checkbox",
			togglelabel: "Checkbox",
			width: 50,
			fixed: "left",
			render: (_: any, record: DeliveryModel) => {
				return (
					<Checkbox
						disabled={!["ACTION_DELIVERED"].includes(record.action_code)}
						checked={selectDeliveryArr.includes(record)}
						onClick={() => {
							// handleCheckBoxClick(record.id);
							handleCheckBoxSelect(record);
						}}
					/>
				);
			},
		},
		{
			title: t("order:id"),
			key: "sale_order_id",
			// sortby: "id",
			width: 100,
		},

		{
			title: t("delivery:table.delivery_id"),
			key: "id",
			width: 100,
		},

		{
			title: t("order:billing"),
			key: "billing",
			width: 300,
			render: (_: any, record: DeliveryModel) => {
				return (
					<>
						<div>
							<div>
								<span className="font-bold">{record.contact_fullname}</span>
								<span className=""> / {record.contact_phone}</span>
							</div>
							<div className="text-xs text-gray-500">
								{record.contact_address}
							</div>
						</div>
					</>
				);
			},
		},
		{
			title: t("delivery:table.cost_total"),
			key: "cost_total",
			align: "right",
			sortby: "cost_total",
			width: 120,
			render: (price_final: number) => {
				return <TextMoney money={price_final} />;
			},
		},
		{
			title: t("delivery:table.cod_total"),
			key: "cod_total",
			align: "right",
			sortby: "cod_total",
			width: 120,
			render: (price_final: number) => {
				return <TextMoney money={price_final} />;
			},
		},
		{
			title: t("delivery:table.cod"),
			key: "cod",
			align: "right",
			sortby: "cod",
			width: 120,
			render: (price_final: number) => {
				return <TextMoney money={price_final} />;
			},
		},
		{
			title: t("delivery:accountant.transfer"),
			key: "cost_on_transfer",
			align: "right",
			sortby: "cod",
			width: 120,
			render: (cost_on_transfer: number) => {
				return <TextMoney money={cost_on_transfer} />;
			},
		},
		{
			title: t("delivery:table.shipper"),
			key: "actor_id",
			width: 150,
			render: (actor_id: any) => {
				return (
					<Typography.Text>
						{actor_id === 0 ? (
							<Tag>{t("delivery:accountant.no")}</Tag>
						) : (
							<Tag
								className="cursor-pointer"
								onClick={(e) => {
									e.stopPropagation();
									e.preventDefault();
									handleClickCard(actor_id);
									setShowContactFields(true);
								}}>
								{employeesD.length > 0
									? employeesD.find((i) => i.value === actor_id)?.text
									: t("delivery:accountant.no")}
							</Tag>
						)}
					</Typography.Text>
				);
			},
		},
		{
			title: (
				<>
					<Tooltip title={t("cashflowreceipt:filter_method")}>
						{t("cashflowreceipt:method_sort")}
					</Tooltip>
				</>
			),
			key: "payment_method",
			align: "center",
			width: 150,
			render: (method: number) => {
				return <TextCashflowReceiptMethod method={method} />;
			},
		},
		{
			title: t("common:status"),
			key: "action_code",
			render: (action_code: string) => {
				return (
					<Tag color="#0089FF">
						{DeliveryModel.getActionStatus(action_code)}
					</Tag>
				);
			},
			width: 150,
		},
		{
			title: t("delivery:table.created_by"),
			key: "creator_id",
			width: 200,
			render: (creator_id) => {
				// console.log(creatorsD);
				return (
					<Typography.Text>
						{creator_id === 0 ? (
							"BOT"
						) : (
							<Tag
								className="cursor-pointer"
								onClick={(e) => {
									e.stopPropagation();
									e.preventDefault();
									handleClickCard(creator_id);
									setShowContactFields(true);
								}}>
								{creatorsD.length > 0
									? creatorsD.find((i) => i.value === creator_id)?.text
									: t("delivery:accountant.empty")}
							</Tag>
						)}
					</Typography.Text>
				);
			},
		},
		{
			title: t("common:date_created"),
			key: "date_created",
			width: 120,
			render: (date_created) => Helper.formatTimestampToText2(date_created),
		},
		{
			title: "",
			key: "actions",
			align: "right",
			fixed: "right",
			toggletype: "trigger",
			width: 50,
			render: (_, record: DeliveryModel) => {
				const items: MenuProps["items"] = [
					{
						key: "3",
						label: (
							<Button
								size="small"
								type="text"
								onClick={() => {
									navigate(`/delivery/accountant_detail/id/${record.id}`);
								}}
								icon={
									<IconInfoCircle
										size="18"
										className={" -mt-0.5 text-gray-400 hover:text-gray-500"}
									/>
								}>
								Chi tiết
							</Button>
						),
					},
				];
				return (
					<Dropdown menu={{ items }}>
						<MoreOutlined
							style={{ fontSize: "16pt" }}
							className="cursor-pointer "
						/>
					</Dropdown>
				);
			},
		},
	];

	//setdisable
	useEffect(() => {
		if (
			selectDeliveryArr.length > 0 &&
			checkSameActionCode(selectDeliveryArr, "ACTION_DELIVERED")
		) {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	}, [selectDeliveryArr, reload]);

	// Item Button render

	const ItemButtonSubmit = ({
		skey,
		action,
		title,
		htmlType = "button",
		handleClick,
	}: DeliveryButtonSubmit) => {
		return (
			<>
				<Button
					block
					onClick={() => {
						if (action === "ACTION_APPROVE_RECEIVE_COD") {
							// setShowModalConfirm(true);
							setActionCode("ACTION_APPROVE_RECEIVE_COD");
							setShowModal(true);
						} else if (action === "ACTION_APPROVE_TIPS") {
							// setShowModalTip(true);
							setActionCode("ACTION_APPROVE_TIPS");
							setShowModal(true);
						} else if (action === "ACTION_APPROVE_REFUND") {
							// setShowModalRefund(true);
							setActionCode("ACTION_APPROVE_REFUND");
							setShowModal(true);
						} else if (action === "ACTION_CANCEL") {
							// setShowModalCancel(true);
							setActionCode("ACTION_CANCEL");
							setShowModal(true);
						}
					}}
					htmlType={htmlType}>
					{title.toLocaleUpperCase()}
				</Button>
			</>
		);
	};

	const getMenuAction = () => {
		const items: MenuProps["items"] = [
			{
				key: "1",
				label: (
					<ItemButtonSubmit
						action={ActionDelivery.ACTION_APPROVE_RECEIVE_COD}
						title={t("delivery:accountant.paid")}
						skey="2"
					/>
				),
			},
		];

		setItems(items);
		return items;
	};

	const handleActionCancel = () => {
		setShowModal(false);
	};

	const handleSuccess = (func: any) => {
		if (func) {
			func();
		}
		setShowModal(false);
	};

	function convertArrSelectDeliveryToStringIDs(arr: DeliveryModel[]) {
		return arr.map((item) => item.id).join(",");
	}

	return (
		<>
			<DeliveryAccountantListHeader />
			<DeliveryAccountantListFilter
				defaultFilters={defaultFilters}
				filters={filters}
				setFilters={setFilters}
				total={total}
			/>

			<DeliverySumByManager
				type="accountant"
				setFilters={setFilters}
				filters={filters}
				defaultFilters={defaultFilters}
			/>

			<Row justify="end" className={disabled ? "invisible" : ""}>
				<Col className="pr-4">
					<Dropdown
						trigger={["hover", "click"]}
						placement="bottomLeft"
						menu={{ items }}
						disabled={disabled}>
						{/* <Button type="primary" block>
              {t("delivery:accountant.confirm_uppercase")}
            </Button> */}
						<ButtonAction
							matrix_action={Delivery.FULL_MATRIX_ACTION}
							action_code={"ACTION_APPROVE_RECEIVE_COD"}
							renderModal={({
								nextAction,
								closeModal,
								gpsRequire,
								noteRequire,
								uploadRequire,
							}) => {
								return (
									<div>
										<PostAction
											key={"1"}
											action={nextAction}
											gpsRequire={gpsRequire}
											noteRequire={noteRequire}
											uploadRequire={uploadRequire}
											data={{
												deliveries_id:
													convertArrSelectDeliveryToStringIDs(
														selectDeliveryArr
													),
											}}
											handlePostActionSuccess={() => {
												closeModal();
												message.success({
													content: "Thao tác thành công !",
												});
												fetchData && fetchData();
											}}
										/>
									</div>
								);
							}}
							single_action={{
								action_code: [{ actionCode: "ACTION_APPROVE_RECEIVE_COD" }],
								prefix_translate: "delivery:newstatus",
							}}
							prefix_translate_button="delivery"
						/>
					</Dropdown>
				</Col>
			</Row>

			<PageDataTable<FilterDelivery, DeliveryModel, DeliveryCollection>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					tableColumnToggleKey: "deliveryaccountant",
				}}></PageDataTable>

			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>

			<Modal
				title={t("delivery:detail.order_confirm")}
				open={showModal}
				centered
				okButtonProps={{ style: { display: "none" } }}
				closable
				destroyOnClose={true}
				// onOk={handleActionComplete}
				onCancel={handleActionCancel}>
				<ConfirmManyAction
					action_code={"ACTION_APPROVE_RECEIVE_COD"}
					noteRequire={true}
					GpsRequire={true}
					UpLoadRequire={false}
					widthImg={370}
					heightImg={370}
					selectDeliveryArr={selectDeliveryArr}
					handleSuccess={() => {
						handleSuccess(() => {
							setReload();
						});
					}}></ConfirmManyAction>
			</Modal>

			<PhoneBookModal
				id={modalId}
				visible={showContactFields}
				setVisible={(isVisible) => {
					setShowContactFields(isVisible);
				}}
			/>
		</>
	);
};

export default DeliveryAccountantsList;
