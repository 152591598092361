import { Tag } from "antd";
import ProductInventoryCheckingCollection from "common/collections/ProductInventoryCheckingCollection";
import Role from "common/constants/Role";
import ProductInventoryCheckingModel from "common/models/ProductInventoryCheckingModel";
import ProductInventoryCheckingRepository from "common/repositories/ProductInventoryCheckingRepository";
import { FilterProductInventoryChecking } from "common/types/ProductInventoryChecking";
import { TableColumnsType } from "common/types/Table";
import { WarehouseJson } from "common/types/Warehouse";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import ProductInventoryCheckingListHeader from "features/productinventorychecking/list/ProductInventoryCheckingListHeader";
import useDatabaseTable from "hooks/useDatabaseTable";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ProductInventoryCheckingListFilter from "./ProductInventoryCheckingListFilter";
import Helper from "common/utils/helper";

const ProductInventoryCheckingList = () => {
	const { t } = useTranslation();

	//fecth data warehouse
	const [warehouseItemsWithLimit] = useDatabaseTable<WarehouseJson>(
		"warehouse",
		Role.INVENTORY_VIEW
	);

	//////////////////////////////////////////
	//Filtering
	const defaultFilters: FilterProductInventoryChecking = useMemo(
		() => ProductInventoryCheckingRepository.getDefaultFilters(),
		[]
	);
	const [filters, setFilters] =
		useStateFilter<FilterProductInventoryChecking>(defaultFilters);
	useFilterLocation(defaultFilters, filters);

	//////////////////////////////////////////
	//Fetch data from this collections
	const [total, setTotal] = useState(0);
	const [dataSource, setDataSource] = useState<ProductInventoryCheckingModel[]>(
		[]
	);

	const fetchData =
		useCallback(async (): Promise<ProductInventoryCheckingCollection> => {
			let collection = await new ProductInventoryCheckingRepository().getItems({
				filters,
			});
			setDataSource(collection.items);
			setTotal(collection.total);

			return collection;
		}, [filters]);

	//Table columns
	const columns: TableColumnsType<ProductInventoryCheckingModel> = [
		{
			toggletype: "ignore",
			title: t("productinventorychecking:id"),
			key: "code",
			align: "left",
			width: 300,
			render: (_, record) => {
				return (
					<>
						<TableLinkText
							link={"/productinventorychecking/edit/id/" + record.id}>
							{record.code}
						</TableLinkText>
					</>
				);
			},
		},
		{
			title: t("productinventorychecking:warehouse"),
			key: "warehouse_id",
			width: 200,
			render: (warehouse_id: number) => {
				const found = warehouseItemsWithLimit.find(
					(i) => i.id === warehouse_id
				);
				if (typeof found !== "undefined") {
					return <span>{found.name}</span>;
				} else {
					return "ID #" + warehouse_id;
				}
			},
		},
		// {
		// 	title: t("productinventorychecking:sku_group"),
		// 	key: "sku_group",
		// 	width: 150,
		// 	align: "center",
		// },

		// {
		// 	title: t("Tình trạng"),
		// 	key: "warning",
		// 	sortby: "warning",
		// 	render: (warning: number) => {
		// 		let statusInfo = ProductInventoryCheckingModel.getWarning(warning);
		// 		return <Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>;
		// 	},
		// 	width: 120,
		// },

		{
			title: t("common:status"),
			key: "status",
			sortby: "status",
			render: (status: number) => {
				let statusInfo = ProductInventoryCheckingModel.getStatus(status);
				return <Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>;
			},
			width: 180,
		},
		{
			title: t("common:date_created"),
			key: "date_created",
			width: 120,
			render: (date_created) => Helper.formatTimestampToText2(date_created),
		},
		{
			title: " ",
			key: "actions",
			toggletype: "trigger",
			align: "right",
			fixed: "right",
			width: 120,
			render: (_: any, record: ProductInventoryCheckingModel) => (
				<RoleCheck roles={[Role.INVENTORY_STOCK_CHECK]} hideOnFail >

					<TableEdit label="Chi tiết" link={"/productinventorychecking/edit/id/" + record.id} />
					<TableInfo record={record}></TableInfo>
				</RoleCheck>
			),
		},
	];

	return (
		<>
			<ProductInventoryCheckingListHeader />
			<ProductInventoryCheckingListFilter
				filters={filters}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
				total={total}
			/>
			<PageDataTable<
				FilterProductInventoryChecking,
				ProductInventoryCheckingModel,
				ProductInventoryCheckingCollection
			>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					tableColumnToggleKey: "productinventorychecking",
				}}></PageDataTable>

			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>
		</>
	);
};

export default ProductInventoryCheckingList;
