import * as XLSX from "xlsx";
import { PromotionCouponJsonAddImport } from "common/types/PromotionCoupon";
import { Button, Checkbox, Col, notification, Row } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Upload } from "antd";
import { useState } from "react";

const { Dragger } = Upload;

export default function ExcelReaderCodes({
	onSubmit,
}: {
	onSubmit?: (data: PromotionCouponJsonAddImport[] , stopIfError : boolean) => Promise<any>;
}) {
	const [fileExcel, setFileExcel] = useState<File | null>(null);
	const [stopIfError, setStopIfError] = useState<boolean>(true);

	const handleFileUpload = async (files: File[]): Promise<void> => {
		try {
			const file = files?.[0]; // Kiểm tra nếu có file được tải lên
			if (!file) return;
			setFileExcel(file);
		} catch (error) {
			console.error(error);
			notification.error({ message: JSON.stringify(error) });
		}
	};

	const handleFileUploadProcess = async (files: File): Promise<void> => {
		try {
			const file = files; // Kiểm tra nếu có file được tải lên
			if (!file) return;
			const reader = new FileReader();
			reader.onload = async (event: ProgressEvent<FileReader>) => {
				const data = new Uint8Array(event.target?.result as ArrayBuffer);
				const workbook = XLSX.read(data, { type: "array" });
				const sheetName = workbook.SheetNames[0];
				const sheet = workbook.Sheets[sheetName];
				
				const headers = XLSX.utils.sheet_to_json(sheet, { header: 1, range: "A1:Z1" })[0] as string[]
				const headerLowCase = headers.map(text=>text.toLowerCase())
				if (!headerLowCase.includes("Mã".toLowerCase()) || !headerLowCase.includes("Số lần áp dụng".toLowerCase())) {
				  notification.error({message : "File không hợp lệ: Thiếu cột 'Mã' hoặc 'Số lần áp dụng'"})
				  return;
				}


				const range = XLSX.utils.decode_range(sheet['!ref'] || '');
				const totalRows = range.e.r + 1; 
				let startRow = 1;
				const chunkSize = 200;

				while (startRow < totalRows) {
					const start = `A${startRow}`;
					const end = `B${startRow + chunkSize - 1}`;

					const rowsChunk = XLSX.utils.sheet_to_json(sheet, {
						defval: null,
						range: `${start}:${end}`,
					});
					const codeList: PromotionCouponJsonAddImport[] = rowsChunk.map(
						(row: any) => {
							const keys = Object.keys(row);
							return {
								code: row?.[keys?.[0]] || "", // Cột 1 - mã
								max_count_apply: row?.[keys?.[1]] || 0, // Cột 2 - số lần áp dụng
							};
						}
					);
					onSubmit?.(codeList.filter((code) => code.code) , stopIfError);
					startRow += chunkSize;
				}
			};
			reader.readAsArrayBuffer(file);
		} catch (error) {
			console.error(error);
			notification.error({ message: JSON.stringify(error) });
		}
	};

	function handleConfirm() {
		if (fileExcel) {
			handleFileUploadProcess(fileExcel)
		}

	}


	const props: UploadProps = {
		name: "file",
		accept: ".xlsx, .xls",
		multiple: false,
		customRequest: (options) => {
			setTimeout(() => {
				options.onSuccess?.("ok");
			}, 0);

			return true;
		},
		showUploadList: true,

		onChange(info) {
			const updatedFileList = info.fileList.map((file) => {
				if (file.status !== "done") {
					file.status = "done";
				}
				return file;
			});

			const files = updatedFileList.map((file) => file.originFileObj as File);
			handleFileUpload(files);
		},

		onDrop(e) {
			const files = Array.from(e.dataTransfer.files);
			handleFileUpload(files);
		},
	};
	return (
		<Row>
			<Col span={24}>
				<Dragger {...props}>
					<p className="ant-upload-drag-icon">
						<InboxOutlined />
					</p>
					<p className="ant-upload-text">
						{/* Nhấp hoặc kéo tệp vào khu vực này để tải lên */}
					</p>
				</Dragger>
			</Col>
			<div className=" flex gap-1 my-2 w-full mt-8">
				<Checkbox
					checked={stopIfError}
					onChange={(e) => {
						setStopIfError(e.target.checked);
					}}
				/>
				<span>Dừng khi có lỗi</span>
			</div>
			<div className=" mt-8 flex  justify-end w-full">
				<Button
				type="primary"
					disabled={!fileExcel}
					onClick={() => {
						handleConfirm()
					}}
					htmlType="button">
					Tiến hành
				</Button>
			</div>
		</Row>
	);
}
