import { Card, Divider, Flex, Space, Tag, Tooltip, Typography } from "antd";
import Role from "common/constants/Role";
import TextDateTime from "components/TextDateTime";
import TextStore from "components/TextStore";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useEditingSaleOrderStore from "zustands/useEditingSaleOrder";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import {
	IconBuildingStore,
	IconClock,
	IconPhone,
	IconUser,
} from "@tabler/icons-react";
import EcomRepository from "common/repositories/EcomRepository";
import { Ecomplatform } from "common/types/Ecom";
import OrderModel from "common/models/OrderModel";
import { OrderJson, SERVICE_TYPE } from "common/types/Order";
import SelectEmployee from "features/employee/SelectEmployee";
import TextUser from "components/TextUser";
import TextCustomer from "components/TextCustomer";
import {
	AppstoreOutlined,
	BorderOutlined,
	CarryOutOutlined,
	CheckCircleFilled,
	InfoOutlined,
} from "@ant-design/icons";

const OrderFormSectionInfo = ({
	orderDefault,
}: {
	orderDefault?: OrderJson;
}) => {
	const { t } = useTranslation();
	const orderState = useEditingSaleOrderStore((state) => state.order);

	const order = orderDefault || orderState;
	const iconProps = { size: 16, className: "mr-1 -mt-0.5" };

	const accountRoleSubjects = useLoginAccountStore((state) =>
		state.account.role.map((r) => r.subject_id)
	);
	const checkRoleCustomerManage = useMemo(() => {
		return (
			[Role.CUSTOMER_MANAGE].filter((r) => accountRoleSubjects.includes(r))
				.length > 0
		);
	}, [accountRoleSubjects]);

	const statusPayment = OrderModel.getStatusPayment(order.payment_status);

	const statusDeliver = OrderModel.getStatusDelivery(order.delivery_status);
	const statusInven = OrderModel.getStatusInven(order.inventory_status);

	return (
		<div className="leading-7 opacity-80 hover:opacity-100">
			<Flex vertical gap={8}>
				<Card title="Xác thực đơn hàng">
					<Flex align="center" justify="start" gap={4}>
						<span>TT thanh toán:</span>
						<Tag className=" h-fit" bordered color={statusPayment?.color}>
							{statusPayment?.label}
						</Tag>
					</Flex>
					<Flex align="center" justify="start" gap={4}>
						<span>TT phiếu kho:</span>
						<Tag className=" h-fit" bordered color={statusInven?.color}>
							{statusInven?.label}
						</Tag>
					</Flex>
					<Flex align="center" justify="start" gap={4}>
						<span>TT giao hàng:</span>
						<Tag className=" h-fit" bordered color={statusDeliver?.color}>
							{statusDeliver?.label}
						</Tag>
					</Flex>
				</Card>
				<Card
					title={
						<Space>
							<IconUser {...iconProps} /> Thông tin người mua
						</Space>
					}
				>
					<TextCustomer id={order.customer_id}></TextCustomer>
					{/* <div>
						{checkRoleCustomerManage ? (
							<Link
								className="text-blue-700 hover:text-blue-500"
								to={"/customer?id=" + order.customer_id}
								target="_blank">
								{order.billing_fullname}&nbsp;
								<Tooltip title={t("customer:id")}>
									<span className="text-gray-400 cursor-default hover:text-black">
										#{order.customer_id}
									</span>
								</Tooltip>
							</Link>
						) : (
							<>
								{order.billing_fullname}&nbsp;
								<Tooltip title={t("customer:id")}>
									<span className="text-gray-400 cursor-default hover:text-black">
										#{order.customer_id}
									</span>
								</Tooltip>
							</>
						)}
						<br />
						<span className="text-gray-400 mr-1">
							<IconPhone {...iconProps} />
							{t("order:billing_phone")}:
						</span>{" "}
						{order.billing_phone}
					</div> */}
				</Card>
				<Card
					title={
						<Space>
							<IconUser {...iconProps} /> Thông tin người bán
						</Space>
					}
				>
					<TextUser id={order.seller_id} zeroLabel="Trống"></TextUser>
				</Card>
				<Card
					title={
						<Space>
							<IconUser {...iconProps} /> Thông tin người giới thiệu
						</Space>
					}
				>
					{order.referral_name.length > 0 ? order.referral_name : "Trống"}
				</Card>
				<Card
					title={
						<Space>
							<IconBuildingStore {...iconProps} /> {t("order:store")}
						</Space>
					}
				>
					<TextStore id={order.store_id} />
				</Card>
			</Flex>
		</div>
	);
};

export default OrderFormSectionInfo;
