import { DataZoneBD } from "common/types/Event";

import { useMemo } from "react";
import SectionProductDB from "./components/SectionProductDB";
import SectionBannerDB from "./components/SectionBannerDB";
import SectionSupportDB from "./components/SectionSupportDB";
import SectionSubbannerDB from "./components/SectionSubbannerDB";
import SectionProductly from "./components/lastyear/SectionProductLY";
import SectionBrandList from "./components/lastyear/SectionBrandListLY";

export default function ShopEventBD11Form({
	data,
	type = "add",
	onFinish,
}: {
	type: "add" | "edit";
	data: DataZoneBD;
	onFinish: (data: DataZoneBD) => void;
}) {
	const onSubmit = async (formData: any) => {
		onFinish({
			...data,
			zone_data: formData,
		});
	};

	const formActive = useMemo(() => {
		switch (data.zone_name) {
			case "banner":
				return <SectionBannerDB data={data.zone_data} onSubmit={onSubmit} />;
			case "product":
				return <SectionProductly data={data.zone_data} onSubmit={onSubmit} />;
			case "sub_banner":
				return <SectionSubbannerDB data={data.zone_data} onSubmit={onSubmit} />;
			case "support":
				return <SectionSupportDB data={data.zone_data} onSubmit={onSubmit} />;

			case "brandlist":
				return <SectionBrandList data={data.zone_data} onSubmit={onSubmit} />;
			default:
				break;
		}
	}, [data.zone_name, JSON.stringify(data.zone_data)]);

	return <>{formActive}</>;
}
