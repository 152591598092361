import { Nav } from "common/types/PageNavigation";
import PageHeader from "components/page/PageHeader";
import { useTranslation } from "react-i18next";

const ProductFormHeader = ({
	isEditing,
	isCopy,
	isParent,
}: {
	isEditing: boolean;
	isCopy?: boolean;
	isParent?: boolean;
}) => {
	const { t } = useTranslation();

	const nav: Nav = [
		{
			text: t("product:heading_nav"),
			link: "/product",
		},
		{
			text: t(isEditing ? "product:heading_edit" : "product:heading_add"),
			link: "",
		},
	];

	return (
		<>
			<PageHeader
				nav={nav}
				heading={t(
					isCopy
						? "product:copy"
						: isParent
						? "product:parent"
						: isEditing
						? "product:heading_edit"
						: "product:heading_add"
				)}
				siteMenuSelectedKey="/product"
				siteMenuHiddenBackLink="/product"
				siteMenuHidden></PageHeader>
		</>
	);
};

export default ProductFormHeader;
