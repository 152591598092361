import { DatePickerProps, Form, Input } from "antd";
import { Filter } from "common/types/Filter";
import DateFormFilter from "components/datetime/DateFormFilter";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const ContactMailListFilter = <F extends Filter>({
  total,
  filters,
  setFilters,
  defaultFilters,
}: {
  total: number;
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
}) => {
  const { t } = useTranslation();

  const getValueFilter = useMemo(() => {
    const entries = Object.entries(filters).filter(
      ([key, val]) => key === "date_created"
    );

    const [createdVal] = entries.map(([key, val]) => Number(val));

    if (createdVal > 0) {
      const createdDate = dayjs.unix(createdVal).format("DD/MM/YYYY");
      return dayjs(createdDate, "DD/MM/YYYY");
    }

    return null;
  }, [filters]);

  const onChange: DatePickerProps["onChange"] = (
    value: DatePickerProps["value"]
  ) => {
    if (typeof value !== "undefined" && value !== null) {
      setFilters({
        ...filters,
        date_created: value.unix(),
      });
    } else {
      setFilters({
        ...filters,
        date_created: 0,
      });
    }
  };

  return (
    <>
      <PageDataFilterForm
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        convertValueByKeyFilter={[
          // "office_id",
          // "department_id",
          // "type",
          // "status",
        ]}
      >
        <Form.Item name="keyword">
          <Input
            allowClear
            placeholder={t("Nhập từ khóa")}
          />
        </Form.Item>
        <DateFormFilter
          value={getValueFilter}
          allowClear={true}
          onChange={onChange}
          mode="single"
          format="DD/MM/YYYY"
          className="w-full"
          placeholder={t("comment:filter.date_created")}
        />
      </PageDataFilterForm>
    </>
  );
};

export default ContactMailListFilter;
