import { Col, Form, FormInstance, Input, Row, Typography } from "antd";
import DeliveryCollection from "common/collections/DeliveryCollection";
import InventoryReceipt from "common/constants/InventoryReceipt";
import Warehouse from "common/constants/Warehouse";
import InventoryReceiptModel from "common/models/InventoryReceiptModel";
import DeliveryRepository from "common/repositories/DeliveryRepository";
import StoreWarehouseRepository from "common/repositories/StoreWarehouseRepository";
import { DeliveryJson, FilterDelivery } from "common/types/Delivery";
import {
	InventoryReceiptJson,
	InventoryReceiptSourceType,
	InventoryReceiptTargetType,
} from "common/types/InventoryResceipt";
import FormSelect from "components/form/FormSelect";
import SearchComponent from "components/search/SearchLayout";
import SupplierSearchSelect from "components/SupplierSearchSelect";
import WarehouseFormSelect from "features/warehouse/WarehouseFormSelect";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type Props = {
	disabled?: boolean;
	model: InventoryReceiptJson;
	form: FormInstance<any>;
};
const InventoryReceiptTarget = ({ disabled, model, form }: Props) => {
	const { t } = useTranslation();

	const [isRequire, setIsRequire] = useState({
		targetId: true,
	});

	const [targetType, setTargetType] = useState(model.target.type);

	/**
	 * TODO: Custom hook
	 * This logic is for Inventory Receipt Direction 1 Type 25
	 *
	 * If source type is Warehouse, should get the Store of that Warehouse
	 * And filter for WarehouseFormSelect with the Store Id and Warehouse Type = TYPE_DEFECT
	 */
	const [storeIds, setStoreIds] = useState<number[]>([]);
	const [warehouseDefectType, setWarehouseDefectType] = useState(-1);

	const typeWatch = Form.useWatch("type", form);
	const targetWatch = Form.useWatch("target", form);
	const sourceWatch = Form.useWatch("source", form);

	const resetLogicState = useCallback(() => {
		setStoreIds([]);
		setWarehouseDefectType(-1);
	}, []);

	const fetchStoreWarehouse = useCallback(async () => {
		const response = await new StoreWarehouseRepository().getItems({
			filters: {
				...StoreWarehouseRepository.getDefaultFilters(),
				warehouse_id: sourceWatch.id,
			},
		});

		if (!response.hasError()) {
			setStoreIds(
				response.items.map((storeWarehouse) => storeWarehouse.store_id)
			);
			setWarehouseDefectType(Warehouse.TYPE_DEFECT);
		}
	}, [sourceWatch]);

	useEffect(() => {
		if (model.direction !== InventoryReceipt.DIRECTION_OUTPUT)
			return resetLogicState();
		if (typeWatch !== InventoryReceipt.TYPE_OUT_EXPORT_CANCEL)
			return resetLogicState();
		if (
			targetWatch?.type === null ||
			targetWatch?.type !== InventoryReceiptTargetType.WAREHOUSE
		)
			return resetLogicState();
		if (
			sourceWatch?.type === null ||
			sourceWatch?.type !== InventoryReceiptSourceType.WAREHOUSE
		)
			return resetLogicState();
		if (sourceWatch?.id === null || sourceWatch?.id === 0)
			return resetLogicState();

		fetchStoreWarehouse();
	}, [
		model.direction,
		targetWatch?.type,
		sourceWatch,
		resetLogicState,
		fetchStoreWarehouse,
	]);

	useEffect(() => {
		if (model.direction === InventoryReceipt.DIRECTION_OUTPUT) {
			// form.setFieldValue(["target", "id"], null);
		}
	}, [model.direction, sourceWatch]);

	useEffect(() => {
		if (model.target.id) {
			form.setFieldValue(["target", "id"], Number(model.target.id));
		}
	}, [typeWatch, model.target.id, model.id]);
	/** End Inventory Receipt Direction 1 Type 25 logic */

	const renderField = useMemo(() => {
		switch (targetType) {
			case InventoryReceiptTargetType.WAREHOUSE: {
				return (
					<WarehouseFormSelect
						rules={[
							{
								required: true,
								message: "Vui lòng chọn",
							},
						]}
						label="Chọn kho"
						name={["target", "id"]}
						disabled={disabled}
						storeIds={storeIds}
						warehouseDefectType={warehouseDefectType}
					/>
				);
			}
			case InventoryReceiptTargetType.SUPPLIER:
				return (
					<SupplierSearchSelect
						labelAlign="left"
						rules={[
							{
								required: true,
								message: "Vui lòng chọn",
							},
						]}
						label="Chọn nhà cung cấp"
						name={["target", "id"]}
						disabled={disabled}
					/>
				);
			case InventoryReceiptTargetType.DELIVERY:
				return (
					<SearchComponent<DeliveryJson, FilterDelivery, DeliveryCollection>
						fetchRepository={(filers) => {
							return new DeliveryRepository().getItemsDeilveryManager({
								filters: filers,
							});
						}}
						defaultFilters={DeliveryRepository.getDefaultFilters()}
						formItemProps={{
							label: "Mã vận đơn",
							name: ["target", "id"],
							rules: [
								{
									required: true,
									message: t("common:form.required"),
								},
							],
						}}
						disabled={disabled}
						form={form}
						keyValue="id"
						showFilters={["keyword"]}
						defaultUseFilters={["keyword"]}
						keyFetchInitData="keyword"
						renderLabel={(item) => {
							return (
								<>
									<Typography.Text>Vận đơn - {item.id}</Typography.Text>
									<Typography.Text mark copyable>
										{" "}
										{item.resource_id}
									</Typography.Text>
								</>
							);
						}}
					/>
					// <DeliverySearch
					// 	labelAlign="left"
					// 	rules={[
					// 		{
					// 			required: true,
					// 			message: "Vui lòng chọn",
					// 		},
					// 	]}
					// 	label="Mã vận đơn"
					// 	name={["target", "id"]}
					// />
				);

			default:
				return (
					<Form.Item
						className="mb-0"
						rules={[
							{
								required: isRequire.targetId,
								message: "Yêu cầu nhập",
							},
						]}
						name={["target", "id"]}
						label="Mã">
						<Input disabled={disabled} placeholder="Giá trị"></Input>
					</Form.Item>
				);
		}
	}, [targetType, disabled, storeIds, warehouseDefectType]);

	return (
		<div>
			<Row gutter={8}>
				<Col span={12}>
					<Form.Item
						className="mb-0"
						rules={[
							{
								required: true,
								message: "Yêu cầu nhập",
							},
						]}
						name={["target", "type"]}
						label="Loại">
						<FormSelect
							className="mb-0"
							disabled={disabled}
							onChange={(val) => {
								if (typeof val === "number") {
									setTargetType(Number(val));
								}
								if (val === InventoryReceiptTargetType.OTHER) {
									setIsRequire((prev) => ({ ...prev, targetId: false }));
								}
							}}
							options={[
								{ label: "Chọn loại", value: 0, disabled: true },
								...InventoryReceiptModel.getTargetTypeOpntion(),
							]}></FormSelect>
					</Form.Item>
				</Col>
				<Col span={12}>{renderField}</Col>
			</Row>

			{model.target.type === InventoryReceiptTargetType.DELIVERY && (
				<Link target="_blank" to={`/delivery/edit/id/${model.target.id}`}>
					Chi tiết vận đơn # {model.target.id}
				</Link>
			)}
		</div>
	);
};

export default InventoryReceiptTarget;
