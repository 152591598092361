import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MoreOutlined } from "@ant-design/icons";
import useStateFilter from "hooks/useStateFilter";
import { IconPin } from "@tabler/icons-react";
import useFilterLocation from "hooks/useFilterLocation";
import React, { useCallback, useMemo, useState } from "react";
import { Dropdown, MenuProps, TableColumnsType, Tag, message } from "antd";

import { FilterNotification } from "common/types/Notification";
import NotificationModel from "common/models/NotificationModel";
import NotificationCollection from "common/collections/NotificationCollection";
import NotificationRepository from "common/repositories/NotificationRepository";

import RoleCheck from "components/RoleCheck";
import TablePin from "../components/TablePin";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableStatus from "../components/TableStatus";
import PageDataTable from "components/page/PageDataTable";
import NotificationListHeader from "./NotificationListHeader";
import NotificationListFilter from "./NotificationListFilter";
import PageDataPagination from "components/page/PageDataPagination";
import Helper from "common/utils/helper";

const NotificationList = () => {
  const { t } = useTranslation();

  // default filter
  const defaultFilters = useMemo(() => {
    return NotificationRepository.getDefaultFilters();
  }, []);

  //state
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<NotificationModel[]>([]);
  const [filters, setFilters] = useStateFilter(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //table columns
  const columns: TableColumnsType<NotificationModel> = [
    {
      title: t("common:columns.id"),
      key: "id",
      width: 80,
      render: (id: number) => <Tag color="blue">{id}</Tag>,
    },
    {
      title: t("notification:title"),
      key: "meta_title",
      width: 100,
      render: (meta_title: string) => (
        <p className="line-clamp-2 m-0">{meta_title}</p>
      ),
    },
    {
      title: t("notification:summary"),
      key: "meta_summary",
      width: 200,
      render: (meta_summary: string) => (
        <p className="line-clamp-2 m-0">{meta_summary}</p>
      ),
    },
    {
      title: t("notification:type"),
      key: "type",
      width: 100,
      render: (type: number) => (
        <>
          <Tag color={type === 1 ? "volcano" : "purple"}>
            {NotificationModel.getTypeNotification(type)}
          </Tag>
        </>
      ),
    },
    {
      title: t("notification:pin"),
      key: "pin",
      width: 100,
      render: (type: number) => (
        <>
          {type === 1 ? (
            <Tag color="blue">{t("notification:pinon")}</Tag>
          ) : (
            <Tag color="magenta">{t("notification:pinoff")}</Tag>
          )}
        </>
      ),
    },
    {
      title: t("notification:status"),
      key: "status",
      width: 100,
      render: (type: number) => (
        <>
          {type === 1 ? (
            <Tag color="green">{t("notification:show")}</Tag>
          ) : (
            <Tag color="red">{t("notification:hide")}</Tag>
          )}
        </>
      ),
    },
		{
			title: t("common:date_created"),
			key: "date_created",
			width: 120,
			render: (date_created) => Helper.formatTimestampToText2(date_created),
		},
    {
      title: "",
      key: "id",
      align: "right",
      fixed: "right",
      width: 100,
      render: (id: number, record: NotificationModel) => {
        const items: MenuProps["items"] = [
          {
            key: "1",
            label: (
              <TableEdit
                className="w-full text-center"
                link={`/notification/edit/id/${record.id}`}
              />
            ),
          },
          {
            key: "2",
            label: (
              <TablePin
                className="h-full w-full"
                error_translate_prefix="notification:form.error"
                onDeleteCallback={(id) => {
                  // setDataSource(dataSource.filter((item) => item.id !== id));
                  fetchData();
                }}
                repository={new NotificationRepository()}
                id={record.id}
                text={
                  record.pin === 0
                    ? t("notification:pin")
                    : t("notification:pinnedoff")
                }
                icon_custom={
                  <IconPin
                    className=" text-gray-400 hover:text-red-500"
                    size="18"
                  />
                }
                record={record}
                title={
                  record.pin === 0
                    ? t("notification:table.pin.yes")
                    : t("notification:table.pin.no")
                }
              />
            ),
          },
          {
            key: "3",
            label: (
              <TableStatus
                className="h-full w-full"
                error_translate_prefix="notification:form.error"
                onDeleteCallback={(id) => {
                  // setDataSource(dataSource.filter((item) => item.id !== id));
                  fetchData();
                }}
                repository={new NotificationRepository()}
                id={record.id}
                text={
                  record.status === 3
                    ? t("notification:show")
                    : t("notification:hide")
                }
                record={record}
                title={
                  record.status === 3
                    ? t("notification:table.eye.yes")
                    : t("notification:table.eye.no")
                }
              />
            ),
          },
        ];
        return (
          <RoleCheck roles={[]} hideOnFail>
            <Dropdown
              menu={{ items }}
              placement="bottomRight"
              className="cursor-pointer"
            >
              <MoreOutlined
                style={{ fontSize: "16pt" }}
                className="cursor-pointer "
              />
            </Dropdown>
            <TableInfo record={record} />
          </RoleCheck>
        );
      },
    },
  ];

  //function
  const fetchData = useCallback(async (): Promise<NotificationCollection> => {
    const response = await new NotificationRepository().getItems({
      filters: filters,
    });

    if (response) {
      setTotal(response.total);
      setDataSource(response.items);
    } else {
      message.error(t("common:error.error_fetching_data"));
    }

    return response;
  }, [filters]);

  return (
    <div>
      <NotificationListHeader />
      <NotificationListFilter
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      />
      <PageDataTable<
        FilterNotification,
        NotificationModel,
        NotificationCollection
      >
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "notification",
        }}
      />
      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </div>
  );
};

export default NotificationList;
