import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { LocationJson } from "common/types/Location";
import BaseModel from "./BaseModel";

const defaultData: LocationJson = {
  location_id: 0,
	parent_id: 0,
	name: "",
	code: "",
	address: "",
	primary: 0,
	type: 0,
	deprecated: 0,
	is_deleted: 0,
};

class LocationModel
  extends BaseModel
  implements BaseModelJson<LocationJson>
{
  location_id: number = defaultData.location_id;
  parent_id: number = defaultData.parent_id;
  name: string = defaultData.name;
  code: string = defaultData.code;
  address: string = defaultData.address;
  primary: number = defaultData.primary;
  type: number = defaultData.type;
  deprecated: number = defaultData.deprecated;
  is_deleted: number = defaultData.is_deleted;

  constructor(json: LocationJson) {
    super();
    Object.assign(this, { ...defaultData, ...json });
  }
  static getDefaultData(): LocationJson {
    return { ...defaultData };
  }
  toJson(): LocationJson {
    return JSON.parse(JSON.stringify({ ...this }));
  }
}

export default LocationModel;
