import WarehouseCollection from "common/collections/WarehouseCollection";
import Role from "common/constants/Role";
import WarehouseModel from "common/models/WarehouseModel";
import WarehouseRepository from "common/repositories/WarehouseRepository";
import { TableColumnsType } from "common/types/Table";
import { FilterWarehouse } from "common/types/Warehouse";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import WarehouseListHeader from "features/warehouse/list/WarehouseListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import WarehouseListFilter from "./WarehouseListFilter";
import { Button, Checkbox, Tag, Tooltip, Typography } from "antd";
import { IconTag } from "@tabler/icons-react";
import WarehouseFormTag from "../WarehouseFormTag";
import WarehouseMarkTesterTagBtn from "../form/WarehouseMarkTesterTagBtn";
import useSettingValue from "hooks/useSettingValue";
import SettingApi from "common/constants/Setting";
import Warehouse from "common/constants/Warehouse";
import Helper from "common/utils/helper";

const WarehouseList = () => {
	const { t } = useTranslation();
	const { setting: settingTesterTagType } = useSettingValue(
		SettingApi.KEY.setting_tag_warehouse_tester_type,
		SettingApi.VALUE.setting_tag_warehouse_tester_type
	);
	const { setting: settingTesterTag } = useSettingValue(
		SettingApi.KEY.setting_tag_warehouse_tester,
		SettingApi.VALUE.setting_tag_warehouse_tester
	);

	//////////////////////////////////////////
	//Filtering
	const defaultFilters: FilterWarehouse = useMemo(
		() => WarehouseRepository.getDefaultFilters(),
		[]
	);
	const [filters, setFilters] = useStateFilter<FilterWarehouse>(defaultFilters);
	useFilterLocation(defaultFilters, filters);
	//////////////////////////////////////////

	const [editingTagId, setEditingTagId] = useState(0);
	const [openConfigTag, setOpenConfigTag] = useState(false);

	//////////////////////////////////////////
	//Fetch data from this collections
	const [total, setTotal] = useState(0);
	const [dataSource, setDataSource] = useState<WarehouseModel[]>([]);
	const fetchData = useCallback(async (): Promise<WarehouseCollection> => {
		let collection = await new WarehouseRepository().getItems({
			filters,
		});
		setDataSource(collection.items);
		setTotal(collection.total);
		return collection;
	}, [filters]);

	const handleOpenConfigTag = (id: number) => {
		setOpenConfigTag(true);
		setEditingTagId(id);
	};

	//////////////////////////////////////////
	//Table columns
	const columns: TableColumnsType<WarehouseModel> = [
		{
			title: t("common:sid"),
			key: "id",
			align: "center",
			width: 150,
			toggletype: "extend",

			render: (id, record) => {
				return (
					<TableLinkText
						title={t("common:table.edit")}
						link={"/warehouse/edit/id/" + record.id}>
						{id}
					</TableLinkText>
				);
			},
		},
		{
			title: t("warehouse:code"),
			key: "code",
			sortby: "code",
			width: 250,
			render(code) {
				return (
					<Tag color="blue">
						<Typography.Text
							className="whitespace-nowrap w-full"
							ellipsis
							copyable
							title={code}>
							{code}
						</Typography.Text>
					</Tag>
				);
			},
		},
		{
			title: t("warehouse:name"),
			key: "name",
			sortby: "name",
			width: 250,
			render(name) {
				return (
					<Typography.Text
						className="whitespace-nowrap w-full"
						ellipsis
						copyable
						title={name}>
						{name}
					</Typography.Text>
				);
			},
		},

		{
			title: t("warehouse:address"),
			key: "address",
			width: 200,
		},
		{
			title: t("warehouse:contact_fullname"),
			key: "contact_fullname",
			width: 180,
			toggletype: "extend",
		},
		{
			title: t("warehouse:contact_phone"),
			key: "contact_phone",
			width: 140,
			toggletype: "extend",
		},
		{
			title: t("warehouse:warehouse_type"),
			key: "type",
			width: 140,
			render: (type: number) => {
				const warehouseType = WarehouseModel.getType(type);
				return <Tag color={warehouseType?.color}>{warehouseType?.label}</Tag>;
			},
		},
		{
			title: t("common:status"),
			key: "status",
			width: 110,
			render: (status: number) => {
				let statusInfo = WarehouseModel.getStatus(status);
				return (
					<span
						className={
							status === Warehouse.STATUS_DISABLE
								? "text-red-500"
								: "text-green-500"
						}>
						{statusInfo?.label}
					</span>
				);
			},
		},
		{
			title: t("warehouse:direction_in"),
			key: "direction_in",
			width: 60,
			align: "center",
			render: (direction_in: number) =>
				direction_in ? <Checkbox checked disabled /> : <Checkbox disabled />,
		},
		{
			title: t("warehouse:direction_out"),
			key: "direction_out",
			width: 60,
			align: "center",
			render: (direction_out: number) =>
				direction_out ? <Checkbox checked disabled /> : <Checkbox disabled />,
		},
		{
			title: t("warehouse:apply_with_other"),
			key: "apply_with_other",
			width: 100,
			align: "center",
			render: (apply_with_other: number) =>
				apply_with_other ? (
					<Checkbox checked disabled />
				) : (
					<Checkbox disabled />
				),
		},
		{
			title: t("common:date_created"),
			key: "date_created",
			width: 120,
			render: (date_created) => Helper.formatTimestampToText2(date_created),
		},
		{
			title: " ",
			key: "actions",
			align: "right",
			fixed: "right",
			toggletype: "trigger",
			width: 240,
			render: (_: any, record: WarehouseModel) => (
				<RoleCheck roles={[Role.SETTING_SALE]} hideOnFail>
					<TableEdit link={"/warehouse/edit/id/" + record.id} />
					{/* <WarehouseMarkTesterTagBtn
						warehouseId={record.id}
						settingTesterTagType={settingTesterTagType}
						settingTesterTag={settingTesterTag}
					/> */}
					<Button
						size={"small"}
						type={"text"}
						onClick={() => handleOpenConfigTag(record.id)}>
						<Tooltip title="Quản lý tag">
							<IconTag
								className="-mt-0.5 text-gray-400 hover:text-red-500"
								size="18"
							/>
						</Tooltip>
					</Button>
					<TableDelete
						error_translate_prefix="warehouse:form.error"
						onDeleteCallback={(id) => {
							setDataSource(dataSource.filter((item) => item.id !== id));
						}}
						repository={new WarehouseRepository()}
						id={record.id}
					/>
					<TableInfo record={record} />
				</RoleCheck>
			),
		},
	];

	return (
		<>
			<WarehouseListHeader />
			<WarehouseListFilter
				filters={filters}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
				total={total}
			/>
			<PageDataTable<FilterWarehouse, WarehouseModel, WarehouseCollection>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					tableColumnToggleKey: "warehouse",
				}}></PageDataTable>

			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>

			<WarehouseFormTag
				id={editingTagId}
				open={openConfigTag}
				setOpen={setOpenConfigTag}></WarehouseFormTag>
		</>
	);
};

export default WarehouseList;
