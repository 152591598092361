import FeedbackCollection from "common/collections/FeedbackCollection";
import FeedbackModel from "common/models/FeedbackModel";
import { FeedbackAddEditJson, FilterFeedback } from "common/types/Feedback";
import type { GetListProps } from "common/types/Filter";
import Helper from "common/utils/helper";
import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

const SERVICE_URL = "/customers/contact";

class FeedbackApi extends BaseApi {
  async getList(
    props: GetListProps<FilterFeedback>
  ): Promise<FeedbackCollection> {
    let collection = new FeedbackCollection();
    const convertProps = Helper.convertParams(props.filters);

    const queryData = {
      params: {
        ...convertProps,
        sort_by: props.filters.sortby,
        sort_type: props.filters.sorttype,
        comments_on: props.filters.comments_on ? props.filters.comments_on.join(",") : null,
      },
    };
    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "?type=2", queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }

  async edit(data: FeedbackAddEditJson): Promise<FeedbackModel> {
    let item = new FeedbackModel(FeedbackModel.getDefaultData());
    try {
      let postData = {
        ...data,
      };
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/update/" + data.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new FeedbackModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default FeedbackApi;
