import { SelectProps } from "antd";
import { Rule } from "antd/es/form";
import { FormLabelAlign, NamePath } from "antd/es/form/interface";
import { SelectValue } from "antd/es/select";
import SettingApi from "common/constants/Setting";
import { SelectOption } from "common/types/SelectOption";
import FormSelect from "components/form/FormSelect";
import useSettingValue from "hooks/useSettingValue";

const ContactCommentOnSelect = ({
  label,
  name,
  disabled,
  required,
  allowClear,
  placeholder,
  labelAlign,
  size,
  status,
  rules,
  onChangeSelect,
  className,
  multiple,
}: {
  label?: string;
  name: string | NamePath;
  disabled?: boolean;
  required?: boolean;
  rules?: Rule[];
  allowClear?: boolean;
  placeholder?: string;
  labelAlign?: FormLabelAlign;
  size?: SelectProps["size"];
  status?: SelectProps["status"];
  initOptions?: SelectOption[];
  onChangeSelect?: (v: SelectValue) => void;
  className?: string;
  multiple?: boolean;
}): JSX.Element => {
  const {
    setting: settingContactCommentOn,
    isLoading: isLoadingSettingContactCommentOn,
  } = useSettingValue(SettingApi.KEY.setting_contact_contents);

  return (
    <FormSelect
      autoFocus
      multiple={multiple}
      rules={rules}
      size={size}
      required={required}
      disabled={disabled || isLoadingSettingContactCommentOn}
      loading={isLoadingSettingContactCommentOn}
      showSearch={settingContactCommentOn.value.length > 6}
      label={label}
      name={name}
      options={settingContactCommentOn.value}
      optionRender={(option: any) => <span>{option.label}</span>}
      allowClear={allowClear}
      placeholder={placeholder}
      labelAlign={labelAlign}
      status={status}
      onChange={onChangeSelect}
      className={className}
    />
  );
};

export default ContactCommentOnSelect;
