import { IconMapPin } from "@tabler/icons-react";
import { Image, TableColumnsType, Tag, Typography } from "antd";
import ActionCollection from "common/collections/ActionCollection";
import ActionModel from "common/models/ActionModel";
import FileModel from "common/models/FileModel";
import ActionRepository from "common/repositories/ActionRepository";
import { FilterAction } from "common/types/Action";
import { IdFetcherResult } from "common/types/IdFetcher";
import IdFetcherRender from "components/idfetcher/IdFetcherRender";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import TextDateTime from "components/TextDateTime";
import useFilterLocation from "hooks/useFilterLocation";
import useIdFetcher from "hooks/useIdFetcher";
import useStateFilter from "hooks/useStateFilter";
import { Fragment, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ActionListFilter from "./ActionListFilter";
import ActionListHeader from "./ActionListHeader";
import useDatabaseTable from "hooks/useDatabaseTable";
import { EmployeeJson } from "common/types/Employee";
import EmployeeRepository from "common/repositories/EmployeeRepository";
import EmployeeModel from "common/models/EmployeeModel";
import Helper from "common/utils/helper";

const ActionList = (): JSX.Element => {
  const { t } = useTranslation();
  const { getListIdFetcher } = useIdFetcher();

  ////////////////////////
  // default filter
  const defaultFilters = useMemo(() => {
    return ActionRepository.getDefaultFilters();
  }, []);

  ///////////////////////
  //state
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<ActionModel[]>([]);
  const [departments, setDepartments] = useState<IdFetcherResult[]>([]);
	const [employeeResult, employeeResultLoading] = useDatabaseTable<EmployeeJson>("employee");

  const [filters, setFilters] = useStateFilter(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //Table columns
  const columns: TableColumnsType<ActionModel> = [
    {
      title: t("action:header_table.creator_id"),
      key: "creator_id",
      width: 100,
      render: (_, record) => (
        <Tag>
          {record.employee?.internal_id}
        </Tag>
      ),
    },
    {
      title: t("action:header_table.creator"),
      key: "creator",
      width: 120,
      render: (_, record) => record.employee?.full_name,
    },
    {
      title: t("checkin:job_title"),
      key: "job_title",
      width: 100,
      render: (_, record) => record.employee?.job_title,
    },
    {
      title: t("checkin:department_title"),
      key: "department_id",
      width: 120,
      render: (department_id: number, record) => (
        <Typography.Text className="capitalize">
          <IdFetcherRender
            mapping={departments}
            value={department_id}
            key_value="text"
          />
        </Typography.Text>
      ),
    },
    {
      title: t("action:header_table.type"),
      key: "action",
      width: 120,
      render: (action: number) => {
        const actionType = ActionModel.getActionType(action);
        return <Tag color={actionType?.color}>{actionType?.label}</Tag>;
      },
    },
		{
      title: t("checkin:time_checkin"),
			key: "datecreated",
			width: 120,
			render: (datecreated) => Helper.formatTimestampToText2(datecreated),
		},
    {
      key: "latitude",
      title: t("checkin:latitude"),
      width: 100,
      render: (_: any, record: ActionModel) => (
        <Typography.Link
          href={`https://maps.google.com/?q=${record.latitude},${record.longitude}`}
          target="_blank"
        >
          <IconMapPin />
        </Typography.Link>
      ),
    },
    {
      key: "image",
      title: t("checkin:image"),
      align: "center",
      width: 120,
      render: (_, record) => {
        if (record.image.id <= 0) {
          return <Tag>Trống</Tag>;
        }

        return (
          <Image
            width={120}
            height={120}
            src={FileModel.getThumbnailFromUrl(
              record.image.file_path,
              480,
              480,
              "resize"
            )}
          />
        );
      },
    },
  ];

  ///////////////////////
  //function

  //get list deapartment from list id
  const getListDepartment = useCallback(
    async (id_list: number[]) => {
      const response = await getListIdFetcher({
        object_type: "department",
        ids: id_list,
      });

      if (!response.hasError()) {
        const result = response.items[0].result!;
        setDepartments(result);
      }
    },
    [getListIdFetcher]
  );

  const fetchEmployee = useCallback(async (ids: number[]) => {
    const response = await new EmployeeRepository().getItemIds({
      ids: ids,
      object_type: "employee",
    });

    return response.items;
  }, []);

  const mappingEmployeeFromFetch = useCallback(async (itemSource: ActionModel[], ids: number[]) => {
    const employeeSource = await fetchEmployee([...new Set(ids)]);

    const items = itemSource.map((item) => {
      let employee = new EmployeeModel(EmployeeModel.getDefaultData());

      const employeeWithUserId = employeeSource.find(
        (source) => source.user_id === item.employee_id
      );
      const employeeWithId = employeeSource.find(
        (source) => source.id === item.employee_id
      );

      if (employeeWithUserId) {
        employee = new EmployeeModel(employeeWithUserId);
      } else if (employeeWithId) {
        employee = new EmployeeModel(employeeWithId);
      }

      return new ActionModel({
        ...{
          ...item,
          employee: employee
        },
      });
    });

    return items;
  }, [fetchEmployee]);

  const mappingEmployee = useCallback(async (itemSource: ActionModel[]) => {
    let items = itemSource;
    const employeeIds: number[] = [];

    if (typeof employeeResult !== "undefined") {
      if (employeeResult.length === 0) {
        items = await mappingEmployeeFromFetch(items, [...new Set(items.map((item) => item.employee_id))]);
      } else {
        items = itemSource.map((item) => {
          let employee = new EmployeeModel(EmployeeModel.getDefaultData());
          
          const foundItemsWithUserId = employeeResult.find(
            (i) => i.user_id === item.employee_id
          );
          const foundItemsWithId = employeeResult.find(
            (i) => i.id === item.employee_id
          );
  
          if (foundItemsWithUserId) {
            employee = new EmployeeModel(foundItemsWithUserId);
          } else if (foundItemsWithId) {
            employee = new EmployeeModel(foundItemsWithId);
          } else {
            employeeIds.push(item.employee_id);
          }
    
          return new ActionModel({
            ...{
              ...item,
              employee: employee
            },
          });
        });
      }
    }

    if (employeeIds.length > 0) {
      items = await mappingEmployeeFromFetch(items, employeeIds);
    }

    return items;
  }, [employeeResult, mappingEmployeeFromFetch]);

  const fetchData = useCallback(async (): Promise<ActionCollection> => {
    if (employeeResultLoading) return new ActionCollection();

    const response = await new ActionRepository().getItems({
      filters: filters,
    });

    if (!response.hasError()) {
      if (response.toJson().items.length === 0) return response;
      const departmentIds = [
        ...new Set(response.toJson().items.map((i) => i.department_id || 0)),
      ];
      await getListDepartment(departmentIds);
    }

    const items = await mappingEmployee(response.items);

    setDataSource(items);
    setTotal(response.total);
    return response;
  }, [employeeResultLoading, filters, getListDepartment, mappingEmployee]);

  return (
    <Fragment>
      <ActionListHeader />
      <ActionListFilter
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      />
      <PageDataTable<FilterAction, ActionModel, ActionCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "checkin",
        }}
      />
      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </Fragment>
  );
};

export default ActionList;
