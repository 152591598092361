import SupplierApi from 'common/api/SupplierApi';
import SupplierCollection from 'common/collections/SupplierCollection';
import SupplierModel from 'common/models/SupplierModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type {
  SupplierJsonAddEdit,
  FilterSupplier,
} from "common/types/Supplier";

class SupplierRepository extends BaseRepository<SupplierApi> {
  _api: SupplierApi | null;

  constructor() {
    super();
    this._api = new SupplierApi(true);
  }

  static getDefaultFilters(): FilterSupplier {
    return {
      page: 1,
      limit: 50,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      ids: [],
    };
  }

  async getItems(props: GetListProps<FilterSupplier>) {
    return this._api
      ? await this._api.getList(props)
      : new SupplierCollection();
  }

  async getItem(id: number): Promise<SupplierModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new SupplierModel(SupplierModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(data: SupplierJsonAddEdit): Promise<SupplierModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new SupplierModel(SupplierModel.getDefaultData());
    }
  }
}

export default SupplierRepository;
