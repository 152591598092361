import { Collapse, Divider, Flex, Space, Typography } from "antd";
import PromotionModel from "common/models/PromotionModel";
import { CartPromotion } from "common/types/Cart";
import TableInfoRow from "components/table/TableInfoRow";
import TextDateTime from "components/TextDateTime";
import TextMoney from "components/TextMoney";
import { t } from "i18next";
import { memo, useMemo } from "react";

interface IOrderDetailPromotionProps {
	promotions: CartPromotion[];
}

const OrderDetailPromotion = ({
	promotions,
}: IOrderDetailPromotionProps): JSX.Element | null => {
	const collapseStyle: React.CSSProperties = useMemo(
		() => ({
			background: "#fff",
			border: "1px dashed #d1d5db",
		}),
		[]
	);

	const isUsePromotion = useMemo(
		() => promotions.some((promotion) => promotion.is_use),
		[promotions]
	);

	const promotionDiscount = useMemo(
		() =>
			promotions.reduce(
				(acc, promotion) => acc + (promotion.is_use ? promotion.discount : 0),
				0
			),
		[promotions]
	);

	const promotionHeader = useMemo(
		() => (
			<Space>
				Đã giảm:
				<Typography.Text className="text-red-500">
					-<TextMoney money={promotionDiscount} />
				</Typography.Text>
			</Space>
		),
		[promotionDiscount]
	);

	if (promotions.length === 0) return null;

	if (!isUsePromotion) return null;

	return (
		<Collapse size="small" style={collapseStyle}>
			<Collapse.Panel header={promotionHeader} key={1}>
				<Space
					direction="vertical"
					size="small"
					split={<Divider dashed className="m-0" />}
					className="overflow-x-auto">
					{promotions.map((promotion) => (
						<Flex vertical>
							<TableInfoRow
								label={t("Mã chương trình")}
								content={promotion.promotion_detail.campaign_info.id}
							/>
							<TableInfoRow
								label={t("Mã code")}
								content={promotion.promotion_detail.campaign_info.code}
							/>
							<TableInfoRow
								label={t("Tên chương trình")}
								content={
									<Typography.Text
										ellipsis={{
											tooltip: promotion.promotion_detail.campaign_info.name,
										}}>
										{promotion.promotion_detail.campaign_info.name}
									</Typography.Text>
								}
							/>
							<TableInfoRow
								label={t("Ngày tạo")}
								content={
									<TextDateTime
										format="HH:mm DD/MM/YYYY"
										ts={promotion.promotion_detail.create_date}
									/>
								}
							/>
							<TableInfoRow
								label={t("Giá trị giảm")}
								content={
									promotion.promotion_detail.discount_value_type ===
									"percent" ? (
										<>
											{promotion.promotion_detail.discount_value}
											{PromotionModel.getDiscountValueType(
												promotion.promotion_detail.discount_value_type
											)}
										</>
									) : (
										<TextMoney
											money={promotion.promotion_detail.discount_value}
										/>
									)
								}
							/>
							<TableInfoRow
								label={t("Mô tả")}
								content={
									<Typography.Text
										ellipsis={{
											tooltip:
												promotion.promotion_detail.campaign_info.description,
										}}>
										{promotion.promotion_detail.campaign_info.description}
									</Typography.Text>
								}
							/>
						</Flex>
					))}
				</Space>
			</Collapse.Panel>
		</Collapse>
	);
};

export default memo(OrderDetailPromotion);
