import { Tag, Tooltip, Typography } from "antd";
import TaxInvoiceCollection from "common/collections/TaxInvoiceCollection";
import Role from "common/constants/Role";
import TaxInvoiceModel from "common/models/TaxInvoiceModel";
import TaxInvoiceRepository from "common/repositories/TaxInvoiceRepository";
import { StoreJson } from "common/types/Store";
import { TableColumnsType } from "common/types/Table";
import { FilterTaxInvoice } from "common/types/TaxInvoice";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import TextDateTime from "components/TextDateTime";
import TextMoney from "components/TextMoney";
import TaxInvoiceListHeader from "features/taxinvoice/list/TaxInvoiceListHeader";
import useDatabaseTable from "hooks/useDatabaseTable";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import TaxInvoiceItemEdit from "../item/TaxInvoiceItemEdit";
import TaxInvoiceItemIssueSync from "../item/TaxInvoiceItemIssueSync";
import TaxInvoiceListFilter from "./TaxInvoiceListFilter";
import TextCashflowReceiptMethod from "components/TextCashflowReceiptMethod";

const TaxInvoiceList = () => {
  const { t } = useTranslation();

  const [storeItemsWithLimit] = useDatabaseTable<StoreJson>(
    "store",
    Role.TAX_INVOICE_VIEW
  );

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterTaxInvoice = useMemo(
    () => TaxInvoiceRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] =
    useStateFilter<FilterTaxInvoice>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<TaxInvoiceModel[]>([]);
  const fetchData = useCallback(async (): Promise<TaxInvoiceCollection> => {
    let collection = await new TaxInvoiceRepository().getItems({
      filters,
    });
    setTotal(collection.total);
    setDataSource(collection.items);
    return collection;
  }, [filters]);

  const updateItemCallback = useCallback(
    (updatedItem: TaxInvoiceModel) => {
      setDataSource(
        dataSource.map((i) => (i.id === updatedItem.id ? updatedItem : i))
      );
    },
    [dataSource]
  );

  //////////////////////////////////////////
  //Table columns
  const columns: TableColumnsType<TaxInvoiceModel> = [
    {
      title: t("common:sid"),
      key: "id",
      align: "center",
      width: 60,
      render: (id, record) => {
        return (
          <TaxInvoiceItemEdit model={record} notPermissionFallback={record.id}>
            <TableLinkText link={"/taxinvoice/edit/id/" + record.id}>
              {id}
            </TableLinkText>
          </TaxInvoiceItemEdit>
        );
      },
    },
		{
			title: t("taxinvoice:order_id"),
			key: "order_id",
			width: 160,
			render: (order_id, record) => {
				return (
					<TableLinkText
            title={t("order:detail_button")}
            link={"/order/edit/id/" + record.order_id}
            className="text-base font-bold"
          >
            <Typography.Text className="text-blue-600" copyable>
              {order_id}
            </Typography.Text>
          </TableLinkText>
				);
			},
		},
		{
			title: t("taxinvoice:code"),
			key: "code",
			width: 160,
			render: (code, record) => {
				return (
					<TableLinkText
            title={t("order:detail_button")}
            link={"/order/edit/id/" + record.order_id}
            className="text-base font-bold"
          >
            <Typography.Text className="text-blue-600" copyable>
              {code}
            </Typography.Text>
          </TableLinkText>
				);
			},
		},
    {
      title: t("taxinvoice:seller_store_id"),
      key: "seller_store_id",
      width: 180,
      render: (seller_store_id: number) => {
        if (seller_store_id > 0) {
          const found = storeItemsWithLimit.find(
            (i) => i.id === seller_store_id
          );
          if (typeof found !== "undefined") {
            return <span>{found.name}</span>;
          } else {
            return "ID #" + seller_store_id;
          }
        } else {
          return "-";
        }
      },
    },

    {
      title: t("taxinvoice:group_buyer"),
      key: "buyer_tax_number",
      width: 180,
      render: (buyer_tax_number, record) => {
        return (
          <>
            <div>
              {record.buyer_name}
              <br />
              <span className="text-xs text-gray-400">
                {t("taxinvoice:tax_number_short")}:{" "}
                {buyer_tax_number.length > 0 ? buyer_tax_number : "---"}
              </span>
            </div>
          </>
        );
      },
    },
    {
      title: t("taxinvoice:invoice_type"),
      key: "invoice_type",
      width: 140,
      render: (invoice_type) => TaxInvoiceModel.getInvoiceType(invoice_type)?.label
    },
    {
      title: t("taxinvoice:buyer_receive_method"),
      key: "buyer_receive_method",
      width: 120,
      render: (buyer_receive_method) => TaxInvoiceModel.getBuyerReceiveMethod(buyer_receive_method)?.label
    },
    {
      title: t("taxinvoice:group_issueprovider"),
      key: "issue_provider",
      width: 200,
      render: (issue_provider, record) => {
        return (
          <TaxInvoiceItemIssueSync
            model={record}
            updateCallback={updateItemCallback}
          />
        );
      },
    },
    {
      title: t("taxinvoice:price_sell_short"),
      key: "price_sell",
      align: "right",
      width: 220,
      render: (price_sell: number, record) => {
        return (
          <div>
            <div>
              <span className="text-gray-400">
                {t("taxinvoice:price_sell_short")}: &nbsp;
              </span>
              <TextMoney money={price_sell} />
            </div>
            <div>
              <span className="text-gray-400">
                + {t("taxinvoice:price_tax")}: &nbsp;
              </span>
              <TextMoney money={record.price_tax} />
            </div>
            <div>
              {t("taxinvoice:price_final_short")}: &nbsp;
              <span className="text-lg font-bold">
                <TextMoney money={record.price_final} />
              </span>
            </div>
          </div>
        );
      },
    },
		{
			title: (
				<>
					<Tooltip
						title={t("cashflowreceipt:filter_method")}
						mouseEnterDelay={0.4}>
						{t("cashflowreceipt:method_sort")}
					</Tooltip>
				</>
			),
			key: "payment_method",
			align: "center",
			togglelabel: t("cashflowreceipt:filter_method"),
			width: 70,
			render: (payment_method: number) => {
				return <TextCashflowReceiptMethod method={payment_method} />;
			},
		},
    {
      title: t("common:status"),
      key: "status",
      render: (status: number) => {
        let statusInfo = TaxInvoiceModel.getStatus(status);
        return <Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>;
      },
      width: 100,
    },
    {
      title: t("common:date_created"),
      key: "date_created",
      width: 100,
      render: (ts: number) => <TextDateTime format="HH:mm, DD/MM" ts={ts} />,
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 95,
      toggletype: "trigger",
      render: (_: any, record: TaxInvoiceModel) => (
        <>
          <TaxInvoiceItemEdit model={record} />
          <TableInfo record={record}></TableInfo>
        </>
      ),
    },
  ];

  return (
    <>
      <TaxInvoiceListHeader />

      <TaxInvoiceListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />

      <PageDataTable<FilterTaxInvoice, TaxInvoiceModel, TaxInvoiceCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "taxinvoice",
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </>
  );
};

export default TaxInvoiceList;
