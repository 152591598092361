import ActionWrapper from "features/action/ActionWrapper";
import ActionList from "features/action/list/ActionList";
import usePathParams from "hooks/usePathParams";

const ActionPage = (): JSX.Element => {
  const [pathParams] = usePathParams();

  let com = <ActionList />;
  switch (pathParams.action) {
    case "add":
      com = <ActionWrapper />;
      break;
    default:
      com = <ActionList />;
  }

  return com;
};

export default ActionPage;
