import { IconEye, IconMinus } from "@tabler/icons-react";
import { Col, Image, Row, Statistic, TableColumnsType, Tooltip, Typography } from "antd";
import ProductCollection from "common/collections/ProductCollection";
import ProductWarehouseCollection from "common/collections/ProductWarehouseCollection";
import Warehouse from "common/constants/Warehouse";
import ProductWarehouseModel from "common/models/ProductWarehouseModel";
import StoreModel from "common/models/StoreModel";
import StoreWarehouseModel from "common/models/StoreWarehouseModel";
import WarehouseModel from "common/models/WarehouseModel";
import ProductRepository from "common/repositories/ProductRepository";
import ProductWarehouseRepository from "common/repositories/ProductWarehouseRepository";
import StoreRepository from "common/repositories/StoreRepository";
import StoreWarehouseRepository from "common/repositories/StoreWarehouseRepository";
import WarehouseRepository from "common/repositories/WarehouseRepository";
import { ProductStatus } from "common/types/Product";
import { FilterProductWarehouse } from "common/types/ProductWarehouse";
import NoPhoto from "components/NoPhoto";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import { t } from "i18next";
import { Fragment, useCallback, useMemo, useRef, useState } from "react";
import { convertNumberToPriceVND } from "utils";
import ProductWarehouseListExpand from "./ProductWarehouseListExpand";
import ProductWarehouseListFilter from "./ProductWarehouseListFilter";
import ProductWarehouseListHeader from "./ProductWarehouseListHeader";

const ProductWarehouseList = (): JSX.Element => {
  const defaultFilters: FilterProductWarehouse = useMemo(
    () => ProductWarehouseRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] =
    useStateFilter<FilterProductWarehouse>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  const [isLoading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<ProductWarehouseModel[]>([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

  const [storeSource, setStoreSource] = useState<StoreModel[]>([]);

  const storeWarehouseFormSelectRef = useRef<any>(null);

  const fetchProductWarehouse = useCallback(
    async (warehouseIds?: number[], item_idlist?: number[]): Promise<ProductWarehouseCollection> => {
      const collection = await new ProductWarehouseRepository().getItems({
        filters: {
          ...filters,
          warehouse_ids: warehouseIds && warehouseIds.length > 0 ? warehouseIds.join(",") : "",
          item_idlist: item_idlist && item_idlist.length > 0 ? item_idlist.join(",") : "",
        },
      });
      return collection;
    },
    [filters]
  );

  const fetchProduct = useCallback(
    async (keyword?: string, ids?: number[]): Promise<ProductCollection> => {
      const collection = await new ProductRepository().getItems({
        filters: {
          ...ProductRepository.getDefaultFilters(),
          keyword: keyword ? keyword : "",
          ids: ids && ids.length > 0 ? ids.join(",") : "",
        },
      });
      return collection;
    },
    []
  );

  const fetchStore = useCallback(
    async (): Promise<void> => {
      const collection = await new StoreRepository().getItems({
        filters: {
          ...StoreRepository.getDefaultFilters()
        },
      });
      setStoreSource(collection.items);
      storeWarehouseFormSelectRef.current?.setStoreSource(collection.items);
    },
    []
  );

  const fetchStoreWarehouse = useCallback(
    async (storeId?: number): Promise<StoreWarehouseModel[]> => {
      const collection = await new StoreWarehouseRepository().getItems({
        filters: {
          ...StoreWarehouseRepository.getDefaultFilters(),
          store_id: storeId ?? 0,
        },
      });
      return collection.items;
    },
    []
  );

  const fetchWarehouse = useCallback(
    async (ids?: number[]): Promise<WarehouseModel[]> => {
      const collection = await new WarehouseRepository().getItems({
        filters: {
          ...WarehouseRepository.getDefaultFilters(),
          ids: ids && ids.length > 0 ? ids.join(",") : "",
        },
      });
      return collection.items;
    },
    []
  );

  const mappingStoreWarehouse = useCallback(async (itemSource: ProductWarehouseModel[], warehouseSource: WarehouseModel[]) => {
    const items = itemSource.map((item) => {
      const store = storeSource.find(
        (source) => source.id === filters.store_id
      );
      const warehouse = warehouseSource.find(
        (source) => source.id === item.warehouse_id
      );

      return new ProductWarehouseModel({
        ...{
          ...item,
          store: store?.toJson(),
          warehouse: warehouse?.toJson(),
        },
      });
    });

    return items;
  }, [storeSource, filters.store_id]);

  const fetchNormalData = useCallback(async (warehouseSource: WarehouseModel[]): Promise<ProductWarehouseCollection> => {
    setLoading(true);
    const productWarehouseCollection = await fetchProductWarehouse(warehouseSource.map((item) => item.id));
    const productIds = [...new Set(productWarehouseCollection.items.map((item) => item.item_id))];
    const productCollection = await fetchProduct("", productIds);

    let items = productWarehouseCollection.items.filter((item) =>
      productCollection.items
        .map((product) => product.id)
        .includes(item.item_id)
    ).map((item) => {
      return new ProductWarehouseModel({
        ...item,
        product: productCollection.items.find(
          (source) => source.id === item.item_id
        )?.toJson(),
      });
    });

    items = await mappingStoreWarehouse(items, warehouseSource);
    setDataSource(items);
    setTotal(productWarehouseCollection.total);
    setLoading(false);
    return productWarehouseCollection;
  }, [fetchProductWarehouse, fetchProduct, mappingStoreWarehouse]);

  /** 
   * Because Inventories API didn't filter product warehouse by keyword
   * So we need to reverse mapping
  */
  const fetchReverseData = useCallback(async (warehouseSource: WarehouseModel[]): Promise<ProductWarehouseCollection> => {
    setLoading(true);
    const productCollection = await fetchProduct(filters.keyword.toString());
    const productWarehouseIds = [...new Set(productCollection.items.map((item) => item.id))];
    const productWarehouseCollection = await fetchProductWarehouse(warehouseSource.map((item) => item.id), productWarehouseIds);

    let items = productWarehouseCollection.items.filter((item) =>
      productCollection.items
        .map((product) => product.id)
        .includes(item.item_id)
    );

    items = items.map((item) => {
      return new ProductWarehouseModel({
        ...item,
        product: productCollection.items.find(
          (source) => source.id === item.item_id
        )?.toJson(),
      });
    });
    
    items = await mappingStoreWarehouse(items, warehouseSource);
    setDataSource(items);
    setTotal(productWarehouseCollection.total);
    setLoading(false);
    return productWarehouseCollection;
  }, [filters, fetchProduct, fetchProductWarehouse, mappingStoreWarehouse]);
  /** End reverse mapping */

  const fetchData = useCallback(async (): Promise<ProductWarehouseCollection> => {
    if (storeSource.length === 0) {
      await fetchStore();
      return new ProductWarehouseCollection();
    }
    
    const storeWarehouseSource = await fetchStoreWarehouse(filters.store_id);
    const warehouseSource = await fetchWarehouse(storeWarehouseSource.map((item) => item.warehouse_id));

    if (filters.keyword.toString().length === 0) {
      return await fetchNormalData(warehouseSource);
    } else {
      return await fetchReverseData(warehouseSource);
    }
  }, [storeSource.length, fetchStore, fetchStoreWarehouse, fetchWarehouse, filters, fetchNormalData, fetchReverseData]);

  const columns: TableColumnsType<ProductWarehouseModel> = useMemo(() => [
    {
      title: t("product:image_short"),
      key: "images",
      align: "center",
      width: 50,
      render: (_, record) => {
        return record.product?.images?.length! > 0 ? (
          <div onClick={(e) => e.stopPropagation()}>
            <Image
              width="40"
              src={record.product?.images[0].url}
              preview={{
                src: record.product?.images[0].url,
                mask: <IconEye size="16" />,
              }}
            />
          </div>
        ) : (
          <NoPhoto width={40} height={40} />
        );
      },
    },
    {
      title: t("product:name"),
      key: "name",
      width: 120,
      render: (_, record) => (
        <div className="text-md font-bold line-clamp-2">
          {record.product?.name}
        </div>
      ),
    },
    {
      title: t("product:sku"),
      key: "sku",
      width: 120,
      align: "center",
      render: (_, record) => record.product?.sku,
    },
    {
      title: t("cửa hàng"),
      key: "store_id",
      width: 120,
      render: (_, record) => record.store?.name,
      hidden: !(filters.store_id > -1)
    },
    {
      title: t("kho"),
      key: "warehouse_id",
      width: 120,
      render: (_, record) => record.warehouse?.name,
    },
    {
      title: t("tồn kho"),
      key: "quantity",
      width: 120,
      render: (_, record) => (
        <Row gutter={16}>
          <Col span={12}>
            <Statistic
              title={
                <Tooltip
                  title={
                    <Typography.Text className="text-white">
                      Số lượng khách đang đặt hàng trên website
                    </Typography.Text>
                  }>
                  Khách đặt
                </Tooltip>
              }
              value={record.reserved}
              valueStyle={{ color: "#3f8600" }}
            />
          </Col>
          <Col span={12}>
            <Statistic
              title={
                <Tooltip
                  title={
                    <Typography.Text className="text-white">
                      Số lượng tồn kho thực tế trong kho
                    </Typography.Text>
                  }>
                  Tồn kho
                </Tooltip>
              }
              value={record.quantity}
              valueStyle={{ color: "#cf1322" }}
              prefix={
                record.quantity < 0 ? (
                  <IconMinus></IconMinus>
                ) : null
              }
            />
          </Col>
        </Row>
      ),
    },
    {
      title: t("product:price"),
      key: "cost_price",
      width: 120,
      render: (cost_price: number) => {
        return <p>{convertNumberToPriceVND(cost_price)}</p>;
      },
    },
    {
      title: t("common:status"),
      key: "status",
      width: 110,
      render: (status: number) => {
        let statusInfo = ProductWarehouseModel.getStatus(status);
        return (
          <span
            className={
              status === ProductStatus.UNACTIVE
                ? "text-red-500"
                : " text-green-500"
            }
          >
            {statusInfo?.label}
          </span>
        );
      },
    },
  ], [filters.store_id]);

  return (
    <Fragment>
      <ProductWarehouseListHeader />
      <ProductWarehouseListFilter
        ref={storeWarehouseFormSelectRef}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />
      <PageDataTable<
        FilterProductWarehouse,
        ProductWarehouseModel,
        ProductWarehouseCollection
      >
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          isLoading: isLoading,
          expandable: {
            expandedRowRender: (record: ProductWarehouseModel) => <ProductWarehouseListExpand record={record.warehouse?.type === Warehouse.TYPE_TESTER ? record : null} />,
            expandedRowClassName: () => "ant-table-expanded-row-dimmer [&_.ant-table]:!ms-0 [&_.ant-table]:!me-0 [&_.ant-table]:!mt-0 [&_.ant-table]:!mb-0",
            expandRowByClick: true,
            showExpandColumn: false,
            expandedRowKeys: expandedRowKeys,
            onExpand: (expanded: boolean, record: ProductWarehouseModel) => {
              setExpandedRowKeys(expanded ? [...expandedRowKeys, record.id] : expandedRowKeys.filter(key => key !== record.id))
            },
          },
        }}
      />
      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </Fragment>
  );
};

export default ProductWarehouseList;
