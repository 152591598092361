import CashflowReceiptCollection from "common/collections/CashflowReceiptCollection";
import CashflowReceiptModel from "common/models/CashflowReceiptModel";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import type { GetListProps } from "common/types/Filter";
import type {
	FilterCashflowReceipt,
	CashflowReceiptJsonAddEdit,
} from "common/types/CashflowReceipt";
const SERVICE_URL = "/cashflowreceipts";

class CashflowReceiptApi extends BaseApi {
	async getList(
		props: GetListProps<FilterCashflowReceipt>
	): Promise<CashflowReceiptCollection> {
		let collection = new CashflowReceiptCollection();

		try {
			let filters = props.filters;

			let queryData = {
				params: {
					page: filters.page,
					limit: filters.limit,
					sort_by: filters.sortby,
					sort_type: filters.sorttype,
					keyword: filters.keyword ? filters.keyword : null,
					status: +filters.status > 0 ? filters.status : null,
					id: filters.id > 0 ? filters.id : null,
					cashflow_group:
						filters.cashflow_group > 0 ? filters.cashflow_group : null,
					direction: filters.direction > 0 ? filters.direction : null,
					store_id: filters.store_id > 0 ? filters.store_id : null,
					source: filters.source > 0 ? filters.source : null,
					source_id: filters.source_id > 0 ? filters.source_id : null,
					target: filters.target > 0 ? filters.target : null,
					method: filters.method > 0 ? filters.method : null,
					date_started: filters.date_started > 0 ? filters.date_started : null,
					date_ended: filters.date_ended > 0 ? filters.date_ended : null,
					is_complete: filters.is_complete >= 0 ? filters.is_complete : null,
				},
			};

			const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}

		return collection;
	}

	async getDetail(id: number): Promise<CashflowReceiptModel> {
		let item = new CashflowReceiptModel(CashflowReceiptModel.getDefaultData());

		try {
			const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
			if (response.hasOwnProperty("data")) {
				item = new CashflowReceiptModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async delete(id: number): Promise<string[]> {
		let resultErrors = [];

		try {
			const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
			if (response.status !== 204) {
				resultErrors.push("error_delete_response_not_204");
			}
		} catch (error) {
			resultErrors = BaseApi.handleError(error).errors;
		}

		return resultErrors;
	}

	async add(data: CashflowReceiptJsonAddEdit): Promise<CashflowReceiptModel> {
		let item = new CashflowReceiptModel(CashflowReceiptModel.getDefaultData());

		try {
			let postData = {
				...data,
			};

			if (postData?.id! <= 0) {
				delete postData.id;
			}

			//For create item
			const response = await AxiosClient().post<any>(SERVICE_URL, postData);
			if (response.hasOwnProperty("data")) {
				item = new CashflowReceiptModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async edit(data: CashflowReceiptJsonAddEdit): Promise<CashflowReceiptModel> {
		let item = new CashflowReceiptModel(CashflowReceiptModel.getDefaultData());

		try {
			let postData = {
				...data,
			};

			//For create item
			const response = await AxiosClient().put<any>(
				SERVICE_URL + "/" + data.id,
				postData
			);
			if (response.hasOwnProperty("data")) {
				item = new CashflowReceiptModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}
}

export default CashflowReceiptApi;
