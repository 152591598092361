import ProductModel from "common/models/ProductModel";
import ProductWarehouseModel from "common/models/ProductWarehouseModel";
import ProductRepository from "common/repositories/ProductRepository";
import ProductWarehouseRepository from "common/repositories/ProductWarehouseRepository";
import { useCallback, useEffect, useState } from "react";

interface IUseProductWarehouse {
	keyword?: string;
	warehouseId?: number;
}

const useProductWarehouse = ({
	keyword,
	warehouseId,
}: IUseProductWarehouse) => {
	const [isLoading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [dataSource, setDataSource] = useState<ProductModel[]>();

	const fetchProduct = useCallback(
		async (ids?: number[]): Promise<ProductModel[]> => {
			const collection = await new ProductRepository().getItems({
				filters: {
					...ProductRepository.getDefaultFilters(),
					keyword: keyword ? keyword : "",
					ids: ids && ids.length > 0 ? ids.join(",") : "",
				},
			});

			setDataSource(collection.items);

			return collection.items;
		},
		[keyword]
	);

	const fetchProductWarehouse = useCallback(
		async (item_idlist?: number[]): Promise<ProductWarehouseModel[]> => {
			const collection = await new ProductWarehouseRepository().getItems({
				filters: {
					...ProductWarehouseRepository.getDefaultFilters(),
					warehouse_id: warehouseId ? warehouseId : -1,
					item_idlist:
						item_idlist && item_idlist.length > 0 ? item_idlist.join(",") : "",
				},
			});
			return collection.items;
		},
		[warehouseId]
	);

	const fetchNormalData = useCallback(async () => {
		const productWarehouseSource = await fetchProductWarehouse();
		const productIds = [
			...new Set(productWarehouseSource.map((item) => item.item_id)),
		];
		const productSource = await fetchProduct(productIds);
		setDataSource(productSource);
	}, [fetchProductWarehouse, fetchProduct]);

	const fetchReverseData = useCallback(async () => {
		const productSource = await fetchProduct();
		const productWarehouseIds = [
			...new Set(productSource.map((item) => item.id)),
		];
		const productWarehouseSource = await fetchProductWarehouse(
			productWarehouseIds
		);

		setDataSource(
			productSource.filter((item) =>
				productWarehouseSource
					.map((productWarehouse) => productWarehouse.item_id)
					.includes(item.id)
			)
		);
	}, [fetchProduct, fetchProductWarehouse]);

	const fetchData = useCallback(async () => {
		setLoading(true);

		try {
			// if (!keyword || keyword.length === 0) {
			//   await fetchNormalData();
			// } else {
			//   await fetchReverseData();
			// }
			await fetchProduct();
		} catch (error) {
			setError(String(error));
		} finally {
			setLoading(false);
		}
	}, [keyword, fetchNormalData, fetchReverseData]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return {
		data: dataSource,
		isLoading: isLoading,
		error: error,
	};
};

export default useProductWarehouse;
