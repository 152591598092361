import StoreWarehouseApi from "common/api/StoreWarehouseApi";
import StoreWarehouseCollection from "common/collections/StoreWarehouseCollection";
import { GetListProps } from "common/types/Filter";
import { FilterStoreWarehouse, StoreWarehouseJson } from "common/types/StoreWarehouse";
import BaseRepository from "./BaseRepository";
import StoreWarehouseModel from "common/models/StoreWarehouseModel";

class StoreWarehouseRepository extends BaseRepository<StoreWarehouseApi> {
  _api: StoreWarehouseApi | null;

  constructor() {
    super();
    this._api = new StoreWarehouseApi(true);
  }

  static getDefaultFilters(): FilterStoreWarehouse {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      store_id: -1,
      store_ids: "",
      warehouse_id: -1,
      warehouse_ids: "",
    };
  }

  async getItems(props: GetListProps<FilterStoreWarehouse>) {
    return this._api ? await this._api.getList(props) : new StoreWarehouseCollection();
  }

  async saveRemote(data: any): Promise<StoreWarehouseModel> {
    if (this._api) {
      return await this._api.add(data);
    } else {
      return new StoreWarehouseModel(StoreWarehouseModel.getDefaultData());
    }
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }
}

export default StoreWarehouseRepository;
