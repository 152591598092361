import useLoginAccountStore from "zustands/useLoginAccountStore";

type Props = {
  roles: Array<number>;
};
const useCheckPermisstion = ({ roles }: Props) => {
  const account = useLoginAccountStore((state) => state.account);

  ///
  const accountRoleSubjects = useLoginAccountStore((state) =>
    state.account.role.map((r) => r.subject_id)
  );

  const hasRoles =
    roles.filter((r) => accountRoleSubjects.includes(r)).length > 0;

  ///

  const checkRole = (roles: Array<number>) => {
    let newArr: any[] = [];
    let hasRole = false;
    for (let index = 0; index < account.role.length; index++) {
      const element = account.role[index].subject_id;
      newArr.push(element);
    }
    if (roles.length === 0) {
      hasRole = true;

      return hasRole;
    }
    if (newArr.length > 0) {
      hasRole = roles.filter((r: number) => newArr.includes(r)).length > 0;
    } else {
      hasRole = false;
    }
    return hasRole;
  };

  return {
    hasRole: hasRoles,
    checkRole: checkRole,
  };
};
export default useCheckPermisstion;
