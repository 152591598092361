import ProductWarehouseList from "features/productwarehouse/list/ProductWarehouseList";
import usePathParams from "hooks/usePathParams";

const ProductWarehousePage = (): JSX.Element => {
  const [pathParams] = usePathParams();

  let com = <ProductWarehouseList />;
  switch (pathParams.action) {
    default:
      com = <ProductWarehouseList />;
  }

  return com;
};

export default ProductWarehousePage;
