const Customer = {
  GENDER_FEMALE: 1,
  GENDER_MALE: 3,
  GENDER_OTHER: 5,
  GENDER_UNKNOWN: 7,

  STATUS_UNACTIVATED: 0,
  STATUS_ENABLE: 1,
  STATUS_DISABLED: 3,
  STATUS_DELETED: 5,
  STATUS_ALREADY_CREATED: 6,

  AGE_RANGE_CHILD: 1,
  AGE_RANGE_YOUNG: 2,
  AGE_RANGE_MIDDLE_1: 3,
  AGE_RANGE_MIDDLE_2: 4,
  AGE_RANGE_MIDDLE_3: 5,
  AGE_RANGE_ELDER: 6,
  AGE_RANGE_OVER_ELDER: 7,
  AGE_RANGE_UNKNOWN: 8,

  CUSTOMER_VISITOR: 1,
  CUSTOMER_NOT_VISITOR: 0,
};

export default Customer;
