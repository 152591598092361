import { message, Tag, Typography } from "antd";
import ShipperKPICollection from "common/collections/ShipperKPICollection";
import ShipperKPIModel from "common/models/ShipperKPIModel";
import StoreModel from "common/models/StoreModel";
import LocationRepository from "common/repositories/LocationRepository";
import ShipperKPIRepository from "common/repositories/ShipperKPIRepository";
import StoreRepository from "common/repositories/StoreRepository";
import { IdFetcherResult } from "common/types/IdFetcher";
import { FilterShipperKPI } from "common/types/ShipperKPI";
import { TableColumnsType } from "common/types/Table";
import Helper from "common/utils/helper";
import { LocationJson } from "components/LocationPicker";
import TextMoney from "components/TextMoney";
import IdFetcherRender from "components/idfetcher/IdFetcherRender";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import TableInfo from "components/table/TableInfo";
import PhoneBookModal from "features/phonebook/form/PhoneBookModal";
import useFilterLocation from "hooks/useFilterLocation";
import useIdFetcher from "hooks/useIdFetcher";
import useStateFilter from "hooks/useStateFilter";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ShipperKPIListFilter from "./ShipperKPIListFilter";
import ShipperKPIListHeader from "./ShipperKPIListHeader";

const ShipperKPIList = () => {
  const { t } = useTranslation();
  const { getListIdFetcher } = useIdFetcher();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterShipperKPI = useMemo(
    () => ShipperKPIRepository.getDefaultFilters(),
    []
  );

  //state
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<ShipperKPIModel[]>([]);
  const [employees, setEmployees] = useState<IdFetcherResult[]>([]);
  const [coordinators, setCoordinators] = useState<IdFetcherResult[]>([]);
  const [districts, setDistricts] = useState<LocationJson[]>([]);
  const [stores, setStores] = useState<StoreModel[]>([]);
  const [modalId, setModalId] = useState<number>(0);
  const [showContactFields, setShowContactFields] = useState(false);
  const [filters, setFilters] =
    useStateFilter<FilterShipperKPI>(defaultFilters);
  useFilterLocation(defaultFilters, filters);
  //////////////////////////////////////////

  //////////////////////////////////////////
  //Fetch data from this collections
  const getListEmployee = useCallback(
    async (id_list: number[]) => {
      const response = await getListIdFetcher({
        object_type: "employee",
        ids: id_list,
      });

      if (!response.hasError()) {
        const result = response.items.filter(
          (i) => i.object_type === "employee"
        )[0].result!;
        setEmployees(result);
      }
    },
    [getListIdFetcher]
  );

  const getListCoordinator = useCallback(
    async (id_list: number[]) => {
      const response = await getListIdFetcher({
        object_type: "employee",
        ids: id_list,
      });

      if (!response.hasError()) {
        const result = response.items.filter(
          (i) => i.object_type === "employee"
        )[0].result!;
        setCoordinators(result);
      }
    },
    [getListIdFetcher]
  );

  const getListDistrict = useCallback(async (id_list: number[]) => {
    const response = await new LocationRepository().getItems({
      filters: {
        ...StoreRepository.getDefaultFilters(),
        type: 10,
        list_ids: id_list.join(","),
      },
    });

    if (!response.hasError()) {
      setDistricts(response.items);
    }
  }, []);

  const getListStore = useCallback(async (id_list: number[]) => {
    const response = await new StoreRepository().getItems({
      filters: {
        ...StoreRepository.getDefaultFilters(),
        ids: id_list.join(","),
      },
    });

    if (!response.hasError()) {
      setStores(response.items);
    }
  }, []);

  const fetchData = useCallback(async (): Promise<ShipperKPICollection> => {
    const collection = await new ShipperKPIRepository().getItems({
      filters,
    });

    if (collection) {
      setTotal(collection.total);
      setDataSource(collection.items);

      const employeeIds = [
        ...new Set(collection.items.map((i) => i.employee_id || 0)),
      ];
      await getListEmployee(employeeIds);

      const coordinatorIds = [
        ...new Set(collection.items.map((i) => i.coordinator || 0)),
      ];
      await getListCoordinator(coordinatorIds);

      const districtIds = [
        ...new Set(collection.items.map((i) => i.district || 0)),
      ];
      await getListDistrict(districtIds);

      const storeIds = [
        ...new Set(collection.items.map((i) => i.store_id || 0)),
      ];
      await getListStore(storeIds);
    } else {
      message.error(t("common:error.error_fetching_data"));
    }

    return collection;
  }, [
    filters,
    getListEmployee,
    getListCoordinator,
    getListDistrict,
    getListStore,
    t,
  ]);

  const handleClickCard = (id: number) => {
    setModalId(id);
  };

  //////////////////////////////////////////
  //Table columns
  const columns: TableColumnsType<ShipperKPIModel> = [
    {
      title: "ID",
      key: "id",
      width: 80,
    },
    {
      title: t("shipperkpi:table_header.employee_id"),
      key: "employee_id",
      width: 150,
      render: (employee_id: any, record) => {
        return (
          <Typography.Text>
            {employee_id === 0 ? (
              <Tag>{t("Trống")}</Tag>
            ) : (
              <Tag
                className="cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleClickCard(employee_id);
                  setShowContactFields(true);
                }}
              >
                <IdFetcherRender
                  value={record.employee_id}
                  mapping={employees}
                  key_value="text"
                />
              </Tag>
            )}
          </Typography.Text>
        );
      },
    },
    {
      title: t("shipperkpi:table_header.order"),
      key: "order_id",
      width: 120,
      render: (order_id) => (
        <Link
          to={"/order/edit/id/" + order_id}
          target="_blank"
          className="text-blue-500"
        >
          #{order_id}
        </Link>
      ),
    },
    {
      title: t("shipperkpi:table_header.district"),
      key: "district",
      width: 120,
      render: (ownDistrict) =>
        districts.find((district) => ownDistrict === district.location_id)
          ?.name,
    },
    {
      title: t("shipperkpi:table_header.store"),
      key: "store_id",
      width: 150,
      render: (store_id) => stores.find((store) => store_id === store.id)?.name,
    },
    {
      title: t("shipperkpi:table_header.coordinator"),
      key: "coordinator",
      width: 150,
      render: (coordinator) => (
        <Typography.Text>
          {coordinator === 0 ? (
            <Tag>{t("Trống")}</Tag>
          ) : (
            <Tag
              className="cursor-pointer mr-0"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleClickCard(coordinator);
                setShowContactFields(true);
              }}
            >
              <IdFetcherRender
                value={coordinator}
                mapping={coordinators}
                key_value="text"
              />
            </Tag>
          )}
        </Typography.Text>
      ),
    },
    {
      title: t("shipperkpi:table_header.shift"),
      key: "shift_time",
      width: 120,
      render: (shift_time) => (
        <Tag
          className="mr-0"
          color={ShipperKPIModel.getShift(shift_time)?.color}
        >
          {ShipperKPIModel.getShift(shift_time)?.label}
        </Tag>
      ),
    },
    {
      title: t("shipperkpi:table_header.status"),
      key: "status",
      width: 180,
      render: (status) => (
        <Tag className="mr-0" color={ShipperKPIModel.getStatus(status)?.color}>
          {ShipperKPIModel.getStatus(status)?.label}
        </Tag>
      ),
    },
    {
      title: t("shipperkpi:table_header.payment"),
      key: "payment",
      width: 120,
      render: (payment) => (
        <Tag
          className="mr-0"
          color={ShipperKPIModel.getPayment(payment)?.color}
        >
          {ShipperKPIModel.getPayment(payment)?.label}
        </Tag>
      ),
    },
    {
      title: t("shipperkpi:table_header.note"),
      key: "note",
      width: 200,
      render: (note) => <Typography.Text>{note}</Typography.Text>,
    },
    {
      title: t("shipperkpi:table_header.total_money"),
      key: "amount",
      width: 120,
      render: (amount) => <TextMoney money={amount} />,
    },
    {
      title: t("common:date_created"),
      key: "date_created",
      width: 120,
      render: (date_created) => Helper.formatTimestampToText2(date_created),
    },
    {
      title: "",
      toggletype: "trigger",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 120,
      render: (_, record) => {
        return <TableInfo record={record} className="mr-4" />;
      },
    },
  ];

  return (
    <>
      <ShipperKPIListHeader />
      <ShipperKPIListFilter
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      />
      <PageDataTable<FilterShipperKPI, ShipperKPIModel, ShipperKPICollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "shipperkpi",
        }}
      />
      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
      <PhoneBookModal
        id={modalId}
        visible={showContactFields}
        setVisible={(isVisible) => {
          setShowContactFields(isVisible);
        }}
      />
    </>
  );
};

export default ShipperKPIList;
