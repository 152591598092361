import LocationModel from "common/models/LocationModel";
import { LocationCollectionJson, LocationJson } from "common/types/Location";
import BaseCollection from "./BaseCollection";

class LocationCollection extends BaseCollection<
  LocationJson,
  LocationModel,
  LocationCollectionJson
> {
  itemsFromJson(jsonItems: LocationJson[]): LocationModel[] {
    return jsonItems.map((item) => new LocationModel(item));
  }
}
export default LocationCollection;
