import { Skeleton } from "antd";
import PromotionModel from "common/models/PromotionModel";
import PromotionRepository from "common/repositories/PromotionRepository";
import ErrorAlert from "components/ErrorAlert";
import FormModal from "components/form/FormModal";
import PromotionForm from "features/promotion/form/PromotionForm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const PromotionFormModal = ({
	id,
	group,
	open,
	setOpen,
	onSaveSuccess,
}: {
	id: number;
	group: number;
	open: boolean;
	setOpen: (v: boolean) => any;
	onSaveSuccess: (m: PromotionModel) => void;
}) => {
	const { t } = useTranslation();

	const [model, setModel] = useState<PromotionModel>(
		new PromotionModel(PromotionModel.getDefaultData())
	);
	const [errors, setErrors] = useState<string[]>([]);
	const [processing, setProcessing] = useState(false);

	const fetchDetail = async (id: number) => {
		let myObj = await new PromotionRepository().getItem(id);
		if (myObj.hasError()) {
			setErrors(myObj.error.errors);
		} else {
			setModel(myObj);
		}
	};

	useEffect(() => {
		if (id) {
			fetchDetail(id);
		}
		return () => {
			setErrors([]);
		};
	}, [id]);

	return (
		<FormModal
			destroyOnClose
			title={id > 0 ? t("promotion:heading_edit") : t("promotion:heading_add")}
			// width={720}
			open={open}
			setOpen={setOpen}
			confirmLoading={processing}
			formId="promotion-form"
			className="md:w-[80vw]"
			okButtonProps={{
				className: "hidden",
			}}
			cancelButtonProps={{
				className: "hidden",
			}}>
			{id > 0 ? (
				<>
					{model.id > 0 || errors.length > 0 ? (
						<>
							{errors.length > 0 ? (
								<ErrorAlert
									items={errors}
									heading={t("common:error.error_fetching_data")}
									onRetry={() => {
										setErrors([]);
										fetchDetail(id);
									}}
								/>
							) : (
								<PromotionForm
									model={model}
									setOpen={setOpen}
									setProcessing={setProcessing}
									onSaveSuccess={onSaveSuccess}
								/>
							)}
						</>
					) : (
						<>
							<Skeleton active />
						</>
					)}
				</>
			) : (
				<PromotionForm
					model={
						new PromotionModel({
							...PromotionModel.getDefaultData(),
						})
					}
					setOpen={setOpen}
					setProcessing={setProcessing}
					onSaveSuccess={onSaveSuccess}
				/>
			)}
		</FormModal>
	);
};

export default PromotionFormModal;
