import { CloseCircleOutlined, ImportOutlined } from "@ant-design/icons";
import {
	Button,
	Checkbox,
	Col,
	Form,
	Input,
	List,
	message,
	Row,
	Select,
	Spin,
	Tag,
	Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import Error from "common/api/Error";
import PromotionCouponRepository from "common/repositories/PromotionCouponRepository";
import FormChangedContext from "contexts/FormChangedContext";
import AddProductBySku from "features/productcollection/form/children/AddProductBySku";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import * as XLSX from "xlsx";
import ErrorComponent from "components/LayoutError";
import { useTranslation } from "react-i18next";
import { delay } from "common/utils/utils";
import PromotionCouponModel from "common/models/PromotionCouponModel";
import { isArray } from "lodash";
import { useWatch } from "antd/es/form/Form";

type Props = {
	initialValue: { codes: string[] };
	idEditing: number;
	onFinish?: () => void;
};

type ErrorDetail = {
	[key: string]: string[];
};
export default function CouponForm({
	initialValue,
	idEditing,
	onFinish,
}: Props) {
	const [messageApi, context] = message.useMessage();
	const [form] = Form.useForm();
	const formChangedProvider = useContext(FormChangedContext);
	const { t } = useTranslation();

	const initialValues = useMemo(() => {
		return { ...initialValue, coupon_scope: 1 };
	}, [initialValue]);

	const messageKey = "coupon-form-error";

	const inputRef = useRef<HTMLInputElement>(null);
	const [fileExcel, setfileExcel] = useState<File | null>(null);
	const [skuList, setSkuList] = useState<string[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [stopOnError, setStopOnError] = useState(true);
	const [codeErrors, setCodeErrors] = useState<string[]>([]);
	const [codeSuccess, setCodeSuccess] = useState<string[]>([]);

	const [codeErrorObject, setCodeErrorObject] = useState<ErrorDetail>({});

	const [process, setProcess] = useState(false);
	const pendingProcess = useRef(false);
	const isMounted = useRef(true);

	const couponCodes = useWatch("codes", form);

	// const [errors,setErrors] = useState([])

	const trimStringToArrValue = (val: string) => {
		if (val.length <= 0) {
			return [];
		}
		return val
			.trim()
			.replace(/\s+/g, "")
			.split(",")
			.filter((i) => i.length > 0)
			.map((i) => i.toUpperCase());
	};
	const codeAll = trimStringToArrValue(couponCodes || "");

	const handleFinish = async (data: any) => {
		setCodeErrorObject({});
		setCodeSuccess([]);
		setLoading(true);
		pendingProcess.current = false;
		const codeString = data.codes as string;
		const dataCodes = trimStringToArrValue(codeString);

		const res = await new PromotionCouponRepository().saveRemote({
			promotion_id: idEditing,
			codes: dataCodes,
			id: 0,
			stop_on_error: stopOnError,
			max_count_apply: +data.max_count_apply ? +data.max_count_apply : 0,
			coupon_scope: data.coupon_scope,
		});

		if (!res.hasError()) {
			messageApi.success("Tạo mã thành công!");
			form.resetFields();
			if (fileExcel) {
				setfileExcel(null);
			}
			onFinish && onFinish();
		} else {
			message.error({
				content: (
					<ErrorComponent
						onClickClose={() => {
							message.destroy(messageKey);
						}}
						heading={t("common:error.form_submit")}
						translate_prefix={"coupon"}
						items={res.error.errors}
					/>
				),
				className: "message_error",
				key: messageKey,
				duration: 10,
			});
			const errorDetail = res.error;
			handleErrorDetail(errorDetail, data.max_count_apply || 0, dataCodes);
			onFinish && onFinish();
		}
		setLoading(false);
	};

	const maxCodeInReaquest = 5;

	const handleErrorDetail = async (
		err: Error,
		max_count_apply: number,
		arrCodes?: string[]
	) => {
		setProcess(true);
		const codes = arrCodes || [];
		const errorCodes = [
			"error_code_invalid",
			"error_code_exist",
			"error_add_code",
		];
		try {
			const errorDetail = err.error_detail as ErrorDetail[] | undefined;
			if (
				errorDetail &&
				errorDetail.length > 0 &&
				Object.keys(errorDetail[0]).some((k: string) => errorCodes.includes(k))
			) {
				const dataErrorObject = errorDetail[0];
				const codeCreateErrors = Object.values(dataErrorObject).flatMap(
					(i) => i
				);

				if (!stopOnError) {
					const hasError = Object.keys(codeErrorObject).some((i) =>
						["error_code_exist", "error_add_code"].includes(i)
					);
					if (hasError) {
						const codeErrorss = Object.values(dataErrorObject).flatMap(
							(item) => item
						) as string[];
						const codeCreateSucess = codeAll.filter(
							(c) => !codeErrorss.includes(c)
						);
						const codeRemoceSuccess = codeAll.filter(
							(c) => !codeCreateSucess.includes(c)
						);
						setCodeSuccess(codeCreateSucess);
						form.setFieldValue("codes", codeRemoceSuccess.join(","));
					}
				}

				setCodeErrorObject((prev) => ({
					...prev,
					...dataErrorObject,
				}));

				// const codeErrors = [
				// 	"ad",
				// 	"á",
				// 	"ds",
				// 	"d",
				// 	"á",
				// 	"dfs",
				// 	" á",
				// 	"á",
				// 	"ádf",
				// 	"á",
				// 	"ds",
				// 	"á",
				// ];

				// const indexCodeError = codes.findIndex((i) => i === codeFirstError);
				// const codeErrs = codes.slice(indexCodeError);

				const codeErrs = codeErrors;

				setCodeErrors(codeErrs);
				if (!stopOnError && codeErrs.length > 0) {
					await delay(1000);
					if (!isMounted.current || pendingProcess.current) return;
					if (codeErrs.length > maxCodeInReaquest) {
						let resultMax: string[][] = [];
						for (let i = 0; i < codeErrs.length; i += maxCodeInReaquest) {
							let subArray = codeErrs.slice(i, i + maxCodeInReaquest);
							resultMax.push(subArray);
						}
						const promises = resultMax.map((subCodes) =>
							new PromotionCouponRepository().saveRemote({
								promotion_id: idEditing,
								codes: subCodes,
								id: 0,
								stop_on_error: stopOnError,
								max_count_apply: max_count_apply,
							})
						);

						await Promise.allSettled(promises).then((results) => {
							results.forEach(async (res) => {
								if (res.status === "fulfilled") {
									const valueCreate = res.value as PromotionCouponModel;
									if (valueCreate.hasError()) {
										await handleErrorDetail(valueCreate.error, max_count_apply);
									}
								} else if (res.status === "rejected") {
									return;
								}
							});
						});
					} else {
						const res = await new PromotionCouponRepository().saveRemote({
							promotion_id: idEditing,
							codes: codeErrs,
							id: 0,
							max_count_apply: max_count_apply,
							stop_on_error: stopOnError,
						});
						if (res.hasError()) {
							await handleErrorDetail(res.error, max_count_apply);
						}
					}
				}
			}
		} catch (error) {
			console.log("🚀 ~ error:", error);
		} finally {
			setProcess(false);
		}
	};

	const uploadFileExcelAndRead = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];

		if (file) {
			const reader = new FileReader();
			reader.onload = (event) => {
				const binaryStr = event.target?.result;
				if (typeof binaryStr === "string") {
					const workbook = XLSX.read(binaryStr, { type: "binary" });
					const sheetName = workbook.SheetNames[0];
					const worksheet = workbook.Sheets[sheetName];
					const jsonData = XLSX.utils.sheet_to_json<string[]>(worksheet, {
						header: 1,
					});
					//  SKU in row 1
					const skuList = jsonData
						.slice(1)
						.map((row: any) => row[0] as string) as string[];
					setSkuList(skuList);
					if (skuList.length > 0) {
						form.setFieldValue(
							"codes",
							skuList
								.reduce((curr: string[], prev) => {
									if (prev && prev.toString().length > 0) {
										curr.push(prev.toString().trim());
									}
									return curr;
								}, [])
								.join(",")
								.trim()
						);
					}
				}
			};
			setfileExcel(file);
			reader.readAsBinaryString(file);
		}
	};

	//////////////////////////////////////

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);
	return (
		<>
			{context}
			{/* <div className="mb-4">
				<input
					ref={inputRef}
					type="file"
					onChange={uploadFileExcelAndRead}
					className=" hidden"
					accept=".xls, .xlsx"></input>
				{fileExcel?.name ? (
					<p>
						{fileExcel?.name}{" "}
						<CloseCircleOutlined
							onClick={() => {
								if (inputRef?.current) {
									inputRef.current.value = "";
									setfileExcel(null);
								}
							}}
						/>
					</p>
				) : (
					<Button
						onClick={() => {
							if (inputRef.current) {
								inputRef.current.click();
							}
						}}
						icon={<ImportOutlined />}>
						Import từ file excel
					</Button>
				)}
			</div> */}
			<Form
				form={form}
				initialValues={initialValues}
				onFinish={handleFinish}
				id={formChangedProvider.id}
				onFieldsChange={() => formChangedProvider.setChanged(true)}>
				<Form.Item hidden name={"product_id"}></Form.Item>
				<Form.Item
					labelCol={{ span: 24 }}
					// help="Nhập mã cách nhau bởi dấu phẩy"
					label="Nhập mã code"
					rules={[
						{
							required: true,
							message: "Vui lòng nhập",
						},
						{
							message: "Tồn tại mã không hợp lệ",
							validator: (_, value: string) => {
								if (
									Object.keys(codeErrorObject).some((i) =>
										["error_code_invalid"].includes(i)
									) &&
									codeErrorObject?.["error_code_invalid"] &&
									isArray(codeErrorObject["error_code_invalid"]) &&
									codeErrorObject["error_code_invalid"].some((i) =>
										trimStringToArrValue(value).includes(i)
									)
								) {
									return Promise.reject("error");
								}

								return Promise.resolve("1");
							},
						},
					]}
					initialValue={initialValue.codes.join(",")}
					name={"codes"}>
					<TextArea
						className="uppercase"
						placeholder="Nhập list mã code"
						value={initialValue.codes.join(",")}
						onChange={(e) => {
							e.preventDefault();
							// setSkuValue(e.target.value);
							// handleChangeInput(e.target.value);
						}}
					/>
				</Form.Item>
				<Form.Item
					labelCol={{ span: 24 }}
					label="Số lần áp dụng"
					help="lưu ý: Nếu bỏ trống hoặc bằng 0 số lần áp dụng sẽ là vô hạn"
					name={"max_count_apply"}>
					<Input type="number" />
				</Form.Item>
				<Form.Item
					labelCol={{ span: 24 }}
					label="Cho phép hiển thị trên website"
					name={"coupon_scope"}>
					<Select
						options={[
							{ label: "hiện", value: 1 },
							{ label: "ẩn", value: 3 },
						]}
					/>
				</Form.Item>
				<Form.Item>
					<div className="mt-2">
						<Checkbox
							checked={stopOnError}
							onChange={(e) => {
								setCodeErrorObject({});
								setStopOnError(e.target.checked);
							}}>
							Dừng khi xảy ra lỗi
						</Checkbox>
					</div>
				</Form.Item>
				{codeSuccess.length > 0 && (
					<Row>
						<Col span={24}>
							<List
								className="max-h-[60vh] overflow-auto"
								header={
									<Typography.Text className="font-bold text-green-500">
										{t(`promotion:coupon.key.create_success`)}:
									</Typography.Text>
								}
								dataSource={codeSuccess}
								renderItem={(item, index) => {
									return (
										<List.Item>
											<Tag color="blue">
												{index + 1}.{" "}
												<Typography.Text copyable className="text-blue-500">
													{item}
												</Typography.Text>
											</Tag>
										</List.Item>
									);
								}}></List>
						</Col>
					</Row>
				)}

				<Row className="bg-gray-100" gutter={4}>
					{Object.entries(codeErrorObject).map(([key, value]) => {
						return (
							<Col span={24}>
								<List
									className="max-h-[60vh] overflow-auto"
									header={
										<Row justify={"space-between"}>
											<Col>
												<Typography.Text className="font-bold text-red-500">
													{t(`promotion:coupon.key.${key}`)}:
												</Typography.Text>
											</Col>
											<Col>
												<Button
													onClick={() => {
														form.setFieldValue(
															"codes",
															codeAll
																.filter((c) => !value.includes(c))
																.join(",")
														);
													}}
													size="small">
													Loại bỏ khỏi ô nhập
												</Button>
											</Col>
										</Row>
									}
									dataSource={value}
									renderItem={(item, index) => {
										return (
											<List.Item>
												<Tag color="blue">
													{index + 1}.{" "}
													<Typography.Text copyable className="text-blue-500">
														{item}
													</Typography.Text>
												</Tag>
											</List.Item>
										);
									}}></List>
							</Col>
						);
					})}
				</Row>

				{/* {codeErrors.length > 0 && !stopOnError && (
					<Form.Item>
						<div className="bg-gray-100 p-4 mt-2">
							<Typography.Text className="font-bold block">
								Danh sách mã code gặp lỗi <Tag>{codeErrors.length} mã</Tag>
							</Typography.Text>
							<Typography.Text type="secondary" className=" block">
								Các mã code sẽ được chạy lại. Vui lòng đợi{" "}
								<Spin size="small" spinning={process}></Spin>
							</Typography.Text>

							<List
								className="max-h-[60vh] overflow-auto"
								dataSource={codeErrors}
								renderItem={(item, index) => {
									return (
										<List.Item>
											<Tag color="blue">
												{index + 1}.{" "}
												<Typography.Text copyable className="text-blue-500">
													{item}
												</Typography.Text>
											</Tag>
										</List.Item>
									);
								}}></List>
						</div>
					</Form.Item>
				)} */}

				<Form.Item className="mt-4">
					<Button
						loading={loading || process}
						className="mt-4"
						htmlType="submit"
						type="primary">
						Xác nhận
					</Button>
					{process && (
						<Button
							className="ml-2"
							type="default"
							onClick={() => {
								pendingProcess.current = true;
							}}>
							Hủy
						</Button>
					)}
				</Form.Item>
			</Form>
		</>
	);
}
