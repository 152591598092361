import { Button, Modal, Tag } from "antd";
import Role from "common/constants/Role";
import DeliveryModel from "common/models/DeliveryModel";
import InventoryReceiptModel from "common/models/InventoryReceiptModel";
import {
	InventoryReceiptJson,
	InventoryReceiptTargetType,
} from "common/types/InventoryResceipt";
import { cn } from "common/utils/utils";
import RoleCheck from "components/RoleCheck";
import DeliveryDetail from "features/delivery/detail/DeliveryDetail";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const InventoryReceiptTarget = ({
	target,
}: {
	target: InventoryReceiptJson["target"];
}) => {
	const [view, setView] = useState(false);

	const dataLabel = InventoryReceiptModel.getTargetLabel(target.type);
	return (
		<>
			<Tag
				className={cn("", {
					"cursor-pointer": target.type === InventoryReceiptTargetType.DELIVERY,
				})}
				onClick={() => {
					target.type === InventoryReceiptTargetType.DELIVERY && setView(true);
				}}
				color={dataLabel.color}>
				{dataLabel.label}
			</Tag>
			<Modal
				title=""
				className="min-w-[70vw]"
				destroyOnClose
				open={view}
				okButtonProps={{ className: "hidden" }}
				footer={
					<RoleCheck roles={[Role.DELIVERY_MANAGER]}>
						<Link
							target="_blank"
							to={`/delivery/manager_detail/id/${target.id}`}
							className="my-2 block">
							Đến chỉnh sửa vận đơn
						</Link>
					</RoleCheck>
				}
				onCancel={() => {
					setView(false);
				}}>
				{target.type === InventoryReceiptTargetType.DELIVERY && (
					<div>
						<DeliveryDetail
							type="default"
							model={new DeliveryModel(DeliveryModel.getDefaultData())}
							id={target.id}></DeliveryDetail>
					</div>
				)}
			</Modal>
		</>
	);
};

export default InventoryReceiptTarget;
