import ContractAndDeviceApi from "common/api/ContractAndDeviceApi";
import DeviceCollection from "common/collections/DeviceCollection";
import ContractAndDeviceModel from "common/models/ContractAndDeviceModel";
import { ContractAndDeviceAddEditJson, FilterContractAndDevice } from "common/types/Device";
import { GetListProps } from "common/types/Filter";
import BaseRepository from "./BaseRepository";

class ContractAndDeviceRepository extends BaseRepository<ContractAndDeviceApi> {
  _api: ContractAndDeviceApi | null;

  constructor() {
    super();
    this._api = new ContractAndDeviceApi(true);
  }

  static getDefaultFilters(): FilterContractAndDevice {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      creator_id: -1,
      holder_id: -1,
      source_status: -1,
    };
  }

  async getItems(props: GetListProps<FilterContractAndDevice>) {
    return this._api ? await this._api.getList(props) : new DeviceCollection();
  }

  async getItem(id: number): Promise<ContractAndDeviceModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new ContractAndDeviceModel(ContractAndDeviceModel.getDefaultData());
  }

  async saveRemote(data: ContractAndDeviceAddEditJson): Promise<ContractAndDeviceModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new ContractAndDeviceModel(ContractAndDeviceModel.getDefaultData());
    }
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }
}

export default ContractAndDeviceRepository;
