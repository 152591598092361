import {
	Button,
	Col,
	DatePicker,
	Form,
	Image,
	Input,
	Modal,
	Popconfirm,
	Row,
} from "antd";
import Helper from "common/utils/helper";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FileModel from "common/models/FileModel";
import { SectionAboutJson } from "./ShopSectionAboutEdit";
import ReactQuill from "react-quill";
import FileUploaderButton from "components/file/uploader/FileUploaderButton";
import { IconPhoto } from "@tabler/icons-react";

export default function ShopSectionAboutForm({
	data,
	open,
	type = "add",
	onFinish,
	onCancel,
}: {
	type: "add" | "edit";
	data: SectionAboutJson;
	open: boolean;
	onFinish: (data: SectionAboutJson) => void;
	onCancel: () => void;
}) {
	const { t } = useTranslation();

	const [form] = Form.useForm<any>();

	const toolbarOptions = [
		[{ font: [] }],
		// [{ header: [1, 2, 3, 4, 5, 6, false] }],
		[{ size: ["small", false, "large", "huge"] }],
		["bold", "italic", "underline", "strike"],
		[{ align: [] }],
		[{ color: [] }, { background: [] }],
		[{ script: "super" }, { script: "sub" }],
		[{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
		[
			{ list: "ordered" },
			{ list: "bullet" },
			{ indent: "-1" },
			{ indent: "+1" },
		],
		["direction"],
		["link", "image", "video", "formula"],
		["clean"],
	];

	const initialValues = {
		title: data.title,
		icon: FileModel.convertToUploadFiles([
			new FileModel({
				...FileModel.getDefaultData(),
				// url: data.image,
				// extension: "jpg",
				// md5_hash: data.image,
				// id: 999,
			}),
		]),
		// icon: data.icon,
		content: data.content,
	};

	//state
	const [imageIcon, setImageIcon] = useState<string>(data.icon);
	const [loading, setLoading] = useState<boolean>(false);

	const doPrepareData = useCallback(
		(formData: any) => {
			const submitData: any = {
				...data,
				title: formData.title,
				// icon:
				//   formData.icon && formData.icon.length > 0 && formData.icon[0].response
				//     ? formData.icon[0]?.response
				//     : FileModel.getDefaultData(),
				icon: imageIcon,
				content: formData.content,
			};

			return submitData;
		},
		[data, imageIcon]
	);

	const onSubmit = async (formData: any) => {
		console.log(doPrepareData(formData));
		setLoading(true);
		await onFinish({
			...data,
			...doPrepareData(formData),
		});
		setLoading(false);
	};

	useEffect(() => {
		form.setFieldsValue({
			...(initialValues as any),
		});
	}, [data, form]);

	useEffect(() => {
		if (data.icon.length > 0) {
			setImageIcon(data.icon);
		}
	}, [data]);

	return (
		<Modal
			title={type === "edit" ? "Cập nhật" : "Thêm mới"}
			open={open}
			footer={<></>}
			onCancel={() => {
				form.resetFields();
				onCancel();
			}}
			maskClosable={false}
			destroyOnClose>
			<>
				<Form
					form={form}
					layout="vertical"
					initialValues={initialValues}
					onFinish={onSubmit}
					autoComplete="off">
					<Row gutter={[16, 16]}>
						<Col md={24} xs={24}>
							<Form.Item
								label="Tiêu đề"
								name="title"
								rules={[{ required: true, message: "Vui lòng nhập" }]}>
								<Input />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={[16, 16]}>
						<Col md={24} xs={24}>
							<Form.Item
								name="content"
								label={t("story:detail")}
								required
								validateFirst
								rules={[
									{
										required: true,
										message: t("story:rules.detail"),
									},
									{
										message: t("story:rules.detail"),
										validator: (_: any, value: string) => {
											if (value !== "<p><br></p>") {
												return Promise.resolve();
											} else {
												return Promise.reject("Some message here");
											}
										},
									},
								]}>
								<ReactQuill
									theme="snow"
									style={{
										height: "300px",
										width: "100%",
										marginBottom: "90px",
									}}
									modules={{
										toolbar: toolbarOptions,
									}}
								/>
							</Form.Item>
						</Col>

						<Row gutter={[16, 16]}>
							<Col md={24}>
								{imageIcon.length > 0 ? (
									<>
										<Popconfirm
											title="Bạn có muốn xóa ảnh"
											onConfirm={() => {
												setImageIcon("");
												form.setFieldValue("image", []);
											}}>
											<Image
												className="cursor-pointer"
												src={imageIcon}
												width={100}
												height={100}
												preview={false}
											/>
										</Popconfirm>
									</>
								) : (
									<>
										<Form.Item className="m-0">
											<FileUploaderButton
												form={form}
												formName="image"
												label={t("Ảnh")}
												icon={<IconPhoto size="24" color="grey" />}
												origin="avatar"
												uploadRenderType="thumbnail"
												isSingleFile={true}
												maxCount={1}
												initFiles={[]}
												onUploadCompleted={(f) => {
													setImageIcon(f.url);
												}}
											/>
										</Form.Item>
									</>
								)}
							</Col>
						</Row>

						{/* <Col md={24} xs={24}>
              <Form.Item className="m-0">
                <FileUploaderButton
                  form={form}
                  formName="icon"
                  label={t("Icon")}
                  icon={<IconPhoto size="24" color="grey" />}
                  origin="avatar"
                  uploadRenderType="thumbnail"
                  isSingleFile={true}
                  maxCount={1}
                  initFiles={initialValues.icon}
                />
              </Form.Item>
            </Col> */}
					</Row>

					<Form.Item className="mt-4">
						<div className="flex gap-4">
							<Button
								type="primary"
								htmlType="submit"
								loading={loading}
								disabled={loading}>
								Hoàn tất
							</Button>
							<Button
								type="default"
								onClick={() => {
									form.resetFields();
									onCancel();
								}}>
								Đóng
							</Button>
						</div>
					</Form.Item>
				</Form>
			</>
		</Modal>
	);
}
