import { valueType } from "antd/es/statistic/utils";
import { cn } from "common/utils/utils";
import { NumericFormat } from "react-number-format";

interface TextMoneyProps {
	money: valueType;
	noSymbol?: boolean;
	className?: string;
	prefix?: string; // Thêm prop prefix
}

const TextMoney: React.FC<TextMoneyProps> = ({
	money,
	noSymbol,
	className,
	prefix = "", // Giá trị mặc định cho prefix
}) => {
	// Thay đổi giá trị prefix truyền vào từ props
	const symbolSuffix = " ₫";
	const thousandSeparator = ",";
	const decimalScale = 0;

	return (
		<NumericFormat
			className={cn("", className)}
			prefix={!!noSymbol ? "" : prefix}
			suffix={!!noSymbol ? "" : symbolSuffix}
			value={money}
			displayType="text"
			thousandSeparator={thousandSeparator}
			decimalScale={decimalScale}
		/>
	);
};

export default TextMoney;
