import { Typography } from "antd";
import ContactMailModel from "common/models/ContactMailModel";
import { t } from "i18next";
import { memo, useMemo } from "react";
import { Link } from "react-router-dom";

interface IContactMailListExpandProps {
	record: ContactMailModel | null;
}

const ContactMailListExpand = ({
	record,
}: IContactMailListExpandProps): JSX.Element => {
	console.log("🚀 ~ record:", record);

	const parseJson = (str: string) => {
		try {
			return JSON.parse(str);
		} catch (e) {
			return str;
		}
	};

	const detail: any = useMemo(
		() => (record?.detail ? parseJson(String(record?.detail)) : null),
		[record?.detail]
	);

	return (
		<Typography className="w-full bg-white p-2 border border-[#d9d9d9] rounded block whitespace-pre-line">
			<Typography.Text className="font-bold mr-1">Chi tiết:</Typography.Text>
			<Typography.Text>
				{(record?.detail?.length && detail?.url) ? (
					<Link
						target="_blank"
						to={detail?.url}
						className="ml-1 uppercase font-semibold">
						{detail?.url}
					</Link>
				) : (
					t("contactmail:no_content")
				)}
			</Typography.Text>
		</Typography>
	);
};

export default memo(ContactMailListExpand);
