import ColorHash from "color-hash";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Avatar, Col, Row, Skeleton, Typography } from "antd";

import FileModel from "common/models/FileModel";
import PhoneBookModel from "common/models/PhoneBookModel";
import PhoneBookRepository from "common/repositories/PhoneBookRepository";
import IdFetcherRender from "components/idfetcher/IdFetcherRender";
import useIdFetcher from "hooks/useIdFetcher";
import { IdFetcherResult } from "common/types/IdFetcher";
import TextOffice from "components/TextOffice";
import TextDateTimeRelative from "components/TextDateTimeRelative";
import TextDateTime from "components/TextDateTime";
import Helper from "common/utils/helper";
import PhoneBookCheckin from "./PhoneBookCheckin";
import ErrorAlert from "components/ErrorAlert";

const PhoneBookDetails = ({ id }: { id: number }) => {
	const { t } = useTranslation();

	const { getListIdFetcher } = useIdFetcher();

	const [loading, setLoading] = useState(false);

	const [model, setModel] = useState<PhoneBookModel>(
		new PhoneBookModel(PhoneBookModel.getDefaultData())
	);
	const [department, setDepartment] = useState<IdFetcherResult[]>([]);

	const fetchDetailAccount = async (id: number) => {
		setLoading(true);
		let item = await new PhoneBookRepository().getItem(id);

		setModel(item);
		setLoading(false);
	};

	const formatTime = useCallback((time: number) => {
		if (Helper.checkTimestampInDay(time)) {
			return "HH:ss";
		}

		return "HH:ss DD/MM/YYY";
	}, []);

	const isOnline = useMemo(() => {
		const timeCheckin = model.last_checkin;
		const timeCheout = model.last_checkout;
		if (
			Helper.checkTimestampInDay(timeCheckin) ||
			Helper.checkTimestampInDay(timeCheout)
		) {
			return true;
		}
		return false;
	}, [model.last_checkin, model.last_checkout]);

	useEffect(() => {
		if (id > 0) {
			fetchDetailAccount(id);
		}
	}, [id]);

	if (model.hasError()) {
		return (
			<ErrorAlert
				className="text-xs"
				type="warning"
				heading={"Load employee detail error (Employee ID: " + id + ")"}
				items={model.error.errors}
				translate_prefix="cashflowreceipt:target"
	  		/>
		);
	};

	return (
		<>
			{loading ? (
				<Skeleton active />
			) : (
				<>
					<div className="relative w-[165px] h-[165px] my-0 mx-auto">
						{model.avatar.id > 0 ? (
							<Avatar
								shape="circle"
								size={165}
								src={FileModel.getThumbnailFromUrl(
									model.avatar.file_path
								)}></Avatar>
						) : (
							<Avatar
								shape="circle"
								size={165}
								style={{
									backgroundColor: new ColorHash().hex(model.full_name),
								}}>
								{model.full_name
									.trim()
									.split(" ")
									.slice(-1)
									.toString()
									.charAt(0)}
							</Avatar>
						)}
						<div
							className={`border-[2px] border-white absolute w-[20px] h-[20px] bottom-[10px] right-[15px] ${
								isOnline ? "bg-[#99DD4A]" : "bg-gray-300"
							}  rounded-full z-[99]`}></div>
					</div>

					<div className="my-0 mx-auto p-8">
						<Row gutter={{ xs: 8, sm: 16 }} className="center">
							<Col span={24} sm={10} className="font-semibold">
								<Typography.Text>
									{t("phonebook:modal.employee_code")}:
								</Typography.Text>
							</Col>
							<Col span={24} sm={14}>
								<Typography.Text>{model.internal_id}</Typography.Text>
							</Col>
						</Row>
						<Row gutter={{ xs: 8, sm: 16 }}>
							<Col span={24} sm={10} className="font-semibold">
								<Typography.Text>
									{t("phonebook:modal.full_name")}:
								</Typography.Text>
							</Col>
							<Col span={24} sm={14}>
								<Typography.Text>{model.full_name}</Typography.Text>
							</Col>
						</Row>
						<Row gutter={{ xs: 8, sm: 16 }}>
							<Col span={24} sm={10} className="font-semibold">
								<Typography.Text>{t("phonebook:modal.email")}:</Typography.Text>
							</Col>
							<Col span={24} sm={14}>
								<Typography.Text>{model.work_email || "--"}</Typography.Text>
							</Col>
						</Row>
						<Row gutter={{ xs: 8, sm: 16 }}>
							<Col span={24} sm={10} className="font-semibold">
								<Typography.Text>{t("phonebook:modal.phone")}:</Typography.Text>
							</Col>
							<Col span={24} sm={14}>
								<Typography.Text>{model.work_phone || "--"}</Typography.Text>
							</Col>
						</Row>

						<Row gutter={{ xs: 8, sm: 16 }}>
							<Col span={24} sm={10} className="font-semibold">
								<Typography.Text>
									{t("phonebook:modal.office")}:
								</Typography.Text>
							</Col>
							<Col span={24} sm={14}>
								<Typography.Text className="capitalize">
									<TextOffice id={Number(model.office_id)} />
								</Typography.Text>
							</Col>
						</Row>
						<Row gutter={{ xs: 8, sm: 16 }}>
							<Col span={24} sm={10} className="font-semibold">
								<Typography.Text>
									{t("phonebook:modal.position")}:
								</Typography.Text>
							</Col>
							<Col span={24} sm={14}>
								<Typography.Text className="capitalize">
									{model.job_title}
								</Typography.Text>
							</Col>
						</Row>
						<Row gutter={{ xs: 8, sm: 16 }}>
							<Col span={24} sm={10} className="font-semibold">
								<Typography.Text>
									{t("phonebook:modal.department")}:
								</Typography.Text>
							</Col>
							<Col span={24} sm={14}>
								<Typography.Text className="capitalize">
									{model.department_id}
								</Typography.Text>
							</Col>
						</Row>
						<PhoneBookCheckin
							item={{
								last_checkin: model.last_checkin,
								last_checkout: model.last_checkout,
								last_login: model.last_login,
							}}
							titleCheckin="Checkin gần nhất"
							titleCheckout="Checkout gần nhất"
							rowProps1={{
								props: {
									className: "justify-unset",
								},
								col1: {
									span: 24,
									sm: 10,
									className: "text-left px-1",
									style: { padding: "0 4px" },
								},
								col2: { span: 24, sm: 10 },
							}}
							rowProps2={{
								props: {
									className: "justify-unset",
								},
								col1: {
									span: 24,
									sm: 10,
									className: "text-left px-1",
									style: { padding: "0 4px" },
								},
								col2: { span: 24, sm: 10 },
							}}></PhoneBookCheckin>
					</div>
				</>
			)}
		</>
	);
};

export default PhoneBookDetails;
