import { Form, Input } from "antd";
import Role from "common/constants/Role";
import TaxInvoiceModel from "common/models/TaxInvoiceModel";
import { Filter } from "common/types/Filter";
import { StoreJson } from "common/types/Store";
import DateFormFilter from "components/datetime/DateFormFilter";
import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import dayjs, { Dayjs } from "dayjs";
import StoreFormSelect from "features/store/StoreFormSelect";
import useDatabaseTable from "hooks/useDatabaseTable";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { RangePickerProps } from "antd/es/date-picker";
import CustomerFormFilter from "features/customer/form/CustomerFormFilter";

type RangeValue = [Dayjs | null, Dayjs | null] | null;

const TaxInvoiceListFilter = <F extends Filter>({
  filters,
  setFilters,
  defaultFilters,
  total
}: {
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
  total: number;
}) => {
  const { t } = useTranslation();

  const [storeItemsWithLimit] = useDatabaseTable<StoreJson>(
    "store",
    Role.TAX_INVOICE_VIEW
  );

  // FILTER: DATESTARTED AND DATEENDED
  const getDateCreatedFilter = useMemo(() => {
    let entries = Object.entries(filters)
      .filter(([key, val]) => key === "date_started" || key === "date_ended")
      .map(([key, val]) => {
        return val;
      }) as number[];
    return (
      +entries[0] > 0 && +entries[1] > 0
        ? [
            dayjs(dayjs.unix(entries[0]).format("DD/MM/YYYY"), "DD/MM/YYYY"),
            dayjs(dayjs.unix(entries[1]).format("DD/MM/YYYY"), "DD/MM/YYYY")
          ]
        : [null, null]
    ) as RangeValue;
  }, [filters]);

  const onChangeDateCreatedFilter: RangePickerProps["onChange"] = (
    value: RangePickerProps["value"],
    dateString: [string, string]
  ) => {
    if (typeof value !== "undefined" && value !== null) {
      setFilters({
        ...filters,
        date_started: +dayjs(
          "00:00, " + value[0]?.format("DD/MM").toString(),
          "HH:mm, DDMM"
        ).unix(),
        date_ended: +dayjs(
          "23:59, " + value[1]?.format("DD/MM").toString(),
          "HH:mm, DDMM"
        ).unix()
      });
    } else {
      setFilters({
        ...filters,
        date_started: -1,
        date_ended: -1
      });
    }
  };

  return (
    <PageDataFilterForm
      filters={filters}
      setFilters={setFilters}
      total={total}
      defaultFilters={defaultFilters}
      convertValueByKeyFilter={[
        "seller_store_id",
        "order_id",
        "buyer_receive_method",
				"payment_method",
        "status"
      ]}>
      <Form.Item name="order_id">
        <Input
          allowClear
          placeholder={t("taxinvoice:filter_order_id_placeholder")}
        />
      </Form.Item>
      <Form.Item name="code">
        <Input
          allowClear
          placeholder={t("taxinvoice:code")}
        />
      </Form.Item>
      <Form.Item name="keyword">
        <Input
          allowClear
          placeholder={t("taxinvoice:filter_keyword_placeholder")}
        />
      </Form.Item>
      <StoreFormSelect
        placeholder={t("taxinvoice:filter_seller_store_id")}
        name="seller_store_id"
        allowClear
        defaultOptions={storeItemsWithLimit.map((i) => ({
          label: i.name,
          value: i.id
        }))}
        popupMatchSelectWidth={false}
      />
      <CustomerFormFilter
        name="buyer_customer_id"
        allowClear
        placeholder={t("taxinvoice:buyer_customer")}
        popupMatchSelectWidth={false}
      />
      <FormSelect
        name="invoice_type"
        placeholder={t("taxinvoice:invoice_type")}
        options={TaxInvoiceModel.getInvoiceTypeList()}
        allowClear
        popupMatchSelectWidth={false}
      />
      <FormSelect
        name="buyer_receive_method"
        placeholder={t("taxinvoice:buyer_receive_method")}
        options={TaxInvoiceModel.getBuyerReceiveMethodList()}
        allowClear
        popupMatchSelectWidth={false}
      />
      <FormSelect
				name="payment_method"
				placeholder={t("cashflowreceipt:method_sort")}
				options={TaxInvoiceModel.getPaymentMethodList()}
				allowClear
				popupMatchSelectWidth={false}
			/>
      <FormSelect
        name="status"
        placeholder={t("common:status")}
        options={TaxInvoiceModel.getStatusList()}
        allowClear
        popupMatchSelectWidth={false}
      />
      {/* FILTER: DATESTART AND DATEEND */}
      <Form.Item label={t("common:date_created")} labelCol={{ span : 6}}> 
        <DateFormFilter
          rangeValue={getDateCreatedFilter}
          allowClear={true}
          showNow={false}
          mode="range"
          format="DD/MM/YYYY"
          onRangeChange={onChangeDateCreatedFilter}
        />
      </Form.Item>
    </PageDataFilterForm>
  );
};

export default TaxInvoiceListFilter;
