import StoreWarehouseCollection from "common/collections/StoreWarehouseCollection";
import type { GetListProps } from "common/types/Filter";
import { FilterStoreWarehouse } from "common/types/StoreWarehouse";
import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";
import StoreWarehouseModel from "common/models/StoreWarehouseModel";

const SERVICE_URL = "/warehouses/storewarehouses";

class StoreWarehouseApi extends BaseApi {
  async getList(props: GetListProps<FilterStoreWarehouse>): Promise<StoreWarehouseCollection> {
    let collection = new StoreWarehouseCollection();

    try {
      let filters = props.filters;

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          keyword: filters.keyword ? filters.keyword : null,
          store_id: filters.store_id > -1 ? filters.store_id : null,
          store_ids: filters.store_ids ? filters.store_ids : null,
          warehouse_id: filters.warehouse_id > -1 ? filters.warehouse_id : null,
          warehouse_ids: filters.warehouse_ids ? filters.warehouse_ids : null,
        },
      };

      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);

      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async add(data: any): Promise<StoreWarehouseModel> {
    let item = new StoreWarehouseModel({ ...StoreWarehouseModel.getDefaultData(), ...data });

    try {
      let postData = {
        ...data,
      };

      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new StoreWarehouseModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
      if (response.status !== 204) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }
}

export default StoreWarehouseApi;
