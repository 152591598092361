import NewsletterList from "features/newsletter/list/NewsletterList";
import usePathParams from "hooks/usePathParams";

const NewsletterPage = (): JSX.Element => {
  const [pathParams] = usePathParams();

  let com = <NewsletterList />;
  switch (pathParams.action) {
    default:
      com = <NewsletterList />;
  }

  return com;
};

export default NewsletterPage;
