import { useTranslation } from "react-i18next";

import PageDataFilterForm from "components/page/PageDataFilterForm";

import { Button, Col, Form, Input, Row } from "antd";
import { Filter } from "common/types/Filter";
import { useEffect, useState } from "react";
import ProductCollectionFormSelectFilter from "features/product/list/ProductCollectionFormSelectFilter";
import ProductCollectionModel from "common/models/ProductCollectionModel";
import FormSelect from "components/form/FormSelect";
import TagModel from "common/models/TagModel";
import TagTypeModel from "common/models/TagTypeModel";
import TagTypeRepository from "common/repositories/TagTypeRepository";
import { IS_ROOT, TagResourceType } from "common/types/Tag";
import { AddListToItemComponentType } from "./AddListToItemComponent";

const AddListToItemFilter = <F extends Filter>({
	total,
	filters,
	setFilters,
	defaultFilters,
	onSearchKeyword,
	type,
}: {
	total: number;
	filters: any;
	setFilters: (f: F) => any;
	defaultFilters: F;
	type: AddListToItemComponentType;
	onSearchKeyword?: (keyword: string) => void;
}) => {
	const { t } = useTranslation();
	// const [keyword, setKeyword] = useState<string>("");
	const [types, setTypes] = useState<TagTypeModel[]>([]);
	const [selectedProductCollection, setSelectedProductCollection] =
		useState<ProductCollectionModel>(
			new ProductCollectionModel(ProductCollectionModel.getDefaultData())
		);
	const [tagIsRoot, setTagIsRoot] = useState<number>(-1);
	const [typeId, setTypeId] = useState<number>(-1);

	const onFilters = (formData: any) => {
		const keyword = formData.keyword;
		if (onSearchKeyword) {
			onSearchKeyword(formData.keyword);
		} else {
			if (["productintag", "productrelated", "product"].includes(type)) {
				if (selectedProductCollection.id > 0) {
					setFilters({
						...filters,
						keyword: keyword,
						collection_id:
							selectedProductCollection.id > 0
								? selectedProductCollection.id
								: undefined,
						...formData,
					});
				} else {
					setFilters({
						...filters,
						keyword: keyword,
						...formData,
					});
				}
			} else if (
				[
					"tagproduct",
					"tagproductcollection",
					"tagstorycollection",
					"tagstory",
					"tagbrand",
				].includes(type)
			) {
				setFilters({
					...filters,
					keyword: keyword,
					is_root: tagIsRoot > 0 ? tagIsRoot : -1,
					type: typeId,
					...formData,
				});
			} else {
				setFilters({ ...filters, keyword: keyword, ...formData });
			}
		}
	};

	useEffect(() => {
		(async () => {
			let resource_type = 0;

			switch (type) {
				case "tagproduct":
					resource_type = TagResourceType.PRODUCT;
					break;
				case "tagbrand":
					resource_type = TagResourceType.BRAND;
					break;
				default:
					break;
			}
			if (resource_type) {
				const res = await new TagTypeRepository().getItems({
					filters: {
						...TagTypeRepository.getDefaultFilters(),
						resource_type: resource_type,
					},
				});
				if (!res.hasError()) {
					setTypes(res.items);
				}
			}
		})();
	}, [type]);

	return (
		<>
			<Form
				initialValues={{
					...filters,
					is_root: filters.is_root > 0 ? filters.is_root : IS_ROOT.USER,
					collection_id:
						filters.collection_id > 0 ? filters.collection_id : undefined,
				}}
				onFinish={onFilters}
				className="w-full">
				<Row gutter={[8, 8]}>
					{/* <Col>
            <span className="leading-8">Bộ lọc:</span>
          </Col> */}
					{["productintag", "productrelated"].includes(type) && (
						<>
							<Col>
								<ProductCollectionFormSelectFilter
									className="w-full"
									existValue={
										filters.collection_id > 0
											? filters.collection_id
											: undefined
									}
									onClear={() => {
										setSelectedProductCollection(
											new ProductCollectionModel(
												ProductCollectionModel.getDefaultData()
											)
										);
									}}
									changeDataProductCollection={(v) => {
										setSelectedProductCollection(v);
									}}
								/>
							</Col>
						</>
					)}
					{[
						"tagproduct",
						"tagproductcollection",
						"tagstorycollection",
						"tagstory",
						"tagbrand",
					].includes(type) && (
						<>
							<Col>
								<FormSelect
									name="is_root"
									placeholder={"Phân loại người tạo"}
									options={TagModel.getIsRoot()}
									allowClear
									popupMatchSelectWidth={false}
									// onClear={() => {
									// 	setTagIsRoot(-1);
									// }}
									// onChange={(v) => {
									// 	setTagIsRoot(Number(v));
									// }}
								/>
							</Col>
						</>
					)}
					{[
						"tagproduct",
						"tagproductcollection",
						"tagstorycollection",
						"tagstory",
						"tagbrand",
					].includes(type) &&
						types.length > 0 && (
							<>
								{/* <Col>
								<FormSelect
									name="resource_type"
									placeholder={"Loại nguồn"}
									options={TagTypeModel.getResouceTypeList()}
									allowClear
									popupMatchSelectWidth={false}
									onClear={() => {
										setTypeId(-1);
									}}
									onChange={(v) => {
										// setTypeId(Number(v));
									}}
								/>
							</Col> */}
								<Col>
									<FormSelect
										name="type"
										placeholder={"loại tag"}
										options={types.map((type) => ({
											label: type.name,
											value: type.id,
										}))}
										allowClear
										popupMatchSelectWidth={false}
										onClear={() => {
											setTypeId(-1);
										}}
										onChange={(v) => {
											setTypeId(Number(v));
										}}
									/>
								</Col>
							</>
						)}
					<Col>
						<Form.Item name={"keyword"}>
							<Input
								allowClear
								placeholder={
									["productintag", "productrelated", "product"].includes(type)
										? "Nhập tên hoặc SKU"
										: "Nhập tên"
								}
								// value={keyword}
								// onChange={(e) => {
								// 	setTimeout(() => {
								// 		setKeyword(e.target.value);
								// 	}, 0);
								// }}
							/>
						</Form.Item>
					</Col>
					<Col>
						<Form.Item>
							<Button
								type="default"
								htmlType="submit"
								onClick={() => {
									// onFilters();
								}}>
								Lọc
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</>
	);
};

export default AddListToItemFilter;
