import { IconTrash } from "@tabler/icons-react";
import {
  Alert,
  App,
  Button,
  Col,
  Flex,
  Form,
  Image,
  Row,
  Spin,
  Typography,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import ActionModel from "common/models/ActionModel";
import ActionRepository from "common/repositories/ActionRepository";
import FileRepository from "common/repositories/FileRepository";
import Error from "components/Error";
import useFunction from "hooks/useFunction";
import { MutableRefObject, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Webcam from "react-webcam";

const ActionAddForm = (): JSX.Element => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { message } = App.useApp();
  const { getGpsPromise } = useFunction();

  const messageKey = "editor";

  //////////////////////////
  //state
  const [imgScreenshotSrc, setImgScreenshotSrc] = useState<string>("");
  const [enableWebcam, setEnableWebcam] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState<boolean>(false);
  /////////////////////////
  //ref
  const webcamRef = useRef(null) as MutableRefObject<any>;
  /////////////////////////
  //function
  const captureScreenshot = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgScreenshotSrc(imageSrc || "");
  }, [webcamRef, setImgScreenshotSrc]);

  const requireGPS = useCallback(() => {
    setProcessing(false);
    message.error("Yêu cầu định vị và GPS");
  }, [message]);

  const onSubmit = useCallback(
    (formData: any) => {
      setProcessing(true);

      getGpsPromise()
        .then(() => {
          message.loading({
            content: t("common:form.processing"),
            key: messageKey,
          });

          navigator.geolocation.getCurrentPosition(
            async (locations) => {
              if (
                locations?.coords.latitude !== 0 &&
                locations.coords.longitude !== 0
              ) {
                try {
                  let webcamFileId = 0;
                  let webcamUploadPass = false;

                  if (imgScreenshotSrc.length > 0) {
                    const webcamFile =
                      await new FileRepository().uploadBase64Image({
                        origin: "checkin",
                        data_uri: imgScreenshotSrc,
                      });

                    if (webcamFile.hasError()) {
                      webcamUploadPass = false;
                      throw webcamFile.error.errors;
                    } else {
                      webcamUploadPass = true;
                      webcamFileId = webcamFile.id;
                    }
                  }

                  if (webcamUploadPass) {
                    const myObj: ActionModel =
                      await new ActionRepository().saveRemote({
                        id: 0,
                        image_id: webcamFileId,
                        latitude: locations.coords.latitude,
                        longitude: locations.coords.longitude,
                        note: formData.note,
                        action: formData.action,
                      });

                    if (myObj.hasError()) {
                      throw myObj.error.errors;
                    } else {
                      form.resetFields();
                      setImgScreenshotSrc("");
                      message.success({
                        content: t("worktracking:form.success.add_action"),
                        className: "message_success",
                        key: messageKey,
                        duration: 2,
                      });
                    }
                  }
                } catch (errors) {
                  message.error({
                    content: (
                      <Error
                        contentPadding={0}
                        onClickClose={() => {
                          message.destroy(messageKey);
                        }}
                        heading={t("common:error.form_submit")}
                        translate_prefix={"worktracking:form.error"}
                        items={errors as string[]}
                      />
                    ),
                    className: "message_error",
                    key: messageKey,
                    duration: 3,
                  });
                } finally {
                  setProcessing(false);
                }
              }
            },
            () => {
              setError(true);
              requireGPS();
            }
          );
        })
        .catch(requireGPS);
    },
    [imgScreenshotSrc, getGpsPromise, message, t, form, requireGPS]
  );

  return (
    <Spin spinning={processing}>
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Row>
          <Col xxl={8} md={24}>
            <Row gutter={0} className="flex justify-center">
              {error ? (
                <Alert
                  message={
                    <Typography.Text>
                      Yêu cầu truy cập camera{". "}
                      <Typography.Link>Nhấn để truy cập</Typography.Link>{" "}
                    </Typography.Text>
                  }
                  type="warning"
                  showIcon
                  closable={false}
                  onClick={async () => {
                    navigator.mediaDevices
                      .getUserMedia({ video: true })
                      .then((stream) => {
                        setError(false);
                      })
                      .catch((error) => {
                        message.open({
                          type: "warning",
                          content: "Bạn hãy mở quyền truy cập camera !",
                          key: "err",
                          duration: 2,
                        });
                      });
                  }}
                />
              ) : (
                <Col flex={"325px"}>
                  <div className="mb-2">
                    {t("worktracking:webcamphoto")}
                    {enableWebcam ? (
                      <Button
                        type="link"
                        onClick={() => {
                          setEnableWebcam(false);
                          setImgScreenshotSrc("");
                        }}
                      >
                        {t("worktracking:enablewebcam_off")}
                      </Button>
                    ) : null}
                  </div>
                  <Row gutter={[0, 8]} className="w-full">
                    {imgScreenshotSrc.length === 0 ? (
                      <>
                        <Col span={24}>
                          <div className="flex items-center bg-gray-300 place-content-center w-full min-w-[325px] h-[250px]">
                            {enableWebcam ? (
                              <Webcam
                                style={{
                                  transform: "scaleX(1)",
                                  margin: "0 auto",
                                  borderRadius: "8px 8px 0 0",
                                }}
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                width="325px"
                                height="250px"
                                minScreenshotWidth={320}
                                forceScreenshotSourceSize={true}
                                videoConstraints={{
                                  width: 325,
                                  height: 250,
                                  facingMode: { exact: "user" },
                                }}
                                onUserMediaError={(err) => {
                                  console.log("Test lỗi webcam", err);
                                  setError(true);
                                }}
                                imageSmoothing={true}
                              />
                            ) : (
                              <>
                                <Button
                                  type="default"
                                  onClick={() => setEnableWebcam(true)}
                                >
                                  {t("worktracking:enablewebcam_on")}
                                </Button>
                              </>
                            )}
                          </div>
                        </Col>
                        <Col span={24}>
                          <Button
                            disabled={!enableWebcam}
                            block
                            ghost
                            type="primary"
                            onClick={(e) => {
                              e.preventDefault();
                              captureScreenshot();
                            }}
                          >
                            {t("worktracking:capture")}
                          </Button>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col span={24}>
                          <Image
                            className="w-full h-full object-cover"
                            src={imgScreenshotSrc}
                            preview={false}
                            style={{ borderRadius: 8 }}
                          />
                        </Col>
                        <Col span={24}>
                          <Button
                            block
                            ghost
                            type="primary"
                            onClick={(e) => {
                              e.stopPropagation();
                              setImgScreenshotSrc("");
                            }}
                            icon={
                              <IconTrash
                                size="16"
                                color="red"
                                className="mr-1 -mt-1"
                              />
                            }
                          >
                            {t("worktracking:webcamphoto_remove")}
                          </Button>
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
          <Col xxl={8} md={24} sm={24} xs={24} className="p-4">
            <Form.Item name="note" label={t("Ghi chú")}>
              <TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col xxl={8} md={24} className="p-4">
            <Flex gap={12}>
              {ActionModel.getActionTypeList().map((action) => (
                <Button
                  type="primary"
                  size="large"
                  htmlType="button"
                  disabled={imgScreenshotSrc === ""}
                  icon={action.icon}
                  onClick={() =>
                    onSubmit({ ...form.getFieldsValue(), action: action.value })
                  }
                  className="size-[100px] flex flex-col items-center"
                >
                  <Typography.Text className="text-white !self-center">
                    {action.label}
                  </Typography.Text>
                </Button>
              ))}
            </Flex>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default ActionAddForm;
