import { PlusSquareOutlined } from "@ant-design/icons";
import { Button, Modal, Select, SelectProps } from "antd";
import ProductCollectionCollection from "common/collections/ProductCollectionCollection";
import ProductCollectionModel from "common/models/ProductCollectionModel";
import ProductCollectionRepository from "common/repositories/ProductCollectionRepository";
import { FilterProductCollection } from "common/types/ProductCollection";
import { uniqueArray } from "common/utils/utils";
import ProductCollectionForm from "features/productcollection/form/ProductCollectionForm";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

export default function ProductCollectionSearchCreate({
  selectProps,
  isEdit,
}: {
  selectProps?: SelectProps;
  isEdit?: boolean;
}) {
  const value = selectProps?.value;

  const [coll, setColl] = useState<ProductCollectionModel[]>([]);
  const [collValue, setCollValue] = useState<ProductCollectionModel[]>([]);
  const [open, setOpen] = useState(false);
  const [filterCollection, setFilterCollection] = useState(
    ProductCollectionRepository.getDefaultFilters()
  );
  const fetchDataCollectionList = useCallback(
    async (
      filter: FilterProductCollection
    ): Promise<ProductCollectionCollection> => {
      let collection = await new ProductCollectionRepository().getItems({
        filters: {
          ...filter,
          limit: 50,
        },
      });
      return collection;
    },
    []
  );

  const debounceSearchCol = useCallback(
    debounce((value: string) => {
      setFilterCollection((pre) => {
        return { ...pre, keyword: value };
      });
    }, 500),
    []
  );

  useEffect(() => {
    (async () => {
      const collection = await fetchDataCollectionList({...filterCollection});
      if (collection.items.length > 0) {
        setColl(collection.items);
      }
    })();
  }, [fetchDataCollectionList, filterCollection]);

  useEffect(() => {
    (async () => {
      if (isEdit && value.length > 0 && collValue.length === 0) {
        const collection = await fetchDataCollectionList({
          ...ProductCollectionRepository.getDefaultFilters(),
          ids: value.join(",") || "",
        });
        if (!collection.hasError()) {
          setCollValue(collection.items);
        }
      }
    })();
  }, [value, isEdit, fetchDataCollectionList, collValue.length]);

  const selectOptions = useMemo(
    () =>
      uniqueArray(collValue.concat(coll)).map((el) => ({
        label: el.title,
        value: el.id,
      })),
    [collValue, coll]
  );

  return (
    <>
      <div className=" flex items-center  flex-wrap">
        <Select
          {...selectProps}
          showSearch
          placeholder="Tìm kiếm danh mục"
          onSearch={(value) => {
            debounceSearchCol(value);
          }}
          filterOption={false}
          mode="multiple"
          allowClear
          options={selectOptions}
        />

        <Button onClick={() => setOpen(true)}>
          <PlusSquareOutlined />
        </Button>

        <Modal
          width={1000}
          centered
          open={open}
          onCancel={() => setOpen(false)}
          title="thêm mới nhóm hàng"
          footer={null}
        >
          <ProductCollectionForm
            model={
              new ProductCollectionModel(
                ProductCollectionModel.getDefaultData()
              )
            }
            onSucsess={() => {
              fetchDataCollectionList(filterCollection);
              setOpen(false);
            }}
            isInfoBasic
            redirectOnSuccess=""
          />
        </Modal>
      </div>

      {/* {isEdit ? (
        <div>
          {collValue.map((col) => {
            return <p>{col.title}</p>;
          })}
        </div>
      ) : null} */}
    </>
  );
}
