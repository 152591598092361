import ProductWarehouseApi from "common/api/ProductWarehouseApi";
import ProductWarehouseCollection from "common/collections/ProductWarehouseCollection";
import { GetListProps } from "common/types/Filter";
import { FilterProductWarehouse } from "common/types/ProductWarehouse";
import BaseRepository from "./BaseRepository";

class ProductWarehouseRepository extends BaseRepository<ProductWarehouseApi> {
  _api: ProductWarehouseApi | null;

  constructor() {
    super();
    this._api = new ProductWarehouseApi(true);
  }

  static getDefaultFilters(): FilterProductWarehouse {
    return {
      page: 1,
      limit: 20,
      sortby: "created_at",
      sorttype: "DESC",
      sort_by: "updated_at",
      sort_type: "DESC",
      status: 1,
      keyword: "",
      item_id: -1,
      item_type: 1,
      store_id: -1,
      warehouse_id: -1,
      warehouse_ids: "",
      item_idlist: "",
    };
  }

  async getItems(props: GetListProps<FilterProductWarehouse>) {
    return this._api
      ? await this._api.getList(props)
      : new ProductWarehouseCollection();
  }
}

export default ProductWarehouseRepository;
