import { DatePickerProps, Form, Input } from "antd";
import ActionModel from "common/models/ActionModel";
import { Filter } from "common/types/Filter";
import DateFormFilter from "components/datetime/DateFormFilter";
import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import dayjs from "dayjs";
import DepartmentFormFilter from "features/department/DepartmentFormFilter";
import UserFormSelect from "features/user/UserFormSelect";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface IActionListFilterProps<F> {
  total: number;
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
}

const ActionListFilter = <F extends Filter>({
  total,
  filters,
  setFilters,
  defaultFilters,
}: IActionListFilterProps<F>): JSX.Element => {
  const { t } = useTranslation();

  ////////////////////////////////
  // process
  const getValueFilter = useMemo(() => {
    const entries = Object.entries(filters).filter(
      ([key]) => key === "date_created"
    );

    const [createdVal] = entries.map(([key, val]) => +val);

    if (createdVal > 0) {
      const createdDate = dayjs.unix(createdVal).format("DD/MM/YYYY");
      return dayjs(createdDate, "DD/MM/YYYY");
    }

    return null;
  }, [filters]);

  ////////////////////////////////////
  // event
  const onChange: DatePickerProps["onChange"] = (
    value: DatePickerProps["value"]
  ) => {
    if (typeof value !== "undefined" && value !== null) {
      setFilters({
        ...filters,
        date_created: value.unix(),
      });
    } else {
      setFilters({
        ...filters,
        date_created: 0,
      });
    }
  };

  return (
    <div>
      <div id="checkin_filter">
        <PageDataFilterForm
          total={total}
          filters={filters}
          setFilters={setFilters}
          defaultFilters={defaultFilters}
          convertValueByKeyFilter={[]}
        >
          <Form.Item name="keyword">
            <Input allowClear placeholder={t("action:filter.keyword")} />
          </Form.Item>
          <UserFormSelect
            name="employee_id"
            allowClear
            placeholder={t("Người tạo")}
            popupMatchSelectWidth={false}
          />
          <DepartmentFormFilter
            placeholder={t("checkin:filter.department")}
            name="department_id"
            allowClear
            popupMatchSelectWidth={false}
          />
          <FormSelect
            name="action"
            allowClear
            placeholder={t("action:filter.type")}
            options={ActionModel.getActionTypeList()}
          />
          <Form.Item>
            <DateFormFilter
              mode="single"
              allowClear
              onChange={onChange}
              format="DD/MM/YYYY"
              value={getValueFilter}
              placeholder={t("checkin:filter.date_created")}
              className="w-full"
            />
          </Form.Item>
        </PageDataFilterForm>
      </div>
    </div>
  );
};

export default ActionListFilter;
