import CompanySettingWrapper from "CompanySettingWrapper";
import CompanyResourceWrapper from "components/companyresource/CompanyResourceWrapper";
import React, { useState } from "react";

const AppProvider = ({ children }: { children: React.ReactNode }) => {
	const [loadCompanySettingDone, setLoadCompanySettingDone] = useState(false);

	return (
		<CompanyResourceWrapper
			setLoadCompanySettingDone={setLoadCompanySettingDone}>
			<CompanySettingWrapper loadCompanySettingDone={loadCompanySettingDone}>
				{children}
			</CompanySettingWrapper>
		</CompanyResourceWrapper>
	);
};

export default AppProvider;
