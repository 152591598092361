import ContractAndDeviceModel from "common/models/ContractAndDeviceModel";
import {
  ContractAndDeviceCollectionJson,
  ContractAndDeviceJson,
} from "common/types/Device";
import BaseCollection from "./BaseCollection";

class ContractAndDeviceCollection extends BaseCollection<
  ContractAndDeviceJson,
  ContractAndDeviceModel,
  ContractAndDeviceCollectionJson
> {
  itemsFromJson(jsonItems: ContractAndDeviceJson[]): ContractAndDeviceModel[] {
    return jsonItems.map((item) => new ContractAndDeviceModel(item));
  }
}
export default ContractAndDeviceCollection;
