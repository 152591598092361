import { BaseCollectionJson } from "./BaseCollectionJson";
import { CartCouponAddJson, CartOrderJson, CartPromotion } from "./Cart";
import { CashflowReceiptJson } from "./CashflowReceipt";
import { YesNo } from "./Enum";
import { Filter } from "./Filter";
import { OrderDetailJson } from "./OrderDetail";
import { ProductReceiptJson } from "./ProductReceipt";
import { TaxInvoiceJson } from "./TaxInvoice";

// type OrderJsonEdit = {
//   id: number;

//   shipping_full_name: string;
//   shipping_phone: string;
//   shipping_address: string;
//   shipping_sub_sub_region_id: number;
//   shipping_sub_region_id: number;
//   shipping_region_id: number;

//   price_sell: number;

//   price_discount: number;
//   price_shipping: number;
//   price_final: number;

//   status: number;
//   cancel_reason: number;
//   note: string;
//   tag: string;

//   details: OrderDetailJson[];
// };

// type OrderBase = OrderJsonEdit & {
//   company_id: number;
//   creator_id: number;
//   customer_id: number;
//   store_id: number;
//   promotion_id: number;
//   contact_email: string;
//   warehouse_id: number;
//   billing_full_name: string;
//   billing_phone: string;
//   billing_address: string;
//   billing_sub_sub_region_id: number;
//   billing_sub_region_id: number;
//   billing_region_id: number;

//   shipping_carrier: number;
//   cod_amount: number;
//   quantity: number;
//   ecom_platform_id?: number;
//   ecom_platform_type?: number;
//   ecom_platform_order_id?: string;
//   ecom_platform_invoice_id?: string;
//   date_created: number;
//   date_arrived: number;
// };

// type OrderJson = OrderBase & {
//   price_deposit: number;
//   price_debt: number;
//   promotion_detail: any;
//   product_receipt_id_list: number[];
//   product_receipt_list: ProductReceiptJson[];
//   cashflow_receipt_id_list: number[];
//   cashflow_receipt_list: CashflowReceiptJson[];
//   tax_invoice_id: number;
//   tax_invoice_list: TaxInvoiceJson[];
//   date_modified: number;
// };

// type OrderCollectionJson = BaseCollectionJson<OrderJson>;

// type FilterOrder = Filter & {
//   id: number;
//   store_id: number;
//   tag: string;
//   ecom_platform_type: number;
//   customer_id: number;
//   date_started: number;
//   date_ended: number;
// };

// type OrderEditingPrice = {
//   price_sell: number;
//   price_final: number;
// };
type OrderJsonEdit = {
	id: number | string;
	invoice_id: number | string;

	shipping_full_name: string;
	shipping_phone: string;
	shipping_address: string;
	shipping_sub_sub_region_id: number;
	shipping_sub_region_id: number;
	shipping_region_id: number;
	price_sell: number;
	price_discount: number;
	tax_ratio: number;
	price_tax: number;
	price_shipping: number;
	price_final: number;
	price_cod?: number;
	status: number;
	cancel_reason: number;
	note: string;
	tag: string;
	origin: number;
	note_invoice?: {
		note_cancel?: string;
	};
	details?: CartOrderJson[];
	customer_credit_balance?: boolean;
	shipping_ward?: number;
	shipping_district?: number;
	shipping_province?: number;
	note_cancel?: string;
	check_debt_cashflow?: boolean;
	note_problem?: string;
	is_problem?: ISPROBLEM;
};

export enum ISPROBLEM {
	NOT,
	IS,
}

export enum PaymentStatus {
	NOTPAYMENT = 3,
	SUCCSECC = 1,
}

export enum OrderDeliveryStatus {
	STATUS_NEW = 1,
	STATUS_DELIVER = 2,
	STATUS_SUCCESS = 9,
	STATUS_COMPLETE = 10,
	STATUS_CANCEL = 11,
}

type OrderSellerJsonEdit = {
	id: number | string;
	shipping_firstname?: string;
	shipping_lastname?: string;
	shipping_phone?: string;
	shipping_address?: string;
	shipping_ward?: number;
	shipping_district?: number;
	shipping_province?: number;
	shipping_country?: number;
	shipping_carrier?: number;
	note?: string;
	discount_custom_type?: string;
	discount_custom_value?: number;
	payment_status?: PaymentStatus;
	delivery_status?: number;
	status?: number;
};

type OrderBase = OrderJsonEdit & {
	company_id: number;
	creator_id: number;
	customer_id: number;
	store_id: number;
	invoice_id: number;
	promotion_id: number;
	contact_email: string;
	warehouse_id: number;
	billing_full_name: string;
	billing_phone: string;
	billing_address: string;
	billing_sub_sub_region_id: number;
	billing_sub_region_id: number;
	billing_region_id: number;

	shipping_carrier: number;
	shipping_tracking_code: string;
	cod_amount: number;
	related_order_id: string;
	quantity: number;
	is_return: number;
	ecom_platform_id?: number;
	ecom_platform_type?: number;
	ecom_platform_order_id?: string;
	ecom_platform_invoice_id?: string;
	date_created: number;
	date_arrived: number;
	tax_invoice_id: number;
};

// type OrderJson = OrderBase & {
// 	price_deposit: number;
// 	price_debt: number;
// 	promotion_detail: any;
// 	product_receipt_id_list: number[];
// 	product_receipt_list: ProductReceiptJson[];
// 	cashflow_receipt_id_list: number[];
// 	cashflow_receipt_list: CashflowReceiptJson[];
// 	random_code: string;
// 	has_return: number;
// 	date_modified: number;
// 	payment_method: number;
// 	code: string;
// 	order_detail: string;
// 	order_description: string;
// 	resource_number: string;
// 	resource_type: number;
// 	tax_invoice_list: TaxInvoiceJson[];
// };

export enum OrderInventoryStatus {
	NOT_SHIPPED = 1,
	SHIPPED,
	PARTIALLY_RETURNED,
	RETURNED_ENOUGH,
	RETURNED_EQUAL,
	SHIPPED_LESS_THAN_ORDERED,
	SHIPPED_MORE_THAN_ORDERED,
}

type OrderJson = {
	has_invoice: number;
	company_id: number;
	creator_id: number;
	customer_id: number;
	store_id: number;
	id: number | string;
	invoice_id: number;
	price_sell: number;
	price_shipping: number;
	price_tax: number;
	price_discount: number;
	price_final: number;
	price_deposit: number;
	pricedebt: number;
	promotion_id: number;
	billing_fullname: string;
	billing_phone: string;
	billing_address: string;
	billing_ward: number;
	billing_district: number;
	billing_province: number;
	billing_country: number;
	billing_company: number;
	billingt_email: string;
	shipping_phone: string;
	shipping_address: string;
	shipping_firstname: string;
	shipping_lastname: string;

	shipping_fullname: string;

	shipping_ward: number;
	shipping_district: number;
	shipping_province: number;
	shipping_country: number;
	shipping_company: number;
	warehouse_id: number;
	shipping_carrier: number;
	note: string;
	customer_credit_balance: boolean;
	note_invoice: Partial<NoteInvoiceJson>;
	cod_amount: number;
	status: number;
	cancel_reason: number;
	tag: string;
	date_arrived: number;
	date_created: number;
	date_modified: number;
	date_completed: number;
	date_deleted: number;
	promotion_detail: any;
	service_type: number;
	code: string;
	order_detail: string;
	order_description: string;
	resource_number: string;
	resource_type: number;
	price_cost: number;
	payment_method_json: string;
	parent_id: number;
	ipaddress: number;
	resource_id: number;
	resource_json: string;
	resource_date_created: number;
	is_deleted: number;
	deposit: number;
	ecom_platform_type: number;
	ecom_platform_order_id: number;
	ecom_platform_invoice_id: number;
	tax_ratio: number;
	debt: number;
	details: {
		data: CartOrderJson[];
		total: number;
	};
	inventory_status: OrderInventoryStatus;
	promotions: CartPromotion[];
	coupons: CartCouponAddJson[];
	payments: { amount: number; method: string; code?: string }[];
	price_discount_coupon: number;
	payment_status: number;
	total_payment: number;
	sale_channel: number;
	type: number;
	delivery_status: number;

	seller_id: number;
	referral_id: number;
	delivery_lits_id: string;

	order_custom_type: "amount" | "percent";
	order_custom_value: number;
	order_custom_discount: number;

	order_discount: number;
	item_discount: number;

	is_problem: ISPROBLEM;
	note_problem: string;
	referral_name: string;
	using_cod: boolean;
	note_discout_custom: string;

	total_price_vat_item: number;
};

export enum SERVICE_TYPE {
	NONE,
	PURTCHASE_METHOD_IN_STORE,
	PURTCHASE_METHOD_DELIVERY,
}

type NoteInvoiceJson = {
	bill_recipient_name: string;
	company_address: string;
	company_name: string;
	invoice_receipt_email: string;
	tax_code: string;
	note_order: any;
};

type OrderCollectionJson = BaseCollectionJson<OrderJson>;

type FilterOrder = Filter & {
	id: number;
	origin: number;
	store_id: number;
	tag: string;
	sale_channel: number;
	code: string;
	from_date: number;
	to_date: number;
	type: number;
	delivery_status?: string | number;
	seller_id?: number;
	payment_status?: number;
	inventory_status?: number;
	service_type?: number;
	customer_credit_balance?: number;
	payment_confirmed_by_staff?: number;
	using_cod?: number;
	is_promotion?: YesNo;
	is_promotion_body?: YesNo;
	is_promotion_item?: YesNo;
};

type OrderEditingPrice = {
	price_sell: number;
	price_final: number;
	price_tax: number;
	price_before_tax: number;
	// price_change: number;
};

type OrderInitTaxInvoiceRequest = {
	order_id: number;
	note: string;
};

export type {
	OrderBase,
	OrderJson,
	OrderJsonEdit,
	OrderCollectionJson,
	FilterOrder,
	OrderEditingPrice,
	OrderInitTaxInvoiceRequest,
	OrderSellerJsonEdit,
	NoteInvoiceJson,
};
