import LocationCollection from "common/collections/LocationCollection";
import type { GetListProps } from "common/types/Filter";
import { FilterLocation } from "common/types/Location";
import Helper from "common/utils/helper";
import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

const SERVICE_URL = "/locations/public/type";

class LocationApi extends BaseApi {
  async getList(
    props: GetListProps<FilterLocation>
  ): Promise<LocationCollection> {
    let collection = new LocationCollection();
    const convertProps = Helper.convertParams(props.filters);

    const queryData = {
      params: {
        ...convertProps,
        sort_by: props.filters.sortby,
        sort_type: props.filters.sorttype,
        list_ids: props.filters.list_ids ? props.filters.list_ids : "",
      },
    };
    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL,
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }
}

export default LocationApi;
