import ShowOnWebsiteForm from "features/shopanother/showonwebsite/form/ShowOnWebsiteForm";
import SettingImageList from "features/shopanother/store/list/SettingImageList";
import usePathParams from "hooks/usePathParams";

const ShopAnotherPage = () => {
	const [pathParams] = usePathParams();
	let com = <></>;

	switch (pathParams.action) {
		case "storeImage":
			com = <SettingImageList></SettingImageList>;
			break;
		case "showonwebsite":
			com = <ShowOnWebsiteForm />;
			break;
		default:
			com = <></>;
			break;
	}
	return com;
};

export default ShopAnotherPage;
