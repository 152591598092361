import { Collapse, Table, Typography } from "antd";
import { ItemType } from "antd/es/menu/interface";
import ProductPublicModel from "common/models/ProductPublicModel";
import StoreModel from "common/models/StoreModel";
import { WarehouseQuantity } from "common/types/Product";
import TextStore from "components/TextStore";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type CollapseData = {
	key: string;
	label: string;
	data: WarehouseQuantity[];
};
export default function TableInventoryProductPublic({
	product,
	storeList,
}: {
	product: ProductPublicModel;
	storeList?: StoreModel[];
}) {
	const { t } = useTranslation();

	const columns = [
		{
			title: "Tên Kho",
			dataIndex: "name",
			key: "name",
			render: (name: string) => {
				// return <TextStore stores={storeList} id={id} />;

				return <Typography.Text>{name}</Typography.Text>;
			},
		},
		// {
		//   title: 'actual_quantity',
		//   dataIndex: 'actual_quantity',
		//   key: 'actual_quantity',
		// },

		{
			title: "Tồn kho",
			dataIndex: "quantity",
			key: "quantity",
		},
	];

	const items: CollapseData[] = useMemo(() => {
		// return [];
		const { warehouse_quantities } = product;

		const result = warehouse_quantities.reduce(
			(curr: CollapseData[], prev: WarehouseQuantity) => {
				const currExited = curr.find((i) => i.key === prev.type.toString());
				if (currExited) {
					currExited.data.push(prev);
				} else {
					curr.push({
						data: [prev],
						key: prev.type.toString(),
						label: prev.name,
					});
				}

				return curr;
			},
			[]
		);
		return result;
	}, [product.warehouse_quantities]);
	console.log("🚀 ~ constitems:CollapseData[]=useMemo ~ items:", items);

	return (
		<>
			<Collapse accordion={false}>
				{items.map((i) => {
					return (
						<Collapse.Panel
							header={t(`warehouse:type_number.${i.key}`)}
							key={i.key}>
							<Table
								className="m-0"
								dataSource={i.data}
								columns={columns}
								pagination={false}
								summary={(pageData) => {
									let totalQuan = 0;
									let totalReserved = 0;
									pageData.forEach(({ quantity }) => {
										totalQuan += quantity;
									});
									return (
										<>
											<Table.Summary.Row>
												<Table.Summary.Cell index={0}>
													<b>Tổng</b>
												</Table.Summary.Cell>

												<Table.Summary.Cell index={2}>
													<b>{totalQuan}</b>
												</Table.Summary.Cell>
											</Table.Summary.Row>
										</>
									);
								}}
							/>
						</Collapse.Panel>
					);
				})}
			</Collapse>
		</>
	);
}
