import { Spin } from "antd";
import WarehouseModel from "common/models/WarehouseModel";
import WarehouseRepository from "common/repositories/WarehouseRepository";
import { WarehouseJson } from "common/types/Warehouse";
import useDatabaseTable from "hooks/useDatabaseTable";
import { useCallback, useEffect, useRef, useState } from "react";

const TextWarehouse = ({
	id,
	warehouseItems,
}: {
	id: number;
	warehouseItems?: WarehouseJson[];
}) => {
	const [warehouseItemsDB, idxDBLoading] =
		useDatabaseTable<WarehouseJson>("warehouse");
	const items = warehouseItems || warehouseItemsDB;

	const [loading, setLoading] = useState(true);
	const [errors, setErrors] = useState<string[]>([]);

	const [warehouse, setWareHouse] = useState(WarehouseModel.getDefaultData());

	const repoRef = useRef(new WarehouseRepository());

	const fetchData = useCallback(async () => {
		if (items) {
			const dataFind = items.find((i) => Number(i.id) === Number(id));
			if (dataFind) {
				setWareHouse(dataFind);
			}
		} else {
			setLoading(true);
			const data = await repoRef.current.getItem(id);
			if (data.hasError()) {
				setErrors(data.error.errors);
			} else {
				setWareHouse(data.toJson());
			}
		}

		setLoading(false);
	}, [id, JSON.stringify(items)]);

	useEffect(() => {
		const repo = repoRef.current;

		if (!idxDBLoading) {
			fetchData();
		}

		return () => repo._api?.abortRequest();
	}, [idxDBLoading, fetchData]);

	return (
		<Spin spinning={idxDBLoading || loading} size="small">
			{warehouse.name.length > 0 ? warehouse.name : "--"}
		</Spin>
	);
};

export default TextWarehouse;
