import InventoryReceipt from "common/constants/InventoryReceipt";
import { BaseModelJson } from "common/interfaces/BaseModelJson";

import BaseModel from "./BaseModel";

import {
	InventoryReceiptDirection,
	InventoryReceiptJson,
	InventoryReceiptSourceType,
	InventoryReceiptTargetType,
	InventoryReceiptType,
} from "common/types/InventoryResceipt";
import { ProductInventoryReceiptJson } from "common/types/InventoryResceipt";
import { SelectOption } from "common/types/SelectOption";
import i18n from "i18n";

const defaultData = {
	id: 0,
	code: "",
	company_id: 0,
	creator_id: 0,
	warehouse_id: null,
	source: {
		type: 0,
		id: 0,
	},
	target: {
		type: 0,
		id: 0,
		status: 0,
	},
	referral_id: 0,
	type: 0,
	direction: 0,
	note: "",
	status: InventoryReceipt.STATUS_DRAFT,
	date_created: 0,
	date_modified: 0,
	detail: [],
	related_receipt_id: "",
	return_receipt_id: "",
};

class InventoryReceiptModel
	extends BaseModel
	implements BaseModelJson<InventoryReceiptJson>
{
	id: number = defaultData.id;
	code: string = defaultData.code;
	company_id: number = defaultData.company_id;
	creator_id: number = defaultData.creator_id;
	warehouse_id: number | null = defaultData.warehouse_id;
	source: InventoryReceiptJson["source"] = defaultData.source;
	target: InventoryReceiptJson["target"] = defaultData.target;
	referral_id: number = defaultData.referral_id;
	type: number = defaultData.type;
	direction: number = defaultData.direction;
	note: string = defaultData.note;
	status: number = defaultData.status;
	date_created: number = defaultData.date_created;
	date_modified: number = defaultData.date_modified;
	detail: ProductInventoryReceiptJson[] = defaultData.detail;
	related_receipt_id: string = defaultData.related_receipt_id;
	return_receipt_id: string = defaultData.return_receipt_id;

	constructor(json: InventoryReceiptJson) {
		super();
		Object.assign(this, { ...defaultData, ...json });
	}
	static getDefaultData(): InventoryReceiptJson {
		return defaultData;
	}

	toJson(): InventoryReceiptJson {
		return JSON.parse(JSON.stringify(this));
	}

	static getStatusList(): SelectOption[] {
		return [
			{
				value: InventoryReceipt.STATUS_DRAFT,
				label: i18n.t("productreceipt:STATUS_DRAFT"),
				color: "gray",
			},
			{
				value: InventoryReceipt.STATUS_WAIT_FOR_APPROVAL,
				label: i18n.t("productreceipt:STATUS_WAIT_FOR_APPROVAL"),
				color: "orange",
			},
			{
				value: InventoryReceipt.STATUS_APPROVED,
				label: i18n.t("productreceipt:STATUS_APPROVED"),
				color: "yellow",
			},
			{
				value: InventoryReceipt.STATUS_SHIPPED,
				label: i18n.t("productreceipt:STATUS_CHECKED"),
				color: "blue-inverse",
			},
			{
				value: InventoryReceipt.STATUS_COMPLETED,
				label: i18n.t("productreceipt:STATUS_COMPLETED"),
				color: "green",
			},
			{
				value: InventoryReceipt.STATUS_CANCELLED,
				label: i18n.t("productreceipt:STATUS_CANCELLED"),
				color: "red",
			},
		];
	}

	static getStatus(value: number): SelectOption | undefined {
		return this.getStatusList().find((item) => item.value === value);
	}

	static getDirectionLabel(value: number) {
		switch (value) {
			case 1: //xuất
				return {
					label: i18n.t("order:inventoryreceipt.out"),
					color: "red",
				};
			case 3: //nhập
				return {
					label: i18n.t("order:inventoryreceipt.in"),
					color: "green",
				};
			default:
				return {
					label: i18n.t("order:inventoryreceipt.notfound"),
					color: "",
				};
		}
	}
	static getDirectionList(): SelectOption[] {
		return [
			{
				value: InventoryReceipt.DIRECTION_INPUT,
				label: i18n.t("productreceipt:DIRECTION_INPUT"),
			},
			{
				value: InventoryReceipt.DIRECTION_OUTPUT,
				label: i18n.t("productreceipt:DIRECTION_OUTPUT"),
			},
		];
	}
	static getTypeList(direction?: InventoryReceiptDirection): SelectOption[] {
		// nhập hàng, nhập cân bằng, nhập chuyển, khác

		switch (direction) {
			case InventoryReceiptDirection.IN:
				return [
					{
						value: InventoryReceipt.TYPE_IN_INPUTRECEIVE,
						label: i18n.t("productreceipt:type_of_in.TYPE_INPUTRECEIVE"),
					},
					{
						value: InventoryReceipt.TYPE_IN_BALANCESTORE,
						label: i18n.t("productreceipt:type_of_in.TYPE_IN_BALANCESTORE"),
					},
					{
						value: InventoryReceipt.TYPE_SELLING_RETURN,
						label: i18n.t("productreceipt:type_of_in.TYPE_SELLING_RETURN"),
					},
					//

					/////////////////

					{
						value: InventoryReceipt.TYPE_TRANSFERSTORE,
						label: i18n.t("productreceipt:type_of_in.TYPE_TRANSFERSTORE"),
					},

					{
						value: InventoryReceipt.TYPE_OTHER,
						label: i18n.t("productreceipt:type_of_in.TYPE_OTHER"),
					},
				];
			case InventoryReceiptDirection.OUT:
				return [
					{
						value: InventoryReceipt.TYPE_SELLING,
						label: i18n.t("productreceipt:type_of_out.TYPE_SELLING_ONE"),
					},
					{
						value: InventoryReceipt.TYPE_OUT_EXPORT_CANCEL,
						label: i18n.t("productreceipt:type_of_out.TYPE_OUT_EXPORT_CANCEL"),
					},
					///////////////////

					{
						value: InventoryReceipt.TYPE_TRANSFERSTORE,
						label: i18n.t("productreceipt:type_of_out.TYPE_TRANSFERSTORE"),
					},

					{
						value: InventoryReceipt.TYPE_OTHER,
						label: i18n.t("productreceipt:type_of_out.TYPE_OTHER"),
					},
				];

			default:
				return [
					{
						value: InventoryReceipt.TYPE_IN_INPUTRECEIVE,
						label: i18n.t("productreceipt:type_of_in.TYPE_INPUTRECEIVE"),
					},
					{
						value: InventoryReceipt.TYPE_IN_BALANCESTORE,
						label: i18n.t("productreceipt:type_of_in.TYPE_IN_BALANCESTORE"),
					},
					//

					/////////////////

					{
						value: InventoryReceipt.TYPE_TRANSFERSTORE,
						label: i18n.t("productreceipt:type_of_in.TYPE_TRANSFERSTORE"),
					},

					{
						value: InventoryReceipt.TYPE_SELLING,
						label: i18n.t("productreceipt:type_of_out.TYPE_SELLING_ONE"),
					},
					{
						value: InventoryReceipt.TYPE_OUT_EXPORT_CANCEL,
						label: i18n.t("productreceipt:type_of_out.TYPE_OUT_EXPORT_CANCEL"),
					},
					///////////////////

					{
						value: InventoryReceipt.TYPE_TRANSFERSTORE,
						label: i18n.t("productreceipt:type_of_out.TYPE_TRANSFERSTORE"),
					},

					{
						value: InventoryReceipt.TYPE_OTHER,
						label: i18n.t("productreceipt:type_of_out.TYPE_OTHER"),
					},
				];
		}
	}

	static getType(value: number) {
		return this.getTypeList().find((item) => item.value === value);
	}

	static getTypeIn(value: number): SelectOption | undefined {
		return this.getTypeList(InventoryReceiptDirection.IN).find(
			(item) => item.value === value
		);
	}
	static getTypeOut(value: number): SelectOption | undefined {
		return this.getTypeList(InventoryReceiptDirection.OUT).find(
			(item) => item.value === value
		);
	}

	static getTargetLabel(type: number) {
		const result = {
			label: "",
			color: "",
		};
		switch (type) {
			case InventoryReceiptTargetType.DELIVERY:
				result.color = "blue";
				result.label = "Vận đơn";

				break;
			case InventoryReceiptTargetType.OTHER:
				result.color = "gray";
				result.label = "Khác";
				break;
			case InventoryReceiptTargetType.SUPPLIER:
				result.color = "magenta";
				result.label = "Nhà cung cấp";
				break;
			case InventoryReceiptTargetType.WAREHOUSE:
				result.color = "blue-inverse";
				result.label = "Kho";
				break;
			default:
				break;
		}

		return result;
	}

	static getSourceLabel(type: number) {
		const result = {
			label: "",
			color: "",
		};
		switch (type) {
			case InventoryReceiptSourceType.ORDER:
				result.color = "blue";
				result.label = "Đơn hàng";

				break;
			case InventoryReceiptTargetType.OTHER:
				result.color = "blue";
				result.label = "Khác";
				break;
			case InventoryReceiptTargetType.SUPPLIER:
				result.color = "blue";
				result.label = "Nhà cung cấp";
				break;
			case InventoryReceiptTargetType.WAREHOUSE:
				result.color = "blue";
				result.label = "Kho";
				break;
			default:
				break;
		}

		return result;
	}

	static getDefaultDetailData() {
		return {
			item_id: 0,
			item_type: 0,
			price: 0,
			cost_price: 0,
			unit: 0,
			unit_type: "",
			unit_price: 0,
			quantity: 0,
			date_created: 0,
			date_modified: 0,
			date_completed: 0,
			sku: "",
		};
	}

	static getSourceTypeOpntion() {
		return [
			{
				value: InventoryReceiptSourceType.ORDER,
				label: "Đơn hàng",
			},

			{
				value: InventoryReceiptSourceType.SUPPLIER,
				label: "Nhà cung cấp",
			},
			{
				value: InventoryReceiptSourceType.WAREHOUSE,
				label: "Kho hàng",
			},
			{
				value: InventoryReceiptSourceType.DOC,
				label: "Chứng từ",
			},
			{
				value: InventoryReceiptSourceType.OTHER,
				label: "Khác",
			},
		];
	}

	static getTargetTypeOpntion() {
		return [
			{
				value: InventoryReceiptTargetType.DELIVERY,
				label: "Vận đơn",
			},

			{
				value: InventoryReceiptSourceType.SUPPLIER,
				label: "Nhà cung cấp",
			},
			{
				value: InventoryReceiptSourceType.WAREHOUSE,
				label: "Kho hàng",
			},
			{
				value: InventoryReceiptSourceType.OTHER,
				label: "Khác",
			},
		];
	}

	static checkDetailItemHaveKeyValue(
		object: ProductInventoryReceiptJson,
		key: keyof ProductInventoryReceiptJson,
		val: any
	) {
		if (object.hasOwnProperty(key)) {
			const value = object[key];
			if (value === val) {
				return true;
			}
			return false;
		}
		return false;
	}
}

export default InventoryReceiptModel;
