import {
	App,
	Button,
	Checkbox,
	Col,
	Collapse,
	DatePicker,
	Divider,
	Form,
	FormInstance,
	Input,
	InputNumber,
	Radio,
	Row,
	Select,
	Tabs,
	Typography,
} from "antd";
import Promotion from "common/constants/Promotion";
import PromotionModel from "common/models/PromotionModel";
import PromotionRepository from "common/repositories/PromotionRepository";
import FormSelect from "components/form/FormSelect";
import Error from "components/LayoutError";
import FormChangedContext from "contexts/FormChangedContext";
import dayjs from "dayjs";
import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";

import PromotionFormSeasonal from "./PromotionFormSeasonal";

import {
	CalculateOnSalePrice,
	type PromotionJson,
	type PromotionJsonAddEdit,
} from "common/types/Promotion";
import MoneyInput from "components/MoneyInput";
import { TabsProps } from "antd/lib";
import TextArea from "antd/es/input/TextArea";
import StoreSearch from "components/store/StoreSearch";
import CustomerSearch from "components/customer/CustomerSearch";
import FormRangeNumber from "components/form/FormRangeNumber";
import { isArray } from "lodash";
import ProductCollectionSearch from "components/collection/ProductCollectionSearch";
const { RangePicker } = DatePicker;
const DATE_FORMAT = "DD/MM/YYYY HH:mm";

type ChildProps = {
	form: FormInstance;
	model: PromotionModel;
};

type ConditionCustom = {
	valueScope: number;
	keyScope: "store_scope" | "customer_scope";
	keyValue: "customer_id_list" | "store_id_list";
	value: number[];
};
const PromotionForm = ({
	model,
	setOpen,
	setProcessing,
	onSaveSuccess,
}: {
	model: PromotionModel;
	setOpen: (v: boolean) => any;
	setProcessing: (p: boolean) => any;
	onSaveSuccess: (o: PromotionModel) => void;
}) => {
	const { t } = useTranslation();
	const { message } = App.useApp();
	const formChangedProvider = useContext(FormChangedContext);

	const [form] = Form.useForm();
	const isEditing = model.id > 0;
	const promotionDate = Form.useWatch("promotion_date", form);

	/////////////////////////////////////////
	const keysConditionGroup = [
		{
			valueScope: model.store_scope,
			keyScope: "store_scope",
			keyValue: "customer_id_list",
			value: model.store_list,
		},
		{
			valueScope: model.customer_scope,
			keyScope: "customer_scope",
			keyValue: "customer_id_list",
			value: model.customer_list,
		},
	];
	const initialValues = useMemo(() => {
		return {
			code: model.code,

			group: model.group > 0 ? model.group : undefined,
			type: model.type > 0 ? model.type : undefined,
			name: model.name,
			promotion_date: [
				model.start_date !== undefined && model.start_date > 0
					? dayjs(dayjs.unix(model.start_date).format(DATE_FORMAT), DATE_FORMAT)
					: undefined,
				// dayjs(dayjs().format(DATE_FORMAT), DATE_FORMAT)
				model.end_date !== undefined && model.end_date > 0
					? dayjs(dayjs.unix(model.end_date).format(DATE_FORMAT), DATE_FORMAT)
					: undefined,
				// dayjs(dayjs().add(1, "M").format(DATE_FORMAT), DATE_FORMAT)
			],
			value: model.value,
			values: model.value,
			store_id_list: model.store_list,
			value_type:
				model.value_type > 0 ? model.value_type : Promotion.VALUETYPE_PERCENT,
			status: model.status > 0 ? model.status : Promotion.STATUS_PENDING,

			allow_multiple_codes: model.allow_multiple_codes,
			apply_with_other: model.apply_with_other,
			store_scope: model.store_scope,
			customer_scope: model.customer_scope,
			customer_id_list: model.customer_list,
			min_check: model.min_check,
			max_applied: model.max_applied,
			collection_list: model.collection_list,
			is_calculate_on_sale_price: model.is_calculate_on_sale_price,
			label: model.label || {
				name: "sale",
				name_card: "sale",
			},
		};
	}, [model]);

	/////////////////////////////////////////
	const [loading, setLoading] = useState(false);
	const [keyActiveTab, setKeyActiveTab] = useState("1");

	/////////////////////////////////////////
	//prepare data for submit
	const doPrepareData = useCallback(
		(formData: any) => {
			const submitData: PromotionJsonAddEdit = {
				// ...PromotionModel.getDefaultData(),
				// ...model.toJson(),
				// ...formData,
				id: model.id,
				code: formData.code,
				group: formData.group,
				name: formData.name,

				// store_id_list: model.store_id_list,
				date_started: formData?.promotion_date?.[0]
					? dayjs(formData.promotion_date[0], DATE_FORMAT).unix()
					: 0,
				date_ended: formData?.promotion_date?.[1]
					? dayjs(formData.promotion_date[1], DATE_FORMAT).unix()
					: 0,
				status: formData?.status || undefined,
				type: formData.type,
				value: formData.value,
				value_type: formData.value_type,

				min_check: formData.min_check,
				max_applied: formData.max_applied,

				allow_multiple_codes: formData.allow_multiple_codes,
				apply_with_other: formData.apply_with_other,

				customer_scope: formData.customer_scope,
				store_scope: formData.store_scope,
				store_list: isArray(formData.store_id_list)
					? formData.store_id_list.join(",")
					: formData.store_id_list,
				customer_list: isArray(formData.customer_id_list)
					? formData.customer_id_list.join(",")
					: formData.customer_id_list,
				note: formData.note,
				collection_list: isArray(formData.collection_list)
					? formData.collection_list.join(",")
					: formData.collection_list,
				label: {
					name: formData?.label?.name,
					name_card: formData?.label?.name_card,
				},
				is_calculate_on_sale_price: formData.is_calculate_on_sale_price,
				// store_scope: 0
			};

			// if (model.group === Promotion.GROUP_LOYALTY) {
			//   submitData.type = Promotion.TYPE_LOYALTY_COUPON;
			// } else {
			//   submitData.type = formData.type;
			// }

			// if (
			// 	model.type === Promotion.TYPE_SEASONAL_ORDER_DISCOUNT ||
			// 	formData.type === Promotion.TYPE_SEASONAL_ORDER_DISCOUNT
			// ) {
			// 	submitData.value = formData.values;
			// 	submitData.value_type = Promotion.VALUETYPE_RANGE;
			// } else {
			// 	submitData.value_type = formData.value_type;
			// 	submitData.value = formData.value;
			// }

			// if (model.group === Promotion.GROUP_PERMANENT) {
			//   submitData.type = Promotion.TYPE_PERMANENT_PRODUCT_DISCOUNT;
			// }

			// if (
			// 	model.group === Promotion.GROUP_SEASONAL &&
			// 	formData.type === Promotion.TYPE_SEASONAL_ORDER_GIFT
			// ) {
			// 	submitData.min_check = parseInt(formData.value);
			// 	submitData.value_type = Promotion.VALUETYPE_QUANTITY;
			// 	submitData.value = "1";
			// }

			// if (
			// 	model.group === Promotion.GROUP_SEASONAL &&
			// 	model.type === Promotion.TYPE_SEASONAL_PRODUCT_GIFT
			// ) {
			// 	submitData.value_type = Promotion.VALUETYPE_QUANTITY;
			// }

			// if (model.store_id_list.length === 0) {
			// 	submitData.store_scope = Promotion.STORESCOPE_ALL;
			// } else {
			// 	submitData.store_scope = Promotion.CUSTOMERSCOPE_CUSTOM;
			// }
			return submitData;
		},
		[model.id, model.type, model.group, model.code, model.store_id_list]
	);

	// let childrenForm = null;
	// switch (model.group) {
	// 	// case Promotion.GROUP_LOYALTY:
	// 	//   childrenForm = <PromotionFormLoyalty isEditing={isEditing} />;
	// 	//   break;
	// 	case Promotion.GROUP_SEASONAL_ID:
	// 		childrenForm = <PromotionFormSeasonal isEditing={isEditing} />;
	// 		break;
	// 	// case Promotion.GROUP_PERMANENT:
	// 	//   childrenForm = <PromotionFormPermanent />;
	// 	//   break;
	// }

	//submit data to server
	const onSubmit = async (formData: any) => {
		if (promotionDate === undefined) {
			return;
		}
		setProcessing(true);
		setLoading(true);
		message.loading({
			content: t("common:form.processing"),
			key: "message",
			duration: 0,
		});

		let item: PromotionModel = await new PromotionRepository().saveRemote(
			doPrepareData(formData)
		);
		setProcessing(false);
		if (item.hasError()) {
			message.error({
				content: (
					<Error
						onClickClose={() => {
							message.destroy("message");
						}}
						heading={t("common:form.error.heading")}
						translate_prefix="promotion:form.error"
						items={item.error.errors}
					/>
				),
				className: "message_error",
				key: "message",
				duration: 4,
			});
		} else {
			message.success({
				content:
					model.id > 0
						? t("promotion:form.success.update")
						: t("promotion:form.success.add"),
				className: "message_success",
				key: "message",
				duration: 2,
			});
			onSaveSuccess(item);
			setOpen(false);
		}
		setLoading(false);
	};

	/////////////////////////////////////////
	// useEffect(() => {
	// 	if (model.group === Promotion.GROUP_SEASONAL_ID) {
	// 		form.setFieldsValue({ type: Promotion.TYPE_PERMANENT_PRODUCT_DISCOUNT });
	// 	}

	// 	if (
	// 		model.group === Promotion.GROUP_SEASONAL_ID &&
	// 		model.type === Promotion.TYPE_SEASONAL_ORDER_GIFT
	// 	) {
	// 		form.setFieldsValue({ value: model.min_check });
	// 	}
	// }, [model.group, form, model.min_check, model.type]);

	const items: TabsProps["items"] = [
		{
			key: "1",
			label: "Thông tin khuyến mãi",
			children: (
				<InforPromotionForm form={form} model={model}></InforPromotionForm>
			),
			forceRender: true,
		},
		// {
		// 	key: "2",
		// 	label: "Thời gian áp dụng",
		// 	children: (
		// 		<TimePromotionForm form={form} model={model}></TimePromotionForm>
		// 	),
		// 	forceRender: true,
		// },
		{
			key: "2",
			label: "Phạm vi & Điều kiện áp dụng",
			children: (
				<ConditionPromotionForm
					form={form}
					model={model}></ConditionPromotionForm>
			),
		},
		{
			key: "3",
			label: "Hiển thị",
			children: (
				<ShowPromotionForm form={form} model={model}></ShowPromotionForm>
			),
		},
	];
	return (
		<Form
			layout="vertical"
			initialValues={initialValues}
			form={form}
			onFinish={onSubmit}
			id={formChangedProvider.id}
			onFieldsChange={(e) => formChangedProvider.setChanged(true)}
			onError={(e) => {}}>
			<Tabs
				destroyInactiveTabPane={false}
				onTabClick={(activeKey) => setKeyActiveTab(activeKey)}
				items={items}
				// defaultActiveKey="1"
				activeKey={keyActiveTab}></Tabs>

			<Row gutter={16}>
				<Col md={16} xs={24}>
					<Button
						loading={loading}
						htmlType="submit"
						type="primary"
						onClick={() => {
							const filedInTab1: string[] = [
								"code",
								"name",
								"group",
								"type",
								"value_type",
								"value",
							];
							form.validateFields(["promotion_date"]);

							const filedInTab2: string[] = ["promotion_date"];

							const filedInTab3: string[] = [""];
							const fieldsWithError = form
								.getFieldsError()
								.filter((field) => field.errors.length > 0);

							if (fieldsWithError.length > 0) {
								const fieldNamesWithError = fieldsWithError.map((field) =>
									field.name[0].toString()
								);

								if (
									fieldNamesWithError.some((name) => filedInTab1.includes(name))
								) {
									// Activate Tab 1
									setKeyActiveTab("1");
								} else if (
									fieldNamesWithError.some((name) => filedInTab2.includes(name))
								) {
									// Activate Tab 2
									// setKeyActiveTab("2");
								} else if (
									fieldNamesWithError.some((name) => filedInTab3.includes(name))
								) {
									// Activate Tab 3
									// setKeyActiveTab("3");
								} else {
									// form.submit();
								}
							} else {
								if (
									typeof promotionDate === "undefined" ||
									promotionDate === null
								) {
									setKeyActiveTab("2");
								}
							}
						}}>
						Xác nhận
					</Button>
				</Col>
			</Row>

			{/* {childrenForm} */}
		</Form>
	);
};

export default PromotionForm;

const InforPromotionForm = (props: ChildProps) => {
	const { form, model } = props;
	const { t } = useTranslation();
	const value_type = Form.useWatch("value_type", form);
	const type = Form.useWatch("type", form);

	const isEditing = model.id > 0;

	return (
		<Row gutter={8} className="mb-4">
			<Col span={12}>
				<Row>
					<Col span={24}>
						<Form.Item
							label={t("promotion:code")}
							name="code"
							// rules={[
							// 	{
							// 		required: false,
							// 		message: t("common:form.required"),
							// 	},
							// ]}
						>
							<Input placeholder="Nếu không nhập sẽ tự sinh mã"></Input>
						</Form.Item>
					</Col>

					<Col span={24}>
						<Form.Item
							label={t("promotion:name")}
							name="name"
							rules={[
								{
									required: true,
									message: t("promotion:form.error.error_name_required"),
								},
							]}>
							<Input placeholder="Tên chương trình" />
						</Form.Item>
					</Col>
				</Row>
			</Col>

			<Col span={12}>
				<Row>
					<Col span={24}>
						<FormSelect
							label={t("common:status")}
							name="status"
							options={PromotionModel.getStatusList()}
						/>

						<Col span={24}>
							<Form.Item
								label={t("promotion:description")}
								name="description"
								rules={[
									{
										required: false,
										message: t("common:form.required"),
									},
								]}>
								<TextArea rows={1}></TextArea>
							</Form.Item>
						</Col>
					</Col>
				</Row>
			</Col>
			<Divider></Divider>
			<Col span={24}>
				<Typography.Title level={4}>Hình thức khuyến mãi</Typography.Title>
			</Col>
			<Col span={12}>
				<Form.Item
					label={t("Hình thức")}
					name="group"
					rules={[
						{
							required: true,
							message: t("common:form.required"),
						},
					]}>
					<Select
						placeholder="Nhóm chương trình"
						disabled={false}
						options={PromotionModel.getGroupList()}
					/>
				</Form.Item>
			</Col>
			<Col span={12}>
				<FormSelect
					label="Loại giảm"
					placeholder="Chọn loại giảm"
					name={"type"}
					options={PromotionModel.getTypeListInSeasonal()}
					rules={[
						{
							required: true,
							message: t("common:form.required"),
						},
					]}></FormSelect>
			</Col>
			<Col span={24}>
				<Row gutter={8}>
					<Col span={12}>
						<Form.Item
							label={t("promotion:value_type")}
							name="value_type"
							rules={[
								{
									required: true,
									message: t("common:form.required"),
								},
							]}>
							<Select
								placeholder="Loại giảm"
								disabled={false}
								options={PromotionModel.getValueTypeList()}
							/>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item
							label={t("promotion:value")}
							name="value"
							rules={[
								{
									required: true,
									message: t("common:form.required"),
								},
							]}>
							{value_type == Promotion.VALUETYPE_AMOUNT ? (
								<MoneyInput addonAfter="VNĐ"></MoneyInput>
							) : (
								<InputNumber max={100} min={0} addonAfter="%"></InputNumber>
							)}
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={8}>
					{type == Promotion.TYPE_SEASONAL_ORDER_DISCOUNT && (
						<Col className="flex gap-4">
							<Form.Item name={"min_check"} label="Tổng tiền hàng tối thiểu">
								<MoneyInput min={0} addonAfter="VNĐ"></MoneyInput>
							</Form.Item>
						</Col>
					)}
					{value_type == Promotion.VALUETYPE_PERCENT && (
						<Col>
							<Form.Item name={"max_applied"} label="Giảm tối đa">
								<MoneyInput addonAfter="VNĐ"></MoneyInput>
							</Form.Item>
						</Col>
					)}
					{type == Promotion.TYPE_SEASONAL_ORDER_DISCOUNT && (
						<Col span={24}>
							<Form.Item
								name={"is_calculate_on_sale_price"}
								label="Áp dụng điều kiện tối thiểu trên">
								<Radio.Group
									onChange={() => {}}
									defaultValue={model.is_calculate_on_sale_price}>
									<Radio value={CalculateOnSalePrice.original_amount}>
										Tiền gốc đơn hàng
									</Radio>
									<Radio value={CalculateOnSalePrice.final_discounted_amount}>
										Tiền sau giảm giá
									</Radio>
								</Radio.Group>
							</Form.Item>
						</Col>
					)}
				</Row>
			</Col>
			<Col span={24}>
				<ProductCollectionSearch
					isMulti={true}
					label="Nhóm hàng được áp dụng"
					name={"collection_list"}
					defaultFilters={{
						ids: model.collection_list.join(","),
					}}></ProductCollectionSearch>
			</Col>
		</Row>
	);
};
const TimePromotionForm = (props: ChildProps) => {
	const { form, model } = props;
	const { t } = useTranslation();
	const value_type = Form.useWatch("value_type", form);
	const isEditing = model.id > 0;
	return (
		<>
			<Col span={24}>
				<Form.Item
					label={`${t("promotion:date_started")} - ${t(
						"promotion:date_ended"
					)}`}
					name="promotion_date"
					rules={[
						{
							required: true,
							message: t("common:form.required"),
							validator(rule, value, callback) {
								if (value.some((i: any) => i === undefined)) {
									return Promise.reject("Yêu cầu nhập");
								}
								return Promise.resolve(1);
							},
						},
					]}>
					<RangePicker
						changeOnBlur
						format={DATE_FORMAT}
						showTime
						style={{ width: "100%" }}
					/>
				</Form.Item>
			</Col>
		</>
	);
};

const ConditionPromotionForm = (props: ChildProps) => {
	const { t } = useTranslation();
	const { form, model } = props;
	const store_scope = Form.useWatch("store_scope", form);

	const customer_scope = Form.useWatch("customer_scope", form);

	const isEditing = model.id > 0;

	const keysConditionGroup: ConditionCustom[] = [
		{
			valueScope: model.store_scope,
			keyScope: "store_scope",
			keyValue: "store_id_list",
			value: model.store_id_list,
		},
		{
			valueScope: model.customer_scope,
			keyScope: "customer_scope",
			keyValue: "customer_id_list",
			value: model.customer_list,
		},
	];

	return (
		<>
			<Row gutter={8}>
				{keysConditionGroup.map((condition) => {
					return (
						<Col span={12}>
							<Row gutter={12}>
								<Col span={24}>
									<Form.Item
										initialValue={model[condition.keyScope]}
										name={condition.keyScope}>
										<Radio.Group
											name={condition.keyScope}
											defaultValue={model.store_scope}
											onChange={(e) => {
												form.setFieldValue(condition.keyScope, +e.target.value);
											}}>
											<Radio value={Promotion.STORESCOPE_ALL}>
												Toàn bộ {t(`promotion:condition.${condition.keyScope}`)}
											</Radio>
											<Radio value={Promotion.STORESCOPE_CUSTOM}>
												{t(`promotion:condition.${condition.keyScope}`)}
											</Radio>
										</Radio.Group>
									</Form.Item>
								</Col>
								<Col span={24}>
									{condition.keyScope === "store_scope" ? (
										<StoreSearch
											disabled={store_scope === Promotion.STORESCOPE_ALL}
											name={condition.keyValue}
											isMulti={true}></StoreSearch>
									) : (
										<CustomerSearch
											disabled={customer_scope === Promotion.CUSTOMERSCOPE_ALL}
											name={condition.keyValue}
											isMulti={true}></CustomerSearch>
									)}
								</Col>
							</Row>
						</Col>
					);
				})}
				{/* <TimePromotionForm form={form} model={model}></TimePromotionForm> */}

				<Col span={24}>
					<Form.Item
						label={`${t("promotion:date_started")} - ${t(
							"promotion:date_ended"
						)}`}
						name="promotion_date"
						rules={[
							{
								required: true,
								message: t("common:form.required"),
								validator(rule, value, callback) {
									if (!value) {
										return Promise.reject("Yêu cầu nhập");
									}
									if (value.some((i: any) => i === undefined)) {
										return Promise.reject("Yêu cầu nhập");
									}
									return Promise.resolve(1);
								},
							},
						]}>
						<RangePicker
							changeOnBlur
							format={DATE_FORMAT}
							showTime
							style={{ width: "100%" }}
						/>
					</Form.Item>
				</Col>
				<Col span={24} className="flex gap-4">
					<Form.Item name={"allow_multiple_codes"}>
						<Checkbox
							defaultChecked={model.allow_multiple_codes === 1}
							onChange={(e) => {
								form.setFieldValue(
									"allow_multiple_codes",
									e.target.checked ? 1 : 3
								);
							}}>
							Cho phép áp dụng trùng
						</Checkbox>
					</Form.Item>

					<Form.Item name={"apply_with_other"}>
						<Checkbox
							defaultChecked={model.apply_with_other === 1}
							onChange={(e) => {
								form.setFieldValue(
									"apply_with_other",
									e.target.checked ? 1 : 3
								);
							}}>
							Cho phép áp dụng với chương trình khác
						</Checkbox>
					</Form.Item>
				</Col>
			</Row>
		</>
	);
};

const ShowPromotionForm = (props: ChildProps) => {
	const { form } = props;
	return (
		<>
			<Col span={24}>
				<Row gutter={32}>
					<Col span={12}>
						<Form.Item
							label={`Nhãn hiện thị khuyến mãi`}
							name={["label", "name"]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label={`Nhãn hiện thị trên sản phẩm`}
							name={["label", "name_card"]}>
							<Input />
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</>
	);
};
