export const DataShipperKPI = {
  total: 2,
  currentpage: 1,
  limit: 20,
  items: [
    {
      id: 1,
      employee_id: 3,
      coordinator: 3,
      order_id: 25857,
      shift_time: "office_hours",
      district: 9894,
      store_id: 632001,
      amount: 100000,
      payment: 1,
      note: "TestTestTestTestTestTestTestTestTestTestTestTestTestTestTestTestTestTestTestTestTestTestTestTestTestTestTestTestTestTestTest",
      status: 2,
      date_created: 1737624685,
      date_modified: 0,
    },
    {
      id: 2,
      employee_id: 3,
      coordinator: 3,
      order_id: 25857,
      shift_time: "evening",
      district: 9894,
      store_id: 632001,
      amount: 200000,
      payment: 2,
      note: "Test",
      status: 3,
      date_created: 1737624685,
      date_modified: 0,
    },
  ],
};
