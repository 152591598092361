import React, { useCallback, useEffect, useState } from "react";
import IdFetcherRepository from "common/repositories/IdFetcherRepository";
import IdFetcherCollection from "common/collections/IdFetcherCollection";
import { IdFetcherResult } from "common/types/IdFetcher";
import Helper from "common/utils/helper";
import { uniqueArray } from "common/utils/utils";
type Props = {
	ids: number[];
	object_type: "employee" | "department";
};
const useIdFetcherState = () => {
	const [results, setResults] = useState<IdFetcherResult[]>([]);

	const getListIdFetcher = useCallback(async ({ ids, object_type }: Props) => {
		const response = await new IdFetcherRepository().doFetching({
			object_type: object_type,
			id_list: uniqueArray(ids),
		});
		const data =
			response.items.filter((i) => i.object_type === object_type)?.[0].result ??
			[];
		setResults(data);
	}, []);

	return {
		results,
		getListIdFetcher,
	};
};

export default useIdFetcherState;
