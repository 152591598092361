import {
	Checkbox,
	Col,
	Dropdown,
	List,
	Popover,
	Row,
	Tag,
	Tooltip,
	Typography,
} from "antd";
import PromotionCollection from "common/collections/PromotionCollection";
import Promotion from "common/constants/Promotion";
import Role from "common/constants/Role";
import PromotionModel from "common/models/PromotionModel";
import PromotionRepository from "common/repositories/PromotionRepository";
import { FilterPromotion, PromotionJson } from "common/types/Promotion";
import { TableColumnsType } from "common/types/Table";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import PageHeaderButton from "components/page/PageHeaderButton";
import RoleCheck from "components/RoleCheck";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import TextDateTime from "components/TextDateTime";
import dayjs from "dayjs";
import PromotionListHeader from "features/promotion/list/PromotionListHeader";
import PromotionCouponModal from "features/promotioncoupon/form/PromotionCouponModal";
import PromotionProductModal from "features/promotionproduct/form/PromotionProductModal";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import update from "immutability-helper";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
	IconEye,
	IconEyeDiscount,
	IconPencil,
	IconSettings,
} from "@tabler/icons-react";

import PromotionFormModal from "../form/PromotionFormModal";
// import PromotionFormSelectGroupModal from "../form/PromotionFormSelectGroupModal";
import PromotionSettingStoreModal from "../form/PromotionSettingStoreModal";
import PromotionListFilter from "./PromotionListFilter";
import {
	AppstoreAddOutlined,
	MoreOutlined,
	SettingOutlined,
} from "@ant-design/icons";
import { MenuProps } from "antd/lib";
import PromotionCouponList from "features/promotioncoupon/list/PromotionCouponList";
import CouponList from "./CouponList";
import TableInfoRow from "components/table/TableInfoRow";
import { CustomerJson } from "common/types/Customer";
import CustomerRepository from "common/repositories/CustomerRepository";
import TextMoney from "components/TextMoney";
import StoreRepository from "common/repositories/StoreRepository";
import { StoreJson } from "common/types/Store";
import Helper from "common/utils/helper";

const PromotionList = () => {
	const { t } = useTranslation();

	// modal editing store apply promotion
	const [editPromotionStoreVisible, setEditPromotionStoreVisible] =
		useState(false);

	// modal setting Compo code
	const [editPromotionCouponVisible, setEditPromotionCouponVisible] =
		useState(false);

	// modal setting promotion Product
	const [editPromotionProductVisible, setEditPromotionProductVisible] =
		useState(false);

	// modal setting promotion
	const [editPromotionVisible, setEditPromotionVisible] = useState(false);

	const [addPromotionVisible, setPromotionVisible] = useState(false);

	const [editingPromotionModel, setEditingPromotionModel] =
		useState<PromotionModel>(
			new PromotionModel(PromotionModel.getDefaultData())
		);
	const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

	const [group, setGroup] = useState(0);

	//////////////////////////////////////////
	//Filtering
	const defaultFilters: FilterPromotion = useMemo(
		() => PromotionRepository.getDefaultFilters(),
		[]
	);
	const [filters, setFilters] = useStateFilter<FilterPromotion>(defaultFilters);
	useFilterLocation(defaultFilters, filters);

	//////////////////////////////////////////
	//Fetch data from this collections
	const [total, setTotal] = useState(0);
	const [dataSource, setDataSource] = useState<PromotionModel[]>([]);
	const fetchData = useCallback(async (): Promise<PromotionCollection> => {
		let collection = await new PromotionRepository().getItems({
			filters,
		});
		setDataSource(collection.items);
		setTotal(collection.total);

		return collection;
	}, [filters]);

	// modal promotion form select group
	const [formSelectGroupVisible, setFormSelectGroupVisible] = useState(false);

	const onSaveSuccess = (item: PromotionModel) => {
		//detech this is NEW or UPDATE
		const foundIndex = dataSource.findIndex((r) => r.id === item.id);
		if (foundIndex >= 0) {
			//update current role item info
			setDataSource(
				update(dataSource, {
					[foundIndex]: {
						$set: item,
					},
				})
			);
		} else {
			//append new item to list
			setDataSource(
				update(dataSource, {
					$unshift: [item],
				})
			);
		}
	};

	const onEdit = (promotion: PromotionModel) => {
		setEditingPromotionModel(promotion);
		setEditPromotionVisible(true);
	};

	//Table columns
	const columns: TableColumnsType<PromotionModel> = [
		{
			title: t("promotion:code"),
			key: "code",
			align: "center",
			width: 150,
			render: (id, record) => {
				return <Typography.Link copyable>{id}</Typography.Link>;
			},
		},
		{
			title: t("promotion:name"),
			key: "name",
			width: 180,
		},
		{
			title: t("promotion:group"),
			width: 100,
			key: "group",
			render: (group: number) => {
				let groupInfo = PromotionModel.getGroup(group);
				return <span>{groupInfo?.label}</span>;
			},
		},
		{
			title: t("promotion:type"),
			width: 200,
			key: "type",
			render: (type: number) => {
				let groupInfo = PromotionModel.getType(type);
				return <Tag color={groupInfo?.color}>{groupInfo?.label}</Tag>;
			},
		},

		{
			title:
				t("worktrackingrange:time_start") +
				" - " +
				t("worktrackingrange:time_end"),
			dataIndex: "time_start",
			width: 150,
			key: "date_start",
			render: (text: string, record: PromotionModel) => (
				<span>
					<TextDateTime
						format={
							dayjs
								.unix(record.start_date)
								.isSame(dayjs.unix(record.end_date), "year")
								? "DD/MM"
								: undefined
						}
						ts={record.start_date}
					/>{" "}
					-
					<TextDateTime ts={record.end_date} />
				</span>
			),
		},
		// {
		//   title: t("promotion:form.section_store_title"),
		//   width: 100,
		//   key: "store_apply",
		//   toggletype: "extend",
		//   render: (_: any, record: PromotionModel) => {
		//     return (
		//       <Tooltip title={t("promotion:form.store")} mouseEnterDelay={0.4}>
		//         <Tag
		//           icon={<IconPencil size={14} />}
		//           className="opacity-50 cursor-pointer hover:opacity-100"
		//           onClick={() => {
		//             setEditPromotionStoreVisible(true);
		//             setEditingPromotionModel(record);
		//           }}
		//         >
		//           ...
		//         </Tag>
		//       </Tooltip>
		//     );
		//   },
		// },

		{
			title: t("Phạm vi áp dụng"),
			key: "sfs",
			width: 250,

			toggletype: "enable",

			render: (_: any, record: PromotionModel) => {
				return (
					<div className="flex gap-1">
						{record.store_scope === Promotion.STORESCOPE_ALL ? (
							<Tag color="blue">Toàn bộ hệ thống</Tag>
						) : (
							<Popover
								title="Các chi nhánh"
								content={<StoreList ids={record.store_list}></StoreList>}>
								<Tag>
									<AppstoreAddOutlined color="blue" className="text-blue-500" />
								</Tag>
							</Popover>
						)}

						{record.customer_scope === Promotion.CUSTOMERSCOPE_ALL ? (
							<Tag color="blue-inverse">Toàn bộ khách hàng</Tag>
						) : (
							<Popover
								title="Khách hàng được áp dụng"
								content={
									<CustomerList ids={record.customer_list}></CustomerList>
								}>
								<Tag>
									<AppstoreAddOutlined color="blue" className="text-blue-500" />
								</Tag>
							</Popover>
						)}

						{/* <Tooltip > */}

						{/* </Tooltip> */}
					</div>
				);
			},
		},

		// {
		// 	title: t("Điều kiện áp dụng"),
		// 	key: "s",
		// 	toggletype: "extend",
		// 	render: (_: any, record: PromotionModel) => {
		// 		const keys: string[] = ["max_quantity_check", "store_list", ""];
		// 		return (
		// 			<div>
		// 				<Tooltip
		// 					title={
		// 						<Row gutter={2}>
		// 							<Col span={24} className="flex gap-1">
		// 								<span>Số lượng:</span>
		// 								<span>
		// 									{record.max_quantity_check === 0
		// 										? "Vô hạn"
		// 										: record.max_quantity_check}
		// 								</span>
		// 							</Col>
		// 							<Col span={24} className="flex gap-1">
		// 								<span>Áp dụng:</span>
		// 								<span>
		// 									{record.customer_scope
		// 										? // <CustomerList
		// 										  // 	ids={[240, 239, 238, 237, 235, 233]}></CustomerList>
		// 										  "Một vài khách hàng"
		// 										: "Toàn bộ khác hàng"}
		// 								</span>
		// 							</Col>

		// 							<Col span={24} className="flex gap-1">
		// 								<span>Áp dụng:</span>
		// 								<span>
		// 									{record.store_scope
		// 										? // <CustomerList
		// 										  // 	ids={[240, 239, 238, 237, 235, 233]}></CustomerList>
		// 										  "Một vài chi nhánh"
		// 										: "Toàn bộ hệ thống"}
		// 								</span>
		// 							</Col>

		// 							<Col span={24} className="flex gap-1">
		// 								<span>Giá trị đơn hàng tối thiểu:</span>
		// 								<span>
		// 									<TextMoney money={record.min_check}></TextMoney>
		// 								</span>
		// 							</Col>
		// 						</Row>
		// 					}>
		// 					<IconEye></IconEye>
		// 				</Tooltip>
		// 			</div>
		// 		);
		// 	},
		// 	width: 120,
		// },
		{
			title: t("common:status"),
			key: "status",

			render: (status: number) => {
				const statusInfor = PromotionModel.getStatus(status);
				return (
					<div>
						<Tag color={statusInfor?.color}>{statusInfor?.label}</Tag>
					</div>
				);
			},
			width: 120,
		},
		{
			title: t("Thiết lập nhóm hàng"),
			key: "type",

			width: 120,
			render: (_: any, record: PromotionModel) => {
				return (
					<div>
						<Tag
							color="blue"
							icon={<IconSettings size={14} />}
							className=" ml-1 cursor-pointer"
							onClick={() => {
								setEditPromotionProductVisible(true);
								setEditingPromotionModel(record);
							}}>
							Thiết lập
						</Tag>
					</div>
				);
			},
		},
		{
			title: t("common:date_created"),
			key: "create_date",
			width: 120,
			render: (create_date) => Helper.formatTimestampToText2(create_date),
		},
		{
			title: " ",
			key: "actions",
			align: "right",
			fixed: "right",
			toggletype: "trigger",
			width: 150,
			render: (_: any, record: PromotionModel) => {
				let items: MenuProps["items"] = [
					{
						key: "1",
						label: (
							<TableEdit
								label={t("Chỉnh sửa")}
								link=""
								onClick={() => onEdit(record)}></TableEdit>
						),
					},
				];

				if (record.group == Promotion.GROUP_COUPON_ID) {
					items.push({
						key: "2",
						label: (
							<TableEdit
								label="Thiết lập mã"
								link={`/promotion/edit/id/${record.id}`}></TableEdit>
						),
					});
				}
				return (
					<RoleCheck roles={[Role.PROMOTION_MANAGE]} hideOnFail>
						{/* <TableEdit link="" onClick={() => onEdit(record)} /> */}
						<div className="flex justify-end">
							<Dropdown trigger={["click"]} menu={{ items }}>
								<MoreOutlined
									style={{ fontSize: "16pt" }}
									className="cursor-pointer "
								/>
							</Dropdown>
							{/* {record.group === "coupon" && (
								<TableEdit
									label="Thiết lập mã"
									link={`/promotion/edit/id/${record.id}`}></TableEdit>
							)} */}
							<TableInfo record={record}></TableInfo>
						</div>
					</RoleCheck>
				);
			},
		},
	];

	//expand

	const expandable = useMemo(
		() => ({
			expandedRowRender: (record: PromotionModel) => {
				if (record.group !== Promotion.GROUP_COUPON_ID) {
					return null;
				}
				// return <ProductItemExpand recordId={record.id} />;
				return <CouponList promotionId={record.id}></CouponList>;
			},
			expandedRowClassName: () => "ant-table-expanded-row-dimmer",
			expandRowByClick: true,
			showExpandColumn: false,
			expandedRowKeys: expandedRowKeys,
			onExpand: (expanded: boolean, record: PromotionModel) => {
				if (expanded) {
					setExpandedRowKeys([record.id]);
				} else {
					setExpandedRowKeys([]);
				}
			},
		}),
		[expandedRowKeys]
	);

	return (
		<>
			<PromotionListHeader>
				<RoleCheck roles={[Role.PROMOTION_MANAGE]}>
					<PageHeaderButton
						type="primary"
						link=""
						onClick={() => {
							// setFormSelectGroupVisible(true);
							setEditPromotionVisible(true);
							setEditingPromotionModel(
								new PromotionModel(PromotionModel.getDefaultData())
							);
						}}>
						{t("promotion:add_button")}
					</PageHeaderButton>
				</RoleCheck>
			</PromotionListHeader>

			<PromotionListFilter
				filters={filters}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
				total={total}
			/>

			<PageDataTable<FilterPromotion, PromotionModel, PromotionCollection>
				{...{
					columns,
					// expandable,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					tableColumnToggleKey: "promotion",
				}}></PageDataTable>

			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>

			{/* {editPromotionStoreVisible ? (
        <PromotionSettingStoreModal
          model={editingPromotionModel}
          open={editPromotionStoreVisible}
          setOpen={(isOpen) => {
            //clear editing id when close
            if (!isOpen) {
              setEditingPromotionModel(
                new PromotionModel(PromotionModel.getDefaultData())
              );
            }
            setEditPromotionStoreVisible(isOpen);
          }}
          onSaveSuccess={onSaveSuccess}
        />
      ) : null}

      {editPromotionCouponVisible ? (
        <PromotionCouponModal
          model={editingPromotionModel}
          open={editPromotionCouponVisible}
          setOpen={(isOpen) => {
            //clear editing id when close
            if (!isOpen) {
              setEditingPromotionModel(
                new PromotionModel(PromotionModel.getDefaultData())
              );
            }
            setEditPromotionCouponVisible(isOpen);
          }}
        />
      ) : null} */}

			{editPromotionProductVisible ? (
				<PromotionProductModal
					model={editingPromotionModel}
					open={editPromotionProductVisible}
					onUpdate={() => {
						fetchData();
					}}
					setOpen={(isOpen) => {
						//clear editing id when close
						if (!isOpen) {
							setEditingPromotionModel(
								new PromotionModel(PromotionModel.getDefaultData())
							);
						}
						setEditPromotionProductVisible(isOpen);
					}}
				/>
			) : null}

			{/* <PromotionFormSelectGroupModal
        key={"cashflow-form-select-type-modal"}
        open={formSelectGroupVisible}
        setOpen={(isOpen) => {
          setFormSelectGroupVisible(isOpen);
        }}
        setGroup={(group) => {
          setGroup(group);
          setEditPromotionVisible(true);
        }}
      /> */}

			{editPromotionVisible ? (
				<PromotionFormModal
					id={editingPromotionModel.id}
					open={editPromotionVisible}
					group={group}
					setOpen={(isOpen) => {
						//clear editing id when close
						if (!isOpen) {
							setEditingPromotionModel(
								new PromotionModel(PromotionModel.getDefaultData())
							);
						}
						setEditPromotionVisible(isOpen);
					}}
					onSaveSuccess={onSaveSuccess}
				/>
			) : null}
		</>
	);
};

export default PromotionList;

const CustomerList = ({ ids }: { ids: number[] }) => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<CustomerJson[]>([]);

	const fetchData = async () => {
		setLoading(true);

		const res = await new CustomerRepository().getItems({
			filters: {
				...CustomerRepository.getDefaultFilters(),
				list_id: ids.join(","),
			},
		});

		if (!res.hasError()) {
			setData(res.toJson().items);
		}
		setLoading(false);
	};
	useEffect(() => {
		ids.length > 0 && fetchData();
	}, [ids.length]);

	return (
		<>
			<List
				size="small"
				className="max-h-[500px] overflow-auto"
				dataSource={data}
				loading={loading}
				renderItem={(i) => {
					return (
						<List.Item>
							<Typography.Text>
								{i.full_name.length > 0 ? (
									i.full_name
								) : (
									<Tag color="gray">Vô danh</Tag>
								)}
							</Typography.Text>
						</List.Item>
					);
				}}></List>
		</>
	);
};

const StoreList = ({ ids }: { ids: number[] }) => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<StoreJson[]>([]);

	const fetchData = async () => {
		setLoading(true);
		const res = await new StoreRepository().getItems({
			filters: {
				...StoreRepository.getDefaultFilters(),
				ids: ids.join(","),
			},
		});

		if (!res.hasError()) {
			setData(res.toJson().items);
		}
		setLoading(false);
	};
	useEffect(() => {
		ids.length > 0 && fetchData();
	}, [ids.length]);

	return (
		<>
			<List
				className="max-h-[500px] overflow-auto"
				dataSource={data}
				loading={loading}
				renderItem={(i) => {
					return (
						<List.Item>
							<Typography.Text>{i.name}</Typography.Text>
						</List.Item>
					);
				}}></List>
		</>
	);
};
