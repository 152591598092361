import { Col, Row } from "antd";
import { DateRange } from "common/types/BiCenter";
import { StoreJson } from "common/types/Store";
import TextMoney from "components/TextMoney";
import ReportChartCategory from "features/report/chart/ReportChartCategory";
import ReportChartDataTable from "features/report/chart/ReportChartDataTable";
import useDatabaseTable from "hooks/useDatabaseTable";
import React, { useCallback } from "react";

const ReportSalePromotionStore = ({
  getRange,
  getRangeCompare
}: {
  getRange: () => DateRange;
  getRangeCompare: () => DateRange | null;
}) => {
  const [storeItemsWithLimit] = useDatabaseTable<StoreJson>("store");
  const formatterStoreValue = useCallback(
    (value: number) => {
      return storeItemsWithLimit.find((i) => i.id === value)?.name;
    },
    [storeItemsWithLimit]
  );

  return (
    <>
      <div className="p-6">
        <Row gutter={16}>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartCategory
              className="p-4"
              title={"Giá trị khuyến mãi theo cửa hàng"}
              dataTable="order"
              dataService="order"
              dataSelect="sum(pricediscount), store"
              dataFilter="status < 20,promotion != 0"
              dataGroupBy="store"
              dataOrderBy="sum(pricediscount) desc"
              keyMapping={{
                name: "store",
                value: "sum_pricediscount",
                formatter: (value: number) => {
                  return formatterStoreValue(value) || "--";
                }
              }}
              height={400}
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
            />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartDataTable
              className="p-4"
              title={"Áp dụng theo cửa hàng"}
              subtitle="Không lấy đơn hàng ở trạng thái hủy"
              dataTable="order"
              dataService="order"
              keyMapping={[
                {
                  title: "Cửa hàng",
                  name: "store",
                  formatter: (value: number | string) => {
                    return <>{formatterStoreValue(+value)}</>;
                  }
                },
                {
                  title: "Tổng giá trị giảm",
                  name: "sum_pricediscount",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(sum_pricediscount)"
                },
                {
                  title: "Đơn có áp dụng",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), store, sum(pricediscount)"
              dataFilter="status < 20,promotion != 0"
              dataGroupBy="store"
              dataOrderBy="sum(pricediscount) DESC"
              dateRange={getRange()}
            />
          </Col>
        </Row>

        <Row gutter={16} className="mt-4">
          <Col flex="1">
            <ReportChartDataTable
              className="p-4"
              title={"Báo cáo áp dụng theo cửa hàng và chương trình khuyến mãi"}
              subtitle="Xem số liệu khuyến mãi, giảm giá theo cửa hàng đối với từng chương trình khuyến mãi"
              fetcherObject="promotion"
              fetcherKeyMapping="promotion"
              dataTable="order"
              dataService="order"
              keyMapping={[
                {
                  title: "Cửa hàng",
                  name: "store",
                  formatter: (value: number | string) => {
                    return <>{formatterStoreValue(+value)}</>;
                  },
                  sortField: "store"
                },
                {
                  title: "Chương trình khuyến mãi",
                  name: "promotion",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "promotion"
                },
                {
                  title: "Tổng giá trị giảm",
                  name: "sum_pricediscount",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(pricediscount)"
                },
                {
                  title: "Đơn có áp dụng",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), store, promotion, sum(pricediscount)"
              dataFilter="status < 20,promotion != 0"
              dataGroupBy="store,promotion"
              dataOrderBy="sum(pricediscount) DESC"
              dateRange={getRange()}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReportSalePromotionStore;
