import { Typography } from "antd";
import ContactModel from "common/models/ContactModel";
import { t } from "i18next";
import { memo } from "react";

interface IContactListExpandProps {
  record: ContactModel | null;
}

const ContactListExpand = ({
  record,
}: IContactListExpandProps): JSX.Element => {
  return (
    <Typography className="w-full bg-white p-2 border border-[#d9d9d9] rounded block whitespace-pre-line">
      <Typography.Text className="font-bold mr-1">Nội dung:</Typography.Text>
      <Typography.Text>
        {String(record?.content).length > 0 ? record?.content : t("feedback:no_content")}
      </Typography.Text>
    </Typography>
  );
};

export default memo(ContactListExpand);
