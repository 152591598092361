import ContactApi from "common/api/ContactApi";
import ContactCollection from "common/collections/ContactCollection";
import {
  ContactAddEditJson,
  FilterContact,
} from "common/types/Contact";
import { GetListProps } from "common/types/Filter";
import BaseRepository from "./BaseRepository";
import ContactModel from "common/models/ContactModel";

class ContactRepository extends BaseRepository<ContactApi> {
  _api: ContactApi | null;

  constructor() {
    super();
    this._api = new ContactApi(true);
  }

  static getDefaultFilters(): FilterContact {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      comments_on: null,
    };
  }

  async getItems(props: GetListProps<FilterContact>) {
    return this._api
      ? await this._api.getList(props)
      : new ContactCollection();
  }

  async saveRemote(data: ContactAddEditJson): Promise<ContactModel> {
    if (this._api) {
      return await this._api.edit(data);
    } else {
      return new ContactModel(ContactModel.getDefaultData());
    }
  }
}

export default ContactRepository;
