const Product = {
	STATUS_ENABLE: 1,
	STATUS_DISABLED: 3,

	UNIT_DEFAULT: 1,
	UNIT_BAG: 3,
	UNIT_CARTON: 5,
	UNIT_TICKET: 7,
	UNIT_BOTTLE: 9,
	UNIT_PIECE: 11,
	UNIT_GLASS: 13,
	UNIT_ANIMAL: 15,
	UNIT_SET: 17,
	UNIT_PAIR: 19,
	UNIT_ROLL: 21,
	UNIT_BOOK: 23,

	SHOW_WEB: "web",
	SHOW_POS: "pos",
	SHOW_ALL: "all",
};

export default Product;
