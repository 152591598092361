import { Form, Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import SettingRepository from "common/repositories/SettingRepository";
import { SettingJson } from "common/types/Setting";
import FormSection from "components/form/FormSection";
import LayoutForm from "components/form/LayoutForm";
import useSettingValues from "hooks/useSettingValues";
import { t } from "i18next";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ShowOnWebsiteFormHeader from "./ShowOnWebsiteFormHeader";

const settingEdits = [
  {
    key: "setting_child_promotion_label",
    title: t("Cài đặt hiển thị tag sản phẩm"),
    subtitle: t("Cài đặt hiển thị tag sản phẩm khi sản phẩm con có khuyến mãi"),
  },
];

const ShowOnWebsiteForm = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);

  const settingKeys = useMemo(
    () => settingEdits.map((setting) => setting.key),
    [JSON.stringify(settingEdits)]
  );

  const { settings, isLoading } = useSettingValues(settingKeys);

  const settingKeyValues = useMemo(
    () =>
      settingEdits.map((settingEdit) => {
        const setting = settings.find(
          (setting) => setting.key === settingEdit.key
        );

        if (!setting) {
          return {
            [settingEdit.key]: "",
          };
        }

        return {
          [settingEdit.key]: JSON.stringify(setting.value),
        };
      }),
    [settingEdits, settings]
  );

  const initialValues = useMemo(
    () => settingKeyValues.reduce((acc, obj) => ({ ...acc, ...obj }), {}),
    [settingKeyValues]
  );

  const doPrepareData = useCallback(
    (formData: any) => {
      return settingEdits
        .map((settingEdit) => {
          const setting = settings.find(
            (setting) => setting.key === settingEdit.key
          );

          if (!setting) {
            return {
              id: 0,
              group: "SHOP",
              key: settingEdit.key,
              lang: "vi",
              value: formData[settingEdit.key],
            } as SettingJson;
          }

          if (formData[setting.key] !== JSON.stringify(setting.value)) {
            return {
              id: setting.id,
              group: setting.group,
              key: setting.key,
              lang: setting.lang,
              status: setting.status,
              value: formData[setting.key],
            } as SettingJson;
          }
        })
        .filter((setting) => setting !== undefined);
    },
    [settingEdits, settings]
  );

  const onSubmit = async (formData: any) => {
    setIsSuccess(false);
    const preparedData = doPrepareData(formData);

    var dataPromises: Promise<any>[] = [];
    preparedData.map((data) =>
      dataPromises.push(new SettingRepository().saveRemote(data as SettingJson))
    );

    await Promise.allSettled(dataPromises);
    setIsSuccess(true);
  };

  return (
    <Spin spinning={isLoading}>
      <ShowOnWebsiteFormHeader />
      {!isLoading && (
        <LayoutForm
          form={form}
          initialValues={initialValues}
          errors={errors}
          isSuccess={isSuccess}
          successTitle={t("showonwebsite:form.success.update")}
          error_translate_prefix="showonwebsite:form.error"
          onSubmit={onSubmit}
          submitText={t("common:form.submit")}
          sidebarItems={<></>}
        >
          {settingEdits.map((settingEdit) => {
            return (
              <FormSection
                key={settingEdit?.key}
                title={settingEdit?.title}
                subtitle={settingEdit?.subtitle}
                divider
              >
                <Form.Item
                  label={t("tag:value")}
                  name={settingEdit?.key}
                  className="mb-0"
                  rules={[
                    {
                      required: true,
                      message: t("Vui lòng nhập"),
                    },
                  ]}
                >
                  <TextArea rows={10} />
                </Form.Item>
              </FormSection>
            );
          })}
        </LayoutForm>
      )}
    </Spin>
  );
};

export default ShowOnWebsiteForm;
