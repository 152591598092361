import ShipperKPICollection from "common/collections/ShipperKPICollection";
import { DataShipperKPI } from "common/fake/data-shipper-kpi";
import type { GetListProps } from "common/types/Filter";
import { FilterShipperKPI } from "common/types/ShipperKPI";
import Helper from "common/utils/helper";
import BaseApi from "./BaseApi";

const SERVICE_URL = "/shippers";

class ShipperKPIApi extends BaseApi {
  async getList(
    props: GetListProps<FilterShipperKPI>
  ): Promise<ShipperKPICollection> {
    let collection = new ShipperKPICollection();
    const convertProps = Helper.convertParams(props.filters);

    const queryData = {
      params: {
        ...convertProps,
        sort_by: props.filters.sortby,
        sort_type: props.filters.sorttype,
      },
    };
    try {
      // const response = await AxiosClient().get<any>(SERVICE_URL + "?type=2", queryData);
      // if (response.hasOwnProperty("data")) {
      //   collection.fromJson(response.data);
      // }
      collection.fromJson(DataShipperKPI);
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }
}

export default ShipperKPIApi;
