import { List, Skeleton, Typography } from "antd";
import LogAuditRepository from "common/repositories/LogAuditRepository";
import LogAuditItem from "features/logaudit/item/LogAuditItem";
import { useCallback, useEffect, useMemo, useState } from "react";

import useDidMount from "@rooks/use-did-mount";
import { IconClock } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { LogAuditJson, FilterLogAudit } from "common/types/LogAudit";
import useStateFilter from "hooks/useStateFilter";
import HomeLeftListWrapper from "../HomeLeftListWrapper";
import LogAuditModel from "common/models/LogAuditModel";
import useProcessStore from "zustands/useProcessStore";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import useEmployeeSource from "hooks/useEmployeeSource";
import useIdFetcher from "hooks/useIdFetcher";
import { IdFetcherResult } from "common/types/IdFetcher";

type Data = LogAuditJson & {
	loading?: boolean;
};
const HomeLeftNewsfeed = () => {
	const { Text } = Typography;
	const { t } = useTranslation();
	const account = useLoginAccountStore((state) => state.account);
	const page = 1;
	const { getListIdFetcher } = useIdFetcher();

	const defaultFilters = useMemo(() => {
		return LogAuditRepository.getDefaultFilters();
	}, []);

	const [filters, setFilters] = useStateFilter(defaultFilters);

	const [loading, setLoading] = useState(true);
	const [errors, setErrors] = useState<string[]>([]);
	const [dataSource, setDataSource] = useState<Data[]>([]);
	const [employees, setEmployees] = useState<IdFetcherResult[]>([]);
	
	const getListEmployee = useCallback(async (id_list: number[]) => {
		const response = await getListIdFetcher({
			object_type: "employee",
			ids: id_list,
		});

		if (!response.hasError()) {
			const result = response.items.filter(
				(i) => i.object_type === "employee"
			)[0].result!;
			setEmployees(result);
		}
	}, [getListIdFetcher]);

	const fetchData = useCallback(async () => {
		setErrors([]);
		setLoading(true);
		const collection = await new LogAuditRepository().getItems({
			filters: {
				...defaultFilters,
				creator_id: account.id,
				page: page,
				limit: 500,
			},
		});
		setLoading(false);
		if (collection.hasError()) {
			setErrors(collection.error.errors);
		} else {
			setDataSource(collection.items);

			const employeeIds = [...new Set(collection.items.map((i) => i.creator_id || 0))];
			await getListEmployee(employeeIds);
		}
	}, [page]);

	useDidMount(() => {
        fetchData();
    });

	return (
		<div>
			<HomeLeftListWrapper<FilterLogAudit, Data>
				{...{
					filters,
					setFilters,
					dataSource,
					errors,
					iconTitle: <IconClock size={18} />,
					loading: loading,
					title: t("Hoạt động gần đây"),

					renderItem(item) {
						return (
							<Skeleton
								className="px-2"
								loading={item.loading}
								active
								avatar
								title={false}
								round>
								<div
									className="flex items-center justify-between border-[1px] border-gray-200 p-2 my-2"
									key={item.id}>
									<LogAuditItem model={new LogAuditModel(item)} employees={employees} />
								</div>
							</Skeleton>
						);
					},
				}}></HomeLeftListWrapper>
		</div>
	);
};

export default HomeLeftNewsfeed;
