import { Button, Row, Tag, Tooltip, message } from "antd";
import { TableColumnsType } from "common/types/Table";
import RoleCheck from "components/RoleCheck";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";

import TagCollection from "common/collections/TagCollection";
import TagModel from "common/models/TagModel";
import TagRepository from "common/repositories/TagRepository";
import { FilterTag } from "common/types/Tag";

import { IconPerfume } from "@tabler/icons-react";
import TagConstant from "common/constants/Tag";
import TagTypeModel from "common/models/TagTypeModel";
import TableDelete from "components/table/TableDelete";
import ProductToTagModal from "features/tag/product/ProductToTagModal";
import TagInsideFormModal from "./TagInsideFormModal";
import TagInsideListFilter from "./TagInsideListFilter";
import Helper from "common/utils/helper";

const TagInsideList = ({ record }: { record: TagTypeModel }) => {
	const { t } = useTranslation();
	// default filter
	const defaultFilters = useMemo(() => {
		return {
			...TagRepository.getDefaultFilters(),
			type: record.id,
			is_root: record.id === 2 ? TagConstant.ROOT.USER : TagConstant.ROOT.USER,
		};
	}, []);
	//////////////////////////////////////////
	//Fetch data from this collections
	const [filters, setFilters] = useState(defaultFilters);
	// useFilterLocation(defaultFilters, filters);

	const [dataSource, setDataSource] = useState<TagModel[]>([]);
	const [total, setTotal] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const fetchData = useCallback(async (): Promise<TagCollection> => {
		setLoading(true);
		const response = await new TagRepository().getItems({
			filters: filters,
		});
		if (!response.hasError()) {
			setTotal(response.total);
			setDataSource(response.items);
		} else {
			message.error("common:error.error_fetching_data");
		}

		setLoading(false);
		return response;
	}, [filters]);

	// modal editing
	const [addVisible, setAddVisible] = useState(false);
	const [editingId, setEditingId] = useState(0);

	const renderTagStatus = (value: number) => {
		switch (value) {
			case 1:
				return <Tag color="green">Hiện</Tag>;
			case 2:
				return <Tag>Nháp</Tag>;
			case 3:
				return <Tag color="red">Ẩn</Tag>;
			default:
				return null;
		}
	};

	const [addProductVisible, setAddProductVisible] = useState(false);
	const [tags, setTags] = useState<string>("");
	const [tagName, setTagName] = useState<string>("");
	const [editingProductTagId, setEditingProductTagId] = useState<number>(0);
	const onEditProduct = (record: TagModel) => {
		setEditingProductTagId(record.id);
		setTags(record.code);
		setTagName(record.name);
		setAddProductVisible(true);
	};

	//Table columns
	const columns: TableColumnsType<TagModel> = [
		{
			title: "ID",
			key: "id",
		},
		{
			title: t("tag:code"),
			key: "code",
		},
		{
			title: t("tag:name"),
			key: "name",
		},
		{
			title: t("Loại tag"),
			key: "type",
			render(value, record, index) {
				return <>{record.type.length > 0 ? record.type[0]?.name : ""}</>;
			},
		},
		{
			title: t("tag:value"),
			key: "value",
		},
		{
			title: t("Dùng cho"),
			key: "resource_type",
			render(value, record, index) {
				return <>{TagModel.getResouceTypeValue(value)}</>;
			},
		},
		{
			title: t("tag:status"),
			key: "status",
			render(value, record, index) {
				return renderTagStatus(value);
			},
		},
		{
			title: t("common:date_created"),
			key: "date_created",
			width: 120,
			render: (date_created) => Helper.formatTimestampToText2(date_created),
		},
		{
			title: " ",
			key: "actions",
			align: "right",
			fixed: "right",
			toggletype: "trigger",
			width: 189,
			render: (_: any, record: TagModel) => (
				<RoleCheck roles={[]} hideOnFail>
					{record.is_root === 2 ? (
						<TableEdit link="" onClick={() => onEdit(record.id)} />
					) : (
						<></>
					)}
					{record.resource_type === TagConstant.RESOURCE_TYPE.PRODUCT ? (
						<>
							<Button
								size={"small"}
								type={"text"}
								onClick={() => onEditProduct(record)}>
								<Tooltip title="Quản lý sản phẩm">
									<IconPerfume
										className="-mt-0.5 text-gray-400 hover:text-red-500"
										size="18"
									/>
								</Tooltip>
							</Button>
						</>
					) : (
						<></>
					)}
					{record.is_root === 2 ? (
						<>
							<TableDelete
								error_translate_prefix="tag:form.error"
								onDeleteCallback={(id) => {
									setDataSource(dataSource.filter((item) => item.id !== id));
								}}
								repository={new TagRepository()}
								id={record.id}
							/>
						</>
					) : (
						<></>
					)}
					<TableInfo record={record} />
				</RoleCheck>
			),
		},
	];

	const onEdit = (id: number) => {
		setEditingId(id);
		setAddVisible(true);
	};

	const onSaveSuccess = (item: TagModel) => {
		fetchData();
	};

	return (
		<>
			<TagInsideListFilter
				defaultFilters={defaultFilters}
				filters={filters}
				setFilters={setFilters}
				total={total}
				record={record}
			/>

			<Row className="flex justify-end my-4">
				<Button
					type="primary"
					onClick={() => {
						setEditingId(0);
						setAddVisible(true);
					}}>
					{t("common:table.add_button")}
				</Button>
			</Row>

			<PageDataTable<FilterTag, TagModel, TagCollection>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					tableColumnToggleKey: "taginsidelist",
				}}></PageDataTable>

			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>

			<TagInsideFormModal
				id={editingId}
				key={editingId}
				open={addVisible}
				tagTypeDetail={record}
				setOpen={(isOpen) => {
					//clear editing id when close
					if (!isOpen) {
						setEditingId(0);
					}
					setAddVisible(isOpen);
				}}
				onSaveSuccess={onSaveSuccess}
			/>

			<ProductToTagModal
				id={editingProductTagId}
				key={editingProductTagId}
				open={addProductVisible}
				tags={tags}
				tagName={tagName}
				setOpen={(isOpen) => {
					//clear editing id when close
					if (!isOpen) {
						setEditingProductTagId(0);
						setTags("");
					}
					setAddProductVisible(isOpen);
				}}
			/>
		</>
	);
};

export default TagInsideList;
