import { IconBuildingWarehouse } from "@tabler/icons-react";
import { Button, message, Popconfirm, Tooltip } from "antd";
import TagConstant from "common/constants/Tag";
import SettingModel from "common/models/SettingModel";
import TagDetailModel from "common/models/TagDetailModel";
import TagRepository from "common/repositories/TagRepository";
import TagTypeRepository from "common/repositories/TagTypeRepository";
import { t } from "i18next";
import { memo, useCallback, useEffect, useState } from "react";

export enum EWarehouseMarkTesterTagBtnEvent {
    RERENDER = "RERENDER",
};

interface IWarehouseMarkTesterTagBtnProps {
    warehouseId: number;
    settingTesterTagType: SettingModel;
    settingTesterTag: SettingModel;
}

interface IWarehouseMarkTesterTagBtnState {
    isLoading: boolean;
    isHavingTag: boolean;
    tagDetail: TagDetailModel | undefined;
}

const WarehouseMarkTesterTagBtn = ({
    warehouseId,
    settingTesterTagType,
    settingTesterTag,
}: IWarehouseMarkTesterTagBtnProps): JSX.Element => {
    const [btnState, setBtnState] = useState<IWarehouseMarkTesterTagBtnState>({
        isLoading: true,
        isHavingTag: false,
        tagDetail: undefined,
    });

    const checkIsHavingTesterTag = useCallback(async () => {
        const response = await new TagRepository().getItemsDetail({
            filters: {
                ...TagRepository.getDefaultTagDetailFilters(),
                resource_id: warehouseId,
                resource_type: TagConstant.RESOURCE_TYPE.WAREHOUSE,
                is_root: TagConstant.ROOT.USER,
            },
        });

        const tagDetailModel = response.items.find((item) => item.tag.find((tagItem) => tagItem.code === settingTesterTag.value))!;

        setBtnState({
            isLoading: false,
            isHavingTag: tagDetailModel?.id > 0,
            tagDetail: tagDetailModel
        });
    }, [warehouseId, settingTesterTag.value]);

    useEffect(() => {
        checkIsHavingTesterTag();

        window.addEventListener(`${EWarehouseMarkTesterTagBtnEvent.RERENDER}-${warehouseId}`, checkIsHavingTesterTag);
        return () => window.removeEventListener(`${EWarehouseMarkTesterTagBtnEvent.RERENDER}-${warehouseId}`, checkIsHavingTesterTag);
    }, [checkIsHavingTesterTag, warehouseId]);

    const markWorkflow = async () => {
        setBtnState((prevState) => ({
            ...prevState,
            isLoading: true,
        }));

        try {
            if (btnState.isHavingTag) {
                await removeTesterTag();
            } else {
                await checkTesterTag();
            };
        } catch (error) {
            message.error(String(error));
        } finally {
            setBtnState((prevState) => ({
                ...prevState,
                isLoading: false,
            }));
        }
    };

    const removeTesterTag = async () => {
        const response = await new TagRepository().deleteTagDetailItem([btnState.tagDetail?.id!]);

        if (response.length === 0) {
            message.success({ content: "Xóa đánh dấu thành công", duration: 2 });
            await checkIsHavingTesterTag();
        } else {
            throw new Error("Thao tác thất bại");
        }
    };

    const doMarkTesterTag = async (tagId: number) => {
        const response = await new TagRepository().addTagDetail({
            details: [{
                tag_id: tagId,
                resource_id: warehouseId,
                resource_type: TagConstant.RESOURCE_TYPE.WAREHOUSE
            }],
        });

        if (!response.hasError()) {
            message.success({ content: "Đánh dấu thành công", duration: 2 });
            await checkIsHavingTesterTag();
        } else {
            throw response.error.errors;
        }
    };

    const checkTesterTag = async () => {
        const testerTag = await new TagRepository().getItems({
            filters: {
                ...TagRepository.getDefaultFilters(),
                code: settingTesterTag.value
            }
        });

        if (testerTag.items.length > 0) {
            await doMarkTesterTag(testerTag.items[0].id);
        } else {
            await checkTesterTagType();
        }
	};

    const checkTesterTagType = async () => {
        const testerTagType = await new TagTypeRepository().getItems({
            filters: {
                ...TagTypeRepository.getDefaultFilters(),
                code: settingTesterTagType.value
            }
        });

        if (testerTagType.items.length > 0) {
            await createTesterTag(testerTagType.items[0].id);
        } else {
            await createTesterTagType();
        }
    };

    const createTesterTag = async (testerTagTypeId: number) => {
        const response: any = await new TagRepository().saveRemote({
            id: 0,
            code: settingTesterTag.value,
            name: settingTesterTag.value.replaceAll("-", " ").toUpperCase(),
            status: 1,
            type: testerTagTypeId,
            value: settingTesterTag.value,
            resource_type: TagConstant.RESOURCE_TYPE.WAREHOUSE,
            is_root: TagConstant.ROOT.USER,
        });

        if (!response.hasError()) {
            message.success({ content: "Tạo tester tag thành công", duration: 2 });
            await doMarkTesterTag(response[0].tag_id);
        } else {
            throw response.error.errors;
        }
    };

    const createTesterTagType = async () => {
        const response: any = await new TagTypeRepository().saveRemote({
            id: 0,
            code: settingTesterTagType.value,
            name: settingTesterTagType.value.replaceAll("-", " ").toUpperCase(),
            status: 1,
            resource_type: TagConstant.RESOURCE_TYPE.WAREHOUSE,
        });

        if (!response.hasError()) {
            message.success({ content: "Tạo tester tag type thành công", duration: 2 });
            await createTesterTag(response.tag_type_id);
        } else {
            throw response.error.errors;
        }
    };

    return (
        <Tooltip title={btnState.isHavingTag ? "Kho ảo cho tester" : "Đánh dấu kho ảo cho tester"}>
            <Popconfirm
                title={btnState.isHavingTag ? "Bạn có muốn xóa đánh dấu kho ảo cho tester" : "Bạn có muốn đánh dấu kho ảo cho tester"}
                placement="topRight"
                onConfirm={markWorkflow}
                okText={t("common:table.confirm_ok")}
                cancelText={t("common:table.confirm_cancel")}
                disabled={btnState.isLoading}
            >
                <Button
                    type="text"
                    loading={btnState.isLoading}
                    icon={
                        btnState.isHavingTag ? (
                            <IconBuildingWarehouse
                                className="-mt-0.5 !text-yellow-500"
                                size="18"
                            />
                        ) : (
                            <IconBuildingWarehouse
                                className="-mt-0.5 !text-gray-400 hover:!text-red-500"
                                size="18"
                            />
                        )
                    }
                />
            </Popconfirm>
        </Tooltip>
    );
}

export default memo(WarehouseMarkTesterTagBtn);
