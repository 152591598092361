import { Col, Form, FormInstance, Input, Row, Tag, Typography } from "antd";
import OrderCollection from "common/collections/OrderCollection";
import StoryCollection from "common/collections/StoryCollection";
import InventoryReceipt from "common/constants/InventoryReceipt";
import InventoryReceiptModel from "common/models/InventoryReceiptModel";
import OrderModel from "common/models/OrderModel";
import OrderRepository from "common/repositories/OrderRepository";
import PublicationsRepository from "common/repositories/PublicationsRepository";
import {
	InventoryReceiptJson,
	InventoryReceiptSourceType,
	InventoryReceiptTargetType,
} from "common/types/InventoryResceipt";
import { FilterOrder, OrderJson } from "common/types/Order";
import { FilterStory, StoryJson } from "common/types/Story";
import AttachmentSearchSelect from "components/attachmentSearchSelect/AttachmentSearchSelect";
import FormSelect from "components/form/FormSelect";
import OrderSearchSelect from "components/order/OrderSearchSelect";
import SearchComponent from "components/search/SearchLayout";
import SupplierSearchSelect from "components/SupplierSearchSelect";
import AttachmentSearch from "features/productinventorychecking/components/AttachmentSearch";
import WarehouseFormSelect from "features/warehouse/WarehouseFormSelect";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type Props = {
	disabled?: boolean;
	model: InventoryReceiptJson;
	form: FormInstance<any>;
};
const InventoryReceiptSource = ({ disabled, model, form }: Props) => {
	const { t } = useTranslation();
	const [isRequire, setIsRequire] = useState({
		sourceId: true,
	});

	const sourceValue = Form.useWatch(["source", "id"], form);
	const sourceTypeWatch = Form.useWatch(["source", "type"], form);

	let com = <></>;

	switch (sourceTypeWatch) {
		case InventoryReceiptSourceType.WAREHOUSE:
			com = (
				<WarehouseFormSelect
					disabled={disabled}
					rules={[
						{
							required: true,
							message: "Vui lòng chọn",
						},
					]}
					label="Chọn kho"
					name={["source", "id"]}></WarehouseFormSelect>
			);
			break;
		case InventoryReceiptSourceType.ORDER:
			com = (
				<SearchComponent<OrderJson, FilterOrder, OrderCollection>
					fetchRepository={(filers) => {
						return new OrderRepository().getItems({
							filters: {
								...filers,
							},
						});
					}}
					defaultFilters={OrderRepository.getDefaultFilters()}
					formItemProps={{
						label: "Mã đơn hàng (ID)",
						name: ["source", "id"],
						rules: [
							{
								required: true,
								message: t("common:form.required"),
							},
						],
					}}
					form={form}
					disabled={disabled}
					keyValue="id"
					showFilters={["keyword", "code"]}
					defaultUseFilters={["keyword"]}
					keyFetchInitData={"keyword"}
					renderLabel={(item) => {
						const label = OrderModel.getTypeInvoice(item.type);
						return (
							<>
								<Typography.Text className={`text-${label?.color}-500`}>
									{label?.label} -{" "}
								</Typography.Text>

								<Typography.Text
									copyable
									className={`text-${label?.color}-500`}>
									{item.code}
								</Typography.Text>
							</>
						);
					}}
				/>
			);

			// com = (
			// 	<OrderSearchSelect
			// 		disabled={disabled}
			// 		labelAlign="left"
			// 		rules={[
			// 			{
			// 				required: true,
			// 				message: "Vui lòng chọn",
			// 			},
			// 		]}
			// 		label="Chọn đơn hàng"
			// 		name={["source", "id"]}
			// 	/>
			// );
			break;
		case InventoryReceiptSourceType.DOC:
			com = (
				<AttachmentSearch
					form={form}
					formItemProps={{
						label: "Chọn chứng từ",
						name: ["source", "id"],
						rules: [
							{
								required: true,
								message: t("common:form.required"),
							},
						],
						required: true,
					}}
					disabled={disabled}
				/>
				// <AttachmentSearchSelect
				// 	labelAlign="left"
				// 	rules={[
				// 		{
				// 			required: true,
				// 			message: "Vui lòng chọn",
				// 		},
				// 	]}
				// 	label="Chọn chứng từ"
				// 	name={["source", "id"]}
				// />
			);
			break;
		case InventoryReceiptSourceType.SUPPLIER:
			com = (
				<SupplierSearchSelect
					labelAlign="left"
					rules={[
						{
							required: true,
							message: "Vui lòng chọn",
						},
					]}
					label="Chọn nhà cung cấp"
					name={["source", "id"]}
					disabled={disabled}
				/>
			);
			break;
		default:
			com = (
				<Form.Item
					className="mb-0"
					rules={[
						{
							required: isRequire.sourceId,
							message: "Yêu cầu nhập",
						},
					]}
					label="Mã"
					name={["source", "id"]}>
					<Input placeholder="Giá trị" disabled={disabled}></Input>
				</Form.Item>
			);
			break;
	}
	const tagRender = (props: any) => {
		const { label, value, closable, onClose } = props;
		const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
			event.preventDefault();
			event.stopPropagation();
		};
		return (
			<Tag
				color={value}
				onMouseDown={onPreventMouseDown}
				closable={closable}
				onClose={onClose}
				style={{ marginInlineEnd: 4 }}>
				{label} + 13
			</Tag>
		);
	};
	return (
		<div>
			<Row gutter={8}>
				<Col span={12}>
					<Form.Item
						className="mb-0"
						rules={[
							{
								required: true,
								message: "Yêu cầu nhập",
							},
						]}
						label="Loại"
						name={["source", "type"]}>
						<FormSelect
							className="mb-0"
							disabled={disabled}
							onChange={(val) => {
								if (typeof val === "number") {
									form.setFieldValue(["source", "type"], Number(val));
								}
								if (val === InventoryReceiptSourceType.OTHER) {
									setIsRequire((prev) => ({ ...prev, sourceId: false }));
								}
							}}
							options={[
								{ label: "Chọn loại", value: 0, disabled: true },
								...InventoryReceiptModel.getSourceTypeOpntion(),
							]}></FormSelect>
					</Form.Item>
				</Col>
				<Col span={12}>{com}</Col>
			</Row>
			{sourceTypeWatch === InventoryReceiptSourceType.ORDER && (
				<Link target="_blank" to={`/order/edit/id/${sourceValue}`}>
					Chi tiết đơn hàng # {sourceValue}
				</Link>
			)}
		</div>
	);
};

export default InventoryReceiptSource;
