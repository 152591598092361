const ShipperKPI = {
  STATUS_CHECKED: 1,
  STATUS_RECEIVED: 2,
  STATUS_DELIVERIED: 3,
  STATUS_FAILED: 4,
  STATUS_CUSTOMER_FAILED: 5,

  PAYMENT_TESTER: 1,
  PAYMENT_TRANSFER: 2,
  PAYMENT_DELIVERY_RECEIVE: 3,
  PAYMENT_COD_TRANSFER: 4,
  PAYMENT_TRANSFER_COD: 5,

  SHIFT_OFFICE_HOURS: "office_hours",
  SHIFT_EVENING: "evening"
};

export default ShipperKPI;
