import DeviceMineList from "features/devicemine/list/DeviceMineList";
import usePathParams from "hooks/usePathParams";

const DeviceMinePage = (): JSX.Element => {
  const [pathParams] = usePathParams();

  let com = <DeviceMineList />;
  switch (pathParams.action) {
    default:
      com = <DeviceMineList />;
  }

  return com;
};

export default DeviceMinePage;
