import FileCollection from "common/collections/FileCollection";
import FileRepository from "common/repositories/FileRepository";
import { useCallback } from "react";
type Props = {
	ids: number[];
	object_type?: number;
	origin?: string;
};

const useIdImageFetcher = () => {
	const getListIdImageFetcher = useCallback(
		async ({ ids, object_type, origin }: Props): Promise<FileCollection> => {
			const response = await new FileRepository().getFileAndDirectories({
				filters: {
					...FileRepository.getDefaultFilters(),
					object_type: object_type ? object_type : 10,
					origin: origin ? origin : "avatar",
					list_file_ids: ids.join(","),
				},
			});
			return response;
		},
		[]
	);

	return {
		getListIdImageFetcher,
	};
};

export default useIdImageFetcher;
