import { Checkbox, Col, Form, Input, Row } from "antd";
import WarehouseModel from "common/models/WarehouseModel";
import WarehouseRepository from "common/repositories/WarehouseRepository";
import AddressInput from "components/addressinput/AddressInput";
import FormSection from "components/form/FormSection";
import LayoutForm from "components/form/LayoutForm";
import RegionPicker from "components/RegionPicker";
import WarehouseFormHeader from "features/warehouse/form/WarehouseFormHeader";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import {
	DirectionAlow,
	type WarehouseJsonAddEdit,
} from "common/types/Warehouse";
import FormSelect from "components/form/FormSelect";
import Warehouse from "common/constants/Warehouse";
import CommonConstant from "common/constants/CommonConstant";

const WarehouseFormEditor = ({ model }: { model: WarehouseModel }) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const isEditing = model.id > 0;

	const account = useLoginAccountStore((state) => state.account);

	const [errors, setErrors] = useState<string[]>([]);
	const [isSuccess, setIsSuccess] = useState(false);

	const initialValues = {
		name: model.name,
		code: model.code,
		foreign_id: model.foreign_id,
		foreign_id_more: model.foreign_id_more,
		contact_fullname: model.contact_fullname,
		contact_phone: model.contact_phone,
		contact_email: model.contact_email,
		regions: {
			region_id: model.region_id,
			sub_region_id: model.sub_region_id,
			sub_sub_region_id: model.sub_sub_region_id,
		},
		address: {
			address: model.address,
			lat: model.lat,
			long: model.long,
			map_place_id: model.map_place_id,
		},
		direction_in: model.direction_in === 1 ? true : false,
		direction_out: model.direction_out === 1 ? true : false,
		type: model.type ?? Warehouse.TYPE_SALE,
		status: model.status ?? Warehouse.STATUS_ENABLE,
		apply_with_other: model.apply_with_other === 1 ? true : false,
	};

	//prepare data for submit
	const doPrepareData = useCallback(
		(formData: any) => {
			const submitData: WarehouseJsonAddEdit = {
				...WarehouseModel.getDefaultData(),
				id: model.id,
				company_id: account.company.id,
				creator_id: account.id,
				name: formData.name,
				code: formData.code,
				foreign_id: formData.foreign_id,
				contact_fullname: formData.contact_fullname,
				contact_phone: formData.contact_phone,
				contact_email: formData.contact_email,
				region_id: formData.regions.region_id,
				sub_region_id: formData.regions.sub_region_id,
				sub_sub_region_id: formData.regions.sub_sub_region_id,
				address: formData.address.address,
				lat: formData.address.lat,
				long: formData.address.long,
				map_place_id: formData.address.map_place_id,
				direction_in: formData.direction_in ? 1 : 0,
				direction_out: formData.direction_out ? 1 : 0,
				type: formData.type > 0 ? formData.type : null,
				status: formData.status,
				apply_with_other: formData.apply_with_other,
			};

			return submitData;
		},
		[model.id, account]
	);

	//submit data to server
	const onSubmit = async (formData: any) => {
		doPrepareData(formData);

		let myObj: WarehouseModel = await new WarehouseRepository().saveRemote(
			doPrepareData(formData)
		);
		if (myObj.hasError()) {
			setErrors(myObj.error.errors);
		} else {
			setIsSuccess(true);
		}
	};

	return (
		<>
			<WarehouseFormHeader isEditing={isEditing} />
			<LayoutForm
				form={form}
				initialValues={initialValues}
				errors={errors}
				isSuccess={isSuccess}
				successTitle={
					isEditing
						? t("warehouse:form.success.update")
						: t("warehouse:form.success.add")
				}
				redirectOnSuccess="/warehouse"
				error_translate_prefix="warehouse:form.error"
				onSubmit={onSubmit}
				submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
				sidebarItems={null}>
				<FormSection
					title={t("warehouse:form.section_info")}
					subtitle={t("warehouse:form.section_info_subtitle")}
					divider>
					<Row gutter={16}>
						<Col md={18} xs={24}>
							<Form.Item
								label={t("warehouse:name")}
								name="name"
								rules={[
									{
										required: true,
										message: t("warehouse:form.error.error_name_required"),
									},
								]}>
								<Input />
							</Form.Item>
						</Col>
						<Col md={6} xs={24}>
							<Form.Item
								label={t("warehouse:code")}
								name="code"
								rules={[
									{
										required: true,
										message: t("warehouse:form.error.error_code_required"),
									},
								]}>
								<Input />
							</Form.Item>
						</Col>
						<Col md={6} xs={24}>
							<FormSelect
								label={t("common:status")}
								name="status"
								required
								options={WarehouseModel.getStatusList()}
							/>
						</Col>
						<Col md={6} xs={24}>
							<FormSelect
								label={t("warehouse:warehouse_type")}
								name="type"
								required
								options={WarehouseModel.getTypeList()}
							/>
						</Col>
						<Col md={4} xs={24}>
							<Form.Item
								label={t("warehouse:direction_in")}
								name="direction_in"
							>
								<Checkbox
									defaultChecked={model.direction_in === DirectionAlow.accepted}
									onChange={(e) => {
										form.setFieldValue(
											"direction_in",
											e.target.checked
												? DirectionAlow.accepted
												: DirectionAlow.denied
										);
									}}
								/>
							</Form.Item>
						</Col>
						<Col md={4} xs={24}>
							<Form.Item
								label={t("warehouse:direction_out")}
								name="direction_out"
							>
								<Checkbox
									defaultChecked={
										model.direction_out === DirectionAlow.accepted
									}
									onChange={(e) => {
										form.setFieldValue(
											"direction_out",
											e.target.checked
												? DirectionAlow.accepted
												: DirectionAlow.denied
										);
									}}
								/>
							</Form.Item>
						</Col>
						<Col md={4} xs={24}>
							<Form.Item
								label={t("warehouse:apply_with_other")}
								name="apply_with_other"
							>
								<Checkbox
									defaultChecked={
										model.apply_with_other === CommonConstant.CHECKED
									}
									onChange={(e) => {
										form.setFieldValue(
											"apply_with_other",
											e.target.checked
												? CommonConstant.CHECKED
												: CommonConstant.UNCHECKED
										);
									}}
								/>
							</Form.Item>
						</Col>
					</Row>
				</FormSection>

				<FormSection
					title={t("warehouse:form.section_contact")}
					subtitle={t("warehouse:form.section_contact_subtitle")}>
					<Row gutter={16}>
						<Col md={12} xs={24}>
							<Form.Item
								label={t("warehouse:contact_fullname")}
								name="contact_fullname">
								<Input />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={16}>
						<Col md={12} xs={24}>
							<Form.Item
								label={t("warehouse:contact_phone")}
								name="contact_phone">
								<Input />
							</Form.Item>
						</Col>

						<Col md={12} xs={24}>
							<Form.Item
								label={t("warehouse:contact_email")}
								name="contact_email">
								<Input />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={16}>
						<Col md={24} xs={24}>
							<Form.Item label={t("warehouse:address")} name="address">
								<AddressInput />
							</Form.Item>
						</Col>
					</Row>

					<Form.Item name="regions" noStyle>
						<RegionPicker />
					</Form.Item>
				</FormSection>

				<FormSection
					className="hidden"
					title={t("warehouse:form.section_foreign")}
					subtitle={t("warehouse:form.section_foreign_subtitle")}>
					<Row gutter={16}>
						<Col md={12} xs={24}>
							<Form.Item
								label={t("warehouse:foreign_id")}
								name="foreign_id"
								tooltip={t("warehouse:foreign_id_help")}>
								<Input />
							</Form.Item>
						</Col>
					</Row>
				</FormSection>
			</LayoutForm>
		</>
	);
};

export default WarehouseFormEditor;
