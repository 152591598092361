import BaseCollection from "common/collections/BaseCollection";
import ProductWarehouseModel from "common/models/ProductWarehouseModel";
import {
  ProductWarehouseCollectionJson,
  ProductWarehouseJson,
} from "common/types/ProductWarehouse";

class ProductWarehouseCollection extends BaseCollection<
  ProductWarehouseJson,
  ProductWarehouseModel,
  ProductWarehouseCollectionJson
> {
  itemsFromJson(jsonItems: ProductWarehouseJson[]): ProductWarehouseModel[] {
    return jsonItems.map((item) => new ProductWarehouseModel(item));
  }
}

export default ProductWarehouseCollection;
