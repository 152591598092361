import { Skeleton } from "antd";
import ContractAndDeviceModel from "common/models/ContractAndDeviceModel";
import ContractAndDeviceRepository from "common/repositories/ContractAndDeviceRepository";
import ErrorAlert from "components/ErrorAlert";
import FormModal from "components/form/FormModal";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import ContractAddEditForm from "./ContractAddEditForm";

interface IContractAddEditModalProps {
  open: boolean;
  setOpen: (v: boolean) => any;
  onSaveSuccess: (item: ContractAndDeviceModel) => void;
  deviceId?: number;
}

const ContractAddEditModal = ({
  open,
  setOpen,
  onSaveSuccess,
  deviceId,
}: IContractAddEditModalProps): JSX.Element => {
  const [model, setModel] = useState<ContractAndDeviceModel>(
    new ContractAndDeviceModel(ContractAndDeviceModel.getDefaultData())
  );
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [processing, setProcessing] = useState(false);

  const fetchDetail = useCallback(async (id: number) => {
    setLoading(true);
    setErrors([]);
    let myObj = await new ContractAndDeviceRepository().getItem(id);
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setModel(myObj);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (Number(deviceId) > 0) {
      fetchDetail(Number(deviceId));
    }
  }, [deviceId, fetchDetail]);

  return (
    <FormModal
      title={t(
        `contractanddevice:contract_manage.${Number(deviceId) > 0 ? "heading_edit_btn" : "heading_add_btn"}`
      )}
      width={800}
      open={open}
      setOpen={setOpen}
      confirmLoading={processing}
      formId="device-form"
      destroyOnClose
    >
      {Number(deviceId) > 0 ? (
        errors.length > 0 ? (
          <ErrorAlert
            items={errors}
            heading={t("common:error.error_fetching_data")}
            onRetry={() => {
              fetchDetail(Number(deviceId));
            }}
          />
        ) : isLoading ? (
          <Skeleton active />
        ) : (
          <ContractAddEditForm
            model={model}
            setOpen={setOpen}
            setProcessing={setProcessing}
            onSaveSuccess={onSaveSuccess}
          />
        )
      ) : (
        <ContractAddEditForm
          model={
            new ContractAndDeviceModel(ContractAndDeviceModel.getDefaultData())
          }
          setOpen={setOpen}
          setProcessing={setProcessing}
          onSaveSuccess={onSaveSuccess}
        />
      )}
    </FormModal>
  );
};

export default ContractAddEditModal;
