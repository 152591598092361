import { BaseCollectionJson } from "./BaseCollectionJson";
import { Filter } from "./Filter";

export type SystemEventJson = {
	id: number;
	company_id: number;
	resource_id: string;
	resource_type: string;
	action: string;
	event: string;
	details: string;
	date_created: number;
	system_event_time: string;
	status: number;
	date_modifield: number;
	is_deleted: number;
	metadata: any | null;
	date_deleted: number;
};

export type SystemEventCollectionJson = BaseCollectionJson<SystemEventJson>;

export type SystemEventFilter = Filter & {
	resource_id: number | string;
	event: number | string;
	action: number | string;
	resource_type: number | string;
	from_time: number;
	to_time: number;
};

//////////////////////////////////

export type SystemEventAttribute = {
	productId: number;
	attributeName: string;
	attributeValue: string;
};

export type SystemEventInventory = {
	productId: number;
	productCode: string;
	productName: string;
	branchId: number;
	branchName: string;
	cost: number;
	onHand: number;
	reserved: number;
	actualReserved: number;
	minQuantity: number;
	maxQuantity: number;
	isActive: boolean;
};

export type SystemEventDetail = {
	id: number;
	allowsSale: boolean;
	attributes: SystemEventAttribute[];
	barCode: string;
	basePrice: number;
	categoryId: number;
	categoryName: string;
	code: string;
	conversionValue: number;
	createdDate: string;
	dataSource: string;
	description: string;
	fullName: string;
	hasVariants: boolean;
	images: string[];
	inventories: SystemEventInventory[];
	isActive: boolean;
	isBatchExpireControl: boolean;
	isLotSerialControl: boolean;
	lastGetAt: number;
	modifiedDate: string;
	name: string;
	retailerId: number;
	tradeMarkId: number;
	tradeMarkName: string;
	type: number;
	weight: number;
};

export enum SystemEventType {
	ERROR = "error",
	FETCH = "fetch",
	FETCH_SHIP = "fetch_ship",
	UPDATE_ORDER = "update_order",
	SYSDATA = "sysdata"
}

export enum SystemEventSourceType {
	CHECKOUTS = "checkouts",
	LIT_CUSTOMER = "lit_customer",
	LIT_INVOICE = "lit_invoice",
	LIT_INVENTORY = "lit_inventory",
	LIT_INVENTORY_RECEIPT = "lit_inventory_receipt",
	LIT_CASHFLOW_RECEIPT = "lit_cashflow_receipt",
	LIT_COMMENT = "lit_comment",
	LIT_PRODUCT = "lit_product",
	LIT_DELIVERY = "lit_delivery",
	LIT_META_BRAND = "lit_meta_brand",
	LIT_PROMOTION = "lit_promotion",
	LIT_STORY = "lit_story",
	ORDER = "order",
}

export enum SystemEventActionType {
	SET = "set",
	CREATED = "created",
	UPDATE = "update",
	UPDATE_ORDER = "update_order",
	UPDATE_COLLECTION = "update_collection",
	UPDATE_INVENTORY = "update_inventory",
	PROMOTION_EDIT = "promotion.edit",
	COMPLETE_RECEIPT = "complete_receipt",
	ERROR_INVOICE = "error_invoice",
	ERROR_ON_PAGE = "error_on_page",
	ERROR_PROMOTION_INVALID = "error_promotion_invalid",
	ERROR_PROMOTION_COLLECTION_INVALID = "error_promotion_collection_invalid",
	STATUS_COMPLETE = "status_complete"
}

export type SystemEventAddEditJson = {
	id: number;
	status: number;
};
