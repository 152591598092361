import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { BrandJson } from "common/types/Brand";
import { SelectOption } from "common/types/SelectOption";
import BaseModel from "./BaseModel";

const defaultData: BrandJson = {
  company_id: 0,
  id: 0,
  logo_brand: 0,
  images: 0,
  title: "",
  handle: "",
  present: "",
  key_word: "",
  quote: "",
  creator_id: 0,
  status: 0,
  date_created: 0,
  date_modified: 0,
  description: "",
  detail: "",
};

class BrandModel extends BaseModel implements BaseModelJson<BrandJson> {
  id: number = defaultData.id;
  company_id: number = defaultData.company_id;
  logo_brand: number = defaultData.logo_brand;
  images: number = defaultData.images;
  title: string = defaultData.title;
  handle: string = defaultData.handle;
  present: string = defaultData.present;
  key_word: string = defaultData.key_word;
  quote: string = defaultData.quote;
  creator_id: number = defaultData.creator_id;
  status: number = defaultData.status;
  date_created: number = defaultData.date_created;
  date_modified: number = defaultData.date_modified;
  description: string = defaultData.description;
  detail: string = defaultData.detail;

  constructor(json: BrandJson) {
    super();
    Object.assign(this, { ...defaultData, ...json });
  }
  static getDefaultData(): BrandJson {
    return { ...defaultData };
  }
  toJson(): BrandJson {
    return JSON.parse(JSON.stringify({ ...this }));
  }

  static getStatusSelectList(): SelectOption[] {
    return [
      {
        value: 1,
        label: "Hiện",
      },
      {
        value: 2,
        label: "Nháp",
      },
      {
        value: 3,
        label: "Ẩn",
      },
    ];
  }
}

export default BrandModel;
