import CustomerCollection from "common/collections/CustomerCollection";
import Role from "common/constants/Role";
import CustomerModel from "common/models/CustomerModel";
import CustomerRepository from "common/repositories/CustomerRepository";
import { FilterCustomer } from "common/types/Customer";
import { TableColumnsType } from "common/types/Table";
import Helper from "common/utils/helper";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableInfo from "components/table/TableInfo";
import TableInfoRow from "components/table/TableInfoRow";
import TableLinkText from "components/table/TableLinkText";
import TextAddress from "components/TextAddress";
import TextMoney from "components/TextMoney";
import TextRegion from "components/TextRegion";
import CustomerFormLatestPointModal from "features/customer/form/CustomerFormLatestPointModal";
import CustomerListHeader from "features/customer/list/CustomerListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomerListFilter from "./CustomerListFilter";
import { message, notification, Tag, Typography } from "antd";

const CustomerList = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterCustomer = useMemo(
    () => CustomerRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] = useStateFilter<FilterCustomer>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<CustomerModel[]>([]);
  const [messageApi, contextHolder] = message.useMessage();
  //////////////////////////////////////////
  // function
  const fetchData = useCallback(async (): Promise<CustomerCollection> => {
    let collection = await new CustomerRepository().getItems({
      filters,
    });
    setTotal(collection.total);
    setDataSource(collection.items);

    return collection;
  }, [filters]);

  async function handleChangeStatus(checked: boolean, record: CustomerModel) {
    messageApi.open({
      type: "loading",
      content: "Action in progress..",
      duration: 0,
    });
    const res = await new CustomerRepository().edit(record.id, {
      status: checked ? 3 : 1,
    });

    if (res.hasError()) {
      notification.error({ message: "Chuyển trạng thái thất bại" });
      messageApi.destroy();

      return;
    }

    messageApi.destroy();
    notification.success({ message: "Chuyển trạng thái thành công" });
    fetchData();
  }

  //////////////////////////////////////////
  // modal show customer latest point
  const [formLatestPointVisible, setFormLatestPointVisible] =
    useState<boolean>(false);
  const [customerPointId, setCustomerPointId] = useState<number>(0);

  //Table columns
  const columns: TableColumnsType<CustomerModel> = [
    {
      title: t("customer:id"),
      key: "id",
      align: "center",
      width: 100,
      render: (id, record) => {
        return (
          <TableLinkText link={"/customer/edit/id/" + id}>{id}</TableLinkText>
        );
      },
    },
    {
      title: t("customer:full_name"),
      key: "full_name",
      width: 150,
      render: (full_name) => full_name ? <Typography.Text>{full_name}</Typography.Text> : "--",
    },
    {
      title: t("customer:visitor"),
      key: "is_visitor",
      width: 150,
      render: (is_visitor) => {
        const customerType = CustomerModel.getCustomerVisitor(is_visitor);
        return customerType ? (
          <Tag color={customerType.color}>{customerType.label}</Tag>
        ) : "--"
      },
    },
    {
      title: t("customer:gender"),
      width: 120,
      key: "gender",
      sortby: "gender",
      render: (gender: number) => CustomerModel.getGender(gender)?.label ?? "--"
    },
    {
      title: t("customer:age_range"),
      width: 120,
      key: "age_range",
      sortby: "age_range",
      render: (age_range: number) => CustomerModel.getAgeRange(age_range)?.label ?? "--"
    },
    // {
    // 	title: t("customer:code"),
    // 	key: "code",
    // },
    {
      title: t("common:phone"),
      key: "phone",
      width: 120,
    },

    {
      title: t("common:region"),
      width: 200,
      key: "country",
      render: (country: number) => <TextRegion id={country} />,
    },
    {
      title: t("customer:count_order_label"),
      width: 100,
      key: "count_order",
      sortby: "count_order",
      align: "right",
      render: (count_order: number, record: CustomerModel) =>
        count_order > 0 ? (
          <TableLinkText
            link={"/order?customer_id=" + record.id}
            title={t("order:status") + ": " + t("order:STATUS_COMPLETE")}
          >
            {Helper.numberFormat(record.count_order)}{" "}
            {t("customer:count_order_unit")}
          </TableLinkText>
        ) : (
          <>0</>
        ),
    },
    {
      title: t("customer:count_money"),
      width: 120,
      key: "money",
      sortby: "money",
      align: "right",
      render: (money: number, record: CustomerModel) =>
        money > 0 ? (
          <TableLinkText
            link={"/order?customer_id=" + record.id}
            title={t("order:status") + ": " + t("order:STATUS_COMPLETE")}
          >
            <TextMoney money={money} />
          </TableLinkText>
        ) : (
          <>0</>
        ),
    },
    {
      title: t("Công nợ"),
      width: 130,
      key: "debt",
      align: "right",
      sortby: "debt",
      render: (_: any, record: CustomerModel) => (
        // <TableLinkText>
        <TextMoney money={record.debt} />
        // </TableLinkText>
      ),
    },

    // {
    // 	title: t("customer:customer_point"),
    // 	width: 130,
    // 	key: "point",
    // 	align: "right",
    // 	sortby: "point",
    // 	render: (_: any, record: CustomerModel) => (
    // 		<TableLinkText onClick={() => onShowFormLatestPoint(record.id)}>
    // 			<TextMoney money={record.point} noSymbol />
    // 		</TableLinkText>
    // 	),
    // },
    // {
    // 	title: t("customer:customer_type_short"),
    // 	key: "customer_type_id",
    // 	width: 150,
    // 	render: (customer_type_id: any) => (
    // 		<TextCustomerType id={customer_type_id} />
    // 	),
    // },
    {
      title: t("common:status"),
      key: "status",
      width: 150,
      render: (status: number, record) => {
        const info = CustomerModel.getStatus(status)
        return (
          <p>{info?.label}</p>
        )
      },
    },
		{
			title: t("common:date_created"),
			key: "date_created",
			width: 120,
			render: (date_created) => Helper.formatTimestampToText2(date_created),
		},
    {
      title: " ",
      key: "action",
      align: "right",
      fixed: "right",
      width: 100,
      toggletype: "trigger",
      render: (_: any, record: CustomerModel) => {
        const genderInfo = CustomerModel.getGender(record.gender);

        return (
          <RoleCheck roles={[Role.CUSTOMER_MANAGE]} hideOnFail>
            {/* <TableEdit link={"/customer/edit/id/" + record.id} /> */}
            <TableInfo record={record}>
              <TableInfoRow label={t("common:email")} content={record.email} />
              <TableInfoRow
                label={t("common:gender")}
                content={genderInfo?.label}
              />

              <TableInfoRow
                label={t("customer:address")}
                content={
                  <>
                    <TextAddress
                      address={record.address}
                      region_id={record.region_id}
                      sub_region_id={record.sub_region_id}
                      sub_sub_region_id={record.sub_sub_region_id}
                    />
                  </>
                }
              />
              <TableInfoRow
                label={t("customer:birthday")}
                content={record.birthday}
              />
            </TableInfo>
          </RoleCheck>
        );
      },
    },
  ];

  return (
    <>
      <CustomerListHeader />

      <CustomerListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />

      <PageDataTable<FilterCustomer, CustomerModel, CustomerCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "customer",
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
      {contextHolder}
      <CustomerFormLatestPointModal
        customerId={customerPointId}
        key={"customer-form-latest-point-modal-" + customerPointId}
        open={formLatestPointVisible}
        setOpen={(isOpen) => {
          setFormLatestPointVisible(isOpen);
        }}
      />
    </>
  );
};

export default CustomerList;
