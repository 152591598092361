import StoreCollection from "common/collections/StoreCollection";
import StoreModel from "common/models/StoreModel";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import type { FullData } from "common/types/FullData";
import type { GetListProps } from "common/types/Filter";
import type {
  FilterStore,
  StoreJson,
  StoreCollectionJson,
} from "common/types/Store";

const SERVICE_URL = "/stores";

class StoreApi extends BaseApi {
  async getList(props: GetListProps<FilterStore>): Promise<StoreCollection> {
    let collection = new StoreCollection();

    try {
      let filters = props.filters;

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          keyword: filters.keyword ? filters.keyword : null,
          status: +filters.status >= 0 ? filters.status : null,
          region_id: +filters.region_id > 0 ? filters.region_id : null,
          ids: filters.ids ? filters.ids : null,
        },
      };

      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async getDetail(id: number): Promise<StoreModel> {
    let item = new StoreModel(StoreModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
      if (response.hasOwnProperty("data")) {
        item = new StoreModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
      if (response.status !== 204) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async add(data: StoreJson): Promise<StoreModel> {
    let item = new StoreModel(StoreModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new StoreModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async edit(data: StoreJson): Promise<StoreModel> {
    let item = new StoreModel(StoreModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + data.id,
        postData,
      );
      if (response.hasOwnProperty("data")) {
        item = new StoreModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  ///////////////////////////////////////////////////////////////
  // FULL DATA fetcher
  async getFullData({
    companyId,
    version,
  }: FullData): Promise<StoreCollection> {
    return this.getFullDataFetcher<
      StoreJson,
      StoreModel,
      StoreCollectionJson,
      StoreCollection
    >({
      collection: new StoreCollection(),
      companyId,
      version,
      url: SERVICE_URL + "/fulldata",
    });
  }
}

export default StoreApi;
