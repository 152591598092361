import NewsletterCollection from "common/collections/NewsletterCollection";
import NewsletterModel from "common/models/NewsletterModel";
import type { GetListProps } from "common/types/Filter";
import { FilterNewsletter, NewsletterAddEditJson } from "common/types/Newsletter";
import Helper from "common/utils/helper";
import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

const SERVICE_URL = "/customers/newsletter";

class NewsletterApi extends BaseApi {
  async getList(
    props: GetListProps<FilterNewsletter>
  ): Promise<NewsletterCollection> {
    let collection = new NewsletterCollection();
    const convertProps = Helper.convertParams(props.filters);

    const queryData = {
      params: {
        ...convertProps,
        sort_by: props.filters.sortby,
        sort_type: props.filters.sorttype,
      },
    };
    try {
      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }

  async edit(data: NewsletterAddEditJson): Promise<NewsletterModel> {
    let item = new NewsletterModel(NewsletterModel.getDefaultData());
    try {
      let postData = {
        ...data,
      };
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + data.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new NewsletterModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default NewsletterApi;
