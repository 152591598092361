import { useTranslation } from "react-i18next";
import { Image, Skeleton, Space, Tag } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { IconBell, IconClockEdit, IconPin } from "@tabler/icons-react";

import {
	FilterNotification,
	NotificationJson,
} from "common/types/Notification";
import Helper from "common/utils/helper";
import useStateFilter from "hooks/useStateFilter";
import ImageDefault from "common/constants/Image";
import { EmployeeJson } from "common/types/Employee";
import NotificationModel from "common/models/NotificationModel";
import NotificationRepository from "common/repositories/NotificationRepository";

import useDatabaseTable from "hooks/useDatabaseTable";
import HomeLeftListWrapper from "../HomeLeftListWrapper";
import NotificationDetailModal from "features/notification/components/NotificationDetailModal";

type Data = NotificationJson & {
	loading?: boolean;
};

type Props = {
	handleError?: () => void;
};
const HomeLeftNotification = () => {
	const { t } = useTranslation();
	const [employeeItems] = useDatabaseTable<EmployeeJson>("employee");

	const [dataSource, setDataSource] = useState<Data[]>(
		Array.from({ length: 10 }).map(() => ({
			...NotificationModel.getDefaultData(),
			loading: true,
		}))
	);
	const defaultFilters = useMemo(() => {
		return {
			...NotificationRepository.getDefaultFilters(),
		};
	}, []);
	const [filters, setFilters] = useStateFilter(defaultFilters);

	const [loading, setLoading] = useState(true);
	const [errors, setErrors] = useState<string[]>([]);
	const [id, setId] = useState<number>(0);
	const [open, setOpen] = useState<boolean>(false);
	const [list, setList] = useState<NotificationModel[]>([]);

	//variable process
	const employeeFilteredOptions =
		(employeeItems &&
			employeeItems
				.filter((o) => o.id > 0)
				.map((e) => {
					return {
						value: e.id,
						label: e.full_name,
					};
				})) ||
		[];
	//////////////////////////////
	//data
	const IconText = ({
		icon,
		text,
	}: {
		icon?: React.FC;
		text: React.ReactNode;
	}) => (
		<span>
			{/* {icon && React.createElement(icon)} */}
			{text}
		</span>
	);

	///////////////////////////////
	//fetch data
	const fetchData = async () => {
		const collection = await new NotificationRepository().getItems({
			filters: { ...filters, status: 1 },
		});
		if (collection.hasError()) {
			setErrors(collection.error.errors);
		}
		setDataSource(collection.items.map((i) => i.toJson()));
		setList(collection.items);

		setLoading(false);
	};

	useEffect(() => {
		fetchData();
	}, []);
	return (
		<div id="notification">
			<HomeLeftListWrapper<FilterNotification, Data>
				{...{
					filters,
					setFilters,
					dataSource,
					errors,
					iconTitle: <IconBell size={18} />,
					loading: loading,
					title: t("common:home.notification"),

					renderItem(item) {
						return (
							<Skeleton
								className="px-2"
								loading={item.loading}
								active
								title={false}
								round>
								<div
									className="flex gap-2 my-2 border-[1px] border-gray-200 h-[90px] w-full overflow-hidden cursor-pointer"
									key={item.id}
									onClick={() => {
										setId(item.id);
										setOpen(true);
									}}>
									{item.pin === 1 && (
										<IconPin
											color="#1d6ddd"
											size={16}
											className="absolute right-2 top-2"
										/>
									)}
									<div className="w-[88px] h-[88px]">
										<Image
											className="object-cover"
											width={"88px"}
											height={"88px"}
											preview={false}
											// fallback={ImageDefault.DEFAULT_IMAGE}
											fallback={"/imgs/nteam/default_logo.jpg"}
											src={item.meta_image.url}
										/>
									</div>
									<div className="p-2 relative flex flex-col w-full justify-between gap-1 flex-1">
										<p className="line-clamp-1 font-semibold m-0 h-[20px] mr-[24px]">
											{item.meta_title}
										</p>
										<p className="line-clamp-1 text-gray-400 m-0 h-[20px]">
											{item.meta_summary}
										</p>
										<div>
											<IconText
												text={
													item.type === 1 ? (
														<Tag color={"blue"}>
															{NotificationModel.getType(item.type)?.label}
														</Tag>
													) : (
														<Tag color={"purple"}>
															{NotificationModel.getType(item.type)?.label}
														</Tag>
													)
												}
												key="list-vertical-star-2"
											/>
											{/* <IconText
                          icon={IconClockEdit}
                          text={
                            <Tag color="blue">
                              {Helper.convertedTimestampToDate(
                                item.date_created
                              )}
                            </Tag>
                          ) : (
                            <Tag color={"purple"}>
                              {NotificationModel.getType(item.type)?.label}
                            </Tag>
                          )
                        }
                        key="list-vertical-star-2"
                      /> */}
											<IconText
												icon={IconClockEdit}
												text={
													<Tag color="blue">
														{Helper.convertedTimestampToDate(item.date_created)}
													</Tag>
												}
												key="list-vertical-star-1"
											/>
											{/* <IconText
                        text={
                          <Tag color="blue">
                            {
                              employeeFilteredOptions?.find(
                                (emp: any) => emp.value === item.creator_id
                              )?.label
                            }
                          </Tag>
                        }
                        key="list-vertical-star-3"
                      /> */}
										</div>
									</div>
								</div>
							</Skeleton>
						);
					},
				}}
			/>
			<NotificationDetailModal
				id={id}
				setId={setId}
				open={open}
				setOpen={setOpen}
				list={list}
			/>
		</div>
	);
};

export default HomeLeftNotification;
