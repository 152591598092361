import create, { StateCreator } from "zustand";
import { devtools, persist } from "zustand/middleware";

type OrderRefreshTrigger =
	| "delivery"
	| "cashflowreceipt"
	| "productreceipt"
	| "orderdetail"
	| "";
type OrderStore = {
	refreshTrigger: OrderRefreshTrigger;
	setRefreshTrigger: (refresh: OrderRefreshTrigger) => void;
};

//init store data
let store: StateCreator<OrderStore> = (set, get) => ({
	refreshTrigger: "",
	setRefreshTrigger: (refresh) =>
		set((state) => ({ ...state, refreshTrigger: refresh })),
});

//create store
const useOrderStore = create(
	persist(store, {
		name: "MAIN::ORDER",
		partialize: (state) => ({ refreshTrigger: state.refreshTrigger }),
	})
);

export default useOrderStore;
