import NewsletterModel from "common/models/NewsletterModel";
import { NewsletterCollectionJson, NewsletterJson } from "common/types/Newsletter";
import BaseCollection from "./BaseCollection";

class NewsletterCollection extends BaseCollection<
  NewsletterJson,
  NewsletterModel,
  NewsletterCollectionJson
> {
  itemsFromJson(jsonItems: NewsletterJson[]): NewsletterModel[] {
    return jsonItems.map((item) => new NewsletterModel(item));
  }
}
export default NewsletterCollection;
