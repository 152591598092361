import Newsletter from "common/constants/Newsletter";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { NewsletterJson } from "common/types/Newsletter";
import { SelectOption } from "common/types/SelectOption";
import { t } from "i18next";
import BaseModel from "./BaseModel";

const defaultData: NewsletterJson = {
  id: 0,
  email: "",
  status: 0,
  is_deleted: 0,
  date_deleted: 0,
  date_created: 0,
  date_modified: 0,
};

class NewsletterModel
  extends BaseModel
  implements BaseModelJson<NewsletterJson>
{
  id: number = defaultData.id;
  email: string = defaultData.email;
  status: number = defaultData.status;
  is_deleted: number = defaultData.is_deleted;
  date_deleted: number = defaultData.date_deleted;
  date_created: number = defaultData.date_created;
  date_modified: number = defaultData.date_modified;

  constructor(json: NewsletterJson) {
    super();
    Object.assign(this, { ...defaultData, ...json });
  }
  static getDefaultData(): NewsletterJson {
    return { ...defaultData };
  }
  toJson(): NewsletterJson {
    return JSON.parse(JSON.stringify({ ...this }));
  }

  static getStatusList(): SelectOption[] {
		return [
			{
				value: Newsletter.STATUS_ENABLE,
				label: t("newsletter:status.enable"),
				color: "green",
			},
			{
				value: Newsletter.STATUS_DRAFT,
				label: t("newsletter:status.draft"),
				color: "blue",
			},
		];
	}

  static getStatus(val: number) {
		return this.getStatusList().find((item) => item.value === val);
	}
}

export default NewsletterModel;
