import {
	Button,
	Checkbox,
	Dropdown,
	Flex,
	MenuProps,
	Modal,
	Tag,
	Tooltip,
	Typography,
} from "antd";
import OrderCollection from "common/collections/OrderCollection";
import Order from "common/constants/Order";
import CashflowReceiptModel from "common/models/CashflowReceiptModel";
import OrderModel from "common/models/OrderModel";
import ProductReceiptModel from "common/models/ProductReceiptModel";
import OrderRepository from "common/repositories/OrderRepository";
import { FilterOrder, OrderJson } from "common/types/Order";
import { TableColumnsType } from "common/types/Table";
import { TaxInvoiceJson } from "common/types/TaxInvoice";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import TextDateTime from "components/TextDateTime";
import TextMoney from "components/TextMoney";
import TextRegion from "components/TextRegion";
import TextStore from "components/TextStore";
import TextTagList from "components/TextTagList";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
	EyeOutlined,
	MoreOutlined,
	SmallDashOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import RoleCheck from "components/RoleCheck";
import Role from "common/constants/Role";
import Delivery from "common/constants/Delivery";
import TextDateTimeRelative from "components/TextDateTimeRelative";
import useCheckPermisstion from "hooks/useCheckPermisstion";
import AxiosClient from "common/api/AxiosClient";
import { Ecomplatform } from "common/types/Ecom";
import OrderAccountantListHeader from "./OrderAccountantListHeader";
import Helper from "common/utils/helper";
import OrderAccountantListFilter from "./OrderAccountListFilter";
import TextEmployee from "components/TextEmployee";
import IdFetcherRender from "components/idfetcher/IdFetcherRender";
import { IdFetcherResult } from "common/types/IdFetcher";
import useIdFetcher from "hooks/useIdFetcher";
import { YesNo } from "common/types/Enum";
import { CartPromotion, IsUse } from "common/types/Cart";
import Promotion from "common/constants/Promotion";
import PromotionModel from "common/models/PromotionModel";
import MoneyInput from "components/MoneyInput";
import OrderFullPromotionView from "./OrderFullPromotionView";

type Props = {
	type?: number;
};
const OrderAccountantList = ({ type = Order.TYPE_INVOICE }: Props) => {
	const { t } = useTranslation();
	const { checkRole, hasRole } = useCheckPermisstion({
		roles: [Role.ORDER_EDIT],
	});
	const { getListIdFetcher } = useIdFetcher();

	//////////////////////////////////////////
	const linkRoute = type === Order.TYPE_INVOICE_ORDER ? "/order" : "/invoice";

	//////////////////////////////////////////

	const [total, setTotal] = useState(0);
	const [orderView, setOrderView] = useState(OrderModel.getDefaultData());
	const [openOrderView, setOpenOrderView] = useState(false);
	const [channels, setChannels] = useState<Ecomplatform[]>([]);
	const [employees, setEmployees] = useState<IdFetcherResult[]>([]);
	const [orderDetails, setOrderDetails] = useState<OrderJson[]>([]);

	//////////////////////////////////////////
	//Filtering
	const defaultFilters: FilterOrder = useMemo(() => {
		return {
			...OrderRepository.getDefaultFilters(),
			type: type,
			is_promotion: YesNo.Yes,
		};
	}, []);
	const [filters, setFilters] = useStateFilter<FilterOrder>(defaultFilters);
	useFilterLocation(defaultFilters, filters);

	//////////////////////////////////////////
	//Fetch data from this collections
	const [dataSource, setDataSource] = useState<OrderModel[]>([]);

	const fetchData = useCallback(async (): Promise<OrderCollection> => {
		let collection = await new OrderRepository().getItems({
			filters,
		});

		if (!collection.hasError()) {
			setDataSource(collection.items);
			setTotal(collection.total);
			getFullListOrderDetail(collection.items.map((i) => +i.id));
			const employeeIds = [
				...new Set(
					collection.items
						.filter((i) => i.seller_id > 0)
						.map((i) => i.seller_id || 0)
				),
			];
			await getListEmployee(employeeIds);
		}

		return collection;
	}, [filters]);

	const getFullListOrderDetail = useCallback(async (ids: number[]) => {
		const promises = ids.map((id) => new OrderRepository().getItem(id));
		const results = await Promise.allSettled(promises);
		const orders = results
			.filter((result) => result.status === "fulfilled")
			.map((result) => (result as PromiseFulfilledResult<OrderModel>).value);

		setOrderDetails(orders.map((i) => i.toJson()));
	}, []);

	const getListEmployee = useCallback(
		async (id_list: number[]) => {
			const response = await getListIdFetcher({
				object_type: "employee",
				ids: id_list,
			});

			if (!response.hasError()) {
				const result =
					response.items.filter((i) => i.object_type === "employee")?.[0]
						?.result ?? ([] as IdFetcherResult[]);
				setEmployees(result);
			}
		},
		[getListIdFetcher]
	);

	function handlerOpenView(item: OrderJson) {
		setOrderView(item);
		setOpenOrderView(true);
	}

	const getSaleChanel = useCallback(async () => {
		try {
			const { data: channels } = await AxiosClient().get("/ecomplatforms");
			const items = channels?.items as Ecomplatform[];
			if (items) {
				setChannels(items);
			}
		} catch (error) {}
	}, []);

	useEffect(() => {
		getSaleChanel();
	}, [getSaleChanel]);

	//Table columns
	////////////////////////////////////////////////////////
	const columns: TableColumnsType<OrderModel> = [
		{
			title: t("common:stt"),
			key: "",
			align: "center",
			width: 30,
			render: (value, item, index) => {
				return <span>{index + 1}</span>;
			},
		},
		{
			title: t("order:id"),
			key: "code",
			width: 160,
			align: "center",
			toggletype: "ignore",
			render: (code, record) => {
				const hasRoleEdit = checkRole([
					Role.CROSS_ORDER_EDIT,
					Role.CROSS_ORDER_VIEW,
					// Role.ORDER_EDIT,
				]);
				// if (!hasRoleEdit) {
				// 	return (
				// 		<Typography.Text className="text-blue-600" copyable>
				// 			{code}
				// 		</Typography.Text>
				// 	);
				// }
				return (
					<RoleCheck roles={[]}>
						<TableLinkText
							title={t("order:detail_button")}
							link={linkRoute + "/edit/id/" + record.id}
							className="text-base font-bold">
							<Typography.Text className="text-blue-600" copyable>
								{code}
							</Typography.Text>
						</TableLinkText>
					</RoleCheck>
				);
			},
		},

		{
			title: t("order:date_created"),
			key: "date_created",
			width: 140,
			render: (ts: number) => {
				return (
					<>
						<span>{Helper.formatTimestampToText2(ts)}</span>
						{/* <TextDateTime ts={ts} format="HH:mm, DD/MM/YYYY"></TextDateTime> */}
					</>
				);
			},
		},
		{
			title: t("order:accountant.cart_promotions"),
			key: "promotions",
			width: 150,
			render: (promotions: CartPromotion[], record) => {
				const orderDetail =
					orderDetails.find((i) => i.id === record.id) ?? record.toJson();

				return (
					<OrderFullPromotionView order={orderDetail}></OrderFullPromotionView>
				);
			},
		},
		{
			title: t("order:accountant.item_total"),
			key: "item_total",
			align: "right",
			width: 120,
			render: (item_total: number) => {
				return (
					<span className="font-bold">
						<TextMoney money={item_total} />
					</span>
				);
			},
		},
		{
			title: t("order:accountant.price_discount"),
			key: "price_discount",
			align: "right",
			width: 120,
			render: (price_discount: number) => {
				return (
					<span className="font-bold">
						<TextMoney money={price_discount} />
					</span>
				);
			},
		},

		{
			title: t("order:accountant.price_final"),
			key: "price_final",
			align: "right",
			width: 200,
			render: (price_final: number, record) => {
				const orderDetail =
					orderDetails.find((i) => i.id === record.id) ?? record.toJson();
				return (
					<Flex vertical gap={2}>
						<div>
							<div>
								<span className="text-gray-400">
									{t("taxinvoice:price_sell_short")}: &nbsp;
								</span>
								<TextMoney money={record.price_sell} />
							</div>
							<Tooltip
								title="Bao gồm thuế VAT trên đơn hàng và trên từng sản phẩm"
								className="cursor-pointer">
								<span className="text-gray-400">
									+ {t("taxinvoice:price_tax")}: &nbsp;
								</span>
								<TextMoney
									money={
										(record.price_tax / 100) * record.price_sell +
										orderDetail.total_price_vat_item
									}
								/>
							</Tooltip>
							<div>
								{t("taxinvoice:price_final_short")}: &nbsp;
								<span className="text-lg font-bold">
									<TextMoney money={record.price_final} />
								</span>
							</div>
						</div>
					</Flex>
				);
			},
		},
		{
			title: t("order:accountant.seller_id"),
			key: "seller_id",
			align: "right",
			width: 150,
			render: (seller_id: number) => {
				return (
					<IdFetcherRender
						value={seller_id}
						mapping={employees}
						render={(r: IdFetcherResult) => (
							<Typography.Text>
								{r.text} - {r.internal_id}
							</Typography.Text>
						)}
					/>
				);
			},
		},
		{
			title: t("Thanh toán"),
			key: "payment_status",
			render: (payment_status: number, record) => {
				let statusInfo = OrderModel.getStatusPayment(payment_status);
				return (
					<>
						<Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>
					</>
				);
			},
			width: 150,
		},

		{
			title: t("common:status"),
			key: "status",

			render: (status: number, record) => {
				let statusInfo = OrderModel.getStatus(status, record.type);
				return (
					<>
						<Tag bordered color={statusInfo?.color}>
							{statusInfo?.label}
						</Tag>
						{status === Order.STATUS_CANCEL && record.cancel_reason > 0 ? (
							<div
								className="px-2 text-xs text-red-400"
								style={{ fontSize: 9 }}>
								{OrderModel.getCancelReason(record.cancel_reason)?.label}
							</div>
						) : null}
					</>
				);
			},
			width: 120,
		},

		{
			title: "Cho phép nợ",
			key: "customer_credit_balance",
			width: 100,
			align: "center",
			render: (customer_credit_balance: number) =>
				customer_credit_balance ? (
					<Checkbox checked disabled />
				) : (
					<Checkbox disabled />
				),
		},

		{
			title: " ",
			toggletype: "trigger",
			key: "actions",
			align: "right",
			fixed: "right",
			width: 120,
			render: (id: number, record) => {
				const delis = record.delivery_lits_id;
				const items: MenuProps["items"] = [
					{
						key: "1",
						label: (
							<RoleCheck roles={[Role.DELIVERY_MANAGER]}>
								<Link
									to={`/delivery/manager?soandcode=${encodeURIComponent(
										record.code
									)}`}>
									<Button type="text" title="Xem vận đơn">
										Xem vận đơn
									</Button>
								</Link>
							</RoleCheck>
						),
					},
					{
						key: "2",
						label: (
							<RoleCheck roles={[Role.DELIVERY_MANAGER]}>
								<Link to={`/delivery/add/id/${record.id}`}>
									<Button type="text" title="Tạo vận đơn">
										Tạo vận đơn
									</Button>
								</Link>
							</RoleCheck>
						),
					},
				];
				return (
					<>
						{/* <Dropdown trigger={["click"]} menu={{ items }}>
							<MoreOutlined
								style={{ fontSize: "16pt" }}
								className="cursor-pointer "
							/>
						</Dropdown>
						<TableInfo record={record} className="mr-4" /> */}
					</>
				);
			},
		},
	];

	// useEffect(() => {
	// 	const intervalId = setInterval(fetchData, 60000);

	// 	return () => clearInterval(intervalId);
	// }, [fetchData]);

	return (
		<>
			<OrderAccountantListHeader type={type} />

			<OrderAccountantListFilter<FilterOrder>
				type={type}
				filters={{
					...filters,
					type: !isNaN(Number(filters.type)) ? Number(filters.type) : -1,
				}}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
				total={total}
			/>

			<PageDataTable<FilterOrder, OrderModel, OrderCollection>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					tableColumnToggleKey:
						type === Order.TYPE_INVOICE_ORDER ? "order" : "invoice",
				}}
			/>

			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>
		</>
	);
};

export default OrderAccountantList;
