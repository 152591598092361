const CrmFeed = {
  CATEGORY_NOTE: 1,
  CATEGORY_ORDER: 3,
  CATEGORY_EMAIL: 5,
  CATEGORY_SMS: 7,
  CATEGORY_VOICE: 9,
  CATEGORY_TICKET: 11,
  CATEGORY_EVENT: 13,
  CATEGORY_TASK: 15,
  CATEGORY_CHANGE_INFO: 17,
  CATEGORY_CALLCENTER: 19,
  CATEGORY_UPGRADE: 21,

  STATUS_ENABLE: 1,
  STATUS_DISABLED: 3,


  INFINITY: 10000000000
};

export default CrmFeed;
