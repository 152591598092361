import { SelectProps } from "antd";
import { Rule } from "antd/es/form";
import { FormLabelAlign } from "antd/es/form/interface";
import { SelectValue } from "antd/lib/select";
import EmployeeRepository from "common/repositories/EmployeeRepository";
import { SelectOption } from "common/types/SelectOption";
import FormSelect from "components/form/FormSelect";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

const UserFormSelect = ({
  label,
  name,
  disabled,
  value,
  onChange,
  labelAlign,
  required,
  allowClear,
  placeholder,
  popupMatchSelectWidth,
  size,
  status,
  className,
  rules,
}: {
  label?: string;
  name: string;
  disabled?: boolean;
  value?: SelectValue;
  onChange?: (v: SelectValue) => void;
  labelAlign?: FormLabelAlign;
  required?: boolean;
  allowClear?: boolean;
  placeholder?: string;
  popupMatchSelectWidth?: boolean;
  size?: SelectProps["size"];
  status?: SelectProps["status"];
  className?: string;
  rules?: Rule[];
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchData = useCallback(async (searchValue?: string) => {
    setLoading(true);

    const collection = await new EmployeeRepository().getItems({
      filters: {
        ...EmployeeRepository.getDefaultFilters(),
        limit: 50,
        keyword: searchValue!,
      },
    });

    if (collection.hasError()) {
      setErrors(collection.error.errors);
    } else {
      setOptions(
        collection.items.map((item) => ({
          value: item.id,
          label: item.full_name,
        }))
      );
    }

    setLoading(false);
  }, []);

  const debounceFetcher = useMemo(() => debounce(fetchData, 500), [fetchData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <FormSelect
      rules={rules}
      className={className}
      size={size}
      disabled={disabled}
      required={required}
      loading={loading}
      showSearch={options.length > 6}
      label={label}
      name={name}
      options={options}
      errors={errors}
      errorRetry={fetchData}
      value={value}
      onChange={onChange}
      onSearch={debounceFetcher}
      labelAlign={labelAlign}
      allowClear={allowClear}
      placeholder={placeholder}
      popupMatchSelectWidth={popupMatchSelectWidth}
      status={status}
    />
  );
};

export default UserFormSelect;
