import CashflowReceiptApi from "common/api/CashflowReceiptApi";
import CashflowReceiptCollection from "common/collections/CashflowReceiptCollection";
import CashflowReceiptModel from "common/models/CashflowReceiptModel";
import { GetListProps } from "common/types/Filter";

import BaseRepository from "./BaseRepository";

import type {
	CashflowReceiptJsonAddEdit,
	FilterCashflowReceipt,
} from "common/types/CashflowReceipt";
class CashflowReceiptRepository extends BaseRepository<CashflowReceiptApi> {
	_api: CashflowReceiptApi | null;

	constructor() {
		super();
		this._api = new CashflowReceiptApi(true);
	}

	static getDefaultFilters(): FilterCashflowReceipt {
		return {
			page: 1,
			limit: 20,
			sortby: "id",
			sorttype: "DESC",
			status: -1,
			keyword: "",
			id: -1,
			cashflow_group: -1,
			direction: -1,
			store_id: -1,
			source: -1,
			target: -1,
			method: -1,
			source_id: -1,
			date_started: -1,
			date_ended: -1,
			is_complete: -1,
		};
	}

	async getItems(props: GetListProps<FilterCashflowReceipt>) {
		return this._api
			? await this._api.getList(props)
			: new CashflowReceiptCollection();
	}

	async getItem(id: number): Promise<CashflowReceiptModel> {
		return this._api
			? await this._api.getDetail(id)
			: new CashflowReceiptModel(CashflowReceiptModel.getDefaultData());
	}

	async deleteItem(id: number): Promise<string[]> {
		return this._api ? await this._api.delete(id) : [];
	}

	async saveRemote(
		data: CashflowReceiptJsonAddEdit
	): Promise<CashflowReceiptModel> {
		if (this._api) {
			if (data?.id! > 0) {
				return await this._api.edit(data);
			} else {
				return await this._api.add(data);
			}
		} else {
			return new CashflowReceiptModel(CashflowReceiptModel.getDefaultData());
		}
	}
}

export default CashflowReceiptRepository;
