import ContractAndDevice from "common/constants/ContractAndDevice";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { ContractAndDeviceJson } from "common/types/Device";
import { SelectOption } from "common/types/SelectOption";
import { t } from "i18next";
import BaseModel from "./BaseModel";

const defaultData: ContractAndDeviceJson = {
  company_id: 0,
  department_id: 0,
  office_id: 0,
  employee_id: 0,
  id: 0,
  code: "",
  name: "",
  type: 0,
  parent_id: 0,
  from_time: 0,
  to_time: 0,
  status: 0,
  source_status: 0,
  quality: 0,
  warranty: 0,
  creator_id: 0,
  file_id: "",
  description: "",
  note: "",
  date_created: 0,
  date_modified: 0,
};

class ContractAndDeviceModel
  extends BaseModel
  implements BaseModelJson<ContractAndDeviceJson>
{
  company_id: number = defaultData.company_id;
  department_id: number = defaultData.department_id;
  office_id: number = defaultData.office_id;
  employee_id: number = defaultData.employee_id;
  id: number = defaultData.id;
  code: string = defaultData.code;
  name: string = defaultData.name;
  type: number = defaultData.type;
  parent_id: number = defaultData.parent_id;
  from_time: number = defaultData.from_time;
  to_time: number = defaultData.to_time;
  status: number = defaultData.status;
  source_status: number = defaultData.source_status;
  quality: number = defaultData.quality;
  warranty: number = defaultData.warranty;
  creator_id: number = defaultData.creator_id;
  file_id: string = defaultData.file_id;
  description: string = defaultData.description;
  note: string = defaultData.note;
  date_created: number = defaultData.date_created;
  date_modified: number = defaultData.date_modified;

  constructor(json: ContractAndDeviceJson) {
    super();
    Object.assign(this, { ...defaultData, ...json });
  }
  static getDefaultData(): ContractAndDeviceJson {
    return { ...defaultData };
  }
  toJson(): ContractAndDeviceJson {
    return JSON.parse(JSON.stringify({ ...this }));
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: ContractAndDevice.STATUS_DRAFT,
        label: t("contractanddevice:status.draft"),
        color: "gray",
      },
      {
        value: ContractAndDevice.STATUS_HOLDING,
        label: t("contractanddevice:status.holding"),
        color: "green",
      },
      {
        value: ContractAndDevice.STATUS_ACTIVE,
        label: t("contractanddevice:status.active"),
        color: "blue",
      },
      {
        value: ContractAndDevice.STATUS_CANCELLED,
        label: t("contractanddevice:status.cancelled"),
        color: "yellow",
      },
      {
        value: ContractAndDevice.STATUS_LOST,
        label: t("contractanddevice:status.lost"),
        color: "red",
      },
    ];
  }

  static getStatus(val: number) {
    return this.getStatusList().find((item) => item.value === val);
  }

  static getDeviceSourceStatusList(): SelectOption[] {
    return [
      {
        value: ContractAndDevice.DEVICE.SOURCE_STATUS_NEW,
        label: t("contractanddevice:device_source_status.new"),
        color: "green",
      },
      {
        value: ContractAndDevice.DEVICE.SOURCE_STATUS_USED,
        label: t("contractanddevice:device_source_status.used"),
        color: "blue",
      },
      {
        value: ContractAndDevice.DEVICE.SOURCE_STATUS_REPAIR,
        label: t("contractanddevice:device_source_status.repair"),
        color: "yellow",
      },
      {
        value: ContractAndDevice.DEVICE.SOURCE_STATUS_OLD,
        label: t("contractanddevice:device_source_status.old"),
        color: "gray",
      },
      {
        value: ContractAndDevice.DEVICE.SOURCE_STATUS_BROKEN,
        label: t("contractanddevice:device_source_status.broken"),
        color: "red",
      },
    ];
  }

  static getDeviceSourceStatus(val: number) {
    return this.getDeviceSourceStatusList().find((item) => item.value === val);
  }

  static getContractSourceStatusList(): SelectOption[] {
    return [
      {
        value: ContractAndDevice.CONTRACT.SOURCE_STATUS_DRAFT,
        label: t("contractanddevice:contract_source_status.draft"),
        color: "gray",
      },
      {
        value: ContractAndDevice.CONTRACT.SOURCE_STATUS_PENDING,
        label: t("contractanddevice:contract_source_status.pending"),
        color: "yellow",
      },
      {
        value: ContractAndDevice.CONTRACT.SOURCE_STATUS_SIGNED,
        label: t("contractanddevice:contract_source_status.signed"),
        color: "green",
      },
      {
        value: ContractAndDevice.CONTRACT.SOURCE_STATUS_COMPLETED,
        label: t("contractanddevice:contract_source_status.completed"),
        color: "blue",
      },
      {
        value: ContractAndDevice.CONTRACT.SOURCE_STATUS_CANCELLED,
        label: t("contractanddevice:contract_source_status.cancelled"),
        color: "red",
      },
    ];
  }

  static getContractSourceStatus(val: number) {
    return this.getContractSourceStatusList().find((item) => item.value === val);
  }
}

export default ContractAndDeviceModel;
