import BaseApi from "./BaseApi";
import AxiosClient from "./AxiosClient";
import Helper from "common/utils/helper";
import { GetListProps } from "common/types/Filter";
import PhoneBookModel from "common/models/PhoneBookModel";
import PhoneBookCollection from "common/collections/PhoneBookCollection";
import { FilterPhoneBook } from "common/types/PhoneBook";

const SERVICE_URL = "/employees";
class PhoneBookApi extends BaseApi {
	//
	async getList(
		props: GetListProps<FilterPhoneBook>
	): Promise<PhoneBookCollection> {
		let collection = new PhoneBookCollection();
		const convertProps = Helper.convertParams(props.filters, true);
		const queryData = {
			params: {
				...convertProps,
				ids: props.filters.ids && props.filters.ids?.length > 0 ? props.filters.ids.join(",") : ""
			},
		};

		try {
			const response = await AxiosClient().get<any>(
				SERVICE_URL + "/getdataemployeemennu",
				queryData
			);
			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}
		return collection;
	}
	//
	async getDetail(id: number): Promise<PhoneBookModel> {
		let item = new PhoneBookModel(PhoneBookModel.getDefaultData());

		try {
			const response = await AxiosClient().get<any>(
				SERVICE_URL + "/getdetailmennu/" + id
			);
			if (response.hasOwnProperty("data")) {
				item = new PhoneBookModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}
		return item;
	}
}

export default PhoneBookApi;
