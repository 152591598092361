const TagType = {
	TAG_TYPE: {
		PRODUCT: 1,
		STORIES: 2,
		PRODUCT_COLLECTION: 3,
		STORIES_COLLECTION: 4,
		BRAND: 5,
		WAREHOUSE: 6,
	},
};

export default TagType;
