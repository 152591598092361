import { Form, Input } from "antd";
import SystemEventModel from "common/models/SystemEventModel";
import { Filter } from "common/types/Filter";
import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import { useTranslation } from "react-i18next";
import DatePickerRangeFilter from "components/datetime/DatePickerRangeFilter";

const TemplateListFilter = <F extends Filter>({
	total,
	filters,
	setFilters,
	defaultFilters,
}: {
	total: number;
	filters: F;
	setFilters: (f: F) => any;
	defaultFilters: F;
}) => {
	const { t } = useTranslation();

	return (
		<PageDataFilterForm
			total={total}
			filters={filters}
			setFilters={setFilters}
			defaultFilters={defaultFilters}
		>
			<Form.Item name="keyword">
				<Input
					placeholder={t("system:filter_keyword_placeholder")}
					allowClear
				/>
			</Form.Item>
			<Form.Item name="resource_id">
				<Input
					placeholder={t("system:filter.resource_id")}
					allowClear
				/>
			</Form.Item>
			<FormSelect
				name="action"
				placeholder={t("system:filter.action")}
				options={SystemEventModel.getActionList()}
				allowClear
				popupMatchSelectWidth={false}
			/>
			<FormSelect
				name="event"
				placeholder={t("system:filter.event")}
				options={SystemEventModel.getEventList()}
				allowClear
				popupMatchSelectWidth={false}
			/>
			<FormSelect
				name="resource_type"
				placeholder={t("system:filter.resource_type")}
				options={SystemEventModel.getResouceTypeList()}
				allowClear
				popupMatchSelectWidth={false}
			/>
			<FormSelect
				name="status"
				options={SystemEventModel.getStatusList()}
				allowClear
				placeholder={t("common:status")}
			/>
			<Form.Item label={t("common:date_created")}>
				<DatePickerRangeFilter
					filters={filters}
					setFilters={setFilters}
					placeholderRange={["từ", "đến"]}
					keyEnd="to_time"
					keyStart="from_time"
				/>
			</Form.Item>
		</PageDataFilterForm>
	);
};

export default TemplateListFilter;
