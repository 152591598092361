import ActionModel from "common/models/ActionModel";
import { ActionCollectionJson, ActionJson } from "common/types/Action";
import BaseCollection from "./BaseCollection";

class ActionCollection extends BaseCollection<
  ActionJson,
  ActionModel,
  ActionCollectionJson
> {
  itemsFromJson(jsonItems: ActionJson[]): ActionModel[] {
    return jsonItems.map((item) => new ActionModel(item));
  }
}
export default ActionCollection;
