import { LoadingOutlined, SettingOutlined } from "@ant-design/icons";
import {
	App,
	Badge,
	Button,
	Checkbox,
	Col,
	Form,
	List,
	Modal,
	notification,
	Row,
	Tooltip,
	Typography,
} from "antd";
import Role from "common/constants/Role";
import EmployeeModel from "common/models/EmployeeModel";
import RbacRoleModel from "common/models/RbacRoleModel";
import RbacRoleRepository from "common/repositories/RbacRoleRepository";
import RbacRoleUserRepository from "common/repositories/RbacRoleUserRepository";
import RbacSubjectRepository from "common/repositories/RbacSubjectRepository";
import { AccountJson } from "common/types/Account";
import {
	RbacSubject,
	RbacSubjectGroup,
	RbacSubjectOj,
} from "common/types/RbacSubject";
import { StoreJson } from "common/types/Store";
import { WarehouseJson } from "common/types/Warehouse";
import ErrorLayout from "components/LayoutError";
import SelectStore from "components/SelectStore";
import WarehouseFormFilter from "features/warehouse/WarehouseFormFilter";
import WarehouseSelects from "features/warehouse/WarehouseSelects";
import produce from "immer";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

const RbacRolePermissionEditorModal = ({
	model,
	open,
	setOpen,
	onSaveSuccess,
}: {
	model: RbacRoleModel;
	open: boolean;
	setOpen: (b: boolean) => any;
	onSaveSuccess: (r: RbacRoleModel) => void;
}) => {
	const { t } = useTranslation();
	const { message } = App.useApp();
	const [form] = Form.useForm();
	const loginAccount = useLoginAccountStore((state) => state.account);

	const [processing, setProcessing] = useState(false);

	const toggleGroup = useCallback(
		(group: RbacSubjectGroup) => {
			const currentAssignedSubjects: number[] =
				form.getFieldValue("assigned_subjects");
			const isSelectedAll = group.items.every((item) =>
				currentAssignedSubjects.includes(item.id)
			);
			if (isSelectedAll) {
				//de-select all now
				form.setFieldsValue({
					assigned_subjects: currentAssignedSubjects.filter(
						(id) => !group.items.map((item) => item.id).includes(id)
					),
				});
			} else {
				//select all
				const newValues = group.items
					.filter((item) => !currentAssignedSubjects.includes(item.id))
					.map((item) => item.id);
				form.setFieldsValue({
					assigned_subjects: produce(currentAssignedSubjects, (draft) => {
						draft.push(...newValues);
					}),
				});
			}
		},
		[form]
	);

	const doSubmitProcess = useCallback(
		async (formData: any) => {
			setProcessing(true);
			message.loading({
				content: t("common:form.processing"),
				key: "message",
				duration: 0,
			});

			const item = await new RbacRoleRepository().savePermission({
				id: model.id,
				assigned_subjects: formData.assigned_subjects,
			});
			setProcessing(false);
			if (item.hasError()) {
				message.error({
					content: (
						<ErrorLayout
							onClickClose={() => {
								message.destroy("message");
							}}
							heading={t("common:form.error.heading")}
							translate_prefix="role:form.error"
							items={item.error.errors}
						/>
					),
					className: "message_error",
					key: "message",
					duration: 4,
				});
			} else {
				message.success({
					content: t("role:form.success.edit_permission"),
					className: "message_success",
					key: "message",
					duration: 2,
				});
				onSaveSuccess(item);
				setOpen(false);
			}
		},
		[model.id, onSaveSuccess, setProcessing, setOpen, t, message]
	);

	const onFinish = (formData: any) => {
		doSubmitProcess(formData);
	};

	async function onSubmitSubject(id: number, storeIds: number[]) {
		try {
			const res = await new RbacRoleUserRepository().limitObjectIdList({
				creator_id: loginAccount.id,
				rbac_role_id: model.id,
				list_ids: storeIds,
				subject_ids: id.toString(),
			});

			if (res.length > 0) {
				throw Error("set_object_failed");
			} else {
				notification.success({
					message: "Cài đặt thành công.",
				});
			}
		} catch (error) {
			notification.error({
				message: "Thất bại",
			});
			throw error;
		}
	}
	const initialValues = {
		assigned_subjects: model.assigned_subjects,
	};

	return (
		<Modal
			destroyOnClose={true}
			title={t("role:heading_edit_permission", { name: model.name })}
			open={open}
			closable={true}
			maskClosable={false}
			width={920}
			onCancel={() => {
				setOpen(false);
			}}
			onOk={() => {
				form.submit();
			}}
			okText={t("common:form.save")}
			cancelText={t("common:close")}
			confirmLoading={processing}
			bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 220px)" }}>
			<Form form={form} onFinish={onFinish} initialValues={initialValues}>
				<Form.Item name="assigned_subjects" noStyle>
					<Checkbox.Group className="block w-full">
						<List
							className="d-block"
							dataSource={RbacSubjectRepository.getAllSubjects()}
							renderItem={(group) => {
								return (
									<List.Item>
										<List.Item.Meta
											description={
												<Row>
													<Col span={4}>
														<span className="text-base text-gray-800">
															{t("permission:group." + group.name)}
														</span>
													</Col>
													<Col span={16}>
														{group.items.map((item) => {
															return (
																<SubjectItem
																	groupName={group.name}
																	key={item.id}
																	rbac_role_id={model.id}
																	item={item}
																	onSubmitSubject={onSubmitSubject}
																/>
															);
														})}
													</Col>
													<Col span={4} className="text-right">
														<Button
															key="selectall"
															type="link"
															onClick={() => toggleGroup(group)}>
															{t("role:toggle_permission")}
														</Button>
													</Col>
												</Row>
											}
										/>
									</List.Item>
								);
							}}
						/>
					</Checkbox.Group>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default RbacRolePermissionEditorModal;

function SetStorePermissonForUser({
	onSubmit,
	defaultValue,
}: {
	defaultValue?: number[];
	onSubmit: (storeIds: number[]) => void;
}) {
	const [stores, setStores] = useState<StoreJson[]>([]);
	return (
		<div className=" flex  flex-col  gap-4 p-6">
			<SelectStore
				defaultValue={defaultValue}
				onChange={(stores) => setStores(stores)}
			/>
			<Button
				onClick={() => onSubmit(stores.map((store) => store.id))}
				className=" w-fit">
				Lưu
			</Button>
		</div>
	);
}

function SetWarehousePermissonForUser({
	onSubmit,
	defaultValue,
}: {
	defaultValue?: number[];
	onSubmit: (storeIds: number[]) => void;
}) {
	console.log("🚀 ~ defaultValue:", defaultValue);
	const [warsehouseIds, setWarsehouseIds] = useState<number[]>(
		defaultValue ?? []
	);
	return (
		<div className=" flex  flex-col  gap-4 p-6">
			<WarehouseSelects
				multiple={true}
				defaultValue={defaultValue}
				value={warsehouseIds}
				selectProps={{
					mode: "multiple",
				}}
				onChange={(val) => {
					const ids = val as number[];
					setWarsehouseIds(ids);
				}}></WarehouseSelects>
			<Button onClick={() => onSubmit(warsehouseIds)} className=" w-fit">
				Lưu
			</Button>
		</div>
	);
}

function SubjectItem({
	item,
	onSubmitSubject,
	rbac_role_id,
	groupName,
}: {
	groupName: string;
	item: RbacSubject;
	rbac_role_id: number;
	onSubmitSubject: (id: number, stores: number[]) => Promise<void>;
}) {
	const { t } = useTranslation();

	const permissionsSpe = [
		1020,
		1021,
		1022,
		1023,
		Role.CROSS_ORDER_VIEW,
		Role.CROSS_ORDER_ADD,
		Role.CROSS_ORDER_EDIT,
		Role.CROSS_ORDER_DELETE,
		Role.INVENTORY_NUMBER,
		Role.INVENTORY_VIEW,
		Role.INVENTORY_ADD,
		Role.INVENTORY_STATUS_APPROVE,
		Role.INVENTORY_STATUS_CHECK,
		Role.INVENTORY_STATUS_COMPLETE,
		Role.INVENTORY_STATUS_CANCEL,
		Role.INVENTORY_TRANSFER_REQUEST,
		Role.INVENTORY_TRANSFER_APPROVE,
		Role.INVENTORY_STOCK_CHECK,
		Role.CASHFLOW_VIEW,
		Role.CASHFLOW_ADD,
		Role.CASHFLOW_EDIT,
		Role.CASHFLOW_STATUS_APPROVE,
		Role.CASHFLOW_STATUS_COMPLETE,
		Role.CASHFLOW_STATUS_CANCEL,

		Role.INVENTORY_STOCK_VIEW,
		Role.INVENTORY_STOCK_EDIT,
		Role.INVENTORY_STOCK_ADD,
		Role.INVENTORY_STOCK_DELETE,
	];

	const isConfigStore = permissionsSpe.includes(item.id);

	const [open, setOpen] = useState(false);

	return (
		<div className="py-0.5 flex gap-2 items-center">
			<Checkbox value={item.id}>
				{/* <Typography.Text>{t("permission:role." + item.ticket)}</Typography.Text> */}
				{isConfigStore ? (
					<Tooltip
						title={
							<Typography.Text className="mx-2 text-white">
								{t("permission:role." + item.ticket + "_sub")}
							</Typography.Text>
						}>
						<Typography.Text>
							{t("permission:role." + item.ticket)}
						</Typography.Text>
					</Tooltip>
				) : (
					<Typography.Text>
						{t("permission:role." + item.ticket)}
					</Typography.Text>
				)}
			</Checkbox>
			{isConfigStore && <SettingOutlined onClick={() => setOpen(true)} />}

			<Modal
				destroyOnClose={true}
				open={open}
				onCancel={() => {
					setOpen(false);
				}}
				footer={null}
				title={`Cài đặt quyền ${t("permission:role." + item.ticket)}`}>
				<SubjectItemConfig
					groupName={groupName}
					item={item}
					rbac_role_id={rbac_role_id}
					onSubmit={async (res) => {
						await onSubmitSubject(item.id, res).finally(() => {
							setOpen(false);
						});
					}}
				/>
			</Modal>
		</div>
	);
}
function SubjectItemConfig({
	onSubmit,

	item,
	rbac_role_id,
	groupName,
}: {
	groupName: string;

	item: RbacSubject;
	onSubmit: (res: number[]) => Promise<void>;
	rbac_role_id: number;
}) {
	const permissionsSpe = [
		1020,
		1021,
		1022,
		1023,
		Role.CROSS_ORDER_VIEW,
		Role.CROSS_ORDER_ADD,
		Role.CROSS_ORDER_EDIT,
		Role.CROSS_ORDER_DELETE,
		Role.INVENTORY_NUMBER,
		Role.INVENTORY_VIEW,
		Role.INVENTORY_ADD,
		Role.INVENTORY_STATUS_APPROVE,
		Role.INVENTORY_STATUS_CHECK,
		Role.INVENTORY_STATUS_COMPLETE,
		Role.INVENTORY_STATUS_CANCEL,
		Role.INVENTORY_TRANSFER_REQUEST,
		Role.INVENTORY_TRANSFER_APPROVE,
		Role.INVENTORY_STOCK_CHECK,
		Role.CASHFLOW_VIEW,
		Role.CASHFLOW_ADD,
		Role.CASHFLOW_EDIT,
		Role.CASHFLOW_STATUS_APPROVE,
		Role.CASHFLOW_STATUS_COMPLETE,
		Role.CASHFLOW_STATUS_CANCEL,

		Role.INVENTORY_STOCK_VIEW,
		Role.INVENTORY_STOCK_EDIT,
		Role.INVENTORY_STOCK_ADD,
		Role.INVENTORY_STOCK_DELETE,
	];

	const [ids, setIds] = useState<number[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [idOnFaileds, setIdOnFaildeds] = useState<number[]>([]);

	// const [list, setList] = useState<RbacSubjectOj[]>([])
	async function getData() {
		try {
			setIsLoading(true);
			const res = await new RbacRoleUserRepository().getSubjectObjectList({
				subject_id: item.id,
				rbac_role_id,
			});
			// const ids: number[] = []
			// setList(res)
			// res.forEach(itemOj => {
			//   if (itemOj.subject_id === item.id) {

			//     ids.push(...itemOj.id_list)
			//   }
			// })
			setIds(res[res.length - 1].id_list);
		} catch (error) {
		} finally {
			setIsLoading(false);
		}
	}

	useEffect(() => {
		if (permissionsSpe.includes(item.id)) {
			getData();
		}
	}, [item.id, rbac_role_id]);

	const comSetPermisson = useMemo(() => {
		switch (groupName) {
			case "inventory":
				return (
					<SetWarehousePermissonForUser
						defaultValue={ids}
						onSubmit={async (res) => {
							await onSubmit(res).catch(() => {
								setIdOnFaildeds(ids);
							});
						}}></SetWarehousePermissonForUser>
				);

			default:
				return (
					<SetStorePermissonForUser
						defaultValue={ids}
						onSubmit={async (res) => {
							await onSubmit(res);
						}}
					/>
				);
		}
	}, [groupName, ids]);

	if (isLoading) {
		return <LoadingOutlined />;
	}

	return <>{comSetPermisson}</>;
}
