import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Modal, Popconfirm, Spin, Tag, Typography, message } from "antd";
import { useTranslation } from "react-i18next";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import useStateFilter from "hooks/useStateFilter";
import Role from "common/constants/Role";

import TableEdit from "components/table/TableEdit";
import TableDelete from "components/table/TableDelete";
import useFilterLocation from "hooks/useFilterLocation";
import FormModal from "components/form/FormModal";

//////////////////////////////////
//Phần thay thế
import { FilterLeaveAssign } from "common/types/LeaveAssign";
import { LeaveAssignJson } from "common/types/LeaveAssign";

import LeaveAssignListFilter from "./LeaveAssignListFilter";
import LeaveAssignModel from "common/models/LeaveAssignModel";
import LeaveAssignCollection from "common/collections/LeaveAssignCollection";
import LeaveAssignListHeader from "./LeaveAssignListHeader";
import IdFetcherRender from "components/idfetcher/IdFetcherRender";
import { IdFetcherResult } from "common/types/IdFetcher";
import LeaveApproveRepository from "common/repositories/LeaveApproveRepository";
import useIdFetcher from "hooks/useIdFetcher";
import LeaveTypeRepository from "common/repositories/LeaveTypeRepository";
import LeaveTypeModel from "common/models/LeaveTypeModel";
import LeaveStepRepository from "common/repositories/LeaveStepRepository";
import LeaveStepModel from "common/models/LeaveStepModel";
import LeaveAssignForm from "features/leaveapprove/assgin/form/LeaveAssignForm";

import useDatabaseTable from "hooks/useDatabaseTable";
import { EmployeeJson } from "common/types/Employee";
import PageDataPagination from "components/page/PageDataPagination";
import { TableColumnsType } from "common/types/Table";
import TextUser from "components/TextUser";
import useEmployeeSource from "hooks/useEmployeeSource";
import TextString from "components/TextString";

const LeaveAssignList = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { getListIdFetcher } = useIdFetcher();
	const { Text } = Typography;

	////////////////////////
	// modal editing
	const [modalVisible, setModalVisible] = useState(false);
	const [editingId, setEditingId] = useState(0);
	const [modal, setModal] = useState(LeaveAssignModel.getDefaultData());
	////////////////////////
	// default filter
	const defaultFilters = useMemo(() => {
		return LeaveApproveRepository.getDefaultFilter();
	}, []);

	///////////////////////
	//state
	const [total, setTotal] = useState<number>(0);
	const [dataSource, setDataSource] = useState<LeaveAssignModel[]>([]);
	const [filters, setFilters] = useStateFilter(defaultFilters);
	useFilterLocation(defaultFilters, filters);

	const [employees, setEmployees] = useState<IdFetcherResult[]>([]);
	const [departments, setDepartments] = useState<IdFetcherResult[]>([]);
	const [leaveTypes, setLeaveTypes] = useState<LeaveTypeModel[]>([]);
	const [steps, setSteps] = useState<LeaveStepModel[]>([]);

	const [loading, setLoading] = useState(true);
	//Table columns
	const columns: TableColumnsType<LeaveAssignModel> = [
		{
			title: t("common:columns.internal_id"),
			key: "id",
			width: 120,
			render: (_, record) => (
				<IdFetcherRender
					value={record.employee_id}
					mapping={employees}
					key_value="internal_id"
				/>
			),
		},
		{
			title: t("common:columns.full_name"),
			key: "full_name",
			width: 150,
			render: (_, record) => (
				<IdFetcherRender
					value={record.employee_id}
					mapping={employees}
					key_value="text"
				/>
			),
		},
		{
			title: t("common:columns.department"),
			key: "department",
			width: 150,
			render: (_, record) => {
				return (
					<IdFetcherRender
						value={record.resourceid}
						mapping={departments}
						key_value="text"
					/>
				);
			},
		},
		{
			title: t("leaveapprove:assign.columns.type"),
			key: "type",
			// sortby: "name",
			render: (type) => {
				const text = type === "leave" ? "Nghỉ phép" : "trống";
				return <Text>{text}</Text>;
			},
		},
		{
			title: t("leaveapprove:assign.columns.step"),
			key: "step",
			render: (step) => {
				const stepName = steps.find((i) => i.steps === step)?.approve_name;
				return (
					<Text>
						{stepName
							? t(`leaveapprove:approval.form.steps.${stepName}`)
							: "Trống"}
					</Text>
				);
			},
		},

		{
			title: t(""),
			key: "actions",
			width: 100,
			align: "right",
			fixed: "right",
			render: (_: any, record: LeaveAssignJson) => (
				<RoleCheck roles={[Role.SETTING_HRM]} hideOnFail>
					<TableEdit link="" onClick={() => onEdit(record)} />
					<TableDelete
						error_translate_prefix="department:form.error"
						onDeleteCallback={(id) => {
							fetchData();
						}}
						repository={new LeaveApproveRepository()}
						id={record.approver_id}
					/>
					{/* <TableInfo record={record} /> */}
				</RoleCheck>
			),
		},
	];

	///////////////////////
	//function
	//get list deapartment from list id
	const getListDepartment = useCallback(async (id_list: number[]) => {
		const response = await getListIdFetcher({
			object_type: "department",
			ids: id_list,
		});

		if (!response.hasError()) {
			const result = response.items.filter(
				(i) => i.object_type === "department"
			)[0].result!;
			setDepartments(result);
		} else {
			message.error(t("common:error.error_fetching_data"));
		}
	}, [getListIdFetcher, t]);

	const getListEmployee = useCallback(async (id_list: number[]) => {
			const response = await getListIdFetcher({
				object_type: "employee",
				ids: id_list,
			});
	
			if (!response.hasError()) {
				const result = response.items.filter(
					(i) => i.object_type === "employee"
				)[0].result!;
				setEmployees(result);
			}
		}, [getListIdFetcher]);

	const fetchData = useCallback(async (): Promise<LeaveAssignCollection> => {
		const response = await new LeaveApproveRepository().getItems({
			filters: filters,
		});
		// if (!response.hasError() && response.items.length > 0) {
		if (!response.hasError()) {
			setTotal(response.total);
			setDataSource(response.items);

			if (response.items.length > 0) {
				const departmentIds = [
					...new Set(response.items.map((i) => i.resourceid)),
				];
				await getListDepartment(departmentIds);

				const employeeIds = [...new Set(response.items.map((i) => i.employee_id || 0))];
				await getListEmployee(employeeIds);
			}
		}
		return response;
	}, [filters, getListDepartment, getListEmployee]);

	//get list employee from list id

	const getListLeaveType = async () => {
		const response = await new LeaveTypeRepository().getListLeaveType({
			filters: LeaveTypeRepository.getDefaultFilters(),
		});
		if (!response.hasError()) {
			setLeaveTypes(response.items);
		} else {
			message.error(t("common:error.error_fetching_data"));
		}
	};

	const getAllStep = async () => {
		const response = await new LeaveStepRepository().getItems();
		if (!response.hasError()) {
			setSteps(response.items);
		}
	};

	const handleCallInit = async () => {
		await getAllStep();
		await getListLeaveType();
		setLoading(false);
	};

	////////////////////////////////
	//event
	const onEdit = (model: LeaveAssignJson) => {
		setModal(model);
		setEditingId(1);
		setModalVisible(true);
	};

	const onAdd = () => {
		setEditingId(0);
		setModalVisible(true);
	};

	const onClose = useCallback(() => {
		setModalVisible(false);
		setModal(LeaveAssignModel.getDefaultData());
	}, [modalVisible]);

	useEffect(() => {
		handleCallInit();
	}, []);

	return (
		<Spin spinning={loading}>
			<LeaveAssignListHeader
				toggleAdd={() => {
					onAdd();
				}}
			/>
			<LeaveAssignListFilter
				total={total}
				filters={filters}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
			/>
			<PageDataTable<FilterLeaveAssign, LeaveAssignModel, LeaveAssignCollection>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					// tableColumnToggleKey: "template",
				}}></PageDataTable>

			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>

			<Modal
				open={modalVisible}
				destroyOnClose
				title={editingId > 0 ? "Sửa quyền duyệt" : "Gán quyền duyệt"}
				closable={false}
				okButtonProps={{ style: { display: "none" } }}
				cancelText={
					<Popconfirm
						title={t("common:confirm_cancel")}
						onConfirm={() => {
							onClose();
						}}>
						{t("common:table.cancel")}
					</Popconfirm>
				}>
				<LeaveAssignForm
					model={new LeaveAssignModel(modal)}
					steps={steps}
					onClose={onClose}></LeaveAssignForm>
			</Modal>
		</Spin>
	);
};

export default LeaveAssignList;
