import { useState } from "react";
import { DataZoneBD } from "common/types/Event";

import {
	Button,
	Col,
	Divider,
	Form,
	Input,
	Modal,
	Row,
	Skeleton,
	Space,
	Spin,
	Typography,
	message,
	notification,
} from "antd";
import SettingApi from "common/constants/Setting";

import PageSortList from "components/list/PageSortList";
import { DropResult } from "react-beautiful-dnd";

import useHandleSetting from "hooks/useHandleSetting";
import ListDeleteButton from "components/list/ListDeleteButton";
import ShopEventHeader from "./ShopEventHeader";
import ShopEventBD11Form from "../form/ShopEventBD11Form";
import { EditOutlined, PlusOutlined, SyncOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";

const ShopEventLastYear = () => {
	const defaultDataPopup: DataZoneBD = {
		zone_data: [],
		zone_name: "sub_banner",
		zone_title: "",
	};

	const [open, setOpen] = useState<boolean>(false);
	const [editTitle, setEditTitle] = useState<DataZoneBD | null>(null);
	const [indexEdit, setIndexEdit] = useState<number>(-1);

	const [form] = Form.useForm();

	const [lang, setLang] = useState<string>("vi");

	const [data, setData] = useState<{
		data: DataZoneBD;
		index: number;
		type: "add" | "edit";
	}>({
		data: defaultDataPopup,
		index: -1,
		type: "add",
	});

	const {
		dataSource,
		loading,
		addItemSetting,
		deleteItemSetting,
		editItemSetting,
		handleDrag,
		fetchData,
	} = useHandleSetting<DataZoneBD>(SettingApi.KEY.landing_page_last_year, lang);

	const handleDragEnd = async (result: DropResult) => {
		try {
			if (!result.destination) return;
			await handleDrag(result.source.index, result.destination.index);
			message.success({
				content: "Thao tác thành công",
				duration: 1,
			});
		} catch (error) {
			message.error({
				content: "Thao tác thất bại",
				duration: 1,
			});
		}
	};

	// function handlelOpenModalAdd(data: DataZoneBD) {
	// 	setData({
	// 		data: data,
	// 		type: "add",
	// 		index: -1,
	// 	});
	// 	setOpen(true);
	// }
	function handlelOpenModalEdit(data: DataZoneBD, index: number) {
		setData({
			data: data,
			type: "edit",
			index,
		});
		setOpen(true);
	}

	async function handleSumit(dataRes: DataZoneBD) {
		try {
			if (data.type === "add") {
				await addItemSetting(dataRes);
			} else {
				await editItemSetting(dataRes, data.index);
			}
			setOpen(false);
		} catch (error) {
			notification.error({
				message: "Có lỗi xảy ra vui lòng thử lại",
				description: JSON.stringify(error),
			});
		}
	}

	async function handleDelete(data: DataZoneBD, index: number) {
		try {
			await deleteItemSetting(data, index);
		} catch (error) {
		} finally {
			setOpen(false);
		}
	}

	const handleEditTitle = async (data: DataZoneBD, index: number) => {
		try {
			await editItemSetting(data, index);
			notification.success({
				message: "Sửa thành công",
			});
		} catch (error) {
			notification.success({
				message: "Sửa thất bại",
			});
		}
	};

	const onFomEditTitleSubmit = () => {};

	return (
		<>
			<ShopEventHeader pathname="salecuoinam"></ShopEventHeader>
			<ShopTool onRefetch={fetchData}></ShopTool>
			<Spin spinning={loading}>
				<Space
					align="baseline"
					direction="horizontal"
					className="block w-full p-8">
					<PageSortList
						dataSource={dataSource}
						handleDragEnd={handleDragEnd}
						listKey="shopBannerHome"
						renderListItem={(item: DataZoneBD, index) => {
							return (
								<Row gutter={8}>
									<Col flex={1}>
										<Typography.Text>
											{index + 1}. {item.zone_title}
										</Typography.Text>
										<>
											<Button
												onClick={() => {
													setEditTitle(item);
													setIndexEdit(index);
												}}
												size="small"
												type="link"
												icon={<EditOutlined />}>
												Chỉnh sửa tiêu đề
											</Button>
										</>
									</Col>
									<Col className="flex gap-2">
										<Button
											size="small"
											type="primary"
											onClick={() => {
												handlelOpenModalEdit(item, index);
											}}>
											Sửa
										</Button>

										<ListDeleteButton
											buttonSize="small"
											buttonType="default"
											handleDelete={() => {
												handleDelete(item, index);
											}}
											id={index}
										/>
									</Col>
								</Row>
							);
						}}
					/>
				</Space>
			</Spin>

			<Modal
				title={
					data.type === "edit" ? `Cập nhật ${data.data.zone_title}` : "Thêm mới"
				}
				open={open}
				footer={<></>}
				onCancel={() => {
					setOpen(false);
				}}
				maskClosable={false}
				width={1000}
				destroyOnClose
				bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}>
				<ShopEventBD11Form
					data={data.data}
					onFinish={handleSumit}
					type="edit"
				/>
			</Modal>

			<Modal
				title={`Cập nhật ${data.data.zone_title}`}
				open={indexEdit >= 0}
				footer={<></>}
				onCancel={() => {
					setIndexEdit(-1);
					setEditTitle(null);
				}}
				maskClosable={false}
				width={1000}
				destroyOnClose
				bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}>
				<Form
					onFinish={onFomEditTitleSubmit}
					form={form}
					initialValues={{ zone_title: editTitle?.zone_title || "" }}>
					<Form.Item name={"zone_title"}>
						<Input></Input>
					</Form.Item>
					<Form.Item>
						<Button htmlType="submit">Xác nhận</Button>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default ShopEventLastYear;

type ShopToolProps = {
	onRefetch?: () => Promise<void>;
	onCreate?: () => Promise<void>;
};
const ShopTool = ({ onRefetch, onCreate }: ShopToolProps) => {
	const [loading, setLoading] = useState({
		refetch: false,
		create: false,
	});

	const handleRefetch = async () => {
		try {
			setLoading((prev) => ({ ...prev, refetch: true }));

			await onRefetch?.();
		} catch (error) {
		} finally {
			setLoading((prev) => ({ ...prev, refetch: false }));
		}
	};

	const handleCreate = async () => {
		try {
			setLoading((prev) => ({ ...prev, create: true }));

			await onCreate?.();
		} catch (error) {
		} finally {
			setLoading((prev) => ({ ...prev, create: false }));
		}
	};

	return (
		<Row gutter={8} className="bg-gray-200 p-2">
			<Col>
				<Button
					loading={loading.refetch}
					onClick={handleRefetch}
					size="small"
					icon={<SyncOutlined></SyncOutlined>}>
					Tải lại
				</Button>
			</Col>
			<Col>
				<Button
					loading={loading.create}
					onClick={handleCreate}
					size="small"
					icon={<PlusOutlined />}>
					Tạo mới
				</Button>
			</Col>
			<Col></Col>
		</Row>
	);
};
