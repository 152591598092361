import { Card, DatePicker, message } from "antd";
import { Button } from "antd";
import { Col, Form, Input, Row } from "antd";
import { DataZoneBD, ZoneDataBanner } from "common/types/Event";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import UploadEvent from "./UploadEvent";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import Helper from "common/utils/helper";

const SectionBannerDB = ({
	data,
	onSubmit,
}: {
	data: ZoneDataBanner[];
	onSubmit: (data: ZoneDataBanner[]) => void;
}) => {
	const { t } = useTranslation();
	// const [imgMobile, setImgMobile] = useState(data.image_mobile);
	// const [imgDesktop, setImgDesktop] = useState(data.image_desktop);
	const { RangePicker } = DatePicker;

	const [form] = useForm();
	const dateFormat = "HH:mm:ss DD-MM-YYYY";

	const init = data.map((item) => ({
		...item,
		available_type: [
			dayjs(item.from_time * 1000 || Date.now()),
			dayjs(item.to_time * 1000 || Date.now()),
		],
	}));
	const rangeConfig = {
		rules: [
			{
				type: "array" as const,
				required: true,
				message: "Vui lòng chọn thời gian!",
			},
		],
	};
	const onFinish = (values: any) => {
		console.log("🚀 ~ onFinish ~ values:", values);
		const dataForm = values.items as any[];
		try {
			const result: ZoneDataBanner[] = dataForm.map((item) => {
				const fromTimestamp = Helper.convertedDateToTimeStamp(
					item.available_type[0]
				) as number;
				const toTimestamp = Helper.convertedDateToTimeStamp(
					item.available_type[1]
				) as number;

				return {
					from_time: fromTimestamp,
					to_time: toTimestamp,
					url: item.url,
					image_desktop: item.image_desktop,
					image_mobile: item.image_mobile,
				};
			});
			onSubmit(result);
		} catch (error) {
			message.error("Có lỗi xảy ra");
		}
	};

	return (
		<Form
			labelCol={{ span: 6 }}
			wrapperCol={{ span: 18 }}
			form={form}
			name="dynamic_form_products"
			autoComplete="off"
			initialValues={{ items: init }}
			layout="vertical"
			onFinish={onFinish}>
			<Form.List name="items">
				{(fields, { add, remove }) => (
					<div style={{ display: "flex", rowGap: 16, flexDirection: "column" }}>
						{fields.map((field, index) => (
							<Card
								size="default"
								title={`Hình ${field.name + 1}`}
								key={field.key}>
								<Row>
									<Col span={12}>
										<UploadEvent
											className="flex gap-2"
											height={120}
											defaultUrl={data?.[index]?.image_desktop || ""}
											onSuccess={(url) => {
												message.success("Thay đổi thành công");
												form.setFieldValue(
													["items", field.name, "image_desktop"],
													url
												);
											}}></UploadEvent>
									</Col>
									<Col span={12}>
										<UploadEvent
											className="flex gap-2"
											height={120}
											defaultUrl={data?.[field.key]?.image_mobile || ""}
											onSuccess={(url) => {
												message.success("Thay đổi thành công");
												form.setFieldValue(
													["items", field.name, "image_mobile"],
													url
												);
											}}></UploadEvent>
									</Col>
									<Col span={24}>
										<Form.Item
											labelCol={{ span: 24 }}
											label="Mốc thời gian chạy"
											{...rangeConfig}
											name={[field.name, "available_type"]}>
											<RangePicker showTime format={dateFormat} />
										</Form.Item>
									</Col>
									<Col span={24}>
										<Form.Item
											labelCol={{ span: 24 }}
											className="mb-4"
											label="Đường dẫn"
											name={[field.name, "url"]}>
											<Input size="middle"></Input>
										</Form.Item>
									</Col>
								</Row>
							</Card>
						))}

						<Button
							className="  absolute bottom-10 right-4 w-[100px]"
							type="primary"
							htmlType="submit">
							Lưu
						</Button>
					</div>
				)}
			</Form.List>
			{/* <Row gutter={16}>
				<Col span={24}>
					<Form.Item
						initialValue={data.url}
						label={"Đường dẫn banner"}
						name="url"
						rules={[
							{
								required: true,
								message: t("template:form.error.error_fullname_required"),
							},
						]}>
						<Input autoFocus />
					</Form.Item>
				</Col>

				<Col span={24}>
					<Row gutter={8}>
						<Col span={12}>
							<Form.Item
								labelCol={{ span: 24 }}
								name="image_desktop"
								initialValue={data.image_desktop}
								className="min-h-[150px]  w-full"
								label="Ảnh banner desktop">
								<UploadEvent
									defaultUrl={imgDesktop}
									height={150}
									onSuccess={(url) => {
										message.success("Thay đổi thành công");
										form.setFieldValue("image_desktop", url);
										setImgDesktop(url);
									}}></UploadEvent>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								labelCol={{ span: 24 }}
								name="image_mobile"
								initialValue={data.image_mobile}
								label="Ảnh banner mobile"
								className="min-h-[150px]">
								<UploadEvent
									defaultUrl={imgMobile}
									height={150}
									width={150}
									onSuccess={(url) => {
										message.success("Thay đổi thành công");
										form.setFieldValue("image_mobile", url);
										setImgMobile(url);
									}}></UploadEvent>
							</Form.Item>
						</Col>
					</Row>
				</Col>
			</Row> */}

			<Button
				className="  absolute bottom-10 right-4 w-[100px]"
				type="primary"
				htmlType="submit">
				Lưu
			</Button>
		</Form>
	);
};

export default SectionBannerDB;
