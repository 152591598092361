import Order from "common/constants/Order";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { OrderDetailJson } from "common/types/OrderDetail";
import { SelectOption, SelectOptionNormal } from "common/types/SelectOption";
import i18n from "i18n";

import BaseModel from "./BaseModel";
import CashflowReceiptModel from "./CashflowReceiptModel";
import ProductReceiptModel from "./ProductReceiptModel";

import {
	ISPROBLEM,
	OrderDeliveryStatus,
	OrderInventoryStatus,
	type NoteInvoiceJson,
	type OrderJson,
} from "common/types/Order";
import { TaxInvoiceJson } from "common/types/TaxInvoice";
import {
	CartCouponAddJson,
	CartOrderJson,
	CartPromotion,
	IsUse,
} from "common/types/Cart";
import ProductModel from "./ProductModel";
import Delivery from "common/constants/Delivery";

const defaultData: OrderJson = {
	company_id: 0,
	creator_id: 0,
	id: 0,
	invoice_id: 0,
	warehouse_id: 0,
	code: "",
	store_id: 0,
	customer_id: 0,
	price_sell: 0,
	price_shipping: 0,
	price_discount: 0,
	price_final: 0,
	price_cost: 0,
	price_tax: 0,
	price_deposit: 0,
	pricedebt: 0,
	promotion_id: 0,
	promotion_detail: "",
	billingt_email: "",
	billing_fullname: "",
	billing_phone: "",
	billing_address: "",
	billing_ward: 0,
	billing_district: 0,
	billing_province: 0,
	billing_country: 0,
	billing_company: 0,
	shipping_fullname: "",
	shipping_phone: "",
	shipping_address: "",
	shipping_ward: 0,
	shipping_district: 0,
	shipping_province: 0,
	shipping_country: 0,
	shipping_company: 0,
	shipping_carrier: 0,
	note: "",
	note_invoice: {},
	cancel_reason: 0,
	payment_method_json: "",
	parent_id: 0,
	service_type: 0,
	status: 0,
	tag: "",
	ipaddress: 0,
	cod_amount: 0,
	order_detail: "",
	order_description: "",
	date_created: 0,
	date_modified: 0,
	date_completed: 0,
	date_arrived: 0,
	resource_type: 0,
	resource_id: 0,
	resource_number: "",
	resource_json: "",
	resource_date_created: 0,
	is_deleted: 0,
	date_deleted: 0,
	ecom_platform_type: 0,
	ecom_platform_order_id: 0,
	ecom_platform_invoice_id: 0,
	tax_ratio: 0,

	details: { total: 0, data: [] },
	promotions: [],
	coupons: [],
	payments: [],
	price_discount_coupon: 0,
	payment_status: 0,
	total_payment: 0,
	sale_channel: 0,
	deposit: 0,
	type: 0,
	debt: 0,
	order_custom_discount: 0,
	order_custom_type: "amount",
	order_custom_value: 0,
	order_discount: 0,
	referral_id: 0,
	seller_id: 0,
	shipping_firstname: "",
	shipping_lastname: "",
	delivery_status: 0,
	delivery_lits_id: "",
	has_invoice: 0,
	item_discount: 0,
	customer_credit_balance: false,
	inventory_status: OrderInventoryStatus.NOT_SHIPPED,
	is_problem: ISPROBLEM.NOT,
	note_problem: "",
	referral_name: "",
	using_cod: false,
	note_discout_custom: "",
	total_price_vat_item: 0,
};
class OrderModel extends BaseModel implements BaseModelJson<OrderJson> {
	id: number | string = 0;
	company_id: number = 0;
	creator_id: number = 0;
	invoice_id: number = 0;
	warehouse_id: number = 0;
	code: string = "";
	store_id: number = 0;
	customer_id: number = 0;
	price_sell: number = 0;
	price_shipping: number = 0;
	price_discount: number = 0;
	price_final: number = 0;
	price_cost: number = 0;
	price_tax: number = 0;
	price_deposit: number = 0;
	pricedebt: number = 0;
	promotion_id: number = 0;
	promotion_detail: string = "";
	billingt_email: string = "";
	billing_fullname: string = "";
	billing_phone: string = "";
	billing_address: string = "";
	billing_ward: number = 0;
	billing_district: number = 0;
	billing_province: number = 0;
	billing_country: number = 0;
	billing_company: number = 0;
	shipping_fullname: string = "";
	shipping_phone: string = "";
	shipping_address: string = "";
	shipping_ward: number = 0;
	shipping_district: number = 0;
	shipping_province: number = 0;
	shipping_country: number = 0;
	shipping_company: number = 0;
	shipping_carrier: number = 0;
	note: string = "";
	note_invoice: Partial<NoteInvoiceJson> = {};
	deposit: number = 0;

	cancel_reason: number = 0;
	payment_method_json: string = "";
	status: number = 0;
	tag: string = "";
	ipaddress: number = 0;
	cod_amount: number = 0;
	order_detail: string = "";
	order_description: string = "";
	date_created: number = 0;
	date_modified: number = 0;
	date_completed: number = 0;
	date_arrived: number = 0;
	resource_type: number = 0;
	resource_id: number = 0;
	resource_number: string = "";
	resource_json: string = "";
	resource_date_created: number = 0;
	is_deleted: number = 0;
	date_deleted: number = 0;
	ecom_platform_type: number = 0;
	ecom_platform_order_id: number = 0;
	ecom_platform_invoice_id: number = 0;
	tax_ratio: number = 0;

	details: { total: number; data: CartOrderJson[] } = defaultData.details;
	promotions: CartPromotion[] = [];
	coupons: CartCouponAddJson[] = [];
	payments = defaultData.payments;
	price_discount_coupon: number = 0;
	payment_status: number = 0;
	service_type: number = 0;
	total_payment: number = 0;
	sale_channel: string = "";
	type: number = 0;
	debt: number = 0;
	delivery_status: number = 0;

	order_custom_discount: number = 0;
	order_custom_type: string = "amount";
	order_custom_value: number = 0;
	order_discount: number = 0;
	referral_id: number = 0;
	seller_id: number = 0;
	shipping_firstname: string = "";
	shipping_lastname: string = "";
	delivery_lits_id: string = "";
	has_invoice: number = 0;
	parent_id: number = 0;
	item_discount: number = 0;
	customer_credit_balance: boolean = false;

	inventory_status: OrderInventoryStatus = OrderInventoryStatus.NOT_SHIPPED;

	is_problem: ISPROBLEM = ISPROBLEM.NOT;
	note_problem: string = "";
	referral_name: string = "";
	using_cod: boolean = false;
	note_discout_custom: string = "";

	total_price_vat_item: number = 0;

	constructor(json: OrderJson) {
		super();

		if (this.checkTypesMatch(defaultData, json)) {
			Object.assign(this, { ...defaultData, ...json });
		} else {
			this.error = {
				errors: ["typeof_key_invalid"],
				statusCode: 406,
			};
		}
	}

	static getDefaultData(): OrderJson {
		return { ...defaultData };
	}

	toJson(): OrderJson {
		return JSON.parse(JSON.stringify({ ...this }));
	}

	private checkTypesMatch(defaultData: any, json: any) {
		for (const key in json) {
			if (json.hasOwnProperty(key) && defaultData.hasOwnProperty(key)) {
				if (typeof json[key] !== typeof defaultData[key]) {
					return false;
				}
				return true;
			}
			return true;
		}
	}

	static getStatusList(type?: number): SelectOption[] {
		const commonStatus: SelectOption[] = [
			{
				value: Order.STATUS_DRAFT,
				label: i18n.t("order:STATUS_DRAFT"),
				color: "yellow",
			},
			{
				value: Order.STATUS_PROCESSING,
				label: i18n.t("order:STATUS_OPEN"),
				color: "blue",
			},
			{
				value: Order.STATUS_COMPLETE,
				label: i18n.t("order:STATUS_COMPLETE"),
				color: "green",
			},
			{
				value: Order.STATUS_CANCEL,
				label: i18n.t("order:STATUS_CANCEL"),
				color: "red",
			},
		];

		// if (typeof type === "undefined") return [];

		if (type === Order.TYPE_INVOICE_ORDER) {
			return [
				...commonStatus,
				// {
				// 	value: Order.STATUS_PROCESSING,
				// 	label: i18n.t("order:STATUS_NEW"),
				// 	color: "blue",
				// },
				// {
				// 	value: Order.STATUS_CONFIRM,
				// 	label: i18n.t("order:STATUS_CONFIRM"),
				// 	color: "blue",
				// },
			];
		}
		return [
			...commonStatus,
			// {
			// 	value: Order.STATUS_CONFIRM,
			// 	label: i18n.t("Đã xác nhận"),
			// 	color: "yellow",
			// },
		];
	}

	static getStatusPaymentList(): SelectOption[] {
		return [
			{
				value: Order.STATUS_PAYMENT_SUCCESS,
				label: i18n.t("Thanh toán đủ"),
				color: "green",
			},
			{
				value: Order.STATUS_PAYMENT_RETURN_SUCCESS,
				label: i18n.t("Hoàn tiền đủ"),
				color: "blue",
			},
			{
				value: Order.STATUS_PAYMENT_NONE,
				label: i18n.t("thiếu/chưa thanh toán"),
				color: "yellow",
			},

			{
				value: Order.STATUS_PAYMENT__PARTIALLY_REFUNDED,
				label: i18n.t("chưa hoàn đủ tiền cho khách"),
				color: "gray",
			},
		];
	}

	static getStatusDeliveryList(): SelectOption[] {
		return [
			{
				value: OrderDeliveryStatus.STATUS_SUCCESS,
				label: i18n.t("Thành công"),
				color: "green",
			},
			{
				value: OrderDeliveryStatus.STATUS_NEW,
				label: i18n.t("Chờ xử lí"),
				color: "blue",
			},
			{
				value: OrderDeliveryStatus.STATUS_DELIVER,
				label: i18n.t("Đang giao hàng"),
				color: "yellow",
			},
			{
				value: OrderDeliveryStatus.STATUS_CANCEL,
				label: i18n.t("Hủy"),
				color: "red",
			},
		];
	}
	static getStatusInvenList(): SelectOption[] {
		// NOT_SHIPPED = 1,
		// SHIPPED,
		// PARTIALLY_RETURNED,
		// RETURNED_ENOUGH,
		// RETURNED_EQUAL,
		// SHIPPED_LESS_THAN_ORDERED,
		// SHIPPED_MORE_THAN_ORDERED,
		return [
			{
				value: OrderInventoryStatus.NOT_SHIPPED,
				label: i18n.t("order:inventoryreceipt.NOT_SHIPPED"),
				color: "blue",
			},
			{
				value: OrderInventoryStatus.SHIPPED,
				label: i18n.t("order:inventoryreceipt.SHIPPED"),
				color: "blue",
			},
			{
				value: OrderInventoryStatus.PARTIALLY_RETURNED,
				label: i18n.t("order:inventoryreceipt.PARTIALLY_RETURNED"),
				color: "yellow",
			},
			{
				value: OrderInventoryStatus.RETURNED_ENOUGH,
				label: i18n.t("order:inventoryreceipt.RETURNED_ENOUGH"),
				color: "red",
			},
			{
				value: OrderInventoryStatus.RETURNED_EQUAL,
				label: i18n.t("order:inventoryreceipt.RETURNED_EQUAL"),
				color: "green",
			},
			{
				value: OrderInventoryStatus.SHIPPED_LESS_THAN_ORDERED,
				label: i18n.t("order:inventoryreceipt.SHIPPED_LESS_THAN_ORDERED"),
				color: "yellow",
			},
			{
				value: OrderInventoryStatus.SHIPPED_MORE_THAN_ORDERED,
				label: i18n.t("order:inventoryreceipt.SHIPPED_MORE_THAN_ORDERED"),
				color: "yellow",
			},
		];
	}

	static getStatusDeliveryActionList(): SelectOption[] {
		return [
			{
				value: OrderDeliveryStatus.STATUS_NEW,
				label: i18n.t("Chờ xử lí"),
				color: "blue",
			},

			{
				value: OrderDeliveryStatus.STATUS_DELIVER,
				label: i18n.t("Đang giao hàng"),
				color: "yellow",
			},
			{
				value: OrderDeliveryStatus.STATUS_SUCCESS,
				label: i18n.t("Giao hàng Thành công"),
				color: "green",
			},
			{
				value: OrderDeliveryStatus.STATUS_COMPLETE,
				label: i18n.t("Hoàn thành"),
				color: "green",
			},
			{
				value: OrderDeliveryStatus.STATUS_CANCEL,
				label: i18n.t("Hủy"),
				color: "red",
			},
		];
	}
	static getShipperActionStatusList(): SelectOptionNormal[] {
		return [
			{
				value: Delivery.ACTION_CREATE,
				label: i18n.t("delivery:newstatus.ACTION_CREATE"),
			},
			{
				value: Delivery.ACTION_START,
				label: i18n.t("delivery:newstatus.ACTION_START"),
			},
			{
				value: Delivery.ACTION_PAUSE,
				label: i18n.t("delivery:newstatus.ACTION_PAUSE"),
			},
			{
				value: Delivery.ACTION_AGAIN,
				label: i18n.t("delivery:newstatus.ACTION_AGAIN"),
			},
			{
				value: Delivery.ACTION_ARRIVED,
				label: i18n.t("delivery:newstatus.ACTION_ARRIVED"),
			},
			{
				value: Delivery.ACTION_DELIVERED,
				label: i18n.t("delivery:newstatus.ACTION_DELIVERED"),
			},
			{
				value: Delivery.ACTION_COMPLETE,
				label: i18n.t("delivery:newstatus.ACTION_COMPLETE"),
			},
			{
				value: Delivery.ACTION_REFUND,
				label: i18n.t("delivery:newstatus.ACTION_REFUND"),
			},
		];
	}
	static getManagerActionStatusList(): SelectOptionNormal[] {
		return [
			{
				value: Delivery.ACTION_WAITING,
				label: i18n.t("delivery:newstatus.ACTION_WAITING"),
			},
			{
				value: Delivery.ACTION_APPROVE_REFUND,
				label: i18n.t("delivery:newstatus.ACTION_APPROVE_REFUND"),
			},
			{
				value: Delivery.ACTION_CANCEL,
				label: i18n.t("delivery:newstatus.ACTION_PAUSE"),
			},
		];
	}
	static getAccountantActionStatusList(): SelectOptionNormal[] {
		return [
			{
				value: Delivery.ACTION_APPROVE_TRANSFERS,
				label: i18n.t("delivery:newstatus.ACTION_APPROVE_TRANSFERS"),
			},
			{
				value: Delivery.ACTION_TIPS,
				label: i18n.t("delivery:newstatus.ACTION_APPROVE_TIPS"),
			},
			{
				value: Delivery.ACTION_APPROVE_RECEIVE_COD,
				label: i18n.t("delivery:newstatus.ACTION_APPROVE_RECEIVE_COD"),
			},
		];
	}
	static getTypeInvoiceList() {
		return [
			{
				value: Order.TYPE_INVOICE_ORDER,
				label: i18n.t("Đặt hàng"),
				color: "blue",
			},
			{
				value: Order.TYPE_INVOICE,
				label: i18n.t("Hóa đơn"),
				color: "yellow",
			},
			// {
			// 	value: -1,
			// 	label: i18n.t("Tất cả"),
			// },
		];
	}

	static getServiceTypeList() {
		return [
			{
				value: Order.SERVICE_TYPE_DELIVERY,
				label: i18n.t("Bán giao hàng"),
				color: "blue",
			},
			{
				value: Order.TYPE_INVOICE,
				label: i18n.t("Bán tại chỗ"),
				color: "blue-inverse",
			},
			// {
			// 	value: -1,
			// 	label: i18n.t("Tất cả"),
			// },
		];
	}

	static getSeviceType(val: number) {
		return this.getServiceTypeList().find((item) => item.value === val);
	}

	static getStatus(value: number, type?: number): SelectOption {
		const list = this.getStatusList(type);
		return (
			list.find((item) => item.value === value) || {
				label: "Chưa xác định",
				value: 0,
			}
		);
	}

	static getStatusPayment(value: number): SelectOption | undefined {
		return this.getStatusPaymentList().find((item) => item.value === value);
	}

	static getStatusDelivery(value: number): SelectOption | undefined {
		return this.getStatusDeliveryList().find((item) => item.value === value);
	}

	static getStatusInven(value: number): SelectOption | undefined {
		return this.getStatusInvenList().find((item) => item.value === value);
	}

	static getEcomPlatformTypeList(): SelectOption[] {
		return [
			{
				value: Order.ECOM_PLATFORM_TYPE_POS,
				label: i18n.t("order:ECOM_PLATFORM_TYPE_POS"),
			},
			{
				value: Order.ECOM_PLATFORM_TYPE_API,
				label: i18n.t("order:ECOM_PLATFORM_TYPE_API"),
			},
			{
				value: Order.ECOM_PLATFORM_TYPE_HARAVAN,
				label: i18n.t("order:ECOM_PLATFORM_TYPE_HARAVAN"),
			},
		];
	}

	static getEcomPlatformType(value: number): SelectOption | undefined {
		return this.getEcomPlatformTypeList().find((item) => item.value === value);
	}

	static getCancelReasonList(): SelectOption[] {
		return [
			{
				value: Order.CANCEL_REASON_OUT_OF_STOCK,
				label: i18n.t("order:CANCEL_REASON_OUT_OF_STOCK"),
			},
			{
				value: Order.CANCEL_REASON_CUSTOMER_DISCARD,
				label: i18n.t("order:CANCEL_REASON_CUSTOMER_DISCARD"),
			},
			{
				value: Order.CANCEL_REASON_CUSTOMER_DISCONNECT,
				label: i18n.t("order:CANCEL_REASON_CUSTOMER_DISCONNECT"),
			},
			{
				value: Order.CANCEL_REASON_CUSTOMER_NOT_PAID,
				label: i18n.t("order:CANCEL_REASON_CUSTOMER_NOT_PAID"),
			},
			{
				value: Order.CANCEL_REASON_DUPLICATE,
				label: i18n.t("order:CANCEL_REASON_DUPLICATE"),
			},
			{
				value: Order.CANCEL_REASON_INCORRECT_ITEM,
				label: i18n.t("order:CANCEL_REASON_INCORRECT_ITEM"),
			},
			{
				value: Order.CANCEL_REASON_HIGH_SHIPPING_FEE,
				label: i18n.t("order:CANCEL_REASON_HIGH_SHIPPING_FEE"),
			},
			{
				value: Order.CANCEL_REASON_DELIVERY_NOT_ONTIME,
				label: i18n.t("order:CANCEL_REASON_DELIVERY_NOT_ONTIME"),
			},
			{
				value: Order.CANCEL_REASON_CUSTOMER_NOT_SATISFY,
				label: i18n.t("order:CANCEL_REASON_CUSTOMER_NOT_SATISFY"),
			},
			{
				value: Order.CANCEL_REASON_SYSTEM_ERROR,
				label: i18n.t("order:CANCEL_REASON_SYSTEM_ERROR"),
			},
			{
				value: Order.CANCEL_REASON_PARTNER_ERROR,
				label: i18n.t("order:CANCEL_REASON_PARTNER_ERROR"),
			},
			{
				value: Order.CANCEL_REASON_OTHER,
				label: i18n.t("order:CANCEL_REASON_OTHER"),
			},
		];
	}

	static getCancelReason(value: number): SelectOption | undefined {
		return this.getCancelReasonList().find((item) => item.value === value);
	}

	static getTypeInvoice(value: number) {
		return this.getTypeInvoiceList().find((i) => i.value === value);
	}

	static getOrderPaymentMethod(method: string) {
		let namePayment = "";
		switch (method) {
			case "Voucher":
				namePayment = i18n.t("delivery:method.METHOD_GIFTCARD");
				break;
			case "transfer":
				namePayment = i18n.t("delivery:method.METHOD_BANK");
				break;
			case "vnpay":
				namePayment = i18n.t("delivery:method.METHOD_VNPAY");
				break;

			case "card":
				namePayment = i18n.t("delivery:method.METHOD_CARD");
				break;

			case "cash":
				namePayment = i18n.t("delivery:method.METHOD_CASH");
				break;
			case "cod":
				namePayment = i18n.t("delivery:method.METHOD_COD");
				break;
			case "cash on delivery":
				namePayment = i18n.t("delivery:method.METHOD_CASH_ON_DELIVERY");
				break;
			case "credit card/debit card":
				namePayment = i18n.t("delivery:method.METHOD_CREDIT_DEBIT_CARD");
				break;
			case "spaylater":
				namePayment = i18n.t("delivery:method.METHOD_SPAYLATER");
				break;

			default:
				break;
		}
		return namePayment;
	}

	static getDefaultDataDetails(): CartOrderJson {
		return {
			order_id: 0,
			product_id: 0,
			id: 0,
			is_use: IsUse.USE,
			item_title: "",
			item_name: "",
			item_quantity: 0,
			item_unit_price_original: 0,
			item_unit_price: 0,
			item_date_deleted: 0,
			item_vat: 0,
			price_vat: 0,
			discount_percent: 0,
			// promotion_id: 0, // Removed as it's commented out
			promotions: [], // Assuming it's an array of CartPromotion
			price_final: 0,
			price_discount: 0,
			product_json: ProductModel.getDefaultData(),
			inventory_status: OrderInventoryStatus.NOT_SHIPPED,
			delivery_status: -1,
		};
	}
}

export default OrderModel;
