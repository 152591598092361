import {
	Button,
	Col,
	Divider,
	Empty,
	Form,
	Input,
	Row,
	Select,
	Space,
	Typography,
} from "antd";
import { ProductInventoryAttachments } from "common/types/ProductInventoryChecking";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import InventoryReceiptModel from "common/models/InventoryReceiptModel";
import AttachmentSearch from "../components/AttachmentSearch";
import IventoryReceiptSearch from "../components/InventoryReceiptSearch";
import SearchComponent from "components/search/SearchLayout";
import InventoryReceipt from "common/constants/InventoryReceipt";
import InventoryReceiptRepository from "common/repositories/InventoryReceiptRepository";
import {
	InventoryReceiptFilter,
	InventoryReceiptJson,
} from "common/types/InventoryResceipt";
import InventoryReceiptCollection from "common/collections/InventoryReceiptCollection";
import { FormInstance } from "antd";

const { Option } = Select;

const ProductInventoryCheckingFormAttachments = ({
	form,
	files,
	isEditing = true,
	isAllowEdit,
}: {
	form?: FormInstance;
	files: ProductInventoryAttachments[];
	isEditing: boolean;
	isAllowEdit: boolean;
}) => {
	const { t } = useTranslation();

	const [type, setType] = useState<null | string>(null);
	const [fieldTypes, setFieldTypes] = useState(() =>
		files.map((item, index) => item.type)
	);

	const handleTypeChange = (index: number, value: any) => {
		const newTypes = [...fieldTypes];
		newTypes[index] = value;
		setFieldTypes(newTypes);
	};
	// if (!files.length) {
	//   return <p>Không có chứng từ</p>;

	// }

	const renderField = useCallback(
		(field: any, type: any) => {
			switch (type) {
				case "inventory_receipt":
					return (
						<Col flex={1}>
							<SearchComponent<
								InventoryReceiptJson,
								InventoryReceiptFilter,
								InventoryReceiptCollection
							>
								fetchRepository={(filers) => {
									return new InventoryReceiptRepository().getItems({
										filters: filers,
									});
								}}
								defaultFilters={InventoryReceiptRepository.getDefaultFilters()}
								formItemProps={{
									...field,

									name: [field.name, "id"],
									rules: [
										{
											required: true,
											message: t("common:form.required"),
										},
									],
								}}
								disabled={!isAllowEdit}
								keyValue="id"
								showFilters={["keyword", "code"]}
								defaultUseFilters={["keyword"]}
								renderLabel={(item) => {
									return (
										<Typography.Text
											className={`text-${
												InventoryReceiptModel.getDirectionLabel(item.direction)
													.color
											}-500`}>
											{item.direction === InventoryReceipt.DIRECTION_INPUT
												? "Phiếu nhập"
												: "Phiếu xuất"}{" "}
											- {item.code}
										</Typography.Text>
									);
								}}
							/>
							{/* <IventoryReceiptSearch
							disabled={!isAllowEdit}
							{...field}
							name={[field.name, "id"]}
							rules={[{ required: true, message: t("common:form.required") }]}>
							{" "}
						</IventoryReceiptSearch> */}
						</Col>
					);
				case "doc":
					return (
						<Col flex={1}>
							<AttachmentSearch
								form={form}
								parentName="attachments"
								formItemProps={{
									...field,
									name: [field.name, "id"],
									rules: [
										{
											required: true,
											message: t("common:form.required"),
										},
									],
								}}
								disabled={!isAllowEdit}
							/>
						</Col>
					);
				default:
					return null;
			}
		},
		[isAllowEdit, fieldTypes]
	);

	return (
		<Form.Item label={t("Chứng từ")} className="mb-0" name="attachments">
			<Form.List name="attachments" initialValue={files}>
				{(fields, { add, remove }) => (
					<Row gutter={4} className="bg-gray-100 py-4">
						{fields.map((field, index) => (
							<Col span={24}>
								<Row gutter={4}>
									<Col>
										<Form.Item
											{...field}
											name={[field.name, "type"]}
											rules={[
												{ required: true, message: t("common:form.required") },
											]}>
											<Select
												placeholder={t("Loại")}
												disabled={!isAllowEdit}
												style={{ width: 200 }}
												onChange={(value) => handleTypeChange(index, value)}>
												<Option value={"inventory_receipt"}>
													{t("inventoryreceipt:attachment.inventory_receipt")}
												</Option>
												<Option value="doc">
													{t("inventoryreceipt:attachment.file")}
												</Option>
											</Select>
										</Form.Item>
									</Col>
									{renderField(field, fieldTypes[index])}
									<Col>
										<Form.Item
											{...field}
											name={[field.name, "receipt_type"]}
											rules={[
												{ required: true, message: t("common:form.required") },
											]}>
											<Select
												placeholder={t("Loại")}
												disabled={!isAllowEdit}
												style={{ width: 200 }}
												options={InventoryReceiptModel.getTypeList()}
											/>
										</Form.Item>
									</Col>

									{isAllowEdit && (
										<Col>
											<Button onClick={() => remove(field.name)}>
												<MinusCircleOutlined />
											</Button>
										</Col>
									)}
								</Row>

								{/* <Form.Item
                  {...field}
                  name={[field.name, "receipt_type"]}
                >
                  <Input
                    placeholder={t("common:receiptType")}
                    // disabled={!isEditing}
                  />
                </Form.Item> */}
								{/* <Form.Item
									{...field}
									name={[field.name, "id"]}
									rules={[{ required: true, message: t("common:required") }]}>
									<Input placeholder={t("id")} disabled={!isAllowEdit} />
								</Form.Item> */}
							</Col>
						))}
						<Col span={24}>
							<Form.Item>
								<Button
									disabled={!isAllowEdit}
									type="dashed"
									onClick={() => add()}
									icon={<PlusOutlined />}>
									{t("Thêm chứng từ")}
								</Button>
							</Form.Item>
						</Col>
					</Row>
				)}
			</Form.List>
		</Form.Item>
	);
};

export default ProductInventoryCheckingFormAttachments;
