import ProductApi from "common/api/ProductApi";
import ProductCollection from "common/collections/ProductCollection";
import ProductModel from "common/models/ProductModel";
import { GetListProps } from "common/types/Filter";
import { ProductCategoryJson } from "common/types/ProductCategory";

import BaseRepository from "./BaseRepository";

import type {
	ProductJsonAddEdit,
	FilterProduct,
	ProductJsonAddEdit2,
	ProductRelatedFilter,
	ProductJsonEdit2,
} from "common/types/Product";
import ProductPublicCollection from "common/collections/ProductPublicCollection";
import ProductPublicModel from "common/models/ProductPublicModel";
class ProductRepository extends BaseRepository<ProductApi> {
	_api: ProductApi | null;

	constructor() {
		super();
		this._api = new ProductApi(true);
	}

	static getDefaultFilters(): FilterProduct {
		return {
			page: 1,
			limit: 20,
			sortby: "created_at",
			sorttype: "DESC",
			sort_by: "updated_at",
			sort_type: "DESC",
			status: 1,
			keyword: "",
			id: -1,
			category_id: -1,
			supplier_id: -1,
			list_product_id: "",
			store_id: -1,
			warehouse_id: -1,
			collection_id: -1,
			tags: undefined,
			max_quantity: -1,
			min_quantity: -1,
		};
	}

	async getItems(
		props: GetListProps<FilterProduct>,
		categoryItems: ProductCategoryJson[] = []
	) {
		return this._api
			? await this._api.getList(props, categoryItems)
			: new ProductCollection();
	}

	async getItemsPublic(
		props: GetListProps<FilterProduct>,
		categoryItems?: ProductCategoryJson[]
	) {
		return this._api
			? await this._api.getListPublic(props, categoryItems)
			: new ProductPublicCollection();
	}

	async getItem(id: number, store_id: number): Promise<ProductModel> {
		return this._api
			? await this._api.getDetail(id, store_id)
			: new ProductModel(ProductModel.getDefaultData());
	}

	async deleteItem(id: number): Promise<string[]> {
		return this._api ? await this._api.delete(id) : [];
	}

	async saveRemote(data: ProductJsonAddEdit2): Promise<ProductModel> {
		if (this._api) {
			if (data.id > 0) {
				return await this._api.edit(data);
			} else {
				return await this._api.add(data);
			}
		} else {
			return new ProductModel(ProductModel.getDefaultData());
		}
	}

	async editRemote(id: number, data: ProductJsonEdit2): Promise<ProductModel> {
		if (this._api) {
			return await this._api.edit({ ...data, id: id });
		} else {
			return new ProductModel(ProductModel.getDefaultData());
		}
	}

	async resyncCompanyResource(): Promise<string[]> {
		return this._api ? await this._api.resyncCompanyResource() : [];
	}

	async getVariants(props: ProductRelatedFilter): Promise<ProductCollection> {
		return this._api
			? await this._api.getVariants(props)
			: new ProductCollection();
	}

	async getGroupStruct(id: number): Promise<any> {
		return this._api ? await this._api.getGroupStruct(id) : [];
	}

	async syncProductKv(id: number) {
		return this._api ? await this._api.syncProductKiotviet(id) : [];
	}

	async getDataSampleProduct(id: number | string) {
		return this._api ? await this._api.getDataSampleByStore(id) : [];
	}
}

export default ProductRepository;
