import { AutoComplete, Form, FormItemProps, Input, Space, Tag } from "antd";
import SupplierRepository from "common/repositories/SupplierRepository";
import { SelectOption } from "common/types/SelectOption";
import React, { useCallback, useEffect, useState } from "react";
import { SelectValue } from "antd/es/select";
import FormSelect, { FormSelectProps } from "components/form/FormSelect";

type Props = FormItemProps &
	Omit<FormSelectProps, "options"> & {
		disabled?: boolean;
		onChangeSelect?: (v: SelectValue) => void;
		className?: string;
		placeholder?: string;
	};
export default function SupplierSearchSelect(props: Props) {
	const { onChangeSelect, className, placeholder, disabled } = props;
	const [errors, setErrors] = useState<string[]>([]);
	const [loading, setLoading] = useState(true);
	const [options, setOptions] = useState<SelectOption[]>([]);
	const [keyword, setKeyword] = useState("");

	const fetchData = useCallback(async () => {
		setLoading(true);
		const collection = await new SupplierRepository().getItems({
			filters: { ...SupplierRepository.getDefaultFilters(), keyword },
		});
		if (collection.hasError()) {
			setErrors(collection.error.errors);
		} else {
			setOptions(
				collection.items.map((item) => ({
					value: item.id,
					label: item.name,
				}))
			);
		}
		setLoading(false);
	}, [keyword]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);
	return (
		<>
			{/* <Form.Item {...props}>
				<AutoComplete
					
					disabled={props.disabled}
					options={options}
					style={{ width: 300 }}
					onSearch={(text) => setKeyword(text)}>
					<Input.Search placeholder="nhập từ khóa" />
				</AutoComplete>
			</Form.Item> */}

			<FormSelect
				{...props}
				autoFocus
				allowClear={true}
				loading={loading}
				placeholder={placeholder}
				showSearch={options.length > 6}
				onChange={onChangeSelect}
				className={className}
				errorRetry={fetchData}
				options={options}
				optionRender={(option: any) => <span>{option.label}</span>}
				errors={errors}
			/>
		</>
	);
}
