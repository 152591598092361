import { Alert, Button, Flex, Form, Tag } from "antd";
import Order from "common/constants/Order";
import Role from "common/constants/Role";
import OrderModel from "common/models/OrderModel";
import OrderRepository from "common/repositories/OrderRepository";
import { OrderDetailJson } from "common/types/OrderDetail";
import LayoutForm from "components/form/LayoutForm";
import RoleCheck from "components/RoleCheck";
import SimpleCollapse from "components/SimpleCollapse";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useEditingSaleOrderStore from "zustands/useEditingSaleOrder";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import OrderFormHeader from "./OrderFormHeader";
import OrderFormSectionEcomPlatform from "./section/OrderFormSectionEcomPlatform";
import OrderFormSectionGeneral from "./section/OrderFormSectionGeneral";
import OrderFormSectionInfo from "./section/OrderFormSectionInfo";
import OrderFormSectionStatus from "./section/OrderFormSectionStatus";
import OrderFormSectionTaxInvoice from "./section/OrderFormSectionTaxInvoice";
import OrderFormSectionCashflowReceipt from "./section/shipping/OrderFormSectionCashflowReceipt";
import OrderFormSectionProductReceipt from "./section/shipping/OrderFormSectionProductReceipt";
import OrderFormSectionShipping from "./section/shipping/OrderFormSectionShipping";

import {
	ISPROBLEM,
	SERVICE_TYPE,
	type OrderJsonEdit,
} from "common/types/Order";
import OrderDetailListForm from "./section/orderdetail/OrderDetailListForm";
import Helper from "common/utils/helper";
import OrderPrintBill from "./OrderPrintBill";
import OrderFormSectionNoteTaxInvoice from "./section/OrderFormSectionNoteTaxInvoice";
import useActionsOrder from "hooks/useActionsOrder";
import useMessage from "antd/es/message/useMessage";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import OrderFormSectionHistory from "./section/OrderFormSectionHistory";
import { CartOrderJson } from "common/types/Cart";
import useOrderStore from "zustands/useOrderStore";
import { SyncOutlined } from "@ant-design/icons";
import { debounce } from "lodash";

const OrderForm = () => {
	const { t } = useTranslation();
	const [message, contextMessage] = useMessage();
	const hasRole = useLoginAccountStore((state) => state.hasRole);

	const [form] = Form.useForm();
	const order = useEditingSaleOrderStore((state) => state.order);
	const setOrder = useEditingSaleOrderStore((state) => state.setOrder);
	const [initStatus, setInitStatus] = useState(order.status);
	const [status, setStatus] = useState(initStatus);
	const [errors, setErrors] = useState<string[]>([]);
	const [isSuccess, setIsSuccess] = useState(false);
	const [isConfirmCashflow, setIsConfirmCashflow] = useState(false);
	const [loadinFetch, setLoadingFetch] = useState(false);

	const [refetch, setRefetch] = useState({
		inventory: false,
		cashflows: false,
	});

	const {
		allowEditInput,
		allowStatusCancel,
		allowStatusComplete,
		enableSubmitButton,
		allowStatusCompleteSelect,
	} = useActionsOrder({ order });

	const allow = true;

	const [details, setDetails] = useState<CartOrderJson[]>([]);

	const initialValues = useMemo(() => {
		return {
			shipping_full_name: order.shipping_fullname,
			shipping_address: order.shipping_address,
			shipping_phone: order.shipping_phone,
			shipping_regions: {
				shipping_province: order.shipping_province,
				shipping_district: order.shipping_district,
				shipping_ward: order.shipping_ward,
			},

			price_sell: order.price_sell,
			price_discount: order.price_discount,
			price_discount_coupon: order.price_discount_coupon,

			price_shipping: order.price_shipping,
			price_final: order.price_final,

			cancel_reason: order.cancel_reason > 0 ? order.cancel_reason : undefined,
			note: order.note,
			tag: order.tag,
			details: order.details,
			customer_credit_balance: order.customer_credit_balance,
			is_problem: order.is_problem,
			note_problem: order.note_problem,
			check_debt_cashflow: true,
		};
	}, [order]);

	//prepare data for submit
	const doPrepareData = useCallback(
		(formData: any) => {
			try {
				const submitData: Partial<OrderJsonEdit> = {
					id: order.id,
					invoice_id: order.id,
					// shipping
					shipping_full_name: formData.shipping_full_name || "",
					shipping_phone: formData.shipping_phone || "",
					shipping_address: formData.shipping_address || "",
					shipping_region_id: formData.shipping_regions?.region_id || "",
					shipping_sub_region_id:
						formData.shipping_regions?.sub_region_id || "",
					shipping_sub_sub_region_id:
						formData.shipping_regions?.sub_sub_region_id || "",
					shipping_district: formData.shipping_regions?.shipping_district || "",
					shipping_ward: formData.shipping_regions?.shipping_ward || "",
					shipping_province: formData.shipping_regions?.shipping_province || "",
					status: status,
					cancel_reason: formData.cancel_reason || "",
					origin: formData.origin || "",
					tag: formData.tag || "",
					note: formData.note || "",
					price_sell: formData.price_sell || 0,
					price_discount: formData.price_discount || 0,
					tax_ratio: formData.tax_ratio || 0,
					price_tax: formData.price_tax || 0,
					price_shipping: formData.price_shipping || 0,
					price_final: formData.price_final || 0,
					price_cod: formData.price_cod || 0,
					customer_credit_balance: formData.customer_credit_balance || false,
					note_invoice: {
						note_cancel: formData.note_cancle || "", // Corrected typo from note_cancle to note_cancel
					},
					// details: formData.details,
					check_debt_cashflow: formData.check_debt_cashflow,
					is_problem: formData.is_problem ? ISPROBLEM.IS : ISPROBLEM.NOT,
					note_problem: formData?.note_problem || "",
				};

				return submitData;
			} catch (error) {
				console.error("Error while preparing submit data:", error);
				return {};
			}
		},
		[order.id, status, isConfirmCashflow]
	);

	const fetchDetail = useCallback(async () => {
		setRefetch((prev) => ({
			cashflows: !prev.cashflows,
			inventory: !prev.inventory,
		}));
		if (order.id) {
			let fetchedModel = await new OrderRepository().getItem(+order.id);
			if (!fetchedModel.hasError()) {
				setOrder(fetchedModel.toJson());
			}
		}
	}, [order.id]);

	const refetchSyncOrder = debounce(async () => {
		setLoadingFetch(true);
		message.loading("Đang kiểm tra thông tin");
		const res = await new OrderRepository().fetchOrder(order.id);
		await fetchDetail();
		message.destroy();
		if (res.length <= 0) {
			message.success("Tải lại thông tin thành công");
		} else {
			message.error("Tải lại thông tin thất bại");
		}

		setLoadingFetch(false);
	}, 200);

	//submit data to server
	const onSubmit = useCallback(
		async (formData: any) => {
			setIsSuccess(false);

			let item: OrderModel = await new OrderRepository().saveRemote(
				doPrepareData(formData)
			);

			if (item.hasError()) {
				setErrors(item.error.errors);
				const errors = item.error.errors;
			} else {
				setOrder(item.toJson());
				setInitStatus(item.status);
				setIsSuccess(true);
			}
		},
		[doPrepareData]
	);
	let sidebarItems = (
		<div className=" -my-6 mb-2">
			<Flex gap={8} className="py-4">
				<Button
					onClick={refetchSyncOrder}
					icon={<SyncOutlined spin={loadinFetch}></SyncOutlined>}>
					Cập nhật đơn
				</Button>

				<OrderPrintBill order={order} />
			</Flex>

			<OrderFormSectionStatus
				allowEditInput={allow || allowEditInput}
				allowStatusComplete={allow || allowStatusCompleteSelect}
				allowStatusCancel={allow || allowStatusCancel}
				status={status}
				setStatus={setStatus}
				initStatus={initStatus}
				type={order.type}
				isSuccess={isSuccess}
			/>
			<OrderFormSectionInfo />
			<div className="pt-4">
				<OrderFormSectionGeneral
					order={order}
					form={form}
					allowEditInput={allow || allowEditInput}
				/>
			</div>
		</div>
	);

	/////////////////////////////////////

	return (
		<>
			<RoleCheck
				roles={[Role.ORDER_VIEW, Role.SELF_ORDER_VIEW, Role.CROSS_ORDER_VIEW]}
				componentOnFail={
					<Alert
						className="mt-4"
						type="warning"
						showIcon
						message={t("order:form.not_view_permission")}></Alert>
				}>
				{contextMessage}
				<OrderFormHeader
					isEditing={Helper.checkEditOrder(order.id)}
					id={order.code}
					type={order.type}
				/>
				<LayoutForm
					form={form}
					initialValues={initialValues}
					errors={errors}
					isSuccess={isSuccess}
					scrollToFirstError={{
						behavior: "smooth",
						block: "start",
					}}
					successTitle={
						Helper.checkEditOrder(order.id)
							? t("order:form.success.update")
							: t("order:form.success.add")
					}
					error_translate_prefix="order:form.error"
					onSubmit={onSubmit}
					submitText={
						Helper.checkEditOrder(order.id)
							? t("common:form.save")
							: t("common:form.submit")
					}
					submitDisabled={!allow && !enableSubmitButton}
					sidebarItems={sidebarItems}
					id="productreceipt-form"
					redirectOnSuccess={Helper.checkEditOrder(order.id) ? "" : "/order"}>
					{order.type === Order.TYPE_INVOICE_ORDER && order.invoice_id > 0 && (
						<Alert
							className="mb-4"
							message={
								<>
									Đơn hàng đặt hàng đã có phát sinh
									<Link
										target="_blank"
										to={
											order.type === Order.TYPE_INVOICE_ORDER &&
											order.invoice_id > 0
												? "/invoice" + `/edit/id/${order.invoice_id}`
												: "/order" + `/edit/id/${order.invoice_id}`
										}
										className=" ml-1 uppercase font-semibold">
										hóa đơn
									</Link>
								</>
							}
							type="info"
							showIcon
						/>
					)}
					{order.type === Order.TYPE_INVOICE && order.parent_id > 0 && (
						<Alert
							className="mb-4"
							message={
								<>
									Hóa đơn bắt nguồn từ
									<Link
										target="_blank"
										to={`/order/edit/id/${order.parent_id}`}
										className=" ml-1 uppercase font-semibold">
										đơn hàng
									</Link>
								</>
							}
							type="info"
							showIcon
						/>
					)}

					{order.service_type === SERVICE_TYPE.PURTCHASE_METHOD_IN_STORE ? (
						<></>
					) : (
						<OrderFormSectionShipping
							form={form}
							allowEditInput={allow || allowEditInput}
							refetchOrder={() => fetchDetail()}
						/>
					)}

					<SimpleCollapse
						header={t("order:form.section_detail")}
						defaultOpen={true}
						childrenItem={
							<OrderDetailListForm
								model={order}
								setDetails={setDetails}
								form={form}
								allowEditInput={allowEditInput}
							/>
						}
					/>
					<RoleCheck
						roles={[]}
						componentOnFail={
							<Alert
								className="mt-4"
								type="warning"
								showIcon
								message={t(
									"order:form.cashflow_receipt_not_view_permission"
								)}></Alert>
						}>
						<OrderFormSectionCashflowReceipt
							status={status}
							isSuccess={isSuccess}
							order={order}
							refetchOrder={() => fetchDetail()}
							onConfirmCashFlow={(value) => {
								setIsConfirmCashflow(value);
							}}
						/>
					</RoleCheck>
					<RoleCheck
						roles={[]}
						componentOnFail={
							<Alert
								className="mt-4"
								type="warning"
								showIcon
								message={t(
									"order:form.product_receipt_not_view_permission"
								)}></Alert>
						}>
						<OrderFormSectionProductReceipt
							status={status}
							isSuccess={isSuccess}
							order={order}
							form={form}
							allowEditInput={allow || allowEditInput}
							refetchOrder={() => fetchDetail()}
						/>
					</RoleCheck>

					<RoleCheck
						roles={[]}
						componentOnFail={
							<Alert
								className="mt-4"
								type="warning"
								showIcon
								message={t(
									"order:form.tax_invoice_not_view_permission"
								)}></Alert>
						}>
						<OrderFormSectionNoteTaxInvoice order={order} form={form} />
					</RoleCheck>
					<RoleCheck
						roles={[]}
						componentOnFail={
							<Alert
								className="mt-4"
								type="warning"
								showIcon
								message={t("order:form.history_not_view_permission")}></Alert>
						}>
						<OrderFormSectionHistory order={order}></OrderFormSectionHistory>
					</RoleCheck>

					<OrderFormSectionEcomPlatform />
				</LayoutForm>
			</RoleCheck>
		</>
	);
};

export default OrderForm;
