import BaseApi from "./BaseApi";
import AxiosClient from "./AxiosClient";

import {
  FilterStory,
  FilterStoryDetail,
  PublicationsStatus,
  StoryJsonAddEdit,
} from "common/types/Story";
import Helper from "common/utils/helper";
import StoryModel from "common/models/StoryModel";
import { GetListProps } from "common/types/Filter";
import StoryCollection from "common/collections/StoryCollection";

const SERVICE_URL = "/publications";

class PublicationsApi extends BaseApi {
  //
  async getList(props: GetListProps<FilterStory>): Promise<StoryCollection> {
    let collection = new StoryCollection();
    const convertProps = Helper.convertParams(props.filters);
    const queryData = {
      params: {
        ...convertProps,
      },
    };

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }
  //
  async getItem(id: number | string): Promise<StoryModel> {
    let item = new StoryModel(StoryModel.getDefaultData());

    try {
      const response = await this.axios.get<any>(SERVICE_URL + "/" + id);
      if (response.hasOwnProperty("data")) {
        item = new StoryModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }

  async add(props: Partial<StoryJsonAddEdit>): Promise<StoryModel> {
    let item = new StoryModel(StoryModel.getDefaultData());

    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps, true);

    try {
      let postData = {
        ...convertProps,
      };

      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new StoryModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }
  //
  async edit(props: Partial<StoryJsonAddEdit>): Promise<StoryModel> {
    let item = new StoryModel(StoryModel.getDefaultData());
    // remove id key
    ///////////////
    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps, true);
    try {
      const postData = {
        ...convertProps,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + props.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new StoryModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async updateStatus(props: {id : number ,status :PublicationsStatus }): Promise<StoryModel> {
    let item = new StoryModel(StoryModel.getDefaultData());
    // remove id key
    ///////////////
    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps, true);
    try {
      const postData = {
        ...convertProps,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/updatestatus/" + props.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new StoryModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
  //
  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
      if (response.status !== 200) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }
}

export default PublicationsApi;
