import {
	IconBan,
	IconBarcode,
	IconFolder,
	IconSearch,
} from "@tabler/icons-react";
import { Button, Col, Popover, Row, Select, Spin } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import ProductPublicModel from "common/models/ProductPublicModel";
import { FilterProduct, ProductJsonPublic } from "common/types/Product";
import useProductWarehouse from "hooks/useProductWarehouse";
import debounce from "lodash/debounce";
import { KeyboardEvent, UIEvent, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
	disabled?: boolean;
	disableWidget?: boolean;
	placeholder?: string;
	size?: SizeType;
	onSelect: (productVariant: ProductJsonPublic) => void;
	loading?: boolean;
	popoverWidthClassName?: string;
	filterBySupplierId?: number;
	filter?: Partial<FilterProduct>;
	className?: string;
};

const ProductWarehouseSearch = ({
	disabled,
	placeholder,
	size,
	onSelect,
	disableWidget,
	loading,
	popoverWidthClassName,
	filterBySupplierId,
	filter,
	className,
}: Props) => {
	const { t } = useTranslation();
	const [keyword, setKeyword] = useState("");
	const containerCategoryRef = useRef<any>(null);
	const lastKeywordRef = useRef("");
	const recordPerPage = 10;
	const currentPageRef = useRef(1);

	const doSearch = useCallback((query: string) => {
		setKeyword(query.trim());
	}, []);

	const { data: dataProductWarehouse, isLoading: isLoadingProductWarehouse } =
		useProductWarehouse({
			keyword: keyword,
			warehouseId: filter?.warehouse_id,
		});

	const doSelect = useCallback(
		(productVariantId: number) => {
			if (typeof onSelect !== "undefined" && productVariantId > 0) {
				const item = dataProductWarehouse?.find(
					(item) => item.id === productVariantId
				);
				if (item) {
					onSelect({ ...ProductPublicModel.getDefaultData(), ...item });
				}
			}
		},
		[dataProductWarehouse, onSelect]
	);

	const handlePopupScroll = useCallback(
		(e: UIEvent<HTMLDivElement>) => {
			const target = e.target;
			if (
				(target as any).scrollTop + (target as any).offsetHeight ===
				(target as any).scrollHeight
			) {
				if (
					dataProductWarehouse &&
					dataProductWarehouse.length % recordPerPage === 0
				) {
					currentPageRef.current++;
					doSearch(lastKeywordRef.current);
				}
			}
		},
		[dataProductWarehouse, doSearch]
	);

	const handleInputKeydown = useCallback(
		(e: KeyboardEvent<HTMLInputElement>) => {
			if (e.key === "Enter") {
				doSearch(e.currentTarget.value);
			}
		},
		[doSearch]
	);

	const handleFocus = useCallback(() => {
		doSearch("");
	}, [doSearch]);

	return (
		<Spin spinning={isLoadingProductWarehouse} className={className}>
			<Row className="">
				<Col flex={"auto"}>
					<Select
						showArrow={true}
						suffixIcon={<IconSearch size={18} color="#dddddd" />}
						bordered={true}
						loading={loading}
						disabled={disabled}
						autoClearSearchValue
						placeholder={placeholder ?? t("productvariant:search_placeholder")}
						onInputKeyDown={handleInputKeydown}
						size={size}
						style={{ width: "100%" }}
						showSearch
						value={null}
						onSearch={debounce((value) => doSearch(value), 500)}
						filterOption={false}
						dropdownRender={(menu) => <>{menu}</>}
						onSelect={(selectedId) => {
							doSelect(selectedId ?? 0);
						}}
						onFocus={handleFocus}
						onPopupScroll={handlePopupScroll}
						notFoundContent={
							<>
								{keyword.length > 0 ||
								typeof filterBySupplierId === "number" ? (
									<>
										<IconBan size={24} className="-mt-0.5 mr-1" />
										{t("productvariant:search_notfound_empty")}
									</>
								) : (
									<>
										<IconBarcode size={24} className="-mt-0.5 mr-1" />
										{t("productvariant:search_notfound_empty_keyword")}
									</>
								)}
							</>
						}>
						{dataProductWarehouse?.map((item, index) => (
							<Select.Option key={item.id} value={item.id}>
								<span className="text-right inline-block text-gray-400 text-xs w-5">
									{index + 1}.
								</span>{" "}
								{item.name} |{" "}
								<span className="font-bold">{item.option_name}</span> -{" "}
								<span className="font-bold">{item.sku}</span> -{" "}
							</Select.Option>
						))}
					</Select>
				</Col>
				{typeof disableWidget === "undefined" && !disableWidget ? (
					<Col
						flex={"40px"}
						className="text-right rounded-tr-sm rounded-br-sm bg-gray-200">
						<div>
							<Popover
								open={
									typeof disabled !== "undefined" && disabled
										? false
										: undefined
								}
								placement="bottomRight"
								trigger="click"
								overlayClassName={
									(popoverWidthClassName || "w-11/12") +
									" ant-popover-inner-nopadding"
								}
								getPopupContainer={() => containerCategoryRef.current}>
								<Button
									disabled={disabled}
									className=""
									shape="default"
									style={{ width: 40 }}
									type="text"
									size={size}
									icon={
										<IconFolder className="-mt-0.5 text-gray-400 hover:text-blue-400" />
									}
								/>
							</Popover>
						</div>
					</Col>
				) : null}
			</Row>

			<div className="" ref={containerCategoryRef}></div>
		</Spin>
	);
};

export default ProductWarehouseSearch;
