import {
	Button,
	Col,
	DatePicker,
	Form,
	Input,
	InputNumber,
	Modal,
	Row,
} from "antd";
import ProductCollectionModel from "common/models/ProductCollectionModel";
import StoryCollectionModel from "common/models/StoryCollectionModel";
import { SelectOptionNormal } from "common/types/SelectOption";
import Helper from "common/utils/helper";
import FormSelectNormal from "components/form/FormSelectNormal";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ShopSectionAboutEdit from "../about/ShopSectionAboutEdit";
import ShopSectionBannerEdit from "../banner/ShopSectionBannerEdit";
import ProductCollectionItem from "../collection/ProductCollectionItem";
import { ShopSectionJson } from "../list/ShopCollectionList";
import StoryCollectionItem from "../nmagazine/StoryCollectionItem";
import { FileJson } from "common/types/File";
import CollectionParamDetailForm from "../collection/CollectionParamDetailForm";

export type SectionBannerNNNJson = {
	id: number;
	title: string;
	image_desktop: string;
	image_mobile: string;
	alt: string;
	width: number;
	height: number;
	link: string;
};

export default function ShopCollectionForm({
	data,
	shopCollectionOptions,
	open,
	type = "add",
	onFinish,
	onCancel,
}: {
	type: "add" | "edit";
	data: ShopSectionJson;
	shopCollectionOptions: SelectOptionNormal[];
	open: boolean;
	onFinish: (data: ShopSectionJson) => void;
	onCancel: () => void;
}) {
	const { t } = useTranslation();
	const [form] = Form.useForm<ShopSectionJson>();

	//state
	const [typeSelect, setTypeSelect] = useState<string>(data.type);
	const [loading, setLoading] = useState<boolean>(false);

	//new
	const [selectedProductCollectionItem, setSelectedProductCollectionItem] =
		useState<ProductCollectionModel>(
			new ProductCollectionModel({
				...ProductCollectionModel.getDefaultData(),
			})
		);
	const [selectedStoryCollectionItem, setSelectedStoryCollectionItem] =
		useState<StoryCollectionModel>(
			new StoryCollectionModel({
				...StoryCollectionModel.getDefaultData(),
			})
		);
	// const [chooseItem, setChooseItem] = useState

	const initialValues = {
		handle: data.handle.length > 0 ? data.handle : "",
		type: data.type.length > 0 ? data.type : "",
		collection_id:
			data.type === "collection"
				? Number(data?.dataSource?.params?.collection_id || 0)
				: "",
		story_collection_id: data.type === "nmagazine" ? Number(data.id) : "",
		from_time: data.from_time > 0 ? dayjs(new Date(data.from_time * 1000)) : 0,
		to_time: data.to_time > 0 ? dayjs(new Date(data.to_time * 1000)) : 0,
		title: data.title.length ? data.title : "",
		title_more: data.title_more.length > 0 ? data.title_more : "",
		link: data.link.length > 0 ? data.link : "",
		link_more: data.link_more.length > 0 ? data.link_more : "",
		form_params: data.dataSource?.params,
		dataSource: data.dataSource,
		//   data.dataSource &&
		//   data.dataSource.params &&
		//   Object.keys(data.dataSource?.params).length > 0
		//     ? Object.keys(data.dataSource.params).map((item) => {
		//         return { name: item, value: data.dataSource?.params[item] };
		//       })
		//     : [],
	};

	const doPrepareData = useCallback(
		(formData: any) => {
			// console.log("🚀 ~ formData:", formData);
			if (typeSelect === "collection") {
				let newParams: any = {};
				for (let i = 0; i < formData.form_params.length; i++) {
					newParams[formData.form_params[i].name] =
						Number(formData.form_params[i].value) > 0
							? Number(formData.form_params[i].value)
							: formData.form_params[i].value;
				}

				return {
					...data,
					id: selectedProductCollectionItem.id,
					handle:
						selectedProductCollectionItem.handle.length > 0
							? selectedProductCollectionItem.handle
							: data.handle,
					type: "collection",
					from_time:
						Helper.datePickerValueToTimestamp(formData.from_time) > 0
							? Helper.datePickerValueToTimestamp(formData.from_time)
							: 0,
					to_time:
						Helper.datePickerValueToTimestamp(formData.to_time) > 0
							? Helper.datePickerValueToTimestamp(formData.to_time)
							: 0,
					title: formData.title ? formData.title : "",
					title_more: formData.title_more ? formData.title_more : "",
					link: formData.link ? formData.link : "",
					link_more: formData.link_more ? formData.link_more : "",
					dataSource: {
						...formData.dataSource,
						params: Object.assign(
							formData.dataSource.params,
							formData.form_params
						),
					},
				};
			} else if (typeSelect === "nmagazine") {
				let newParams: any = {};
				for (let i = 0; i < formData.form_params.length; i++) {
					newParams[formData.form_params[i].name] =
						Number(formData.form_params[i].value) > 0
							? Number(formData.form_params[i].value)
							: formData.form_params[i].value;
				}

				return {
					...data,
					id: selectedStoryCollectionItem.id,
					handle:
						selectedStoryCollectionItem.handle.length > 0
							? selectedStoryCollectionItem.handle
							: data.handle,
					type: "nmagazine",
					title: formData.title ? formData.title : "",
					title_more: formData.title_more ? formData.title_more : "",
					link: formData.link ? formData.link : "",
					link_more: formData.link_more ? formData.link_more : "",
				};
			} else if (typeSelect === "section_about") {
				return {
					...data,
					id: 0,
					title: formData.title ? formData.title : "",
				};
			} else {
				return {};
			}
		},
		[
			data,
			typeSelect,
			selectedProductCollectionItem,
			selectedStoryCollectionItem,
		]
	);

	const onSubmit = async (formData: any) => {
		setLoading(true);
		onFinish({
			...data,
			...doPrepareData(formData),
		});
		setLoading(false);
	};

	// console.log(initialValues);

	const renderFormItem = () => {
		if (typeSelect === "collection") {
			return (
				<>
					<Row gutter={[16, 16]}>
						<Col md={12} xs={12}>
							<ProductCollectionItem
								existValue={Number(initialValues.collection_id)}
								changeDataProductCollection={changeDataProductCollection}
							/>
						</Col>
						<Col md={12}>
							<Form.Item hidden name={"dataSource"}>
								<Input></Input>
							</Form.Item>
							<Form.Item
								rules={[
									{
										required: true,
										message: "Vui lòng nhập",
									},
								]}
								name={["form_params", "limit"]}
								label="Số lượng sản phẩm hiển thị">
								<InputNumber max={60} />
							</Form.Item>
						</Col>
						<Col md={12}>
							<Form.Item name="title" label="Tiêu đề">
								<Input />
							</Form.Item>
						</Col>
						<Col md={12}>
							<Form.Item name="title_more" label="Tiêu đề nút xem thêm">
								<Input />
							</Form.Item>
						</Col>
						<Col md={12}>
							<Form.Item name="link" label="Link tiêu đề">
								<Input />
							</Form.Item>
						</Col>
						<Col md={12}>
							<Form.Item name="link_more" label="Link xem thêm">
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={[16, 16]}>
						<Col md={12} xs={24}>
							<Form.Item label={"Thời gian hiển thị từ"} name="from_time">
								<DatePicker
									style={{ width: "100%" }}
									placeholder={"HH:mm dd/mm/yyyy"}
									format="HH:mm DD/MM/YYYY"
									showTime
								/>
							</Form.Item>
						</Col>
						<Col md={12} xs={24}>
							<Form.Item
								label={"Thời gian hiển thị đến"}
								name="to_time"
								rules={[
									{
										message: t("Thời gian không hợp lệ"),
										validator: (_: any, value: any) => {
											if (
												Helper.datePickerValueToTimestamp(
													form.getFieldValue("from_time")
												) < Helper.datePickerValueToTimestamp(value) ||
												!Helper.datePickerValueToTimestamp(value)
											) {
												return Promise.resolve();
											} else {
												return Promise.reject("Some message here");
											}
										},
									},
								]}>
								<DatePicker
									style={{ width: "100%" }}
									placeholder={"HH:mm dd/mm/yyyy"}
									format="HH:mm DD/MM/YYYY"
									showTime
								/>
							</Form.Item>
						</Col>
						{/* 
						<Col md={24}>
							<CollectionParamDetailForm name="form_params" />
						</Col> */}
					</Row>
				</>
			);
		} else if (typeSelect === "section_banner") {
			return (
				<>
					<Row gutter={[16, 16]}>
						<Col md={24}>
							<ShopSectionBannerEdit isEditing={true} />
						</Col>
					</Row>
				</>
			);
		} else if (typeSelect === "section_about") {
			return (
				<>
					<Row gutter={[16, 16]}>
						<Col md={24}>
							<Form.Item name="title" label="Tiêu đề">
								<Input />
							</Form.Item>
						</Col>
						<Col md={24}>
							<ShopSectionAboutEdit
								isEditing={data.handle.length > 0}
								model={data}
							/>
						</Col>
					</Row>
				</>
			);
		} else if (typeSelect === "nmagazine") {
			return (
				<>
					<Row gutter={[16, 16]}>
						<Col md={24} xs={24}>
							<StoryCollectionItem
								existValue={Number(data.id)}
								changeDataStoryCollection={changeDataStoryCollection}
							/>
						</Col>

						<Col md={12}>
							<Form.Item name="title" label="Tiêu đề">
								<Input />
							</Form.Item>
						</Col>
						<Col md={12}>
							<Form.Item name="title_more" label="Tiêu đề nút xem thêm">
								<Input />
							</Form.Item>
						</Col>
						<Col md={12}>
							<Form.Item name="link" label="Link tiêu đề">
								<Input />
							</Form.Item>
						</Col>
						<Col md={12}>
							<Form.Item name="link_more" label="Link xem thêm">
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={[16, 16]}>
						<Col md={12} xs={24}>
							<Form.Item label={"Thời gian hiển thị từ"} name="from_time">
								<DatePicker
									style={{ width: "100%" }}
									placeholder={"HH:mm dd/mm/yyyy"}
									format="HH:mm DD/MM/YYYY"
									showTime
								/>
							</Form.Item>
						</Col>
						<Col md={12} xs={24}>
							<Form.Item
								label={"Thời gian hiển thị đến"}
								name="to_time"
								rules={[
									{
										message: t("Thời gian không hợp lệ"),
										validator: (_: any, value: any) => {
											if (
												Helper.datePickerValueToTimestamp(
													form.getFieldValue("from_time")
												) < Helper.datePickerValueToTimestamp(value) ||
												!Helper.datePickerValueToTimestamp(value)
											) {
												return Promise.resolve();
											} else {
												return Promise.reject("Some message here");
											}
										},
									},
								]}>
								<DatePicker
									style={{ width: "100%" }}
									placeholder={"HH:mm dd/mm/yyyy"}
									format="HH:mm DD/MM/YYYY"
									showTime
								/>
							</Form.Item>
						</Col>

						<Col md={24}>
							<CollectionParamDetailForm name="form_params" />
						</Col>
					</Row>
				</>
			);
		} else if (typeSelect === "namperfumetv") {
			return <></>;
		} else {
			return (
				<>
					<Row gutter={[16, 16]}>
						<Col md={24}>Vui lòng chọn loại</Col>
					</Row>
				</>
			);
		}
	};

	const changeDataProductCollection = (
		newProductCollection: ProductCollectionModel
	) => {
		setSelectedProductCollectionItem(newProductCollection);
	};

	const changeDataStoryCollection = (
		newProductCollection: StoryCollectionModel
	) => {
		setSelectedStoryCollectionItem(newProductCollection);
	};

	useEffect(() => {
		form.setFieldsValue({
			...(initialValues as any),
		});
	}, [data]);

	useEffect(() => {
		setTypeSelect(data.type);
	}, [data]);

	const beforeClose = () => {
		form.resetFields();
		// setTypeSelect("");
		setSelectedProductCollectionItem(
			new ProductCollectionModel(ProductCollectionModel.getDefaultData())
		);
		setSelectedStoryCollectionItem(
			new StoryCollectionModel(StoryCollectionModel.getDefaultData())
		);
	};

	return (
		<Modal
			title={type === "edit" ? `Cập nhật ${data.title}` : "Thêm mới"}
			open={open}
			footer={<></>}
			onCancel={() => {
				beforeClose();
				onCancel();
			}}
			maskClosable={false}
			destroyOnClose
			width={900}
			bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}>
			<>
				<Form
					form={form}
					layout="vertical"
					initialValues={initialValues}
					onFinish={onSubmit}
					autoComplete="off">
					<Row gutter={[16, 16]}>
						<Col md={24} xs={24}>
							<FormSelectNormal
								name="type"
								disabled={
									data.handle.length > 0 || typeSelect === "namperfumetv"
								}
								rules={[{ required: true, message: "Vui lòng chọn" }]}
								label="Loại"
								options={shopCollectionOptions.filter(
									(item) => item.value !== "namperfumetv"
								)}
								onChange={(value) => {
									if (value) {
										setTypeSelect(value.toString());
									}
								}}
							/>
						</Col>
					</Row>
					{renderFormItem()}

					{!["section_banner", "namperfumetv"].includes(typeSelect) && (
						<Form.Item className="mt-4">
							<div className="flex gap-4">
								<Button
									type="primary"
									htmlType="submit"
									loading={loading}
									disabled={loading}>
									Hoàn tất
								</Button>
								<Button
									type="default"
									onClick={() => {
										beforeClose();
										onCancel();
									}}>
									Đóng
								</Button>
							</div>
						</Form.Item>
					)}
				</Form>
			</>
		</Modal>
	);
}
