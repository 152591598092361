import { App, Col, Form, Input, Row } from "antd";
import Error from "components/LayoutError";
import FormChangedContext from "contexts/FormChangedContext";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useFormDefaultStore from "zustands/useFormDefaultStore";

import TagModel from "common/models/TagModel";
import TagRepository from "common/repositories/TagRepository";
import { TagAddEditJson } from "common/types/Tag";
import FormSelect from "components/form/FormSelect";
import TagTypeFormSelect from "./TagTypeFormSelect";
import TagConstant from "common/constants/Tag";
import TagTypeModel from "common/models/TagTypeModel";
import TagTypeRepository from "common/repositories/TagTypeRepository";
import TagTypeFormSelectNormal from "./TagTypeFormSelectNormal";
const TagForm = ({
  model,
  setOpen,
  setProcessing,
  onSaveSuccess,
}: {
  model: TagModel;
  setOpen: (v: boolean) => any;
  setProcessing: (p: boolean) => any;
  onSaveSuccess: (o: TagModel) => void;
}) => {
  console.log(model);
  const { t } = useTranslation();
  const { message } = App.useApp();
  const formChangedProvider = useContext(FormChangedContext);

  const [getValue, setValue] = useFormDefaultStore((state) => [
    state.getValue,
    state.setValue,
  ]);

  const [type, setType] = useState<number>(model.type_id);
  const [tagTypeResourceType, setTagTypeResourceType] = useState<number>(
    model.resource_type
  );
  const [selectProductId, setSelectProductId] = useState<number>(
    model.resource_id
  );
  console.log("🥺 ~ selectProductId:", selectProductId);

  const [form] = Form.useForm();
  const initialValues = useMemo(() => {
    return {
      name: model.name,
      code: model.code.length > 0 ? model.code : "",
      status: model.status > 0 ? model.status : 1,
      type: model.type_id > 0 ? model.type_id : "",
      value: model.value.length > 0 ? model.value : "",
      resoucre_type: model.id > 0 ? model.resource_type : 1,
    };
  }, [model, getValue]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: TagAddEditJson = {
        ...TagModel.getDefaultData(),
        id: model.id,
        code: formData.code.length > 0 ? formData.code.trim() : "",
        name: formData.name.length > 0 ? formData.name.trim() : "",
        status: formData.status,
        value:
          formData.value.length > 0 ? formData.value : formData.name,
        type: type,
        resource_type: tagTypeResourceType,
        resource_id:
          type === TagConstant.TYPE.PRODUCT_STRUCT ? selectProductId : -1,
      };

      return submitData;
    },
    [model.id, model.code, setValue, type, selectProductId, tagTypeResourceType]
  );

  // const fetchTagTypeDetail = async (id: number): Promise<TagTypeModel> => {
  //   const item = await new TagTypeRepository().getItem(id);
  //   if (!item.hasError()) {
  //     setTagTypeDetail(item);
  //   }
  //   return item;
  // };

  //submit data to server
  const onSubmit = async (formData: any) => {
    console.log("🥺 ~ onSubmit ~ formData:", formData)
    console.log("🥺 ~ onSubmit ~ formData:", doPrepareData(formData));
    setProcessing(true);
    message.loading({
      content: t("common:form.processing"),
      key: "message",
      duration: 0,
    });

    // const response = await fetchTagTypeDetail(type);
    if (true) {
      let item: TagModel = await new TagRepository().saveRemote(
        doPrepareData(formData)
      );

      setProcessing(false);
      if (item.hasError()) {
        message.error({
          content: (
            <Error
              onClickClose={() => {
                message.destroy("message");
              }}
              heading={t("common:form.error.heading")}
              translate_prefix="tag:form.error"
              items={item.error.errors}
            />
          ),
          className: "message_error",
          key: "message",
          duration: 4,
        });
      } else {
        message.success({
          content: t("common:form.success.save"),
          className: "message_success",
          key: "message",
          duration: 2,
        });
        onSaveSuccess(item);
        setOpen(false);
      }
    } else {
      // message.error({
      //   content: (
      //     <Error
      //       onClickClose={() => {
      //         message.destroy("message");
      //       }}
      //       heading={t("common:form.error.heading")}
      //       translate_prefix="tag:form.error"
      //       items={response.error.errors}
      //     />
      //   ),
      //   className: "message_error",
      //   key: "message",
      //   duration: 4,
      // });
    }
  };

  // useEffect(() => {
  //   if (model.type_id > 0) {
  //     fetchTagTypeDetail(model.type_id);
  //   }
  // }, [model]);

  return (
    <Form
      layout="vertical"
      initialValues={initialValues}
      form={form}
      onFinish={onSubmit}
      id={formChangedProvider.id}
      onFieldsChange={() => formChangedProvider.setChanged(true)}
    >
      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item label={t("tag:code")} name="code" className="mb-0">
            <Input placeholder={t("tag:form.code_placeholder")} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item
            label={t("tag:name")}
            name="name"
            rules={[
              {
                required: true,
                message: t("tag:form.error.error_name_required"),
              },
            ]}
            className="mb-0"
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item
            label={t("tag:value")}
            name="value"
            className="mb-0"
            rules={[
              {
                required: true,
                message: t("tag:form.error.error_value_required"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col md={24} xs={24}>
          <FormSelect
            label={t("tag:status")}
            name="status"
            placeholder={"Trạng thái"}
            options={TagModel.getStatusSelectList()}
            popupMatchSelectWidth={false}
          />
        </Col>
      </Row>
      <Row>
        <Col md={24} xs={24}>
          <Form.Item
            label="Loại tag"
            name="type"
            rules={[
              {
                required: true,
                message: t("tag:form.error.error_type_required"),
              },
            ]}
          >
            <TagTypeFormSelectNormal
              placeholder="Chọn loại tag"
              values={model.type_id > 0 ? [model.type_id] : undefined}
              onChange={(v: any) => {
                console.log("🥺 ~ v:", v);
                if (v.length > 0) {
                  setType(v[0].value);
                  setTagTypeResourceType(v[0].resource_type);
                }
              }}
              isMulti={false}
            />
          </Form.Item>
        </Col>
      </Row>
      {/* {type === TagConstant.TYPE.PRODUCT_STRUCT && (
        <>
          <Row>
            <Col md={24} xs={24}>
              <Form.Item
                label="Chọn sản phẩm"
                name="resource_id"
                rules={[
                  {
                    required: true,
                    message: t("tag:form.error.error_type_required"),
                  },
                ]}
              >
                <ProductFormSelect
                  placeholder="Chọn sản phẩm"
                  values={
                    model.resource_id > 0 ? [model.resource_id] : undefined
                  }
                  onChange={(v) => {
                    if (v.length > 0) {
                      setSelectProductId(v[0]);
                    }
                  }}
                  isMulti={false}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )} */}
      {/* <Row gutter={16}>
        <Col md={24} xs={24}>
          <FormSelect
            label={"Dùng cho"}
            name="resoucre_type"
            options={TagModel.getResouceTypeList()}
            popupMatchSelectWidth={false}
          />
        </Col>
      </Row> */}
    </Form>
  );
};

export default TagForm;
