import ShipperKPIList from "features/shipperkpi/list/ShipperKPIList";
import usePathParams from "hooks/usePathParams";

const ShipperKPIPage = (): JSX.Element => {
  const [pathParams] = usePathParams();

  let com = <ShipperKPIList />;
  switch (pathParams.action) {
    default:
      com = <ShipperKPIList />;
  }

  return com;
};

export default ShipperKPIPage;
