import PageDataTable from "components/page/PageDataTable";
import React, {
	ChangeEvent,
	FC,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import PromotionCouponModel from "common/models/PromotionCouponModel";
import {
	FilterPromotionCoupon,
	PromotionCouponCodeStatus,
	PromotionCouponJsonAddEdit,
	PromotionCouponJsonAddImport,
	PromotionCouponUpdate,
} from "common/types/PromotionCoupon";
import PromotionCouponRepository from "common/repositories/PromotionCouponRepository";
import useStateFilter from "hooks/useStateFilter";
import PageDataPagination from "components/page/PageDataPagination";
import {
	Button,
	Checkbox,
	Dropdown,
	Input,
	message,
	Modal,
	notification,
	Popconfirm,
	Progress,
	TableColumnsType,
	Tag,
	Typography,
} from "antd";
import PromotionCouponCollection from "common/collections/PromotionCouponCollection";
import usePathParams from "hooks/usePathParams";
import RoleCheck from "components/RoleCheck";
import Role from "common/constants/Role";
import CouponListHeader from "./CouponListHeader";
import TableDelete from "components/table/TableDelete";
import PageHeaderButton from "components/page/PageHeaderButton";
import { useTranslation } from "react-i18next";
import CouponForm from "../form/CouponForm";
import {
	DownOutlined,
	ImportOutlined,
	MoreOutlined,
	SyncOutlined,
} from "@ant-design/icons";
import TableInfo from "components/table/TableInfo";
import { MenuProps } from "antd/lib";
import FormModal from "components/form/FormModal";
import PromotionModel from "common/models/PromotionModel";
import PromotionRepository from "common/repositories/PromotionRepository";
import ExcelReaderCodes from "./ExcelReaderCodes";
import { chunk } from "lodash";
import Error from "common/api/Error";
import Helper from "common/utils/helper";
import CrmFeed from "common/constants/CrmFeed";
import Coupon from "common/constants/Coupon";

type Props = {
	promotionId?: number;
};

type ErrorCodes = PromotionCouponJsonAddEdit & { errors: Error };
export default function CouponList({ promotionId }: Props) {
	const [pathParams] = usePathParams();
	const { t } = useTranslation();
	const [messageApi, context] = message.useMessage();
	const id = promotionId ?? pathParams.id ?? 0;
	const [isLoadingImport, setIsLoadingImport] = useState(false);
	const [errorsImport, setErrorsImport] = useState<ErrorCodes[]>([]);
	const [openErrorImport, setOpenErrorImport] = useState(false);
	const [percent, setPercent] = useState(0);

	// const [itemsSelect, setItemSelect] = useState<PromotionCouponModel[]>([])
	const [itemsSelectPage, setItemSelectPage] = useState<
		{ page: number; items: PromotionCouponModel[] }[]
	>([]);
	const itemsSelect = itemsSelectPage.flatMap((item) => item.items);

	const [promotion, setPromotion] = useState(
		new PromotionModel(PromotionModel.getDefaultData())
	);

	const defaultFilters: FilterPromotionCoupon = useMemo(
		() => ({
			...PromotionCouponRepository.getDefaultFilters(),
			promotion_id: Number(id),
		}),
		[pathParams.id]
	);
	const [filters, setFilters] =
		useStateFilter<FilterPromotionCoupon>(defaultFilters);

	const [openAdd, setOpenAdd] = useState(false);
	const [openImport, setOpenImport] = useState(false);
	const [codeEdit, setCodeEdit] = useState<PromotionCouponModel | null>(null);
	// useFilterLocation(defaultFilters, filters);
	const [openEditNumApp, setOpenEditNumApp] = useState(false);

	const [inputValue, setInputValue] = useState(0);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(+e.target.value);
	};

	//////////////////////////////////////////
	//Fetch data from this collections
	const [total, setTotal] = useState(0);
	const [dataSource, setDataSource] = useState<PromotionCouponModel[]>([]);
	const fetchData =
		useCallback(async (): Promise<PromotionCouponCollection> => {
			let collection = await new PromotionCouponRepository().getItems({
				filters,
			});
			setDataSource(collection.items);
			setTotal(collection.total);

			return collection;
		}, [filters]);

	const fetchDataPromotion = useCallback(async (): Promise<PromotionModel> => {
		let collection = await new PromotionRepository().getItem(+id);
		setPromotion(collection);
		return collection;
	}, [id]);

	const onDelete = async (id: number) => {
		setDataSource((prev) => prev.filter((i) => i.id !== id));
		// const res = await new PromotionCouponRepository().deleteItem(id);
		// if (!res.length > 0) {
		// 	messageApi.success("Xóa thành công");
		// } else {
		// 	messageApi.warning("Xóa thất bại");
		// }
	};
	const onSelect = (checked: boolean, record: PromotionCouponModel) => {
		const page = +filters.page;
		if (checked) {
			setItemSelectPage((pre) => {
				const newData = [...pre];
				const pageActive = newData.findIndex((item) => item.page === page);

				pageActive >= 0
					? newData[pageActive].items.push(record)
					: newData.push({ page, items: [record] });

				return newData;
			});
		} else {
			setItemSelectPage((pre) => {
				const newData = [...pre];
				const pageActive = newData.findIndex((item) => item.page === page);
				if (pageActive >= 0) {
					const updatedItems = newData[pageActive].items.filter(
						(item) => item.id != record.id
					);
					if (updatedItems.length > 0) {
						newData[pageActive].items = updatedItems;
					} else {
						newData.splice(pageActive, 1);
					}
				}
				return newData;
			});
		}
	};

	function checkIsValidItemsUpdate(
		action:
			| "release"
			| "stop_release"
			| "max_app"
			| "hidden"
			| "delete"
			| "setpublic"
			| "setprivate"
	) {
		const codesUsed = itemsSelect.filter((item) => item.used_count);
		const handleAction = () => {
			switch (action) {
				case "release":
					handleUpdateStatusCodes(PromotionCouponCodeStatus.RELEASED);
					break;
				case "stop_release":
					handleUpdateStatusCodes(PromotionCouponCodeStatus.STOP_RELEASED);
					break;
				case "hidden":
					handleUpdateStatusCodes(PromotionCouponCodeStatus.HIDDEN);
					break;
				case "max_app":
					handleUpdateNumnerUseCodes(inputValue);
					break;
				case "delete":
					handleDeleteCodes();
					break;

				case "setpublic":
					handleScopeCodes(1);
					break;

				case "setprivate":
					handleScopeCodes(3);
			}
		};

		if (codesUsed.length) {
			Modal.confirm({
				title:
					"Có các mã đã được sử dụng nên không thể thao tác bạn có muốn loại bỏ để tiếp tục không",
				content: (
					<div>
						<ul>
							{codesUsed.map((code) => {
								return <li>{code.code}</li>;
							})}
						</ul>
					</div>
				),
				onOk: handleAction,
			});
			return;
		}

		handleAction();
	}

	async function handleUpdateStatusCodes(status: number) {
		const codesUsed = itemsSelect.filter((item) => !item.used_count);
		if (!codesUsed.length) {
			notification.error({ message: "Danh sách trống" });

			return;
		}
		const data: PromotionCouponUpdate = {
			is_error: true,
			data: codesUsed.map((item) => ({ id: item.id, status: status })),
		};
		const res = await new PromotionCouponRepository().updateCodes(data);

		if (!res.hasError()) {
			notification.success({ message: "Thành công" });
			fetchData();
			setItemSelectPage([]);
		}
	}

	async function handleUpdateNumnerUseCodes(num: number) {
		const codesUsed = itemsSelect.filter((item) => !item.used_count);

		if (!codesUsed.length) {
			notification.error({ message: "Danh sách trống" });

			return;
		}
		const data: PromotionCouponUpdate = {
			is_error: true,
			data: codesUsed.map((item) => ({
				id: item.id,
				max_count_apply: num ? num : 0,
				status: item.status,
				code: item.code,
			})),
		};
		const res = await new PromotionCouponRepository().updateCodes(data);
		if (!res.hasError()) {
			notification.success({ message: "Thành công" });
			fetchData();
			setItemSelectPage([]);
		}
	}

	async function handleDeleteCodes() {
		const codesUsed = itemsSelect.filter((item) => !item.used_count);

		if (!codesUsed.length) {
			notification.error({ message: "Danh sách trống" });
			return;
		}

		try {
			const promises = codesUsed.map((code) =>
				new PromotionCouponRepository().deleteItem(code.id)
			);

			await Promise.all(promises);

			notification.success({ message: "Thành công" });
			fetchData();
			setItemSelectPage([]);
		} catch (error) {
			notification.error({ message: "Có lỗi xảy ra khi xóa mã" });
			console.error(error);
		}
	}

	async function handleScopeCodes(value: 1 | 3) {
		const codesUsed = itemsSelect.filter((item) => !item.used_count);
		if (!codesUsed.length) {
			notification.error({ message: "Danh sách trống" });

			return;
		}
		const data: PromotionCouponUpdate = {
			is_error: true,
			data: codesUsed.map((item) => ({
				id: item.id,
				code: item.code,
				coupon_scope: value,
			})),
		};
		const res = await new PromotionCouponRepository().updateCodes(data);
		if (!res.hasError()) {
			notification.success({ message: "Thành công" });
			fetchData();
			setItemSelectPage([]);
		}
	}

	function handleOpenInput() {
		setOpenEditNumApp(true);
	}

	function groupCodesByCountApply(codes: PromotionCouponJsonAddImport[]) {
		return codes.reduce((pre: { [x: string]: string[] }, proCode) => {
			const max_count_apply = proCode.max_count_apply || 0;
			if (new Object(pre).hasOwnProperty(max_count_apply)) {
				pre[max_count_apply] = [...pre[max_count_apply], proCode.code.trim()];
			} else {
				pre[max_count_apply] = [proCode.code.trim()];
			}
			return pre;
		}, {});
	}

	async function addCodesToPromotion(body: PromotionCouponJsonAddEdit) {
		const res = await new PromotionCouponRepository().saveRemote(body);
		if (res.hasError()) {
			return { ...body, errors: res.error };
		}
		return true;
	}

	async function handleImportCodes(
		codes: PromotionCouponJsonAddImport[],
		overwrite: boolean,
		stopIfError: boolean
	) {
		try {
			setOpenImport(false);
			setIsLoadingImport(true);
			const MAX_SIZE_UPLOAD = 200;
			const errors: ErrorCodes[] = [];
			const groupCodes = groupCodesByCountApply(codes);
			const percentItem = 100 / Object.keys(groupCodes).length;
			let index = 0;
			for (const key in groupCodes) {
				if (Object.prototype.hasOwnProperty.call(groupCodes, key)) {
					const codes = groupCodes[key];
					const max_count_apply = +key;

					if (!codes.length) {
						index++;
						continue;
					}
					if (codes.length <= MAX_SIZE_UPLOAD) {
						const res = await addCodesToPromotion({
							codes,
							id: 0,
							promotion_id: +id,
							stop_on_error: stopIfError,
							max_count_apply,
						});
						if (typeof res !== "boolean") {
							errors.push(res);
						}
					} else {
						const chunkCodesLimit = chunk(codes, MAX_SIZE_UPLOAD);
						for await (const codesLimit of chunkCodesLimit) {
							const res = await addCodesToPromotion({
								codes: codesLimit,
								id: 0,
								promotion_id: +id,
								stop_on_error: stopIfError,
								max_count_apply,
							});
							if (typeof res !== "boolean") {
								errors.push(res);
							}
							await Helper.delay(1000);
						}
					}
					index++;
					setPercent(Math.floor(percentItem * index));
					// await Helper.delay(3000)
				}
			}

			if (errors.length) {
				setOpenImport(false);
				setErrorsImport(errors);
				setOpenErrorImport(true);
			} else {
				notification.success({ message: "Nhập mã thành công" });
				fetchData();
				setOpenImport(false);
			}
		} catch (error) {
			notification.error({ message: "Thất bại vui lòng thử lại" });
		} finally {
			setIsLoadingImport(false);
			setPercent(0);
		}
	}

	useEffect(() => {
		fetchDataPromotion();
	}, [fetchDataPromotion]);

	const columns: TableColumnsType<PromotionCouponModel> = [
		{
			title: dataSource.length ? (
				<div className=" flex items-center gap-2">
					<span>Chọn toàn bộ</span>
					<Checkbox
						checked={itemsSelectPage.some(
							(pageItem) =>
								pageItem.page == filters.page &&
								pageItem.items.length === dataSource.length
						)}
						onChange={(e) => {
							const checked = e.target.checked;
							if (checked) {
								// Chọn toàn bộ mục trong trang hiện tại
								dataSource.forEach((item) => onSelect(true, item));
							} else {
								// Bỏ chọn toàn bộ mục trong trang hiện tại
								dataSource.forEach((item) => onSelect(false, item));
							}
						}}
					/>
				</div>
			) : (
				""
			),
			// title: "",
			key: "id",
			width: 140,

			render: (id, record) => {
				return (
					<>
						<Checkbox
							checked={itemsSelect.some((item) => item.id === record.id)}
							onChange={(e) => {
								onSelect(e.target.checked, record);
							}}
						/>
					</>
				);
			},
		},

		{
			title: "Mã code",
			key: "code",
			render: (code, record) => {
				return (
					<Tag color="blue">
						<Typography.Text copyable>{code}</Typography.Text>
					</Tag>
				);
			},
		},

		{
			title: "Số lần dùng",
			key: "used_count",
			width: 120,

			render: (count_usage, record) => {
				return <>{count_usage}</>;
			},
		},
		{
			title: t("promotion:promotioncoupon.max_count_apply"),
			dataIndex: "max_count_apply",
			key: "max_count_apply",
			width: 180,
			render: (max_count_apply: number) => {
				if (max_count_apply >= CrmFeed.INFINITY) {
					return <>{t("promotion:promotioncoupon.unlimit")}</>;
				}
				return <>{max_count_apply}</>;
			},
		},

		{
			title: "Scope",
			key: "coupon_scope",
			width: 120,

			render: (coupon_scope, record) => {
				return (
					<>
						{coupon_scope === Coupon.COUPON_SCOPE_PUBLIC ? "Public" : "Private"}
					</>
				);
			},
		},

		{
			title: "Tình trạng",
			key: "status",
			render: (_, record) => {
				const status = record.status;
				let color = "default";
				let value = "";
				switch (status) {
					case 1:
						value = "Đã phát hành";
						color = "green";
						break;
					case 2:
						value = "Chưa phát hành";
						color = "blue";

						break;
					case 3:
						value = "Ẩn";
						color = "warning";

						break;
					case 4:
						color = "gray";

						value = "Đã sử dụng";

						break;
					default:
						break;
				}
				return <Tag color={color}>{value}</Tag>;
			},
		},
		{
			title: " ",
			key: "actions",
			align: "right",
			fixed: "right",
			width: 150,
			render: (_: any, record: PromotionCouponModel) => {
				const items: MenuProps["items"] = [
					{
						key: "1",
						label: <></>,
					},
				];

				return (
					<RoleCheck roles={[Role.PROMOTION_MANAGE]} hideOnFail>
						<div className="flex justify-end">
							{/* <TableEdit link=""></TableEdit> */}
							{record.used_count ? null : (
								<TableDelete
									onDeleteCallback={onDelete}
									id={record.id}
									repository={new PromotionCouponRepository()}></TableDelete>
							)}

							<TableInfo record={record} className="mr-4" />
						</div>
					</RoleCheck>
				);
			},
		},
	];

	const items: MenuProps["items"] = [
		{
			label: (
				<Popconfirm
					title="Phát hành mã"
					description="bạn muốn phát hành mã đã chọn?"
					onConfirm={() => {
						checkIsValidItemsUpdate("release");
					}}
					okText="Xác nhận"
					cancelText="Đóng">
					<Button type="primary" className=" w-full">
						Phát hành
					</Button>
				</Popconfirm>
			),
			key: "0",
		},

		{
			label: (
				<Popconfirm
					title="Dừng phát hành mã"
					description="bạn muốn dừng phát hành mã đã chọn?"
					onConfirm={() => {
						checkIsValidItemsUpdate("stop_release");
					}}
					okText="Xác nhận"
					cancelText="Đóng">
					<Button danger type="primary" className=" w-full">
						Dừng phát hành
					</Button>
				</Popconfirm>
			),
			key: "1",
		},

		{
			label: (
				<Popconfirm
					title="Ẩn mã"
					description="bạn muốn ẩn mã đã chọn?"
					onConfirm={() => {
						checkIsValidItemsUpdate("hidden");
					}}
					okText="Xác nhận"
					cancelText="Đóng">
					<Button danger type="primary" className=" w-full">
						Ẩn mã
					</Button>
				</Popconfirm>
			),
			key: "2",
		},
		{
			label: (
				<Popconfirm
					title="Xóa mã"
					description="bạn muốn xóa các mã đã chọn?"
					onConfirm={() => {
						checkIsValidItemsUpdate("delete");
					}}
					okText="Xác nhận"
					cancelText="Đóng">
					<Button danger type="primary" className=" w-full">
						Xóa mã
					</Button>
				</Popconfirm>
			),
			key: "2",
		},

		{
			label: (
				<Popconfirm
					title="Public mã"
					description="Bạn muốn Public mã đã chọn"
					onConfirm={() => {
						checkIsValidItemsUpdate("setpublic");
					}}
					okText="Xác nhận"
					cancelText="Đóng">
					<Button type="primary" className=" w-full">
						Public
					</Button>
				</Popconfirm>
			),
			key: "5",
		},
		{
			label: (
				<Popconfirm
					title="Private mã"
					description="Bạn muốn Private mã đã chọn"
					onConfirm={() => {
						checkIsValidItemsUpdate("setprivate");
					}}
					okText="Xác nhận"
					cancelText="Đóng">
					<Button type="primary" className=" w-full">
						Private
					</Button>
				</Popconfirm>
			),
			key: "6",
		},

		{
			label: (
				<Button type="primary" className=" w-full" onClick={handleOpenInput}>
					Cập nhập số lần dùng
				</Button>
			),
			key: "3",
		},
	];

	return (
		<div>
			{context}
			<CouponListHeader>
				<RoleCheck roles={[]}>
					<div className=" flex gap-4">
						{/* <PageHeaderButton
							type="dashed"
							link=""
							icon={<SyncOutlined />}
							onClick={() => {
								fetchData()
							}}>
							{t("Đồng bộ")}
						</PageHeaderButton> */}
						<PageHeaderButton
							icon={<ImportOutlined />}
							type="dashed"
							link=""
							onClick={() => {
								setOpenImport(true);
							}}>
							{t("promotion:import_button")}
						</PageHeaderButton>
						<PageHeaderButton
							type="primary"
							link=""
							onClick={() => {
								setOpenAdd(true);
							}}>
							{t("promotion:add_button")}
						</PageHeaderButton>
					</div>
				</RoleCheck>
			</CouponListHeader>

			{promotion ? (
				<div className=" p-4">
					<h3>Danh sách mã code chương trình {promotion.name}</h3>
				</div>
			) : null}

			{itemsSelect.length ? (
				<div className=" p-4 mt-4 flex  items-center  gap-10">
					<div>
						<>
							Đã chọn {itemsSelect.length}/{total}
						</>
					</div>

					<div className=" flex gap-2 items-center">
						<Button onClick={() => setItemSelectPage([])}>Bỏ tất cả</Button>
						<Dropdown menu={{ items }} trigger={["click"]}>
							<Button type="primary" icon={<DownOutlined />}>
								Thao tác
							</Button>
						</Dropdown>
					</div>
				</div>
			) : null}

			<PageDataTable<
				FilterPromotionCoupon,
				PromotionCouponModel,
				PromotionCouponCollection
			>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					tableColumnToggleKey: "promotion",
				}}></PageDataTable>

			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>
			<FormModal
				formId="coupon-form"
				okButtonProps={{ className: "hidden" }}
				title="Tạo mới các mã coupon"
				open={openAdd}
				confirmLoading={true}
				cancelButtonProps={{ className: "hidden" }}
				onCancel={() => {
					setOpenAdd((prev) => !prev);
				}}>
				<CouponForm
					onFinish={() => {
						fetchData();
						setOpenAdd(false);
					}}
					idEditing={Number(id)}
					initialValue={{ codes: [] }}></CouponForm>
			</FormModal>
			<Modal
				title="Nhập số lượng áp dụng"
				open={openEditNumApp}
				onOk={() => {
					checkIsValidItemsUpdate("max_app");
				}}
				onCancel={() => {
					setOpenEditNumApp((prev) => !prev);
				}}>
				<Input
					placeholder="Nhập số lần áp dụng"
					value={inputValue}
					onChange={handleInputChange}
					type="number"
					min={0}
				/>
				<p>Lưu ý: Nếu bằng 0 sẽ là vôn hạn lần áp dụng</p>
			</Modal>

			<Modal
				title="Nhập mã từ file excel"
				open={openImport}
				destroyOnClose
				footer={null}
				maskClosable={false}
				onCancel={() => setOpenImport(false)}>
				{openImport && (
					<ImportCodes
						onClose={() => setOpenImport(false)}
						onSubmit={handleImportCodes}
					/>
				)}
			</Modal>

			<Modal
				centered
				title=""
				closeIcon={null}
				open={isLoadingImport}
				footer={null}
				maskClosable={false}
				onCancel={() => setIsLoadingImport(false)}>
				<div className=" flex items-center justify-center  flex-col">
					<Progress type="circle" percent={percent} />
					<p>Đang tiến hành nhập mã lên hệ thống...</p>
				</div>
			</Modal>

			<Modal
				centered
				title="Danh sách các mã đã gặp lỗi trong quá trình nhập mã"
				open={openErrorImport}
				footer={null}
				onCancel={() => setOpenErrorImport(false)}>
				<div>
					<ul className=" flex flex-col">
						{errorsImport.map((err) => {
							return (
								<>
									<li>
										Mã: <b>{err.codes.join(", ")}</b> - số lần áp dụng:{" "}
										{err.max_count_apply} - lỗi:{" "}
										{t(`promotion:form.error.${err.errors.errors[0]}`)}
									</li>
								</>
							);
						})}
					</ul>
				</div>
			</Modal>
		</div>
	);
}

// Định nghĩa kiểu prop cho component
interface ImportCodesProps {
	onClose?: () => void;
	onSubmit?: (
		data: PromotionCouponJsonAddImport[],
		overwrite: boolean,
		isSkipError: boolean
	) => Promise<any>;
}

const ImportCodes: FC<ImportCodesProps> = ({ onClose, onSubmit }) => {
	const [overwrite, setOverwrite] = useState<boolean>(true);

	return (
		<div className="relative rounded-lg bg-white p-6 shadow-lg">
			<ExcelReaderCodes
				onSubmit={async (codes, stopIfError) => {
					await onSubmit?.(codes, overwrite, stopIfError);
				}}
			/>

			{/* <div className=" flex gap-1">
				<Checkbox checked={overwrite} onChange={(e => { setOverwrite(e.target.checked) })} />
				<span>Ghi đè lên mã đã trùng</span>
			</div>
			<h5 className='mb-4'>
				Giúp tránh tạo trùng mã đã có. Nếu bỏ chọn, mặc định tạo mới tất cả mã
				được nhập.
			</h5> */}

			<div className=" mt-4">
				<h6 className="mb-2">Tải dữ liệu mẫu:</h6>

				<div className="flex space-x-4">
					<a
						href="https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/October/45961-961831729589719-1729589719.xlsx"
						className="text-blue-500 underline">
						File excel mẫu
					</a>
				</div>
			</div>

			{/* 		
			<div className="mt-6 flex gap-4 justify-end">
				<Button
					onClick={() => {
						setCodes([]);
						onClose?.();
					}}>
					Hủy
				</Button>

				<Button
					type="primary"
					disabled={!codes.length}
					onClick={() => {
						onSubmit?.(codes, overwrite, stopIfError);
					}}>
					Xác nhận
				</Button>
			</div> */}
		</div>
	);
};
