import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { ContactMailJson } from "common/types/ContactMail";
import { SelectOption } from "common/types/SelectOption";
import BaseModel from "./BaseModel";
import i18n from "i18n";

const defaultData: ContactMailJson = {
	id: 0,
	contact: "",
	item_id: "",
	detail: "",
	type: 0,
	status: 0,
	is_deleted: 0,
	date_deleted: 0,
	date_created: 0,
	date_modified: 0,
};

class ContactMailModel
	extends BaseModel
	implements BaseModelJson<ContactMailJson>
{
	id: number = defaultData.id;
	contact: string = defaultData.contact;
	item_id: string = defaultData.item_id;
	detail: string = defaultData.detail;
	type: number = defaultData.type;
	status: number = defaultData.status;
	is_deleted: number = defaultData.is_deleted;
	date_deleted: number = defaultData.date_deleted;
	date_created: number = defaultData.date_created;
	date_modified: number = defaultData.date_modified;

	constructor(json: ContactMailJson) {
		super();
		Object.assign(this, { ...defaultData, ...json });
	}
	static getDefaultData(): ContactMailJson {
		return { ...defaultData };
	}
	toJson(): ContactMailJson {
		return JSON.parse(JSON.stringify({ ...this }));
	}

	static getPinSelectList(): SelectOption[] {
		return [
			{
				value: 1,
				label: "Ghim",
			},
			{
				value: 0,
				label: "Không ghim",
			},
		];
	}

	static getStatusSelectList(): SelectOption[] {
		return [
			{
				value: 1,
				label: i18n.t("contactmail:status.1"),
				color: "blue",
			},
			{
				value: 2,
				label: i18n.t("contactmail:status.2"),
				color: "success",
			},
		];
	}

	static getStatus(value: number): SelectOption | undefined {
		return this.getStatusSelectList().find((item) => item.value === value);
	}
}

export default ContactMailModel;
