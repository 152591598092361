import SettingModel from "common/models/SettingModel";
import SettingRepository from "common/repositories/SettingRepository";
import { useCallback, useEffect, useState } from "react";

const useSettingValues = (key: string[]) => {
  const [settings, setSettings] = useState<SettingModel[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getSetting = useCallback(async () => {
    let response = await new SettingRepository().getItems({
      filters: {
        ...SettingRepository.getDefaultFilters(),
        keys: key.join(","),
      },
    });

    if (response.hasError()) {
      throw response.error;
    }

    return response;
  }, [key]);

  const fetchSetting = useCallback(async () => {
    try {
      const response = await getSetting();
      setSettings(response.items);
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [getSetting]);

  useEffect(() => {
    fetchSetting();
  }, [fetchSetting]);

  return { settings, isLoading, error };
};

export default useSettingValues;
