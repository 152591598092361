import { Button, Popconfirm, Table } from "antd";
import { TableColumnsType } from "common/types/Table";
import { WarehouseJson } from "common/types/Warehouse";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconTrash } from "@tabler/icons-react";
import WarehouseRepository from "common/repositories/WarehouseRepository";
import WarehouseModel from "common/models/WarehouseModel";

const StoreWarehouseList = ({
  warehouse_ids,
  handleWarehouseDeleteId,
  loading,
}: {
  warehouse_ids: number[];
  handleWarehouseDeleteId: (id: number) => void;
  loading?: boolean;
}) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<WarehouseModel[]>([]);

  const fetchData = useCallback(
    async () => {
      setLoading(true);

      if (warehouse_ids.length <= 0) {
        setDataSource([]);
        setLoading(false);
        return;
      }
      
      const res = await new WarehouseRepository().getItems({
        filters: {
          ...WarehouseRepository.getDefaultFilters(),
          ids: warehouse_ids.length > 0 ? warehouse_ids.join(",") : "",
        },
      });
      setDataSource(res.items);
      setLoading(false);
    },
    [warehouse_ids]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  //Table columns
  const columns: TableColumnsType<WarehouseJson> = [
    {
      title: t("store:warehouse"),
      key: "name",
      dataIndex: "name",
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 90,
      render: (record: WarehouseJson) => (
        <Popconfirm
          title={t("common:table.confirm_delete_title")}
          placement="topRight"
          onConfirm={() => {
            handleWarehouseDeleteId(record.id);
          }}
          okText={t("common:table.confirm_ok")}
          cancelText={t("common:table.confirm_cancel")}
        >
          <Button
            size="small"
            type="text"
            icon={<IconTrash size="14" color="red" />}
          ></Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <Table
      size="small"
      className="table-dashboard"
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      rowKey="id"
      loading={isLoading || loading}
    />
  );
};

export default StoreWarehouseList;
