import { Spin, Typography } from "antd";
import EcomRepository from "common/repositories/EcomRepository";
import { Ecomplatform } from "common/types/Ecom";
import { memo, useCallback, useEffect, useState } from "react";

interface IOrderChannelProps {
    channelId: number;
}

const OrderChannel = ({
    channelId
}: IOrderChannelProps): JSX.Element => {
    const [isLoading, setLoading] = useState(true);
	const [channels, setChannels] = useState<Ecomplatform[]>([]);

    const getChannels = useCallback(async () => {
		try {
            const api = new EcomRepository();
            const datas = await api.getItemsChannel();
            
            if (datas) {
                setChannels(datas);
            }
        } catch (error) {
        } finally {
            setLoading(false);
        }
	}, []);
    
    useEffect(() => {
        if (channelId) {
            getChannels();
        }
    }, [channelId, getChannels]);

    return (
        <Spin spinning={isLoading} size="small">
            <Typography.Text>{channels.find((chanel) => chanel.id === channelId)?.title}</Typography.Text>
        </Spin>
    );
}

export default memo(OrderChannel);
