import NewsletterApi from "common/api/NewsletterApi";
import NewsletterCollection from "common/collections/NewsletterCollection";
import { GetListProps } from "common/types/Filter";
import { FilterNewsletter, NewsletterAddEditJson } from "common/types/Newsletter";
import BaseRepository from "./BaseRepository";
import NewsletterModel from "common/models/NewsletterModel";

class NewsletterRepository extends BaseRepository<NewsletterApi> {
  _api: NewsletterApi | null;

  constructor() {
    super();
    this._api = new NewsletterApi(true);
  }

  static getDefaultFilters(): FilterNewsletter {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
    };
  }

  async getItems(props: GetListProps<FilterNewsletter>) {
    return this._api
      ? await this._api.getList(props)
      : new NewsletterCollection();
  }

  async saveRemote(data: NewsletterAddEditJson): Promise<NewsletterModel> {
    if (this._api) {
      return await this._api.edit(data);
    } else {
      return new NewsletterModel(NewsletterModel.getDefaultData());
    }
  }
}

export default NewsletterRepository;
