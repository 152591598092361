const Warehouse = {
  STATUS_ENABLE: 1,
  STATUS_DISABLE: 3,

  IS_DELETE: 1,

  CHECKED: 1,
  UNCHECKED: 0,

  TYPE_SALE: 1,      // Kho bán
  TYPE_DEFECT: 2,    // Kho lỗi
  TYPE_TEMPORARY: 3, // Kho tạm
  TYPE_TESTER: 4,    // Kho testter
};

export default Warehouse;
