import { IconCalendar, IconInfoCircle } from "@tabler/icons-react";
import {
  Button,
  message,
  Popover,
  Row,
  Spin,
  Statistic,
  Typography,
} from "antd";
import LeaveTypeModel from "common/models/LeaveTypeModel";
import LeaveSummaryRepository from "common/repositories/LeaveSummaryRepository";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";

interface ILeaveStatisticProps {
  leaveType: LeaveTypeModel;
}

const LeaveStatistic = ({ leaveType }: ILeaveStatisticProps): JSX.Element => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [leaveSumAvaiable, setLeaveSumAvaiable] = useState<number>(0);
  const [leaveSummax, setLeaveSummax] = useState<number>(0);

  const fetchLeaveSummary = useCallback(async () => {
    setLoading(true);
    try {
      const avaiable: any = await new LeaveSummaryRepository().getSummaryDate(
        leaveType.id,
        new Date().getFullYear()
      );

      if (avaiable) {
        setLeaveSumAvaiable(avaiable.available_days);
        setLeaveSummax(leaveType.summax);
      }
    } catch (error) {
      message.error(String(error));
    } finally {
      setLoading(false);
    }
  }, [leaveType]);

  useEffect(() => {
    fetchLeaveSummary();
  }, [fetchLeaveSummary]);

  return (
    <Statistic
      style={{
        boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      }}
      className=" p-2 min-h-[100px] bg-white rounded-[4px]"
      title={
        <Typography.Text className="font-semibold text-black text-[16px]">
          {`Số ngày ${leaveType.name?.toLocaleLowerCase()} còn lại`}
          <Popover
            trigger={"click"}
            title=""
            content={<div className="max-w-[200px]">{leaveType.note}</div>}
            placement="topRight"
          >
            <Button size="small" type="text" onClick={() => {}}>
              <IconInfoCircle
                size="18"
                className=" -mt-0.5  text-gray-400 hover:text-gray-500"
              />
            </Button>
          </Popover>
        </Typography.Text>
      }
      valueRender={() => (
        <Spin spinning={isLoading}>
          <Row className="mt-4 flex items-center gap-2">
            <IconCalendar />
            <span className="font-bold">{leaveSumAvaiable}</span> /{" "}
            {leaveSummax} {t("leave:day")}
          </Row>
        </Spin>
      )}
    />
  );
};

export default LeaveStatistic;
