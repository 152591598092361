import React, { useCallback, useState } from "react";
import IdFetcherRepository from "common/repositories/IdFetcherRepository";
import IdFetcherCollection from "common/collections/IdFetcherCollection";
import { IdFetcherResult } from "common/types/IdFetcher";
type Props = {
	ids: number[];
	object_type: "employee" | "department";
};
const useIdFetcher = () => {
	const getListIdFetcher = useCallback(
		async ({ ids, object_type }: Props): Promise<IdFetcherCollection> => {
			const response = await new IdFetcherRepository().doFetching({
				object_type: object_type,
				id_list: ids,
			});

			return response;
		},
		[]
	);

	return {
		getListIdFetcher,
	};
};

export default useIdFetcher;
