import { BaseCollectionJson } from "./BaseCollectionJson";
import { Filter, FilterValue } from "./Filter";

type PromotionCouponJson = {
	company_id?: number;
	creator_id?: number;
	promotion_id: number;
	id: number;
	code: string;
	max_count_apply: number;
	used_count: number;
	start_date: number;
	end_date: number;
	modified_date: number;
	create_date: number;
	status: number;
	type: number;
	value: number;
	value_type: number;
	description: string;
	condition: object;
	name: string;
	coupon_scope: 1 | 3;
};

type PromotionCouponJsonAddEdit = {
	codes: string[];
	stop_on_error?: boolean;
	id: number;
	max_count_apply?: number;
	promotion_id: number;
	coupon_scope?: 1 | 3; //1 PUBLIC , 3PRIVATE
};
type PromotionCouponJsonAddImport = {
	code: string;
	max_count_apply?: number;
};

export enum PromotionCouponCodeStatus {
	RELEASED = 1,
	STOP_RELEASED,
	HIDDEN,
	USED,
}
type PromotionCouponUpdate = {
	is_error: boolean;
	data: {
		code?: string;
		id: number;
		max_count_apply?: number;
		status?: PromotionCouponCodeStatus;
		coupon_scope?: 1 | 3; //1 PUBLIC , 3PRIVATE
	}[];
};

type PromotionCouponCollectionJson = BaseCollectionJson<PromotionCouponJson>;

type FilterPromotionCoupon = Filter & { promotion_id: FilterValue };

export type {
	PromotionCouponJson,
	PromotionCouponJsonAddEdit,
	PromotionCouponCollectionJson,
	FilterPromotionCoupon,
	PromotionCouponUpdate,
	PromotionCouponJsonAddImport,
};
