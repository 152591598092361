import { Spin } from "antd";
import { IdFetcherResult } from "common/types/IdFetcher";
import React from "react";

type K = keyof IdFetcherResult;
const IdFetcherRender = ({
	value,
	mapping,
	key_value,
	render,
}: {
	value: number;
	mapping?: IdFetcherResult[];
	key_value?: K;
	render?: (r: IdFetcherResult) => React.ReactNode;
}) => {
	if (typeof mapping === "undefined") {
		return <Spin size="small" />;
	} else {
		if (key_value && mapping && mapping.length > 0) {
			const findMapping = mapping.filter((i) => i.value === value)[0];

			// if (key_value === "text") {
			//   return <>{findMapping.text}</>;
			// }
			if (findMapping && findMapping.hasOwnProperty(key_value)) {
				return <>{findMapping[key_value]}</>;
			} else {
				return <>Trống</>;
				// return <>{findMapping[key_value]}</>;
			}
		}

		const result = mapping.find((i) => i.value === value);
		if (!result) {
			return <>...</>;
		}

		if (render) return <>{render(result)}</>;
		return <>{result?.text ?? (result?.value || "...")}</>;
	}
};

export default IdFetcherRender;
