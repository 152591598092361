import FeedbackList from "features/feedback/list/FeedbackList";
import usePathParams from "hooks/usePathParams";

const FeedbackPage = (): JSX.Element => {
  const [pathParams] = usePathParams();

  let com = <FeedbackList />;
  switch (pathParams.action) {
    default:
      com = <FeedbackList />;
  }

  return com;
};

export default FeedbackPage;
