import { SelectProps } from "antd";
import { Rule } from "antd/es/form";
import { FormLabelAlign } from "antd/es/form/interface";
import { SelectValue } from "antd/es/select";
import CustomerRepository from "common/repositories/CustomerRepository";
import { SelectOption } from "common/types/SelectOption";
import FormSelect from "components/form/FormSelect";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

const CustomerFormFilter = ({
  label,
  name,
  disabled,
  value,
  onChange,
  labelAlign,
  required,
  allowClear,
  defaultOptions,
  placeholder,
  popupMatchSelectWidth,
  size,
  status,
  className,
  rules,
}: {
  label?: string;
  name: string;
  disabled?: boolean;
  value?: SelectValue;
  onChange?: (v: SelectValue) => void;
  labelAlign?: FormLabelAlign;
  required?: boolean;
  allowClear?: boolean;
  defaultOptions?: SelectOption[];
  placeholder?: string;
  popupMatchSelectWidth?: boolean;
  size?: SelectProps["size"];
  status?: SelectProps["status"];
  className?: string;
  rules?: Rule[];
}) => {
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<string[]>([]);
  const [options, setOptions] = useState<SelectOption[]>(defaultOptions || []);

  const fetchData = useCallback(async (searchValue?: string) => {
		const collection = await new CustomerRepository().getItems({
      filters: {
        ...CustomerRepository.getDefaultFilters(),
        limit: 50,
        keyword: searchValue!,
      }
    });

		if (collection.hasError()) {
			setErrors(collection.error.errors);
		} else {
			setOptions(
				collection.items.map((item) => ({
					value: item.id,
					label: item.full_name,
				}))
			);
		}

		setLoading(false);
	}, []);

  const debounceFetcher = useMemo(() => debounce(fetchData, 500), [fetchData]);

  useEffect(() => {
    if (defaultOptions && defaultOptions.length > 0) {
      setOptions(defaultOptions);
    }
  }, [defaultOptions]);

  useEffect(() => {
    if (loading && typeof defaultOptions === "undefined") {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [loading, fetchData, defaultOptions]);

  return (
    <FormSelect
      rules={rules}
      className={className}
      size={size}
      disabled={disabled}
      required={required}
      loading={loading}
      showSearch={options.length > 6}
      label={label}
      name={name}
      options={options}
      errors={errors}
      errorRetry={fetchData}
      value={value}
      onChange={onChange}
      onSearch={debounceFetcher}
      labelAlign={labelAlign}
      allowClear={allowClear}
      placeholder={placeholder}
      popupMatchSelectWidth={popupMatchSelectWidth}
      status={status}
    />
  );
};

export default CustomerFormFilter;
