import { Image, message, Tag, Typography } from "antd";
import DeviceCollection from "common/collections/DeviceCollection";
import ContractAndDevice from "common/constants/ContractAndDevice";
import Role from "common/constants/Role";
import ContractAndDeviceModel from "common/models/ContractAndDeviceModel";
import FileModel from "common/models/FileModel";
import ContractAndDeviceRepository from "common/repositories/ContractAndDeviceRepository";
import { FilterContractAndDevice } from "common/types/Device";
import { IdFetcherResult } from "common/types/IdFetcher";
import { TableColumnsType } from "common/types/Table";
import IdFetcherRender from "components/idfetcher/IdFetcherRender";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TextDateTime from "components/TextDateTime";
import TextString from "components/TextString";
import PhoneBookModal from "features/phonebook/form/PhoneBookModal";
import useFilterLocation from "hooks/useFilterLocation";
import useIdFetcher from "hooks/useIdFetcher";
import useIdImageFetcher from "hooks/useIdImageFetcher";
import useStateFilter from "hooks/useStateFilter";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ContractAddEditModal from "../components/ContractAddEditModal";
import ContractManageListFilter from "./ContractManageListFilter";
import ContractManageListHeader from "./ContractManageListHeader";

const ContractManageList = () => {
	const { t } = useTranslation();
	const { getListIdFetcher } = useIdFetcher();
	const { getListIdImageFetcher } = useIdImageFetcher();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterContractAndDevice = useMemo(
    () => ({
      ...ContractAndDeviceRepository.getDefaultFilters(),
      type: ContractAndDevice.GROUP_CONTRACT,
    }),
    []
  );

	//state
	const [total, setTotal] = useState<number>(0);
	const [dataSource, setDataSource] = useState<ContractAndDeviceModel[]>([]);
	const [filters, setFilters] =
		useStateFilter<FilterContractAndDevice>(defaultFilters);
	const [employees, setEmployees] = useState<IdFetcherResult[]>([]);
	const [employeeCreators, setEmployeeCreators] = useState<IdFetcherResult[]>(
		[]
	);
	const [images, setImages] = useState<FileModel[]>([]);
	const [modalId, setModalId] = useState<number>(0);
	const [showContactFields, setShowContactFields] = useState(false);
	const [formId, setFormId] = useState<number>(0);
	const [isOpenForm, setIsOpenForm] = useState(false);
	useFilterLocation(defaultFilters, filters);
	//////////////////////////////////////////

	//////////////////////////////////////////
	//Fetch data from this collections
	const getListImages = useCallback(
		async (id_list: number[]) => {
			const response = await getListIdImageFetcher({
				object_type: 10,
				ids: id_list,
			});

			if (!response.hasError()) {
				setImages(response.items);
			}
		},
		[getListIdImageFetcher]
	);

	const getListEmployees = useCallback(
		async (id_list: number[]) => {
			const response = await getListIdFetcher({
				object_type: "employee",
				ids: id_list,
			});

			if (!response.hasError()) {
				const result = response.items.filter(
					(i) => i.object_type === "employee"
				)[0].result!;
				setEmployees(result);
			}
		},
		[getListIdFetcher]
	);

	const getListEmployeeCreators = useCallback(
		async (id_list: number[]) => {
			const response = await getListIdFetcher({
				object_type: "employee",
				ids: id_list,
			});

			if (!response.hasError()) {
				const result = response.items.filter(
					(i) => i.object_type === "employee"
				)[0].result!;
				setEmployeeCreators(result);
			}
		},
		[getListIdFetcher]
	);

	const fetchData = useCallback(async (): Promise<DeviceCollection> => {
		const collection = await new ContractAndDeviceRepository().getItems({
			filters,
		});

		if (collection) {
			setTotal(collection.total);
			setDataSource(collection.items);

			const imageIds = [
				...new Set(
					collection.items.reduce(
						(acc: number[], curr) =>
							acc.concat(curr.file_id.split(",").map(Number).filter(Boolean)),
						[]
					)
				),
			];
			await getListImages(imageIds);

			const employeeIds = [
				...new Set(collection.items.map((i) => i.employee_id || 0)),
			];
			await getListEmployees(employeeIds);

			const employeeCreatorIds = [
				...new Set(collection.items.map((i) => i.creator_id || 0)),
			];
			await getListEmployeeCreators(employeeCreatorIds);
		} else {
			message.error(t("common:error.error_fetching_data"));
		}

		return collection;
	}, [filters, getListImages, getListEmployees, getListEmployeeCreators, t]);

	const handleClickCard = (id: number) => {
		setModalId(id);
	};

  //////////////////////////////////////////
  //Table columns
  const columns: TableColumnsType<ContractAndDeviceModel> = [
    {
      title: t("contractanddevice:contract_manage.table_header.image"),
      key: "file_id",
      align: "center",
      width: 150,
      render: (file_id) => {
        const recordImages = images.find((image) =>
          file_id.split(",").map(Number).includes(image.id)
        );
        if (images.length === 0 || recordImages?.id === 0) {
          return <Tag>Trống</Tag>;
        }
        return <Image width={120} height={120} src={recordImages?.url} />;
      },
    },
    {
      title: t("contractanddevice:contract_manage.table_header.code"),
      key: "code",
      width: 150,
    },
    {
      title: t("contractanddevice:contract_manage.table_header.name"),
      key: "name",
      width: 200,
      render: (name) => <TextString text={name} />,
    },
    {
      title: t("contractanddevice:contract_manage.table_header.creator_id"),
      key: "creator_id",
      width: 200,
      render: (creator_id) => (
        <Typography.Text>
          {creator_id === 0 ? (
            <Tag>{t("Trống")}</Tag>
          ) : (
            <Tag
              className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleClickCard(creator_id);
                setShowContactFields(true);
              }}
            >
              <IdFetcherRender
                value={creator_id}
                mapping={employeeCreators}
                key_value="text"
              />
            </Tag>
          )}
        </Typography.Text>
      ),
    },
    {
      title: t("contractanddevice:contract_manage.table_header.employee_id"),
      key: "employee_id",
      width: 200,
      render: (employee_id) => (
        <Typography.Text>
          {employee_id === 0 ? (
            <Tag>{t("Trống")}</Tag>
          ) : (
            <Tag
              className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleClickCard(employee_id);
                setShowContactFields(true);
              }}
            >
              <IdFetcherRender
                value={employee_id}
                mapping={employees}
                key_value="text"
              />
            </Tag>
          )}
        </Typography.Text>
      ),
    },
    {
      title: t("contractanddevice:contract_manage.table_header.status"),
      key: "status",
      width: 150,
      render: (status: number) => {
        let statusInfo = ContractAndDeviceModel.getStatus(status);
        return (
          <Tag bordered color={statusInfo?.color}>
            {statusInfo?.label}
          </Tag>
        );
      },
    },
    {
      title: t("contractanddevice:contract_manage.table_header.source_status"),
      key: "source_status",
      width: 150,
      render: (source_status: number) => {
        let sourceStatusInfo =
          ContractAndDeviceModel.getContractSourceStatus(source_status);
        return (
          <Tag bordered color={sourceStatusInfo?.color}>
            {sourceStatusInfo?.label}
          </Tag>
        );
      },
    },
    {
      title: t("feedback:table_header.date_created"),
      key: "date_created",
      width: 120,
      render(_, record) {
        return (
          <TextDateTime ts={record.date_created} format="HH:mm, DD/MM/YYYY" />
        );
      },
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      toggletype: "trigger",
      width: 150,
      render: (_: any, record: ContractAndDeviceModel) => (
        <RoleCheck roles={[Role.SETTING_HRM]} hideOnFail>
          <TableEdit
            link=""
            onClick={() => {
              setFormId(record.id);
              setIsOpenForm(true);
            }}
          />
          <TableDelete
            error_translate_prefix="warehouse:form.error"
            onDeleteCallback={(id) => {
              setDataSource(dataSource.filter((item) => item.id !== id));
            }}
            repository={new ContractAndDeviceRepository()}
            id={record.id}
          />
          <TableInfo record={record} />
        </RoleCheck>
      ),
    },
  ];

	return (
		<>
			<ContractManageListHeader onSaveSuccess={fetchData} />
			<ContractManageListFilter
				total={total}
				filters={filters}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
			/>
			<PageDataTable<
				FilterContractAndDevice,
				ContractAndDeviceModel,
				DeviceCollection
			>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					tableColumnToggleKey: "device",
				}}
			/>
			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>
			<PhoneBookModal
				id={modalId}
				visible={showContactFields}
				setVisible={(isVisible) => {
					setShowContactFields(isVisible);
				}}
			/>
			<ContractAddEditModal
				open={isOpenForm}
				setOpen={(isOpen) => setIsOpenForm(isOpen)}
				onSaveSuccess={fetchData}
				deviceId={formId}
			/>
		</>
	);
};

export default ContractManageList;
