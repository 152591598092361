import { Nav } from "common/types/PageNavigation";
import PageHeader from "components/page/PageHeader";
import { useTranslation } from "react-i18next";

const ShowOnWebsiteFormHeader = () => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("showonwebsite:heading_nav"),
      link: "/customer",
    },
  ];

  return (
    <PageHeader
      heading={t("showonwebsite:heading_edit")}
      nav={nav}
      siteMenuOpenKey="sale"
      siteMenuSelectedKey="/shopanother/showonwebsite"
      siteMenuHidden
    />
  );
};

export default ShowOnWebsiteFormHeader;
