import TaxInvoiceApi from "common/api/TaxInvoiceApi";
import TaxInvoiceCollection from "common/collections/TaxInvoiceCollection";
import TaxInvoiceModel from "common/models/TaxInvoiceModel";
import { GetListProps } from "common/types/Filter";

import BaseRepository from "./BaseRepository";

import type {
  TaxInvoiceJsonAddEdit,
  FilterTaxInvoice,
} from "common/types/TaxInvoice";

class TaxInvoiceRepository extends BaseRepository<TaxInvoiceApi> {
  _api: TaxInvoiceApi | null;

  constructor() {
    super();
    this._api = new TaxInvoiceApi(true);
  }

  static getDefaultFilters(): FilterTaxInvoice {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      id: -1,
      seller_store_id: -1,
      order_id: -1,
      code: "",
      date_started: -1,
      date_ended: -1,
      buyer_customer_id: -1,
      invoice_type: -1,
      buyer_receive_method: -1,
      payment_method: -1,
    };
  }

  async getItems(props: GetListProps<FilterTaxInvoice>) {
    return this._api
      ? await this._api.getList(props)
      : new TaxInvoiceCollection();
  }

  async getItem(id: number): Promise<TaxInvoiceModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new TaxInvoiceModel(TaxInvoiceModel.getDefaultData());
  }

  async saveRemote(data: TaxInvoiceJsonAddEdit): Promise<TaxInvoiceModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return new TaxInvoiceModel(TaxInvoiceModel.getDefaultData());
      }
    } else {
      return new TaxInvoiceModel(TaxInvoiceModel.getDefaultData());
    }
  }
}

export default TaxInvoiceRepository;
