import { Col, Row } from "antd";
import LeaveTypeModel from "common/models/LeaveTypeModel";
import LeaveTypeRepository from "common/repositories/LeaveTypeRepository";
import { useCallback, useEffect, useState } from "react";
import LeaveStatistic from "../components/LeaveStatistic";

const LeaveListSummary = (): JSX.Element => {
  const [leaveTypes, setLeaveTypes] = useState<LeaveTypeModel[]>([]);

  const fetchLeaveTypes = useCallback(async () => {
    const collection = await new LeaveTypeRepository().getItems();
    setLeaveTypes(collection);
  }, []);

  useEffect(() => {
    fetchLeaveTypes();
  }, [fetchLeaveTypes]);

  return (
    <Row gutter={[16, 16]} className="p-5">
      {leaveTypes.map((leaveType) => (
        <Col xs={24} md={12} lg={8} className="">
          <LeaveStatistic leaveType={leaveType} />
        </Col>
      ))}
    </Row>
  );
};

export default LeaveListSummary;
