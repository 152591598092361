import Order from "common/constants/Order";
import OrderModel from "common/models/OrderModel";
import ProductModel from "common/models/ProductModel";
import OrderRepository from "common/repositories/OrderRepository";
import ProductRepository from "common/repositories/ProductRepository";
import { CartOrderJson } from "common/types/Cart";
import FormEditFetcher from "components/form/FormEditFetcher";
import OrderFormHeader from "features/order/form/OrderFormHeader";
import OrderFormWrapper from "features/order/form/OrderFormWrapper";
import OrderAccountantList from "features/order/list/accountant/OrderAccountantList";
import OrderList from "features/order/list/OrderList";
import usePathParams from "hooks/usePathParams";
import React, { useEffect, useState } from "react";

const InvoiceAccountantPage = () => {
	const [pathParams] = usePathParams();

	const [model, setModel] = useState<OrderModel>(
		new OrderModel(OrderModel.getDefaultData())
	);
	const [fetchErrors, setFetchErrors] = useState<string[]>([]);

	//clear edit model when change action
	useEffect(() => {
		setModel(new OrderModel(OrderModel.getDefaultData()));
		setFetchErrors([]);
	}, [pathParams.action]);

	let com = null;
	const isModel =
		(typeof model.id === "string" && model.id.length > 0) ||
		(typeof model.id === "number" && model.id > 0);

	switch (pathParams.action) {
		case "add":
			break;
		// case "edit":
		// 	com = isModel ? (
		// 		<OrderFormWrapper model={model} />
		// 	) : (
		// 		<FormEditFetcher
		// 			doLoad={async () => {
		// 				let fetchedModel = await new OrderRepository().getItem(
		// 					parseInt(pathParams.id.toString())
		// 				);
		// 				if (fetchedModel.hasError()) {
		// 					setFetchErrors(fetchedModel.error.errors);
		// 				} else {
		// 					// const res = await new ProductRepository().getItems({
		// 					// 	filters: {
		// 					// 		...ProductRepository.getDefaultFilters(),
		// 					// 		list_product_id: fetchedModel.details.data
		// 					// 			.map((i) => i.product_id)
		// 					// 			.join(","),
		// 					// 	},
		// 					// });
		// 					// if (!res.hasError()) {
		// 					// 	const products = res.toJson().items;

		// 					// 	const newData: CartOrderJson[] = fetchedModel
		// 					// 		.toJson()
		// 					// 		.details.data.map((i) => ({
		// 					// 			...i,
		// 					// 			product_json:
		// 					// 				products.find((p) => p.id === i.product_id) ||
		// 					// 				ProductModel.getDefaultData(),
		// 					// 		}));
		// 					// 	fetchedModel = new OrderModel({
		// 					// 		...fetchedModel.toJson(),
		// 					// 		details: { data: newData, total: fetchedModel.details.total },
		// 					// 	});
		// 					// }

		// 					setModel(fetchedModel);
		// 				}
		// 			}}
		// 			errors={fetchErrors}
		// 			heading={<></>}
		// 		/>
		// 	);

		// 	break;
		default:
			com = <OrderAccountantList type={Order.TYPE_INVOICE} />;
	}

	return com;
};

export default InvoiceAccountantPage;
