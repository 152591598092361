import { Table, TableProps } from "antd";
import { ColumnsType } from "antd/es/table";
import BaseCollection from "common/collections/BaseCollection";
import BaseModel from "common/models/BaseModel";
import { Filter } from "common/types/Filter";
import {
  TableColumnsType,
  TableColumnType,
  TableFilters,
  TableSorter,
} from "common/types/Table";
import PageDataTableView from "components/page/PageDataTableView";
import useBreakpoint from "hooks/useBreakpoint";
import update from "immutability-helper";
import React, { useCallback, useEffect, useState } from "react";
import usePageDataColumnToggleStore from "zustands/usePageDataTableColumnToggleStore";

import PageDataTableColumnToggle from "./PageDataTableColumnToggle";

const PageDataTable = <
  F extends Filter,
  M extends BaseModel,
  C extends BaseCollection<any, any, any>
>({
  defaultFilters,
  children,
  columns,
  filters,
  setFilters,
  dataSource,
  fetchData,
  expandable,
  rowClassName,
  rowSelection,
  onRow,
  tableColumnToggleKey,
  rowKey,
  isLoading,
}: {
  defaultFilters: F;
  columns: TableColumnsType<M>;
  filters: F;
  setFilters: (f: F) => void;
  dataSource: M[];
  fetchData: () => Promise<C>;
  children?: React.ReactNode;
  expandable?: TableProps<M>["expandable"];
  rowClassName?: TableProps<M>["rowClassName"];
  rowSelection?: TableProps<M>["rowSelection"];
  onRow?: TableProps<M>["onRow"];
  tableColumnToggleKey?: string;
  rowKey?: string;
  isLoading?: boolean;
}) => {
  //listing data
  const [loading, setLoading] = useState(true);

  const disableColumnList = usePageDataColumnToggleStore(
    (state) => state.getTable(tableColumnToggleKey || "")?.disable_columns || []
  );
  const extendColumnList = usePageDataColumnToggleStore(
    (state) => state.getTable(tableColumnToggleKey || "")?.extend_columns || []
  );

  const [isSmall] = useBreakpoint();
  const getColumnToggleKey = useCallback((column: TableColumnType<M>) => {
    return typeof column.togglekey !== "undefined" &&
      column.togglekey.length > 0
      ? column.togglekey
      : typeof column.dataIndex === "string" && column.dataIndex.length > 0
      ? column.dataIndex
      : typeof column.key !== "undefined"
      ? column.key.toString()
      : "";
  }, []);

  //handle change sorting and filtering
  const handleTableChange = (
    tablefilters: TableFilters,
    sorter: TableSorter<M>
  ) => {
    let newFilters: F = {
      ...filters,
      limit: defaultFilters.limit,
      page: defaultFilters.page,
      sortby: defaultFilters.sortby,
      sorttype: defaultFilters.sorttype,
    };

    /////////////////////////////////////////////////////////
    // SORTING
    //Only support SINGLE sorter
    if (!Array.isArray(sorter)) {
      if (
        typeof sorter.column !== "undefined" &&
        typeof sorter.columnKey !== "undefined"
      ) {
        newFilters.sortby = sorter.columnKey.toString();
        if (typeof sorter.order === "string" && sorter.order === "ascend") {
          newFilters.sorttype = "ASC";
        } else {
          newFilters.sorttype = "DESC";
        }
      }
    } else {
      console.log("Do not support multiple sorter");
    }

    /////////////////////////////////////////////////////////
    // FILTERING
    //LOOP THOURGHT filter in table
    Object.keys(tablefilters).forEach((k) => {
      if (typeof tablefilters[k] !== "undefined") {
        if (tablefilters[k] !== null) {
          //SET FILTER action
          let filterValue = null;
          if (Array.isArray(tablefilters[k])) {
            if (typeof tablefilters[k]![0] !== "undefined") {
              filterValue = tablefilters[k]![0];
            }
          } else {
            filterValue = tablefilters[k];
          }
          // console.log("filterValue", k, filterValue);

          //found valid filtervalue, update back to page filters
          if (filterValue !== null) {
            if (Object.keys(newFilters).includes(k)) {
              if (typeof newFilters[k as keyof F] === "string") {
                // @ts-ignore
                newFilters[k as keyof F] = filterValue.toString();
              } else if (
                typeof newFilters[k as keyof F] === "number" &&
                typeof filterValue === "number"
              ) {
                // @ts-ignore
                newFilters[k as keyof F] = filterValue;
              }
            }
          }
        } else {
          //CLEAR FILTER action
          if (
            Object.keys(newFilters).includes(k) &&
            newFilters[k as keyof F] !== defaultFilters[k as keyof F]
          ) {
            newFilters[k as keyof F] = defaultFilters[k as keyof F];
          }
        }
      }
    });

    setFilters(newFilters);
  };

  //fetch data when change fetchData() (trigger by change filters from parent ^^!)
  useEffect(() => {
    (async () => {
      setLoading(true);

      //call parent fetchData()
      await fetchData();

      setLoading(false);
    })();
  }, [fetchData]);

  //refine columns for using in <Table..>
  // - remove "sortby" key because not use for <Table..>
  // - add "dataIndex" from "key"
  // - add related sorter keys (from input sortby props): sorter, sortDirections, sortOrder
  // - add "filterMultiple" to false when enable "filters"
  const processedColumns: ColumnsType<M> = columns
    .map((column) => {
      return typeof column.key === "undefined"
        ? Table.EXPAND_COLUMN
        : {
            ...update(column, { $unset: ["sortby"] }),
            width:
              isSmall && typeof column.width === "undefined"
                ? 200
                : column.width,
            dataIndex: column.dataIndex || column.key,
            ...(typeof column.sortby !== "undefined" &&
              column.sortby.length > 0 && {
                sorter: true,
                sortDirections: ["ascend", "descend", "ascend"],
                sortOrder:
                  filters.sortby === column.sortby
                    ? filters.sorttype === "ASC"
                      ? "ascend"
                      : "descend"
                    : null,
              }),
            ...(typeof column.filters !== "undefined" &&
              column.filters.length > 0 && {
                filterMultiple: false,
              }),
            ...(column.toggletype === "trigger" &&
              typeof tableColumnToggleKey !== "undefined" &&
              tableColumnToggleKey.length > 0 && {
                title: (
                  <>
                    {column.title}{" "}
                    <PageDataTableColumnToggle
                      tableColumnToggleKey={tableColumnToggleKey}
                      columns={columns}
                    />
                  </>
                ),
              }),
          };
    })
    .filter(
      (c: TableColumnType<M>) =>
        c.toggletype === "ignore" ||
        c.toggletype === "trigger" ||
        (c.toggletype === "extend" &&
          extendColumnList.includes(getColumnToggleKey(c))) ||
        (c.toggletype !== "extend" &&
          !disableColumnList.includes(getColumnToggleKey(c)))
    );

  return (
    <PageDataTableView<F, M>
      {...{
        dataSource,
        loading: loading || Boolean(isLoading),
        filters,
        columns: processedColumns,
        handleTableChange,
        expandable,
        rowClassName,
        rowSelection,
        onRow,
        rowKey,
      }}
    >
      {children}
    </PageDataTableView>
  );
};

export default PageDataTable;
