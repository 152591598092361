import PromotionCouponApi from 'common/api/PromotionCouponApi';
import PromotionCouponCollection from 'common/collections/PromotionCouponCollection';
import PromotionCouponCheckResultModel from 'common/models/PromotionCouponCheckResultModel';
import PromotionCouponModel from 'common/models/PromotionCouponModel';
import { GetListProps } from 'common/types/Filter';
import { PromotionCouponCheckQuery } from 'common/types/PromotionCheck';

import BaseRepository from './BaseRepository';

import type {
  PromotionCouponJsonAddEdit,
  FilterPromotionCoupon,
  PromotionCouponUpdate,
} from "common/types/PromotionCoupon";
class PromotionCouponRepository extends BaseRepository<PromotionCouponApi> {
  _api: PromotionCouponApi | null;

  constructor() {
    super();
    this._api = new PromotionCouponApi(true);
  }

  static getDefaultFilters(): FilterPromotionCoupon {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      promotion_id: -1,
    };
  }

  async getItems(props: GetListProps<FilterPromotionCoupon>) {
    return this._api
      ? await this._api.getList(props)
      : new PromotionCouponCollection();
  }

  async getItem(id: number): Promise<PromotionCouponModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new PromotionCouponModel(PromotionCouponModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(
    data: PromotionCouponJsonAddEdit
  ): Promise<PromotionCouponModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new PromotionCouponModel(PromotionCouponModel.getDefaultData());
    }
  }


  async updateCodes(
    data: PromotionCouponUpdate
  ): Promise<PromotionCouponModel> {
    if (this._api) {
      return await this._api.updateCode(data);
    } else {
      return new PromotionCouponModel(PromotionCouponModel.getDefaultData());
    }
  }

  async posCheckCoupons(
    data: PromotionCouponCheckQuery
  ): Promise<PromotionCouponCheckResultModel> {
    if (this._api) {
      return await this._api?.check(data);
    } else {
      return new PromotionCouponCheckResultModel(
        PromotionCouponCheckResultModel.getDefaultData()
      );
    }
  }
}

export default PromotionCouponRepository;
