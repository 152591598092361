import DeviceCollection from "common/collections/DeviceCollection";
import ContractAndDeviceModel from "common/models/ContractAndDeviceModel";
import {
	ContractAndDeviceAddEditJson,
	FilterContractAndDevice,
} from "common/types/Device";
import type { GetListProps } from "common/types/Filter";
import Helper from "common/utils/helper";
import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

const SERVICE_URL = "/contracts";

class ContractAndDeviceApi extends BaseApi {
	async getList(
		props: GetListProps<FilterContractAndDevice>
	): Promise<DeviceCollection> {
		let collection = new DeviceCollection();
		const convertProps = Helper.convertParams(props.filters);

		const queryData = {
			params: {
				...convertProps,
				sort_by: props.filters.sortby,
				sort_type: props.filters.sorttype,
			},
		};
		try {
			const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}
		return collection;
	}

	async getDetail(id: number): Promise<ContractAndDeviceModel> {
		let item = new ContractAndDeviceModel(
			ContractAndDeviceModel.getDefaultData()
		);

		try {
			const response = await this.axios.get<any>(SERVICE_URL + "/" + id);
			if (response.hasOwnProperty("data")) {
				item = new ContractAndDeviceModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async add(
		data: ContractAndDeviceAddEditJson
	): Promise<ContractAndDeviceModel> {
		let item = new ContractAndDeviceModel(
			ContractAndDeviceModel.getDefaultData()
		);

		console.log(data);

		try {
			let postData = {
				...data,
			};

			const response = await AxiosClient().post<any>(SERVICE_URL, postData);
			if (response.hasOwnProperty("data")) {
				item = new ContractAndDeviceModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async edit(
		data: ContractAndDeviceAddEditJson
	): Promise<ContractAndDeviceModel> {
		let item = new ContractAndDeviceModel(
			ContractAndDeviceModel.getDefaultData()
		);
		try {
			let postData = {
				...data,
			};
			const response = await AxiosClient().put<any>(
				SERVICE_URL + "/" + data.id,
				postData
			);
			if (response.hasOwnProperty("data")) {
				item = new ContractAndDeviceModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async delete(id: number): Promise<string[]> {
		let resultErrors = [];

		try {
			const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
			if (response.status !== 204) {
				resultErrors.push("error_delete_response_not_204");
			}
		} catch (error) {
			resultErrors = BaseApi.handleError(error).errors;
		}

		return resultErrors;
	}
}

export default ContractAndDeviceApi;
