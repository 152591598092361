import {
  IconCoin,
  IconInfoCircle,
  IconMotorbike,
  IconPackage,
} from "@tabler/icons-react";
import { Button, Col, Popover, Row, Statistic, Typography } from "antd";
import Helper from "common/utils/helper";

type Props = {
  title: string;
  count: number;
  total: number;
  explainContent?: string;
};

const DeliveryStatisic = ({ title, count, total, explainContent }: Props) => {
  return (
    <>
      <Statistic
        style={{
          boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        }}
        className=" p-2 min-h-[100px] bg-white rounded-[4px]"
        title={
          <Typography.Text
            className="font-semibold text-black text-[16px]"
          >
            {title}
            <Popover
              trigger={"click"}
              title=""
              content={<div>{explainContent}</div>}
              placement="topRight"
            >
              <Button size="small" type="text" onClick={() => {}}>
                <IconInfoCircle
                  size="18"
                  className=" -mt-0.5  text-gray-400 hover:text-gray-500"
                />
              </Button>
            </Popover>
          </Typography.Text>
        }
        valueStyle={{ color: "#00000073" }}
        valueRender={() => (
          <Row className="text-sm mt-4 font-normal">
            <Col xs={24} xl={5} >
              <IconMotorbike />
            </Col>
            <Col xs={24} xl={9}>
              <IconPackage /> {count || 0} đơn hàng
            </Col>
            <Col xs={24} xl={10}>
              <IconCoin className="mr-1" />
              {Helper.moneyFormat(total || 0)}
            </Col>
          </Row>
        )}
        precision={0}
      />
    </>
  );
};

export default DeliveryStatisic;
