import { Flex, Form, Input, InputNumber, Typography } from "antd";
import { SelectValue } from "antd/es/select";
import ProductModel from "common/models/ProductModel";
import { Filter } from "common/types/Filter";
import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import StoreFormSelectNormal from "features/store/StoreFormSelectNormal";
import SupplierFormSelect from "features/supplier/SupplierFormSelect";
import { useTranslation } from "react-i18next";
import ProductCollectionFormSelectFilter from "./ProductCollectionFormSelectFilter";
import { Select } from "antd/lib";
import { useState } from "react";
import StoreWarehouseFormSelect from "features/store/StoreWarehouseFormSelect";
import { FilterProduct } from "common/types/Product";

const ProductListFilter = <F extends Filter>({
	filters,
	setFilters,
	defaultFilters,
	total,
	// list_type,
	listTags,
}: {
	filters: any;
	setFilters: (f: F) => any;
	defaultFilters: F;
	total: number;
	list_type?: SelectValue[];
	listTags: SelectValue[];
}) => {
	const { t } = useTranslation();
	const { Option } = Select;

	const [nameFieldStock, setNameFieldStock] = useState("");
	const selectBefore = (
		<Select
			style={{ width: "auto" }}
			showSearch={false}
			placeholder="Mức tồn kho">
			<Option value="">
				<Typography.Text>Mức tồn kho</Typography.Text>
			</Option>
			<Option value="max_quantity">
				<Typography.Text>Nhỏ hơn</Typography.Text>
			</Option>
			<Option value="min_quantity">Lớn hơn</Option>
		</Select>
	);
	return (
		<PageDataFilterForm
			filters={filters}
			setFilters={setFilters}
			total={total}
			defaultFilters={defaultFilters}
			convertValueByKeyFilter={[
				"status",
				"category_id",
				"supplier_id",
				"store_id",
				"collection_id",
				// "tags_code",
				// "max_quantity",
				// "min_quantity",
			]}
		>
			<Form.Item name="keyword">
				<Input
					allowClear
					placeholder={t("product:filter_keyword_placeholder")}
				/>
			</Form.Item>
			{/* <Form.Item name="id">
        <Input allowClear placeholder={t("common:sid")} />
      </Form.Item> */}
			{/* <ProductCategoryFormSelect
        placeholder={t("product:category_id")}
        name="category_id"
        localOnly={false}
        popupMatchSelectWidth={false}
      /> */}
			<ProductCollectionFormSelectFilter
				existValue={filters.collection_id}
				changeDataProductCollection={() => {}}
				required={false}
			/>
			<SupplierFormSelect
				name="supplier_id"
				placeholder={t("product:supplier")}
				popupMatchSelectWidth={false}
			/>
			<StoreWarehouseFormSelect
				filters={filters}
				storeName="store_id"
				warehouseName="warehouse_id"
				allowClear
				popupMatchSelectWidth={false}
			/>
			<FormSelect
				name="status"
				options={ProductModel.getStatusList()}
				allowClear
				placeholder={t("common:status")}
				popupMatchSelectWidth={false}
			/>
			<StoreFormSelectNormal
				placeholder="Phân loại (tag)"
				// name="tags_code"
				name="tags"
				popupMatchSelectWidth={false}
				options={listTags}
				allowClear
			/>
			<FormSelect
				name="show"
				placeholder="Hiển thị"
				options={ProductModel.getShowOptions}
				allowClear	
			/>
			<Flex>
				<Form.Item name={"min_quantity"}>
					<InputNumber
						addonBefore={<Typography.Text>Tồn kho tối thiểu</Typography.Text>}
						placeholder="Giá trị tồn kho"
					/>
				</Form.Item>

				<Form.Item name={"max_quantity"}>
					<InputNumber
						addonAfter={<Typography.Text>Tồn kho tối đa</Typography.Text>}
						placeholder="Giá trị tồn kho"
					/>
				</Form.Item>
			</Flex>
		</PageDataFilterForm>
	);
};

export default ProductListFilter;
