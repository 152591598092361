import { BaseCollectionJson } from "./BaseCollectionJson";
import { CouponCheckJson } from "./Coupon";
import { OrderInventoryStatus } from "./Order";
import { PaymentMethodDetailUpdate } from "./Payment";
import { BrandJson, ProductJson, ProductJson2, TagJson } from "./Product";
import { PromotionJson2 } from "./Promotion";

type CartJson = {
	company_id: number;
	creator_id: number; // người tạo

	id: number; // id của order

	code: string;
	store_id: number; // cửa hàng
	customer_id: number; // customer
	browser_id: string;
	coupons: CartCouponAddJson[];
	cancel_reason: number;

	// tiền
	price_sell: number;
	price_shipping: number;
	price_discount: number; // tiền giảm giá
	price_discount_coupon: number;
	price_final: number; // tiền tổng
	price_cost: number;
	price_deposit: number;
	price_debt: number;
	price_tax: number;

	promotion_id: number;
	// promotion_detail: string;
	promotions: CartPromotion[];

	contact_email: string; // email liên hệ

	// phần mua
	billing_full_name: string;
	billing_phone: string;
	// billing_email: string;
	billing_address: string;
	billing_ward: number;
	billing_district: number;
	billing_province: number;
	billing_country: number;
	billing_company: string;
	billing_sub_sub_region_id: number;
	billing_sub_region_id: number;
	billing_region_id: number;

	// phần giao hàng
	shipping_full_name: string;
	shipping_phone: string;
	shipping_address: string;
	shipping_ward: number;
	shipping_district: number;
	shipping_province: number;
	shipping_country: number;
	shipping_company: string;
	shipping_sub_sub_region_id: number;
	shipping_sub_region_id: number;
	shipping_region_id: number;

	warehouse_id: number; // kho hàng nào

	shipping_carrier: number; // đơn vị vẫn chuyển

	product_receipt_id_list: number[];
	cashflow_receipt_id_list: number[];
	tax_invoice_id: number;
	quantity: number;
	note: string;
	cod_amount: number;

	status: number; // trạng thái mới tạo là 12 danh sách trạng thái ở dưới

	tag: string;
	ecom_platform_id: number;
	ecom_platform_type: number;
	ecom_platform_order_id: string;
	ecom_platform_invoice_id: string;
	date_arrived: number;
	date_created: number;
	date_modified: number;
	payment_method: number;
	resource_type: number;
	resource_id: string;
	resource_number: string;
	order_detail: string;
	order_description: string;
	// product_receipt_list: [];
	// cashflow_receipt_list: [];
	// tax_invoice_list: [];
	kiotviet_code: string;
	details: {
		data: CartOrderJson[];
		total: number;
	};

	total_payment: number;
	debt: number;
	payments: PaymentMethodDetailUpdate[];
};

type CartAddEditJson = {
	cart_id: number;
	action: "add" | "update" | "delete" | "creator" | "coupon" | "fetch";
	browser_id?: string;
	details?: ProductCartAddEditJson[];
	coupons?: CartCouponAddJson[];
	promotions?: CartPromotion[];
};

type ProductCartAddEditJson = {
	id?: number;
	item_quantity: number;
	product_id: number;
	promotions?: CartPromotion[];
	is_use?: IsUse;
};

export enum IsUse {
	NOT_USE,
	USE,
}

type CartPromotion = {
	discount: number;
	promotion_id: number; // id của loại chường trình promotion
	sale_promotion_id: number; // id của điều kiện promotion
	product_id: number; // id của product nào phát sinh ra promotion (điều kiện)
	item_quantity: number; // sô lượng sản phẩm
	is_use: IsUse; // có sài cái promotion này không , nếu sài để 1 không sài để 0
	promotion_detail: PromotionJson2;
	discount_value: number;
	code: string;
};

type CartOrderJson = {
	order_id: number;
	product_id: number;
	id: number;
	is_use: IsUse;
	item_title: string;
	item_name: string;
	item_quantity: number;
	item_unit_price_original: number;
	item_unit_price: number;
	item_date_deleted: number;
	item_vat: number;
	price_vat: number;
	discount_percent: number;
	// promotion_id: number;
	promotions: CartPromotion[];
	price_final: number;
	price_discount: number;
	product_json: ProductJson2;
	inventory_status: OrderInventoryStatus;
	delivery_status: number;
};

type ProductJsonOrder = {
	status: number;
	company_id: number;
	id: number;
	name: string;
	parent_id: number;
	sku: string;
	barcode: string;
	handle: string;
	images: { url: string }[];
	brand: BrandJson | null;
	price: number;
	price_min: number;
	price_max: number;
	compare_at_price: number;
	compare_at_price_min: number;
	compare_at_price_max: number;
	tags: TagJson[];
	created_at: number;
	updated_at: number;
	count_childs: number;
	full_name: string;
	summary: string;
	description: string;
	allow_sale: number;
	limit_sale: number;
	detail: string;
	meta_title: string;
	meta_description: string;
	compare_discount: number;
	compare_discount_percent: number;
	option_name: string;
};

// type CartPayMethod = {
//   method: "voucher" | "transfer" | "cash";
//   amount: number;
//   voucher?: {
//     code: string;
//   };
// };

type CartOrderCollectionJson = BaseCollectionJson<CartOrderJson>;

type CartCreateJson = {
	store_id: number;
	browser_id?: string;
};

type CartDeleteJson = {
	cart_id: number;
	browser_id?: string;
};

type CartPreOrderAddEditJson = {
	store_id: number;
	details: ProductCartAddEditJson[];
	coupons?: CartCouponAddJson[];
};

type CartPreOrderJson = {
	order_id: number;
};

type CartPayJson = {
	code: string;
	id: number;
	url_vnpay?: string;
};

type CartTimingJson = {
	switch_cart: number;
	recheck_stock: number;
};

type CartCouponAddJson = {
	code: string; // code của coupom
	coupon_campaign_id: number; // id của chương trình coupon
	id: number; // id của coupon
	coupon_detail: CouponCheckJson;
	is_use: IsUse;
};

type PriceProductPromotionProps = {
	item_quantity: number;
	product_id: number;
	cart_id: number;
	promotions: CartPromotion[];
};

type CalcPriceProps = {
	data: PriceProductPromotionProps[];
};

type PricePromotionJson = {
	product_id: number;
	discount: number;
	price_Unit: number;
	item_quantity: number;
	price_final: number;
};

type QuantityRemaining = {
	product_id: number;
	quantity: number;
	store_id: number;
};

type CalcPricePromotionJson = PricePromotionJson[];

type CartCollectionJson = BaseCollectionJson<CartJson>;

export type {
	CalcPricePromotionJson,
	CalcPriceProps,
	CartAddEditJson,
	CartCollectionJson,
	CartCouponAddJson,
	CartCreateJson,
	CartDeleteJson,
	CartJson,
	CartOrderCollectionJson,
	CartOrderJson,
	CartPayJson,
	CartPreOrderAddEditJson,
	CartPreOrderJson,
	CartPromotion,
	CartTimingJson,
	PriceProductPromotionProps,
	PricePromotionJson,
	ProductCartAddEditJson,
	ProductJsonOrder,
	QuantityRemaining,
};
