import PageHeader from "components/page/PageHeader";
import React from "react";
import { useTranslation } from "react-i18next";

const ProductWarehouseListHeader = ({ children }: { children?: React.ReactNode }) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("productwarehouse:heading_list")}
      siteMenuOpenKey="product"
      siteMenuSelectedKey="/productwarehouse">
      {children}
    </PageHeader>
  );
};

export default ProductWarehouseListHeader;
