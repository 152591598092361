import {
	Button,
	Col,
	DatePicker,
	Form,
	Image,
	Input,
	Row,
	Skeleton,
} from "antd";
import { useTranslation } from "react-i18next";

import { PlusOutlined } from "@ant-design/icons";
import { IconPencil, IconX } from "@tabler/icons-react";
import { OrderProductJson } from "common/types/Delivery";
import { SectionBannerNNNJson } from "../form/ShopCollectionForm";
import FileModel from "common/models/FileModel";
import useHandleSetting from "hooks/useHandleSetting";
import SettingApi from "common/constants/Setting";
import { useState } from "react";
import ShopSectionBannerForm from "./ShopSectionBannerForm";
import ListDeleteButton from "components/list/ListDeleteButton";
const { RangePicker } = DatePicker;

const ShopSectionBannerEdit = ({ isEditing }: { isEditing: boolean }) => {
	const { t } = useTranslation();
	const ARRAY_BANNER = [1, 2, 3];

	const [open, setOpen] = useState<boolean>(false);

	const defaultDataPopup: SectionBannerNNNJson = {
		id: 0,
		title: "",
		image_desktop: "",
		image_mobile: "",
		alt: "",
		width: 0,
		height: 0,
		link: "",
	};

	const [data, setData] = useState<{
		data: SectionBannerNNNJson;
		index: number;
		type: "add" | "edit";
	}>({
		data: defaultDataPopup,
		index: -1,
		type: "add",
	});

	const { dataSource, loading, deleteItemWithId, editItemWithId } =
		useHandleSetting<SectionBannerNNNJson>(SettingApi.KEY.k_categories, "vi");

	function handlelOpenModalAdd(data: SectionBannerNNNJson) {
		setData({
			data: data,
			type: "add",
			index: -1,
		});
		setOpen(true);
	}
	function handlelOpenModalEdit(data: SectionBannerNNNJson, index: number) {
		setData({
			data: data,
			type: "edit",
			index,
		});
		setOpen(true);
	}

	async function handleSumit(dataRes: SectionBannerNNNJson) {
		try {
			if (data.type === "add") {
				await editItemWithId(dataRes, dataRes.id);
			} else {
				await editItemWithId(dataRes, dataRes.id);
			}
		} catch (error) {
		} finally {
			setOpen(false);
		}
	}

	async function handleDelete(data: SectionBannerNNNJson, index: number) {
		try {
			await deleteItemWithId(data, data.id);
		} catch (error) {
		} finally {
			setOpen(false);
		}
	}
	const i = [
		{
			id: 1,
			title: "Nước hoa nữ",
			title_color: "#3a393a",
			image_desktop:
				"https://asset.namefragrance.vn/uploads/filecloud/10308/2025/February/171306-105371738897217-1738897217.jpg",
			image_mobile:
				"https://asset.namefragrance.vn/uploads/filecloud/10308/2025/February/171301-274511738897054-1738897054.jpg",
			alt: "Nước hoa nữ",
			width: 0,
			height: 0,
			link: "/collections/nuoc-hoa-nu",
		},
		{
			id: 2,
			title: "Nước hoa nam",
			title_color: "#3a393a",
			image_desktop:
				"https://asset.namefragrance.vn/uploads/filecloud/10308/2025/February/171302-634211738897066-1738897066.jpg",
			image_mobile:
				"https://asset.namefragrance.vn/uploads/filecloud/10308/2025/February/171303-952661738897073-1738897073.jpg",
			alt: "Nước hoa nam",
			width: 0,
			height: 0,
			link: "/collections/nuoc-hoa-nam",
		},
		{
			id: 3,
			title: "Nước hoa niche",
			title_color: "#3a393a",
			image_desktop:
				"https://asset.namefragrance.vn/uploads/filecloud/10308/2025/February/171304-771231738897082-1738897082.jpg",
			image_mobile:
				"https://asset.namefragrance.vn/uploads/filecloud/10308/2025/February/171305-688051738897090-1738897090.jpg",
			alt: "Nước hoa niche",
			width: 0,
			height: 0,
			link: "/collections/nuoc-hoa-niche",
		},
	];

	return (
		<>
			<Row gutter={[16, 16]}>
				{!loading ? (
					<>
						{ARRAY_BANNER.map((item, index) => {
							const findItem = dataSource.find((tmp) => tmp.id === item);
							if (findItem) {
								return (
									<>
										<Col md={8} xs={24} key={index} className="">
											<div className="flex items-start gap-2">
												<div className="flex flex-col">
													<Image
														height={100}
														width={100}
														style={{ borderRadius: 4 }}
														src={findItem.image_desktop}
													/>
													{findItem.title}
												</div>
												<div className="flex flex-col gap-2">
													<Button
														onClick={() => {
															handlelOpenModalEdit(findItem, findItem.id);
														}}>
														Sửa
													</Button>
													<ListDeleteButton
														handleDelete={() => {
															handleDelete(findItem, findItem.id);
														}}
														label=""
														id={findItem.id}
													/>
												</div>
											</div>
										</Col>
									</>
								);
							} else {
								return (
									<>
										<Col md={8} xs={24} key={index} className="">
											<Button
												type="primary"
												key={index}
												onClick={() =>
													handlelOpenModalAdd({
														...defaultDataPopup,
														id: item,
													})
												}>
												Thêm banner {item}
											</Button>
										</Col>
									</>
								);
							}
						})}
					</>
				) : (
					<>
						<Skeleton active />
					</>
				)}
			</Row>

			{open && (
				<ShopSectionBannerForm
					type={data.type}
					data={data.data}
					open={open}
					onCancel={() => setOpen(false)}
					onFinish={(data) => {
						handleSumit(data);
					}}
				/>
			)}
		</>
	);
};

export default ShopSectionBannerEdit;
