import { BaseCollectionJson } from "./BaseCollectionJson";
import { Filter } from "./Filter";

export type InventoryReceiptJson = {
	id: number;
	code: string;
	company_id: number;
	creator_id: number;
	warehouse_id: number | null;
	source: {
		type: number;
		id: number | string;
	};
	target: {
		type: number;
		id: number | string;
		status: InventoryReceiptJsonTargetStatus;
	};
	referral_id: number;
	type: number;
	direction: number;
	note: string;
	status: number;
	date_created: number;
	date_modified: number;
	detail: ProductInventoryReceiptJson[];
	related_receipt_id: string;
	return_receipt_id: string;
};

export enum InventoryReceiptJsonTargetStatus {
	DRAFT = 1,
	PROCESS = 3,
	COMPLETED = 9,
	CANCELLED = 11,
}

export enum InventoryReceiptDirection {
	OUT = 1,
	IN = 3,
}
export enum InventoryReceiptStatus {
	DRAFT = 1,
	WAIT_APPROVE = 3,
	APPROVE = 5,
	COMPLETE = 9,
	CANCEL = 11,
}
export enum InventoryReceiptType {
	SALE = 3,
	DELI = 5,
}

export enum InventoryReceiptSourceType {
	ORDER = 1,
	WAREHOUSE = 3,
	SUPPLIER = 5,
	OTHER = 15,
	DOC = 10,
}

export enum InventoryReceiptTargetType {
	DELIVERY = 1,
	WAREHOUSE = 3,
	SUPPLIER = 5,
	OTHER = 15,
}

// "source_type" : 3, //1: order | 3: warehouse | 5: supplier | 9: other
export type InventoryReceiptJsonAddEdit = {
	action?: "add" | "update" | "delete";
	id: number;
	warehouse_id: number;
	type: number;
	direction: InventoryReceiptDirection;
	note?: string;
	status: InventoryReceiptStatus;
	target_id: number;
	target_type: InventoryReceiptTargetType;
	source_id: number;
	source_type: InventoryReceiptSourceType;
	related_receipt_id: string;
	return_receipt_id: string;
	details: Pick<
		ProductInventoryReceiptJson,
		"item_id" | "item_type" | "quantity" | "price" | "unit" | "unit_price"
	>[];
	referral_id: string;
};

export type ProductInventoryReceiptJson = {
	item_id: number;
	item_type: number;
	price: number;
	cost_price: number;
	price_tax: number;
	unit: number;
	unit_type: string;
	unit_price: number;
	quantity: number;
	date_created: number;
	date_modified: number;
	date_completed: number;
	sku?: string;
	isDelete?: boolean;
	typeAction?: "update" | "delete" | "add";
	id: number;

	max_quantity?: number;
};

export type InventoryReceiptFilter = Filter & {
	source_type: number;
	source_id: number;
	direction?: number;
	code?: string;
	type?: number;
	is_complete?: number;
	ids?: string;
};

export type InventoryReceiptCollectionJson =
	BaseCollectionJson<InventoryReceiptJson>;
