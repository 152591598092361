import { Button, Dropdown, MenuProps, message, Tag } from "antd";
import NewsletterCollection from "common/collections/NewsletterCollection";
import NewsletterModel from "common/models/NewsletterModel";
import NewsletterRepository from "common/repositories/NewsletterRepository";
import { FilterNewsletter } from "common/types/Newsletter";
import { TableColumnsType } from "common/types/Table";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import NewsletterListFilter from "./NewsletterListFilter";
import NewsletterListHeader from "./NewsletterListHeader";
import TextString from "components/TextString";
import TextDateTime from "components/TextDateTime";
import Newsletter from "common/constants/Newsletter";
import { MoreOutlined } from "@ant-design/icons";

const NewsletterList = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterNewsletter = useMemo(
    () => NewsletterRepository.getDefaultFilters(),
    []
  );

  //state
  const [isLoadingUpdate, setLoadingUpdate] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<NewsletterModel[]>([]);
  const [filters, setFilters] = useStateFilter<FilterNewsletter>(defaultFilters);
  useFilterLocation(defaultFilters, filters);
  //////////////////////////////////////////

  //////////////////////////////////////////
  //Fetch data from this collections
  const fetchData = useCallback(async (): Promise<NewsletterCollection> => {
    const collection = await new NewsletterRepository().getItems({
      filters,
    });

    if (collection) {
      setTotal(collection.total);
      setDataSource(collection.items);
    } else {
      message.error(t("common:error.error_fetching_data"));
    }

    return collection;
  }, [filters, t]);

  const onUpdateStatus = async (record: NewsletterModel, status: number) => {
    setLoadingUpdate(true);
    try {
      const response = await new NewsletterRepository().saveRemote(
        { id: record.id, status }
      );
      if (!response.hasError()) {
        await fetchData();
      } else {
        message.error(t(`newsletter:error.${response.error.errors[0]}`));
      }
    } catch (error: any) {
      message.error(error);
    } finally {
      setLoadingUpdate(false);
    }
  };

  //////////////////////////////////////////
  //Table columns
  const columns: TableColumnsType<NewsletterModel> = [
    {
      title: "ID",
      key: "id",
      width: 80,
    },
    {
      title: t("newsletter:table_header.email"),
      key: "contact_id",
      width: 200,
      render: (contact_id) => <TextString text={contact_id} />
    },
    {
      title: t("feedback:table_header.status"),
      key: "status",
      width: 100,
      render: (status: number) => {
				let statusInfo = NewsletterModel.getStatus(status);
				return (
					<Tag bordered color={statusInfo?.color}>
            {statusInfo?.label}
          </Tag>
				);
			},
    },
    {
      title: t("newsletter:table_header.date_created"),
      key: "date_created",
      width: 120,
      render: (_, record) => <TextDateTime ts={record.date_created} format="HH:mm, DD/MM/YYYY" />,
    },
		{
			title: " ",
			toggletype: "trigger",
			key: "actions",
			align: "right",
			fixed: "right",
			width: 100,
			render: (_: number, record) => {
				const items: MenuProps["items"] = [
          {
            key: "1",
            label: (
              <Button
                type="text"
                title="Đã xử lý"
                onClick={
                  () => onUpdateStatus(record, record.status === Newsletter.STATUS_DRAFT ? Newsletter.STATUS_ENABLE : Newsletter.STATUS_DRAFT)
                }
              >
                {record.status === Newsletter.STATUS_DRAFT ? "Đã xử lý" : "Chưa xử lý"}
              </Button>
            ),
          }
				];
				return (
          <div onClick={(e) => e.stopPropagation()}>
            <Dropdown trigger={["click"]} menu={{ items }}>
              <MoreOutlined
                style={{ fontSize: "16pt" }}
                className="cursor-pointer"
              />
            </Dropdown>
          </div>
				);
			},
		},
  ];

  return (
    <>
      <NewsletterListHeader />
      <NewsletterListFilter
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      />
      <PageDataTable<FilterNewsletter, NewsletterModel, NewsletterCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          isLoading: isLoadingUpdate,
					tableColumnToggleKey: "newsletter",
        }}
      />
      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </>
  );
};

export default NewsletterList;
