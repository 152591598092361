import TagConstant from "common/constants/Tag";
import TagType from "common/constants/TagType";
import TagTypeModel from "common/models/TagTypeModel";
import PageHeader from "components/page/PageHeader";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const TagProductListHeader = ({
	type,
	children,
}: {
	type: number;
	children?: React.ReactNode;
}) => {
	const { t } = useTranslation();

	const renderTitle = (typeHeader: number) => {
		return TagTypeModel.getResouceTypeLabel(typeHeader)?.label || <></>;
	};

	const renderKey = (typeHeader: number) => {
		switch (typeHeader) {
			case TagType.TAG_TYPE.PRODUCT:
				return "/tagproduct";

			case TagType.TAG_TYPE.STORIES:
				return "/tagstory";

			case TagType.TAG_TYPE.PRODUCT_COLLECTION:
				return "/tagproductcollection";

			case TagType.TAG_TYPE.STORIES_COLLECTION:
				return "/tagstorycollection";
			case TagType.TAG_TYPE.BRAND:
				return "/tagbrand";

			case TagType.TAG_TYPE.WAREHOUSE:
				return "/tag/warehouse";
		}
	};

	return (
		<PageHeader
			heading={t("tag:heading_list") + " " + renderTitle(type)}
			siteMenuSelectedKey={renderKey(type)}
			siteMenuOpenKey="tag">
			{children}
		</PageHeader>
	);
};

export default TagProductListHeader;
