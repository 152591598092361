import { IconLoader } from "@tabler/icons-react";
import { Form, Select, Typography } from "antd";
import ProductCollectionCollection from "common/collections/ProductCollectionCollection";
import ProductCollectionModel from "common/models/ProductCollectionModel";
import ProductCollectionRepository from "common/repositories/ProductCollectionRepository";
import { SelectOptionNormal } from "common/types/SelectOption";
import FormSelectNormal from "components/form/FormSelectNormal";
import useStateFilter from "hooks/useStateFilter";
import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "rooks";

type SelectedItem = {
	id: number;
	handle: string;
};

type Props = {
	existValue: number;
	className?: string;
	onClear?: () => void;
	changeDataProductCollection: (v: ProductCollectionModel) => void;
	required?: boolean;
};

function removeItemDuplicate(arr: SelectOptionNormal[]) {
	let uniqueArray = [];
	for (let i = arr.length - 1; i >= 0; i--) {
		let foundDuplicate = false;
		for (let j = i - 1; j >= 0; j--) {
			if (arr[i].value === arr[j].value) {
				foundDuplicate = true;
				break;
			}
		}
		if (!foundDuplicate) {
			uniqueArray.unshift(arr[i]);
		}
	}
	return uniqueArray;
}

const ProductCollectionFormSelectFilter = ({
	existValue,
	className = "",
	onClear,
	changeDataProductCollection,
	required = false,
}: Props) => {
	const [options, setOptions] = useState<SelectOptionNormal[]>([]);
	const [keyword, setKeyWord] = useState<string>("");
	const defaultFilters = useMemo(() => {
		return { ...ProductCollectionRepository.getDefaultFilters(), limit: 50 };
	}, []);
	const [filters, setFilters] = useStateFilter(defaultFilters);
	const [total, setTotal] = useState<number>(0);
	const [dataSource, setDataSource] = useState<ProductCollectionModel[]>([]);
	const [value, setValue] = useState<number>(existValue);
	const [selectedItem, setSelectedItem] = useState<ProductCollectionModel>(
		new ProductCollectionModel({
			...ProductCollectionModel.getDefaultData(),
			// id: existValue > 0 ? existValue : 0,
		})
	);

	const [loading, setLoading] = useState<boolean>(false);

	const fetchDataDefaultOption = async (id: number) => {
		const myObj = await new ProductCollectionRepository().getItem(id);
		setLoading(false);

		if (!myObj.hasError()) {
			setSelectedItem(myObj);
			changeDataProductCollection(myObj);

			const defauilValueOption = [
				{
					label: `${myObj.title}`,
					value: Number(myObj.id),
				},
			];
			return defauilValueOption;
		} else {
			setSelectedItem(
				new ProductCollectionModel({
					...ProductCollectionModel.getDefaultData(),
					id: id,
				})
			);
			changeDataProductCollection(
				new ProductCollectionModel({
					...ProductCollectionModel.getDefaultData(),
					id: id,
				})
			);
			return [];
		}
	};

	const fetchData = async (): Promise<ProductCollectionCollection> => {
		setLoading(true);
		const response = await new ProductCollectionRepository().getItems({
			filters: filters,
		});
		if (!response.hasError()) {
			setTotal(response.total);
			const convertedItem = response.items.map((item) => {
				return {
					label: `${item.title}`,
					value: Number(item.id),
				};
			});
			if (existValue > 0) {
				let dataDe = await fetchDataDefaultOption(existValue);
				if (dataDe.length > 0) {
					setOptions(removeItemDuplicate([...dataDe, ...convertedItem]));
				} else {
					setOptions(convertedItem);
				}
			} else {
				setOptions(convertedItem);
			}
			setDataSource(response.items);
		}
		setLoading(false);

		return response;
	};

	const handleSearchChange = useDebounce((value: string) => {
		setTimeout(() => {
			setFilters({ ...filters, keyword: value });
		}, 500);
	}, 500);

	const handleChangeSelect = (e: number) => {
		setValue(e);
		const findItem = dataSource.find((item) => item.id === e);
		if (findItem) {
			setSelectedItem(findItem);
			changeDataProductCollection(findItem);
		}
	};

	useEffect(() => {
		fetchData();
	}, [filters]);

	return (
		<>
			{/* <Form.Item label="Danh mục sản phẩm" initialValue={selectedItem.title}> */}
			<FormSelectNormal
				className={className}
				placeholder="Danh mục sản phẩm"
				rules={
					required
						? [
								{
									validator(rule, value, callback) {
										if (selectedItem.id === 0) {
											return Promise.reject("Some message here");
										} else {
											return Promise.resolve();
										}
									},
									message: "Vui lòng nhập.",
								},
						  ]
						: []
				}
				name="collection_id"
				defaultValue={value}
				showSearch
				loading={loading}
				// value={value}
				onClear={onClear}
				onChange={(e) => {
					handleChangeSelect(Number(e));
				}}
				allowClear={true}
				options={options}
				onSearch={(v) => {
					handleSearchChange(v);
				}}
				filterOption={false}
			/>
			{/* </Form.Item> */}
		</>
	);
};

export default ProductCollectionFormSelectFilter;
