import {
	Button,
	Col,
	FormInstance,
	Modal,
	Popover,
	Row,
	Table,
	Tag,
	Typography,
} from "antd";
import ProductReceipt from "common/constants/ProductReceipt";
import InventoryReceiptModel from "common/models/InventoryReceiptModel";
import ShippingBillModel from "common/models/ShippingBillModel";
import InventoryReceiptRepository from "common/repositories/InventoryReceiptRepository";
import ShippingBillRepository from "common/repositories/ShippingBillRepository";
import { ShippingBillJson } from "common/types/ShippingBill";
import { TableColumnsType } from "common/types/Table";
import ErrorAlert from "components/ErrorAlert";
import TextWarehouse from "components/TextWarehouse";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useEditingSaleOrderStore from "zustands/useEditingSaleOrder";

import { IconLink, IconRefresh, IconSquarePlus } from "@tabler/icons-react";

import OrderFormSectionProductReceiptLinkForm from "./OrderFormSectionProductReceiptLinkForm";
import OrderFormSectionInventoryReceiptItem, {
	OrderFormSectionInventoryReceiptItemRef,
} from "./OrderFormSectionInventoryReceiptItem";
import RoleCheck from "components/RoleCheck";
import Role from "common/constants/Role";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import Order from "common/constants/Order";
import TableInfo from "components/table/TableInfo";
import {
	InventoryReceiptJson,
	InventoryReceiptJsonTargetStatus,
	InventoryReceiptSourceType,
	InventoryReceiptTargetType,
} from "common/types/InventoryResceipt";
import InventoryReceiptTarget from "../inventoryReceipt/InventoryReceiptTarget";
import InventoryReceiptSource from "../inventoryReceipt/InventoryReceiptSource";
import InventoryReceipt from "common/constants/InventoryReceipt";
import InventoryReceiptForm from "features/inventoryreceipt/form/InventoryReceiptForm";
import useOrderStore from "zustands/useOrderStore";
import { OrderJson } from "common/types/Order";

const OrderFormSectionProductReceipt = ({
	order,
	form,
	allowEditInput = true,
	isSuccess,
	status,
	refetchOrder,
}: {
	order: OrderJson;
	form?: FormInstance;
	allowEditInput?: boolean;
	isSuccess?: boolean;
	status?: number;
	refetchOrder?: () => void;
}) => {
	const { t } = useTranslation();
	const hasRole = useLoginAccountStore((state) => state.hasRole);
	const { refreshTrigger, setRefreshTrigger } = useOrderStore();

	const [itemView, setItemView] = useState<InventoryReceiptModel | null>(null);
	const [openItemView, setOpenItemView] = useState(false);
	// const order = useEditingSaleOrderStore((state) => state.order);
	const [dataSource, setDataSource] = useState<InventoryReceiptModel[]>([]);

	const [loading, setLoading] = useState(true);
	const [errors, setErrors] = useState<string[]>([]);

	const [shippingBills, setShippingBills] = useState<ShippingBillModel[]>([]);

	const OrderFormSectionInventoryReceiptItemRef =
		useRef<OrderFormSectionInventoryReceiptItemRef | null>(null);

	//get productreceipt of current order
	const fetchData = useCallback(async () => {
		setLoading(true);

		//First, fetch product receipt
		let collection = await new InventoryReceiptRepository().getItems({
			filters: {
				...InventoryReceiptRepository.getDefaultFilters(),
				source_id: +order.id,
				source_type: 1,
			},
		});
		if (collection.hasError()) {
			setErrors(collection.error.errors);
		} else {
			//Second, fetch ShippingBill
			// const shippingBillCollection =
			// 	await new ShippingBillRepository().getItems({
			// 		filters: {
			// 			...ShippingBillRepository.getDefaultFilters(),
			// 			sale_order_id: order.invoice_id,
			// 		},
			// 	});
			// setLoading(false);
			// if (shippingBillCollection.hasError()) {
			// 	setErrors(shippingBillCollection.error.errors);
			// } else {
			// 	setShippingBills(shippingBillCollection.items);
			// }
			setDataSource(collection.items);
		}
		setLoading(false);
	}, [order.id]);

	// const deleteShippingBillCallback = useCallback(
	// 	(id: number) => {
	// 		setShippingBills(shippingBills.filter((i) => i.id !== id));
	// 	},
	// 	[shippingBills]
	// );

	// const updateShippingBillCallback = useCallback(
	// 	(item: ShippingBillJson) => {
	// 		const foundIndex = shippingBills.findIndex((i) => i.id === item.id);

	// 		//not found
	// 		if (foundIndex === -1) {
	// 			setShippingBills([...shippingBills, new ShippingBillModel(item)]);
	// 		} else {
	// 			//update
	// 			setShippingBills(
	// 				shippingBills.map((i, index) =>
	// 					index === foundIndex ? new ShippingBillModel(item) : i
	// 				)
	// 			);
	// 		}
	// 	},
	// 	[shippingBills]
	// );

	function handlerViewItem(item: InventoryReceiptModel) {
		setItemView(item);
		setOpenItemView(true);
	}

	function handlerAddItem(direction: number) {
		const isIn = direction === InventoryReceipt.DIRECTION_INPUT;
		if (isIn) {
			const dataPrev = dataSource.find(
				(data) => data.direction === InventoryReceipt.DIRECTION_OUTPUT
			);
			const itemAdd = new InventoryReceiptModel({
				...InventoryReceiptModel.getDefaultData(),
				direction,
				source: {
					id: order.id,
					type: InventoryReceiptSourceType.ORDER,
				},
				target: {
					id: order.store_id,
					type: InventoryReceiptTargetType.WAREHOUSE,
					status: InventoryReceiptJsonTargetStatus.DRAFT,
				},
				status: InventoryReceipt.STATUS_DRAFT,
				type: InventoryReceipt.TYPE_SELLING_RETURN,
				warehouse_id: order.store_id,
				related_receipt_id: dataPrev ? dataPrev.id.toString() : "",
				detail: order.details.data.map((data) => ({
					item_id: data.product_id,
					quantity: data.item_quantity,
					item_type: 1,
					cost_price: 0,
					price: 0,
					unit: 0,
					unit_type: "",
					date_completed: 0,
					date_created: 0,
					date_modified: 0,
					id: 0,
					unit_price: 0,
					max_quantity: data.item_quantity,
					price_tax: 0,
				})),
			});
			setItemView(itemAdd);
			setOpenItemView(true);
		} else {
			const dataPrev = dataSource.find(
				(data) => data.direction === InventoryReceipt.DIRECTION_INPUT
			);
			const itemAdd = new InventoryReceiptModel({
				...InventoryReceiptModel.getDefaultData(),
				direction,
				source: {
					id: order.id,
					type: InventoryReceiptSourceType.ORDER,
				},
				target: {
					id: order.delivery_lits_id,
					type: InventoryReceiptTargetType.DELIVERY,
					status: InventoryReceiptJsonTargetStatus.DRAFT,
				},
				related_receipt_id: dataPrev ? dataPrev.id.toString() : "",
				status: InventoryReceipt.STATUS_DRAFT,
				type: InventoryReceipt.TYPE_SELLING,
				warehouse_id: order.store_id,

				detail: order.details.data.map((data) => ({
					item_id: data.product_id,
					quantity: data.item_quantity,
					item_type: 1,
					cost_price: 0,
					price: 0,
					unit: 0,
					unit_type: "",
					date_completed: 0,
					date_created: 0,
					date_modified: 0,
					id: 0,
					unit_price: 0,
					price_tax: 0,
				})),
			});
			setItemView(itemAdd);
			setOpenItemView(true);
		}
	}

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		if (isSuccess) {
			fetchData();
		}
	}, [isSuccess]);

	useEffect(() => {
		if (refreshTrigger === "delivery") {
			OrderFormSectionInventoryReceiptItemRef?.current
				?.refresh()
				.finally(() => {
					setRefreshTrigger("");
				});
		}
	}, [refreshTrigger]);

	//Table columns
	const columns: TableColumnsType<InventoryReceiptModel> = [
		{
			title: t("productreceipt:id"),
			dataIndex: "code",
			align: "center",
			width: 200,
			render: (_, record) => {
				return (
					<>
						<Typography.Text
							copyable
							className=" cursor-pointer font-semibold text-blue-500"
							onClick={() => {
								handlerViewItem(record);
							}}>
							{record.code}
						</Typography.Text>

						{/* <TableLinkText
							link={"/inventoryreceipt/edit/id/" + record.id}
							target="_blank"
							className="font-bold text-md">
							{record.id}
						</TableLinkText> */}
					</>
				);
			},
		},
		{
			title: t("order:inventoryreceipt.direction"),
			dataIndex: "direction",
			sortby: "direction",
			render: (direction: number) => {
				let directionInfo = InventoryReceiptModel.getDirectionLabel(direction);
				return <Tag color={directionInfo?.color}>{directionInfo?.label}</Tag>;
			},
			width: 120,
		},
		{
			title: t("common:status"),
			dataIndex: "status",
			sortby: "status",
			render: (status: number) => {
				let statusInfo = InventoryReceiptModel.getStatus(status);
				return <Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>;
			},
			width: 120,
		},
		{
			title: t("productreceipt:warehouse_id"),
			dataIndex: "warehouse_id",
			width: 200,
			render: (warehouse_id: number) => {
				return <TextWarehouse id={warehouse_id} />;
			},
		},
		{
			title: t("productreceipt:source"),
			dataIndex: "source",
			width: 150,
			render: (source: InventoryReceiptJson["source"]) => {
				return (
					<InventoryReceiptSource source={source}></InventoryReceiptSource>
				);
			},
		},
		{
			title: t("productreceipt:target"),
			dataIndex: "target",
			width: 150,
			render: (target: InventoryReceiptJson["target"]) => {
				return (
					<InventoryReceiptTarget target={target}></InventoryReceiptTarget>
				);
			},
		},

		{
			title: t("order:inventoryreceipt.delivery_column"),
			dataIndex: "id",
			render: (id: number, record) => {
				return (
					<>
						<OrderFormSectionInventoryReceiptItem
							ref={OrderFormSectionInventoryReceiptItemRef}
							inventory_receipt={record.toJson()}
							form={form!}
							allowEditInput={
								allowEditInput
							}></OrderFormSectionInventoryReceiptItem>
						{/* <OrderFormSectionProductReceiptItem
              form={form}
              product_receipt={record.toJson()}
              deleteCallback={deleteShippingBillCallback}
              updateCallback={updateShippingBillCallback}
              shipping_bill={
                shippingBills
                  .find((i) => i.product_receipt_id === id)
                  ?.toJson() || ShippingBillModel.getDefaultData()
              }
              key={id}
              allowEditInput={allowEditInput}
            /> */}
					</>
				);
			},
		},

		{
			dataIndex: "date_created",
			align: "end",
			render: (_, record) => {
				return <TableInfo record={record}></TableInfo>;
			},
		},
	];

	return (
		<div className="mt-8">
			<Row className="bg-gray-100 p-2">
				<Col
					span={3}
					className="text-xl text-gray-600 font-extrabold uppercase text-right px-2 bg-gray-100">
					{t("order:form.section_receiptprefix")}
					<br />
					{/* {t("order:form.section_productreceipt_3")}- */}
					{t("order:form.section_inventoryreceipt")}
				</Col>
				<Col span={21}>
					{errors.length > 0 ? (
						<ErrorAlert
							items={errors}
							heading={t("common:error.error_fetching_data")}
							onRetry={() => {
								setErrors([]);
								fetchData();
								OrderFormSectionInventoryReceiptItemRef?.current?.refresh();
							}}
							translate_prefix="order:form.error"
						/>
					) : null}

					{!loading && dataSource.length === 0 ? (
						<div className="italic">{t("order:product_receipt_empty")}</div>
					) : null}

					<div>
						<Table<InventoryReceiptModel>
							className="border"
							rowKey={"id"}
							pagination={{
								pageSize: 100,
								hideOnSinglePage: true,
							}}
							columns={columns}
							dataSource={dataSource}
							loading={loading}
						/>

						<div className="my-2">
							<Button
								onClick={async () => {
									fetchData();
									await OrderFormSectionInventoryReceiptItemRef?.current?.refresh();
								}}
								icon={<IconRefresh size={16} className="mr-1" />}
								className="opacity-40 hover:opacity-80"
								size="small"
								type="text">
								{t("order:form.refresh_receipt")}
							</Button>
							{order.status !== Order.STATUS_CANCEL && (
								<>
									<RoleCheck roles={[]}>
										<Button
											onClick={() =>
												handlerAddItem(InventoryReceipt.DIRECTION_INPUT)
											}
											icon={
												<IconSquarePlus size={16} className="mr-1 -mt-0.5" />
											}
											className="opacity-40 hover:opacity-80"
											size="small"
											type="text">
											{t("order:form.add_more_inventoryreceipt_in")}
										</Button>
									</RoleCheck>
									<RoleCheck roles={[]}>
										<Button
											onClick={() =>
												handlerAddItem(InventoryReceipt.DIRECTION_OUTPUT)
											}
											icon={
												<IconSquarePlus size={16} className="mr-1 -mt-0.5" />
											}
											className="opacity-40 hover:opacity-80"
											size="small"
											type="text">
											{t("order:form.add_more_inventoryreceipt_out")}
										</Button>
									</RoleCheck>
								</>
							)}

							<Popover
								title={t("order:form.link_receipt.title")}
								content={
									<OrderFormSectionProductReceiptLinkForm
										onSuccess={() => fetchData()}
									/>
								}
								placement="rightTop"
								trigger={["click"]}>
								<Button
									icon={<IconLink size={16} className="mr-1 -mt-0.5" />}
									className="opacity-40 hover:opacity-80"
									size="small"
									type="text">
									{t("order:form.link_receipt.button")}
								</Button>
							</Popover>
						</div>
					</div>
				</Col>
			</Row>

			<Modal
				centered
				destroyOnClose
				className="min-w-[80vw]"
				open={openItemView}
				onCancel={() => setOpenItemView(false)}
				bodyStyle={{ maxHeight: 600, overflow: "auto" }}
				footer={
					<div className=" flex gap-4  justify-between">
						{itemView?.id && (
							<RoleCheck roles={[Role.INVENTORY_STATUS_APPROVE]} hideOnFail>
								<Link
									target="_blank"
									to={`/inventoryreceipt/edit/id/${itemView?.id}`}>
									<Button type="link" htmlType="button" color="primary">
										Cập nhập phiếu #{itemView?.id}
									</Button>
								</Link>
							</RoleCheck>
						)}

						<Button onClick={() => setOpenItemView(false)}>Đóng</Button>
					</div>
				}>
				{itemView && (
					<InventoryReceiptForm
						fieldsLock={["warehouse_id", "source_type", "source_id"]}
						model={itemView}
						disable={true}
						isView
						isSymmetric={true}
						refetch={() => {
							fetchData();
							refetchOrder?.();
							setOpenItemView(false);
						}}
						// disable={hasRole(Role.INV)}
					/>
				)}
			</Modal>
		</div>
	);
};

export default OrderFormSectionProductReceipt;
