import { Form, Input } from "antd";
import ContractAndDeviceModel from "common/models/ContractAndDeviceModel";
import { Filter } from "common/types/Filter";
import DatePickerRangeFilter from "components/datetime/DatePickerRangeFilter";
import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import UserFormSelect from "features/user/UserFormSelect";
import { useTranslation } from "react-i18next";

const ContractManageListFilter = <F extends Filter>({
  total,
  filters,
  setFilters,
  defaultFilters,
}: {
  total: number;
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <PageDataFilterForm
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      >
        <Form.Item name="keyword">
          <Input allowClear placeholder={t("Nhập từ khóa")} />
        </Form.Item>
        <UserFormSelect
          name="creator_id"
          allowClear
          placeholder={t("Người tạo")}
          popupMatchSelectWidth={false}
        />
        <UserFormSelect
          name="holder_id"
          allowClear
          placeholder={t("Người giữ")}
          popupMatchSelectWidth={false}
        />
        <FormSelect
          name="status"
          options={ContractAndDeviceModel.getStatusList()}
          allowClear
          placeholder={t("common:status")}
        />
        <FormSelect
          name="source_status"
          options={ContractAndDeviceModel.getContractSourceStatusList()}
          allowClear
          placeholder={t("contractanddevice:contract_manage.table_header.source_status")}
        />
        <DatePickerRangeFilter
          filters={filters}
          keyStart="date_from"
          keyEnd="date_to"
          setFilters={setFilters}
          placeholderRange={[
            t("reportdelivery:placeholder_datefrom"),
            t("reportdelivery:placeholder_dateto"),
          ]}
        />
      </PageDataFilterForm>
    </>
  );
};

export default ContractManageListFilter;
