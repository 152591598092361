import StoreApi from 'common/api/StoreApi';
import StoreCollection from 'common/collections/StoreCollection';
import StoreModel from 'common/models/StoreModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type { StoreJson, FilterStore } from "common/types/Store";

class StoreRepository extends BaseRepository<StoreApi> {
  _api: StoreApi | null;

  constructor() {
    super();
    this._api = new StoreApi(true);
  }

  static getDefaultFilters(): FilterStore {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      region_id: -1,
      ids: "",
    };
  }

  async getFullData(
    companyId: number,
    version: number
  ): Promise<StoreCollection> {
    return this._api
      ? await this._api.getFullData({ companyId, version })
      : new StoreCollection();
  }

  async getItems(props: GetListProps<FilterStore>) {
    return this._api ? await this._api.getList(props) : new StoreCollection();
  }

  async getItem(id: number): Promise<StoreModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new StoreModel(StoreModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(data: StoreJson): Promise<StoreModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new StoreModel(StoreModel.getDefaultData());
    }
  }
}

export default StoreRepository;
