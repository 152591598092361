import SupplierList from "features/supplier/list/SupplierList";
import usePathParams from "hooks/usePathParams";

const SupplierPage = () => {
  const [pathParams] = usePathParams();

  let com = null;

  switch (pathParams.action) {
    case "edit":
      com = <SupplierList isEdit editId={Number(pathParams.id)} />;
      break;
    default:
      com = <SupplierList />;
  }

  return com;
};

export default SupplierPage;
