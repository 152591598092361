import { Divider, Flex, Space } from "antd";
import Order from "common/constants/Order";
import { Nav } from "common/types/PageNavigation";
import Helper from "common/utils/helper";
import PageHeader from "components/page/PageHeader";
import TextDateTime from "components/TextDateTime";
import { useTranslation } from "react-i18next";
import useEditingSaleOrderStore from "zustands/useEditingSaleOrder";
import OrderChannel from "../components/OrderChannel";
import OrderModel from "common/models/OrderModel";
import { CheckCircleFilled } from "@ant-design/icons";

const OrderFormHeader = ({
	isEditing,
	id,
	type,
}: {
	isEditing: boolean;
	id: number | string;
	type?: number;
}) => {
	const { t } = useTranslation();
	const order = useEditingSaleOrderStore((state) => state.order);

	const nav: Nav = [
		{
			text: t("order:heading_nav"),
			link: order.type === Order.TYPE_INVOICE ? "/invoice" : "/order",
		},
	];

	return (
		<PageHeader
			nav={nav}
			heading={
				t(
					isEditing
						? type === Order.TYPE_INVOICE
							? "order:heading_edit_invoice"
							: "order:heading_edit"
						: "order:heading_add"
				) + (Helper.checkEditOrder(id) ? " #" + id : "")
			}
			afterHeading={
				<Space split={<Divider type="vertical" />}>
					<TextDateTime format="HH:mm, DD/MM/YYYY" ts={order.date_created} />
					<Space>
						Kênh bán hàng: <OrderChannel channelId={order.sale_channel} />
					</Space>
					{OrderModel.getSeviceType(order.service_type)?.label}
					{order.using_cod && "Có COD"}
				</Space>
			}
			siteMenuSelectedKey={
				order.type === Order.TYPE_INVOICE ? "/invoice" : "/order"
			}
			siteMenuHidden
		/>
	);
};

export default OrderFormHeader;
