import { Form, Input } from "antd";
import WarehouseModel from "common/models/WarehouseModel";
import { Filter } from "common/types/Filter";
import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import { useTranslation } from "react-i18next";

const WarehouseListFilter = <F extends Filter>({
  filters,
  setFilters,
  defaultFilters,
  total,
}: {
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
  total: number;
}) => {
  const { t } = useTranslation();

  return (
    <PageDataFilterForm
      filters={filters}
      setFilters={setFilters}
      total={total}
      defaultFilters={defaultFilters}
    >
      <Form.Item name="keyword">
        <Input
          allowClear
          placeholder={t("warehouse:filter_keyword_placeholder")}
        />
      </Form.Item>
      <FormSelect
        name="type"
        placeholder={t("warehouse:warehouse_type_placeholder")}
        options={WarehouseModel.getTypeList()}
        allowClear
        popupMatchSelectWidth={false}
      />
      <FormSelect
        name="status"
        placeholder={t("warehouse:warehouse_status_placeholder")}
        options={WarehouseModel.getStatusList()}
        allowClear
        popupMatchSelectWidth={false}
      />
      <FormSelect
        name="direction_in"
        label={t("warehouse:direction_in")}
        placeholder={t("warehouse:direction_in")}
        options={WarehouseModel.getCheckboxOptionList()}
        allowClear
        popupMatchSelectWidth={false}
      />
      <FormSelect
        name="direction_out"
        label={t("warehouse:direction_out")}
        placeholder={t("warehouse:direction_out")}
        options={WarehouseModel.getCheckboxOptionList()}
        allowClear
        popupMatchSelectWidth={false}
      />
      <FormSelect
        name="apply_with_other"
        label={t("warehouse:apply_with_other")}
        placeholder={t("warehouse:apply_with_other")}
        options={WarehouseModel.getCheckboxOptionList()}
        allowClear
        popupMatchSelectWidth={false}
      />
    </PageDataFilterForm>
  );
};

export default WarehouseListFilter;
