import { Empty, FormItemProps, Typography } from "antd";
import ProductCollectionCollection from "common/collections/ProductCollectionCollection";
import ProductCollectionRepository from "common/repositories/ProductCollectionRepository";
import StoreRepository from "common/repositories/StoreRepository";
import { CustomerJson, FilterCustomer } from "common/types/Customer";
import {
	ProductCollectionJson,
	FilterProductCollection,
} from "common/types/ProductCollection";
import MultiSearchLayout from "components/search/MultiSearchLayout";
import SearchComponent from "components/search/SearchLayout";
import { useTranslation } from "react-i18next";
type Props = FormItemProps & {
	disabled?: boolean;
	isMulti?: boolean;
	defaultFilters?: Partial<FilterProductCollection>;
};

const ProductCollectionSearch = (props: Props) => {
	const { defaultFilters } = props;
	const { t } = useTranslation();
	if (props?.isMulti) {
		return (
			<>
				<MultiSearchLayout<
					ProductCollectionJson,
					FilterProductCollection,
					ProductCollectionCollection
				>
					fetchRepository={(filers) => {
						return new ProductCollectionRepository().getItems({
							filters: filers,
						});
					}}
					defaultFilters={{
						...ProductCollectionRepository.getDefaultFilters(),
						...defaultFilters,
					}}
					formItemProps={{
						...props,
					}}
					disabled={props.disabled}
					keyValue="id"
					showFilters={["keyword"]}
					defaultUseFilters={["keyword"]}
					renderLabel={(item) => {
						return <>{item.title}</>;
					}}
				/>
			</>
		);
	}
	return (
		<>
			<SearchComponent<
				ProductCollectionJson,
				FilterProductCollection,
				ProductCollectionCollection
			>
				fetchRepository={(filers) => {
					return new ProductCollectionRepository().getItems({
						filters: filers,
					});
				}}
				defaultFilters={ProductCollectionRepository.getDefaultFilters()}
				formItemProps={{
					...props,
				}}
				disabled={props.disabled}
				keyValue="id"
				showFilters={["keyword"]}
				defaultUseFilters={["keyword"]}
				renderLabel={(item) => {
					return <>{item.title}</>;
				}}
			/>
		</>
	);
};

export default ProductCollectionSearch;
