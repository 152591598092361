import { IconPhoto, IconTrash } from "@tabler/icons-react";
import {
	Button,
	Checkbox,
	Col,
	Collapse,
	ColorPicker,
	DatePicker,
	Form,
	Image,
	Input,
	Modal,
	Popconfirm,
	Row,
	Typography,
} from "antd";
import { MegaMenuCategory, MegaMenuJson } from "common/types/ShopMegaMenu";
import Helper from "common/utils/helper";
import FileUploaderButton from "components/file/uploader/FileUploaderButton";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ShopMegaMenuDetailForm from "./ShopMegaMenuDetailForm";

export default function ShopMegaMenuForm({
	data,
	open,
	type = "add",
	onFinish,
	onCancel,
}: {
	type: "add" | "edit";
	data: MegaMenuJson;
	open: boolean;
	onFinish: (data: MegaMenuJson) => void;
	onCancel: () => void;
}) {
	const { t } = useTranslation();

	const [form] = Form.useForm<any>();
	// MegaMenuJson
	const [imageFirst, setImageFirst] = useState<string>("");
	const [imageSecond, setImageSecond] = useState<string>("");
	const [imageThird, setImageThird] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const [hasSubMenu, setHasSubMenu] = useState<boolean>(
		data.submenu && data.submenu.hasOwnProperty("category") ? true : false
	);
	const [countCategory, setCountCategory] = useState<number>(0);

	const initialValues = {
		name: data.name.length > 0 ? data.name : "",
		from_time: data.from_time > 0 ? dayjs(new Date(data.from_time * 1000)) : 0,
		to_time: data.to_time > 0 ? dayjs(new Date(data.to_time * 1000)) : 0,
		link: data.link,
		is_event: data.is_event ? data.is_event : false,
		style: data.style,
		hide_in_page: data.hide_in_page,
	};

	const doPrepareData = useCallback(
		(formData: any) => {
			console.log(hasSubMenu);
			let newCategories: MegaMenuCategory[] = [];

			if (countCategory > 0) {
				for (let i = 0; i < countCategory; i++) {
					newCategories.push({
						id: i + 1,
						type: formData[`type_menu_${i + 1}`]
							? formData[`type_menu_${i + 1}`]
							: data.submenu?.category[i].type || "",
						details: formData[`detail_menu_${i + 1}`]
							? formData[`detail_menu_${i + 1}`]
							: data.submenu?.category[i].details,
					});
				}
			}

			const submitData: any = {
				...data,
				id:
					data.id && data.id.toString().length > 0
						? Number(data.id)
						: undefined,

				link:
					formData.link.length === 0 || formData.link === "#"
						? "#"
						: formData.link,
				name: formData.name.trim(),

				from_time:
					Helper.datePickerValueToTimestamp(formData.from_time) > 0
						? Helper.datePickerValueToTimestamp(formData.from_time)
						: 0,
				to_time:
					Helper.datePickerValueToTimestamp(formData.to_time) > 0
						? Helper.datePickerValueToTimestamp(formData.to_time)
						: 0,
				is_event: formData.is_event ? true : undefined,
				submenu: !hasSubMenu
					? null
					: {
							category:
								newCategories.length > 0
									? [...newCategories]
									: data?.submenu?.category
									? [...data?.submenu?.category]
									: [],
							images: [
								{
									url: imageFirst,
									alt: "first",
								},
								{ url: imageSecond, alt: "second" },
								{ url: imageThird, alt: "third" },
							],
					  },
				style: {
					color: formData.textcolor,
				},
				hide_in_page: formData.hide_in_page,
			};

			return submitData;
		},
		[data, imageFirst, imageSecond, imageThird, hasSubMenu, countCategory]
	);

	const onSubmit = async (formData: any) => {
		setLoading(true);
		await onFinish({
			...data,
			...doPrepareData(formData),
		});
		setLoading(false);
	};

	const handleOnClose = () => {
		// form.resetFields();
		// setCountCategory(0);
		// setImageFirst("");
		// setImageSecond("");
		// setImageThird("");
		onCancel();
	};

	const menuLdev = [
		{
			link: "/pages/sinh-nhat-11-nam",
			name: "BIRTHDAY",
			to_time: 1725123544,
			from_time: 1722790800,
			is_event: true,
			submenu: null,
			hide_in_page: true,
			style: {
				color: "#d72229",
			},
		},
		{
			name: "namperfume Favorites",
			link: "/blogs/thuong-hieu-nuoc-hoa/namperfume-favorites",
			is_event: true,
			submenu: null,
			from_time: 1712136268,
			to_time: 0,
			id: 2,
			style: {
				color: "#d72229",
			},
		},
		{
			name: "Deal Thơm",
			link: "/collections/deal-thom",
			submenu: null,
			is_event: true,
			from_time: 1712136268,
			to_time: 0,
			id: 1,
			style: {
				color: "#3a393a",
			},
		},
		{
			name: "Nước Hoa Nam",
			link: "/collections/nuoc-hoa-nam",
			submenu: {
				category: [
					{
						id: 1,
						type: "Phân loại",
						details: [
							{
								link: "/collections/nuoc-hoa-nam?sort_by=date_created&sort_type=DESC&page=1",
								title: "Mới nhất",
							},
							{
								link: "/collections/nuoc-hoa-nam?sort_by=best_seller&sort_type=DESC&page=1",
								title: "Yêu thích nhất",
							},
							{
								link: "/collections/nuoc-hoa-niche",
								title: "Nước hoa Niche",
							},
							{
								link: "/collections/giftset-nam",
								title: "Giftset",
							},
							{
								link: "/collections/nuoc-hoa-unisex",
								title: "Nước hoa unisex",
							},
							{
								link: "/collections/nuoc-hoa-mini",
								title: "Nước hoa mini",
							},
						],
					},
					{
						id: 2,
						type: "Thương hiệu",
						details: [
							{
								title: "GUCCI",
								link: "/blogs/thuong-hieu-nuoc-hoa/gucci",
							},
							{
								title: "CALVIN KLEIN",
								link: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
							},
							{
								title: "NARCISO RODRIGUEZ",
								link: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
							},
							{
								title: "CHANEL",
								link: "/blogs/thuong-hieu-nuoc-hoa/chanel",
							},
							{
								title: "BURBERRY",
								link: "/blogs/thuong-hieu-nuoc-hoa/burberry",
							},
							{
								title: "BVLGARI",
								link: "/blogs/thuong-hieu-nuoc-hoa/bvlgari",
							},
							{
								title: "VERSACE",
								link: "/blogs/thuong-hieu-nuoc-hoa/versace",
							},
							{
								title: "HERMES",
								link: "/blogs/thuong-hieu-nuoc-hoa/hermes",
							},
							{
								title: "JO MALONE",
								link: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
							},
							{
								title: "CHRISTIAN DIOR",
								link: "/blogs/thuong-hieu-nuoc-hoa/christian-dior",
							},
							{
								title: "VALENTINO",
								link: "/blogs/thuong-hieu-nuoc-hoa/valentino",
							},
							{
								title: "HUGO BOSS",
								link: "/blogs/thuong-hieu-nuoc-hoa/hugoboss",
							},
							{
								title: "CAROLINA HERRERA",
								link: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
							},
							{
								title: "JEAN PAUL GAULTIER",
								link: "/blogs/thuong-hieu-nuoc-hoa/jean-paul-gaultier",
							},
							{
								title: "YVES SAINT LAURENT",
								link: "/blogs/thuong-hieu-nuoc-hoa/yves-saint-laurent",
							},
							{
								title: "Xem tất cả",
								link: "/collections/all",
							},
						],
					},
				],
				images: [
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41754-579051721185703-1721185703.jpg",
						alt: "first",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41755-698421721185710-1721185710.jpg",
						alt: "second",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41756-814521721185712-1721185712.jpg",
						alt: "third",
					},
				],
			},
			from_time: 0,
			to_time: 0,
			style: {
				color: "#3a393a",
			},
		},
		{
			name: "Nước Hoa Nữ",
			link: "/collections/nuoc-hoa-nu",
			submenu: {
				category: [
					{
						id: 1,
						type: "Phân loại",
						details: [
							{
								link: "/collections/nuoc-hoa-nu?sort_by=date_created&sort_type=DESC&page=1",
								title: "Mới nhất",
							},
							{
								link: "/collections/nuoc-hoa-nu?sort_by=best_seller&sort_type=DESC&page=1",
								title: "Yêu thích nhất",
							},
							{
								link: "/collections/nuoc-hoa-niche",
								title: "Nước hoa Niche",
							},
							{
								link: "/collections/giftset-nu",
								title: "Giftset",
							},
							{
								link: "/collections/nuoc-hoa-unisex",
								title: "Nước hoa unisex",
							},
							{
								link: "/collections/nuoc-hoa-mini",
								title: "Nước hoa mini",
							},
						],
					},
					{
						id: 2,
						type: "Thương hiệu",
						details: [
							{
								title: "GUCCI",
								link: "/blogs/thuong-hieu-nuoc-hoa/gucci",
							},
							{
								title: "CALVIN KLEIN",
								link: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
							},
							{
								title: "NARCISO RODRIGUEZ",
								link: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
							},
							{
								title: "CHANEL",
								link: "/blogs/thuong-hieu-nuoc-hoa/chanel",
							},
							{
								title: "BURBERRY",
								link: "/blogs/thuong-hieu-nuoc-hoa/burberry",
							},
							{
								title: "BVLGARI",
								link: "/blogs/thuong-hieu-nuoc-hoa/bvlgari",
							},
							{
								title: "VERSACE",
								link: "/blogs/thuong-hieu-nuoc-hoa/versace",
							},
							{
								title: "HERMES",
								link: "/blogs/thuong-hieu-nuoc-hoa/hermes",
							},
							{
								title: "JO MALONE",
								link: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
							},
							{
								title: "CHRISTIAN DIOR",
								link: "/blogs/thuong-hieu-nuoc-hoa/christian-dior",
							},
							{
								title: "VALENTINO",
								link: "/blogs/thuong-hieu-nuoc-hoa/valentino",
							},
							{
								title: "HUGO BOSS",
								link: "/blogs/thuong-hieu-nuoc-hoa/hugoboss",
							},
							{
								title: "CAROLINA HERRERA",
								link: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
							},
							{
								title: "JEAN PAUL GAULTIER",
								link: "/blogs/thuong-hieu-nuoc-hoa/jean-paul-gaultier",
							},
							{
								title: "YVES SAINT LAURENT",
								link: "/blogs/thuong-hieu-nuoc-hoa/yves-saint-laurent",
							},
							{
								title: "Xem tất cả",
								link: "/collections/all",
							},
						],
					},
				],
				images: [
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41757-407431721185814-1721185814.jpg",
						alt: "first",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41758-874371721185816-1721185816.jpg",
						alt: "second",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41759-135341721185819-1721185819.jpg",
						alt: "third",
					},
				],
			},
			from_time: 0,
			to_time: 0,
			style: {
				color: "#3a393a",
			},
		},
		{
			name: "Nước Hoa Mini",
			link: "/collections/nuoc-hoa-mini",
			submenu: {
				category: [
					{
						id: 1,
						type: "Phân loại",
						details: [
							{
								link: "/collections/nuoc-hoa-mini?sort_by=date_created&sort_type=DESC&page=1",
								title: "Mới nhất",
							},
							{
								link: "/collections/nuoc-hoa-mini?sort_by=best_seller&sort_type=DESC&page=1",
								title: "Yêu thích nhất",
							},
							{
								link: "/collections/nuoc-hoa-niche",
								title: "Nước hoa Niche",
							},
							{
								link: "/collections/giftset-nuoc-hoa-mini",
								title: "Giftset",
							},
							{
								link: "/collections/nuoc-hoa-unisex",
								title: "Nước hoa unisex",
							},
							{
								link: "/collections/nuoc-hoa-mini",
								title: "Nước hoa mini",
							},
						],
					},
					{
						id: 2,
						type: "Thương hiệu",
						details: [
							{
								title: "GUCCI",
								link: "/blogs/thuong-hieu-nuoc-hoa/gucci",
							},
							{
								title: "CALVIN KLEIN",
								link: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
							},
							{
								title: "NARCISO RODRIGUEZ",
								link: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
							},
							{
								title: "CHANEL",
								link: "/blogs/thuong-hieu-nuoc-hoa/chanel",
							},
							{
								title: "BURBERRY",
								link: "/blogs/thuong-hieu-nuoc-hoa/burberry",
							},
							{
								title: "BVLGARI",
								link: "/blogs/thuong-hieu-nuoc-hoa/bvlgari",
							},
							{
								title: "VERSACE",
								link: "/blogs/thuong-hieu-nuoc-hoa/versace",
							},
							{
								title: "HERMES",
								link: "/blogs/thuong-hieu-nuoc-hoa/hermes",
							},
							{
								title: "JO MALONE",
								link: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
							},
							{
								title: "CHRISTIAN DIOR",
								link: "/blogs/thuong-hieu-nuoc-hoa/christian-dior",
							},
							{
								title: "VALENTINO",
								link: "/blogs/thuong-hieu-nuoc-hoa/valentino",
							},
							{
								title: "HUGO BOSS",
								link: "/blogs/thuong-hieu-nuoc-hoa/hugoboss",
							},
							{
								title: "CAROLINA HERRERA",
								link: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
							},
							{
								title: "JEAN PAUL GAULTIER",
								link: "/blogs/thuong-hieu-nuoc-hoa/jean-paul-gaultier",
							},
							{
								title: "YVES SAINT LAURENT",
								link: "/blogs/thuong-hieu-nuoc-hoa/yves-saint-laurent",
							},
							{
								title: "Xem tất cả",
								link: "/collections/all",
							},
						],
					},
				],
				images: [
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41760-235331721186216-1721186216.jpg",
						alt: "first",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41761-877821721186218-1721186218.jpg",
						alt: "second",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41762-250731721186220-1721186220.jpg",
						alt: "third",
					},
				],
			},
			from_time: 0,
			to_time: 0,
			style: {
				color: "#3a393a",
			},
		},
		{
			name: "Giftset",
			link: "/collections/giftset-nuoc-hoa",
			submenu: {
				category: [
					{
						id: 1,
						type: "Phân loại",
						details: [
							{
								link: "/collections/giftset-nam",
								title: "Gift set nước hoa nam",
							},
							{
								link: "/collections/giftset-nu",
								title: "Gift set nước hoa nữ",
							},
							{
								link: "/collections/giftset-nuoc-hoa-mini",
								title: "Gift set nước hoa mini",
							},
						],
					},
					{
						id: 2,
						type: "Mức giá",
						details: [
							{
								title: "Dưới 1.000.000",
								link: "/collections/giftset-nuoc-hoa?price_max=1000000&price_min=0&page=1",
							},
							{
								title: "Dưới 2.000.000",
								link: "/collections/giftset-nuoc-hoa?price_max=2000000&price_min=0&page=1",
							},
							{
								title: "Trên 2.000.000",
								link: "/collections/giftset-nuoc-hoa?price_min=2000000&page=1",
							},
						],
					},
				],
				images: [
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41763-202711721186317-1721186317.jpg",
						alt: "first",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41764-534361721186319-1721186319.jpg",
						alt: "second",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41765-860271721186321-1721186321.jpg",
						alt: "third",
					},
				],
			},
			from_time: 0,
			to_time: 0,
			style: {
				color: "#3a393a",
			},
		},
		{
			name: "Nước Hoa Niche",
			link: "/collections/nuoc-hoa-niche",
			submenu: null,
			style: {
				color: "#3a393a",
			},
		},
		{
			name: "Thương hiệu",
			link: "/pages/thuong-hieu-a-z",
			submenu: {
				category: [
					{
						id: 1,
						type: "Thương hiệu mới",
						details: [
							{
								link: "/blogs/thuong-hieu-nuoc-hoa/maison-margiela",
								title: "MAISON MARGIELA",
							},
							{
								link: "/blogs/thuong-hieu-nuoc-hoa/kilian",
								title: "KILIAN",
							},
							{
								link: "/blogs/thuong-hieu-nuoc-hoa/mancera",
								title: "MANCERA",
							},
							{
								link: "/blogs/thuong-hieu-nuoc-hoa/parfums-de-marly",
								title: "PERFUMS DE MARLY",
							},
							{
								link: "/blogs/thuong-hieu-nuoc-hoa/creed",
								title: "CREED",
							},
							{
								link: "",
								title: "",
							},
							{
								link: "/pages/thuong-hieu-a-z",
								title: "Thương hiệu A-Z",
							},
						],
					},
					{
						id: 2,
						type: "Thương hiệu",
						details: [
							{
								title: "GUCCI",
								link: "/blogs/thuong-hieu-nuoc-hoa/gucci",
							},
							{
								title: "CALVIN KLEIN",
								link: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
							},
							{
								title: "NARCISO RODRIGUEZ",
								link: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
							},
							{
								title: "CHANEL",
								link: "/blogs/thuong-hieu-nuoc-hoa/chanel",
							},
							{
								title: "BURBERRY",
								link: "/blogs/thuong-hieu-nuoc-hoa/burberry",
							},
							{
								title: "BVLGARI",
								link: "/blogs/thuong-hieu-nuoc-hoa/bvlgari",
							},
							{
								title: "VERSACE",
								link: "/blogs/thuong-hieu-nuoc-hoa/versace",
							},
							{
								title: "HERMES",
								link: "/blogs/thuong-hieu-nuoc-hoa/hermes",
							},
							{
								title: "JO MALONE",
								link: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
							},
							{
								title: "CHRISTIAN DIOR",
								link: "/blogs/thuong-hieu-nuoc-hoa/christian-dior",
							},
							{
								title: "VALENTINO",
								link: "/blogs/thuong-hieu-nuoc-hoa/valentino",
							},
							{
								title: "HUGO BOSS",
								link: "/blogs/thuong-hieu-nuoc-hoa/hugoboss",
							},
							{
								title: "CAROLINA HERRERA",
								link: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
							},
							{
								title: "JEAN PAUL GAULTIER",
								link: "/blogs/thuong-hieu-nuoc-hoa/jean-paul-gaultier",
							},
							{
								title: "YVES SAINT LAURENT",
								link: "/blogs/thuong-hieu-nuoc-hoa/yves-saint-laurent",
							},
						],
					},
				],
				images: [
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41766-149571721186423-1721186423.jpg",
						alt: "first",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41767-792551721186425-1721186425.jpg",
						alt: "second",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41768-496611721186427-1721186427.jpg",
						alt: "third",
					},
				],
			},
			from_time: 0,
			to_time: 0,
			style: {
				color: "#3a393a",
			},
		},
		{
			name: "Bodycare & Homecare",
			link: "/collections/bodycare-homecare",
			submenu: {
				category: [
					{
						id: 1,
						type: "Phân loại",
						details: [
							{
								link: "/collections/cham-soc-toc",
								title: "Chăm sóc tóc",
							},
							{
								link: "/collections/cham-soc-da",
								title: "Chăm sóc da",
							},
							{
								link: "/collections/tinh-dau-nuoc-hoa",
								title: "Tinh dầu nước hoa",
							},
							{
								link: "/collections/lan-khu-mui",
								title: "Lăn khử mùi",
							},
							{
								link: "/collections/thuc-pham-chuc-nang",
								title: "Thực phẩm chức năng",
							},
							{
								link: "/collections/qua-tang",
								title: "Quà Tặng",
							},
							{
								link: "/collections/hop-qua",
								title: "Hộp quà",
							},
						],
					},
				],
				images: [
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41770-788861721186550-1721186550.jpg",
						alt: "first",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41769-750951721186550-1721186550.jpg",
						alt: "second",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41771-798141721186553-1721186553.jpg",
						alt: "third",
					},
				],
			},
			from_time: 0,
			to_time: 0,
			style: {
				color: "#3a393a",
			},
		},
		{
			name: "Son Môi",
			link: "/collections/son-moi",
			submenu: null,
		},
	];

	const menuBeta = [
		{
			link: "/pages/sinh-nhat-11-nam",
			name: "BIRTHDAY",
			to_time: 1725123544,
			from_time: 1722790800,
			is_event: true,
			submenu: null,
			hide_in_page: true,
			style: {
				color: "#d72229",
			},
		},
		{
			name: "namperfume Favorites",
			link: "/blogs/thuong-hieu-nuoc-hoa/namperfume-favorites",
			is_event: true,
			submenu: null,
			from_time: 1712136268,
			to_time: 0,
			id: 2,
			style: {
				color: "#d72229",
			},
		},
		{
			name: "Deal Thơm",
			link: "/collections/deal-thom",
			submenu: null,
			is_event: true,
			from_time: 1712136268,
			to_time: 1722759309,
			id: 1,
			style: {
				color: "#3a393a",
			},
		},
		{
			name: "Nước Hoa Nam",
			link: "/collections/nuoc-hoa-nam",
			from_time: 0,
			to_time: 0,
			style: {
				color: "#3a393a",
			},
			submenu: {
				category: [
					{
						id: 1,
						type: "Phân loại",
						details: [
							{
								link: "/collections/nuoc-hoa-nam?sort_by=date_created&sort_type=DESC&page=1",
								title: "Mới nhất",
							},
							{
								link: "/collections/nuoc-hoa-nam?sort_by=best_seller&sort_type=DESC&page=1",
								title: "Yêu thích nhất",
							},
							{
								link: "/collections/nuoc-hoa-niche",
								title: "Nước hoa Niche",
							},
							{
								link: "/collections/giftset-nam",
								title: "Giftset",
							},
							{
								link: "/collections/nuoc-hoa-unisex",
								title: "Nước hoa unisex",
							},
							{
								link: "/collections/nuoc-hoa-mini",
								title: "Nước hoa mini",
							},
						],
					},
					{
						id: 2,
						type: "Thương hiệu",
						details: [
							{
								title: "GUCCI",
								link: "/blogs/thuong-hieu-nuoc-hoa/gucci",
							},
							{
								title: "CALVIN KLEIN",
								link: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
							},
							{
								title: "NARCISO RODRIGUEZ",
								link: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
							},
							{
								title: "CHANEL",
								link: "/blogs/thuong-hieu-nuoc-hoa/chanel",
							},
							{
								title: "BURBERRY",
								link: "/blogs/thuong-hieu-nuoc-hoa/burberry",
							},
							{
								title: "BVLGARI",
								link: "/blogs/thuong-hieu-nuoc-hoa/bvlgari",
							},
							{
								title: "VERSACE",
								link: "/blogs/thuong-hieu-nuoc-hoa/versace",
							},
							{
								title: "HERMES",
								link: "/blogs/thuong-hieu-nuoc-hoa/hermes",
							},
							{
								title: "JO MALONE",
								link: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
							},
							{
								title: "CHRISTIAN DIOR",
								link: "/blogs/thuong-hieu-nuoc-hoa/christian-dior",
							},
							{
								title: "VALENTINO",
								link: "/blogs/thuong-hieu-nuoc-hoa/valentino",
							},
							{
								title: "HUGO BOSS",
								link: "/blogs/thuong-hieu-nuoc-hoa/hugoboss",
							},
							{
								title: "CAROLINA HERRERA",
								link: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
							},
							{
								title: "JEAN PAUL GAULTIER",
								link: "/blogs/thuong-hieu-nuoc-hoa/jean-paul-gaultier",
							},
							{
								title: "YVES SAINT LAURENT",
								link: "/blogs/thuong-hieu-nuoc-hoa/yves-saint-laurent",
							},
							{
								title: "Xem tất cả",
								link: "/collections/all",
							},
						],
					},
				],
				images: [
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41754-579051721185703-1721185703.jpg",
						alt: "first",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41755-698421721185710-1721185710.jpg",
						alt: "second",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41756-814521721185712-1721185712.jpg",
						alt: "third",
					},
				],
			},
		},
		{
			name: "Nước Hoa Nữ",
			link: "/collections/nuoc-hoa-nu",
			submenu: {
				category: [
					{
						id: 1,
						type: "Phân loại",
						details: [
							{
								link: "/collections/nuoc-hoa-nu?sort_by=date_created&sort_type=DESC&page=1",
								title: "Mới nhất",
							},
							{
								link: "/collections/nuoc-hoa-nu?sort_by=best_seller&sort_type=DESC&page=1",
								title: "Yêu thích nhất",
							},
							{
								link: "/collections/nuoc-hoa-niche",
								title: "Nước hoa Niche",
							},
							{
								link: "/collections/giftset-nu",
								title: "Giftset",
							},
							{
								link: "/collections/nuoc-hoa-unisex",
								title: "Nước hoa unisex",
							},
							{
								link: "/collections/nuoc-hoa-mini",
								title: "Nước hoa mini",
							},
						],
					},
					{
						id: 2,
						type: "Thương hiệu",
						details: [
							{
								title: "GUCCI",
								link: "/blogs/thuong-hieu-nuoc-hoa/gucci",
							},
							{
								title: "CALVIN KLEIN",
								link: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
							},
							{
								title: "NARCISO RODRIGUEZ",
								link: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
							},
							{
								title: "CHANEL",
								link: "/blogs/thuong-hieu-nuoc-hoa/chanel",
							},
							{
								title: "BURBERRY",
								link: "/blogs/thuong-hieu-nuoc-hoa/burberry",
							},
							{
								title: "BVLGARI",
								link: "/blogs/thuong-hieu-nuoc-hoa/bvlgari",
							},
							{
								title: "VERSACE",
								link: "/blogs/thuong-hieu-nuoc-hoa/versace",
							},
							{
								title: "HERMES",
								link: "/blogs/thuong-hieu-nuoc-hoa/hermes",
							},
							{
								title: "JO MALONE",
								link: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
							},
							{
								title: "CHRISTIAN DIOR",
								link: "/blogs/thuong-hieu-nuoc-hoa/christian-dior",
							},
							{
								title: "VALENTINO",
								link: "/blogs/thuong-hieu-nuoc-hoa/valentino",
							},
							{
								title: "HUGO BOSS",
								link: "/blogs/thuong-hieu-nuoc-hoa/hugoboss",
							},
							{
								title: "CAROLINA HERRERA",
								link: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
							},
							{
								title: "JEAN PAUL GAULTIER",
								link: "/blogs/thuong-hieu-nuoc-hoa/jean-paul-gaultier",
							},
							{
								title: "YVES SAINT LAURENT",
								link: "/blogs/thuong-hieu-nuoc-hoa/yves-saint-laurent",
							},
							{
								title: "Xem tất cả",
								link: "/collections/all",
							},
						],
					},
				],
				images: [
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41757-407431721185814-1721185814.jpg",
						alt: "first",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41758-874371721185816-1721185816.jpg",
						alt: "second",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41759-135341721185819-1721185819.jpg",
						alt: "third",
					},
				],
			},
			from_time: 0,
			to_time: 0,
			style: {
				color: "#3a393a",
			},
		},
		{
			name: "Nước Hoa Mini",
			link: "/collections/nuoc-hoa-mini",
			submenu: {
				category: [
					{
						id: 1,
						type: "Phân loại",
						details: [
							{
								link: "/collections/nuoc-hoa-mini?sort_by=date_created&sort_type=DESC&page=1",
								title: "Mới nhất",
							},
							{
								link: "/collections/nuoc-hoa-mini?sort_by=best_seller&sort_type=DESC&page=1",
								title: "Yêu thích nhất",
							},
							{
								link: "/collections/nuoc-hoa-niche",
								title: "Nước hoa Niche",
							},
							{
								link: "/collections/giftset-nuoc-hoa-mini",
								title: "Giftset",
							},
							{
								link: "/collections/nuoc-hoa-unisex",
								title: "Nước hoa unisex",
							},
							{
								link: "/collections/nuoc-hoa-mini",
								title: "Nước hoa mini",
							},
						],
					},
					{
						id: 2,
						type: "Thương hiệu",
						details: [
							{
								title: "GUCCI",
								link: "/blogs/thuong-hieu-nuoc-hoa/gucci",
							},
							{
								title: "CALVIN KLEIN",
								link: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
							},
							{
								title: "NARCISO RODRIGUEZ",
								link: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
							},
							{
								title: "CHANEL",
								link: "/blogs/thuong-hieu-nuoc-hoa/chanel",
							},
							{
								title: "BURBERRY",
								link: "/blogs/thuong-hieu-nuoc-hoa/burberry",
							},
							{
								title: "BVLGARI",
								link: "/blogs/thuong-hieu-nuoc-hoa/bvlgari",
							},
							{
								title: "VERSACE",
								link: "/blogs/thuong-hieu-nuoc-hoa/versace",
							},
							{
								title: "HERMES",
								link: "/blogs/thuong-hieu-nuoc-hoa/hermes",
							},
							{
								title: "JO MALONE",
								link: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
							},
							{
								title: "CHRISTIAN DIOR",
								link: "/blogs/thuong-hieu-nuoc-hoa/christian-dior",
							},
							{
								title: "VALENTINO",
								link: "/blogs/thuong-hieu-nuoc-hoa/valentino",
							},
							{
								title: "HUGO BOSS",
								link: "/blogs/thuong-hieu-nuoc-hoa/hugoboss",
							},
							{
								title: "CAROLINA HERRERA",
								link: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
							},
							{
								title: "JEAN PAUL GAULTIER",
								link: "/blogs/thuong-hieu-nuoc-hoa/jean-paul-gaultier",
							},
							{
								title: "YVES SAINT LAURENT",
								link: "/blogs/thuong-hieu-nuoc-hoa/yves-saint-laurent",
							},
							{
								title: "Xem tất cả",
								link: "/collections/all",
							},
						],
					},
				],
				images: [
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41760-235331721186216-1721186216.jpg",
						alt: "first",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41761-877821721186218-1721186218.jpg",
						alt: "second",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41762-250731721186220-1721186220.jpg",
						alt: "third",
					},
				],
			},
			from_time: 0,
			to_time: 0,
			style: {
				color: "#3a393a",
			},
		},
		{
			name: "Giftset",
			link: "/collections/giftset-nuoc-hoa",
			submenu: {
				category: [
					{
						id: 1,
						type: "Phân loại",
						details: [
							{
								link: "/collections/giftset-nam",
								title: "Giftset nước hoa nam",
							},
							{
								link: "/collections/giftset-nu",
								title: "Giftset nước hoa nữ",
							},
							{
								link: "/collections/giftset-nuoc-hoa-mini",
								title: "Giftset nước hoa mini",
							},
						],
					},
					{
						id: 2,
						type: "Mức giá",
						details: [
							{
								title: "Dưới 1.000.000",
								link: "/collections/giftset-nuoc-hoa?price_max=1000000&price_min=0&page=1",
							},
							{
								title: "Dưới 2.000.000",
								link: "/collections/giftset-nuoc-hoa?price_max=2000000&price_min=0&page=1",
							},
							{
								title: "Trên 2.000.000",
								link: "/collections/giftset-nuoc-hoa?price_min=2000000&page=1",
							},
						],
					},
				],
				images: [
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41763-202711721186317-1721186317.jpg",
						alt: "first",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41764-534361721186319-1721186319.jpg",
						alt: "second",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41765-860271721186321-1721186321.jpg",
						alt: "third",
					},
				],
			},
			from_time: 0,
			to_time: 0,
			style: {
				color: "#3a393a",
			},
		},
		{
			name: "Nước Hoa Niche",
			link: "/collections/nuoc-hoa-niche",
			submenu: null,
			style: {
				color: "#3a393a",
			},
		},
		{
			name: "Thương hiệu",
			link: "/pages/thuong-hieu-a-z",
			submenu: {
				category: [
					{
						id: 1,
						type: "Thương hiệu mới",
						details: [
							{
								link: "/blogs/thuong-hieu-nuoc-hoa/maison-margiela",
								title: "MAISON MARGIELA",
							},
							{
								link: "/blogs/thuong-hieu-nuoc-hoa/kilian",
								title: "KILIAN",
							},
							{
								link: "/blogs/thuong-hieu-nuoc-hoa/mancera",
								title: "MANCERA",
							},
							{
								link: "/blogs/thuong-hieu-nuoc-hoa/parfums-de-marly",
								title: "PERFUMS DE MARLY",
							},
							{
								link: "/blogs/thuong-hieu-nuoc-hoa/creed",
								title: "CREED",
							},
							{
								link: "",
								title: "",
							},
							{
								link: "/pages/thuong-hieu-a-z",
								title: "Thương hiệu A-Z",
							},
						],
					},
					{
						id: 2,
						type: "Thương hiệu",
						details: [
							{
								title: "GUCCI",
								link: "/blogs/thuong-hieu-nuoc-hoa/gucci",
							},
							{
								title: "CALVIN KLEIN",
								link: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
							},
							{
								title: "NARCISO RODRIGUEZ",
								link: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
							},
							{
								title: "CHANEL",
								link: "/blogs/thuong-hieu-nuoc-hoa/chanel",
							},
							{
								title: "BURBERRY",
								link: "/blogs/thuong-hieu-nuoc-hoa/burberry",
							},
							{
								title: "BVLGARI",
								link: "/blogs/thuong-hieu-nuoc-hoa/bvlgari",
							},
							{
								title: "VERSACE",
								link: "/blogs/thuong-hieu-nuoc-hoa/versace",
							},
							{
								title: "HERMES",
								link: "/blogs/thuong-hieu-nuoc-hoa/hermes",
							},
							{
								title: "JO MALONE",
								link: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
							},
							{
								title: "CHRISTIAN DIOR",
								link: "/blogs/thuong-hieu-nuoc-hoa/christian-dior",
							},
							{
								title: "VALENTINO",
								link: "/blogs/thuong-hieu-nuoc-hoa/valentino",
							},
							{
								title: "HUGO BOSS",
								link: "/blogs/thuong-hieu-nuoc-hoa/hugoboss",
							},
							{
								title: "CAROLINA HERRERA",
								link: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
							},
							{
								title: "JEAN PAUL GAULTIER",
								link: "/blogs/thuong-hieu-nuoc-hoa/jean-paul-gaultier",
							},
							{
								title: "YVES SAINT LAURENT",
								link: "/blogs/thuong-hieu-nuoc-hoa/yves-saint-laurent",
							},
						],
					},
				],
				images: [
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41766-149571721186423-1721186423.jpg",
						alt: "first",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41767-792551721186425-1721186425.jpg",
						alt: "second",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41768-496611721186427-1721186427.jpg",
						alt: "third",
					},
				],
			},
			from_time: 0,
			to_time: 0,
			style: {
				color: "#3a393a",
			},
		},
		{
			name: "Bodycare & Homecare",
			link: "/collections/bodycare-homecare",
			submenu: {
				category: [
					{
						id: 1,
						type: "Phân loại",
						details: [
							{
								link: "/collections/cham-soc-toc",
								title: "Chăm sóc tóc",
							},
							{
								link: "/collections/cham-soc-da",
								title: "Chăm sóc da",
							},
							{
								link: "/collections/tinh-dau-nuoc-hoa",
								title: "Tinh dầu nước hoa",
							},
							{
								link: "/collections/lan-khu-mui",
								title: "Lăn khử mùi",
							},
							{
								link: "/collections/thuc-pham-chuc-nang",
								title: "Thực phẩm chức năng",
							},
							{
								link: "/collections/qua-tang",
								title: "Quà Tặng",
							},
							{
								link: "/collections/hop-qua",
								title: "Hộp quà",
							},
						],
					},
				],
				images: [
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41770-788861721186550-1721186550.jpg",
						alt: "first",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41769-750951721186550-1721186550.jpg",
						alt: "second",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41771-798141721186553-1721186553.jpg",
						alt: "third",
					},
				],
			},
			from_time: 0,
			to_time: 0,
			style: {
				color: "#3a393a",
			},
		},
		{
			name: "Son Môi",
			link: "/collections/son-moi",
			submenu: null,
			style: {
				color: "#3a393a",
			},
		},
	];

	useEffect(() => {
		form.setFieldsValue({
			...(initialValues as any),
		});
		if (
			data.submenu &&
			(data.submenu.hasOwnProperty("category") ||
				data.submenu.hasOwnProperty("images"))
		) {
			if (data.submenu?.category.length > 0) {
				// let newCategories: MegaMenuCategory[] = [];

				for (let i = 0; i < data.submenu?.category.length; i++) {
					form.setFieldValue(
						`type_menu_${i + 1}`,
						data.submenu.category[i].type
					);
					form.setFieldValue(
						`detail_menu_${i + 1}`,
						data.submenu.category[i].details
					);
				}
			}
		}
	}, [data, form]);

	useEffect(() => {
		form.setFieldValue("textcolor", data.style?.color);
		if (
			data.submenu &&
			(data.submenu.hasOwnProperty("category") ||
				data.submenu.hasOwnProperty("images"))
		) {
			setHasSubMenu(true);
			if (data.submenu.images.length > 0) {
				for (let i = 0; i < data.submenu.images.length; i++) {
					if (i === 0) {
						setImageFirst(data.submenu.images[i].url);
					} else if (i === 1) {
						setImageSecond(data.submenu.images[i].url);
					} else {
						setImageThird(data.submenu.images[i].url);
					}
				}
			}
			if (data.submenu?.category.length > 0) {
				setCountCategory(data.submenu?.category.length);
			}
		} else {
			setHasSubMenu(false);
			setCountCategory(0);
			setImageFirst("");
			setImageSecond("");
			setImageThird("");
		}
	}, [data]);

	return (
		<Modal
			title={type === "edit" ? `Cập nhật ${data.name}` : "Thêm mới"}
			open={open}
			footer={<></>}
			onCancel={() => {
				handleOnClose();
			}}
			maskClosable={false}
			width={800}
			destroyOnClose={true}
			bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}>
			<>
				<Form
					form={form}
					layout="vertical"
					initialValues={initialValues}
					onFinish={onSubmit}
					autoComplete="off">
					<Row gutter={[8, 16]}>
						<Col md={12} xs={24}>
							<Row gutter={4}>
								<Col span={18}>
									<Form.Item
										label="Tên"
										name="name"
										rules={[{ required: true, message: "Vui lòng nhập" }]}>
										<Input />
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item
										name="textcolor"
										initialValue={initialValues.style?.color}
										label="Màu chữ"
										labelCol={{ span: 24 }}>
										<ColorPicker
											defaultValue={initialValues.style?.color}
											onChange={(value, hex) => {
												form.setFieldValue("textcolor", hex);
												// const key = field.key;
												// let data = form.getFieldValue(
												// 	name
												// ) as ZoneDataProduct[];
												// if (data[key]) {
												// 	data[key] = {
												// 		...data[key],
												// 		style: {
												// 			...data[key].style,
												// 			bg: hex,
												// 		},
												// 	};
												// 	form.setFieldValue(name, data);
												// } else {
												// 	handleSetForKey("style", {
												// 		style: {
												// 			button: "",
												// 			bg: hex,
												// 		},
												// 	});
												// }
											}}
										/>
									</Form.Item>
								</Col>
							</Row>
						</Col>

						<Col md={12} xs={24}>
							<Form.Item
								label="Đường dẫn"
								name="link"
								// rules={[{ required: true, message: "Vui lòng nhập" }]}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={[16, 16]} align="middle">
						<Col md={12} xs={24}>
							<Form.Item label={"Thời gian hiển thị từ"} name="from_time">
								<DatePicker
									style={{ width: "100%" }}
									placeholder={"HH:mm dd/mm/yyyy"}
									format="HH:mm DD/MM/YYYY"
									showTime
								/>
							</Form.Item>
						</Col>
						<Col md={12} xs={24}>
							<Form.Item
								label={"Thời gian hiển thị đến"}
								name="to_time"
								rules={[
									{
										message: t("Thời gian không hợp lệ"),
										validator: (_: any, value: any) => {
											if (
												Helper.datePickerValueToTimestamp(
													form.getFieldValue("from_time")
												) < Helper.datePickerValueToTimestamp(value) ||
												!Helper.datePickerValueToTimestamp(value)
											) {
												return Promise.resolve();
											} else {
												return Promise.reject("Some message here");
											}
										},
									},
								]}>
								<DatePicker
									style={{ width: "100%" }}
									placeholder={"HH:mm dd/mm/yyyy"}
									format="HH:mm DD/MM/YYYY"
									showTime
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={[16, 16]}>
						<Col md={8} xs={24}>
							<Form.Item
								name="hide_in_page"
								valuePropName="checked"
								label="Ẩn khi truy cập trang">
								<Checkbox></Checkbox>
							</Form.Item>
						</Col>
						<Col md={8} xs={24}>
							<Form.Item
								name="is_event"
								valuePropName="checked"
								label="menu sự kiện">
								<Checkbox></Checkbox>
							</Form.Item>
						</Col>
					</Row>

					{/* <Row>
            <Checkbox
              checked={hasSubMenu}
              onChange={(e) => {
                setHasSubMenu(e.target.checked);
              }}
            >
              Có submenu
            </Checkbox>
          </Row> */}

					{hasSubMenu && data.submenu && (
						<div>
							{data.submenu?.category.map((item, index) => {
								console.log("🥺 ~ {data.submenu?.category.map ~ item:", item);
								return (
									<Collapse
										key={index}
										className="mb-2"
										// defaultActiveKey={[1, 2]}
									>
										<Collapse.Panel
											key={item.id}
											header={
												<>
													<Typography.Text>{item.type}</Typography.Text>
												</>
											}>
											<Row>
												<Col md={24}>
													<Form.Item
														name={`type_menu_${item.id}`}
														label="Tiêu đề chính"
														rules={[
															{
																required: true,
																message: "Vui lòng nhập",
															},
														]}>
														<Input />
													</Form.Item>
												</Col>
											</Row>
											<ShopMegaMenuDetailForm
												name={`detail_menu_${item.id}`}
												data={item.details}
											/>
										</Collapse.Panel>
									</Collapse>
								);
							})}

							<Row gutter={[16, 16]}>
								<Col md={8}>
									{imageFirst.length > 0 ? (
										<>
											<div className="flex items-start">
												<Image
													className="cursor-pointer"
													src={imageFirst}
													width={100}
													height={100}
													preview={true}
													onClick={(e) => {
														e.stopPropagation();
													}}
												/>
												<Popconfirm
													title="Bạn có muốn xóa ảnh"
													onConfirm={() => {
														setImageFirst("");
														form.setFieldValue("image", []);
													}}>
													<Button
														icon={
															<IconTrash className="w-4 h-4 text-gray-500 hover:text-red-500" />
														}></Button>
												</Popconfirm>
											</div>
										</>
									) : (
										<>
											<Form.Item className="m-0">
												<FileUploaderButton
													form={form}
													formName="image1"
													label={t("Ảnh")}
													icon={<IconPhoto size="24" color="grey" />}
													origin="avatar"
													uploadRenderType="thumbnail"
													isSingleFile={true}
													maxCount={1}
													initFiles={[]}
													onUploadCompleted={(f) => {
														setImageFirst(f.url);
													}}
												/>
											</Form.Item>
										</>
									)}
								</Col>
								<Col md={8}>
									{imageSecond.length > 0 ? (
										<>
											<div className="flex items-start">
												<Image
													className="cursor-pointer"
													src={imageSecond}
													width={100}
													height={100}
													preview={true}
													onClick={(e) => {
														e.stopPropagation();
													}}
												/>
												<Popconfirm
													title="Bạn có muốn xóa ảnh"
													onConfirm={() => {
														setImageSecond("");
														form.setFieldValue("image", []);
													}}>
													<Button
														icon={
															<IconTrash className="w-4 h-4 text-gray-500 hover:text-red-500" />
														}></Button>
												</Popconfirm>
											</div>
										</>
									) : (
										<>
											<Form.Item className="m-0">
												<FileUploaderButton
													form={form}
													formName="image2"
													label={t("Ảnh")}
													icon={<IconPhoto size="24" color="grey" />}
													origin="avatar"
													uploadRenderType="thumbnail"
													isSingleFile={true}
													maxCount={1}
													initFiles={[]}
													onUploadCompleted={(f) => {
														setImageSecond(f.url);
													}}
												/>
											</Form.Item>
										</>
									)}
								</Col>
								<Col md={8}>
									{imageThird.length > 0 ? (
										<>
											<div className="flex items-start">
												<Image
													className="cursor-pointer"
													src={imageThird}
													width={100}
													height={100}
													preview={true}
													onClick={(e) => {
														e.stopPropagation();
													}}
												/>
												<Popconfirm
													title="Bạn có muốn xóa ảnh"
													onConfirm={() => {
														setImageThird("");
														form.setFieldValue("image", []);
													}}>
													<Button
														icon={
															<IconTrash className="w-4 h-4 text-gray-500 hover:text-red-500" />
														}></Button>
												</Popconfirm>
											</div>
										</>
									) : (
										<>
											<Form.Item className="m-0">
												<FileUploaderButton
													form={form}
													formName="image3"
													label={t("Ảnh")}
													icon={<IconPhoto size="24" color="grey" />}
													origin="avatar"
													uploadRenderType="thumbnail"
													isSingleFile={true}
													maxCount={1}
													initFiles={[]}
													onUploadCompleted={(f) => {
														setImageThird(f.url);
													}}
												/>
											</Form.Item>
										</>
									)}
								</Col>
							</Row>
						</div>
					)}

					<Row></Row>

					<Form.Item className="mt-4">
						<div className="flex gap-4">
							<Button
								type="primary"
								htmlType="submit"
								loading={loading}
								disabled={loading}>
								Hoàn tất
							</Button>
							<Button
								type="default"
								onClick={() => {
									handleOnClose();
								}}>
								Đóng
							</Button>
						</div>
					</Form.Item>
				</Form>
			</>
		</Modal>
	);
}
