import { IconEye, IconMinus } from "@tabler/icons-react";
import {
	Col,
	Image,
	Row,
	Skeleton,
	Table,
	Tag,
	Typography,
	TableColumnsType,
	Statistic,
	Tooltip,
	Button,
} from "antd";
import ProductPublicModel from "common/models/ProductPublicModel";
import ProductRepository from "common/repositories/ProductRepository";
import {
	ProductJson,
	ProductJsonPublic,
	ProductStatus,
} from "common/types/Product";
import { cn } from "common/utils/utils";
import NoPhoto from "components/NoPhoto";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { convertNumberToPriceVND } from "utils";
import ProductItemPublicExpand from "./ProductItemPublicExpand";
import StoreModel from "common/models/StoreModel";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export default function ProductParentChild({
	product,
	store_id,
	storeList,
	renderAction,
}: {
	product: ProductPublicModel;
	store_id: number;
	storeList?: StoreModel[];
	renderAction?: (product: ProductJsonPublic) => React.ReactNode;
}) {
	const [productChilds, setProductChilds] = useState<ProductPublicModel[]>([]);
	const [loading, setLoading] = useState(false);
	const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

	async function getProductChild(parent_id: number, store_id: number) {
		setLoading(true);
		const res = await new ProductRepository().getItemsPublic({
			filters: {
				...ProductRepository.getDefaultFilters(),
				parent_id: parent_id,
				store_id: store_id,
			},
		});
		if (!res.hasError()) {
			setProductChilds(res.items);
		}
		setLoading(false);
	}

	const data = [product, ...productChilds];

	function onSaveSuccess() {
		if (product.id && store_id) getProductChild(product.id, store_id);
	}

	function onDeleteCallback() {
		if (product.id && store_id) getProductChild(product.id, store_id);
	}

	useEffect(() => {
		if (product.id && store_id) getProductChild(product.id, store_id);
	}, [product.id, store_id]);

	//Table columns
	const columns: TableColumnsType<ProductPublicModel> = [
		{
			title: t("product:image_short"),
			key: "images",
			align: "center",
			width: 50,
			render: (record) => {
				const images = record.images;
				return images.length > 0 ? (
					<div onClick={(e) => e.stopPropagation()}>
						<Image
							width="40"
							src={images[0].url}
							preview={{
								src: images[0].url,
								mask: <IconEye size="16" />,
							}}
						/>
					</div>
				) : (
					<NoPhoto width={40} height={40} />
				);
			},
		},
		{
			title: t("product:name"),
			key: "name",
			render: (record) => {
				return (
					<div className=" text-md font-bold line-clamp-2">
						{record.full_name}
					</div>
				);
			},
		},
		{
			title: t("product:sku"),
			key: "sku",
			width: 120,
			align: "center",
			render: (record) => {
				return <div className=" text-md font-bold">{record.sku}</div>;
			},
		},
		{
			title: t("tồn kho tại cửa hàng"),
			key: "quantity",

			width: 250,
			render: (record: ProductPublicModel) => {
				const dataStoreFilter = record.quantities.find(
					(i) => i.store_id === Number(store_id)
				);
				if (dataStoreFilter) {
					return (
						<>
							<Row gutter={16}>
								<Col span={12}>
									<Statistic
										title={
											<>
												<Tooltip
													title={
														<Typography.Text className="text-white">
															Số lượng khách đang đặt hàng
														</Typography.Text>
													}>
													Khách đặt
												</Tooltip>
											</>
										}
										value={dataStoreFilter.reserved}
										valueStyle={{ color: "#3f8600" }}
									/>
								</Col>
								<Col span={12}>
									<Statistic
										title={
											<>
												<Tooltip
													title={
														<Typography.Text className="text-white">
															Số lượng tồn kho thực tế trong kho
														</Typography.Text>
													}>
													Tồn kho
												</Tooltip>
											</>
										}
										value={dataStoreFilter.quantity}
										valueStyle={{ color: "#cf1322" }}
										prefix={
											dataStoreFilter.quantity < 0 ? (
												<IconMinus></IconMinus>
											) : null
										}
									/>
								</Col>
							</Row>
						</>
					);
				}
				return (
					<>
						<Tag> Không có thông tin</Tag>
					</>
				);
			},
		},
		{
			title: t("product:total_quantity"),
			key: "total_quantity",
			width: 150,
			render: (record) => {
				const caseQuatity = {
					isZero: record.total_quantity === 0,
					isNegative: record.total_quantity < 0,
				};
				return (
					<>
						<Typography.Text
							className={cn("", {
								"text-yellow-500": caseQuatity.isZero,
								"text-red-500": caseQuatity.isNegative,
							})}>
							{" "}
							{record.total_quantity}
						</Typography.Text>
					</>
				);
			},
		},
		{
			title: t("product:price"),
			key: "price",
			width: 160,
			render: (record: ProductPublicModel) => {
				return <p>{convertNumberToPriceVND(record.price)}</p>;
			},
		},
		{
			title: t("common:status"),
			key: "status",
			render: (record) => {
				let statusInfo = ProductPublicModel.getStatus(record.status);
				return (
					<span
						className={
							record.status === ProductStatus.UNACTIVE
								? "text-red-500"
								: " text-green-500"
						}>
						{statusInfo?.label}
					</span>
				);
			},
			width: 110,
		},
		{
			title: " ",
			key: "actions",
			align: "right",
			fixed: "right",
			width: 200,
			render: (_: any, record: ProductPublicModel) => {
				// const unitInfo = ProductPublicModel.getUnit(record.unit);

				return <>{renderAction && renderAction(record.toJson())}</>;
			},
		},
	];

	if (loading) {
		return (
			<div>
				<Skeleton />
			</div>
		);
	}

	return (
		<div className="p-4">
			{/* <Link to={`/product/add/parent/${product.id}`} target="_blank">
        <Button type="primary" className=" mb-4" icon={<PlusOutlined />}>Thêm hàng hóa cùng loại</Button>
        </Link> */}
			<Table
				rowKey={"id"}
				columns={columns}
				expandable={{
					expandedRowRender: (record) => {
						return (
							<ProductItemPublicExpand
								product={record}
								storeList={storeList}
								onSaveSuccess={onSaveSuccess}
								onDeleteSuccess={onDeleteCallback}
							/>
						);
					},
					rowExpandable: (record) => record.name !== "Not Expandable",
					expandRowByClick: true,
					showExpandColumn: false,
					expandedRowClassName: () => "ant-table-expanded-row-dimmer",

					expandedRowKeys: expandedRowKeys,
					onExpand: (expanded: boolean, record: ProductPublicModel) => {
						console.log(expanded, record);
						if (expanded) {
							setExpandedRowKeys([record.id]);
						} else {
							setExpandedRowKeys([]);
						}
					},
				}}
				dataSource={data}
				pagination={false}
			/>
		</div>
	);
}
