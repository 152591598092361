import { Spin, Tag, Typography } from "antd";
import EmployeeModel from "common/models/EmployeeModel";
import EmployeeRepository from "common/repositories/EmployeeRepository";
import IdFetcherRepository from "common/repositories/IdFetcherRepository";
import { EmployeeJson } from "common/types/Employee";
import { IdFetcherResult } from "common/types/IdFetcher";
import dbm from "dbm";
import PhoneBookModal from "features/phonebook/form/PhoneBookModal";
import useDatabaseTable from "hooks/useDatabaseTable";
import React, { useCallback, useEffect, useRef, useState } from "react";

const TextUser = ({
	id,
	keyshow,
	zeroLabel = <Typography>Khách hàng</Typography>,
}: {
	id: number;
	keyshow?: keyof EmployeeJson;
	zeroLabel?: React.ReactNode;
}) => {
	const [employeeResult] = useDatabaseTable<EmployeeJson>("employee");

	const [employee, setEmployee] = useState<EmployeeModel>(
		new EmployeeModel(EmployeeModel.getDefaultData())
	);

	const [result, setResults] = useState<IdFetcherResult[]>([]);

	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const repoRef = useRef(new EmployeeRepository());

	const getDetailEmp = useCallback(
		async (id: number) => {
			setLoading(true);
			const res = await repoRef.current.getItemIds({
				ids: [id],
				object_type: "employee",
			});
			if (!res.hasError() && res.items.length > 0) {
				setEmployee(res.items[0]);
			} else {
				// const resUser = await new IdFetcherRepository().doFetching({
				// 	id_list: [id],
				// 	object_type: "employee",
				// });
				// setResults(resUser.items.flatMap((i) => i.result));
			}

			setLoading(false);
		},
		[id]
	);

	useEffect(() => {
		if (id > 0 && employee.id === 0 && employeeResult) {
			(async () => {
				// const collection = dbm.getCollection("employee");

				if (typeof employeeResult !== "undefined") {
					// const foundItems = await collection
					// 	?.chain()
					// 	.find({
					// 		id: id,
					// 	})
					// 	.limit(1)
					// 	.data();
					const foundItemsWithUserId = employeeResult.find(
						(i) => i.user_id === id
					);

					const foundItemsWithId = employeeResult.find((i) => i.id === id);

					if (foundItemsWithUserId) {
						setEmployee(new EmployeeModel(foundItemsWithUserId));
					} else if (foundItemsWithId) {
						setEmployee(new EmployeeModel(foundItemsWithId));
					} else {
						getDetailEmp(id);
					}
				}
			})();
		}
	}, [id, employee.id, employeeResult]);

	useEffect(() => {
		const repo = repoRef.current;

		return () => repo._api?.abortRequest();
	}, []);

	return (
		<Spin spinning={loading} size="small">
			{id <= 0 ? (
				zeroLabel
			) : employee.id > 0 ? (
				<>
					{keyshow ? (
						<Typography.Text className="inline">
							{employee[keyshow]}
						</Typography.Text>
					) : (
						<Typography.Link
							className="cursor-pointer"
							onClick={() => {
								setOpen(true);
							}}>
							{employee.full_name}
							{" - "}
							<Typography.Text className="inline" copyable>
								{employee.internal_id}
							</Typography.Text>
						</Typography.Link>
					)}
				</>
			) : (
				<Tag color="error">Trống</Tag>
			)}

			<PhoneBookModal
				id={employee.id}
				visible={open}
				setVisible={(isVisible) => {
					setOpen(isVisible);
				}}
			/>
		</Spin>
	);
};

export default TextUser;
