import { FormItemProps } from "antd";
import { Form, InputNumber, Space } from "antd";
import MoneyInput from "components/MoneyInput";
import { debounce } from "lodash";
import React, { useState } from "react";

type Props = {
	FormItemPropsMin: FormItemProps;
	FormItemPropsMax: FormItemProps;
	placeholder?: string;
};
const FormRangeNumber = ({
	FormItemPropsMin,
	FormItemPropsMax,
	placeholder = "Công nợ từ",
}: Props) => {
	const [min, setMin] = useState<number | null>(null);
	const [max, setMax] = useState<number | null>(null);

	const onMinChange = debounce((value: number | null) => {
		if (value) {
			setMin(value);
			// setValue?.(value);
		}
	}, 500);

	const onMaxChange = debounce((value: number | null) => {
		if (value) {
			setMax(value);
			// setValue?.(value);
		}
	}, 500);
	return (
		<Space direction="horizontal" className="gap-0" style={{ gap: 0 }}>
			<Form.Item {...FormItemPropsMin} className="m-0">
				<MoneyInput
					min={0}
					// max={max - 1}
					value={min}
					onChange={onMinChange}
					placeholder={placeholder}
					formatter={(value) =>
						`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
					}
					addonAfter="VNĐ"
					addonBefore="Từ"
				/>
			</Form.Item>

			<Form.Item {...FormItemPropsMax} className="m-0">
				<MoneyInput
					min={min ? min + 1 : undefined}
					// max={100}
					value={max}
					formatter={(value) =>
						`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
					}
					onChange={onMaxChange}
					placeholder="đến"
					addonAfter="VNĐ"
					addonBefore="Đến"
				/>
			</Form.Item>
		</Space>
	);
};

export default FormRangeNumber;
