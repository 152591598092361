import React, { useCallback, useEffect, useMemo } from "react";
import { debounce } from "lodash";
import { Form, Select } from "antd";
import EmployeeCollection from "common/collections/EmployeeCollection";
import EmployeeRepository from "common/repositories/EmployeeRepository";
import { FilterEmployee } from "common/types/Employee";

interface Option {
	value: number;
	label: string;
}
function removeItemDuplicate(arr: Option[]) {
	let uniqueArray = [];
	for (let i = arr.length - 1; i >= 0; i--) {
		let foundDuplicate = false;
		for (let j = i - 1; j >= 0; j--) {
			if (arr[i].value === arr[j].value) {
				foundDuplicate = true;
				break;
			}
		}
		if (!foundDuplicate) {
			uniqueArray.unshift(arr[i]);
		}
	}
	return uniqueArray;
}

const SelectEmployeeId = (props: {
	values?: number[];
	ignoreValues?: number[];
	multiple?: boolean;
	name?: string;
	placeholder?: string;
	isMulti?: boolean;
	className?: string;
	icon?: React.ReactNode;
	width?: number;
	disabled?: boolean;
	onChange: (v: number[]) => void;
}) => {
	const [employeeOption, setEmployeeOption] = React.useState<any[]>([]);
	const [keyword, setKeyword] = React.useState<string>("");

	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState("");

	const defaultFilters: FilterEmployee = useMemo(() => {
		return { ...EmployeeRepository.getDefaultFilters() };
	}, []);
	const fetchEmployeeValueDefault = useCallback(async (ids: number[]) => {
		if (!ids.length) return;
		const collection = await new EmployeeRepository().getIdsItems({
			ids: ids,
			object_type: "user",
		});
		setLoading(false);

		if (collection.hasError()) {
			setError(collection.error.errors[0]);
		} else {
			const defauilValueOption = collection.items.map((emp, index) => {
				return {
					value: emp.id,
					label: `(${emp.internal_id}) ${emp.full_name}`,
				};
			});
			return defauilValueOption;
		}
	}, []);
	const fetchDataOptions = useCallback(async (): Promise<void> => {
		try {
			setLoading(true);
			let collection = await new EmployeeRepository().getItems({
				filters: keyword
					? { ...defaultFilters, keyword: keyword }
					: { ...defaultFilters },
			});
			if (collection.hasError()) {
				setError(collection.error.errors[0]);
			} else {
				const employeeArrayOption = collection.items.map((emp) => {
					return {
						value: emp.id,
						label: `(${emp.internal_id}) ${emp.full_name}`,
					};
				});
				if (props.values?.length) {
					let dataDe = await fetchEmployeeValueDefault(props.values);
					if (dataDe) {
						const mergeOption: any[] = [...dataDe, ...employeeArrayOption];
						const options = removeItemDuplicate(mergeOption);
						setEmployeeOption(options);
					}
				} else {
					setEmployeeOption(employeeArrayOption);
				}
			}
		} catch (error) {
			throw error;
		} finally {
			setLoading(false);
		}
	}, [defaultFilters, fetchEmployeeValueDefault, keyword, props.values]);

	const onChangeHandle = (value: any) => {
		if (typeof props.onChange !== "undefined") {
			if (typeof value != "undefined") {
				setKeyword("");
				if (Array.isArray(value)) {
					props.onChange(value);
				} else {
					props.onChange([value]);
				}
			} else {
				props.onChange([]);
			}
		}
	};
	const handleInputChage = (value: string) => {
		setKeyword(value);
		setLoading(false);
	};
	useEffect(() => {
		fetchDataOptions();
	}, [fetchDataOptions, keyword]);

	return (
		<Form.Item noStyle>
			<Select<number[]>
				{...(props.icon && { suffixIcon: props.icon })}
				disabled={props.disabled}
				virtual={false}
				// dropdownClassName={className}
				style={{ width: props.width || "100%" }}
				onChange={onChangeHandle}
				onSearch={debounce((value) => {
					setLoading(true);
					handleInputChage(value);
				}, 700)}
				loading={loading}
				placeholder={props.placeholder}
				allowClear={true}
				showSearch={true}
				mode={props.multiple ? "multiple" : undefined}
				defaultValue={props.values || []}
				optionFilterProp="label"
				filterOption={(input: any, option: any) =>
					option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
					option.label
						.normalize("NFD")
						.replace(/[\u0300-\u036f]/g, "")
						.replace(/[đĐ]/g, "d")
						.toLowerCase()
						.indexOf(
							input
								.normalize("NFD")
								.replace(/[\u0300-\u036f]/g, "")
								.replace(/[đĐ]/g, "d")
								.toLowerCase()
						) >= 0
				}
				options={employeeOption}></Select>
		</Form.Item>
	);
};

export default SelectEmployeeId;
