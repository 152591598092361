import { LeaveSummaryJson } from "common/types/LeaveSummary";
import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

const SERVICE_URL = "/leavesummarys";

class LeaveSummaryApi extends BaseApi {
	async getSummaryDate(id: number, year?: number): Promise<LeaveSummaryJson> {
		let item: LeaveSummaryJson = { available_days: 0 };
		console.log("🚀 ~ LeaveSummaryApi ~ getSummaryDate ~ year:", year);

		try {
			let queryData = {
				params: {
					leavetypes_id: id,
					year: year ?? new Date().getFullYear(),
				},
			};

			const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
			if (response.hasOwnProperty("data")) {
				item = response.data;
			}
		} catch (error) {
			item.error = error;
		}
		return item;
	}
}

export default LeaveSummaryApi;
