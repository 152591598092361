import LocationApi from "common/api/LocationApi";
import LocationCollection from "common/collections/LocationCollection";
import { GetListProps } from "common/types/Filter";
import { FilterLocation } from "common/types/Location";
import BaseRepository from "./BaseRepository";

class LocationRepository extends BaseRepository<LocationApi> {
  _api: LocationApi | null;

  constructor() {
    super();
    this._api = new LocationApi(true);
  }

  static getDefaultFilters(): FilterLocation {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      type: -1,
      list_ids: "",
    };
  }

  async getItems(props: GetListProps<FilterLocation>) {
    return this._api
      ? await this._api.getList(props)
      : new LocationCollection();
  }
}

export default LocationRepository;
