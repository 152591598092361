import ProductWarehouseCollection from "common/collections/ProductWarehouseCollection";
import type { GetListProps } from "common/types/Filter";
import { FilterProductWarehouse } from "common/types/ProductWarehouse";
import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

const SERVICE_URL = "/inventories";

class ProductWarehouseApi extends BaseApi {
  async getList(
    props: GetListProps<FilterProductWarehouse>
  ): Promise<ProductWarehouseCollection> {
    let collection = new ProductWarehouseCollection();

    try {
      let filters = props.filters;

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          keyword: filters.keyword ? filters.keyword : null,
          status: +filters.status >= 0 ? filters.status : null,
          item_id: filters.item_id > 0 ? filters.item_id : null,
          item_type: filters.item_type > 0 ? filters.item_type : null,
          warehouse_id: filters.warehouse_id > 0 ? filters.warehouse_id : null,
          warehouse_ids: filters.warehouse_ids ? filters.warehouse_ids : null,
          item_idlist: filters.item_idlist ? filters.item_idlist : null,
        },
      };

      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }
}

export default ProductWarehouseApi;
