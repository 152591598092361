import { useTranslation } from "react-i18next";
import useStateFilter from "hooks/useStateFilter";
import useFilterLocation from "hooks/useFilterLocation";
import { IconPhotoOff, IconPhoto } from "@tabler/icons-react";
import React, { useCallback, useMemo, useState } from "react";
import {
	Badge,
	TableColumnsType,
	Tag,
	Tooltip,
	Typography,
	message,
} from "antd";

import Role from "common/constants/Role";
import CommentModel from "common/models/CommentModel";
import EmployeeModel from "common/models/EmployeeModel";
import { CommentJson, FilterComment } from "common/types/Comment";
import CommentRepository from "common/repositories/CommentRepository";
import CommentCollection from "common/collections/CommentCollection";
import EmployeeRepository from "common/repositories/EmployeeRepository";

import RoleCheck from "components/RoleCheck";
import RateStars from "../components/RateStars";
import TableEdit from "components/table/TableEdit";
import TextDateTime from "components/TextDateTime";
import CommentListFilter from "./CommentListFilter";
import CommentListHeader from "./CommentListHeader";
import PageDataTable from "components/page/PageDataTable";
import PageDataPagination from "components/page/PageDataPagination";
import Helper from "common/utils/helper";

const CommentList = () => {
	const { t } = useTranslation();

	// default filter
	const defaultFilters = useMemo(() => {
		return CommentRepository.getDefaultFilter();
	}, []);

	//state
	const [total, setTotal] = useState<number>(0);
	const [dataSource, setDataSource] = useState<CommentModel[]>([]);
	const [filters, setFilters] = useStateFilter(defaultFilters);
	useFilterLocation(defaultFilters, filters);

	const [employees, setEmployees] = useState<EmployeeModel[]>([]);

	//table columns
	const columns: TableColumnsType<CommentModel> = [
		{
			title: t("ID"),
			key: "id",
			dataIndex: "id",
			width: 60,
			render: (id) => {
				return <Tag color="blue">{id}</Tag>;
			},
		},
		{
			title: t("comment:form.item_name"),
			key: "item_detail",
			dataIndex: "item_detail",
			width: 120,
			render: (item_detail) => {
				return (
					<Tooltip title={item_detail.data_title}>
						<span className="line-clamp-1   cursor-default ">
							{item_detail.data_title}
						</span>
					</Tooltip>
				);
			},
		},
		{
			title: t("comment:form.item_type"),
			key: "item_type",
			dataIndex: "item_type",
			width: 120,
			render: (item_type) => {
				const color = item_type === "product" ? "geekblue" : "";
				const title =
					item_type === "product"
						? t("comment:model.product")
						: t("comment:empty");
				return <Tag color={color}>{title}</Tag>;
			},
		},
		{
			title: t("comment:client_type"),
			key: "customer_id",
			dataIndex: "customer_id",
			width: 100,
			render: (customer_id, record: CommentModel) => {
				return (
					<Tag
						color={
							record.employee_id > 0 ? "cyan" : customer_id > 0 ? "green" : ""
						}>
						{record.employee_id > 0
							? t("comment:admin")
							: customer_id > 0
							? t("comment:form.customer")
							: t("comment:form.passersby")}
					</Tag>
				);
			},
		},
		{
			title: t("comment:client_name"),
			key: "name",
			dataIndex: "name",
			width: 150,
			render: (name: string, record: CommentModel) => {
				return (
					<p className="line-clamp-2 m-0">
						{record.employee_id > 0
							? employees.find((element) => element.id === record.employee_id)
									?.full_name
							: name}
					</p>
				);
			},
		},
		{
			title: t("comment:image"),
			key: "images",
			dataIndex: "images",
			width: 60,
			ellipsis: true,
			render: (images: any) => {
				if (images.length > 0) {
					return (
						<Badge count={images.length}>
							<IconPhoto className=" text-gray-400 " size="18" />
						</Badge>
					);
				} else {
					return <IconPhotoOff className=" text-gray-400 " size="18" />;
				}
			},
		},
		{
			title: t("comment:form.type"),
			key: "type",
			dataIndex: "type",
			width: 100,
			render: (type) => {
				const color =
					type === 1
						? "blue"
						: type === 2
						? "purple"
						: type === 3
						? "volcano"
						: type === 0
						? "cyan"
						: "";
				const title =
					type === 1
						? t("comment:review")
						: type === 2
						? t("comment:comment")
						: type === 3
						? t("comment:qa")
						: type === 0
						? t("comment:reply")
						: t("comment:empty");
				return <Tag color={color}>{title}</Tag>;
			},
		},
		{
			title: t("comment:form.rate"),
			key: "rate",
			dataIndex: "rate",
			width: 100,
			render: (rate, record: CommentModel) => {
				if (record.type === 1) {
					const rateStars =
						rate === 1 ? (
							<RateStars
								stars={1}
								style={{ color: "#d72229", fontSize: "16px" }}
							/>
						) : rate === 2 ? (
							<RateStars
								stars={2}
								style={{ color: "#d72229", fontSize: "16px" }}
							/>
						) : rate === 3 ? (
							<RateStars
								stars={3}
								style={{ color: "#d72229", fontSize: "16px" }}
							/>
						) : rate === 4 ? (
							<RateStars
								stars={4}
								style={{ color: "#d72229", fontSize: "16px" }}
							/>
						) : rate === 5 ? (
							<RateStars
								stars={5}
								style={{ color: "#d72229", fontSize: "16px" }}
							/>
						) : (
							<Tag>{t("comment:model.empty")}</Tag>
						);
					return rateStars;
				} else {
					return <Tag>{t("comment:model.empty")}</Tag>;
				}
			},
		},
		{
			title: t("comment:form.count_chill"),
			key: "count_chill",
			dataIndex: "count_chill",
			width: 100,
			render: (count_chill) => {
				return <Typography.Text>{count_chill}</Typography.Text>;
			},
		},
		{
			title: t("common:date_created"),
			key: "date_created",
			width: 120,
			render: (date_created) => Helper.formatTimestampToText2(date_created),
		},
		{
			title: t("comment:form.status"),
			key: "status",
			dataIndex: "status",
			width: 100,
			render: (status) => {
				const color = status === 2 ? "magenta" : "green";
				const title =
					status === 2
						? t("comment:model.not_approved")
						: t("comment:model.approved");
				return <Tag color={color}>{title}</Tag>;
			},
		},
		{
			title: t(""),
			key: "actions",
			width: 100,
			align: "right",
			fixed: "right",
			render: (_: any, record: CommentJson) => (
				<RoleCheck roles={[Role.COMMENT_MANAGER]} hideOnFail>
					<TableEdit
						link={`/comment/process/id/${record.id}`}
						label={t("comment:action")}
					/>
				</RoleCheck>
			),
		},
	];

	//fetch data
	const fetchData = useCallback(async (): Promise<CommentCollection> => {
		const response = await new CommentRepository().getItems({
			filters: filters,
		});

		if (response.error.errors.length > 0) {
			message.open({
				type: "error",
				content: t(`comment:form.error.${response.error.errors[0]}`),
			});
		} else {
			setTotal(response.total);
			setDataSource(response.items);
			console.log(response.items);
			fetchIdList(
				response.items.map((item) => {
					return item.employee_id;
				})
			);
		}

		return response;
	}, [filters]);

	//fetch data employee
	const fetchIdList = async (ids: number[]) => {
		const idsList = await new EmployeeRepository().getIdsItems({
			object_type: "employee",
			ids: ids,
		});
		setEmployees(idsList.items);
	};

	return (
		<div>
			<CommentListHeader />
			<CommentListFilter
				total={total}
				filters={filters}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
			/>
			<PageDataTable<FilterComment, CommentModel, CommentCollection>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					tableColumnToggleKey: "comment",
				}}
			/>
			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>
		</div>
	);
};

export default CommentList;
