import React, {
	useCallback,
	useState,
	MutableRefObject,
	useRef,
	useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import {
	Alert,
	App,
	Button,
	Col,
	Form,
	Image,
	Result,
	Row,
	Typography,
} from "antd";
import { IconDoorEnter, IconDoorExit, IconTrash } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

import useFunction from "hooks/useFunction";
import Webcam from "react-webcam";
import Error from "components/Error";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import CheckinV2PendingItem from "../CheckinV2PendingItem";

import CheckinModel from "common/models/CheckinModel";
import FileRepository from "common/repositories/FileRepository";
import CheckinRepository from "common/repositories/CheckinRepository";
import { CheckinJsonAddEdit } from "common/types/Checkin";

type Props = {
	pending?: CheckinModel;
	isCheckin: boolean;
	fetchData: () => Promise<void>;
	isDoneCheckin: boolean;
};
const CheckinV2Form = ({
	pending,
	isCheckin,
	fetchData,
	isDoneCheckin,
}: Props) => {
	const [form] = Form.useForm();
	const { t } = useTranslation();
	const { message } = App.useApp();
	const navigate = useNavigate();
	const { getGpsPromise } = useFunction();
	const account = useLoginAccountStore((state) => state.account);

	const messageKey = "editor";

	//////////////////////////
	//state
	const [imgScreenshotSrc, setImgScreenshotSrc] = useState<string>("");
	const [enableWebcam, setEnableWebcam] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [error, setError] = useState<boolean>(false);

	/////////////////////////
	//ref
	const webcamRef = useRef(null) as MutableRefObject<any>;

	/////////////////////////
	//function
	const doPrepareData = useCallback(
		(formData: CheckinJsonAddEdit) => {
			const submitData: CheckinJsonAddEdit = {
				id: 0,
				latitude: 0,
				longitude: 0,
				image_id: 0,
			};

			return submitData;
		},
		[pending]
	);

	const captureScreenshot = useCallback(() => {
		const imageSrc = webcamRef.current.getScreenshot();
		setImgScreenshotSrc(imageSrc || "");
	}, [webcamRef, setImgScreenshotSrc]);

	const onSubmit = useCallback(
		async (formData: any) => {
			setProcessing(true);
			getGpsPromise()
				.then(async (res) => {
					// getGps();
					message.loading({
						content: t("common:form.processing"),
						key: messageKey,
					});

					navigator.geolocation.getCurrentPosition(
						async (locations) => {
							if (
								locations?.coords.latitude !== 0 &&
								locations.coords.longitude !== 0
							) {
								let webcamFileId = 0;
								let webcamUploadPass = false;
								if (imgScreenshotSrc.length > 0) {
									const webcamFile =
										await new FileRepository().uploadBase64Image({
											origin: "checkin",
											data_uri: imgScreenshotSrc,
										});
									if (webcamFile.hasError()) {
										webcamUploadPass = false;
										message.error({
											content: (
												<Error
													contentPadding={0}
													onClickClose={() => {
														message.destroy(messageKey);
													}}
													heading={t("common:error.form_submit")}
													translate_prefix={"workcheckin:form.errors"}
													items={webcamFile.error.errors}
												/>
											),
											className: "message_error",
											key: messageKey,
											duration: 3,
										});
										setProcessing(false);
									} else {
										webcamUploadPass = true;
										webcamFileId = webcamFile.id;
									}
								}

								if (webcamUploadPass) {
									const myObj: CheckinModel =
										await new CheckinRepository().saveRemote({
											id: 0,
											image_id: webcamFileId,
											latitude: locations.coords.latitude,
											longitude: locations.coords.longitude,
											action: 0,
										});
									if (myObj.hasError()) {
										message.error({
											content: (
												<Error
													contentPadding={0}
													onClickClose={() => {
														message.destroy(messageKey);
													}}
													heading={t("common:error.form_submit")}
													translate_prefix={"worktracking:form.error"}
													items={myObj.error.errors}
												/>
											),
											className: "message_error",
											key: messageKey,
											duration: 3,
										});
										setProcessing(false);
									} else {
										fetchData();
										setImgScreenshotSrc("");
										setProcessing(false);
										message.success({
											content: isCheckin
												? t("worktracking:form.success.checkin")
												: t("worktracking:form.success.checkout"),
											className: "message_success",
											key: messageKey,
											duration: 2,
										});
									}
								}
							}
						},
						() => {
							message.error("Yêu cầu định vị và GPS");
							setError(true);
							setProcessing(false);
						}
					);
				})
				.catch(() => {
					message.error("Yêu cầu định vị và GPS");
					setProcessing(false);
				});
		},
		[imgScreenshotSrc]
	);
	/////////////////////////

	return (
		<>
			{typeof pending !== "undefined" ? (
				<CheckinV2PendingItem pending={pending} isCheckin={isCheckin} />
			) : null}

			{false ? (
				<Result
					status="success"
					title="Bạn đã hoàn thành chấm công của ngày hôm nay"
					subTitle="Hẹn gặp lại vào ngày mai."
				/>
			) : (
				<Form
					labelCol={{ span: 6 }}
					form={form}
					layout={"inline"}
					onFinish={onSubmit}
					className="flex gap-2">
					<Row gutter={0} className="flex justify-center">
						{error ? (
							<Alert
								message={
									<Typography.Text>
										Yêu cầu truy cập camera{". "}
										<Typography.Link>Nhấn để truy cập</Typography.Link>{" "}
									</Typography.Text>
								}
								type="warning"
								showIcon
								closable={false}
								onClick={async () => {
									navigator.mediaDevices
										.getUserMedia({ video: true })
										.then((stream) => {
											setError(false);
										})
										.catch((error) => {
											message.open({
												type: "warning",
												content: "Bạn hãy mở quyền truy cập camera !",
												key: "err",
												duration: 2,
											});
										});
								}}
							/>
						) : (
							<Col flex={"325px"}>
								<div className="mb-2">
									{t("worktracking:webcamphoto")}
									{enableWebcam ? (
										<Button
											type="link"
											onClick={() => {
												setEnableWebcam(false);
												setImgScreenshotSrc("");
											}}>
											{t("worktracking:enablewebcam_off")}
										</Button>
									) : null}
								</div>
								<Row gutter={[0, 8]} className="w-full">
									{imgScreenshotSrc.length === 0 ? (
										<>
											<Col span={24}>
												<div className="flex items-center bg-gray-300 place-content-center w-full min-w-[325px] h-[250px]">
													{enableWebcam ? (
														<Webcam
															style={{
																transform: "scaleX(1)",
																margin: "0 auto",
																borderRadius: "8px 8px 0 0",
															}}
															audio={false}
															ref={webcamRef}
															screenshotFormat="image/jpeg"
															width="325px"
															height="250px"
															minScreenshotWidth={320}
															forceScreenshotSourceSize={true}
															videoConstraints={{
																width: 325,
																height: 250,
																facingMode: { exact: "user" },
															}}
															onUserMediaError={(err) => {
																console.log("Test lỗi webcam", err);
																setError(true);
															}}
															imageSmoothing={true}
														/>
													) : (
														<>
															<Button
																type="default"
																onClick={() => setEnableWebcam(true)}>
																{t("worktracking:enablewebcam_on")}
															</Button>
														</>
													)}
												</div>
											</Col>
											<Col span={24}>
												<Button
													disabled={!enableWebcam}
													block
													ghost
													type="primary"
													onClick={(e) => {
														e.preventDefault();
														captureScreenshot();
													}}>
													{t("worktracking:capture")}
												</Button>
											</Col>
										</>
									) : (
										<>
											<Col span={24}>
												<Image
													className="w-full h-full object-cover"
													src={imgScreenshotSrc}
													preview={false}
													style={{ borderRadius: 8 }}
												/>
											</Col>
											<Col span={24}>
												<Button
													block
													ghost
													type="primary"
													onClick={(e) => {
														e.stopPropagation();
														setImgScreenshotSrc("");
													}}
													icon={
														<IconTrash
															size="16"
															color="red"
															className="mr-1 -mt-1"
														/>
													}>
													{t("worktracking:webcamphoto_remove")}
												</Button>{" "}
											</Col>
										</>
									)}
								</Row>
							</Col>
						)}
					</Row>
					<Row
						gutter={[
							{ sm: 4, lg: 8 },
							{ sm: 8, lg: 8 },
						]}
						className="mx-auto md:mx-0 mt-4 mb-0 w-[325px] lg:basis-[500px] gap-2 sm:gap-0">
						<Col span={24} md={12}>
							<Button
								size="large"
								type="primary"
								disabled={
									imgScreenshotSrc === "" ? true : isCheckin ? false : true
								}
								className="rounded-l-lg"
								icon={<IconDoorEnter size={18} className="mr-2 -mt-1" />}
								loading={processing}
								htmlType="button"
								onClick={() => form.submit()}
								block>
								{t("checkin:checkin")}
							</Button>
						</Col>
						<Col span={24} md={12}>
							<Button
								size="large"
								type="primary"
								disabled={
									imgScreenshotSrc === "" ? true : isCheckin ? true : false
								}
								className="rounded-r-lg"
								icon={<IconDoorExit size={18} className="mr-2 -mt-1" />}
								loading={processing}
								htmlType="button"
								onClick={() => form.submit()}
								block>
								{t("checkin:checkout")}
							</Button>
						</Col>
					</Row>
				</Form>
			)}
		</>
	);
};

export default CheckinV2Form;
