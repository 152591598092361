import { PlusSquareOutlined } from "@ant-design/icons";
import { Button, Modal, Select, SelectProps } from "antd";
import SupplierCollection from "common/collections/SupplierCollection";
import SupplierModel from "common/models/SupplierModel";
import SupplierRepository from "common/repositories/SupplierRepository";
import SupplierFormModal from "features/supplier/form/SupplierFormModal";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";

export default function SuppilerSearchCreate({
  selectProps,
  isEdit,
}: {
  selectProps?: SelectProps;
  isEdit?: boolean;
}) {
  const [addVisible, setAddVisible] = useState(false);

  const [supList, setSupList] = useState<SupplierModel[]>([]);

  const [filterSup, setFilterSup] = useState(
    SupplierRepository.getDefaultFilters()
  );

  const fetchDataSupList =
    useCallback(async (): Promise<SupplierCollection> => {
      let collection = await new SupplierRepository().getItems({
        filters: { ...filterSup, limit: 50 },
      });
      if (collection.items.length > 0) {
        setSupList(collection.items);
      }
      return collection;
    }, [filterSup]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchSup = useCallback(
    debounce((value: string) => {
      setFilterSup((pre) => {
        return { ...pre, keyword: value };
      });
    }, 500),
    []
  );

  useEffect(() => {
    fetchDataSupList();
  }, [fetchDataSupList]);
  return (
    <div className=" flex  items-center">
      <Select
        {...selectProps}
        showSearch
        placeholder="Tìm kiếm nhà cung cấp"
        onSearch={(value) => {
          debounceSearchSup(value);
        }}
        filterOption={false}
        allowClear
        options={supList.map((store) => ({
          label: store.name,
          value: store.id,
        }))}
      />

      <Button onClick={() => setAddVisible(true)}>
        <PlusSquareOutlined />
      </Button>

      {addVisible && (
        <SupplierFormModal
          id={0}
          open={addVisible}
          setOpen={(isOpen) => {
            setAddVisible(isOpen);
          }}
          onSaveSuccess={(sup) => {
            setAddVisible(false);
            setSupList((pre) => [sup, ...pre]);
          }}
        />
      )}
    </div>
  );
}
