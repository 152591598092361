import { ClearOutlined } from "@ant-design/icons";
import { IconDoorEnter } from "@tabler/icons-react";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { ActionJson } from "common/types/Action";
import { FileJson } from "common/types/File";
import { SelectOption } from "common/types/SelectOption";
import i18n from "i18n";
import { ReactNode } from "react";
import BaseModel from "./BaseModel";
import EmployeeModel from "./EmployeeModel";
import FileModel from "./FileModel";

type ActionSelectOption = SelectOption & {
  icon?: ReactNode
}

class ActionModel extends BaseModel implements BaseModelJson<ActionJson> {
  company_id: number;
  office_id: number;
  department_id: number;
  creator_id: number;
  employee_id: number;
  cwsg_id: number;
  id: number;
  latitude: number;
  longitude: number;
  work_schedule_range_id: number;
  image: FileJson;
  status: number;
  type: number;
  is_late: number;
  is_final: number;
  verify: number;
  duration: number;
  datecreated: number;
  datemodified: number;
  action: number;
  employee?: EmployeeModel;

  constructor(json: ActionJson) {
    super();
    this.id = json.id || 0;
    this.company_id = json.company_id || 0;
    this.office_id = json.office_id || 0;
    this.department_id = json.department_id || 0;
    this.creator_id = json.creator_id || 0;
    this.employee_id = json.employee_id || 0;
    this.cwsg_id = json.cwsg_id || 0;
    this.latitude = json.latitude || 0;
    this.longitude = json.longitude || 0;
    this.work_schedule_range_id = json.work_schedule_range_id || 0;
    this.image = json.image || 0;
    this.status = json.status || 0;
    this.type = json.type || 0;
    this.is_late = json.is_late || 0;
    this.is_final = json.is_final || 0;
    this.verify = json.verify || 0;
    this.duration = json.duration || 0;
    this.datecreated = json.datecreated || 0;
    this.datemodified = json.datemodified || 0;
    this.action = json.action || 0;
    this.employee = json.employee || new EmployeeModel(EmployeeModel.getDefaultData());
  }

  toJson(): ActionJson {
    return {
      id: this.id,
      company_id: this.company_id,
      office_id: this.office_id,
      department_id: this.department_id,
      creator_id: this.creator_id,
      employee_id: this.employee_id,
      cwsg_id: this.cwsg_id,
      latitude: this.latitude,
      longitude: this.longitude,
      work_schedule_range_id: this.work_schedule_range_id,
      image: this.image,
      status: this.status,
      type: this.type,
      is_late: this.is_late,
      is_final: this.is_final,
      verify: this.verify,
      duration: this.duration,
      datecreated: this.datecreated,
      datemodified: this.datemodified,
      action: this.action,
      employee: this.employee,
    };
  }

  static getDefaultData(): ActionJson {
    return {
      id: 0,
      company_id: 0,
      office_id: 0,
      department_id: 0,
      creator_id: 0,
      employee_id: 0,
      cwsg_id: 0,
      latitude: 0,
      longitude: 0,
      work_schedule_range_id: 0,
      image: FileModel.getDefaultData(),
      status: 0,
      type: 0,
      is_late: 0,
      is_final: 0,
      verify: 0,
      duration: 0,
      datecreated: 0,
      datemodified: 0,
      action: 0,
      employee: new EmployeeModel(EmployeeModel.getDefaultData()),
    };
  }

  static getActionTypeList(): ActionSelectOption[] {
    return [
      {
        value: 0,
        label: i18n.t("action:opt.checkin"),
        color: "orange",
        icon: <IconDoorEnter size={18} />
      },
      {
        value: 1,
        label: i18n.t("action:opt.cleaning"),
        color: "yellow",
        icon: <ClearOutlined size={18} />
      },
    ];
  }

  static getActionType(typeValue: number) {
    return this.getActionTypeList().find((type) => type.value === typeValue);
  }
}

export default ActionModel;
