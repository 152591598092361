import { useCallback, useMemo, useState } from "react";

import { Button, Dropdown, Flex, message, Tag, Typography } from "antd";
import PageDataTable from "components/page/PageDataTable";
import useStateFilter from "hooks/useStateFilter";
import { useTranslation } from "react-i18next";

import useFilterLocation from "hooks/useFilterLocation";

//////////////////////////////////

import EmployeeCollection from "common/collections/EmployeeCollection";
import EmployeeModel from "common/models/EmployeeModel";
import EmployeeRepository from "common/repositories/EmployeeRepository";
import { FilterEmployee } from "common/types/Employee";
import { TableColumnsType } from "common/types/Table";

import TextDateTime from "components/TextDateTime";
import PageDataPagination from "components/page/PageDataPagination";
import ContactMailListFilter from "./ContactMailListFilter";
import ContactMailListHeader from "./ContactMailListHeader";
import ContactMailRepository from "common/repositories/ContactMailRepository";
import { ContactMailStatus, FilterContactMail } from "common/types/ContactMail";
import ContactMailModel from "common/models/ContactMailModel";
import ContactMailCollection from "common/collections/ContactMailCollection";
import ProductRepository from "common/repositories/ProductRepository";
import ProductModel from "common/models/ProductModel";
import ContactMailListExpand from "./ContactMailListExpand";
import { MoreOutlined } from "@ant-design/icons";
import { MenuProps } from "antd/lib";

const ContactMailList = () => {
	const { t } = useTranslation();

	//////////////////////////////////////////
	//Filtering
	const defaultFilters: FilterContactMail = useMemo(
		() => ContactMailRepository.getDefaultFilters(),
		[]
	);

	//state

	const [total, setTotal] = useState<number>(0);
	const [dataSource, setDataSource] = useState<ContactMailModel[]>([]);
	const [productSource, setProductSource] = useState<ProductModel[]>([]);
	const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
	const [loading, setLoading] = useState(false);

	const [filters, setFilters] =
		useStateFilter<FilterContactMail>(defaultFilters);
	useFilterLocation(defaultFilters, filters);

	//////////////////////////////////////////

	//////////////////////////////////////////
	//Fetch data from this collections
	const fetchData = useCallback(async (): Promise<ContactMailCollection> => {
		let collection = await new ContactMailRepository().getItems({
			filters,
		});

		const productCollection = await new ProductRepository().getItems({
			filters: {
				...ProductRepository.getDefaultFilters(),
				ids: [...new Set(collection.items.map((item) => item.item_id))].join(
					","
				),
			},
		});

		if (collection) {
			setTotal(collection.total);
			setDataSource(collection.items);
			setProductSource(productCollection.items);
		} else {
			message.error(t("common:error.error_fetching_data"));
		}

		return collection;
	}, [filters]);

	const onUpdateStatus = async (record: ContactMailModel, status: number) => {
		setLoading(true);

		const response = await new ContactMailRepository().saveRemote({
			id: record.id,
			status,
			contact: record.contact,
			item_id: record.item_id,
			type: record.type,
		});
		if (!response.hasError()) {
			await fetchData();
		} else {
			message.error(t(`newsletter:error.${response.error.errors[0]}`));
		}
		setLoading(false);
	};

	//////////////////////////////////////////
	//Table columns

	const columns: TableColumnsType<ContactMailModel> = [
		{
			title: "ID",
			key: "id",
			width: 50,
		},
		{
			title: t("contactmail:email"),
			key: "contact",
			width: 120,
			render(value, record, index) {
				return <Typography.Text copyable>{value}</Typography.Text>;
			},
		},
		{
			title: t("contactmail:product"),
			key: "product",
			width: 200,
			render: (_, record) => {
				const product = productSource.find(
					(source) => source.id === Number(record.item_id)
				);

				if (!product) return <Tag>Không có thông tin</Tag>;
				const link =
					process.env.NODE_ENV === "production"
						? "https://beta.namperfume.net/products/"
						: "https://uat.namefragrance.vn/products/";
				return (
					<Flex vertical>
						<Typography.Link href={link + product.handle} target="_blank">
							{product.full_name}
						</Typography.Link>
						<Typography.Text copyable color="blue">
							{product.sku}
						</Typography.Text>
					</Flex>
				);
			},
		},
		{
			title: t("common:status"),
			key: "status",
			width: 60,
			render(status: number, record) {
				const label = ContactMailModel.getStatus(status);
				return <Tag color={label?.color}>{label?.label}</Tag>;
			},
		},
		{
			title: t("contactmail:date_created"),
			key: "date_created",
			width: 80,
			render(_, record) {
				return (
					<TextDateTime ts={record.date_created} format="HH:mm, DD/MM/YYYY" />
				);
			},
		},
		{
			title: " ",
			toggletype: "trigger",
			key: "actions",
			align: "right",
			fixed: "right",
			width: 100,
			render: (_: number, record) => {
				const statusToUpdate =
					record.status === ContactMailStatus.ENABLE
						? ContactMailStatus.DISABLE
						: ContactMailStatus.PROCESSED;

				const items: MenuProps["items"] = [
					{
						key: "1",
						label: (
							<Button
								type="text"
								title="Đã xử lý"
								onClick={() => onUpdateStatus(record, statusToUpdate)}>
								{record.status === ContactMailStatus.PROCESSED
									? t("contactmail:status.1")
									: t("contactmail:status.2")}
							</Button>
						),
					},
				];
				return (
					<div onClick={(e) => e.stopPropagation()}>
						<Dropdown
							disabled={record.status !== ContactMailStatus.ENABLE}
							trigger={["click"]}
							menu={{ items }}>
							<MoreOutlined
								className="disabled:cursor-not-allowed cursor-pointer"
								disabled={record.status !== ContactMailStatus.ENABLE}
								style={{ fontSize: "16pt" }}
							/>
						</Dropdown>
					</div>
				);
			},
		},
	];

	return (
		<>
			<ContactMailListHeader />
			<ContactMailListFilter
				total={total}
				filters={filters}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
			/>

			<PageDataTable<FilterContactMail, ContactMailModel, ContactMailCollection>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					tableColumnToggleKey: "contactmail",
					rowClassName: "cursor-pointer",
					expandable: {
						expandedRowRender: (record: ContactMailModel) => (
							<ContactMailListExpand record={record} />
						),
						expandedRowClassName: () =>
							"ant-table-expanded-row-dimmer [&_.ant-table]:!ms-0 [&_.ant-table]:!me-0 [&_.ant-table]:!mt-0 [&_.ant-table]:!mb-0",
						expandRowByClick: true,
						showExpandColumn: false,
						expandedRowKeys: expandedRowKeys,
						onExpand: (expanded: boolean, record: ContactMailModel) => {
							setExpandedRowKeys(
								expanded
									? [...expandedRowKeys, record.id]
									: expandedRowKeys.filter((key) => key !== record.id)
							);
						},
					},
				}}></PageDataTable>

			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>
			{/* <EmployeeLeaveFormModal
        setModel={setEmployee}
        model={employee}
        visible={showModal}
        setVisible={setShowModal}
        onSaveSuccess={onSaveSuccess}
      /> */}
		</>
	);
};

export default ContactMailList;
