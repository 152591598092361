import { Card, DatePicker } from "antd";
import { Button } from "antd";
import { Col, Form, Input, Row } from "antd";

import { useForm } from "antd/es/form/Form";
import { CloseOutlined } from "@ant-design/icons";
import useMessage from "antd/es/message/useMessage";
import UploadEvent from "../UploadEvent";
import { ZoneDataBrandList } from "common/types/Event";
import Helper from "common/utils/helper";
import dayjs from "dayjs";

const SectionBrandList = ({
	data,
	onSubmit,
}: {
	data: ZoneDataBrandList[];
	onSubmit: (data: ZoneDataBrandList[]) => void;
}) => {
	const [message] = useMessage();
	const { RangePicker } = DatePicker;

	const [form] = useForm();

	const init = {
		items: data.map((item) => ({
			...item,
			available_type: [
				dayjs(item.from_time * 1000 || Date.now()),
				dayjs(item.to_time * 1000 || Date.now()),
			],
		})),
	};

	const rangeConfig = {
		rules: [
			// {
			// 	type: "array" as const,
			// 	required: true,
			// 	message: "Vui lòng chọn thời gian!",
			// },
		],
	};

	const dateFormat = "HH:mm:ss DD-MM-YYYY";

	const onFinish = (values: any) => {
		const result: ZoneDataBrandList[] = values.items.map((item: any) => {
			const fromTimestamp = Helper.convertedDateToTimeStamp(
				item?.available_type?.[0] || 0
			) as number;
			const toTimestamp = Helper.convertedDateToTimeStamp(
				item?.available_type?.[1] || 0
			) as number;

			return {
				from_time: Math.max(0, fromTimestamp),
				to_time: Math.max(0, toTimestamp),
				img: item.img,
				url: item.url,
			};
		});
		console.log(
			"🚀 ~ constresult:ZoneDataBrandList[]=values.items.map ~ result:",
			result
		);

		onSubmit(result);
	};

	return (
		<Form
			labelCol={{ span: 6 }}
			wrapperCol={{ span: 18 }}
			form={form}
			name="dynamic_form_brand_list"
			autoComplete="off"
			initialValues={init}
			layout="vertical"
			scrollToFirstError
			onFinish={onFinish}>
			<Form.List name="items">
				{(fields, { add, remove }) => (
					<div style={{ display: "flex", rowGap: 16, flexDirection: "column" }}>
						{fields.map((field, index) => (
							<Card
								size="default"
								title={`Hình ${field.name + 1}`}
								key={field.key}
								extra={
									<CloseOutlined
										onClick={() => {
											remove(field.name);
										}}
									/>
								}>
								<Row gutter={[8, 8]}>
									<Col span={24}>
										<UploadEvent
											className="flex gap-2"
											height={120}
											defaultUrl={data[index].img}
											onSuccess={(url) => {
												message.success("Thay đổi thành công");
												form.setFieldValue(["items", field.name, "img"], url);
											}}></UploadEvent>
									</Col>
									<Col span={24}>
										<Form.Item
											labelCol={{ span: 24 }}
											label="Mốc thời gian chạy"
											{...rangeConfig}
											name={[field.name, "available_type"]}>
											<RangePicker showTime format={dateFormat} />
										</Form.Item>
									</Col>
									<Col span={24}>
										<Form.Item initialValue={"123"} name={[field.name, "url"]}>
											<Input></Input>
										</Form.Item>
									</Col>
								</Row>
							</Card>
						))}

						<Button type="dashed" onClick={() => add()} block>
							+ Thêm nhóm
						</Button>

						<Button
							className="  absolute bottom-10 right-4 w-[100px]"
							type="primary"
							htmlType="submit">
							Lưu
						</Button>
					</div>
				)}
			</Form.List>
		</Form>
	);
};

export default SectionBrandList;
