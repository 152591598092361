import { Button, Collapse, Flex, Skeleton, Typography, message } from "antd";
import SettingApi from "common/constants/Setting";

import { SelectOptionNormal } from "common/types/SelectOption";
import ListDeleteButton from "components/list/ListDeleteButton";
import PageSortList from "components/list/PageSortList";
import useHandleSetting from "hooks/useHandleSetting";
import { useEffect, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import ShopCollectionForm from "../form/ShopCollectionForm";
import ShopCollectionHeader from "./ShopCollectionHeaderList";

export type ShopSectionJson = {
	id: number;
	handle: string;
	title: string;
	type: string;
	from_time: number;
	to_time: number;
	link: string;
	link_more: string;
	title_more: string;
	dataSource?: DataSourceShopCollection;
};

export type DataSourceShopCollection = {
	params: ParamsShopCollection;
};

export type ParamsShopCollection = {
	[key: string]: string | number;
};

const ShopCollectionList = () => {
	const [open, setOpen] = useState<boolean>(false);
	const [shopCollectionOptions, setShopCollectionOptions] = useState<
		SelectOptionNormal[]
	>([]);

	const defaultDataSection: ShopSectionJson = {
		id: 0,
		handle: "",
		title: "",
		type: "",
		from_time: 0,
		to_time: 0,
		link: "",
		link_more: "",
		title_more: "",
		dataSource: {
			params: {},
		},
	};

	const [data, setData] = useState<{
		data: ShopSectionJson;
		index: number;
		type: "add" | "edit";
	}>({
		data: defaultDataSection,
		index: -1,
		type: "add",
	});

	const {
		dataSource,
		loading,
		addItemSetting,
		deleteItemSetting,
		editItemSetting,
		handleDrag,
		reload,
	} = useHandleSetting<ShopSectionJson>(
		SettingApi.KEY.k_settings_website_namperfume_net,
		"vi"
	);

	const handleDragEnd = async (result: DropResult) => {
		try {
			if (!result.destination) return;
			await handleDrag(result.source.index, result.destination.index);
			message.success({
				content: "Thao tác thành công",
				duration: 1,
			});
		} catch (error) {
			message.error({
				content: "Thao tác thất bại",
				duration: 1,
			});
		}
	};

	function handlelOpenModalAdd(data: ShopSectionJson) {
		setData({
			data: data,
			type: "add",
			index: -1,
		});
		setOpen(true);
	}
	function handlelOpenModalEdit(data: ShopSectionJson, index: number) {
		if (data.handle === "section_banner") {
		}
		setData({
			data: data,
			type: "edit",
			index,
		});
		setOpen(true);
	}

	async function handleSumit(dataRes: ShopSectionJson) {
		console.log(dataRes);
		try {
			if (data.type === "add") {
				await addItemSetting(dataRes);
			} else {
				await editItemSetting(dataRes, data.index);
			}
		} catch (error) {
		} finally {
			setOpen(false);
		}
	}

	async function handleDelete(data: ShopSectionJson, index: number) {
		try {
			await deleteItemSetting(data, index);
		} catch (error) {
		} finally {
			setOpen(false);
		}
	}

	useEffect(() => {
		if (dataSource.length > 0) {
			let result: string[] = [];
			for (let i = 0; i < dataSource.length; i++) {
				if (!result.includes(dataSource[i].type)) {
					result.push(dataSource[i].type);
				}
			}
			if (result.length > 0) {
				setShopCollectionOptions(
					result.map((item) => {
						return {
							label: item,
							value: item,
						};
					})
				);
			}
		}
	}, [dataSource]);

	return (
		<div>
			<ShopCollectionHeader>
				<Flex gap={4}>
					<Button loading={loading} onClick={reload}>
						Tải lại
					</Button>
					<Button
						type="primary"
						onClick={() => handlelOpenModalAdd(defaultDataSection)}>
						Thêm
					</Button>
				</Flex>
			</ShopCollectionHeader>

			{!loading ? (
				<>
					<PageSortList
						dataSource={dataSource}
						handleDragEnd={handleDragEnd}
						listKey="shopcollectionlist"
						renderListItem={(item: ShopSectionJson, index) => {
							return (
								<>
									<div className="p-2">
										<div className="flex  items-center justify-between">
											<div className="flex flex-col">
												<Typography.Text className="font-bold">
													{item.title}
												</Typography.Text>

												<Typography.Text className="">
													{item.type}
												</Typography.Text>

												<Typography.Text>
													{item.handle}
												</Typography.Text>
											</div>
											<div className=" flex gap-2">
												<Button
													onClick={() => {
														handlelOpenModalEdit(item, index);
													}}>
													Sửa
												</Button>

												<ListDeleteButton
													handleDelete={() => {
														handleDelete(item, index);
													}}
													id={index}
												/>
											</div>
										</div>
									</div>
								</>
							);
						}}
					/>
				</>
			) : (
				<Skeleton active />
			)}
			<ShopCollectionForm
				shopCollectionOptions={shopCollectionOptions}
				type={data.type}
				data={data.data}
				open={open}
				onCancel={() => setOpen(false)}
				onFinish={(data) => {
					console.log(data);
					handleSumit(data);
				}}
			/>
		</div>
	);
};

export default ShopCollectionList;
