import { Col, Form, Input, Row, Spin } from "antd";
import MoneyInput from "components/MoneyInput";
import FormSection from "components/form/FormSection";
import LayoutForm from "components/form/LayoutForm";

import Delivery from "common/constants/Delivery";
import DeliveryModel from "common/models/DeliveryModel";
import DeliveryRepository from "common/repositories/DeliveryRepository";
import { DeliveryInternalAddEditJson } from "common/types/Delivery";
import { SelectOption } from "common/types/SelectOption";
import FormSelectNormal from "components/form/FormSelectNormal";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DeliveryInternalFormHeader from "./DeliveryInternalFormHeader";
import DeliveryInternalOrderDetail from "./DeliveryInternalOrderDetail";
import { LocationNameJson } from "pages/DeliveryPage";
import ShippingCarrierRepository from "common/repositories/ShippingCarrierRepository";
import { ShippingCarrierJson } from "common/types/ShippingCarrier";

type Props = {
	model: DeliveryModel;
	// modelOrder: OrderModel;
	redirectOnSuccess?: string;
	dataLocationName?: LocationNameJson;
	onSubmitForm?: (data: DeliveryInternalAddEditJson) => void;
	submitText?: string;
	isShowHeader?: boolean;
	fieldsLock?: (keyof DeliveryInternalAddEditJson)[];
};

const DeliveryInternalForm = ({
	model,
	dataLocationName,
	redirectOnSuccess = "/delivery/manager",
	onSubmitForm,
	submitText,
	isShowHeader = true,
	fieldsLock = [],
}: Props) => {
	//
	let isEditing = model.id > 0 ? true : false;

	const { TextArea } = Input;
	const { t } = useTranslation();
	const [loading, setLoading] = useState<boolean>(false);
	const [resourceType, setResourceType] = useState<any>(
		model.resource_type || 1
	);

	const [shippingcarrier, setShippingcarrier] = useState<ShippingCarrierJson[]>(
		[]
	);

	//
	const [errors, setErrors] = useState<string[]>([]);
	const [isSuccess, setIsSuccess] = useState(false);
	const [form] = Form.useForm();
	//

	function handleLock(name: keyof DeliveryInternalAddEditJson) {
		return fieldsLock.includes(name);
	}

	const initialValues: any = {
		id: model.id,
		cotips: model.cotips,
		resource_id: model.resource_id,
		resource_type: model.resource_type > 0 ? model.resource_type : 1,
		cost_total: model.cost_total,
		cod: model.cod,
		cost_note_transfer: model.cost_note_transfer,
		contact_fullname: model.contact_fullname,
		contact_phone: model.contact_phone,
		// contact_address:
		//   model.contact_address +
		//   `${
		//     dataLocationName
		//       ? `, ${dataLocationName.ward}, ${dataLocationName.district} , ${dataLocationName.province}`
		//       : ""
		//   }`,
		contact_address: model.contact_address,
		note: model.note,
		payment_method: model.payment_method > 0 ? model.payment_method : 1,
		order_detail: model.order_detail || "",
		partner_delivery: model.partner_delivery || null,
		code: model.code,
	};

	// function
	const doPrepareData = useCallback(
		(formData: any) => {
			const submitData: any = {
				id: model.id,
				cotips: formData.cotips || 0,
				resource_id: formData.resource_id || "",
				resource_type: formData.resource_type,
				cost_total: formData.cost_total,
				cod: formData.cod,
				cost_note_transfer: formData.cost_note_transfer,
				contact_fullname: formData.contact_fullname.toUpperCase(),
				contact_phone: formData.contact_phone,
				contact_address: formData.contact_address,
				note: formData.note || "",
				payment_method: formData.payment_method || 1,
				partner_delivery: formData.partner_delivery,
				code: formData.code,
				order_detail:
					// (model.order_description !== "" ? model.order_description : "") +
					formData.order_detail,
			};
			return submitData;
		},
		[model.id]
	);

	const onSubmit = async (formData: DeliveryInternalAddEditJson) => {
		// console.log(formData);
		if (onSubmitForm) {
			// setIsSuccess(true);
			const data = doPrepareData(formData);

			onSubmitForm(data);
			return;
		}
		// console.log(doPrepareData(formData));
		const data = doPrepareData(formData);
		let response: DeliveryModel = await new DeliveryRepository().saveRemote(
			data
		);
		if (response.hasError()) {
			setErrors(response.error.errors);
		} else {
			setIsSuccess(true);
		}
	};

	useEffect(() => {
		(async () => {
			const res = await new ShippingCarrierRepository().getItems({
				filters: ShippingCarrierRepository.getDefaultFilters(),
			});
			if (!res.hasError()) {
				setShippingcarrier(res.toJson().items);
			}
		})();
	}, []);

	useEffect(() => {
		if (shippingcarrier.length) {
			form.setFieldValue(
				"partner_delivery",
				model.partner_delivery ? model.partner_delivery : null
			);
		}
	}, [shippingcarrier]);

	const sidebarItems = <div></div>;

	return (
		<>
			{isShowHeader && <DeliveryInternalFormHeader isEditing={isEditing} />}
			<Spin spinning={loading}>
				<LayoutForm
					form={form}
					initialValues={initialValues}
					errors={errors}
					isSuccess={isSuccess}
					successTitle={
						isEditing
							? t("delivery:form.internal_success.edit")
							: t("delivery:form.internal_success.add")
					}
					redirectOnSuccess={redirectOnSuccess}
					error_translate_prefix="delivery:form.error"
					onSubmit={onSubmit}
					disable_status_loading
					submitText={
						submitText
							? submitText
							: isEditing
							? t("delivery:form.heading_edit")
							: t("delivery:form.heading_add")
					}
					sidebarItems={null}>
					<FormSection
						title={t("delivery:form.section_customer_delivery")}
						subtitle={t("delivery:form.section_customer_delivery_sub")}
						divider
						className="bg-green-50  pt-5">
						<Row gutter={16}>
							<Col md={12} xs={24}>
								<Form.Item
									label={t("delivery:form.contact_fullname")}
									name="contact_fullname"
									validateFirst
									rules={[
										{
											required: true,
											message: t("common:error.required_full_name"),
										},
									]}>
									<Input
										disabled={handleLock("contact_fullname")}
										size="large"
										placeholder={t("delivery:form.placeholder_fullname")}
										style={{ textTransform: "uppercase" }}
									/>
								</Form.Item>
							</Col>
							<Col md={12} xs={24}>
								<Form.Item
									required
									label={t("employee:phone")}
									name="contact_phone"
									validateFirst
									rules={[
										{
											required: true,
											message: t("common:error.required_phone"),
										},
										{
											message: t("common:error.error_phone_format"),
											validator: (_: any, value: string) => {
												// console.log(Number(value));
												if (Number(value) >= 0) {
													return Promise.resolve();
												} else {
													return Promise.reject("Some message here");
												}
											},
										},
										{
											message: t("common:error.error_phone_format"),
											validator: (_: any, value: string) => {
												// console.log(Number(value));
												if (value.length === 10 || value.length === 11) {
													return Promise.resolve();
												} else {
													return Promise.reject("Some message here");
												}
											},
										},
									]}>
									<Input
										placeholder="VD: 0987654xxx"
										size="large"
										disabled={handleLock("contact_phone")}
									/>
								</Form.Item>
							</Col>
							<Col md={24} xs={24}>
								<Form.Item
									label="Địa chỉ giao hàng"
									required
									name="contact_address"
									rules={[
										{
											required: true,
											message: "Địa chỉ giao hàng không được trống",
										},
									]}>
									<Input
										placeholder="44 Hoa Mai, Phú Nhuận, Thành phố Hồ Chí Minh"
										disabled={handleLock("contact_address")}
									/>
								</Form.Item>
							</Col>
							{/* <Col md={24} xs={24}>
                <Form.Item
                  label="Số nhà/tên đường"
                  required
                  name="contact_address"
                  rules={[
                    {
                      required: true,
                      message: "Địa chỉ giao hàng không được trống",
                    },
                  ]}
                >
                  <Input placeholder="44 Hoa Mai, Phú Nhuận, Thành phố Hồ Chí Minh" />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <FormSelect
                  label="Tỉnh/Thành phố"
                  // required
                  showSearch
                  name="province"
                  onChange={(e) => {
                    handleRegionPick(e);
                  }}
                  options={region}
                  placeholder="Chọn Tỉnh/TP"
                />
              </Col>{" "}
              <Col md={8} xs={24}>
                <FormSelect
                  label="Quận/Huyện"
                  showSearch
                  // required
                  name="district"
                  onChange={(e) => {
                    handleSubRegionPick(e);
                  }}
                  options={subRegion}
                  placeholder="Chọn Quận/Huyện"
                />
              </Col>
              <Col md={8} xs={24}>
                <FormSelect
                  label="Phường/Xã"
                  showSearch
                  // required
                  name="ward"
                  options={subSubRegion}
                  placeholder="Chọn Phường/Xã"
                />
              </Col> */}
						</Row>
					</FormSection>
					<FormSection
						title={t("Thông tin vận đơn")}
						divider
						className="bg-green-50  pt-5">
						<Row gutter={16}>
							<Col md={12} xs={24}>
								<FormSelectNormal
									// disabled={isEditing}
									disabled={handleLock("partner_delivery")}
									label="Đối tác vận chuyển"
									rules={[
										{
											required: true,
											message: t("common:form.required"),
										},
									]}
									name="partner_delivery"
									defaultValue={1}
									options={shippingcarrier.map((data) => ({
										label: data.name,
										value: data.id,
									}))}
									placeholder="Nguồn hàng"
									onChange={(e) => {
										setResourceType(e);
									}}
								/>
							</Col>
							<Col md={12} xs={24}>
								<Form.Item label="Mã vận đơn" name="code">
									<Input disabled={handleLock("code")} />
								</Form.Item>
							</Col>
						</Row>
					</FormSection>
					<FormSection
						title="Thông tin đơn hàng"
						subtitle="Các thông tin chi tiết của đơn hàng"
						divider>
						<Row gutter={[16, 16]}>
							<Col md={8} xs={24}>
								<Form.Item
									label="Mã đơn hàng"
									name="resource_id"
									rules={[
										{
											message: "Mã đơn hàng không hợp lệ",
											validator: (_: any, value: string) => {
												// console.log(Number(value));
												if (
													value.length ||
													(value === "" && resourceType === 1)
												) {
													return Promise.resolve();
												} else {
													return Promise.reject("Some message here");
												}
											},
										},
										{
											message: "Mã đơn hàng không được vượt 30 ký tự",
											validator: (_: any, value: string) => {
												// console.log(Number(value));
												if (value.length <= 30) {
													return Promise.resolve();
												} else {
													return Promise.reject("Some message here");
												}
											},
										},
									]}>
									<Input
										style={{ textTransform: "uppercase" }}
										className="uppercase "
										disabled={handleLock("resource_id")}

										// addonBefore="MDH"
									></Input>
								</Form.Item>
							</Col>
							<Col md={8} xs={24}>
								<FormSelectNormal
									disabled={handleLock("resource_type")}
									label="Nguồn hàng"
									required
									name="resource_type"
									defaultValue={Delivery.METHOD_CASH}
									options={[
										{ value: 1, label: "Nội bộ" },
										{ value: 2, label: "Haravan" },
									]}
									placeholder="Nguồn hàng"
									onChange={(e) => {
										setResourceType(e);
									}}
								/>
							</Col>

							<Col md={8} xs={24}>
								<Form.Item
									label="Số tiền đơn hàng"
									name="cost_total"
									rules={[
										{
											required: !isEditing,
											message: "Yêu cầu số tiền đơn hàng ",
										},
									]}>
									<MoneyInput
										// disabled={isEditing}
										disabled={handleLock("cost_total")}
										name="cost_total"
										style={{ width: "100%" }}
										addonAfter="VND"
									/>
								</Form.Item>
							</Col>
							<Col md={8} xs={24}>
								<Form.Item
									label="Số tiền đã thu"
									name="cost_note_transfer"
									rules={[
										{
											required: false,
										},
										{
											message:
												"Số tiền đã thu phải nhỏ hơn hoặc bằng số tiền đơn hàng",
											validator: (_, value) => {
												// console.log(typeof value)
												// if (typeof value === "number" && value > 0) {
												if (Number(value) <= form.getFieldValue("cost_total")) {
													return Promise.resolve();
												} else {
													return Promise.reject("Some message here");
												}
											},
										},
									]}>
									<MoneyInput
										name="cost_note_transfer"
										style={{ width: "100%" }}
										addonAfter="VND"
										disabled={handleLock("cost_note_transfer")}
									/>
								</Form.Item>
							</Col>
							<Col md={8} xs={24}>
								<Form.Item
									label="Số tiền shipper phải thu"
									name="cod"
									rules={[
										{
											required: false,
											message: "Yêu cầu số tiền phải thu",
										},
										// {
										//   message: t(
										//     "Số tiền shipper phải thu phải nhỏ hơn hoặc bằng số tiền đơn hàng"
										//   ),
										//   validator: (_, value) => {
										//     // console.log(typeof value)
										//     // if (typeof value === "number" && value > 0) {
										//     if (Number(value) <= form.getFieldValue("cost_total")) {
										//       return Promise.resolve();
										//     } else {
										//       return Promise.reject("Some message here");
										//     }
										//   },
										// },
									]}>
									<MoneyInput
										name="cod"
										style={{ width: "100%" }}
										addonAfter="VND"
										disabled={handleLock("cod")}
									/>
								</Form.Item>
							</Col>

							<Col md={8} xs={24}>
								<Form.Item
									label="Phương thức thanh toán"
									name="payment_method"

									// rules={[
									//   {
									//     required: true,
									//     message: t("employee:form.error.error_phone_required"),
									//   },
									// ]}
								>
									<FormSelectNormal
										// disabled={isEditing}
										disabled={handleLock("payment_method")}
										required
										name="payment_method"
										defaultValue={Delivery.METHOD_CASH}
										options={DeliveryModel.getPaymentMethodSelect()}
										placeholder="Phương thức thanh toán"
										rules={[
											{
												required: false,
												message: t("employee:form.error.error_role_required"),
											},
										]}
									/>
								</Form.Item>
							</Col>
						</Row>
					</FormSection>
					<FormSection
						title="Thông tin chi tiết"
						subtitle="Thông tin chi tiết đơn hàng"
						divider>
						<Col md={24} xs={24}>
							<DeliveryInternalOrderDetail
								resourceType={resourceType}
								isEditing={isEditing}
								order_detail={model.order_detail}
							/>
						</Col>
					</FormSection>
					<FormSection
						title="Thông tin bổ sung"
						subtitle="Ghi chú, thông tin đính kèm cho vận đơn">
						<Col md={24} xs={24}>
							<Form.Item
								label="Ghi chú"
								name="note"
								rules={[
									{
										required: false,
										message: "Ghi chú không được trống",
									},
								]}>
								<TextArea
									disabled={handleLock("note")}
									name="note"
									rows={4}
									placeholder="Nhập ghi chú (nếu có)"
								/>
							</Form.Item>
						</Col>
					</FormSection>
				</LayoutForm>
			</Spin>
		</>
	);
};

export default DeliveryInternalForm;
