import {
	Alert,
	Col,
	Divider,
	Flex,
	Form,
	FormInstance,
	Input,
	Popover,
	Row,
	Table,
	Tag,
	Tooltip,
	Typography,
} from "antd";
import OrderDetailModel from "common/models/OrderDetailModel";
import { OrderEditingPrice, OrderJson } from "common/types/Order";
import MoneyInput from "components/MoneyInput";
import TextMoney from "components/TextMoney";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useEditingSaleOrderStore from "zustands/useEditingSaleOrder";

import { IconTruck } from "@tabler/icons-react";
import OrderDetailPriceSection from "./OrderDetailPriceSection";
import { TableColumnsType } from "common/types/Table";
import Helper from "common/utils/helper";
import DeliveryModel from "common/models/DeliveryModel";
import i18n from "i18n";
import { GiftOutlined } from "@ant-design/icons";
import { CartOrderJson } from "common/types/Cart";
import TableInfoRow from "components/table/TableInfoRow";
import TextDateTime from "components/TextDateTime";
import PromotionModel from "common/models/PromotionModel";
const OrderDetailPriceSummary = ({
	model,
	items,
	form,
	allowEditInput,
}: {
	model: OrderJson;
	items: CartOrderJson[];
	form: FormInstance;
	allowEditInput: boolean;
}) => {
	const { t } = useTranslation();
	const order = useEditingSaleOrderStore((state) => state.order);
	const [priceDiscount, setPriceDiscount] = useState<number>(
		order.price_discount
	);
	const [priceShipping, setPriceShipping] = useState(order.price_shipping);
	const [taxRatio, setTaxRatio] = useState(order.tax_ratio);

	// const columns: TableColumnsType<{
	// 	amount: number;
	// 	method: string;
	// 	code?: string;
	// }> = [
	// 	{
	// 		title: t("order:detail.no"),
	// 		dataIndex: "id",
	// 		width: 60,
	// 		align: "center",
	// 		render: (_, __, index: any) => {
	// 			return <>{index + 1}</>;
	// 		},
	// 	},

	// 	{
	// 		title: t("Phương thức"),
	// 		dataIndex: "method",
	// 		align: "left",
	// 		render: (method: string, record) => {
	// 			let namePayment = "";

	// 			// const listOptionPayments = DeliveryModel.getPaymentMethodSelect();
	// 			// namePayment =
	// 			// 	listOptionPayments.find((i) => i.value === method)?.label || "";
	// 			switch (method) {
	// 				case "Voucher":
	// 					namePayment = i18n.t("delivery:method.METHOD_GIFTCARD");
	// 					break;
	// 				case "transfer":
	// 					namePayment = i18n.t("delivery:method.METHOD_BANK");
	// 					break;
	// 				case "vnpay":
	// 					namePayment = i18n.t("delivery:method.METHOD_VNPAY");
	// 					break;
	// 				default:
	// 					break;
	// 			}

	// 			return (
	// 				<>
	// 					{namePayment} {record?.code && <strong>{record.code}</strong>}{" "}
	// 				</>
	// 			);
	// 		},
	// 	},
	// 	{
	// 		title: t("Số tiền"),
	// 		dataIndex: "amount",
	// 		align: "right",
	// 		render: (amount: number) => {
	// 			return <>{Helper.moneyFormat(amount)}</>;
	// 		},
	// 	},
	// ];

	const promotionsFull = model.promotions.filter((i) => i.discount > 0);
	useEffect(() => {
		//sync to formdata
		form.setFieldsValue({
			price_sell: model.price_sell,
			price_final: model.price_final,
			price_shipping: model.price_shipping,
			price_discount: model.price_discount,
			total_payment: model.total_payment,
		});
	}, [form, model]);

	return (
		<div className="px-2 pt-2">
			<Row gutter={16} className="my-4" justify="end">
				<Col span={12}>
					{promotionsFull.length > 0 && (
						<Row
							gutter={16}
							className="mb-2 border border-gray-300 border-dashed">
							<Col flex={"full"}>
								<div className="mt-1 flex flex-col gap-1 cursor-pointer">
									<Typography.Text>
										<GiftOutlined className="mr-1 text-red-500" />
										{t("Thông tin khuyến mãi")} :
									</Typography.Text>
									<ul>
										{promotionsFull.map((pro) => {
											return (
												<>
													<Popover
														key={pro.promotion_id}
														content={
															<div>
																<TableInfoRow
																	label={t("Mã chương trình")}
																	content={
																		pro.promotion_detail.campaign_info.id
																	}
																/>

																<TableInfoRow
																	label={t("Mã code")}
																	content={
																		pro.promotion_detail.campaign_info.code
																	}
																/>

																<TableInfoRow
																	label={t("Tên chương trình")}
																	content={
																		<Typography.Text
																			ellipsis={{
																				tooltip: (
																					<>
																						{
																							pro.promotion_detail.campaign_info
																								.name
																						}
																					</>
																				),
																			}}>
																			{pro.promotion_detail.campaign_info.name}
																		</Typography.Text>
																	}
																/>

																<TableInfoRow
																	label={t("Ngày tạo")}
																	content={
																		<TextDateTime
																			format="HH:ss DD/MM/YYYY"
																			ts={
																				pro.promotion_detail.create_date
																			}></TextDateTime>
																	}
																/>

																<TableInfoRow
																	label={t("Giá trị giảm")}
																	content={
																		pro.promotion_detail.discount_value_type ===
																		"percent" ? (
																			<>
																				{pro.promotion_detail.discount_value}
																				{PromotionModel.getDiscountValueType(
																					pro.promotion_detail
																						.discount_value_type
																				)}
																			</>
																		) : (
																			<TextMoney
																				money={
																					pro.promotion_detail.discount_value
																				}
																			/>
																		)
																	}
																/>

																<TableInfoRow
																	label={t("Mô tả")}
																	content={
																		<Typography.Text
																			ellipsis={{
																				tooltip: (
																					<>
																						{
																							pro.promotion_detail.campaign_info
																								.description
																						}
																					</>
																				),
																			}}>
																			{
																				pro.promotion_detail.campaign_info
																					.description
																			}
																		</Typography.Text>
																	}
																/>
															</div>
														}>
														<li key={pro.promotion_id}>
															{`${
																pro.promotion_detail.campaign_info.code ||
																"_code"
															} ${
																pro.promotion_detail.campaign_info.name ||
																"_name"
															}  ${
																pro.promotion_detail.campaign_info
																	.description || ""
															}`}
														</li>
													</Popover>
												</>
											);
										})}
									</ul>
									{/* {promotionsFull.map((pro) => {
								return (
									<Alert
										message={`${pro.promotion_detail.campaign_info.code} ${pro.promotion_detail.campaign_info.name}  ${pro.promotion_detail.campaign_info.description}`}
										key={pro.promotion_id}
									/>
								);
							})} */}
								</div>
							</Col>
						</Row>
					)}

					{model.coupons.length > 0 && (
						<Row
							gutter={16}
							className="mb-2 border border-gray-300 border-dashed">
							<Col flex={"full"}>
								<div className="mt-1 flex flex-col gap-1">
									<Typography.Text>
										<GiftOutlined className="mr-1 text-red-500" />
										{t("Mã Coupons")} :
									</Typography.Text>
									<ul>
										{model.coupons.map((coupon) => {
											return (
												<li key={coupon.id}>
													Mã <strong>{coupon.code}</strong>
													{` ${coupon.coupon_detail.coupon_campaign.name}`}
												</li>
											);
										})}
									</ul>
								</div>
							</Col>
						</Row>
					)}
				</Col>
				<Col span={12} flex="auto" className="w-fit">
					<>
						<Row gutter={16} className="mb-4">
							<Col flex={"auto"} className="text-right">
								{t("order:price_sell")} :
							</Col>
							<Col flex={"170px"} className="text-right">
								<span className="font-bold">
									<TextMoney money={model.price_sell} />
									<Form.Item hidden name="price_sell">
										<Input type={"hidden"} />
									</Form.Item>
								</span>
							</Col>
						</Row>
						{/* <Row gutter={16} className="mb-2 bg-gray-100 py-1">
				<Col span={24} className="mb-2">
					<div className="mt-1">
					
						{t("Voucher")} :
					</div>
					<Row gutter={[0, 8]}>
						{order.payments
							.filter((p) => p.method === "Voucher")
							.map((i, index) => (
								<Col span={24} key={index}>
									<Row gutter={16} className="gap-8">
										<Col flex={"auto"} className="text-right">
											{i?.code && <Tag className="flex-auto">{i.code}</Tag>}
										</Col>
										<Col flex={"170px"}>
											<MoneyInput
												value={i.amount}
												readOnly
												disabled={!allowEditInput}
												style={{ width: "100%" }}
												onChange={(v) => setPriceDiscount(v !== null ? +v : 0)}
											/>
										</Col>
									</Row>
								</Col>
							))}
					</Row>
				</Col>

				<Col span={24} className="text-right">
					<Row gutter={16}>
						<Col flex={"auto"} className="text-right">
							Tổng :
						</Col>
						<Col flex={"170px"}>
							<Form.Item noStyle>
								<MoneyInput
									readOnly
									disabled={!allowEditInput}
									style={{ width: "100%" }}
									onChange={(v) => setPriceDiscount(v !== null ? +v : 0)}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Col>
			</Row> */}
						<Row gutter={16} className="mb-2">
							<Col flex={"auto"} className="text-right">
								<div className="mt-1">
									{/* {order.promotion_id > 0 &&
						typeof order.promotion_detail === "object" ? (
							<OrderDetailPriceSection
								promotion_detail={order.promotion_detail}
							/>
						) : null} */}
									{t("Giảm giá KM")} :
								</div>
							</Col>
							<Col flex={"170px"} className="text-right">
								<Form.Item noStyle>
									<MoneyInput
										readOnly
										disabled={!allowEditInput}
										value={-order.order_discount}
										style={{ width: "100%" }}
										onChange={(v) => setPriceDiscount(v !== null ? +v : 0)}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={16} className="mb-2" align="middle" justify="end">
							<Col flex={"auto"} className="text-right">
								<Typography.Text className="whitespace-nowrap">
									{t("order:detail.custom_manual_discount")} :
								</Typography.Text>
							</Col>

							<Col flex={"170px"} className="shrink-0">
								<Form.Item noStyle>
									<MoneyInput
										readOnly
										disabled={!allowEditInput}
										value={-order.order_custom_discount}
										style={{ width: "100%" }}
										onChange={(v) => setPriceDiscount(v !== null ? +v : 0)}
									/>
								</Form.Item>
							</Col>
							{order.note_discout_custom && (
								<>
									<Col span={24} className="text-left">
										<Typography.Text type="secondary">
											Lý do: {order.note_discout_custom}
										</Typography.Text>
									</Col>
									<Divider></Divider>
								</>
							)}
						</Row>
						<Row gutter={16} className="mb-2">
							<Col flex={"auto"} className="text-right">
								<div className="mt-1">
									{/* {order.promotion_id > 0 &&
						typeof order.promotion_detail === "object" ? (
							<OrderDetailPriceSection
								promotion_detail={order.promotion_detail}
							/>
						) : null} */}
									{t("Giảm giá Coupons")} :
								</div>
							</Col>
							<Col flex={"170px"} className="text-right">
								<Form.Item noStyle>
									<MoneyInput
										readOnly
										disabled={!allowEditInput}
										value={order.price_discount_coupon}
										style={{ width: "100%" }}
										onChange={(v) => setPriceDiscount(v !== null ? +v : 0)}
									/>
								</Form.Item>
							</Col>
						</Row>
					</>
				</Col>
			</Row>
			{/* <Divider></Divider> */}

			{/* <Row gutter={16} className="mb-2">
				<Col flex={"auto"} className="text-right">
					<div className="mt-1">
						{t("Tổng giảm giá (coupon & KM)")} :
					</div>
				</Col>
				<Col flex={"170px"} className="text-right">
					<Form.Item name="price_discount" noStyle>
						<MoneyInput
							disabled={!allowEditInput}
							style={{ width: "100%" }}
							onChange={(v) => setPriceDiscount(v !== null ? +v : 0)}
						/>
					</Form.Item>
				</Col>
			</Row> */}

			<Row gutter={16} className="mb-2">
				<Col flex={"auto"} className="text-right">
					<div className="mt-1">{t("order:price_shipping")} :</div>
				</Col>
				<Col flex={"170px"} className="text-right">
					<Form.Item name="price_shipping" noStyle>
						<MoneyInput
							disabled={!allowEditInput}
							addonBefore={<IconTruck size={16} />}
							style={{ width: "100%" }}
							onChange={(v) => setPriceShipping(v ?? 0)}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16} className="mb-2">
				<Col flex={"auto"} className="text-right">
					<div className="mt-1">{t("Phí VAT")} :</div>
				</Col>
				<Col flex={"170px"} className="text-right">
					<TextMoney money={(model.price_tax / 100) * model.price_sell} />
				</Col>
			</Row>
			<Row gutter={16} className="mb-2">
				<Col flex={"auto"} className="text-right">
					<div className="mt-1">{t("Khách đã trả")} :</div>
				</Col>
				<Col flex={"170px"} className="text-right">
					<TextMoney money={model.deposit} />
				</Col>
			</Row>

			<Row gutter={16} className="mb-2">
				<Col flex={"auto"} className="text-right">
					<div className="mt-1  text-red-400">
						{t("Công nợ của khách hàng")} :
					</div>
				</Col>
				<Col flex={"170px"} className="text-right text-red-400">
					<TextMoney money={model.debt} />
				</Col>
			</Row>

			<Row gutter={16} className="text-xl py-3 px-1 bg-green-50">
				<Col flex={"auto"} className="text-right text-xl">
					{t("Khách cần trả:")} :
				</Col>
				<Col flex={"170px"} className="text-right font-bold text-xl">
					<TextMoney
						money={
							model.total_payment - model.deposit > 0
								? model.total_payment - model.deposit
								: 0
						}
					/>
					<Form.Item hidden name="total_payment">
						<Input type={"hidden"} />
					</Form.Item>
				</Col>
			</Row>
		</div>
	);
};

export default OrderDetailPriceSummary;
