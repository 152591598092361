import ActionApi from "common/api/ActionApi";
import ActionCollection from "common/collections/ActionCollection";
import ActionModel from "common/models/ActionModel";
import { ActionJsonAddEdit, FilterAction } from "common/types/Action";
import type { GetListProps } from "common/types/Filter";
import BaseRepository from "./BaseRepository";

class ActionRepository extends BaseRepository<ActionApi> {
	_api: ActionApi | null;
	constructor() {
		super();
		this._api = new ActionApi(true);
	}

	static getDefaultFilters(): FilterAction {
		return {
			page: 1,
			limit: 20,
			sort_by: "id",
			sort_type: "DESC",
			sortby: "",
			sorttype: "",
			status: -1,
			keyword: "",
			id: -1,
			office_id: -1,
			creator_id: -1,
			employee_id: -1,
			department_id: -1,
			type: -1,
			date_created: -1,
			action: -1,
		};
	}

	async getItems(
		props: GetListProps<FilterAction>
	): Promise<ActionCollection> {
		return this._api
			? await this._api.getList(props.filters)
			: new ActionCollection();
	}

	async saveRemote(data: ActionJsonAddEdit): Promise<ActionModel> {
		if (this._api) {
			if (data.id > 0) {
				return await this._api.add(data);
			} else {
				// return await this._api.edit(data);
				return await this._api.add(data);
			}
		} else {
			return new ActionModel(ActionModel.getDefaultData());
		}
	}
}

export default ActionRepository;
