import { Flex, Table, Tag, Typography } from "antd";
import ProductPublicModel from "common/models/ProductPublicModel";
import StoreModel from "common/models/StoreModel";
import ProductRepository from "common/repositories/ProductRepository";
import TextStore from "components/TextStore";
import { useCallback, useEffect, useState } from "react";
import type { Sample } from "common/types/Product";
import { TableColumnsType } from "antd";
type Props = {
	product: ProductPublicModel;
};
export default function TableSampleProduct({ product }: Props) {
	const [dataSource, setDataSource] = useState<Sample[]>([]);
	const [loading, setLoading] = useState(false);

	const columns: TableColumnsType<Sample> = [
		{
			title: "Chi nhánh",
			dataIndex: "name",
			key: "name",
			render: (name: string) => {
				return <Typography.Text>{name}</Typography.Text>;
			},
		},
		// {
		//   title: 'actual_quantity',
		//   dataIndex: 'actual_quantity',
		//   key: 'actual_quantity',
		// },
		{
			title: "Tình trạng",
			dataIndex: "onhand",
			key: "onhand",

			render: (onhand) => {
				const label = onhand
					? { label: "Có mãu thử", color: "green" }
					: { label: "Không có mẫu thử", color: "gray" };
				return <Tag color={label.color}>{label.label}</Tag>;
			},
		},
	];

	//////////////////////////////////////
	const getDataSample = useCallback(async () => {
		if (product.id <= 0) return;
		setLoading(true);
		const res = await new ProductRepository().getDataSampleProduct(product.id);

		if (res.length > 0) {
			setDataSource(res);
		}

		setLoading(false);
	}, [product.id]);

	//////////////////////////////////////
	useEffect(() => {
		getDataSample();
	}, [getDataSample]);

	//////////////////////////////////////

	return (
		<Table
			className="h-full"
			rootClassName="h-full"
			style={{ margin: 0, marginBlock: 0 }}
			loading={loading}
			dataSource={dataSource}
			columns={columns}
			pagination={false}
			size="middle"
			// summary={(pageData) => {
			//   let totalQuan = 0;
			//   let totalReserved = 0;
			//   pageData.forEach(({ quantity, reserved }) => {
			//     totalQuan += quantity;
			//     totalReserved += reserved;
			//   });
			//   return (
			//     <>
			//       <Table.Summary.Row>
			//         <Table.Summary.Cell index={0}>
			//           <b>Tổng</b>
			//         </Table.Summary.Cell>
			//         <Table.Summary.Cell index={1}>
			//           <b>{totalReserved}</b>
			//         </Table.Summary.Cell>
			//         <Table.Summary.Cell index={2}>
			//           <b>{totalQuan}</b>
			//         </Table.Summary.Cell>
			//       </Table.Summary.Row>
			//     </>
			//   );
			// }}
		/>
	);
}
