import { Tooltip } from 'antd';
import CashflowReceiptModel from 'common/models/CashflowReceiptModel';
import TextEmployeeId from 'components/TextEmployeeId';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const CashflowReceiptItemTargetEmployee = ({
  model,
}: {
  model: CashflowReceiptModel;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Tooltip
        title={t("cashflowreceipt:TARGET_EMPLOYEE")}
        mouseEnterDelay={0.4}
      >
        <span className="px-1 py-0.5 text-xs font-semibold mr-1 bg-yellow-500 rounded text-white">
          {t("cashflowreceipt:target.employee_short")}
        </span>
      </Tooltip>
      {model.target_id > 0 ? (
        <Link
          to={"/employee/detail/id/" + model.target_id}
          target="_blank"
          className=""
        >
          <TextEmployeeId id={model.target_id} isSimple />
        </Link>
      ) : null}
    </>
  );
};

export default CashflowReceiptItemTargetEmployee;
