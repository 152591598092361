import ContactList from "features/contact/list/ContactList";
import usePathParams from "hooks/usePathParams";

const ContactPage = (): JSX.Element => {
  const [pathParams] = usePathParams();

  let com = <ContactList />;
  switch (pathParams.action) {
    default:
      com = <ContactList />;
  }

  return com;
};

export default ContactPage;
