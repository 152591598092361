function convertNumberToPriceVND(priceNumber:  number) {
  let output = "";

  //languagecode-countrycode:
  //languagecode: full list @ https://www.w3schools.com/tags/ref_language_codes.asp
  //countrycode: full list @ https://www.w3schools.com/tags/ref_country_codes.asp
  const locale = "vi-VN";

  //full currencylist at https://www.currency-iso.org/en/home/tables/table-a1.html
  const currency = "VND";

  const formatter = new Intl.NumberFormat(locale, {
    currency,
  });

  //formating data
  output = formatter.format(Math.round(priceNumber)).replace(/\./g, ",");

  return output + "₫";
}


function convertTemplateToHtmlString(template: string, data: any): string {

  const getValueFromPath = (obj: Record<string, any>, path: string): any => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  const convertValue = (value: any, pipe: string): any => {
    switch (pipe) {
      case 'currency':
        return convertNumberToPriceVND(value);
      case 'uppercase':
        return String(value).toUpperCase();
      case 'lowercase':
        return String(value).toLowerCase();
      default:
        return value;
    }
  };

  const replaceSingleKey = (template: string, data: any) => {
    return template.replace(/{{\s*([^}\s|]+)\s*(\|\s*([^}\s]+)\s*)?}}/g, (_, key, __, pipe) => {
      const keys = key.split(".");
      let value = data;

      keys.forEach((k: string) => {
        if (value && typeof value === "object" && k in value) {
          value = value[k];
        } else {
          value = "";
        }
      });

      if (pipe) {
        value = convertValue(value, pipe.trim());
      }

      return value || "";
    });
  };

  const ifReplace = (template: string, data: any) => {
    return template.replace(
      /{%\s*if\s+([^}\s]+)\s*%}(.*?)({%\s*endif\s*%})/gs,
      (_, condition, content, end) => {
        const conditionValue = getValueFromPath(data, condition);
        return conditionValue ? content.trim() : "";
      }
    );
  };

  const forReplace = (template: string, data: any) => {
    return template.replace(
      /{%\s*for\s+([^}\s]+)\s+in\s+([^}\s]+)\s*%}(.*?)({%\s*endfor\s*%})/gs,
      (_, item, collection, content, end) => {
        const dataNew = getValueFromPath(data,collection)
        if (!Array.isArray(dataNew)) return "";
        return dataNew
          .map((obj: any) => {
            const newData = { [item]: obj };
            return replaceSingleKey(content.trim(), newData);
          })
          .join("");
      }
    );
  };

  let result = template;

  result = ifReplace(result, data);
  result = forReplace(result, data);
  result = replaceSingleKey(result, data);

  return result;
}

function concatStrings(a: string | string[], b: string | string[]): string[] {
  const arrA = Array.isArray(a) ? a : a.length > 0 ? [a] : [];
  const arrB = Array.isArray(b) ? b : b.length > 0 ? [b] : [];
  return arrA.concat(arrB);
}

export { convertNumberToPriceVND  , convertTemplateToHtmlString, concatStrings};
