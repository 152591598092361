import {
	Button,
	Col,
	Form,
	FormProps,
	Input,
	Modal,
	notification,
	Popconfirm,
	Row,
	Table,
	Tooltip,
} from "antd";
import ProductInventoryCheckingDetailCollection from "common/collections/ProductInventoryCheckingDetailCollection";
import ProductInventoryCheckingDetailModel from "common/models/ProductInventoryCheckingDetailModel";
import ProductInventoryCheckingModel from "common/models/ProductInventoryCheckingModel";
import ProductInventoryCheckingDetailRepository from "common/repositories/ProductInventoryCheckingDetailRepository";
import { TableColumnsType } from "common/types/Table";
import QuantityInput from "components/QuantityInput";
import TableDelete from "components/table/TableDelete";
import ProductWarehouseSearch from "features/product/ProductWarehouseSearch";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconHistory, IconX } from "@tabler/icons-react";
import ProductInventoryCheckingDetailLogModal from "./ProductInventoryCheckingDetailLogModal";
import { ProductJsonPublic } from "common/types/Product";
import { ColumnType } from "antd/es/table";
import { EditFilled } from "@ant-design/icons";
import {
	ProductInventoryCheckingDetailJsonAdd,
	ProductInventoryCheckingDetailJsonEdit,
} from "common/types/ProductInventoryCheckingDetail";
import { useWatch } from "antd/es/form/Form";
import ProductRepository from "common/repositories/ProductRepository";
import { debounce } from "lodash";
import TextArea from "antd/es/input/TextArea";

const ProductInventoryCheckingDetailList = ({
	detailItems,
	productIventoryCheckingModel,
	setDetailItems,
	defaultWarehouseId,
	isAllowEdit,
	isFull = true,
	isDisable,
	refetch,
}: {
	detailItems: ProductInventoryCheckingDetailModel[];
	productIventoryCheckingModel: ProductInventoryCheckingModel;
	setDetailItems: (items: ProductInventoryCheckingDetailModel[]) => void;
	defaultWarehouseId: number;
	isAllowEdit: boolean;
	isDisable?: boolean;
	isFull?: boolean;
	refetch?: () => void;
}) => {
	const { t } = useTranslation();
	const [modalVisible, setModalVisible] = useState(false);
	const [isOpenEdit, setIsOpenEdit] = useState(false);
	const [detail, setDetail] = useState<ProductInventoryCheckingDetailModel>(
		new ProductInventoryCheckingDetailModel(
			ProductInventoryCheckingDetailModel.getDefaultData()
		)
	);

	const [products, setProducts] = useState<ProductJsonPublic[]>([]);

	const [itemNews, setItemNews] = useState<
		ProductInventoryCheckingDetailModel[]
	>([]);

	const [
		productIventoryCheckingDetailModel,
		setProductIventoryCheckingDetailModel,
	] = useState<ProductInventoryCheckingDetailModel>(
		new ProductInventoryCheckingDetailModel(
			ProductInventoryCheckingDetailModel.getDefaultData()
		)
	);
	const confirmingDeleteSku = useRef("");
	const [loading, setLoading] = useState(false);
	const isDraft = isAllowEdit;

	async function fetchProducts(ids: string, warehouse_id: number) {
		const res = await new ProductRepository().getItemsPublic({
			filters: {
				...ProductRepository.getDefaultFilters(),
				list_product_id: ids,
			},
		});
		if (!res.hasError()) {
			setProducts(res.toJson().items);
		}
	}

	const fetchProductInventoryCheckingDetail = useCallback(
		async (id: number) => {
			setLoading(true);
			//fetch all
			let foundItem: ProductInventoryCheckingDetailModel[] = [];
			let page = 1;
			let limit = 50;
			let fetchedCollection = new ProductInventoryCheckingDetailCollection();
			do {
				fetchedCollection =
					await new ProductInventoryCheckingDetailRepository().getItems({
						filters: {
							product_inventory_checking_id: id,
							page,
							limit,
							sortby: "id",
							sorttype: "ASC",
							keyword: "",
							status: 0,
						},
					});

				if (!fetchedCollection.hasError()) {
					fetchedCollection.items.forEach(
						(item: ProductInventoryCheckingDetailModel) => {
							foundItem.push(item);
						}
					);
				}
				page++;
			} while (
				!fetchedCollection.hasError() &&
				fetchedCollection.items.length === limit
			);

			setLoading(false);
			setDetailItems([...foundItem]);
			// setItemNews([])
		},
		[setDetailItems]
	);

	//debounce

	const fetchProductsDebounce = useCallback(debounce(fetchProducts, 300), []);

	useEffect(() => {
		if (productIventoryCheckingModel.id > 0) {
			fetchProductInventoryCheckingDetail(productIventoryCheckingModel.id);
		}
	}, [
		productIventoryCheckingModel.id,
		productIventoryCheckingModel.data_detail,
		fetchProductInventoryCheckingDetail,
	]);

	useEffect(() => {
		const ids = detailItems.map((item) => item.product_id).join(",");
		if (ids) {
			fetchProductsDebounce(ids, defaultWarehouseId);
		}
	}, [detailItems, defaultWarehouseId]);

	const doRemoveRowWithSku = useCallback(
		(sku: string) => {
			setDetailItems(detailItems.filter((item) => item.product_sku !== sku));
			confirmingDeleteSku.current = "";
		},
		[detailItems, setDetailItems]
	);
	const onSelectVariant = useCallback(
		(product_variant: ProductJsonPublic) => {
			const foundItem = detailItems.find(
				(r) => r.product_id === product_variant.id
			);

			if (foundItem) {
				notification.warning({ message: "Sản phẩm đã có trong danh sách" });
			} else {
				const system_quan = product_variant.warehouse_quantities.find(
					(item) => item.warehouse_id === defaultWarehouseId
				);

				const itemNew: ProductInventoryCheckingDetailModel =
					new ProductInventoryCheckingDetailModel({
						...ProductInventoryCheckingDetailModel.getDefaultData(),
						product_id: product_variant.id,
						product_sku: product_variant.sku,
						system_quantity: system_quan ? system_quan.quantity : 0,
						quantity: 0,
						quality: 100,
					});

				setDetailItems([...detailItems, itemNew]);
				setItemNews((pre) => [...pre, itemNew]);
			}
		},
		[defaultWarehouseId, detailItems, setDetailItems]
	);

	function onUpdateQuantity(
		item: ProductInventoryCheckingDetailModel,
		quantity: number
	) {
		// Tạo một bản sao của mảng hiện tại
		const itemsPrev = [...detailItems];

		// Tìm index của item cần cập nhật
		const index = itemsPrev.findIndex((i) => i.product_id === item.product_id);
		if (index !== -1) {
			// Cập nhật số lượng của item
			itemsPrev[index].quantity = quantity;
		}

		// Cập nhật lại mảng detailItems
		setDetailItems(itemsPrev);
	}

	function onUpdateQuality(
		item: ProductInventoryCheckingDetailModel,
		quality: number
	) {
		// Tạo một bản sao của mảng hiện tại
		const itemsPrev = [...detailItems];

		// Tìm index của item cần cập nhật
		const index = itemsPrev.findIndex((i) => i.product_id === item.product_id);

		if (index !== -1) {
			// Cập nhật chất lượng của item
			itemsPrev[index].quality = quality;
		}

		// Cập nhật lại mảng detailItems
		setDetailItems(itemsPrev);
	}

	function onUpdateNote(
		item: ProductInventoryCheckingDetailModel,
		note: string
	) {
		// Tạo một bản sao của mảng hiện tại
		const itemsPrev = [...detailItems];

		// Tìm index của item cần cập nhật
		const index = itemsPrev.findIndex((i) => i.product_id === item.product_id);

		if (index !== -1) {
			// Cập nhật chất lượng của item
			itemsPrev[index].note = note;
		}

		// Cập nhật lại mảng detailItems
		setDetailItems(itemsPrev);
	}

	function handleEditDetail(record: ProductInventoryCheckingDetailModel) {
		setIsOpenEdit(true);
		setDetail(record);
	}

	async function onSubmitEditDetail(
		record: ProductInventoryCheckingDetailJsonEdit
	) {
		const res =
			await new ProductInventoryCheckingDetailRepository().saveRemoteEdit(
				record
			);
		if (res.hasError()) {
			notification.success({ message: res.error.errors[0] });
		} else {
			const pre = [...detailItems];
			const itemIndex = pre.findIndex((i) => i.id === res.id);
			pre[itemIndex] = res;
			setDetailItems(pre);
			notification.success({ message: "Chỉnh sửa thành công" });
		}
	}

	const system_quantity_collum: ColumnType<ProductInventoryCheckingDetailModel>[] =
		[
			{
				title: t("productinventorychecking:form.system_quantity"),
				dataIndex: "system_quantity",
				width: 150,
				align: "center",
			},
		];
	const deviant_quantity_collum: ColumnType<ProductInventoryCheckingDetailModel>[] =
		[
			{
				title: t("productinventorychecking:form.deviant_quantity"),
				dataIndex: "deviant_quantity",

				width: 100,

				align: "center",
				render: (_: any, record: ProductInventoryCheckingDetailModel) => {
					const deviant_quantity = record.quantity - record.system_quantity;

					return (
						<>
							{/* {deviant_quantity > 0 ? "" : "-"} */}
							{deviant_quantity}
						</>
					);
				},
			},
		];

	const columns: TableColumnsType<ProductInventoryCheckingDetailModel> = [
		{
			title: t("common:table.index"),
			dataIndex: "id",
			align: "center",
			render: (_, __, index: number) => {
				return <>{index + 1}</>;
			},
			width: 50,
		},

		{
			title: t("productinventorychecking:form.product_info"),
			dataIndex: "product_id",
			render: (product_variant_id: number, record) => {
				const findItem = products.find((pro) => pro.id === product_variant_id);
				if (findItem) {
					return (
						<div className=" flex gap-2 items-center">
							<img src={findItem.images[0]?.url} className=" w-10 h-10" />
							<div>
								<p>{findItem.full_name}</p>
								<p className="mb-0">{findItem.sku}</p>
							</div>
						</div>
					);
				}

				return (
					<p className="mb-0">{record.product_sku}</p>
				);
			},
		},

		...(isFull ? system_quantity_collum : []),

		{
			title: t("productinventorychecking:form.quantity"),
			dataIndex: "quantity",
			width: 140,
			align: "center",
			render: (_: any, record: ProductInventoryCheckingDetailModel) => {
				const isNewAdd = itemNews.find(
					(i) => i.product_id === record.product_id
				);
				return (
					<>
						{isAllowEdit && isNewAdd ? (
							<QuantityInput
								disabled={isDisable && record.id !== 0}
								key={record.id}
								value={record.quantity}
								onChange={(quantity: number | null) => {
									onUpdateQuantity(record, quantity ?? 1);
								}}
							/>
						) : (
							<Input
								style={{ width: "100%" }}
								size="large"
								value={record.quantity}
								className="text-center"
								disabled
							/>
						)}
					</>
				);
			},
		},

		{
			title: t("Chất lượng (%)"),
			dataIndex: "quality",
			width: 140,
			align: "center",
			render: (_: any, record: ProductInventoryCheckingDetailModel) => {
				const isNewAdd = itemNews.find(
					(i) => i.product_id === record.product_id
				);
				return (
					<>
						{isAllowEdit && isNewAdd ? (
							<QuantityInput
								key={record.id}
								value={record.quality}
								disabled={isDisable && record.id !== 0}
								min={0}
								max={100}
								onChange={(quantity: number | null) => {
									if (quantity && quantity <= 100) {
										onUpdateQuality(record, quantity ?? 1);
									}
								}}
							/>
						) : (
							<Input
								style={{ width: "100%" }}
								size="large"
								value={record.quality}
								className="text-center"
								disabled
							/>
						)}
					</>
				);
			},
		},

		{
			title: t("Ghi chú"),
			dataIndex: "note",
			width: 140,
			align: "center",
			render: (_: any, record: ProductInventoryCheckingDetailModel) => {
				const isNewAdd = itemNews.find(
					(i) => i.product_id === record.product_id
				);
				return (
					<>
						<Input
							disabled={
								!isAllowEdit || (isDisable && record.id !== 0) || !isNewAdd
							}
							style={{ width: "100%" }}
							size="large"
							value={record.note}
							onChange={(e) => {
								onUpdateNote(record, e.target.value);
							}}
							className="text-center"
						/>
					</>
				);
			},
		},

		...(isFull ? deviant_quantity_collum : []),

		...(isDraft
			? [
					{
						title: " ",
						dataIndex: "operation",
						width: 50,
						render: (_: any, record: ProductInventoryCheckingDetailModel) => {
							return (
								<>
									<div className="flex items-center content-between">
										{record.id === 0 ? (
											<Popconfirm
												disabled={!isAllowEdit}
												title={t("common:table.confirm_delete_title")}
												placement="topRight"
												onConfirm={() => {
													doRemoveRowWithSku(record.product_sku);
												}}
												onCancel={() => (confirmingDeleteSku.current = "")}
												okText={t("common:table.confirm_ok")}
												cancelText={t("common:table.confirm_cancel")}>
												<Button
													type="link"
													size="small"
													className="text-red-200 hover:text-red-500"
													onClick={() =>
														(confirmingDeleteSku.current = record.product_sku)
													}>
													<IconX />
												</Button>
											</Popconfirm>
										) : (
											<div className=" flex items-center gap-1">
												<Button onClick={() => handleEditDetail(record)}>
													<EditFilled />
												</Button>
												<TableDelete
													error_translate_prefix="productinventorychecking:form.error"
													onDeleteCallback={(id) => {
														setDetailItems(
															detailItems.filter((item) => item.id !== id)
														);
													}}
													repository={
														new ProductInventoryCheckingDetailRepository()
													}
													id={record.id}
												/>
											</div>
										)}

										{record.changelog.length > 0 ? (
											<Tooltip
												title={t("productinventorychecking:tooltip_changelog")}
												placement={"top"}>
												<Button
													type="link"
													size="small"
													className="text-gray-500 hover:text-gray-700"
													onClick={() => {
														setModalVisible(true);
														setProductIventoryCheckingDetailModel(record);
													}}>
													<IconHistory size={18} />
												</Button>
											</Tooltip>
										) : null}
									</div>
								</>
							);
						},
					},
			  ]
			: [
					{
						title: " ",
						dataIndex: "operation",
						width: 50,
						render: (_: any, record: ProductInventoryCheckingDetailModel) => {
							return (
								<>
									<div className="flex items-center content-between">
										{record.changelog.length > 0 ? (
											<Tooltip
												title={t("productinventorychecking:tooltip_changelog")}
												placement={"top"}>
												<Button
													type="link"
													size="small"
													className="text-gray-500 hover:text-gray-700"
													onClick={() => {
														setModalVisible(true);
														setProductIventoryCheckingDetailModel(record);
													}}>
													<IconHistory size={18} />
												</Button>
											</Tooltip>
										) : null}
									</div>
								</>
							);
						},
					},
			  ]),
	];

	return (
		<div>
			<div className="mb-2">
				<ProductWarehouseSearch
					filter={{
						warehouse_id: defaultWarehouseId,
					}}
					placeholder={
						!defaultWarehouseId
							? "Vui lòng chọn kho"
							: "Nhập SKU , Tên sản phẩm..."
					}
					disabled={!isAllowEdit || !defaultWarehouseId}
					size="large"
					onSelect={(product_variant) => {
						onSelectVariant(product_variant);
					}}
				/>
			</div>

			<Table
				scroll={{ x: "max-content" }}
				size="small"
				className="w-full table-dashboard"
				dataSource={detailItems}
				columns={columns}
				rowKey={"product_id"}
				bordered
				loading={loading}
				pagination={{ hideOnSinglePage: true }}
				footer={() => null}
				summary={() => {
					const system_quantity_total = detailItems.reduce(
						(accumulator, current) =>
							accumulator + Number(current["system_quantity"]),
						0
					);

					const quantity_total = detailItems.reduce(
						(accumulator, current) => accumulator + Number(current["quantity"]),
						0
					);

					return (
						<>
							{false ? (
								<>
									<Table.Summary fixed>
										<Table.Summary.Row className="text-center">
											<Table.Summary.Cell
												colSpan={1}
												index={0}
												className="font-bold">
												{t("producttransmit:form.quantity_total")}
											</Table.Summary.Cell>
											<Table.Summary.Cell index={1} className="font-bold">
												{/* {system_quantity_total} */}
											</Table.Summary.Cell>
											<Table.Summary.Cell index={2} className="font-bold">
												{system_quantity_total}
											</Table.Summary.Cell>
											<Table.Summary.Cell index={3} className="font-bold">
												{quantity_total}
											</Table.Summary.Cell>
											<Table.Summary.Cell index={4} className="font-bold">
												{quantity_total - system_quantity_total}
											</Table.Summary.Cell>
											<Table.Summary.Cell index={5}></Table.Summary.Cell>
										</Table.Summary.Row>
									</Table.Summary>
								</>
							) : null}
						</>
					);
				}}
			/>
			{modalVisible ? (
				<ProductInventoryCheckingDetailLogModal
					open={modalVisible}
					setOpen={(hidden: boolean) => {
						setModalVisible(hidden);
						setProductIventoryCheckingDetailModel(
							new ProductInventoryCheckingDetailModel(
								ProductInventoryCheckingDetailModel.getDefaultData()
							)
						);
					}}
					productIventoryCheckingDetailModel={
						productIventoryCheckingDetailModel
					}
				/>
			) : null}

			<Modal
				open={isOpenEdit}
				onCancel={() => setIsOpenEdit(false)}
				title="Chỉnh sửa"
				footer={null}
				destroyOnClose>
				<ProductInventoryCheckingDetailForm
					detail={detail}
					onSubmit={onSubmitEditDetail}
				/>
			</Modal>
		</div>
	);
};

export default ProductInventoryCheckingDetailList;

function ProductInventoryCheckingDetailForm({
	detail,
	onSubmit,
}: {
	detail: ProductInventoryCheckingDetailModel;
	onSubmit: (data: ProductInventoryCheckingDetailJsonEdit) => void;
}) {
	const [form] = Form.useForm();

	const onFinish: FormProps<ProductInventoryCheckingDetailJsonEdit>["onFinish"] =
		(values) => {
			onSubmit({ ...detail, ...values });
		};
	return (
		<Form
			form={form}
			name="basic"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 16 }}
			style={{ maxWidth: 600 }}
			initialValues={detail}
			onFinish={onFinish}
			autoComplete="off">
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item
						name="quantity"
						label="Kiểm thực tế"
						labelCol={{ span: 24 }}>
						<QuantityInput
							value={detail.quantity}
							onChange={(quantity: number | null) => {
								form.setFieldValue("quantity", quantity || 1);
							}}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						name="quality"
						label="Chất lượng (%)"
						labelCol={{ span: 24 }}>
						<QuantityInput
							min={0}
							max={100}
							value={detail.quality}
							onChange={(quantity: number | null) => {
								form.setFieldValue("quality", quantity || 1);
							}}
						/>
					</Form.Item>
				</Col>
				<Col span={24}>
					<Form.Item name="note" label="Ghi chú" labelCol={{ span: 24 }}>
						<TextArea rows={3} className="w-full" />
					</Form.Item>
				</Col>
			</Row>

			<Form.Item className="w-full">
				<Button type="primary" htmlType="submit">
					Xác nhận
				</Button>
			</Form.Item>
		</Form>
	);
}
