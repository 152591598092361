import Order from "common/constants/Order";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const OrderAccountantListHeader = ({ type }: { type: number }) => {
	const { t } = useTranslation();

	const [params] = useSearchParams();
	const hasFilterInvoice = type === Order.TYPE_INVOICE;

	return (
		<PageHeader
			heading={
				!!hasFilterInvoice
					? t("order:accountant.invoice_heading_list")
					: t("order:accountant.heading_list")
			}
			nav={[]}
			siteMenuOpenKey="sale"
			siteMenuSelectedKey={"/invoice_accountant"}>
			<PageHeaderButton type="primary" link="/pos" target="_blank">
				{t("order:pos_button")}
			</PageHeaderButton>
		</PageHeader>
	);
};

export default OrderAccountantListHeader;
