import BaseApi from "./BaseApi";
import AxiosClient from "./AxiosClient";
import type { GetListProps } from "common/types/Filter";
import Helper from "common/utils/helper";

import EmployeeModel from "common/models/EmployeeModel";
import EmployeeCollection from "common/collections/EmployeeCollection";
import type { FullData } from "common/types/FullData";
import type {
	FilterEmployee,
	EmployeeJson,
	EmployeeJsonAddEdit,
	EmployeeCollectionJson,
	PasswordUpdateJson,
	BankInfoEmployee,
	EmployeeTypeWork,
	EmployeeFetching,
	EmployeeProfileJsonEdit,
} from "common/types/Employee";
import EmployeeCollectionIdFetching from "common/collections/EmployeeCollectionIdFetching";

const SERVICE_URL = "/employees";

class EmployeeApi extends BaseApi {
	async getList(
		props: GetListProps<FilterEmployee>
	): Promise<EmployeeCollection> {
		let collection = new EmployeeCollection();

		try {
			let filters = props.filters;

			let queryData = {
				params: {
					page: filters.page,
					limit: filters.limit,
					sort_by: filters.sortby,
					sort_type: filters.sorttype,
					ignore_group: 1,
					is_not_deleted: 1,
					keyword: filters.keyword ? filters.keyword : null,
					status: Number(filters.status) >= 0 ? filters.status : null,
					office_id: filters.office_id > 0 ? filters.office_id : null,
					department_id:
						filters.department_id > 0 ? filters.department_id : null,
					type: filters.type >= 0 ? filters.type : null,
					keyword_name: filters.keyword_name ? filters.keyword_name : null,
				},
			};

			const response = await AxiosClient().get<any>(SERVICE_URL, queryData);

			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}

		return collection;
	}

	async getIdsList(
		props: EmployeeFetching
	): Promise<EmployeeCollectionIdFetching> {
		let collection = new EmployeeCollectionIdFetching();

		try {
			let queryData = {
				params: {
					ids: props.ids.join(","),
					object_type: props.object_type,
				},
			};

			const response = await this.axios.get<any>(
				SERVICE_URL + "/idlist",
				queryData
			);

			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}

		return collection;
	}

	async getDetail(id: number): Promise<EmployeeModel> {
		let item = new EmployeeModel(EmployeeModel.getDefaultData());

		try {
			const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
			if (response.hasOwnProperty("data")) {
				item = new EmployeeModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async getListTypeOfWork() {
		// let collection = new
	}

	async delete(id: number): Promise<string[]> {
		let resultErrors = [];

		try {
			const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
			if (response.status !== 204) {
				resultErrors.push("error_delete_response_not_204");
			}
		} catch (error) {
			resultErrors = BaseApi.handleError(error).errors;
		}

		return resultErrors;
	}

	async add(data: EmployeeJsonAddEdit): Promise<EmployeeModel> {
		let item = new EmployeeModel(EmployeeModel.getDefaultData());

		let dataProps: any = { ...data };
		delete dataProps.id;
		const convertProps = Helper.convertParams(dataProps);

		try {
			let postData = {
				...convertProps,
			};

			//For create item
			const response = await AxiosClient().post<any>(SERVICE_URL, postData);
			if (response.hasOwnProperty("data")) {
				item = new EmployeeModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async edit(data: EmployeeJsonAddEdit): Promise<EmployeeModel> {
		let item = new EmployeeModel(EmployeeModel.getDefaultData());

		let dataProps: any = { ...data };
		delete dataProps.id;
		const convertProps = Helper.convertParams(dataProps);

		try {
			let postData = {
				...convertProps,
			};

			//For create item
			const response = await AxiosClient().put<any>(
				SERVICE_URL + "/" + data.id,
				postData
			);
			if (response.hasOwnProperty("data")) {
				item = new EmployeeModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async setPassword(data: PasswordUpdateJson): Promise<string[]> {
		let errors: string[] = [];

		try {
			let postData = {
				password: data.password,
			};

			//For create item
			const response = await AxiosClient().put<any>(
				SERVICE_URL + "/setpass/" + data.id,
				postData
			);
			if (!response.hasOwnProperty("data")) {
				errors.push("error_data_not_found");
			}
		} catch (error) {
			errors = BaseApi.handleError(error).errors;
		}

		return errors;
	}

	///////////////////////////////////////////////////////////////
	// FULL DATA fetcher
	async getFullData({
		companyId,
		version,
	}: FullData): Promise<EmployeeCollection> {
		return this.getFullDataFetcher<
			EmployeeJson,
			EmployeeModel,
			EmployeeCollectionJson,
			EmployeeCollection
		>({
			collection: new EmployeeCollection(),
			companyId,
			version,
			url: SERVICE_URL + "/fulldata",
		});
	}

	async getBank() {
		let items: BankInfoEmployee[] = [];

		try {
			const response = await AxiosClient().get<any>(SERVICE_URL + "/getbank");
			if (response.hasOwnProperty("data")) {
				items = response.data.items;
			}
		} catch (error) {
			// item.withError(BaseApi.handleError(error));
		}

		return items;
	}

	async getTypeWork() {
		let items: EmployeeTypeWork[] = [];

		try {
			const response = await AxiosClient().get<any>(
				SERVICE_URL + "/gettypeworks"
			);
			if (response.hasOwnProperty("data")) {
				items = response.data;
			}
		} catch (error) {
			// item.withError(BaseApi.handleError(error));
		}

		return items;
	}

	async getFullDetail(id: number): Promise<EmployeeModel> {
		let item = new EmployeeModel(EmployeeModel.getDefaultData());

		try {
			const response = await AxiosClient().get<any>(
				SERVICE_URL + "/getfulldetail/" + id
			);
			if (response.hasOwnProperty("data")) {
				item = new EmployeeModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async getFullDetail2(id: number): Promise<EmployeeModel> {
		let item = new EmployeeModel(EmployeeModel.getDefaultData());

		try {
			const response = await AxiosClient().get<any>(
				SERVICE_URL + "/" + id + "/user"
			);
			if (response.hasOwnProperty("data")) {
				item = new EmployeeModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async updateInfoEmployee(
		data: EmployeeProfileJsonEdit
	): Promise<EmployeeModel> {
		let item = new EmployeeModel(EmployeeModel.getDefaultData());
		let dataProps: any = { ...data };
		delete dataProps.id;
		try {
			let postData = {
				...dataProps,
			};

			//For create item
			const response = await AxiosClient().put<any>(
				SERVICE_URL + "/editemployee/" + data.id,
				postData
			);
			if (response.hasOwnProperty("data")) {
				item = new EmployeeModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async updateAvatar(id: number): Promise<EmployeeModel> {
		let item = new EmployeeModel(EmployeeModel.getDefaultData());

		try {
			let postData = {
				avatar_id: id,
			};

			//For create item
			const response = await AxiosClient().put<any>(
				SERVICE_URL + "/avatar",
				postData
			);
			if (response.hasOwnProperty("data")) {
				item = new EmployeeModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	/////////////////////////////////////////////
	async getListBirthday() {
		let collection = new EmployeeCollection();
		try {
			const response = await AxiosClient().get<any>(
				SERVICE_URL + "/getbirthdays"
			);
			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}

		return collection;
	}
}

export default EmployeeApi;
