import { Button, Col, Form, Row } from "antd";
import StoreWarehouseModel from "common/models/StoreWarehouseModel";
import WarehouseModel from "common/models/WarehouseModel";
import StoreWarehouseRepository from "common/repositories/StoreWarehouseRepository";
import WarehouseRepository from "common/repositories/WarehouseRepository";
import FormSection from "components/form/FormSection";
import { t } from "i18next";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import SelectWarehouse from "../SelectWarehouse";
import StoreWarehouseList from "../list/StoreWarehouseList";
import StoreModel from "common/models/StoreModel";

interface IStoreFormLinkWarehouseProps {
  model: StoreModel
}

const StoreFormLinkWarehouse = forwardRef(({ model }: IStoreFormLinkWarehouseProps, ref) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [warehouseListIds, setWarehouseListIds] = useState<number[]>([]);
  const [valueSelected, setValueSelected] = useState<number>(0);
  const [defaultValues, setDefaultValues] = useState<number[]>([]);

  const selectWarehouseRef = useRef<any>(null);
  const [warehouseDataSource, setWarehouseDataSource] = useState<WarehouseModel[]>([]);
  const [storeWarehouseSource, setStoreWarehouseSource] = useState<StoreWarehouseModel[]>([]);
  const [storeWarehouseDelete, setStoreWarehouseDelete] = useState<StoreWarehouseModel[]>([]);

  useImperativeHandle(ref, () => ({
    getWarehouseListIds: () => warehouseListIds,
    getStoreWarehouseDelete: () => storeWarehouseDelete
  }), [warehouseListIds, storeWarehouseDelete]);

  const fetchWarehouse = useCallback(async () => {
    const response = await new WarehouseRepository().getItems({ filters: WarehouseRepository.getDefaultFilters() });
    setWarehouseDataSource(response.items);
  }, []);

  const fetchStoreWarehouse = useCallback(async () => {
    setLoading(true);
    if (model.id !== 0) {
      const collection = await new StoreWarehouseRepository().getItems({
        filters: {
          ...StoreWarehouseRepository.getDefaultFilters(),
          store_id: model.id
        }
      });
      await fetchWarehouse();
      setStoreWarehouseSource(collection.items);
    }
    setLoading(false);
  }, [model.id, fetchWarehouse]);

  useEffect(() => {
    fetchStoreWarehouse();
  }, [fetchStoreWarehouse]);

  const storeWarehouseData = useMemo(() => [
    ...warehouseListIds,
    ...storeWarehouseSource.map((source) => source.warehouse_id),
  ], [warehouseListIds, storeWarehouseSource]);

  const handleChangeWarehouseList = () => {
    const isInSource = storeWarehouseSource.find((source) => source.warehouse_id === valueSelected);
    const isInDelete = storeWarehouseDelete.find((sourceDelete) => sourceDelete.warehouse_id === valueSelected);
    
    if (isInDelete) {
      setStoreWarehouseDelete((prevState) => ([...prevState.filter((state) => state.id !== isInDelete.id)]));
      setStoreWarehouseSource((prevState) => ([...prevState, isInDelete]));
    }

    if (!isInSource && !isInDelete) {
      setWarehouseListIds([
        ...warehouseListIds.filter((idx) => idx !== valueSelected),
        valueSelected,
      ]);
    }
    setDefaultValues([]);
  };

  const handleDeleteWarehouse = async (id: number) => {
    const isInSource = storeWarehouseSource.find((source) => source.warehouse_id === id);

    if (isInSource) {
      const warehouse = warehouseDataSource.find((source) => source.id === id);

      if (warehouse?.apply_with_other === 0) {
        selectWarehouseRef.current?.setDataSource((prevState: WarehouseModel[]) => ([...prevState, warehouse]));
      }

      setStoreWarehouseSource((prevState) => ([...prevState.filter((state) => state.id !== isInSource.id)]));
      setStoreWarehouseDelete((prevState) => ([...prevState, isInSource]));
    }
    
    if (!isInSource) {
      setWarehouseListIds(warehouseListIds.filter((idx) => idx !== id));
    }
  };

  return (
    <FormSection
      title={t("store:form.section_warehouse_info")}
      subtitle={""}
      divider
    >
      <Row gutter={16}>
        <Col md={20} xs={24}>
          <Form.Item label="">
            <SelectWarehouse
              ref={selectWarehouseRef}
              placeholder={t("store:form.select_warehouse")}
              multiple={false}
              onChange={(ids: number[]) => {
                setValueSelected(ids[0]);
                setDefaultValues(ids);
              }}
              values={defaultValues}
              isWarehouseNotMapStore={true}
              excludeWarehouseIds={storeWarehouseData}
            />
          </Form.Item>
        </Col>
        <Col md={4} xs={24}>
          <Button
            size="middle"
            block
            type="default"
            loading={false}
            onClick={handleChangeWarehouseList}
          >
            {t("store:form.choose")}
          </Button>
        </Col>
      </Row>
      <Col>
        <StoreWarehouseList
          warehouse_ids={storeWarehouseData}
          handleWarehouseDeleteId={handleDeleteWarehouse}
          loading={loading}
        />
      </Col>
    </FormSection>
  );
});

export default StoreFormLinkWarehouse;
