import { App, Form, FormInstance, Row, Spin } from "antd";
import FormContent from "components/form/FormContent";
import FormSidebar from "components/form/FormSidebar";
import Error from "components/LayoutError";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import type { Options } from "scroll-into-view-if-needed";
import FormLoading from "./FormLoading";
const LayoutForm = ({
	form,
	initialValues,
	errors,
	error_heading,
	error_translate_prefix,
	isSuccess,
	successTitle,
	onSuccess,
	redirectOnSuccess,
	onSubmit,
	submitIcon,
	submitText,
	submitDisabled,
	sidebarItems,
	children,
	messageKeyForm,
	isModal,
	hideSidebar,
	layout,
	id,
	span,
	hideSubmitButton,
	hideFormSubmit,
	validateTrigger,
	disable_status_loading,
	scrollToFirstError,
	disable_message,
	onValuesChange,
	loading = false,
}: {
	onSubmit: (...args: any) => void;
	form?: FormInstance;
	initialValues?: Object;
	errors?: string[];
	error_heading?: string;
	error_translate_prefix?: string;
	isSuccess?: boolean;
	successTitle?: string;
	onSuccess?: (...args: any) => void;
	redirectOnSuccess?: string;
	submitIcon?: React.ReactNode;
	submitText?: string;
	submitDisabled?: boolean;
	sidebarItems: React.ReactNode | null;
	children?: React.ReactNode;
	messageKeyForm?: string;
	isModal?: boolean;
	hideSidebar?: boolean;
	layout?: "vertical" | "horizontal";
	id?: string;
	span?: number;
	hideSubmitButton?: boolean;
	hideFormSubmit?: boolean;
	validateTrigger?: string | false | string[];
	disable_status_loading?: boolean;
	disable_message?: boolean;
	scrollToFirstError?: Options | boolean;
	onValuesChange?: (changedValues: any, values: any) => void;
	loading?: boolean;
}) => {
	const { t } = useTranslation();
	let navigate = useNavigate();
	const { message } = App.useApp();
	const messageKey = messageKeyForm || "editor";
	const [processing, setProcessing] = useState(false);

	//handle ERROR
	useEffect(() => {
		if (typeof errors !== "undefined" && errors.length > 0 && !processing) {
			message.error({
				content: (
					<Error
						onClickClose={() => {
							message.destroy(messageKey);
						}}
						heading={
							typeof error_heading !== "undefined" && error_heading.length > 0
								? error_heading
								: t("common:error.form_submit")
						}
						translate_prefix={error_translate_prefix || ""}
						items={errors}
					/>
				),
				className: "message_error",
				key: messageKey,
				duration: 10,
			});
		}
	}, [
		errors,
		t,
		error_translate_prefix,
		error_heading,
		messageKey,
		processing,
		message,
	]);

	//default warning message if validator error on form when submit
	const onFinishFailed = (_errorInfo: any) => {
		message.warning({
			content: t("common:form.error_client"),
			className: "message_warning",
			key: messageKey,
			duration: 1,
		});
	};

	//isSuccess become TRUE --> request SUCCESS
	useEffect(() => {
		if (isSuccess) {
			message.success({
				content: successTitle,
				className: "message_success",
				key: messageKey,
				duration: 2,
			});

			//check callback set from parent
			if (typeof onSuccess !== "undefined") {
				onSuccess();
			}

			if (
				typeof redirectOnSuccess !== "undefined" &&
				redirectOnSuccess.length > 0
			) {
				navigate(redirectOnSuccess);
			}
		}
	}, [
		isSuccess,
		successTitle,
		onSuccess,
		redirectOnSuccess,
		navigate,
		messageKey,
		message,
	]);

	//formsubmit onFinish proxy, wrapping to set processing (to show spinner) and call onSubmit callback from parent
	const onFormSubmit = useCallback(
		async (formData: any) => {
			if (disable_status_loading) {
				setProcessing(true);
				message.loading({
					content: t("common:form.processing"),
					key: messageKey,
					duration: 0,
				});
				await onSubmit(formData);
				message.destroy(messageKey);
				if (!disable_message) {
					message.success({
						content: successTitle,
						className: "message_success",
						key: messageKey,
						duration: 2,
					});
				}

				setProcessing(false);
			} else {
				setProcessing(true);

				message.loading({
					content: t("common:form.processing"),
					key: messageKey,
					duration: 0,
				});

				await onSubmit(formData);

				setProcessing(false);
			}
		},
		[
			disable_message,
			disable_status_loading,
			onSubmit,
			message,
			t,
			messageKey,
			successTitle,
		]
	);

	const isInsideModal = useMemo(() => {
		return typeof isModal !== "undefined" && isModal;
	}, [isModal]);

	const isHideSidebar = useMemo(() => {
		return typeof hideSidebar !== "undefined" && hideSidebar;
	}, [hideSidebar]);

	const formChildren = <>{children}</>;

	useEffect(() => {
		return () => {
			setProcessing(false);
		};
	}, []);

	return (
		<div className={isHideSidebar || isInsideModal ? "" : "content-data"}>
			<FormLoading loading={loading}>
				<Form
					onChange={(e) => {
						form?.getFieldsValue();
					}}
					{...(typeof form !== "undefined" && { form })}
					initialValues={initialValues}
					layout={layout || "vertical"}
					onFinish={onFormSubmit}
					onFinishFailed={onFinishFailed}
					id={id}
					validateTrigger={validateTrigger}
					onValuesChange={onValuesChange}
					scrollToFirstError={
						scrollToFirstError && {
							behavior: "smooth",
							scrollMode: "if-needed",
							block: "end",
							inline: "center",
						}
					}>
					<Spin spinning={processing}>
						{isHideSidebar ? (
							formChildren
						) : (
							<Row>
								<FormContent isInsideModal={isInsideModal} span={span || 18}>
									{formChildren}
								</FormContent>
								<FormSidebar
									submitIcon={submitIcon}
									submitText={submitText || ""}
									isInsideModal={isInsideModal}
									submitDisabled={submitDisabled}
									span={typeof span === "number" ? 24 - span : 6}
									hideSubmitButton={hideSubmitButton}
									hideFormSubmit={hideFormSubmit}>
									{sidebarItems}
								</FormSidebar>
							</Row>
						)}
					</Spin>
				</Form>
			</FormLoading>
		</div>
	);
};

export default LayoutForm;
