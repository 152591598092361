import { message, Tag } from "antd";
import DeviceCollection from "common/collections/DeviceCollection";
import ContractAndDeviceModel from "common/models/ContractAndDeviceModel";
import ContractAndDeviceRepository from "common/repositories/ContractAndDeviceRepository";
import { FilterContractAndDevice } from "common/types/Device";
import { TableColumnsType } from "common/types/Table";
import TextString from "components/TextString";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DeviceMineListFilter from "./DeviceMineListFilter";
import DeviceMineListHeader from "./DeviceMineListHeader";

const DeviceMineList = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterContractAndDevice = useMemo(
    () => ContractAndDeviceRepository.getDefaultFilters(),
    []
  );

  //state
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<ContractAndDeviceModel[]>([]);
  const [filters, setFilters] =
    useStateFilter<FilterContractAndDevice>(defaultFilters);
  useFilterLocation(defaultFilters, filters);
  //////////////////////////////////////////

  //////////////////////////////////////////
  //Fetch data from this collections
  const fetchData = useCallback(async (): Promise<DeviceCollection> => {
    const collection = await new ContractAndDeviceRepository().getItems({
      filters,
    });

    if (collection) {
      setTotal(collection.total);
      setDataSource(collection.items);
    } else {
      message.error(t("common:error.error_fetching_data"));
    }

    return collection;
  }, [filters, t]);

  //////////////////////////////////////////
  //Table columns
  const columns: TableColumnsType<ContractAndDeviceModel> = [
    // {
    //   title: t("contractanddevice:manage.table_header.image"),
    //   key: "image",
    //   align: "center",
    //   width: 150,
    //   render: (image) => {
    //     if (image.id <= 0) {
    //       return <Tag>Trống</Tag>;
    //     }
    //     return <Image width={120} height={120} src={image.url} />;
    //   },
    // },
    {
      title: t("contractanddevice:manage.table_header.code"),
      key: "code",
      width: 150,
    },
    {
      title: t("contractanddevice:manage.table_header.name"),
      key: "name",
      width: 200,
      render: (name) => <TextString text={name} />,
    },
    {
      title: t("contractanddevice:manage.table_header.status"),
      key: "status",
      width: 150,
      render: (status: number) => {
        let statusInfo = ContractAndDeviceModel.getStatus(status);
        return (
          <Tag bordered color={statusInfo?.color}>
            {statusInfo?.label}
          </Tag>
        );
      },
    },
  ];

  return (
    <>
      <DeviceMineListHeader />
      <DeviceMineListFilter
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      />
      <PageDataTable<FilterContractAndDevice, ContractAndDeviceModel, DeviceCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "device",
        }}
      />
      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </>
  );
};

export default DeviceMineList;
