import ActionCollection from "common/collections/ActionCollection";
import ActionModel from "common/models/ActionModel";
import { ActionJsonAddEdit, FilterAction } from "common/types/Action";
import Helper from "common/utils/helper";
import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

const SERVICE_URL = "/workcheckings";

class ActionApi extends BaseApi {
	async getList(props: FilterAction): Promise<ActionCollection> {
		let collection = new ActionCollection();
		const convertProps = Helper.convertParams(props, true);
		const queryData = {
			params: {
				...convertProps,
			},
		};

		try {
			const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}
		return collection;
	}

	async add(props: ActionJsonAddEdit): Promise<ActionModel> {
		let item = new ActionModel(ActionModel.getDefaultData());

		let dataProps: any = { ...props };
		delete dataProps.id;
		const convertProps = Helper.convertParams(dataProps);

		try {
			let postData = {
				...convertProps,
				action: dataProps.action > -1 ? dataProps.action : null,
			};

			const response = await AxiosClient().post<any>(SERVICE_URL, postData);
			if (response.hasOwnProperty("data")) {
				item = new ActionModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}
		return item;
	}
}

export default ActionApi;
