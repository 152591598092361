import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { ShipperKPIJson } from "common/types/ShipperKPI";
import BaseModel from "./BaseModel";
import { SelectOption, SelectOptionNormal } from "common/types/SelectOption";
import ShipperKPI from "common/constants/ShipperKPI";
import { t } from "i18next";

const defaultData: ShipperKPIJson = {
  id: 0,
  employee_id: 0,
  coordinator: 0,
  order_id: 0,
  shift_time: "",
  district: 0,
  store_id: 0,
  amount: 0,
  payment: 0,
  note: "",
  status: 0,
  date_created: 0,
  date_modified: 0,
};

class ShipperKPIModel
  extends BaseModel
  implements BaseModelJson<ShipperKPIJson>
{
  id: number = defaultData.id;
  employee_id: number = defaultData.employee_id;
  coordinator: number = defaultData.coordinator;
  order_id: number = defaultData.order_id;
  shift_time: string = defaultData.shift_time;
  district: number = defaultData.district;
  store_id: number = defaultData.store_id;
  amount: number = defaultData.amount;
  payment: number = defaultData.payment;
  note: string = defaultData.note;
  status: number = defaultData.status;
  date_created: number = defaultData.date_created;
  date_modified: number = defaultData.date_modified;

  constructor(json: ShipperKPIJson) {
    super();
    Object.assign(this, { ...defaultData, ...json });
  }
  static getDefaultData(): ShipperKPIJson {
    return { ...defaultData };
  }
  toJson(): ShipperKPIJson {
    return JSON.parse(JSON.stringify({ ...this }));
  }

  static getPaymentList(): SelectOption[] {
    return [
      {
        value: ShipperKPI.PAYMENT_TESTER,
        label: t("shipperkpi:payment_tester"),
        color: "blue",
      },
      {
        value: ShipperKPI.PAYMENT_TRANSFER,
        label: t("shipperkpi:payment_transfer"),
        color: "blue",
      },
      {
        value: ShipperKPI.PAYMENT_DELIVERY_RECEIVE,
        label: t("shipperkpi:payment_delivery_receive"),
        color: "blue",
      },
      {
        value: ShipperKPI.PAYMENT_COD_TRANSFER,
        label: t("shipperkpi:payment_cod_transfer"),
        color: "blue",
      },
      {
        value: ShipperKPI.PAYMENT_TRANSFER_COD,
        label: t("shipperkpi:payment_transfer_cod"),
        color: "blue",
      },
    ];
  }

  static getPayment(val: number) {
    return this.getPaymentList().find((item) => item.value === val);
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: ShipperKPI.STATUS_CHECKED,
        label: t("shipperkpi:status_checked"),
        color: "gray",
      },
      {
        value: ShipperKPI.STATUS_RECEIVED,
        label: t("shipperkpi:status_received"),
        color: "yellow",
      },
      {
        value: ShipperKPI.STATUS_DELIVERIED,
        label: t("shipperkpi:status_deliveried"),
        color: "green",
      },
      {
        value: ShipperKPI.STATUS_FAILED,
        label: t("shipperkpi:status_failed"),
        color: "red",
      },
      {
        value: ShipperKPI.STATUS_CUSTOMER_FAILED,
        label: t("shipperkpi:status_customer_failed"),
        color: "red",
      },
    ];
  }

  static getStatus(val: number) {
    return this.getStatusList().find((item) => item.value === val);
  }

  static getShiftList(): SelectOptionNormal[] {
    return [
      {
        value: ShipperKPI.SHIFT_OFFICE_HOURS,
        label: t("shipperkpi:shift_office_hours"),
        color: "green",
      },
      {
        value: ShipperKPI.SHIFT_EVENING,
        label: t("shipperkpi:shift_evening"),
        color: "black",
      },
    ];
  }

  static getShift(val: string) {
    return this.getShiftList().find((item) => item.value === val);
  }
}

export default ShipperKPIModel;
