import PageHeader from "components/page/PageHeader";
import { useTranslation } from "react-i18next";

const ActionListHeader = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <PageHeader
      heading={t("action:header_list")}
      siteMenuOpenKey="hrm"
      siteMenuSelectedKey="/actions"
    />
  );
};

export default ActionListHeader;
