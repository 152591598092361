import { Popover, Space, Spin, Tag, Typography } from "antd";
import InventoryReceipt from "common/constants/InventoryReceipt";
import InventoryReceiptModel from "common/models/InventoryReceiptModel";
import ProductWarehouseModel from "common/models/ProductWarehouseModel";
import InventoryReceiptHistoryRepository from "common/repositories/InventoryReceiptHistoryRepository";
import InventoryReceiptRepository from "common/repositories/InventoryReceiptRepository";
import Helper from "common/utils/helper";
import TableLinkText from "components/table/TableLinkText";
import { memo, useCallback, useEffect, useState } from "react";

interface IProductWarehouseListExpandProps {
  record: ProductWarehouseModel | null;
}

const ProductWarehouseListExpand = ({
  record,
}: IProductWarehouseListExpandProps): JSX.Element => {
  const [isLoading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<InventoryReceiptModel[]>([]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const collection = await new InventoryReceiptHistoryRepository().getItems({
      filters: {
        ...InventoryReceiptRepository.getDefaultFilters(),
        item_id: record?.item_id ? record.item_id : -1,
        warehouse_id: record?.warehouse_id ? record.warehouse_id : -1,
      },
    });
    setDataSource(collection.items);
    setLoading(false);
  }, [record?.item_id, record?.warehouse_id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const HistoryItemPopover = ({ data }: { data: InventoryReceiptModel }): JSX.Element => {
    return (
      <Space>
        <Typography.Text>Kiểm tra theo mã phiếu:</Typography.Text>
        <TableLinkText link={"/inventoryreceipt/edit/id/" + data.id}>
          {data.code}
        </TableLinkText>
      </Space>
    );
  };

  const HistoryItem = ({ data }: { data: InventoryReceiptModel }): JSX.Element => {
    const direction = data.direction === InventoryReceipt.DIRECTION_INPUT ? "thêm vào" : "xuất khỏi";
    const product = data.detail.find((item) => item.item_id === record?.item_id);

    return (
      <li>
        <Popover content={<HistoryItemPopover data={data} />} placement="rightTop">
          <Space>
            <Typography.Text>Đã {direction} kho</Typography.Text>
            <Tag className="mr-0">{record?.warehouse?.name}</Tag>
            <Typography.Text>vào lúc</Typography.Text>
            <Tag className="mr-0">{Helper.formatTimestampToText2(data.date_created)}</Tag>
            <Typography.Text>số lượng</Typography.Text>
            <Tag className="mr-0">{product?.quantity}</Tag>
          </Space>
        </Popover>
      </li>
    )
  };

  return (
    <Spin spinning={isLoading}>
      <Typography.Text className="pt-2 pl-4 block">
        <b>Lịch sử kho tester:</b>
      </Typography.Text>
      <ol className="p-4 pl-8 mb-0">
        {dataSource.length > 0 ?
          dataSource.map((data) => <HistoryItem key={data.id} data={data} />) 
          :
          <li>
            <Tag className="mr-0">Không có thông tin lịch sử kho tester</Tag>
          </li>
        }
      </ol>
    </Spin>
  );
};

export default memo(ProductWarehouseListExpand);
