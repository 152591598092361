import { Col, Collapse, Divider, Modal, Row, Typography } from "antd";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SelectListComponent from "./SelectListComponent";
import { SelectOption } from "common/types/SelectOption";
import AddProductBySku from "./AddProductBySku";
import TagModel from "common/models/TagModel";
import { IconLoader } from "@tabler/icons-react";
import { AddListToItemComponentType } from "./AddListToItemComponent";

export default function AddListToItemModal({
	id,
	open,
	activeTag = new TagModel(TagModel.getDefaultData()),
	existValues = [],
	setOpen,
	renderItems,
	onSaveSuccess,
	handleRemoveItem,
	reload,
	type,
	store_id,
	handleAfterAddSuccess,
	handleAddSkuSuccess,
	existValuesSku,
}: {
	id: number;
	open: boolean;
	activeTag?: TagModel;
	existValues?: number[];
	existValuesSku?: string[];
	setOpen: (v: boolean) => any;
	renderItems: any;
	onSaveSuccess: any;
	handleRemoveItem: any;
	reload: boolean;
	type: AddListToItemComponentType;
	store_id?: number;
	handleAfterAddSuccess?: any;
	handleAddSkuSuccess?: any;
}) {
	const { t } = useTranslation();

	const [errors, setErrors] = useState<string[]>([]);
	const [processing, setProcessing] = useState(false);
	const [selectedValues, setSelectedValues] = useState<SelectOption[]>([]);
	const [title, setTitle] = useState<string>("Thêm mới");

	useEffect(() => {
		if (id > 0 && errors.length === 0) {
		}
	}, [id, errors]);

	const changeProcessing = (f: boolean) => {
		setProcessing(f);
	};

	return (
		<Modal
			title={
				<>
					<Typography.Text>{title}</Typography.Text>
				</>
			}
			width={800}
			open={open}
			confirmLoading={processing}
			onCancel={() => {
				setOpen(false);
				setSelectedValues([]);
			}}
			onOk={async () => {
				setProcessing(true);
				const response =
					type !== "productrelated"
						? await onSaveSuccess(selectedValues)
						: await onSaveSuccess(selectedValues, activeTag);
				if (response) {
					setSelectedValues([]);
				}
				setOpen(false);
				setProcessing(false);
			}}
			maskClosable={false}
			destroyOnClose
			bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}>
			{type === "product" && (
				<Row>
					<Col span={24}>
						<Collapse
							items={[
								{
									key: "1",
									label: "Import nhanh sku sản phẩm từ cột excel",
									children: (
										<>
											<AddProductBySku
												existValues={existValuesSku}
												type={type}
												id={id}
												handleAddSkuSuccess={handleAddSkuSuccess}
												setOpen={setOpen}
											/>
										</>
									),
								},
							]}
						/>
					</Col>
					<Divider />
				</Row>
			)}
			<Row>
				<Col span={24}>
					<SelectListComponent
						changeProcessing={changeProcessing}
						type={type}
						reload={reload}
						handleRemoveItem={handleRemoveItem}
						selectedValue={selectedValues}
						handleSelectedValue={(v) => {
							setSelectedValues(v);
						}}
						isMulti={true}
						values={existValues || []}
						multiple={true}
						placeholder={"Chọn sản phẩm"}
						renderItems={renderItems}
						store_id={store_id}
					/>
				</Col>
			</Row>
		</Modal>
	);
}

// export default AddListToItemModal;
