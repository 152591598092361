import PageHeader from "components/page/PageHeader";
import React from "react";
import { useTranslation } from "react-i18next";

const DeviceMineListHeader = ({ children }: { children?: React.ReactNode }) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("contractanddevice:mine.heading_list")}
      siteMenuSelectedKey="/device/mine">
      {children}
    </PageHeader>
  );
};

export default DeviceMineListHeader;
