import TagConstant from "common/constants/Tag";
import TagList from "features/tag/list/TagList";
import TagOutSideList from "features/tagproduct/list/TagOutSideList";
import TagTypeList from "features/tagtype/list/TagTypeList";
import usePathParams from "hooks/usePathParams";

const TagPage = () => {
	const [pathParams] = usePathParams();

	let com = null;

	switch (pathParams.action) {
		case "warehouse":
			com = <TagOutSideList type={TagConstant.RESOURCE_TYPE.WAREHOUSE} />;
			break;

		default:
			com = <TagList />;
	}

	return com;
};

export default TagPage;
