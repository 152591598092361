import { BaseModelJson } from "common/interfaces/BaseModelJson";
import {
	SystemEventActionType,
	SystemEventJson,
	SystemEventSourceType,
	SystemEventType,
} from "common/types/SystemEvent";
import BaseModel from "./BaseModel";
import { SelectOption, SelectOptionNormal } from "common/types/SelectOption";
import { t } from "i18next";
import SystemEvent from "common/constants/SystemEvent";

const defaultData: SystemEventJson = {
	id: 0,
	company_id: 0,
	resource_id: "",
	resource_type: "",
	action: "",
	event: "",
	details: "",
	date_created: 0,
	system_event_time: "",
	status: 0,
	date_modifield: 0,
	is_deleted: 0,
	metadata: null,
	date_deleted: 0,
};

class SystemEventModel
	extends BaseModel
	implements BaseModelJson<SystemEventJson>
{
	id: number = defaultData.id;
	company_id: number = defaultData.company_id;
	resource_id: string = defaultData.resource_id;
	resource_type: string = defaultData.resource_type;
	action: string = defaultData.action;
	event: string = defaultData.event;
	details: string = defaultData.details;
	date_created: number = defaultData.date_created;
	system_event_time: string = defaultData.system_event_time;
	status: number = defaultData.status;
	date_modifield: number = defaultData.date_modifield;
	is_deleted: number = defaultData.is_deleted;
	metadata: number = defaultData.metadata;
	date_deleted: number = defaultData.date_deleted;

	constructor(json: SystemEventJson) {
		super();
		Object.assign(this, { ...defaultData, ...json });
	}

	toJson(): SystemEventJson {
		return JSON.parse(JSON.stringify({ ...this }));
	}

	static getDefaultData(): SystemEventJson {
		return { ...defaultData };
	}

	static getEventLabel(value: string): SelectOptionNormal | undefined {
		return this.getEventList().find(
			(op: SelectOptionNormal) => op.value === value
		);
	}
	static getEventList(): SelectOptionNormal[] {
		return [
			{
				label: t(`system:event.error`),
				value: SystemEventType.ERROR,
				color: "red",
			},
			{
				label: t(`system:event.fetch`),
				value: SystemEventType.FETCH,
				color: "red",
			},
			{
				label: t(`system:event.fetch_ship`),
				value: SystemEventType.FETCH_SHIP,
				color: "red",
			},
			{
				label: t(`system:event.update_order`),
				value: SystemEventType.UPDATE_ORDER,
				color: "red",
			},
			{
				label: t(`system:event.sysdata`),
				value: SystemEventType.SYSDATA,
				color: "red",
			},
		];
	}

	static getResouceType(value: SystemEventSourceType): SelectOptionNormal | undefined {
		return this.getResouceTypeList().find(
			(op: SelectOptionNormal) => op.value === value
		);
	}
	static getResouceTypeList(): SelectOptionNormal[] {
		return [
			{
				label: "Website",
				value: "web",
				color: "blue",
			},
			// {
			// 	label: "Đồng bộ đơn hàng",
			// 	value: "pullmen_order",
			// 	color: "blue-inverse",
			// },
			{
				label: t(`system:resource_type_label.checkouts`),
				value: SystemEventSourceType.CHECKOUTS,
				color: "blue",
			},
			{
				label: t(`system:resource_type_label.lit_customer`),
				value: SystemEventSourceType.LIT_CUSTOMER,
				color: "blue",
			},
			{
				label: t(`system:resource_type_label.lit_invoice`),
				value: SystemEventSourceType.LIT_INVOICE,
				color: "blue",
			},
			{
				label: t(`system:resource_type_label.lit_inventory`),
				value: SystemEventSourceType.LIT_INVENTORY,
				color: "blue",
			},
			{
				label: t(`system:resource_type_label.lit_inventory_receipt`),
				value: SystemEventSourceType.LIT_INVENTORY_RECEIPT,
				color: "blue",
			},
			{
				label: t(`system:resource_type_label.lit_cashflow_receipt`),
				value: SystemEventSourceType.LIT_CASHFLOW_RECEIPT,
				color: "blue",
			},
			{
				label: t(`system:resource_type_label.lit_comment`),
				value: SystemEventSourceType.LIT_COMMENT,
				color: "blue",
			},
			{
				label: t(`system:resource_type_label.lit_product`),
				value: SystemEventSourceType.LIT_PRODUCT,
				color: "blue",
			},
			{
				label: t(`system:resource_type_label.lit_delivery`),
				value: SystemEventSourceType.LIT_DELIVERY,
				color: "blue",
			},
			{
				label: t(`system:resource_type_label.lit_meta_brand`),
				value: SystemEventSourceType.LIT_META_BRAND,
				color: "blue",
			},
			{
				label: t(`system:resource_type_label.lit_promotion`),
				value: SystemEventSourceType.LIT_PROMOTION,
				color: "blue",
			},
			{
				label: t(`system:resource_type_label.lit_story`),
				value: SystemEventSourceType.LIT_STORY,
				color: "blue",
			},
			{
				label: t(`system:resource_type_label.order`),
				value: SystemEventSourceType.ORDER,
				color: "blue",
			},
			{
				label: t(`system:resource_type_label.checkouts`),
				value: SystemEventSourceType.CHECKOUTS,
				color: "blue",
			},
			{
				label: t(`system:resource_type_label.lit_customer`),
				value: SystemEventSourceType.LIT_CUSTOMER,
				color: "blue",
			},
			{
				label: t(`system:resource_type_label.lit_invoice`),
				value: SystemEventSourceType.LIT_INVOICE,
				color: "blue",
			},
			{
				label: t(`system:resource_type_label.lit_inventory`),
				value: SystemEventSourceType.LIT_INVENTORY,
				color: "blue",
			},
			{
				label: t(`system:resource_type_label.lit_inventory_receipt`),
				value: SystemEventSourceType.LIT_INVENTORY_RECEIPT,
				color: "blue",
			},
			{
				label: t(`system:resource_type_label.lit_cashflow_receipt`),
				value: SystemEventSourceType.LIT_CASHFLOW_RECEIPT,
				color: "blue",
			},
			{
				label: t(`system:resource_type_label.lit_comment`),
				value: SystemEventSourceType.LIT_COMMENT,
				color: "blue",
			},
			{
				label: t(`system:resource_type_label.lit_product`),
				value: SystemEventSourceType.LIT_PRODUCT,
				color: "blue",
			},
			{
				label: t(`system:resource_type_label.lit_delivery`),
				value: SystemEventSourceType.LIT_DELIVERY,
				color: "blue",
			},
			{
				label: t(`system:resource_type_label.lit_meta_brand`),
				value: SystemEventSourceType.LIT_META_BRAND,
				color: "blue",
			},
			{
				label: t(`system:resource_type_label.lit_promotion`),
				value: SystemEventSourceType.LIT_PROMOTION,
				color: "blue",
			},
			{
				label: t(`system:resource_type_label.lit_story`),
				value: SystemEventSourceType.LIT_STORY,
				color: "blue",
			},
			{
				label: t(`system:resource_type_label.order`),
				value: SystemEventSourceType.ORDER,
				color: "blue",
			},
		];
	}

	static getActionList(): SelectOptionNormal[] {
		return [
			{
				label: t(`system:action_label.set`),
				value: SystemEventActionType.SET,
				color: "blue",
			},
			{
				label: t(`system:action_label.created`),
				value: SystemEventActionType.CREATED,
				color: "blue-inverse",
			},
			{
				label: t(`system:action_label.update`),
				value: SystemEventActionType.UPDATE,
				color: "yellow",
			},
			{
				label: t(`system:action_label.update_order`),
				value: SystemEventActionType.UPDATE_ORDER,
				color: "yellow-inverse",
			},
			{
				label: t(`system:action_label.update_collection`),
				value: SystemEventActionType.UPDATE_COLLECTION,
				color: "cyan",
			},
			{
				label: t(`system:action_label.update_inventory`),
				value: SystemEventActionType.UPDATE_INVENTORY,
				color: "cyan-inverse",
			},
			{
				label: t(`system:action_label.promotion.edit`),
				value: SystemEventActionType.PROMOTION_EDIT,
				color: "orange",
			},
			{
				label: t(`system:action_label.complete_receipt`),
				value: SystemEventActionType.COMPLETE_RECEIPT,
				color: "orange-inverse",
			},
			{
				label: t(`system:action_label.error_invoice`),
				value: SystemEventActionType.ERROR_INVOICE,
				color: "magenta",
			},
			{
				label: t(`system:action_label.error_on_page`),
				value: SystemEventActionType.ERROR_ON_PAGE,
				color: "magenta-inverse",
			},
			{
				label: t(`system:action_label.error_promotion_invalid`),
				value: SystemEventActionType.ERROR_PROMOTION_INVALID,
				color: "purple",
			},
			{
				label: t(`system:action_label.error_promotion_collection_invalid`),
				value: SystemEventActionType.ERROR_PROMOTION_COLLECTION_INVALID,
				color: "purple-inverse",
			},
			{
				label: t(`system:action_label.status_complete`),
				value: SystemEventActionType.STATUS_COMPLETE,
				color: "susccess",
			},
		];
	}
	static getActionLabel(value: string): SelectOptionNormal | undefined {
		return this.getActionList().find(
			(op: SelectOptionNormal) => op.value === value
		);
	}

	static getStatusList(): SelectOption[] {
		return [
			{
				value: SystemEvent.STATUS_NEW,
				label: t("feedback:status.new"),
				color: "blue",
			},
			{
				value: SystemEvent.STATUS_PROCESSED,
				label: t("feedback:status.processed"),
				color: "green",
			},
		];
	}

	static getStatus(val: number) {
		return this.getStatusList().find((item) => item.value === val);
	}
}

export default SystemEventModel;
