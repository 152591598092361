import React, { useCallback, useMemo, useState } from "react";
import { Button, Dropdown, MenuProps, Modal } from "antd";
import Delivery from "common/constants/Delivery";
import { useTranslation } from "react-i18next";
import { ActionCode, MatrixArray, MatrixItem } from "common/types/Delivery";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { ItemType } from "antd/es/menu/interface";

////////////////////////////////
type Props = {
	action_code: ActionCode;
	matrix_action: MatrixArray;
	single_action?: SingleAction;
	prefix_translate_button: string;
	prefix_button?: string;
	type?: "default" | "shipper";
	elButton?: React.ReactNode;
	loading?: boolean;
	renderModal: ({
		nextAction,
		closeModal,
		gpsRequire,
		noteRequire,
		uploadRequire,
	}: {
		nextAction: ActionCode;
		closeModal: () => void;
		uploadRequire: boolean;
		gpsRequire: boolean;
		noteRequire: boolean;
	}) => React.ReactNode;
};

type SingleAction = {
	prefix_translate: string;
	action_code: SingleActionItem[];
	size?: SizeType;
};

export type SingleActionItem = {
	actionCode: ActionCode;
	isShow?: boolean;
};

type ButtonSubmit = {
	label: string;
	action: ActionCode;
	uploadRequire: boolean;
	gpsRequire: boolean;
	noteRequire: boolean;
	type?: "link" | "text" | "default" | "primary" | "dashed";
	size?: SizeType;
};

type ModalProps = {
	action: ActionCode;
	noteRequire: boolean;
	uploadRequire: boolean;
	gpsRequire: boolean;
};
////////////////////////////
const ButtonAction = ({
	action_code,
	matrix_action,
	single_action,
	renderModal,
	prefix_translate_button,
	prefix_button,
	type = "default",
	elButton,
	loading,
}: Props) => {
	const { t } = useTranslation();

	//state
	const [isOpen, setIsOpen] = useState(false);
	const [nextAction, setNextAction] = useState<ActionCode>(action_code);
	const [noteRequire, setNoteRequire] = useState<boolean>(false);
	const [uploadRequire, setUploadRequire] = useState<boolean>(false);
	const [gpsRequire, setGpsRequire] = useState<boolean>(false);

	///////////////////////////////
	//function
	const showModal = ({
		action,
		noteRequire,
		uploadRequire,
		gpsRequire,
	}: ModalProps) => {
		setIsOpen(true);
		setNextAction(action);
		setUploadRequire(uploadRequire);
		setNoteRequire(noteRequire);
		setGpsRequire(gpsRequire);
	};

	const closeModal = () => {
		setIsOpen(false);
		setNextAction("");
	};

	//////////////////////////////
	// process
	const ItemMatrix: MatrixItem | null = useMemo(() => {
		let foundItem: MatrixItem | null = null;
		const findItem = matrix_action.find((i) => i[0] === action_code);
		if (typeof findItem !== "undefined") {
			foundItem = [...findItem];
		}

		return foundItem;
	}, [action_code, matrix_action]);

	const find = useMemo(
		(): ((action: ActionCode) => MatrixItem | null) => (action: ActionCode) => {
			let foundItem: MatrixItem | null = null;
			for (let i = 0; i < matrix_action.length; i++) {
				if (matrix_action[i][0] === action) {
					foundItem = matrix_action[i];
				}
			}
			return foundItem;
		},
		[matrix_action]
	);

	// Ation item render
	const ItemButtonSubmit = ({
		action,
		label,
		uploadRequire,
		gpsRequire,
		noteRequire,
		type = "default",
		size = "middle",
	}: ButtonSubmit) => {
		return (
			<Button
				loading={loading}
				size={size}
				block
				type={type}
				className="uppercase"
				onClick={() =>
					showModal({ action, gpsRequire, noteRequire, uploadRequire })
				}>
				{label}
			</Button>
		);
	};

	//   let NextAction = [];
	//   let Action_code: ActionCode | null = null;
	//   if (action_code === "ACTION_TRANSFER") {
	//     Action_code = ActionNow;
	//   } else if (action_code === "ACTION_COMPLETE") {
	//     Action_code = "ACTION_COMPLETE";
	//   } else if (action_code === "ACTION_DELIVERED") {
	//     Action_code = "ACTION_DELIVERED";
	//   }
	//   const NowAction = find(
	//     Action_code !== null ? Action_code : action_code,
	//     ActionMatrix,
	//     true
	//   );

	//   if (NowAction !== undefined && NowAction?.join() !== "") {
	//     NextAction = NowAction.map((act: any) => {
	//       return find(act, ActionMatrix, false);
	//     });
	//   }
	//   return (
	//     <>
	//       <Menu.Item key="NEXT_ACTION">
	//         <Button size="large" block onClick={handleReload} htmlType="button">
	//           {t(`delivery:action.reload`).toLocaleUpperCase()}
	//         </Button>
	//       </Menu.Item>
	//       {NextAction.length > 0 &&
	//         NextAction.map((item: any, index: number) => (
	//           <ItemButtonSubmit
	//             action={item[0]}
	//             gpsRequire={item[2]}
	//             uploadRequire={item[1]}
	//             noteRequire={item[3]}
	//             title={item[0]}
	//             htmlType="button"
	//             key={index}
	//             skey={JSON.stringify(index)}
	//           ></ItemButtonSubmit>
	//         ))}
	//     </>
	//   );
	// };
	const items: MenuProps["items"] = useMemo(() => {
		let listButtonAction: ItemType[] = [];

		if (ItemMatrix !== null && ItemMatrix[4].length > 0) {
			ItemMatrix[4].forEach((i: ActionCode) => {
				const foundAction = find(i);
				foundAction !== null &&
					listButtonAction.push({
						label: ItemButtonSubmit({
							action: foundAction[0],
							uploadRequire: foundAction[1],
							gpsRequire: foundAction[2],
							noteRequire: foundAction[3],
							label: t(`${prefix_translate_button + "." + foundAction[0]}`),
						}),
						key: i,
					});
			});
		}
		return listButtonAction;
	}, [action_code, matrix_action, ItemMatrix]);

	const nextMatrixAction: MatrixArray = useMemo(() => {
		let Data: MatrixArray = [];
		if (ItemMatrix !== null && ItemMatrix[4].length > 0) {
			ItemMatrix[4].forEach((i: ActionCode) => {
				const foundAction = find(i);
				foundAction !== null && Data.push(foundAction);
			});
		}
		return Data;
	}, [action_code, matrix_action, ItemMatrix]);
	const itemSingleActions: MenuProps["items"] = useMemo(() => {
		let listButtonAction: ItemType[] = [];
		if (single_action) {
			if (single_action.action_code.length > 1) {
				single_action.action_code
					.filter((i) => (typeof i.isShow !== "undefined" ? i.isShow : true))
					.forEach((i: SingleActionItem) => {
						const itemMatrix = find(i.actionCode);
						itemMatrix &&
							listButtonAction.push({
								label: ItemButtonSubmit({
									action: itemMatrix[0],
									uploadRequire: itemMatrix[1],
									gpsRequire: itemMatrix[2],
									noteRequire: itemMatrix[3],
									label: t(
										`${single_action.prefix_translate}.${itemMatrix[0]}`
									),
								}),
								key: i.actionCode,
							});
					});
			}
		}
		return listButtonAction;
	}, [single_action]);

	const itemSingle = useMemo(() => {
		if (single_action && single_action.action_code.length === 1) {
			return find(single_action.action_code[0].actionCode);
		} else {
			return null;
		}
	}, [single_action]);

	return (
		<>
			{single_action ? (
				single_action.action_code.length > 1 ? (
					<Dropdown menu={{ items: itemSingleActions }}>
						<Button
							loading={loading}
							style={{ textTransform: "uppercase" }}
							type="primary"
							size={single_action.size}
							block>
							{action_code === "ACTION_RECEIVE_COD"
								? t("delivery:button_action.button.done")
								: t("delivery:button_action.button.pending")}
						</Button>
					</Dropdown>
				) : (
					itemSingle &&
					ItemButtonSubmit({
						label: t(`${single_action.prefix_translate}.${itemSingle[0]}`),
						action: itemSingle[0],
						uploadRequire: itemSingle[1],
						gpsRequire: itemSingle[2],
						noteRequire: itemSingle[3],
						type: "primary",
						size: single_action.size,
					})
				)
			) : (
				<div>
					{nextMatrixAction.length === 1 && prefix_button ? (
						ItemButtonSubmit({
							action: nextMatrixAction[0][0],
							uploadRequire: nextMatrixAction[0][1],
							gpsRequire: nextMatrixAction[0][2],
							noteRequire: nextMatrixAction[0][3],
							type: "primary",
							label: t(`${prefix_button}.${nextMatrixAction[0][0]}`),
						})
					) : (
						<Dropdown menu={{ items: items }} trigger={["click"]}>
							<Button
								loading={loading}
								style={{ textTransform: "uppercase" }}
								type="primary"
								size="middle"
								block>
								{[
									Delivery.ACTION_RECEIVE_COD,
									Delivery.ACTION_DELIVERED,
								].includes(action_code)
									? t("delivery:button_action.button.processing")
									: [Delivery.ACTION_COMPLETE].includes(action_code)
									? t("delivery:button_action.button.success")
									: t("delivery:button_action.button.waiting_completion")}
							</Button>
						</Dropdown>
					)}
				</div>
			)}
			<Modal
				title={t("delivery:button_action.modal_title")}
				destroyOnClose
				centered
				open={isOpen}
				onCancel={() => {
					setIsOpen(false);
				}}
				bodyStyle={{ padding: "12px 24px" }}
				okButtonProps={{ style: { display: "none" } }}>
				{renderModal({
					nextAction: nextAction,
					gpsRequire: gpsRequire,
					noteRequire: noteRequire,
					uploadRequire: uploadRequire,
					closeModal: closeModal,
				})}
			</Modal>
		</>
	);
};

export default ButtonAction;
