import { App, Button, Input, Popconfirm, Table } from "antd";
import ProductTransmitDetailCollection from "common/collections/ProductTransmitDetailCollection";
import ProductTransmit from "common/constants/ProductTransmit";
import Role from "common/constants/Role";
import ProductTransmitDetailModel from "common/models/ProductTransmitDetailModel";
import ProductTransmitModel from "common/models/ProductTransmitModel";
import ProductTransmitDetailRepository from "common/repositories/ProductTransmitDetailRepository";
import { ProductVariantInMemory } from "common/types/ProductVariant";
import { TableColumnsType } from "common/types/Table";
import QuantityInput from "components/QuantityInput";
import TextProductVariant from "components/TextProductVariant";
import dbm from "dbm";
import ProductVariantSearch from "features/product/ProductVariantSearch";
import update from "immutability-helper";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import addToListFx from "sounds/pos/notify.mp3";
import useSound from "use-sound";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import useUiSettingStore from "zustands/useUiSettingStore";

import { IconBan, IconX } from "@tabler/icons-react";

import ColumnHeaderItem from "../item/ColumnHeaderItem";

const ProductTransmitDetailList = ({
	detailItems,
	productTranmitModel,
	setDetailItems,
}: {
	detailItems: ProductTransmitDetailModel[];
	productTranmitModel: ProductTransmitModel;
	setDetailItems: (items: ProductTransmitDetailModel[]) => void;
}) => {
	const hasRoleAndLimit = useLoginAccountStore(
		(state) => state.hasRoleAndLimit
	);

	const { t } = useTranslation();
	const { message } = App.useApp();
	const enableSoundFx = useUiSettingStore((state) => state.enableSoundFx);
	const [soundFxAddToListSuccess] = useSound(addToListFx);
	const confirmingDeleteSku = useRef("");
	const [loading, setLoading] = useState(false);
	const isPending = useMemo(
		() => productTranmitModel.status === ProductTransmit.STATUS_PENDING,
		[productTranmitModel.status]
	);
	//fetch all ProductTransmitDetail

	const fetchProductTransmitDetail = useCallback(
		async (id: number) => {
			setLoading(true);
			//fetch all
			let foundItem: ProductTransmitDetailModel[] = [];
			let page = 1;
			let limit = 50;
			let fetchedCollection = new ProductTransmitDetailCollection();
			do {
				fetchedCollection =
					await new ProductTransmitDetailRepository().getItems({
						filters: {
							product_transmit_id: id,
							page,
							limit,
							sortby: "id",
							sorttype: "ASC",
							keyword: "",
							status: 0,
						},
					});

				if (!fetchedCollection.hasError()) {
					fetchedCollection.items.forEach(
						(item: ProductTransmitDetailModel) => {
							foundItem.push(item);
						}
					);
				}
				page++;
			} while (
				!fetchedCollection.hasError() &&
				fetchedCollection.items.length === limit
			);

			setLoading(false);
			setDetailItems(foundItem);
		},
		[setDetailItems]
	);

	useEffect(() => {
		if (productTranmitModel.id > 0) {
			fetchProductTransmitDetail(productTranmitModel.id);
		}
	}, [productTranmitModel.id, fetchProductTransmitDetail]);

	const doRemoveRowWithSku = useCallback(
		(sku: string) => {
			setDetailItems(detailItems.filter((item) => item.product_sku !== sku));
			confirmingDeleteSku.current = "";
		},
		[detailItems, setDetailItems]
	);

	const updateRow = useCallback(
		async (product_variant_id: number, quantity: number) => {
			let insertPass = true;

			const foundIndex = detailItems.findIndex(
				(r) => r.product_variant_id === product_variant_id
			);
			let newItems: ProductTransmitDetailModel[] = [];
			if (foundIndex >= 0) {
				//update current item
				newItems = update(detailItems, {
					[foundIndex]: {
						$set: new ProductTransmitDetailModel({
							...detailItems[foundIndex],
							quantity: quantity,
						}),
					},
				});
			} else {
				await (async () => {
					const foundItems = await dbm
						.getCollection("productvariant")
						.chain()
						.find({
							id: product_variant_id,
						})
						.limit(1)
						.data();
					if (foundItems.length > 0) {
						const foundVariant: ProductVariantInMemory = foundItems[0];
						//append new item to list
						newItems = update(detailItems, {
							$push: [
								new ProductTransmitDetailModel({
									...ProductTransmitDetailModel.getDefaultData(),
									product_transmit_id: productTranmitModel.id,
									product_variant_id: product_variant_id,
									product_id: foundVariant.product_id,
									product_sku: foundVariant.sku,
									quantity: 1,
								}),
							],
						});
					} else {
						insertPass = false;
						message.error(
							t("productvariant:id_not_found", { id: product_variant_id })
						);
					}
				})();
			}

			if (insertPass) {
				setDetailItems(newItems);
				if (enableSoundFx) {
					soundFxAddToListSuccess();
				}
			}
		},
		[
			detailItems,
			setDetailItems,
			enableSoundFx,
			soundFxAddToListSuccess,
			productTranmitModel.id,
			t,
			message,
		]
	);

	const onSelectVariant = useCallback(
		(product_variant_id: number) => {
			const foundItem = detailItems.find(
				(r) => r.product_variant_id === product_variant_id
			);

			updateRow(
				product_variant_id,
				typeof foundItem !== "undefined" ? foundItem.quantity + 1 : 1
			);
		},
		[detailItems, updateRow]
	);

	const columns: TableColumnsType<ProductTransmitDetailModel> = [
		{
			title: t("common:table.index"),
			dataIndex: "id",
			align: "center",
			render: (_, __, index: number) => {
				return <>{index + 1}</>;
			},
			width: 50,
		},
		{
			title: t("producttransmit:sku"),
			dataIndex: "product_sku",
		},
		{
			title: t("producttransmit:form.product_info"),
			dataIndex: "product_variant_id",
			render: (product_variant_id: number) => {
				return <TextProductVariant id={product_variant_id} />;
			},
		},
		{
			title: t("producttransmit:form.quantity"),
			dataIndex: "quantity",
			width: 140,
			align: "center",
			render: (_: any, record: ProductTransmitDetailModel) => {
				return (
					<>
						{productTranmitModel.status !== ProductTransmit.STATUS_PENDING ? (
							<Input
								style={{ width: "100%" }}
								size="large"
								value={record.quantity}
								className="text-center"
								disabled
							/>
						) : (
							<QuantityInput
								key={record.product_variant_id}
								value={record.quantity}
								onChange={(quantity: number | null) =>
									updateRow(record.product_variant_id, quantity ?? 1)
								}
							/>
						)}
					</>
				);
			},
		},
		{
			title: () => {
				return (
					<ColumnHeaderItem
						receipt_id={productTranmitModel.receipt_id_from}
						type="from"
						warehouse_id={productTranmitModel.warehouse_id_from}
					/>
				);
			},
			dataIndex: "from_item_quantity",
			width: 150,
			align: "center",
			render: (_: any, record: ProductTransmitDetailModel) => {
				if (
					hasRoleAndLimit(
						Role.INVENTORY_VIEW,
						productTranmitModel.warehouse_id_from
					)
				) {
					return (
						<>
							{productTranmitModel.receipt_id_from > 0
								? record.from_item_quantity
								: "-"}
						</>
					);
				} else {
					return <IconBan className="text-gray-300" />;
				}
			},
		},
		{
			title: () => {
				return (
					<ColumnHeaderItem
						receipt_id={productTranmitModel.receipt_id_to}
						type="to"
						warehouse_id={productTranmitModel.warehouse_id_to}
					/>
				);
			},
			dataIndex: "to_item_quantity",
			width: 150,
			align: "center",
			render: (_: any, record: ProductTransmitDetailModel) => {
				if (
					hasRoleAndLimit(
						Role.INVENTORY_VIEW,
						productTranmitModel.warehouse_id_to
					)
				) {
					return (
						<>
							{productTranmitModel.receipt_id_to > 0
								? record.to_item_quantity
								: "-"}
						</>
					);
				} else {
					return <IconBan className="text-gray-300" />;
				}
			},
		},
		{
			title: t("producttransmit:form.compare_quantity"),
			dataIndex: "deviant_quantity",
			width: 100,
			align: "center",
			render: (_: any, record: ProductTransmitDetailModel) => {
				if (
					hasRoleAndLimit(
						Role.INVENTORY_VIEW,
						productTranmitModel.warehouse_id_to
					) &&
					hasRoleAndLimit(
						Role.INVENTORY_VIEW,
						productTranmitModel.warehouse_id_from
					)
				) {
					return (
						<>
							{productTranmitModel.receipt_id_to > 0 &&
							productTranmitModel.receipt_id_from > 0
								? record.deviant_quantity
								: "-"}
						</>
					);
				} else {
					return "-";
				}
			},
		},
		...(isPending
			? [
					{
						title: " ",
						dataIndex: "operation",
						width: 50,
						render: (_: any, record: ProductTransmitDetailModel) => {
							return (
								<>
									<Popconfirm
										title={t("common:table.confirm_delete_title")}
										placement="topRight"
										onConfirm={() => {
											doRemoveRowWithSku(record.product_sku);
										}}
										onCancel={() => (confirmingDeleteSku.current = "")}
										okText={t("common:table.confirm_ok")}
										cancelText={t("common:table.confirm_cancel")}>
										<Button
											type="link"
											size="small"
											className="text-red-200 hover:text-red-500"
											onClick={() =>
												(confirmingDeleteSku.current = record.product_sku)
											}>
											<IconX />
										</Button>
									</Popconfirm>
								</>
							);
						},
					},
			  ]
			: []),
	];

	let newColumns = [...columns];

	if (productTranmitModel.id === 0) {
		newColumns = columns.filter(
			(item) =>
				item.dataIndex !== "from_item_quantity" &&
				item.dataIndex !== "to_item_quantity" &&
				item.dataIndex !== "deviant_quantity"
		);
	} else {
		newColumns = columns.filter((item) => item.dataIndex !== "operation");
	}

	return (
		<div>
			<div hidden={productTranmitModel.id > 0} className="mb-2">
				{/* <ProductVariantSearch
          size="large"
          onSelect={(product_variant_id) => onSelectVariant(product_variant_id)}
        /> */}
			</div>

			<Table
				size="small"
				className="w-full table-dashboard"
				dataSource={detailItems}
				columns={newColumns}
				rowKey={"product_variant_id"}
				bordered
				loading={loading}
				pagination={{ hideOnSinglePage: true }}
				footer={() => null}
				summary={() =>
					productTranmitModel.id > 0 ? (
						<Table.Summary fixed>
							<Table.Summary.Row className="text-center">
								<Table.Summary.Cell colSpan={3} index={0} className="font-bold">
									{t("producttransmit:form.quantity_total")}
								</Table.Summary.Cell>
								<Table.Summary.Cell index={1} className="font-bold">
									{detailItems.reduce(
										(accumulator, current) =>
											accumulator + Number(current["quantity"]),
										0
									)}
								</Table.Summary.Cell>
								<Table.Summary.Cell index={2} className="font-bold">
									{hasRoleAndLimit(
										Role.INVENTORY_VIEW,
										productTranmitModel.warehouse_id_from
									) && productTranmitModel.receipt_id_from > 0 ? (
										<>
											{detailItems.reduce(
												(accumulator, current) =>
													accumulator + Number(current["from_item_quantity"]),
												0
											)}
										</>
									) : (
										<>-</>
									)}
								</Table.Summary.Cell>
								<Table.Summary.Cell index={3} className="font-bold">
									{hasRoleAndLimit(
										Role.INVENTORY_VIEW,
										productTranmitModel.warehouse_id_to
									) && productTranmitModel.receipt_id_to > 0 ? (
										<>
											{detailItems.reduce(
												(accumulator, current) =>
													accumulator + Number(current["to_item_quantity"]),
												0
											)}
										</>
									) : (
										<>-</>
									)}
								</Table.Summary.Cell>
								<Table.Summary.Cell index={4} className="font-bold">
									{hasRoleAndLimit(
										Role.INVENTORY_VIEW,
										productTranmitModel.warehouse_id_to
									) &&
									hasRoleAndLimit(
										Role.INVENTORY_VIEW,
										productTranmitModel.warehouse_id_from
									) &&
									productTranmitModel.receipt_id_from > 0 &&
									productTranmitModel.receipt_id_to > 0 ? (
										<>
											{detailItems.reduce(
												(accumulator, current) =>
													accumulator + Number(current["deviant_quantity"]),
												0
											)}
										</>
									) : (
										<>-</>
									)}
								</Table.Summary.Cell>
							</Table.Summary.Row>
						</Table.Summary>
					) : null
				}
			/>
		</div>
	);
};

export default ProductTransmitDetailList;
