import BaseCollection from "./BaseCollection";

//////////////////////////////////
//Phần thay thế
import SystemEventModel from "common/models/SystemEventModel";
import {
	SystemEventJson,
	SystemEventCollectionJson,
} from "common/types/SystemEvent";

class TemplateCollection extends BaseCollection<
	SystemEventJson,
	SystemEventModel,
	SystemEventCollectionJson
> {
	itemsFromJson(jsonItems: SystemEventJson[]): SystemEventModel[] {
		return jsonItems.map((item) => new SystemEventModel(item));
	}
}
export default TemplateCollection;
