import { EditFilled } from "@ant-design/icons";
import {
	Checkbox,
	Divider,
	Flex,
	Form,
	FormInstance,
	Input,
	Popover,
	Space,
	Tag,
	Tooltip,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import Order from "common/constants/Order";
import { ISPROBLEM, OrderJson } from "common/types/Order";
import TagStringInput from "components/TagStringInput";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const OrderFormSectionGeneral = ({
	form,
	allowEditInput,
	order,
}: {
	form: FormInstance;
	allowEditInput: boolean;
	order: OrderJson;
}) => {
	const { t } = useTranslation();

	const type = order.type;
	const [isProblem, setIsProblem] = useState(order.is_problem === ISPROBLEM.IS);

	// const [isCreditBalance, setIscreditBalance] = useState(order.is);

	useEffect(() => {
		setIsProblem(order.is_problem === ISPROBLEM.IS ? true : false);
	}, [order.is_problem]);

	useEffect(() => {
		form.setFieldValue("note_problem", order.note_problem);
	}, [order.note_problem]);

	// Auto checked customer_credit_balance when it is true
	useEffect(() =>
		form.setFieldValue("customer_credit_balance", order.customer_credit_balance),
	[form, order.customer_credit_balance]);

	const isAllowDept = Form.useWatch("customer_credit_balance", form);

	return (
		<Flex vertical gap={4}>
			<Flex gap={2} wrap="wrap">
				<Form.Item
					name="customer_credit_balance"
					valuePropName="checked"
					className="flex gap-2 m-0">
					<Tag>
						<Flex gap={4}>
							<Tooltip title="">Cho phép nợ</Tooltip>
							<Checkbox
								checked={isAllowDept}
								onChange={(e) => form.setFieldValue("customer_credit_balance", e.target.checked)}
							/>
						</Flex>
					</Tag>
				</Form.Item>
				<Form.Item
					name="is_problem"
					valuePropName="checked"
					className="flex gap-2 m-0">
					<Tag>
						<Flex gap={4}>
							<Tooltip
								title={`${
									type && type === Order.TYPE_INVOICE ? "Hóa đơn" : "Đơn hàng"
								} đang gặp vấn đề tồn đọng cần xử lý. Vui lòng kiểm tra lại thông tin đơn hàng ( phiếu thu - chi , nhập - xuất ,... )`}>
								Mark as Problem
							</Tooltip>
							<Checkbox
								onChange={(e) => {
									setIsProblem(e.target.checked);
								}}
								defaultChecked={isProblem}
								checked={isProblem}
							/>

							<Popover
								trigger={["click", "contextMenu"]}
								content={
									<Space>
										<Form.Item
											labelCol={{ span: 24 }}
											label="Nội dung vấn đề tồn đọng"
											name={"note_problem"}>
											<TextArea rows={2}></TextArea>
										</Form.Item>
									</Space>
								}>
								<EditFilled></EditFilled>
							</Popover>
						</Flex>
					</Tag>
				</Form.Item>
			</Flex>

			<Divider className="my-4"></Divider>
			<Form.Item label={t("order:tag")} name="tag">
				<TagStringInput disabled={!allowEditInput} />
			</Form.Item>
			<Form.Item label={t("order:note")} name="note">
				<Input.TextArea
					disabled={!allowEditInput}
					autoSize
					style={{ minHeight: 56, maxHeight: 120 }}
				/>
			</Form.Item>
		</Flex>
	);
};

export default OrderFormSectionGeneral;
