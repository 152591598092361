import { IconLoader, IconPlus, IconTag } from "@tabler/icons-react";
import {
	Button,
	Modal,
	Skeleton,
	Tag,
	Tooltip,
	Typography,
	message,
} from "antd";
import TagConstant from "common/constants/Tag";
import TagDetailModel from "common/models/TagDetailModel";
import TagModel from "common/models/TagModel";
import TagRepository from "common/repositories/TagRepository";
import { SelectOption } from "common/types/SelectOption";
import AddListToItemComponent from "features/productcollection/form/children/AddListToItemComponent";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ProductCollectionTagModal = ({
	id,
	open,
	setOpen,
}: {
	id: number;
	open: boolean;
	setOpen: (v: boolean) => void;
}) => {
	const { t } = useTranslation();

	const [processing, setProcessing] = useState<boolean>(false);

	const [selectTagReload, setSelectTagReload] = useState<boolean>(false);
	const [existTagValues, setExistTagValue] = useState<number[]>([]);

	//add tag to productcollection
	const onSubmitTagDetail = async (v: SelectOption[]) => {
		setProcessing(true);
		if (v.length > 0) {
			message.loading({ content: "Vui lòng đợi", duration: 1 });
			const response = await new TagRepository().addTagDetail({
				details: v.map((item, index) => {
					return {
						tag_id: item.value,
						resource_id: id,
						resource_type: TagConstant.RESOURCE_TYPE.PRODUCT_COLLECTION,
					};
				}),
			});

			if (!response.hasError()) {
				message.success({ content: "Thao tác thành công", duration: 1 });
				setExistTagValue([...existTagValues, ...v.map((item) => item.value)]);
				setSelectTagReload(!selectTagReload);
				setProcessing(false);

				return true;
			} else {
				message.error(response.error.errors);
				setProcessing(false);

				return false;
			}
		}
		setProcessing(false);
	};

	//remove tag from productcollection
	const onDeleteTagItem = async (v: SelectOption) => {
		setProcessing(true);

		message.loading({ content: "Vui lòng đợi", duration: 1 });
		const findTagDetailIdList = await new TagRepository().getItemsDetail({
			filters: {
				...TagRepository.getDefaultTagDetailFilters(),
				resource_id: id,
				resource_type: TagConstant.RESOURCE_TYPE.PRODUCT_COLLECTION,
				tag_id: v.value,
			},
		});
		if (
			!findTagDetailIdList.hasError() &&
			findTagDetailIdList.items.length > 0
		) {
			const findTagDetailId = findTagDetailIdList.items.find(
				(item) => item.resoucre_id === id && item.tag_id === Number(v.value)
			);

			if (findTagDetailId) {
				const reponse = await new TagRepository().deleteTagDetailItem([
					findTagDetailId.id,
				]);
				if (reponse.length === 0) {
					message.success({ content: "Thao tác thành công", duration: 1 });
					setExistTagValue(existTagValues.filter((item) => item !== v.value));
					setSelectTagReload(!selectTagReload);
				} else {
					message.error("Thao tác thất bại");
				}
			} else {
				message.error("Thao tác thất bại");
			}
		} else {
			message.error("Thao tác thất bại");
		}
		setProcessing(false);
	};

	const changeTagExistValue = (newTag: TagDetailModel[]) => {
		setExistTagValue(newTag.map((item) => item.tag_id));
	};

	const handleRemoveCurrentItem = async (v: SelectOption[]) => {
		setProcessing(true);

		if (v.length > 0) {
			const reponse = await new TagRepository().deleteTagDetailItem(
				v.map((item) => item.value)
			);
			if (reponse.length === 0) {
				message.success({ content: "Thao tác thành công", duration: 1 });
				// setExistValue(existValues.filter((item) => item !== v.value));
				setSelectTagReload(!selectTagReload);
				setProcessing(false);

				return true;
			} else {
				message.error("Thao tác thất bại");
				setProcessing(false);

				return false;
			}
		}
	};

	return (
		<Modal
			title={
				<>
					<div>
						<Typography.Text>{`Quản lý tag trong danh mục`}</Typography.Text>
						{processing && <IconLoader className="animate-spin" />}
					</div>
				</>
			}
			width={920}
			bodyStyle={{
				paddingTop: 8,
				paddingBottom: 8,
				overflowY: "auto",
				maxHeight: "calc(100vh - 250px)",
			}}
			open={open}
			confirmLoading={processing}
			onCancel={() => {
				setOpen(false);
			}}
			onOk={() => {
				setOpen(false);
			}}
			maskClosable={false}
			okButtonProps={{ style: { display: "none" } }}
			cancelText={"Đóng"}
			destroyOnClose>
			<AddListToItemComponent
				type="tagproductcollection"
				reload={selectTagReload}
				existValues={existTagValues}
				id={id}
				onSaveSuccess={onSubmitTagDetail}
				handleRemoveItem={(v: SelectOption) => {
					onDeleteTagItem(v);
				}}
				handleRemoveCurrentItem={handleRemoveCurrentItem}
				changeExistValue={changeTagExistValue}
				renderItems={(item: TagModel) => {
					return (
						<>
							<div className="flex justify-between items-center">
								<Tooltip
									title={
										<>
											<div>ID: {item.id}</div>
											<div>Code: {item.code}</div>
											<div>Tên: {item.name}</div>
											<div>Giá trị: {item.value}</div>
										</>
									}>
									<Tag className="w-fit">{item.name}</Tag>
								</Tooltip>
								{item.type.length > 0 && (
									<Typography.Text className="">
										(Loại: {item.name})
									</Typography.Text>
								)}
							</div>
						</>
					);
				}}></AddListToItemComponent>
		</Modal>
	);
};

export default ProductCollectionTagModal;
