import InventoryReceiptHistoryApi from "common/api/InventoryReceiptHistoryApi";
import InventoryReceiptCollection from "common/collections/InventoryReceiptCollection";
import { GetListProps } from "common/types/Filter";
import { InventoryReceiptHistoryFilter } from "common/types/InventoryReceiptHistory";
import BaseRepository from "./BaseRepository";

class InventoryReceiptHistoryRepository extends BaseRepository<InventoryReceiptHistoryApi> {
	_api: InventoryReceiptHistoryApi | null;

	constructor() {
		super();
		this._api = new InventoryReceiptHistoryApi(true);
	}

	static getDefaultFilters(): InventoryReceiptHistoryFilter {
		return {
			keyword: "",
			limit: 20,
			page: 1,
			sortby: "",
			sorttype: "",
			status: -1,
			item_id: -1,
			warehouse_id: -1,
		};
	}

	async getItems(props: GetListProps<InventoryReceiptHistoryFilter>) {
		return this._api
			? await this._api.getList(props)
			: new InventoryReceiptCollection;
	}
}

export default InventoryReceiptHistoryRepository;
