const StoryCollectionConstant = {
  TYPE_STORY: 1,
  TYPE_ACADEMY: 2,
  TYPE_ADMIN: 3,
  CONTENT_TYPE: {
    CONTENT_IMAGE: 1,
    CONTENT_VIDEO: 2,
    CONTENT_AUDIO: 3,
  },
  STATUS_PIN: 1,
	STATUS_NO_PIN: 0,
  STATUS_ENABLE: 1,
	STATUS_DRAFT: 2,
	STATUS_HIDDEN: 3,
};

export default StoryCollectionConstant;
