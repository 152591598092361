import { Form, Select } from 'antd';
import WarehouseRepository from 'common/repositories/WarehouseRepository';
import { WarehouseJson } from 'common/types/Warehouse';
import { debounce } from 'lodash';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';

const SelectWarehouse = forwardRef((props: {
  values?: number[];
  multiple?: boolean;
  name?: string;
  placeholder?: string;
  isMulti?: boolean;
  className?: string;
  icon?: React.ReactNode;
  width?: number;
  onChange: (v: number[]) => void;
  isWarehouseNotMapStore?: boolean;
  excludeWarehouseIds?: number[];
}, ref) => {
  const [dataSource, setDataSource] = useState<WarehouseJson[]>([]);

  const fetchData = useCallback(
    async (searchKeyword: string = "") => {
      const res = await new WarehouseRepository().getItems({
        filters: {
          ...WarehouseRepository.getDefaultFilters(),
          keyword: searchKeyword,
          warehouse_not_map_store: props.isWarehouseNotMapStore
        }
      });
      let warehouses: WarehouseJson[] = res.toJson().items
      setDataSource(warehouses);
    },
    [props.isWarehouseNotMapStore]
  );

  const debouncedFetchData = useCallback(debounce(fetchData, 500), [fetchData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const warehouseFilteredOptions = useMemo(() => (dataSource &&
    dataSource
      .filter((o) => o?.id > 0)
      .filter((o) => !props.excludeWarehouseIds?.includes(o?.id))
      .map((e) => {
        return {
          value: e?.id,
          label: e?.name,
        };
      })
    ) || [],
  [dataSource, props.excludeWarehouseIds]);

  let className = "ant-select-item-option-selected-hide";
  if (typeof props.className !== "undefined") {
    className += " " + props.className;
  }

  const onChangeHandle = (value: any) => {
    if (typeof props.onChange !== "undefined") {
      if (typeof value != "undefined") {
        if (Array.isArray(value)) {
          props.onChange(value);
        } else {
          props.onChange([value]);
        }
      } else {
        props.onChange([]);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    setDataSource: setDataSource
  }), []);

  return (
    <Form.Item noStyle>
      <Select<number[]>
        {...(typeof props.icon !== "undefined" && { suffixIcon: props.icon })}
        virtual={false}
        popupClassName={className}
        style={{ width: props.width || "100%" }}
        onChange={onChangeHandle}
        onSearch={debouncedFetchData}
        placeholder={props.placeholder}
        allowClear={true}
        showSearch={true}
        mode={props.multiple ? "multiple" : undefined}
        defaultValue={props.values || []}
        optionFilterProp="label"
        filterOption={(input: any, option: any) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
          option.label
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/[đĐ]/g, "d")
            .toLowerCase()
            .indexOf(
              input
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/[đĐ]/g, "d")
                .toLowerCase()
            ) >= 0
        }
        options={warehouseFilteredOptions}
      ></Select>
    </Form.Item>
  );
});

export default SelectWarehouse;
