import { App, Button, Tooltip } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useOnline } from "rooks";

import { SyncOutlined } from "@ant-design/icons";

import HeaderLanguageSwitch from "./HeaderLanguageSwitch";
import HeaderProfile from "./HeaderProfile";

const HeaderRight = () => {
	const { t } = useTranslation();
	const { message } = App.useApp();
	const onlineStatus = useOnline();

	const clickOffline = useCallback(() => {
		message.warning(t("common:offline_warning"));
	}, [t, message]);

	return (
		<div className="navbar-right">
			{!onlineStatus ? (
				<Tooltip placement="leftTop" title={t("common:offline_warning_long")}>
					<Button
						onClick={clickOffline}
						size="small"
						danger
						type="text"
						icon={<SyncOutlined spin />}>
						OFFLINE
					</Button>
				</Tooltip>
			) : null}

			<HeaderLanguageSwitch />
			{/* <HeaderNotification /> */}
			<HeaderProfile />
		</div>
	);
};

export default HeaderRight;
