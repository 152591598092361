import ContactCollection from "common/collections/ContactCollection";
import { ContactAddEditJson, ContactJson, FilterContact } from "common/types/Contact";
import type { GetListProps } from "common/types/Filter";
import Helper from "common/utils/helper";
import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";
import ContactModel from "common/models/ContactModel";

const SERVICE_URL = "/customers/contact";

class ContactApi extends BaseApi {
  async getList(
    props: GetListProps<FilterContact>
  ): Promise<ContactCollection> {
    let collection = new ContactCollection();
    const convertProps = Helper.convertParams(props.filters);

    const queryData = {
      params: {
        ...convertProps,
        sort_by: props.filters.sortby,
        sort_type: props.filters.sorttype,
        comments_on: props.filters.comments_on ? props.filters.comments_on.join(",") : null,
      },
    };
    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "?type=1", queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }

  async edit(data: ContactAddEditJson): Promise<ContactModel> {
    let item = new ContactModel(ContactModel.getDefaultData());
    try {
      let postData = {
        ...data,
      };
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/update/" + data.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new ContactModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default ContactApi;
