import { BaseCollectionJson } from "./BaseCollectionJson";
import { Filter } from "./Filter";

export enum ContactMailStatus {
	ENABLE = 1,
	DISABLE = 2,
	PROCESSED = 3,
}

type ContactMailJson = {
	id: number;
	contact: string;
	item_id: string;
	detail: string;
	type: number;
	status: number;
	is_deleted: number;
	date_deleted: number;
	date_created: number;
	date_modified: number;
};

type ContactMailAddEditJson = {
	id: number;
	contact: string;
	item_id: number | string;
	type: number;
	status: ContactMailStatus;
};

type FilterContactMail = Filter & {
	contact: string;
};

type ContactMailCollectionJson = BaseCollectionJson<ContactMailJson>;

export type {
	ContactMailJson,
	FilterContactMail,
	ContactMailCollectionJson,
	ContactMailAddEditJson,
};
