import { SelectProps } from "antd";
import { Rule } from "antd/es/form";
import { FormLabelAlign } from "antd/es/form/interface";
import { SelectValue } from "antd/lib/select";
import StoreRepository from "common/repositories/StoreRepository";
import FormSelect from "components/form/FormSelect";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

const StoreCodeSelect = ({
	label,
	name,
	disabled,
	value,
	onChange,
	labelAlign,
	required,
	allowClear,
	placeholder,
	popupMatchSelectWidth,
	size,
	status,
	className,
	rules,
}: {
	label?: string;
	name: string;
	disabled?: boolean;
	value?: SelectValue;
	onChange?: (v: SelectValue) => void;
	labelAlign?: FormLabelAlign;
	required?: boolean;
	allowClear?: boolean;
	placeholder?: string;
	popupMatchSelectWidth?: boolean;
	size?: SelectProps["size"];
	status?: SelectProps["status"];
	className?: string;
	rules?: Rule[];
}) => {
	const [errors, setErrors] = useState<string[]>([]);
	const [loading, setLoading] = useState(true);
	const [options, setOptions] = useState<{value: string; label: string}[]>([]);

	const fetchData = useCallback(async () => {
		setLoading(true);
		const collection = await new StoreRepository().getItems({
			filters: StoreRepository.getDefaultFilters(),
		});
		if (collection.hasError()) {
			setErrors(collection.error.errors);
		} else {
			setOptions(
				collection.items.map((item) => ({
					value: item.code,
					label: item.name,
				}))
			);
		}
		setLoading(false);
	}, []);
	
	const debounceFetcher = useMemo(() => debounce(fetchData, 500), [fetchData]);

	useEffect(() => {
		if (loading) {
			fetchData();
		} else {
			setLoading(false);
		}
	}, [loading, fetchData]);

	return (
		<FormSelect
			rules={rules}
			className={className}
			size={size}
			disabled={disabled}
			required={required}
			loading={loading}
			showSearch={options.length > 6}
			label={label}
			name={name}
			options={options}
			errors={errors}
			errorRetry={fetchData}
			value={value}
			onChange={onChange}
			onSearch={debounceFetcher}
			labelAlign={labelAlign}
			allowClear={allowClear}
			placeholder={placeholder}
			popupMatchSelectWidth={popupMatchSelectWidth}
			status={status}
		/>
	);
};

export default StoreCodeSelect;
