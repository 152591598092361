import { MoreOutlined } from "@ant-design/icons";
import {
	Button,
	Dropdown,
	Flex,
	MenuProps,
	message,
	Tag,
	Typography,
} from "antd";
import FeedbackCollection from "common/collections/FeedbackCollection";
import Feedback from "common/constants/Feedback";
import SettingApi from "common/constants/Setting";
import FeedbackModel from "common/models/FeedbackModel";
import SettingModel from "common/models/SettingModel";
import StoreModel from "common/models/StoreModel";
import FeedbackRepository from "common/repositories/FeedbackRepository";
import StoreRepository from "common/repositories/StoreRepository";
import { FilterFeedback } from "common/types/Feedback";
import { TableColumnsType } from "common/types/Table";
import Helper from "common/utils/helper";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import useFilterLocation from "hooks/useFilterLocation";
import useSettingValue from "hooks/useSettingValue";
import useStateFilter from "hooks/useStateFilter";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import FeedbackListExpand from "./FeedbackListExpand";
import FeedbackListFilter from "./FeedbackListFilter";
import FeedbackListHeader from "./FeedbackListHeader";

const FeedbackList = () => {
	const { t } = useTranslation();
	const {
		setting: settingFeedbackCommentOn,
		isLoading: isLoadingSettingFeedback,
	} = useSettingValue(SettingApi.KEY.setting_stores_feedback_contents);

	//////////////////////////////////////////
	//Filtering
	const defaultFilters: FilterFeedback = useMemo(
		() => FeedbackRepository.getDefaultFilters(),
		[]
	);

	//state
	const [isLoadingUpdate, setLoadingUpdate] = useState(false);
	const [total, setTotal] = useState<number>(0);
	const [dataSource, setDataSource] = useState<FeedbackModel[]>([]);
	const [storeDataSource, setStoreDataSource] = useState<StoreModel[]>([]);
	const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
	const [filters, setFilters] = useStateFilter<FilterFeedback>(defaultFilters);
	useFilterLocation(defaultFilters, filters);
	//////////////////////////////////////////

	//////////////////////////////////////////
	//Fetch data from this collections
	const fetchData = useCallback(async (): Promise<FeedbackCollection> => {
		const storeCollection = await new StoreRepository().getItems({
			filters: StoreRepository.getDefaultFilters(),
		});
		const collection = await new FeedbackRepository().getItems({
			filters,
		});

		if (collection) {
			setTotal(collection.total);
			setDataSource(collection.items);
			setStoreDataSource(storeCollection.items);
		} else {
			message.error(t("common:error.error_fetching_data"));
		}

		return collection;
	}, [filters, t]);

	const onUpdateStatus = async (record: FeedbackModel, status: number) => {
		setLoadingUpdate(true);
		try {
			const response = await new FeedbackRepository().saveRemote({
				id: record.id,
				status,
			});
			if (!response.hasError()) {
				await fetchData();
			}
		} catch (error: any) {
			message.error(error);
		} finally {
			setLoadingUpdate(false);
		}
	};

	//////////////////////////////////////////
	//Table columns
	const columns: TableColumnsType<FeedbackModel> = [
		{
			title: "ID",
			key: "id",
			width: 80,
		},
		{
			title: t("feedback:table_header.comments_on"),
			key: "comments_on",
			width: 300,
			render: (comments_on) => {
				const commentsOnArr = comments_on.split(",");
				let com = <></>;
				if (commentsOnArr.length === 0) {
					com = <Tag>Không có thông tin</Tag>;
				}
				com = (
					<Flex wrap gap={4}>
						{commentsOnArr.map((commentOn: string) => {
							const commentLabel = settingFeedbackCommentOn.value
								? settingFeedbackCommentOn.value.find(
										(setting: SettingModel) => setting.value === commentOn
								  )?.label
								: "";

							if (!commentLabel) {
								return <Tag>Không có thông tin</Tag>;
							}

							return <Tag color="blue-inverse">{commentLabel}</Tag>;
						})}
					</Flex>
				);
				return com;
			},
		},
		{
			title: t("feedback:table_header.email"),
			key: "email",
			width: 200,
			render: (email) => {
				if (email.length === 0) return "--";
				return email;
			},
		},
		{
			title: t("feedback:table_header.full_name"),
			key: "full_name",
			width: 200,
			render: (full_name) => {
				if (full_name.length === 0) return "--";
				return full_name;
			},
		},
		{
			title: t("feedback:table_header.phone"),
			key: "phone",
			width: 100,
			render: (phone) => {
				if (!Number(phone)) return "--";
				return (
					<Typography.Text className="text-blue-500" copyable>
						{phone}
					</Typography.Text>
				);
			},
		},
		{
			title: t("feedback:table_header.receive_from"),
			key: "receive_from",
			width: 120,
			render: (receive_from: string) =>
				storeDataSource.find((store) => store.code === receive_from)?.name,
		},
		{
			title: t("feedback:table_header.status"),
			key: "status",
			width: 100,
			render: (status: number) => {
				let statusInfo = FeedbackModel.getStatus(status);
				return (
					<Tag bordered color={statusInfo?.color}>
						{statusInfo?.label}
					</Tag>
				);
			},
		},
		{
			title: t("common:date_created"),
			key: "date_created",
			width: 120,
			render: (date_created) => Helper.formatTimestampToText2(date_created),
		},
		{
			title: " ",
			toggletype: "trigger",
			key: "actions",
			align: "right",
			fixed: "right",
			width: 100,
			render: (_: number, record) => {
				const items: MenuProps["items"] = [
					{
						key: "1",
						label: (
							<Button
								type="text"
								title="Đã xử lý"
								onClick={() =>
									onUpdateStatus(
										record,
										record.status === Feedback.STATUS_NEW
											? Feedback.STATUS_PROCESSED
											: Feedback.STATUS_NEW
									)
								}>
								{record.status === Feedback.STATUS_NEW
									? "Đã xử lý"
									: "Chưa xử lý"}
							</Button>
						),
					},
				];
				return (
					<div onClick={(e) => e.stopPropagation()}>
						<Dropdown
							disabled={record.status === Feedback.STATUS_PROCESSED}
							trigger={["click"]}
							menu={{ items }}>
							<MoreOutlined
								style={{ fontSize: "16pt" }}
								className="cursor-pointer"
							/>
						</Dropdown>
					</div>
				);
			},
		},
	];

	return (
		<>
			<FeedbackListHeader />
			<FeedbackListFilter
				total={total}
				filters={filters}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
			/>
			<PageDataTable<FilterFeedback, FeedbackModel, FeedbackCollection>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					isLoading: isLoadingSettingFeedback || isLoadingUpdate,
					tableColumnToggleKey: "feedback",
					rowClassName: "cursor-pointer",
					expandable: {
						expandedRowRender: (record: FeedbackModel) => (
							<FeedbackListExpand record={record} />
						),
						expandedRowClassName: () =>
							"ant-table-expanded-row-dimmer [&_.ant-table]:!ms-0 [&_.ant-table]:!me-0 [&_.ant-table]:!mt-0 [&_.ant-table]:!mb-0",
						expandRowByClick: true,
						showExpandColumn: false,
						expandedRowKeys: expandedRowKeys,
						onExpand: (expanded: boolean, record: FeedbackModel) => {
							setExpandedRowKeys(
								expanded
									? [...expandedRowKeys, record.id]
									: expandedRowKeys.filter((key) => key !== record.id)
							);
						},
					},
				}}
			/>
			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>
		</>
	);
};

export default FeedbackList;
