import { Form, Input } from "antd";
import PromotionModel from "common/models/PromotionModel";
import { Filter } from "common/types/Filter";
import DateFormFilter from "components/datetime/DateFormFilter";
import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import dayjs, { Dayjs } from "dayjs";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { RangePickerProps } from "antd/es/date-picker";
import CustomerFormSelect from "features/customer/form/CustomerFormSelect";
import StoreFormSelect from "features/store/StoreFormSelect";

type RangeValue = [Dayjs | null, Dayjs | null] | null;

const PromotionListFilter = <F extends Filter>({
	filters,
	setFilters,
	defaultFilters,
	total,
}: {
	filters: F;
	setFilters: (f: F) => any;
	defaultFilters: F;
	total: number;
}) => {
	const { t } = useTranslation();

	// FILTER: DATESTARTED AND DATEENDED
	const getDateCreatedFilter = useMemo(() => {
		let entries = Object.entries(filters)
			.filter(([key, val]) => key === "date_started" || key === "date_ended")
			.map(([key, val]) => {
				return val;
			}) as number[];
		return (
			+entries[0] > 0 && +entries[1] > 0
				? [
						dayjs(dayjs.unix(entries[0]).format("DD/MM/YYYY"), "DD/MM/YYYY"),
						dayjs(dayjs.unix(entries[1]).format("DD/MM/YYYY"), "DD/MM/YYYY"),
				  ] || null
				: [null, null] || null
		) as RangeValue;
	}, [filters]);
	const onChangeDateCreatedFilter: RangePickerProps["onChange"] = (
		value: RangePickerProps["value"],
		dateString: [string, string]
	) => {
		if (typeof value !== "undefined" && value !== null) {
			setFilters({
				...filters,
				date_started: +dayjs(
					"00:00, " + value[0]?.format("DD/MM").toString(),
					"HH:mm, DDMM"
				).unix(),
				date_ended: +dayjs(
					"23:59, " + value[1]?.format("DD/MM").toString(),
					"HH:mm, DDMM"
				).unix(),
			});
		} else {
			setFilters({
				...filters,
				date_started: -1,
				date_ended: -1,
			});
		}
	};

	return (
		<PageDataFilterForm
			filters={filters}
			setFilters={setFilters}
			total={total}
			defaultFilters={defaultFilters}
			convertValueByKeyFilter={["status"]}
		>
			<Form.Item name="keyword">
				<Input
					allowClear
					placeholder={t("promotion:filter_keyword_placeholder")}
				/>
			</Form.Item>

			{/* <Form.Item name="id">
        <Input allowClear placeholder={t("common:sid")} />
      </Form.Item> */}

			<FormSelect
				name="status"
				options={PromotionModel.getStatusList()}
				allowClear
				placeholder={t("common:status")}
				popupMatchSelectWidth={false}
			/>

			<FormSelect
				name="group"
				options={PromotionModel.getGroupList()}
				allowClear
				placeholder={t("promotion:group")}
				popupMatchSelectWidth={false}
			/>

			<FormSelect
				name="type"
				options={PromotionModel.getTypeList()}
				allowClear
				placeholder={t("Loại giảm")}
				popupMatchSelectWidth={false}
			/>

			<FormSelect
				name="store_scope"
				options={[
					{ label: "Toàn bộ hệ thống", value: 1 },
					{ label: "Một vài chi nhánh", value: 3 },
				]}
				allowClear
				placeholder={t("Phạm vi cửa hàng")}
				// popupMatchSelectWidth={false}
			/>

			<StoreFormSelect
				placeholder="Chọn chi nhánh"
				name="store_list"
				allowClear
				popupMatchSelectWidth={false}
				multiple
			/>

			<FormSelect
				name="customer_scope"
				options={[
					{ label: "Toàn bộ khách hàng", value: 1 },
					{ label: "Một vài khách hàng", value: 3 },
				]}
				allowClear
				placeholder={t("Phạm vi khách hàng")}
			/>

			<CustomerFormSelect
				placeholder="Chọn khách hàng"
				name="customer_list"
				allowClear
				popupMatchSelectWidth={false}
				multiple
			/>
			{/* <StoreSearch isMulti={true} name={"store_list"}></StoreSearch> */}

			{/* FILTER: DATESTART AND DATEEND */}
			<Form.Item label={t("common:date_created")} labelCol={{ span: 6 }}>
				<DateFormFilter
					rangeValue={getDateCreatedFilter}
					allowClear={true}
					showNow={false}
					mode="range"
					format="DD/MM/YYYY"
					onRangeChange={onChangeDateCreatedFilter}
				/>
			</Form.Item>
		</PageDataFilterForm>
	);
};

export default PromotionListFilter;
